import React, { useState, useEffect } from 'react';
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button, Row, Col, FloatingLabel, Accordion } from 'react-bootstrap';
import { ServiceShow, ServicesForm, fetchService, serviceTaxcod } from "../../../../redux/slice/ServiceSlice";
import { insertService } from '../../../../redux/slice/ServiceSlice';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { openErrorbox } from '../../../Pages/Contracts/Common/actionPerform';
import customerService from '../../../../services/customerService';

export const NewService = ({ setSingleService, singleservice, setAddNewService }) => {
  // This line initializes the useDispatch hook and assigns it to the dispatch variable
  const dispatch = useDispatch<AppDispatch>()
  const { show, servicelist, servicetaxcode } = useSelector((state: RootState) => state.service);
  const [singleservicedata, setsingleServiceData] = useState([]);
  const [supplier, setSupplier] = useState([])
  const [adminFee, setAdminFee] = useState(false)
  const [creditShow, setCreditShow] = useState(false)
  const [debitOwner, setDebitOwner] = useState(false)

  // Initialize the useTranslation hook for internationalization
  const [t] = useTranslation()
  const AccountPlanList = useSelector((state: RootState) => state.accountplane.accountPlane);
  // used it to show validation error on form
  const validationSchema = Yup.object().shape({
    account_category: Yup.string().nullable()
      .required(t("requiredMmessage.fieldRequired")).test('is-not-zero', t("requiredMmessage.fieldRequired"), (value) => value !== "0"),
    name: Yup.string().trim().nullable()
      .required('Name is required'),
    // service_code_sku: Yup.string().trim().nullable()
    //   .required('Service Code Sku is required'),
    // debit: Yup.string().trim().nullable()
    //   .required('Debit is required'),
    // credit: Yup.string().trim().nullable()
    //   .required('Credit is required'),
    // pay_incom_task: Yup.string().trim().nullable()
    //   .required('ISSQN collection is required'),
    // admin_service: Yup.string().trim().nullable()
    //   .required('Service code (Tax) is required'),
    // admn_deduct_service: Yup.string().trim().nullable()
    //   .required('Percentage of taxes is required'),
    // issqn_collection_id: Yup.string().trim().nullable()
    //   .required('Invoice and dimob is required'),
    // service_code_tax_id: Yup.string().trim().nullable()
    //   .required('Invoice and dimob is required'),
    // percent_of_tax: Yup.string().trim().nullable()
    //   .required('Invoice and dimob is required'),
    // not_taxable: Yup.string().trim().nullable()
    //   .required('Invoice and dimob is required'),
    // invoice_dimob: Yup.string().trim().nullable()
    //   .required('Invoice and dimob is required'),
  });

  // The useForm hook is used to handle form registration, submission, and validation.
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm<ServicesForm>({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (show) {
      // Set a timeout to delay updating the singleServiceData state
      setTimeout(() => {
        // Update the singleServiceData state with the singleservice value
        setsingleServiceData(singleservice);
      }, 800)
      let supplierData = { supplier: "", start: 0, end: 10 }
      customerService.getAllSuppliers(supplierData).then(res => {
        if (res.data.resCode == 201) {
          setSupplier(res.data.data);
        } else {
          setSupplier([]);
        }
      }).catch(err => {
        console.log(err)
      });
    }
  }, [show])

  const [list, setList] = useState<any>([]);
  useEffect(() => {
    // Function to modify labels recursively
    const modifyLabels = (data: any) => {
      const updatedData: any = [];
      for (const item of data) {
        // Create an updated item with value, label, and childData
        const updatedItem: any = {
          value: item.item,
          label: item.description,
          childData: item.childData ? modifyLabels(item.childData) : [],
        };
        updatedData.push(updatedItem);
      }
      return updatedData;
    };
    // Call the modifyLabels function on AccountPlanList and set the modified list
    setList(modifyLabels(AccountPlanList))
  }, [AccountPlanList])

  useEffect(() => {
    if (show) {
      let newobj: any = "";
      if (singleservice) {
        // Construct an object with the service details
        newobj = {
          "service_id": singleservice[0]?.ID_PRODUTO_PRD,
          "name": singleservice[0]?.ST_DESCRICAO_PRD,
          "account_category": singleservice[0]?.ST_DESCRICAODESCONTO_CONT || 0,
          "service_code_sku": singleservice[0]?.ST_SINCRO_PRD,
          "debit": singleservice[1]?.ID_DEBITO_PRD || "",
          "credit": singleservice[1]?.ID_CREDITO_PRD || "",
          "pay_incom_task": Boolean(singleservice[0]?.FL_INSIDEICMS_PRD || false),
          "admin_service": Boolean(singleservice[1]?.FL_COBRARTXADM_PRD),
          "admn_deduct_service": Boolean(singleservice[1]?.FL_DEDUZIR_PRD),
          "issqn_collection_id": singleservice[0]?.FL_NATUREZAOP_PRD ?? "",
          "service_code_tax_id": singleservice[0]?.ID_CODIGOSERVICO_CSE ? Number(singleservice[0]?.ID_CODIGOSERVICO_CSE) : "",
          "percent_of_tax": singleservice[0]?.VL_TRIBUTOSAPROX_PRD,
          "not_taxable": Boolean(singleservice[0]?.FL_NAOTRIBUTAVEL_PRD),
          "invoice_dimob": singleservice[1]?.FL_DIMOB_PRD,
          "is_disabled": Boolean(singleservice[1]?.FL_DESATIVADO_PRD),
          "supplier": Number(singleservice[1]?.ID_FORNECEDOR_PRD),
        }
     
        if (singleservice[0]?.ID_PRODUTO_PRD) {
          if (singleservice[0]?.ID_PRODUTO_PRD == "999999999" || singleservice[0]?.ID_PRODUTO_PRD == "999999994" || singleservice[0]?.ID_PRODUTO_PRD == "999999995" || singleservice[0]?.ID_PRODUTO_PRD == "22" || singleservice[0]?.ID_PRODUTO_PRD == "999999985" || singleservice[0]?.ID_PRODUTO_PRD == "999999987" || singleservice[0]?.ID_PRODUTO_PRD == "999999996" || singleservice[0]?.ID_PRODUTO_PRD == "1000000000" || singleservice[0]?.ID_PRODUTO_PRD ==  "999999993" || singleservice[0]?.ID_PRODUTO_PRD == "999999997") {
            setDebitOwner(true)
            setCreditShow(true)
          } else if (singleservice[0]?.ID_PRODUTO_PRD == "999999991" || singleservice[0]?.ID_PRODUTO_PRD == "999999990" || singleservice[0]?.ID_PRODUTO_PRD == "999999989" || singleservice[0]?.ID_PRODUTO_PRD == "999999988") {
            setCreditShow(false)
            setDebitOwner(true)
          } else {
            setDebitOwner(false)
            setCreditShow(false)
          }
        }

      } else {
        // Empty object if no singleservice data is available
        // newobj = {
        //   "service_id": "",
        //   "name": "",
        //   "account_category": 0,
        //   "service_code_sku": "",
        //   "debit": "",
        //   "credit": "",
        //   "pay_incom_task": "",
        //   "admin_service": "",
        //   "admn_deduct_service": "",
        //   "issqn_collection_id": "",
        //   "service_code_tax_id": "",
        //   "percent_of_tax": "",
        //   "not_taxable": "",
        //   "invoice_dimob": "",
        //   "is_disabled": "",
        //   "supplier": "",
        // };

      }

      let fields: string[] = Object.keys(newobj);
      // Set form values for each field using the newobj
      const inputElement = document.querySelector('.supplier_head');
    const inputElement1 = document.querySelector('.supplier');
    if (newobj?.credit == Number(4)) {
      inputElement?.removeAttribute("hidden");
      inputElement1?.removeAttribute("hidden");
    } else {
      inputElement?.setAttribute("hidden", "")
      inputElement1?.setAttribute("hidden", "")
    }
      fields.forEach((field: any) => setValue(field, newobj?.[field]));
    } else {
      // Clear singleservice data when show is false
      setSingleService([]);
    }
    if (singleservice[0]?.ID_PRODUTO_PRD == "14") {
      setAdminFee(true)
      setDebitOwner(true)
      setCreditShow(true)
    }
  }, [show, singleservicedata])

  const onSubmit = async (data: any) => {

    let newobj: any = "";
    // Check if singleservice array is empty
    if (singleservice.length < 1) {
      // If singleservice is empty, create newobj without service_id and set is_disabled to false
      newobj = {
        "service_id": "",
        "name": data?.name,
        "account_category": data?.account_category,
        "service_code_sku": data?.service_code_sku,
        "debit": data?.debit,
        "credit": data?.credit,
        "pay_incom_task": Boolean(data?.pay_incom_task),
        "admin_service": Boolean(data?.admin_service),
        "admn_deduct_service": Boolean(data?.admn_deduct_service),
        "issqn_collection_id": data?.issqn_collection_id,
        "service_code_tax_id": data?.service_code_tax_id,
        "percent_of_tax": data?.percent_of_tax,
        "not_taxable": Boolean(data?.not_taxable),
        "invoice_dimob": data?.invoice_dimob,
        "is_disabled": Boolean(false),
        "supplier": data?.supplier
      }
    } else {
      // If singleservice has a value, create newobj with the service_id and is_disabled value from singleservice
      newobj = {
        "service_id": singleservice[0]?.ID_PRODUTO_PRD,
        "name": data?.name,
        "account_category": data?.account_category,
        "service_code_sku": data?.service_code_sku,
        "debit": data?.debit,
        "credit": data?.credit,
        "pay_incom_task": Boolean(data?.pay_incom_task),
        "admin_service": Boolean(data?.admin_service),
        "admn_deduct_service": Boolean(data?.admn_deduct_service),
        "issqn_collection_id": data?.issqn_collection_id,
        "service_code_tax_id": data?.service_code_tax_id,
        "percent_of_tax": data?.percent_of_tax,
        "not_taxable": Boolean(data?.not_taxable),
        "invoice_dimob": data?.invoice_dimob,
        "is_disabled": Boolean(singleservice[0]?.FL_DESATIVADO_PRD),
        "supplier": data?.supplier
      }
    }
    // Make an API request to insert a new service using dispatch
    let response = await dispatch(insertService(newobj));
    if (response?.["payload"]?.["data"]?.["type"] == "success" || response?.["payload"]?.["data"]?.["type"] == "Success") {
      // Show a success message using Swal
      Swal.fire({
        title: `${t("Sweetalert.addedService")}`,
        text: `${t("Sweetalert.newService")}`,
        // icon: 'success','success',
        confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
      })
      formClose()
      let newdispatch = await dispatch(fetchService());
      setAddNewService(newdispatch.payload);

    } else {
      // Show an error message
      Swal.fire({
        title: `${t("Sweetalert.Error")}`,
        text: response?.["payload"]?.["data"]?.["message"],
        // icon: 'success','error',
        confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
      })
    }
  }
  //New service popup clode
  const formClose = () => {
    dispatch(ServiceShow(false))
    reset()
  }

  const handleCheckUncheck = (e: any) => {
    let name = e.target.name;
    let adminobj: {} = {
      admin_service: false,
      admn_deduct_service: false
    }
    // Check if the name is "admin_service"
    if (name == "admin_service") {
      adminobj["admin_service"] = true;
    }
    // Check if the name is "admn_deduct_service"
    if (name == "admn_deduct_service") {
      adminobj["admn_deduct_service"] = true;
    }
    // Get the list of fields from the adminobj object
    let fields: string[] = Object.keys(adminobj);
    // Loop through each field and set its value using the setValue function
    fields.forEach((field: any) => setValue(field, adminobj?.[field]));
  }

  const ChangeCredit = (e: any) => {
    const inputElement = document.querySelector('.supplier_head');
    const inputElement1 = document.querySelector('.supplier');
    if (e.target.value == 4) {
      inputElement?.removeAttribute("hidden");
      inputElement1?.removeAttribute("hidden");
    } else {
      inputElement?.setAttribute("hidden", "")
      inputElement1?.setAttribute("hidden", "")
    }
  }


  const handleService = (e: any) => {
    let value = e.target.value
    servicetaxcode.data.forEach((item: any) => {
      if (item.ID_CODIGOSERVICO_CSE == value) {
        setValue("percent_of_tax", item.VL_ALIQUOTA_CSE)
      }
    })
  }
  return (
    <Modal show={show} onHide={formClose}>
      <Modal.Header closeButton>
        <Modal.Title>{singleservice.length > 0 ? `${t("services.editService")}` : `${t("services.newService")}`}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} id="newservicetsx">
        <Modal.Body>
          <FloatingLabel className="mb-lg-4 mb-3" controlId="name" label={t("formLabel.name")+ " *"}>
            <Form.Control type="text" placeholder="Name" className={`${errors?.name ? 'is-invalid' : ''}`}  {...register('name')} />
          </FloatingLabel>
          <Row className="gy-lg-4 gy-3">
            <Col md={7}>
              <FloatingLabel className="" controlId="account" label={t("formLabel.accountCategory")+ " *"}>
                <Form.Select className={`${errors.account_category ? 'is-invalid' : ''}`}  {...register('account_category')}>
                  <option value="0">{t("formLabel.select")}</option>
                  {/* <optgroup label="1 recipes">
                    <option value="1">1.1.1 Administration fee</option>
                    <option value="2">1.1.2 IPTU administration fee</option>
                    <option value="3">1.1.3 Insurance administration fee</option>
                    <option value="4">1.1.4 Condominium management fee</option>
                    <option value="5">1.1.5 Lease agreement fee</option>
                  </optgroup> */}
                  {
                    list && list.length > 0 &&
                    list.map((item, index) => {
                      if ((item.childData).length > 0) {
                        return (
                          <optgroup key={index} label={`${item.value} ${item.label}`}>
                            {
                              item.childData && (item.childData).length > 0 &&
                              item.childData.map((firstItem, index) => {
                                if ((firstItem.childData).length > 0) {
                                  return (
                                    <>
                                      <option key={index} value={firstItem.value}>{firstItem.value} {firstItem.label}</option>
                                      {
                                        (firstItem.childData).map((seconditem, index) => {
                                          if ((seconditem.childData).length > 0) {
                                            return (
                                              <>
                                                <option key={index} value={seconditem.value}>{seconditem.value} {seconditem.label}</option>
                                                {
                                                  (seconditem.childData).map((thirditem, index) => {
                                                    if ((thirditem.childData).length > 0) {
                                                      (thirditem.childData).map((fourthitem, index) => {
                                                        return (
                                                          <>
                                                            <option key={index} value={fourthitem.value}>{fourthitem.value} {fourthitem.label}</option>
                                                          </>
                                                        )
                                                      })
                                                    } else {
                                                      return (
                                                        <>
                                                          <option key={index} value={thirditem.value}>{thirditem.value} {thirditem.label}</option>
                                                        </>
                                                      )
                                                    }
                                                  })
                                                }
                                              </>
                                            )
                                          } else {
                                            return (
                                              <option key={index} value={seconditem.value}>{seconditem.value} {seconditem.label}</option>
                                            )
                                          }
                                        })
                                      }
                                    </>
                                  )
                                } else {
                                  return (
                                    <option key={index} value={firstItem.value}>{firstItem.value} {firstItem.label}</option>
                                  )
                                }
                              })
                            }
                          </optgroup>
                        )
                      }
                    })
                  }

                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col md={5}>
              <FloatingLabel className="mb-lg-4 mb-3" controlId="service_code" label={t("formLabel.serviceCode")}>
                <Form.Control type="text" placeholder="Service code (SKU)" className={`${errors.service_code_sku ? 'is-invalid' : ''}`}  {...register('service_code_sku')} />
              </FloatingLabel>
            </Col>
          </Row>
          <Accordion className="accordion-custom">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{t("button.more")}</Accordion.Header>
              <Accordion.Body>
                <Row className="gy-3 gy-lg-4">
                  <Col md={6} hidden={debitOwner ? debitOwner : debitOwner}>
                    <FloatingLabel controlId="debit" label={t("formLabel.debit")}>
                      <Form.Select className={` ${errors.debit ? 'is-invalid' : ''}`}  {...register('debit')}>
                        <option value="">{t("formLabel.select")}</option>
                        <option value="1">{t("services.owner")}</option>
                        <option value="2">{t("services.tenant")}</option>
                        <option value="3">{t("services.Imobiliária")}</option>
                        <option value="4">{t("services.Imóvel")}</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md={6} hidden={creditShow ? creditShow : creditShow}>
                    <FloatingLabel controlId="credit" label={t("formLabel.credit")}>
                      <Form.Select className={` ${errors.credit ? 'is-invalid' : ''}`}  {...register('credit')} onChange={(e) => ChangeCredit(e)}>
                        <option value="">{t("formLabel.select")}</option>
                        <option value="1">{t("services.owner")}</option>
                        {creditShow && <option value="2">{t("services.tenant")}</option>}
                        <option value="3">{t("services.Imobiliária")}</option>
                        <option value="4">{t("services.third")}</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md={6} className={"supplier_head"} hidden>
                    <FloatingLabel controlId="supplier" className={"supplier"} label={t("formLabel.Supplier")} hidden>
                      <Form.Select className={`${errors.credit ? 'is-invalid' : ''}`}  {...register('supplier')} >
                        <option value="">{t("formLabel.select")}</option>
                        {
                          supplier &&
                          supplier?.length > 0 &&
                          supplier?.map((item, index) => {
                            return (
                              <option value={item["id"]} >{item["name"]}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <Form.Check type="checkbox" {...register('pay_incom_task')} label={t("services.payIncomeTax")} />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>{t("formLabel.transfer")}</Accordion.Header>
              <Accordion.Body>
                <span hidden={adminFee}>
                  <Form.Check type="checkbox" className="mb-3" onClick={(e) => handleCheckUncheck(e)} {...register('admin_service')} label={t("services.calculateAdministration")} />
                </span>
                <Form.Check type="checkbox" className="mb-3" onClick={(e) => handleCheckUncheck(e)} {...register('admn_deduct_service')} label={t("services.calculateAdministrationFeeBeducting")} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>{t("formLabel.taxation")}</Accordion.Header>
              <Accordion.Body>
                <Row className="gy-3 gy-lg-4">
                  <Col md={12}>
                    <FloatingLabel controlId="issqn" label={t('formLabel.ISSQNCollection')}>
                      <Form.Select className={`${errors.issqn_collection_id ? 'is-invalid' : ''}`}  {...register('issqn_collection_id')}>
                        <option value="">{t("formLabel.select")}</option>
                        <option value="1">{t("services.alwaysInMunicipality")}</option>
                        <option value="2">{t("services.outsideTheMunicipality")}</option>
                        <option value="3">{t("services.free")}</option>
                        <option value="4">{t("services.immune")}</option>
                        <option value="5">{t("services.suspendedCourtDecision")}</option>
                        <option value="6">{t("services.suspendedDueAdministrative")}</option>
                      </Form.Select>
                    </FloatingLabel>
                    <span className="fs-12 d-block ms-3 mt-1">{t("button.edit")}:<a className="text-primary">{t("services.otherCNAES")}</a></span>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel controlId="service_code" label={t("formLabel.serviceCodeTax")}>
                      <Form.Select className={`${errors.service_code_tax_id ? 'is-invalid' : ''}`}  {...register('service_code_tax_id')} onChange={(e) => handleService(e)}>
                        <option value="">{t("formLabel.select")}</option>
                        {
                          servicetaxcode &&
                          servicetaxcode["data"] &&
                          servicetaxcode["data"].length > 0 &&
                          servicetaxcode["data"].map((item, index) => {
                            return (
                              <option value={item["ID_CODIGOSERVICO_CSE"]} >{item["ST_CODIGOCNAE_CSE"]}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel controlId="percentage_taxes" label={t("formLabel.percentageOfTaxes")}>
                      <Form.Control type="text" placeholder="Percentage of taxes" className={`${errors.percent_of_tax ? 'is-invalid' : ''}`}  {...register('percent_of_tax')} />
                    </FloatingLabel>
                  </Col>
                  <Col md={12}>
                    <Form.Check type="checkbox" {...register('not_taxable')} id={`not_taxable`} label={t("formLabel.notTaxable")} />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>{t("formLabel.incomeInvoice")}</Accordion.Header>
              <Accordion.Body>
                <FloatingLabel controlId="invoice_dimob" label={t("formLabel.invoiceanddimob")}>
                  <Form.Select className={`${errors.invoice_dimob ? 'is-invalid' : ''}`}  {...register('invoice_dimob')}>
                    <option value="">{t("formLabel.select")}</option>
                    <option value="1">{t("services.notInclude")}</option>
                    <option value="2">{t("services.includeAsIncome")}</option>
                    <option value="3">{t("services.issueAnInvoice")}</option>
                    <option value="4">{t("services.includeAsWithholding")}</option>
                  </Form.Select>
                </FloatingLabel>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={formClose}>{t("button.close")}</Button>
          <Button type="submit" variant="primary" onClick={openErrorbox} >{t("button.toSave")}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
