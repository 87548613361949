import React, { useState, MouseEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ComProps, GroupForm } from '../../Interface';
import '../../../App.css';
import Swal from 'sweetalert2';
import { userService } from '../../../services/userService';
import { FormValues, UserFormValues } from './types';
// import { HITS } from '../../constants/utils';
import Checkbox from '../../Pages/Contracts/Common/Checkbox';
import { Modal, Form, Button, Row, Col, Table, Accordion, FloatingLabel, InputGroup, Dropdown, Card, Alert } from 'react-bootstrap';
import { openErrorbox } from '../../Pages/Contracts/Common/actionPerform';
import { useTranslation } from 'react-i18next';
import { encrypt_Decrypt, getAll_Default_Permission } from '../../constants/utils';
import { useNavigate } from 'react-router-dom';
import i18n from '../../../i18n';
interface DATA {
    id: any;
    first_name: string;
    last_name: string;
    email: string;
    isChecked: boolean
}
interface GROUP_DATA {
    groupId: any;
    name: string;
}


const Groups = (props) => {
    let { submenu, setGroupData, allGroups, setGroups, HITS, getAllUsers, setuserGroup } = props;
    const navigate = useNavigate();
    const [t] = useTranslation()

    const [show, setShow] = useState(false);
    const [DelGroupModel, deleteGroupModel] = useState(false);
    const [headingText, setHeadingText] = useState("");
    const [GroupFormTitle, setGroupFormTitle] = useState(t("userModule.addToGroup"));
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    const [isTicketCheck, setIsTicketCheck] = useState(false);
    // const [allGroups, setGroups] = useState<Array<DATA>>([]);
    const [groupDelData, setDeleteGroupData] = useState<GROUP_DATA>();
    const [groupRowId, setGroupId] = useState(0);
    const [checkData, setCheckData] = useState([])

    const form_validationSchema = Yup.object().shape({
        name: Yup.string().trim().nullable()
            .required(t("requiredMmessage.fieldRequired"))
    });
    var frm = { resolver: yupResolver(form_validationSchema) }
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm<GroupForm>(frm);
    const groupShow = () => {
        setShow(true);
        setGroupId(0);
        setGroupFormTitle(t("userModule.addToGroup"));
    }
    const groupClose = () => {
        reset();
        setShow(false);
    }
    const formClose = () => { setShow(false); reset(); }
    const createCheckbox = (item, index) => (
        <div key={item.key} className="mb-3 my-3">
            <div className='form-check'>
                <Checkbox key={item.key} type="checkbox" name={item.key} id={item.key} handleClick={handleClick} isChecked={isCheck ? isCheck.includes(item.key) : false} disabled={false} />
                <label htmlFor={item.key} className="form-check-label">{item.value}</label>
            </div>
        </div>
    )

    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(1000) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(HITS.map(li => li.key));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, (id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }

    };
    useEffect(() => {
        if (isCheck.length >= 20) {
            setIsCheckAll(true)
        } else {
            setIsCheckAll(false)
        }
    }, [isCheck])
    const createCheckboxes = () => (
        HITS.map(createCheckbox)
    )
    const handleTicketClick = e => {

        const { checked } = e.target;
        setIsTicketCheck(true);
        if (!checked) { setIsTicketCheck(false); }
    };

    // Delete Group 
    const closeGroupDeleteModel = () => {
        deleteGroupModel(false);
    }
    const handleGroupDeleteEvent = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const groupId = e.currentTarget.name;
        const groupName = e.currentTarget.getAttribute('data-gropname');
        const deleteGPobj = { groupId: (groupId ? groupId : 0), name: (groupName ? groupName : '') };
        setHeadingText((groupName != null) ? groupName : '');
        setDeleteGroupData(deleteGPobj);
        deleteGroupModel(true);
    }
    const deleteGroupNow = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        userService.deleteGroup(groupDelData).then(res => {
            if (res.data && res.type == "Success") {
                deleteGroupModel(false);
                Swal.fire({
                    title: `${t("Sweetalert.Success")}`,
                    text: `${t("Sweetalert.deleteRecord")}`,
                    // icon: 'success',"success",
                    confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                    timer: 2000
                });
                getAllGroups('');
                setIsCheck([]);
                getAllUsers({ userId: "" });
            }
            else {
                Swal.fire({
                    title: `${t('Sweetalert.Error')}`,
                    text: t('Sweetalert.deleteFailed'),
                    // icon: 'success',"error",
                    confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                    timer: 2000
                });
            }
            getAllGroups('');
        }).catch(error => {
            Swal.fire({
                title: `${t('Sweetalert.Error')}`,
                text: `${t('Sweetalert.somethingWent')}`,
                // icon: 'success',"error",
                confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                timer: 2000
            });
            getAllGroups({});
        });
    }
    // Update group +++
    const updateGroupEvent = (e: MouseEvent<HTMLButtonElement>) => {
        setShow(true)
        setGroupFormTitle(t("userModule.Updategroup"));
        e.preventDefault();
        const groupId = e.currentTarget.name;
        // console.log(groupId, "groupId")
        userService.getGroupData({ groupId: groupId }).then(async res => {
            if (res.data && res.data.length) {
                let groupDetails = res.data[0];
                // let groupName : any = "";
                // groupName = e.currentTarget.getAttribute('data-gropname'); 
                // Set the Form
                setValue('name', groupDetails.ST_NOME_GRPU);
                if (groupDetails.ST_ACESSOS_GRPU && groupDetails.ST_ACESSOS_GRPU != null) {
                    if ((groupDetails.ST_ACESSOS_GRPU).search('"1')) {
                        let permi = JSON.parse(groupDetails.ST_ACESSOS_GRPU);
                        let mmp: any = [];
                        await HITS.map(lis => {
                            if (permi[lis.indexKey] == 1) {
                                mmp.push(lis.key);
                            }
                        })
                        // console.log('mmp====', mmp)
                        setIsCheck(mmp);
                    } else {
                        setIsCheck(JSON.parse(groupDetails.ST_ACESSOS_GRPU));
                    }
                } else {
                    setIsCheck([]);
                }
                setIsTicketCheck(groupDetails.FL_VISIVELPARACLIENTES_GRPU);
                // console.log('groupDetails.ID_GRUPO_GRPU ', groupDetails.ID_GRUPO_GRPU)
                setGroupId(groupDetails.ID_GRUPO_GRPU);
            }
        }).catch(err => {
            console.log("err", err)
        });
    }
    // Update group

    // Get All Groups
    const getAllGroups = (groupId) => {
        let groupData = { groupId: groupId, page: 1, end: 10 }
        userService.getAllGroups(groupData).then(res => {
            if (res.data) {
                setGroups(res.data);
            }
            else { setGroups([]); }
        }).catch(err => {
            console.log("err", err)
        });
    }
	useEffect(() => {
        getAllUsers({ userId: "" });
	}, [i18n.language])
    const formSubmit = (data: GroupForm) => {
        let owner = {
            name: data.name
        }
        let msm: any = '{';
        isCheck.map((ele, index) => {
            msm += `"${ele}" : "1"`;
            if ((isCheck.length - 1) > index) {
                msm += ',';
            }
        })
        msm += '}';
        // let mms : any = [];
        // isCheck.forEach(ele => {
        //     if( ele ){
        //         mms[ele] = 1
        //     }
        // }); 

        // console.log('Group Form submit data: ::::::', data);
        let newGroup = {
            isTicketCheck: isTicketCheck,
            name: data.name,
            groupAccess: msm,
            groupId: groupRowId ? groupRowId : 0
        }
        if (isCheck.length > 0) {
            userService.createGroup(newGroup).then(res => {
                getAllGroups({});
                /*-------CHECK THE API RESPONSE--------*/
                if (res.data && res.type == "Success") {
                    formClose();
                    Swal.fire({
                        title: `${t('Sweetalert.Success')}`,
                        text: res.type == "Success" ? `${t('Sweetalert.RecordUpdated')}` : `${t('Sweetalert.insertRecord')}`,
                        // icon: 'success',"success",
                        confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                        timer: 2000
                    });
                    reset()
                    setIsCheck([]);
                    getAllUsers({ userId: "" });
                } else {
                    Swal.fire({
                        title: `${t('Sweetalert.Error')}`,
                        text: `${t('Sweetalert.cannotInsert')}`,
                        // icon: 'success',"error",
                        confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                        timer: 2000
                    });
                }

            }).catch(error => {
                Swal.fire({
                    title: `${t('Sweetalert.Error')}`,
                    text: `${t('Sweetalert.somethingWent')}`,
                    // icon: 'success',"error",
                    confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                    timer: 2000
                });
                getAllGroups({});
            });
        } else {
            Swal.fire({
                title: `${t("Sweetalert.Error")}`,
                text: `${t("Sweetalert.pleasecheckone")}`,
                // icon: 'success',"error",
                confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
            });
        }
    }
    const AllUserGroup = async () => {
        getAllUsers({ userId: "" });
        setuserGroup("user");
    }
    const UserDetails = async (id: any) => {
        let res = await userService.getAllUserGroup(id)
        setuserGroup("group");
        setGroupData(res.data)
    }

    return (
        <>
            <Card className='groupstsxTemplate'>
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <div>{t("users.userGroups")}
                    </div>
                    {
                        userJson && userJson.length > 0 && (userJson.indexOf(1001) !== -1) &&
                        <Button variant="link" type="button" onClick={groupShow} className="fw-bold p-0">{t("users.newgGroup")}</Button>
                    }
                </Card.Header>
                <Card.Body className="pb-1">
                    {/* <!-- Table start from here -->*/}
                    <Table responsive className="table table-custom table-custom-bordered mb-0">
                        <thead>
                            <tr>
                                <th>{t("label.group")}</th>
                                <th>{t("formLabel.users")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-dark-70"><a className="text-reset cursor-pe" onClick={() => AllUserGroup()}>{t("users.allusers")}</a></td>
                                <td className="text-dark-70">{(allGroups && allGroups.length) ? allGroups[0]['TotalUsers'] : '0'}</td>
                                <td className="text-end"></td>
                            </tr>
                            <tr>
                                <td className="text-dark-70">
                                    <a className="text-reset cursor-pe" onClick={() => UserDetails("nogroup")}>{t("users.usersWithoutGroup")}</a>
                                </td>
                                <td className="ttext-dark-70">{(allGroups && allGroups.length) ? allGroups[0]['UsersWithoutGroups'] : '0'}</td>
                                <td className="text-end"></td>
                            </tr>
                            {allGroups && allGroups.length > 0 && allGroups.map((group: any, index: number) => {
                                if (group && group?.["ST_NOME_GRPU"]) {
                                    return (
                                        <tr key={index}>
                                            <td className="text-dark-70"><a className="text-reset cursor-pe" onClick={(e) => UserDetails(group.ID_GRUPO_GRPU)}>{group.ST_NOME_GRPU} </a></td>
                                            <td className="ttext-dark-70">{group.UsersInGroup ? group.UsersInGroup : 0}</td>
                                            {
                                                userJson && userJson.length > 0 && (userJson.indexOf(1001) !== -1) &&
                                                <td className="text-end">
                                                    <ul className="list-inline mb-0 d-flex justify-content-end align-items-center">
                                                        <li className="list-inline-item me-2 me-xxl-4 me-lg-3">
                                                            <Button variant="link" className="p-0" data-gropname={group.ST_NOME_GRPU} name={group.ID_GRUPO_GRPU} onClick={updateGroupEvent}>
                                                                <span className="icon img-primary cursor-pe"><img className="h-20" src="./assets/images/icons/edit-light.svg" alt="Edit" /></span>
                                                            </Button>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Button variant="link" className="p-0" data-gropname={group.ST_NOME_GRPU} name={group.ID_GRUPO_GRPU} onClick={handleGroupDeleteEvent}>
                                                                <span className="icon img-primary cursor-pe"><img className="h-20" src="./assets/images/icons/delete-light.svg" alt="Delete" /></span>
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                </td>
                                            }
                                        </tr>
                                    )
                                }
                            }
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {/* <!-- /Table end --> */}
            <p className="d-block my-lg-4 my-3">{t("formLabel.listing")} {allGroups.length} {t("userModule.groups")}.</p>
            <Modal show={show} onHide={groupClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{GroupFormTitle} </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(formSubmit)}>
                    <Modal.Body>
                        <Alert variant="warning" className="fw-normal mb-lg-4 mb-3">
                            {t("users.theAccessesGroup")} <span className="fw-bold">{t("users.register")}</span> {t("users.ofusers")} <span className="fw-bold">{t("users.usersMore")}</span>.
                        </Alert>
                        <FloatingLabel controlId="floatingInput" label={t("formLabel.name")+ " *"} className="mb-3 mb-lg-4">
                            <Form.Control type="text" placeholder="Name" className={`form-control ${errors.name ? 'is-invalid' : ''}`}  {...register('name')} />
                        </FloatingLabel>
                        <span className="d-block mb-3">{t("label.access")}</span>
                        <div className="scl2">
                            <div key="selectall" className="mb-3 my-3">
                                <div className='form-check'>
                                    <Checkbox key="selectall" type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} disabled={false} />
                                    <label htmlFor="selectall" className="form-check-label">{t("selectAll")}</label>
                                </div>
                            </div>
                            {createCheckboxes()}
                        </div>
                        <Accordion className="accordion-custom">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{t("banks.moreOptions")}</Accordion.Header>
                                <Accordion.Body>
                                    <Form.Group>
                                        <Checkbox type="checkbox" name="allow_customer_open_ticket" id="allow_customer_open_ticket" handleClick={handleTicketClick} isChecked={isTicketCheck} disabled={false} />
                                        <label className="ms-2" htmlFor="fallow_customer_open_ticket">{t("users.allowCustomer")}</label>
                                    </Form.Group>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={groupClose}>{t("button.close")}</Button>
                        <Button type="submit" variant="primary" name="save" onClick={openErrorbox}>{t("button.toSave")}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* Delet ethe Group */}
            <Modal show={DelGroupModel} onHide={closeGroupDeleteModel}>
                <Modal.Header closeButton>
                    <Modal.Title>{headingText}</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        {t("users.deleteGroup")}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={closeGroupDeleteModel}>
                            {t("button.close")}
                        </Button>
                        <Button type="submit" variant="primary" name="deletegroup" onClick={deleteGroupNow} >
                            {t("button.delete")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
export default Groups;