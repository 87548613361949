import React, { useState, MouseEvent,useEffect } from 'react';
import { Outlet, Link, useNavigate, useLocation }            from 'react-router-dom';
import { useForm }                 from 'react-hook-form';
import { yupResolver }             from '@hookform/resolvers/yup';
import * as Yup                    from 'yup';
import { Row, Col, Table, Badge, Card }  from 'react-bootstrap';
import { getAllContPendingChck, getChecklist }   from "../../../../redux/slice/expenditureDataSlice";
import { AppDispatch,RootState }   from '../../../../redux/store';
import { useDispatch,useSelector } from 'react-redux';
import Loader                      from '../../../Profile/Company/Loader';
import Swal                        from 'sweetalert2';
import { useTranslation }          from 'react-i18next';
import '../../../../App.css';
import moment from 'moment';

const Pending_checklists = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch           = useDispatch<AppDispatch>()
    const [t]                = useTranslation();
    const [showlable, setShowLable] = useState({})
    const { contPeninfChck,status } = useSelector((state: RootState) => state.expenditureData)
    const {Property_Type} = useSelector((state:RootState) =>state.contractData)
    useEffect(() => {
        dispatch(getAllContPendingChck({ "start":0, "end":10 }));           
    }, [dispatch])

    const HandleChecklist = (id) =>{
      dispatch(getChecklist("checklist"))
        navigate(`/contract_details/${id}`);
    }
    
    useEffect(() => {
        if (Property_Type) {
          // Creating a dictionary from 'Property_Type' for quick lookup
          const dataDict = Property_Type.reduce((acc, item) => {
            acc[item.value] = item.label;
            return acc;
          }, {});
      
          setShowLable(dataDict);
        }
      }, [Property_Type]);
    
    return (
        <section className="content-wrapper" id="template_pending_checklist">
            {/* <!-- Breadcrumb section start from here --> */}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 5, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/contracts"}><img src="./assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3">
                                    <h1 className="mb-0 h4 lh-base text-truncate">{t("pChecklists")}</h1>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <Link to={"/checklists"} className="fw-bold">
                                        <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />{t("tchecklists")}
                                    </Link>
                                </li>
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                </li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/* <!-- Table start from here --> */}
                {
                    (status == "loading") ?
                        <Loader />
                    :
                    <Card>
                        <Card.Body className="pb-1">
                        <Table responsive className="table-custom table-custom-bordered mb-0">
                        <thead>
                            <tr>
                                <th className="w-20">{t("start")}</th>
                                <th>{t("contract")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { contPeninfChck.data && contPeninfChck.data.length > 0 ? contPeninfChck.data.map((item) => {
                                 const label = showlable[item.types];
                                return (
                                <tr key={item.id}>
                                    <td className="text-dark-70">{moment(item.create).format('DD/MM/YYYY')  }</td>
                                    <td>
                                        <span className="d-block text-dark-70">   {t(`${(item.type).toLowerCase()}`)} 1/ {item.id}</span>
                                        <span className="d-block">
                                            <img src="assets/images/icons/home_g.svg" />
                                            <span className="ps-1">
                                                {label} {t("in")} {item.address}, {item.city},{item.state} - {item.zipcode}
                                            </span>
                                            </span>
                                        <span className="d-block">
                                            <img src="assets/images/icons/user_g.svg" />
                                            <span className="ps-1">
                                                { item.neighborhood ? item.neighborhood : '?'}
                                            </span>
                                        </span>
                                        <span className="d-block">
                                            <img src="assets/images/icons/key_g.svg" />
                                            <span className="ps-1">
                                                { item.complement ? item.complement : '?' }
                                            </span>
                                        </span>
                                    </td>
                                    <td className="text-end text-dark">
                                        <Badge bg="light" className="text-dark me-2 me-xxl-4 me-lg-3 cursor-pe" onClick={(e) =>HandleChecklist(item.id)}> {item.pendingItems} {t("pendingitems")} </Badge>
                                        {/* <Badge bg="warning-10" className="text-dark cursor-pe">{item.awaitingApproval} {t("awaitingapproval")}</Badge> */}
                                    </td>
                                </tr>
                                )})
                              : 
                                <tr>
                                    <td colSpan={3}>
                                        <span>{t('formLabel.noRecordsFounds')}</span>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                        </Card.Body>
                    </Card>
                }
                {/*  <!-- /Table end --> */}

                <p className="d-block py-2">{t('formLabel.listing')} { contPeninfChck.totalRow && contPeninfChck.totalRow > 0 ? contPeninfChck.totalRow : 0 } {contPeninfChck.totalRow && contPeninfChck.totalRow == 0 || contPeninfChck.totalRow > 1 ? `${t('property.records')}`: `${t('property.record')}`}.</p>
            </main>
            {/* <!-- /Main content start end --> */}
        </section>
    )
}
export default Pending_checklists;