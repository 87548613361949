import {
  Form,
  Accordion,
  Modal,
  Alert,
  Button,
  Row,
  Col,
  FloatingLabel,
  InputGroup,
  Card,
} from "react-bootstrap";
import {
  reInsuranceTp,
  insertRenewInsurance,
  contractDatails,
  insuranceOpen,
  getInsurance,
  renewInsuranceClose,
  getTenants,
  getProperty,
  getOwner,
  updateContractInsurancePolicy
} from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import { contractForm } from "../../../Interface";
import Swal from "sweetalert2";
import "../../../../App.css";
import moment from "moment";
import { getValue } from "@testing-library/user-event/dist/utils";
import InsuranceService from "./InsuranceService";
interface Coverage {
  value: number;
  text: string;
  // Define properties of Coverage
}
interface tenantes {
  ID_PESSOA_PES: string;
  // Define properties of Coverage
}
interface InsuranceRecord {
  Id: number;
  Name: string;
  Total: number;
  Installments: number;
  TotalCalculatedByInstallments: number;
  Coverages: Coverage[]; // Ensure Coverages property is defined
  end2: string;
  start2: string;
  insEndLbl: string;
  immobile: string;
  rent_value: number;
  datatenants: tenantes[];

  // Add other properties if needed
}
const RenewInsuranceService = (contractForm: any) => {
  const { newRenewInsuranceShow, insuranceData, status } = useSelector(
    (state: RootState) => state.contractData
  );
  const [double, setDouble] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [t] = useTranslation();
  const [issValue, setIssValue] = useState(0);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(-1);
  const [contractDetails, setContractDetails] = useState<InsuranceRecord[]>([]);
  const [quoteId, setQuoteId] = useState(0);
  const [contractStartData, setContractStartDate] = useState('');
  const [contractEndDate, setContractEndDate] = useState('');

  useEffect(() => {
    if (newRenewInsuranceShow) {
      dispatch(getInsurance({ insurance: "", start: 0, end: 1000 }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (newRenewInsuranceShow) {
      getContractsData(id);
    }
  }, [newRenewInsuranceShow]);

  const getContractsData = async (id: any) => {
    const fields: string[] = [
      "id",
      "start",
      "end",
      "assuredValue",
      "prizeAmount",
      "installmentValue",
      "numberInstallments2",
      "insuranceCompany",
      "policy2",
      "contractedPlan",
      "responsible4",
      "observation4",
    ];
    if (typeof id !== "undefined" && id) {
      let data = await dispatch(contractDatails(Number(id)));
      if (data.payload.data.length > 0) {
        setContractDetails(data.payload.data);
        setIssValue(data.payload.data[0]["isContract"]);
        fields.forEach((field: any) =>
          setValue(field, data.payload.data[0][field])
        );
      }
    }
  };
  const newRenewInsuranceShowClose = () => {
    dispatch(renewInsuranceClose(false));
    reset();
    getContractsData(id);
  };
  const newInsuranceOpen = (evt) => {
    dispatch(reInsuranceTp(evt.target.title));
    dispatch(insuranceOpen(true));
    dispatch(renewInsuranceClose(false));
  };
  const renewInsuranceValidation = Yup.object().shape({});
  const renewInsuranceValidation1 = Yup.object().shape({
    start: Yup.string()
      .trim()
      .nullable()
      .required(t("requiredMmessage.fieldRequired")),
    end: Yup.string()
      .trim()
      .nullable()
      .required(t("requiredMmessage.fieldRequired")),

    assuredValue: Yup.string()
      .trim()
      .nullable()
      .required(t("requiredMmessage.fieldRequired")),

    insuranceCompany: Yup.string()
      .trim()
      .nullable()
      .required(t("requiredMmessage.fieldRequired")),
    // policy2: Yup.string()
    //   .trim()
    //   .nullable()
    //   .required(t("requiredMmessage.fieldRequired")),
    contractedPlan: Yup.string()
      .trim()
      .nullable()
      .required(t("requiredMmessage.fieldRequired")),
    responsible4: Yup.string()
      .trim()
      .nullable()
      .required(t("requiredMmessage.fieldRequired")),
  });
  let frm = {
    resolver: yupResolver(renewInsuranceValidation1),
  };
  if (isCheckboxChecked == true) {
    frm = {
      resolver: yupResolver(renewInsuranceValidation),
    };
  }
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<contractForm>(frm);
  const onSubmit = async (data: contractForm, e: any) => {
    Swal.fire({
      title: "Confirmation",
      text: "Are you sure you want to Renew this Insurance?",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if(data.insuranceCompany == "Tokio Marine"){
        const response = await fetch(
          "https://api-seguros.dev.kenlo.io/insurance_api/legacy/auth/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              agency_license: "adm055927",
              loginvv: "lenilda.santos@ingaia.com.br",
              login: "apiv2@gmail.com.br",
            }),
          }
        );
        const accessData = await response.json();
        if (accessData.access_token != "") {
          let contractData = await dispatch(contractDatails(Number(id)));
          const property_id = contractData.payload.data[0].immobile;

          const fetchPropertyDetails = await dispatch(
            getProperty({
              id: property_id,
              contract: "contract",
              start: 0,
              end: 1,
            })
          );

          const propertyDetails = fetchPropertyDetails.payload.data[0];
          const ownerId = propertyDetails.ownerResult[0].ownerId;
          const fetchOwnerDetails = await dispatch(getOwner());
          const ownerDetails = fetchOwnerDetails.payload.data.filter(
            (owner: { id: any }) => owner.id === ownerId
          );
          const fetchTenantsDetails = await dispatch(getTenants());
          const tenantIdString =
            contractData.payload.data[0].datatenants.tenates[0].ID_PESSOA_PES;
          const tenantId = parseInt(tenantIdString);
          const tenantDetails = fetchTenantsDetails.payload.data.filter(
            (tenant: { id: any }) => {
              return tenant.id === tenantId;
            }
          );
          const insuranceResponse = await fetch(
            "https://api-seguros.dev.kenlo.io/insurance_api/legacy/properties/contract?apiKey=9e798944154b13a83e62cf96588cfe7f'",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `${accessData.access_token}`,
              },
              body: JSON.stringify({
                property: {
                  state: propertyDetails.state,
                  city: propertyDetails.city,
                  address_complement: "",
                  address: propertyDetails.address,
                  address_number: propertyDetails.number,
                  address_neighbourhood: propertyDetails.neighborhood,
                  cep: propertyDetails.zipcode,
                },
                contract_id: "1854",
                policy_duration: {
                  effective_date_end: contractStartData,
                  effective_date_start: contractEndDate,
                },
                agency_license: "adm055927",
                CONTRATACAO_AUTOMATICA: 0,
                tenant: {
                  phone: tenantDetails[0].mobile,
                  birth_date: "",
                  name: tenantDetails[0].name,
                  gender: "M",
                  email: tenantDetails[0].email,
                  foreign: "",
                  document_data: {
                    emission_date: "",
                    document_nature: "",
                    document_number: tenantDetails[0].cnpj,
                  },
                  politically_exposed: "",
                  document_number: "746.624.300-20",
                  person_type: "Fisica",
                },
                property_owner: {
                  birth_date: ownerDetails.birth,
                  email: ownerDetails.email,
                  document_number: ownerDetails.cnpj,
                  name: ownerDetails.name,
                  phone: ownerDetails.mobile,
                  person_type: ownerDetails.fantasy,
                },
                user_email: "insurance-team@ingaia.com.br",
                insurance: {
                  property_id: propertyDetails.id,
                  renewal: "",
                  main_coverage: 150000,
                  zip_code: propertyDetails.zipcode,
                  property_type: propertyDetails.propertyType,
                  property_group_id: "1",
                },
                quote_id: quoteId,
              }),
            }
          );
          const insuranceData = await insuranceResponse.json();
          setValue('policy2',insuranceData.insurance.insurance_policy_number)
          if(getValues('policy2') !=''){
            let contractId = data.id;
            let policyNumber =insuranceData.insurance.insurance_policy_number;
            e.preventDefault();
            setDouble(true);
            let response = await dispatch(insertRenewInsurance(data));
            
            if (
            typeof response.payload !== "undefined" &&
            (response.payload.resCode === 202 || response.payload.resCode === 200)
            ) {
                await dispatch(updateContractInsurancePolicy({contractId,policyNumber}));
            setDouble(false);
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: t("Sweetalert.successget"),
                confirmButtonText: "OK",
                timer: 2000,
            });
            newRenewInsuranceShowClose();
            } else {
            setDouble(false);
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000,
            });
            }
          }
        }
      }else{
        setDouble(true);
        let response = await dispatch(insertRenewInsurance(data));
        
        if (
        typeof response.payload !== "undefined" &&
        (response.payload.resCode === 202 || response.payload.resCode === 200)
        ) {
        setDouble(false);
        Swal.fire({
            // icon: 'success','success',
            title: t("Sweetalert.Success"),
            text: t("Sweetalert.successget"),
            confirmButtonText: "OK",
            timer: 2000,
        });
        newRenewInsuranceShowClose();
        } else {
        setDouble(false);
        Swal.fire({
            title: t("Sweetalert.Error"),
            text: t("Sweetalert.somethingWent"),
            // icon: 'success',"error",
            confirmButtonText: "OK",
            timer: 2000,
        });
        }
      }
        // User clicked confirm button
        // You can put your code for the action you want to perform here
        //   let data = await dispatch(cancelInsuranceRequest(contractId));
        //   if(data.payload.resCode == '201'){
        //     window.location.reload();
        //   }else{
        //     Swal.fire({
        //         title: t("Sweetalert.Error"),
        //         text: t("Sweetalert.somethingWent"),
        //         // icon: 'success',"error",
        //         confirmButtonText: "OK",
        //         timer: 2000,
        //       });
        //   }
        //Swal.fire("Success", "Your action was successful!", "success");
      } else {
        // User clicked cancel button or closed the popup
        //Swal.fire("Cancelled", "Your action was cancelled", "error");
      }
    });

    return;
    
  };
  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
    reset();
  };
  const handleInsurance = async (index: any, record: any) => {
    
    setClickedIndex(index);
    const insuranceEndDate = moment(contractDetails[0].insEndLbl,'DD/MM/YYYY').format('YYYY-MM-DD')
    if(contractDetails[0].insEndLbl !=''){
        var contractEndDateEarlier = insuranceEndDate;
    }else{
        var contractEndDateEarlier = contractDetails[0].start2;
    }
    let assuredValue = getValues('assuredValue');
    if(assuredValue == ''){
        let calculateRent = (contractDetails[0].rent_value*100);
        setValue('assuredValue',calculateRent.toString())
    }
    const contractStartDate = new Date(contractEndDateEarlier); // Convert string to Date object
    contractStartDate.setDate(contractStartDate.getDate() + 1);

    const contractEndDate = new Date(contractStartDate); // Create a copy of contractStartDate

    // Add 12 months to the contract end date
    contractEndDate.setMonth(contractEndDate.getMonth() + 12);

    const contractStartDateISOString = contractStartDate
      .toISOString()
      .split("T")[0];
    const contractEndDateString = contractEndDate.toISOString().split("T")[0];
    setValue("start", contractStartDateISOString);
    setValue("end", contractEndDateString);
    
    setValue("installmentValue", record.TotalCalculatedByInstallments);
    setValue("prizeAmount", record.LastInstallment);
    setValue("contractedPlan", record.Name);
    setQuoteId(record.RequestId);
    setContractStartDate(contractStartDateISOString)
    setContractEndDate(contractEndDateString)
    setValue('numberInstallments2',record.Installments)
  };
  return (
    <>
      {/* <!-- New renew insurance modal start from here --> */}
      <Modal show={newRenewInsuranceShow} onHide={newRenewInsuranceShowClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("renewInsurance")}  </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Check className="mb-lg-4 mb-3" checked={isCheckboxChecked} type="checkbox"  {...register('isContract')} label={t("Doesnothaveinsurance")} onChange={handleCheckboxChange} /> */}
            <input type="hidden" {...register("id")} />
            <Row
              className={`gy-3 gy-lg-4 mb-lg-4 mb-3 ${
                isCheckboxChecked ? "d-none" : ""
              }`}
            >
              {contractForm.insuranceQuotes &&
                contractForm.insuranceQuotes.map((record, index) => (
                  <Col md={6}>
                    <Card
                      className={`border border-2 text-center ${
                        clickedIndex === index ? "border-primary" : ""
                      }`}
                      onClick={() => handleInsurance(index, record)}
                      style={{ cursor: "pointer" }}
                    >
                      <Card.Body className="p-lg-4">
                        <h4 className="mb-4">{t(record.Name)}</h4>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/temp/tokio_icon.png"
                          }
                          alt="tokio icon"
                          className="img-fluid mx-auto d-block"
                        />
                        <span className="d-block text-secondary fs-12">
                          {t("newContractDev.inCollaborationKenloInsurance")}
                        </span>
                        <div className="fw-bold my-4">
                          {record.Installments}x{" "}
                          <span className="h3 lh-1">
                            R$ {record.TotalCalculatedByInstallments}
                          </span>{" "}
                          <span className="fs-12 text-secondary d-block mt-1 ms-2">
                            R$ {record.Total} {t("newContractDev.inTotal")}
                          </span>
                        </div>
                        <div className="d-flex justify-content-center text-start">
                          <ul className="list-unstyled">
                            {record.Coverages.map((coverage, idx) => (
                              <li className="mb-3" key={idx}>
                                {coverage.value === 0 ? (
                                  <img
                                    title="contractInsurance"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/cross-w.svg"
                                    }
                                    alt="cross icon"
                                    className="h-16 cursor-pe me-lg-2 me-1"
                                  />
                                ) : (
                                  <img
                                    title="contractInsurance"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/check.svg"
                                    }
                                    alt="cross icon"
                                    className="h-16 cursor-pe me-lg-2 me-1"
                                  />
                                )}
                                {t(coverage.text)}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <span className="d-block text-dark-70 fs-12">
                          {t(
                            "newContractDev.insuranceAutomaticallyContractedActivated"
                          )}
                        </span>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>
            <Row
              className={`gy-3 gy-lg-4 mb-lg-4 mb-3 ${
                isCheckboxChecked ? "d-none" : ""
              }`}
            >
              <Col md={6}>
                <FloatingLabel controlId="floatingInput" label={t("start") + " *"}>
                  <Form.Control
                    type="date"
                    placeholder={t("start")}
                    className={`form-control ${
                      errors.start ? "is-invalid" : ""
                    }`}
                    {...register("start")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.start?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel controlId="floatingInput" label={t("end")+ " *"}>
                  <Form.Control
                    type="date"
                    placeholder={t("end")}
                    className={`form-control ${errors.end ? "is-invalid" : ""}`}
                    {...register("end")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.end?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6} style={{ display: "none" }}>
                <FloatingLabel
                  controlId="floatingInput"
                  label={t("installmentValue")+ " *"}
                >
                  <Form.Control
                    type="text"
                    placeholder={t("installmentValue")}
                    className={`form-control ${
                      errors.installmentValue ? "is-invalid" : ""
                    }`}
                    {...register("installmentValue")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.installmentValue?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6} style={{ display: "none" }}>
                <FloatingLabel
                  controlId="floatingInput"
                  label={t("numberInstallments")}
                >
                  <Form.Control
                    type="text"
                    placeholder={t("numberInstallments")+ " *"}
                    className={`form-control ${
                      errors.numberInstallments2 ? "is-invalid" : ""
                    }`}
                    {...register("numberInstallments2")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.numberInstallments2?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="floatingInput"
                  label={t("assuredValue")+ " *"}
                >
                  <Form.Control
                    type="text"
                    placeholder={t("assuredValue")}
                    className={`form-control ${
                      errors.assuredValue ? "is-invalid" : ""
                    }`}
                    {...register("assuredValue")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.assuredValue?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6} style={{ display: "none" }}>
                <FloatingLabel
                  controlId="floatingInput"
                  label={t("prizeAmount")}
                >
                  <Form.Control
                    type="text"
                    placeholder={t("prizeAmount") + " *"}
                    className={`form-control ${
                      errors.prizeAmount ? "is-invalid" : ""
                    }`}
                    {...register("prizeAmount")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.prizeAmount?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <InputGroup>
                  <FloatingLabel
                    controlId="floatingInput"
                    label={t("insuranceCompany")+ " *"}
                  >
                    <Form.Select
                      className={`form-control ${
                        errors.insuranceCompany ? "is-invalid" : ""
                      }`}
                      {...register("insuranceCompany")}
                    >
                      <option value="">{t("dropdownItem.select")}..</option>
                      {insuranceData.data && insuranceData.data.length > 0 ? (
                        insuranceData.data.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))
                      ) : (
                        <option value="">{t("reportSystem.NoRecords")}</option>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.insuranceCompany?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <InputGroup.Text onClick={newInsuranceOpen}>
                    <img
                      title="reNewInsurance"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/documents-add-light.svg"
                      }
                      alt="Documents add"
                      className="h-20 cursor-pe"
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md={6} style={{ display: "none" }}>
                <FloatingLabel controlId="floatingInput" label={t("policy")+ " *"}>
                  <Form.Control
                    type="text"
                    placeholder={t("policy")+ " *"}
                    className={`form-control ${
                      errors.policy2 ? "is-invalid" : ""
                    }`}
                    {...register("policy2")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.policy2?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label={t("contractedPlan")+ " *"}
                >
                  <Form.Control
                    type="text"
                    placeholder={t("contractedPlan")}
                    className={`form-control ${
                      errors.contractedPlan ? "is-invalid" : ""
                    }`}
                    {...register("contractedPlan")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contractedPlan?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="floatingSelect"
                  label={t("responsible")+ " *"}
                >
                  <Form.Select
                    className={`form-control ${
                      errors.responsible4 ? "is-invalid" : ""
                    }`}
                    {...register("responsible4")}
                    aria-label="Responsible"
                  >
                    <option value="">{t("dropdownItem.select")}</option>
                    <option value="1">{t("realEstate")}</option>
                    <option value="2">{t("Dashboard.Tenant")}</option>
                    <option value="3">{t("Dashboard.Owner")}</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.responsible4?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <FloatingLabel
              controlId="floatingTextarea2"
              label={t("formLabel.observation")}
            >
              <Form.Control
                as="textarea"
                placeholder="Observation"
                {...register("observation4")}
                style={{ height: "100px" }}
              />
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={newRenewInsuranceShowClose}
            >
              {t("button.close")}
            </Button>
            <Button disabled={double} type="submit" variant="primary">
              {t("button.save")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* <!-- /New renew insurance modal end --> */}
      <InsuranceService />
    </>
  );
};
export default RenewInsuranceService;
