import React, { useState, useEffect, MouseEvent } from 'react';
import { Table, Form, Button, Badge, ProgressBar, Tooltip, OverlayTrigger, Card } from 'react-bootstrap';
import NewServiceNote from './NewServiceNote';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { userService } from '../../../../services/userService';
import Loader from '../../../Profile/Company/Loader';
import Checkbox from '../../../Pages/Contracts/Common/Checkbox';
import eventBus from "../ReceivableCharges/EventBus";
import { incomeService } from '../../../../services/incomeServices';
import { getBraches } from '../../../../redux/slice/branchSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { DownloadsPdf } from '../../../../redux/slice/taxationSlice';
import { converter } from '../../../constants/utils';
interface DATA {
    id: any;
    first_name: string;
    last_name: string;
    email: string;
    isChecked: boolean
}

const InvoicesListing = ({ params, getInvioceList }) => {
    //New charges modal initialization
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const [showNewServiceNoteModal, setNewServiceNoteModal] = useState(false);
    const newServiceNoteModalClose = () => setNewServiceNoteModal(false);
    const newServiceNoteModalShow = () => setNewServiceNoteModal(true);
    const [allInvoices, setInvoices] = useState<Array<DATA>>([]);
    const [getSingleInvoice, setGetSingleInvoice] = useState<any>([])
    const [tenantData, setTenantData] = useState('')
    // const [ chargesList,setAllInvoices ] = useState<any>([]);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [status, setStatus] = useState("");
    const [branchState, setBranchState] = useState({
        start: "0",
        end: "100",
    });
 
    const updateRecord = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const charge_Id = e.currentTarget.name;
        setNewServiceNoteModal(true);
        eventBus.dispatch("chargeIdis", charge_Id);
    }

    // Get All Invoices
    const getAllInvoices = (param) => {
        let groupData = { groupId: param.invoiceId, page: 1, end: 10 }
        userService.getAllGroups(groupData).then(res => {
            if (res?.data) { setInvoices(res?.data); }
            else { setInvoices([]) }
            setStatus("");
        }).catch(err => {
            setStatus("");
        });
    }

    useEffect(() => {
        // Dispatch the action to get branches when the component mounts
        dispatch(getBraches(branchState))
    }, [dispatch]);

    const [clientData, setClientData] = useState<any>([])
    // Get Invoices
    useEffect(() => {
        let compData: any = '';
        if (params) {
            compData = params ? JSON?.parse(params) : '';
        }
        if (compData.action == 'selectall') {
            selectAllRows();
        } else if (compData.action == 'unselectall') {
            unSelectAllRows();
        } else if (compData.action == 'change_expiration') {
            //console.log('Change Expiration Funtion with API')
        } else {
            setStatus("loading");
            getAllInvoices({ invoiceId: '' });
        }
    
    }, []);

    useEffect(()=>{
        (async () => {
            let GetClientData = await incomeService?.getClients();
            if(GetClientData?.data?.clients.length>0){
                setClientData(GetClientData.data.clients)
            }
        })()
    },[])
    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(getInvioceList?.map(li => li.ID_NOTA_NOT));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const handleClick = (e) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
        }
        if (document.querySelectorAll(".check_Checkbox input").length > 0) {
            let data = document.querySelectorAll(".check_Checkbox input");

            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++;
                    }
                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                });
                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10);
        }
    };
    const selectAllRows = () => {
        setInvoices(allInvoices?.map(topping => ({ ...topping, isChecked: true })))
    }
    const unSelectAllRows = () => {
        setInvoices(allInvoices?.map(topping => ({ ...topping, isChecked: false })))
    }
    // Check / Uncheck Single row event.
    const selectRow = async (e) => {
        setInvoices([])
        await allInvoices?.map(data => {
            if (data && parseInt(e.target.value) === data?.id) {
                data['isChecked'] = !data.isChecked ? true : false;
            }
            return data;
        });
        await setInvoices(allInvoices);
    }

    function convertDateFormat(inputDateString) {
        if (!inputDateString) {
            return ""; // Handle null or undefined case, or return a default value
        }

        const originalDate = new Date(inputDateString);

        // Check if the date is valid
        if (isNaN(originalDate.getTime())) {
            return "Invalid Date";
        }

        const month = originalDate.getMonth() + 1;
        const day = originalDate.getDate();
        const year = originalDate.getFullYear();

        // Create the formatted date string
        const formattedDateString = `${day}/${month}/${year}`;

        return formattedDateString;
    }

    const DownloadPdf = async (id) => {
        let res: any = await dispatch(DownloadsPdf(id))

        if (!res.payload.ok) {
            throw new Error(`HTTP error! Status: ${res.payload.status}`);
        }
        // Create a Blob from the response data
        const blob = await res.payload.blob();
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);
        // Create a link element and trigger a click to download the file
        const a = document.createElement("a");
        a.href = url;
        a.download = `downloaded_${id}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    };


    const EditInvoice = (id) => {
        setNewServiceNoteModal(true)
        let invoice = {
            invoice_id: id
        }
        incomeService.EditInvoice(invoice).then((res) => {
            if (res.type == "success" || res.type == "Success") {
                setGetSingleInvoice(res.data)
            }
        })
    }
   
    return (
        <>
       
            {
                (status == "loading") ?
                    <Loader />
                    :
                    <Card>
                    <Card.Body className="pb-1">
                    <Table responsive className="table-custom table-custom-bordered mb-0">
                        <thead>
                            <tr>
                                <th className="w-24"><Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} /></th>
                                <th>{t("Invoices.issuance")}</th>
                                <th>{t("Invoices.client")}</th>
                                <th>{t("Invoices.value")}</th>
                                <th>{t("Invoices.Pdf")}</th>
                                <th className="text-end"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {getInvioceList.length > 0 ?
                                getInvioceList &&
                                getInvioceList?.map((invoice: any, index) => {
                                    let typeName = clientData?.filter(items => Number(items.ID_SACADO_SAC) === Number(invoice.ID_CLIENTE_NOT));
                                    return (
                                        <tr key={index}>
                                            <td className={"check_Checkbox"}>
                                                <Checkbox
                                                    key={index}
                                                    type="checkbox"
                                                    name={invoice.ID_NOTA_NOT}
                                                    id={invoice.ID_NOTA_NOT}
                                                    handleClick={handleClick}
                                                    isChecked={isCheck.includes(invoice.ID_NOTA_NOT)}
                                                />
                                            </td>
                                            <td>{convertDateFormat(invoice.DT_EMISSAO_NOT)}</td>
                                            <td>
                                                <span className="d-block"><img src="assets/images/icons/user_g.svg" />{invoice.ID_SACADO_SAC !== null ? <span className="ps-1"> {invoice.ID_SACADO_SAC}  {t("Dashboard.Contract")} {invoice.ST_IDENTIFICADOR_IMO}/1 </span> : <span className="ps-1"> {invoice.ID_CLIENTE_NOT} - {typeName.length > 0 ? typeName[0]?.ST_NOME_SAC : ""}</span>}</span>
                                                <span className="d-block mb-1">Cobrança {invoice.ID_RECEBIMENTO_RECB}</span>
                                                {invoice.ID_PLUGNOTAS_ID || invoice.ID_NFELOTE_NOT ? <div className="w-75"><ProgressBar className="h-10 w-75" variant="success" now={100} /></div> : <div className="w-75"><ProgressBar className="h-10 w-75" variant="warning" now={45} /></div>}
                                                {/* <div className="w-75"><ProgressBar className="h-10 w-75" variant="warning" now={45} /></div> */}
                                            </td>
                                            <td>
                                               <span className="d-block text-nowrap">R$ {converter(`${invoice.VL_VALOR_NOT}`)}</span>
                                            </td>
                                            <td>{invoice.ID_PLUGNOTAS_ID !== "" ?
                                               <span className="img-primary cursor-pe icon" onClick={(e) => DownloadPdf(invoice.ID_PLUGNOTAS_ID)}><img src="assets/images/icons/download-arrow.svg" alt="exclamation" className="h-18"/></span>
                                                :
                                                invoice.ID_NFELOTE_NOT !== null ?
                                                <span className="img-primary cursor-pe icon" onClick={(e) => DownloadPdf(invoice.ID_NFELOTE_NOT)}><img src="assets/images/icons/download-arrow.svg" alt="exclamation" className="h-18" /></span>

                                                    :
                                                    ""
                                            }</td>
                                            <td className="text-end">
                                               <div className="d-flex justify-content-end">
                                               {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("Invoices.exclamation")}</Tooltip>}>
                                                <span className="cursor-pe icon"><img src="assets/images/icons/triangle-exclamation.svg" alt="exclamation" className="h-16" /></span>
                                                </OverlayTrigger> */}
                                               </div>
                                                {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("button.edit")}</Tooltip>}>
                                                <Button variant="link" className="p-0">
                                                    <img src="assets/images/icons/edit-light.svg" alt="Edit" className="cursor-pe h-16 me-2" onClick={(e) => EditInvoice(invoice.ID_NOTA_NOT)} />
                                                </Button>
                                            </OverlayTrigger> */}
                                                <NewServiceNote showServiceNote={showNewServiceNoteModal} serviceNoteShow={newServiceNoteModalShow} serviceNoteClose={newServiceNoteModalClose} getSingleInvoice={getSingleInvoice} />
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={9} className="text-center">{t("NoRecords")}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    </Card.Body>
                </Card>
            }
        </>
    )
}

export default InvoicesListing
