import React, { useEffect, useState } from 'react';
import { Outlet, Link, useParams } from 'react-router-dom';
import { Form, Modal, Button, Row, Col, FloatingLabel, Card, Badge, Alert, Offcanvas } from 'react-bootstrap';
import CommonToast from './CommonToast';
import SuccessMessage from './SuccessMessage';
import { getAllBillMethodListing, getAllContractCharge } from '../../../../../../redux/slice/expenditureDataSlice';
import { AppDispatch, RootState } from '../../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { set, useForm } from 'react-hook-form';
import { Termination } from '../../../../../Interface';
import Swal from 'sweetalert2';
import customerService from '../../../../../../services/customerService';
import { contractDatails, getAllExpense, insertExtraCharge } from '../../../../../../redux/slice/contractDataSlice';
import { Text_Decoder, convertToNumber, converter, formatNumber, minDate } from '../../../../../constants/utils';
import { gettoken } from '../../../../../../services/apiConfig';
import moment from "moment-timezone";
import i18n from '../../../../../../i18n';
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

const TerminationCalculationBilling = (props) => {
    const { showTerminationCalculationModal, setTerminationCalculationModal, terminationCalculationModalClose, terminationCalculationShow, ShowThisData, terminationResp, allresponsibleList } = props;
    const [t] = useTranslation();
    const { id }: any = useParams();
    const dispatch = useDispatch<AppDispatch>()
    const [statusOfChecklist, setStatusOfChecklist] = useState(1);
    const [aarinConfig, setAarinConfig] = useState<any>("1");
    const [aarinSuppConfig, setaarinSuppConfig] = useState<any>("1");
    const typeValue = "termination_calculation_XYZ";
    const [securityDepositRule, setsecurityDepositRule] = useState(false);
    const [responsiblevalue, setResponsibleValue] = useState('')
    const [chargevalue, setChargeValue] = useState('')
    const [totalVal, setTotalVal] = useState('')
    const [charge, setcharge] = useState<any>([{
        service: "",
        complement: "",
        total_amount: "",
        proportional_amount: "",
        credit: "",
        admin_fee: "",
        status: 1
    }]);

    const [safeinstallment, setsafeinstallment] = useState<any>([
        {
            due_date: "",
            total: "",
        }
    ]);
    const [installment, setinstallment] = useState<any>([
        {
            due_date: "",
            total: "",
        }
    ]);

    const [loader_on, setloader_on] = useState(false);
    const [typeSubmit, settypeSubmit] = useState("draft");
    const validationSchema = Yup.object().shape({
        responsible: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
            .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
        limit_date: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
            .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
        responsible_party_name: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
            .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
        terminate_moveout: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
            .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
        porpoortional_day: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
            .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
        charge_due_date: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
            .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
        penalty: Yup.object().shape({
            service: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
                .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
            // complement: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
            // value: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
            credit: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
                .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
            admin_fee: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
                .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
        }),

        // charge: Yup.array().of(Yup.object().shape({
        //     service: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        //     // complement: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        //     total_amount: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        //     // proportional_amount: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        //     credit: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        //     admin_fee: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        // })),

        // fine: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        // fee: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        // discount: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        total_amount: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
            .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
        billing_method: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
            .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
        // deduct_security: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        // security_deposit: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        number_parcels: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
            .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),

        // installment: Yup.array().of(Yup.object().shape({
        //     due_date: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        //     total: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        // })),

        // observation_first: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        // observation_second: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
    });

    const validationSchema1 = Yup.object().shape({
        responsible: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
            .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
        responsible_party_name: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
            .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
        limit_date: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
            .test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
    });
    var frm = {
        resolver: yupResolver(validationSchema1),
    };
    if (typeSubmit === "done") {
        frm = {
            resolver: yupResolver(validationSchema),
        };
    }
    const { register, handleSubmit, reset, getValues, setValue, setError, watch, clearErrors, formState: { errors } } = useForm<Termination>(frm);

    const { billingData } = useSelector((state: RootState) => state.expenditureData);
    const { contractDatail, serviceData } = useSelector((state: RootState) => state.contractData);
    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M').format("MMM/YYYY");
    let searchDt = moment(newDate).format("MMM/YYYY")
    let endDt = moment(newDate).add(1, 'M').format("MMM/YYYY");
    const [valueCon, setValueCon] = useState('');
    const [state, setState] = useState({
        id: id,
        startDate: startDt,
        searchDate: searchDt,
        endDate: endDt,
        type: "",
        expense: "",
        start: 0,
        end: 1000
    })
    const [stateCharge, setStateCharge] = useState({
        id: id,
        startDate: startDt,
        searchDate: searchDt,
        endDate: endDt,
        type: "",
        start: 0,
        end: 1000
    })

    useEffect(() => {
        if (showTerminationCalculationModal) {
            dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
        }
    }, [dispatch]);
    //Termination calculation billing modal close open function

    useEffect(() => {
        setValue('bankAccount', contractDatail[0].bank_account);
        setValue('propertyId', contractDatail[0].propertyId);
        setValue('tenant', contractDatail[0].sacadoId);
        setValue('branch', contractDatail[0].branch);
    }, [contractDatail])

    //Common toast
    const [showToast, setToastShow] = useState(false);
    const commonToastClose = () => setToastShow(false);
    const commonToastShow = () => {
        setToastShow(true);
        setTimeout(() => {
            setToastShow(false);
        }, 2000);
    }

    //Delete service modal initialization
    const [showDeleteServiceModal, setDeleteServiceModal] = useState(false);
    const deleteServiceModalClose = () => {
        setDeleteServiceModal(false);
        terminationCalculationShow(true)
    }


    //View calculation modal initialization
    const [showViewCalculationModal, setViewCalculationModal] = useState(false);
    const viewCalculationModalClose = () => {
        setViewCalculationModal(false);
        setTerminationCalculationModal(true)
    }
    const viewCalculationModalShow = () => {
        setViewCalculationModal(true);
        setTerminationCalculationModal(false)
    }
    //Alert
    const [showAlert, setAlertShow] = useState(true);

    //Success modal initialization
    const [showSuccessModal, setSuccessModal] = useState(false);
    const successModalClose = () => setSuccessModal(false);
    const successModalShow = () => {
        setSuccessModal(true);
        terminationCalculationModalClose(false);
    }

    let [showResponsible, setShowResponsible] = useState([]);
    const handleResponsible = (e, rep_id, type) => {
        setValue("responsible_party_name", "");
        let id: any = 0;
        let arrayChange: any = [];
        if (type == "form") {
            id = Number(e.target.value);
        } else {
            id = Number(e);
        }
        if (id !== "") {
            if (id == 1) {
                arrayChange = allresponsibleList[0]["agency"];
            }

            if (id == 2) {
                arrayChange = allresponsibleList[0]["owner"]
            }

            if (id == 3) {
                arrayChange = allresponsibleList[0]["tenent"]
            }

            if (id == 4) {
                arrayChange = allresponsibleList[0]["manager"]
            }

            if (id == 5) {
                arrayChange = allresponsibleList?.[0]?.["gurantor"] ?? allresponsibleList?.[0]?.["guarantor"]
            }
        }

        if (type == "load") {
            setTimeout(() => {
                setValue("responsible_party_name", rep_id);
            }, 10)
        }

        if (type == "form") {
            if (arrayChange.length > 0 && arrayChange.length < 2) {
                setTimeout(() => {
                    setValue("responsible_party_name", arrayChange[0].value);
                }, 100)
            } else {
                setTimeout(() => {
                    setValue("responsible_party_name", rep_id);
                }, 100)
            }
        }

        setShowResponsible(arrayChange);
    }

    const [calculationValue, setCalculationValue] = useState({
        rent_charge: 0,
        month_remain: 0,
        duration: 0,
        penalty_amt: 0
    })

    const [rentalPeroidValue, setrentalPeriodValue] = useState<any>(0);
    const [runOncee, setrunOnce] = useState(true);
    const [runOnChange, setRunOnChange] = useState(true);
    const [delete_all_remaining_expense, setdelete_all_remaining_expense] = useState([]);
    const [exemption, setExemption] = useState<any>({
        type: "",
        fine_free_month_after: "",
        till_now_month: "",
        fine_status: ""
    });

    useEffect(() => {
        if (showTerminationCalculationModal && runOncee) {
            reset();
            (async () => {

                setValue("responsible", "1");
                setShowResponsible(allresponsibleList[0]["agency"]);
                let dataObj = {
                    type: typeValue,
                    contract_id: id
                };

                const maturityDate: any = new Date(contractDatail?.[0]?.["end2"].split("T")[0]); // maturity Date
                // Getting this month total date
                const todayDate: any = new Date(dateBrazil);
                const year = todayDate.getFullYear();
                const month1 = todayDate.getMonth() + 1;
                const lastDay = new Date(year, month1, 0);
                const totalDaysInMonth = lastDay.getDate();
                const month = todayDate.getMonth() + 2;

                // const date = moment(`${contractDatail?.[0]["due_date"]}`);
                // const day: any = date.date();

                let fullDate: any = `${year}-${month}-${contractDatail?.[0]["due_date"]}`;
                let securityDeposit: any = contractDatail[0]["guarantee"];
                if (securityDeposit == 2 || securityDeposit == "2") {
                    setsecurityDepositRule(true);
                    setValue("security_deposit", contractDatail[0]["value"])
                } else {
                    setsecurityDepositRule(false);
                }

                // moment(contractDatail?.[0]?.["termination_list"]?.[0]?.["DT_MOVEOUT_TRM"])

                let returnPorpotionalDay = calculatePorpotionalDay()
                console.log(returnPorpotionalDay, "This is return valueeeeeeee");

                //////// ------- OLD
                // const maturityDate1: any = new Date(`${year}-${month1}-${contractDatail?.[0]["due_date"]}`);
                // const moveOut: any = new Date(moment(contractDatail?.[0]?.["termination_list"]?.[0]?.["DT_MOVEOUT_TRM"]).format("YYYY-MM-DD"));

                // const differenceInMilliseconds = todayDate - maturityDate1;
                // const differenceInMilliseconds = moveOut - maturityDate1;
                // let proportionalDay: any = Math.floor(differenceInMilliseconds / (24 * 60 * 60 * 1000));
                //////// ------- OLD

                let proportionalDay: any = (returnPorpotionalDay["advance"]["day"] && returnPorpotionalDay["advance"]["day"] > 0) ? Number(`-${returnPorpotionalDay["advance"]["day"]}`) : Number(returnPorpotionalDay["payment"]["day"]);

                // if (proportionalDay < 1) {
                //     proportionalDay = 0;
                // }

                let chargeDueDate: any = new Date(dateBrazil);
                if (todayDate < maturityDate) {
                    const month = todayDate.getMonth() + 2;
                    const year = todayDate.getFullYear();
                    chargeDueDate = moment(fullDate.split("T")[0]).format("YYYY-MM-DD");
                } else {
                    chargeDueDate = moment(contractDatail?.[0]?.["termination_date"].split("T")[0]).format("YYYY-MM-DD");
                }

                let startDate = new Date(contractDatail?.[0]?.["start2"].split("T")[0]);
                const monthsDiff = (todayDate.getFullYear() - startDate.getFullYear()) * 12 + (todayDate.getMonth() - startDate.getMonth());

                let Exemption_Current_Status = contractDatail?.[0]?.["exemption_termination"];
                if (Exemption_Current_Status == 0 || Exemption_Current_Status == "0") {
                    exemption["type"] = "no_exemption";
                    exemption["fine_free_month_after"] = String(12);
                    exemption["till_now_month"] = String(monthsDiff);
                }

                if (Exemption_Current_Status == 1 || Exemption_Current_Status == "1") {
                    exemption["type"] = "free";
                    exemption["fine_free_month_after"] = "";
                    exemption["till_now_month"] = String(monthsDiff);
                }

                if (Exemption_Current_Status == 2 || Exemption_Current_Status == "2") {
                    exemption["type"] = "exemption";
                    exemption["fine_free_month_after"] = String(contractDatail?.[0]?.["fineExemption"]);
                    exemption["till_now_month"] = String(monthsDiff);
                }

                // Start rental period
                // if Rental period with closed month is checked from contract edit then this part start
                let rentalPeriod: any = contractDatail?.[0]?.["lease_period"];
                setrentalPeriodValue(rentalPeriod);
                // if (rentalPeriod > 0) {
                //     let thismonth_lastDay: any = new Date(moment().endOf('month').format("YYYY-MM-DD"));
                //     const differenceInMilliseconds = thismonth_lastDay - todayDate;
                //     let proportionalDay1: any = Math.floor(differenceInMilliseconds / (24 * 60 * 60 * 1000));
                //     if (proportionalDay1 < 1) {
                //         proportionalDay = 0;
                //     } else {

                //         // --------- Start old calculation ------
                //         // let thismonth_startDay: any = new Date(moment().format("YYYY-MM-01"));
                //         // const differenceInMilliseconds = todayDate - thismonth_startDay;
                //         // let proportionalDay1: any = Math.floor(differenceInMilliseconds / (24 * 60 * 60 * 1000)) + 1;
                //         // --------- End  old  calculation ------
                //         let proportionalDay1: any = moment(todayDate).format("DD");
                //         proportionalDay = Number(proportionalDay1);
                //     }
                // }
                // End rental period

                ///Default if not set
                setValue("terminate_moveout", moment(contractDatail?.[0]?.["termination_date"].split("T")[0]).format("YYYY-MM-DD"));
                setValue("porpoortional_day", Number(`${proportionalDay}`));

                // setValue("charge_due_date", chargeDueDate);
                setTimeout(() => {
                    calculation_view();
                }, 100)

                let dataresp = await customerService.getAllExpense({
                    userId: gettoken.userId,
                    id: id,
                    startDate: moment(new Date(dateBrazil)).format("YYYY-MM-DD"),
                    searchDate: moment(new Date(dateBrazil)).format("YYYY-MM-DD"),
                    endDate: "undefined",
                    type: "",
                    expense: "",
                    start: 0,
                    end: 1000
                })

                let dataObj1 = {
                    type: "modify_recurring_XYZ",
                    contract_id: id
                };

                let modifyrecuring: any = await customerService.get_terminationDefaultTerminationList(dataObj1)
                    .then(async (res) => {
                        if (res["data"]["type"] == "success" || res["data"]["type"] == "Success") {
                            return res["data"]?.["data"]?.[0];
                        }
                    });

                let storeAllRemainExpense: any = [];
                let allcharges: any = [];
                let allInstallment: any = [];
                if (dataresp?.["data"] && dataresp?.["data"]?.["data"].length > 0) {
                    dataresp?.["data"]?.["data"].forEach((item: any, index: number) => {
                        if (item["type"] !== "New recurring expense") {
                            storeAllRemainExpense.push(item["id"]);
                            let getValuePorportional = Number(item["value"].includes(",") ? Number(item["value"].replace(",", "")) : Number(item["value"])) / Number(totalDaysInMonth) * Number(proportionalDay);
                            allcharges.push({
                                service: item["serviceId"],
                                complement: item["complement"],
                                total_amount: item["value"].includes(",") ? Number(item["value"].replace(",", "")) : Number(item["value"]),
                                proportional_amount: getValuePorportional.toFixed(2),
                                credit: item["creditId"],
                                admin_fee: item["isCalAdmin"] === "true" ? 1 : 0,
                                status: 1
                            })
                        }
                    })
                    setdelete_all_remaining_expense(storeAllRemainExpense);
                    setcharge(allcharges);
                }

                let countIncCharge = 0;
                allcharges && allcharges.length > 0 &&
                    allcharges.forEach((item: any, index: number) => {
                        let getValuePorportional = Number(item["total_amount"]) / Number(totalDaysInMonth) * Number(proportionalDay);
                        setValue(`charge.${index}.service`, item?.["service"]);
                        setValue(`charge.${index}.complement`, item?.["complement"]);
                        setValue(`charge.${index}.total_amount`, item["total_amount"]);
                        setValue(`charge.${index}.proportional_amount`, String(getValuePorportional.toFixed(2)));
                        setValue(`charge.${index}.credit`, item?.["credit"]);
                        setValue(`charge.${index}.admin_fee`, item?.["admin_fee"]);
                        setValue(`charge.${index}.status`, item?.["status"]);
                        countIncCharge = countIncCharge + 1;
                    })

                if (modifyrecuring && String(modifyrecuring?.["ST_STATUS_TERPOP"]) !== "1" && modifyrecuring?.["modify_recurring_condos_fee"] && modifyrecuring?.["modify_recurring_condos_fee"].length > 0) {
                    modifyrecuring?.["modify_recurring_condos_fee"].forEach((item: any, index: number) => {
                        let getValuePorportional = Number(item["TX_TOTAL_AMOUNT_TERSERCAL"]) / Number(totalDaysInMonth) * Number(proportionalDay);
                        setValue(`charge.${countIncCharge}.service`, item?.["SL_SERVICE_TERSERCAL"] ?? "");
                        setValue(`charge.${countIncCharge}.complement`, item?.["TX_COMPLEMENT_TERSERCAL"] ?? "");
                        setValue(`charge.${countIncCharge}.total_amount`, item?.["TX_TOTAL_AMOUNT_TERSERCAL"] ?? 0);
                        setValue(`charge.${countIncCharge}.proportional_amount`, String(getValuePorportional.toFixed(2)));
                        setValue(`charge.${countIncCharge}.credit`, item?.["SL_CREDIT_TERSERCAL"]);
                        setValue(`charge.${countIncCharge}.admin_fee`, item?.["admin_fee"] ?? "");
                        setValue(`charge.${countIncCharge}.status`, item?.["status"] ?? 1);

                        allcharges.push({
                            service: item["SL_SERVICE_TERSERCAL"] ?? "",
                            complement: item["TX_COMPLEMENT_TERSERCAL"],
                            total_amount: item["TX_TOTAL_AMOUNT_TERSERCAL"].includes(",") ? Number(item["TX_TOTAL_AMOUNT_TERSERCAL"].replace(",", "")) : Number(item["TX_TOTAL_AMOUNT_TERSERCAL"]),
                            proportional_amount: getValuePorportional.toFixed(2),
                            credit: item["SL_CREDIT_TERSERCAL"],
                            admin_fee: item["isCalAdmin"] === "true" ? 1 : 0,
                            status: 1
                        })
                        setcharge(allcharges);
                        countIncCharge = countIncCharge + 1;
                    })
                }

                let additional_deduction_on_off = contractDatail?.[0]?.["different_interest"];
                let getNOdes: any = document.querySelectorAll(".additionFee_deduct");

                if (additional_deduction_on_off !== "0" && additional_deduction_on_off !== "") {
                    // getNOdes.forEach((item) => {
                    //     item.setAttribute("disabled", "disabled");
                    //     item.setAttribute("readOnly", "readOnly");
                    // })

                    setValue("fine", contractDatail?.[0]?.["trafficTicket"]);
                    setValue("fee", contractDatail?.[0]?.["fees"]);
                    setValue("discount", contractDatail?.[0]?.["punctuality"]);
                }

                customerService.get_terminationDefaultTerminationList(dataObj).then(async (res) => {
                    if (res["data"]["type"] == "success" || res["data"]["type"] == "Success") {
                        let post = res["data"]["data"][0];
                        if (post !== undefined) {
                            reset();
                            const runaccordingly = async () => {
                                setcharge([
                                    {
                                        service: "",
                                        complement: "",
                                        total_amount: "",
                                        proportional_amount: "",
                                        credit: "",
                                        admin_fee: "",
                                        status: 1
                                    }
                                ])
                                setinstallment([{
                                    due_date: "",
                                    total: ""
                                }]);
                                return true;
                            }

                            await runaccordingly().then(async (res) => {
                                let bolbdecode = await Text_Decoder(post["TA_FIRST_OBSERVATION_TERPOP"]?.["data"]);
                                let bolbdecode1 = await Text_Decoder(post["TA_SECOND_OBSERVATION_TERPOP"]?.["data"]);

                                setRunOnChange(false);

                                // setValue("id", post?.["ID_TERMINATIONPOPUP_TERPOP"]);
                                setValue("responsible", post?.["SL_RESPONSIBLE_TERPOP"]);
                                setValue("limit_date", post?.["DT_LIMITDATE_TERPOP"] ? moment(post?.["DT_LIMITDATE_TERPOP"].split("T")[0]).format("YYYY-MM-DD") : "");
                                setValue("observation_first", bolbdecode);
                                setValue("observation_second", bolbdecode1);
                                setStatusOfChecklist(String(post?.["ST_STATUS_TERPOP"]) == "0" ? Number(post?.["ST_STATUS_TERPOP"]) : 1);

                                setValue("termination_calculation_id", post?.["termination_calculation"]?.["ID_TERMINATION_CALCULATION_TERCAL"])
                                setValue("terminate_moveout", moment(contractDatail?.[0]?.["termination_date"].split("T")[0]).format("YYYY-MM-DD") ?? moment(post?.["termination_calculation"]?.["DT_TERMINATION_DATE_TERCAL"].split("T")[0]).format("YYYY-MM-DD"));
                                setValue("porpoortional_day", post?.["termination_calculation"]?.["TX_PORPOTIONAL_DAY_TERCAL"] && post?.["termination_calculation"]?.["TX_PORPOTIONAL_DAY_TERCAL"] !== "NaN" ? Number(post?.["termination_calculation"]?.["TX_PORPOTIONAL_DAY_TERCAL"]) : 0);
                                setValue("charge_due_date", post?.["termination_calculation"]?.["DT_CHARGE_DUE_TERCAL"] !== undefined ? moment(post?.["termination_calculation"]?.["DT_CHARGE_DUE_TERCAL"].split("T")[0]).format("YYYY-MM-DD") : chargeDueDate);
                                //Penalty
                                setValue("penalty.service", post?.["termination_calculation"]?.["SL_PENALITY_SERVICE_TERCAL"] ?? 1);
                                setValue("penalty.complement", post?.["termination_calculation"]?.["TX_PENALITY_COMPLEMENT_TERCAL"]);
                                setValue("penalty.value", post?.["termination_calculation"]?.["TX_PENALITY_VALUES_TERCAL"] !== 0 ? converter(`${post?.["termination_calculation"]?.["TX_PENALITY_VALUES_TERCAL"]}`) : converter(`${calculationValue?.["penalty_amt"]}`));
                                setValueCon(post?.["termination_calculation"]?.["TX_PENALITY_VALUES_TERCAL"] !== 0 ? post?.["termination_calculation"]?.["TX_PENALITY_VALUES_TERCAL"] : converter(calculationValue?.["penalty_amt"]));
                                setValue("penalty.credit", post?.["termination_calculation"]?.["SL_PENALITY_CREDIT_TERCAL"]);
                                setValue("penalty.admin_fee", post?.["termination_calculation"]?.["CB_PENALITY_ADMIN_FEE_TERCAL"] ? "1" : "0");

                                setValue("fine", post?.["termination_calculation"]?.["TX_AD_FINE_TERCAL"]);
                                setValue("fee", post?.["termination_calculation"]?.["TX_AD_FEE_TERCAL"]);
                                setValue("discount", post?.["termination_calculation"]?.["TX_AD_DISCOUNT_TERCAL"]);

                                // setValue("total_amount", post?.["termination_calculation"]?.["TX_CHARGE_TOTAL_AMOUNT_TERCAL"]);
                                setValue("billing_method", post?.["termination_calculation"]?.["SL_CHARGE_BILLING_METHOD_TERCAL"]);
                                setValue("deduct_security", post?.["termination_calculation"]?.["CB_CHARGE_DEDUCTION_TERCAL"]);
                                setValue("security_deposit", post?.["termination_calculation"]?.["TX_CHARGE_DEPOSIT_TERCAL"]);

                                let allchargeLoop: any = [];
                                if (post?.["termination_service_calculation"].length > 0) {
                                    post?.["termination_service_calculation"].forEach((item: any, index: number) => {
                                        if (item["TX_TYPE_TERCAL"] == "charge_calculation_XYZ") {
                                            allchargeLoop.push({
                                                service: item?.["SL_SERVICE_TERSERCAL"],
                                                complement: item?.["TX_COMPLEMENT_TERSERCAL"],
                                                total_amount: converter(`${item?.["TX_TOTAL_AMOUNT_TERSERCAL"]}`),
                                                proportional_amount: Number(item?.["TX_PORPOTIONAL_TERSERCAL"]).toFixed(2),
                                                credit: item?.["SL_CREDIT_TERSERCAL"],
                                                admin_fee: String(item?.["CB_ADMIN_FEE_TERSERCAL"]) === "0" ? 0 : 1,
                                                status: "1"
                                            });
                                        }
                                    })
                                    setcharge(allchargeLoop);
                                }

                                setTimeout(() => {
                                    allchargeLoop && allchargeLoop.length > 0 &&
                                        allchargeLoop.forEach((item: any, index: number) => {
                                            setValue(`charge.${index}.service`, item?.["service"]);
                                            setValue(`charge.${index}.complement`, item?.["complement"]);
                                            setValue(`charge.${index}.total_amount`, item?.["total_amount"]);
                                            setValue(`charge.${index}.proportional_amount`, converter(`${item?.["proportional_amount"]}`));
                                            setValue(`charge.${index}.credit`, item?.["credit"]);
                                            setValue(`charge.${index}.admin_fee`, item?.["admin_fee"]);
                                            setValue(`charge.${index}.status`, item?.["status"]);
                                        })
                                }, 500)


                                let allinstallmentLoop: any = [];
                                if (post?.["termination_installment_calculation"] && post?.["termination_installment_calculation"].length > 0) {
                                    post?.["termination_installment_calculation"].forEach((item: any, index: number) => {
                                        allinstallmentLoop.push({
                                            due_date: item?.["DT_DUE_DATE_TERINSCAL"],
                                            total: item?.["TX_TOTAL_TERINSCAL"]
                                        });
                                    })
                                    setsafeinstallment(allinstallmentLoop);
                                    setinstallment(allinstallmentLoop);
                                }

                                setTimeout(() => {
                                    // allinstallmentLoop && allinstallmentLoop.length > 0 &&
                                    //     allinstallmentLoop.forEach((item, index) => {
                                    //         const date = moment(`${item?.["DT_DUE_DATE_TERINSCAL"] || 0}`);
                                    //         const day: any = date.date();
                                    //         setValue(`installment.${index}.due_date`, item?.["DT_DUE_DATE_TERINSCAL"] ? moment(item?.["DT_DUE_DATE_TERINSCAL"].split("T")[0]).format("YYYY-MM-DD") : allInstallment?.[index]?.["due_date"]);
                                    //        setValue(`installment.${index}.total`, item?.["TX_TOTAL_TERINSCAL"] ? item?.["TX_TOTAL_TERINSCAL"] : allInstallment?.[index]?.["total"]);
                                    //     })
                                    allinstallmentLoop && allinstallmentLoop.length > 0 &&
                                        allinstallmentLoop.forEach((item: any, index: number) => {
                                            setValue(`installment.${index}.due_date`, item?.["DT_DUE_DATE_TERINSCAL"] ? moment(item?.["DT_DUE_DATE_TERINSCAL"].split("T")[0]).format("YYYY-MM-DD") : allInstallment?.[index]?.["due_date"]);
                                            setValue(`installment.${index}.total`, item?.["TX_TOTAL_TERINSCAL"] ? item?.["TX_TOTAL_TERINSCAL"] : allInstallment?.[index]?.["total"]);
                                        })
                                    setValue("number_parcels", post?.["termination_calculation"]?.["TX_NUMBER_OF_PARCELS_TERCAL"] ?? allInstallment.length);
                                }, 100)

                                handleResponsible(post?.["SL_RESPONSIBLE_TERPOP"], post?.["SL_RESPONSIBLE_PARTY_NAME_TERPOP"] ?? "", "load")
                                // if (post?.["ST_STATUS_TERPOP"] !== 1 && post?.["ST_STATUS_TERPOP"] !== undefined) {
                                //     document.querySelectorAll('.DisableThis').forEach((item: any, index: number) => {
                                //         item.setAttribute("disabled", "");
                                //         item.setAttribute("read-only", "");
                                //     });

                                //     document.querySelectorAll('.DisableThis input').forEach((item: any, index: number) => {
                                //         item.setAttribute("disabled", "");
                                //         item.setAttribute("read-only", "");
                                //     })
                                // }
                                setrunOnce(false);
                            })
                        }
                    }
                })
                // .then(res => {
                //     finalCalculation("11113334");
                // })
            })()
        }
        finalCalculation("11113334");
    }, [showTerminationCalculationModal]);

    useEffect(() => {
        setTimeout(() => {
            finalCalculation("200");
        }, 10)
    }, [charge, watch("penalty.value"), watch("penalty.credit"), watch("charge"), watch("security_deposit")])

    const [propotionalPay_date, setpropotionalPay_date] = useState({
        advance: {
            day: 0,
            pay_to_tenant: true
        },
        payment: {
            day: 0,
            pay_to_tenant: false
        },
    })

    const calculatePorpotionalDay = () => {

        // ! -- info
        // contract_form_billing_method_value = 0 -> expired Month Or 1 -> Month Due
        // rent_period_with_complete_month = 0 -> unchecked Or 1 -> checked

        let propotionalPay: any = {
            advance: {
                day: 0,
                pay_to_tenant: true
            },
            payment: {
                day: 0,
                pay_to_tenant: false
            },
        }

        if (contractDatail && contractDatail.length > 0) {
            let contractDetails: any = contractDatail?.[0] || [];
            let rent_period_with_complete_month = contractDetails["lease_period"];
            let contract_billing_Data = contractDetails["paymentMethod"];
            let contract_billing = contract_billing_Data ? "month_due" : "exipre_month";

            let dueDate: any = moment(moment().format(`YYYY-MM-${contractDetails["due_date"] && String(contractDetails["due_date"]).length > 1 ? contractDetails["due_date"] : "0" + contractDetails["due_date"]}`));
            let todayDate: any = moment();
            let moveOut: any = moment(contractDatail?.[0]?.["termination_list"]?.[0]?.["DT_MOVEOUT_TRM"]);
            let contract_start_date: any = moment(contractDatail?.[0]?.["start2"]);

            // rent_period_with_complete_month = true and contrahct_form_billing_method_value = month_due

            // 2 case:
            // contract start date : 05 /06 / 2024
            // due date: 10
            // rental period checked
            // moveout: 25 /08
            // billing method: month due

            // 05 - 30 /06(paid on 10 /06)
            // 01 - 30 /07(paid on 10 /07)
            // 01 - 28 /08(paid on 10 /08) -> (need to return money ) -> 3 days(if end of month date is 31);

            if (rent_period_with_complete_month && contract_billing == "month_due") {
                // Payment not done till due date
                if (moveOut.isSameOrAfter(todayDate, 'day')) {
                    // One month payment in advance paid before due date - it already did advance payment so need to return
                    let todayDate1 = moment().endOf("month");
                    let dayDifference = todayDate1.diff(moveOut, 'days');
                    propotionalPay["advance"]["day"] = dayDifference;
                    setpropotionalPay_date(propotionalPay);
                }
            }

            // !rent_period_with_complete_month and contract_form_billing_method_value = month_due

            // // 2 case:
            // >>>>>>>> // contract start date : 01/06/2024
            // >>>>>>>> // due date : 05
            // >>>>>>>> // rental period unchecked
            // >>>>>>>> // moveout : 28/08
            // >>>>>>>> // billing method: month due

            // >>>>>>>> // 01/06 - 31/06 (paid on 05/06) -> 27 day rent pay in particular month
            // >>>>>>>> // 01/07 - 31/07 (paid on 05/07)
            // >>>>>>>> // 01/08 - 31/08  - 31/8 (paid on 05/08) -> (need to pay rent of 0 day only)

            // if (!rent_period_with_complete_month && contract_billing == "month_due") {
            //     let contract_start_day = contract_start_date.format("DD");
            //     let todayDate_day = moment().format("DD");
            //     let fetch_contract_start_day = moment(moment().format(`YYYY-MM-${contract_start_day}`)); // Contract Start day
            //     let end_of_rent_this_month = moment();
            //     if (Number(todayDate_day) > Number(contract_start_day)) {
            //         end_of_rent_this_month = moment(moment().add(1, "month").format(`YYYY-MM-${contract_start_day}`));
            //     } else {
            //         end_of_rent_this_month = moment(moment().format(`YYYY-MM-${contract_start_day}`));
            //     }

            //     end_of_rent_this_month = moment(end_of_rent_this_month);
            //     fetch_contract_start_day = moment(fetch_contract_start_day);

            //     if (moveOut.isSameOrAfter(todayDate, 'day')) {
            //         //     if (!today_with_duedate["day"]) {
            //         let todayDate1 = end_of_rent_this_month;
            //         let dayDifference = todayDate1.diff(moveOut, 'days');
            //         propotionalPay["advance"]["day"] = dayDifference;
            //         setpropotionalPay_date(propotionalPay);
            //     }
            // }

            // 2 case:
            // contract start date : "2024-07-01"
            // due date : 10
            // rental period unchecked
            // moveout : 27/08
            // billing method: month_due

            // 01/07 - 10/07 (paid on 01/07) 10 day rent will be paid first
            // 10/07 - 10/08 (paid on 10/07) 30 day rent paid
            // 11/08 - 27/08 -- 10/09 (proprotional day) -> 14 owner need to give money to tenent 14 day

            if (!rent_period_with_complete_month && contract_billing == "month_due") {
                if (moveOut.isSameOrAfter(todayDate, 'day')) {
                    let formateMOveout = moment(contractDatail?.[0]?.["termination_list"]?.[0]?.["DT_MOVEOUT_TRM"]);
                    let formatIt = moment(formateMOveout.add(1, "months").format(`YYYY-MM-${contractDetails["due_date"] && String(contractDetails["due_date"]).length > 1 ? contractDetails["due_date"] : "0" + contractDetails["due_date"]}`));
                    let dayDifference = formatIt.diff(moveOut, "days");
                    propotionalPay["advance"]["day"] = dayDifference + 1;
                }
            }

            // rent_period_with_complete_month and contract_form_billing_method_value = expire_month

            // 1 case:
            // contract start date : 14/06/2024
            // due date : 05
            // rental period checked
            // moveout : 25/08
            // billing method: expired month

            // 14-30 /06 (paid on 30/06)
            // 01-30 /07 (paid on 30/07)
            // 01-25 /08 (proprotional day) -> 25
            if (rent_period_with_complete_month && contract_billing == "exipre_month") {
                // Payment not done till due date
                if (moveOut.isSameOrAfter(todayDate, 'day')) {
                    let todayDate1 = moment().startOf("month");
                    let dayDifference = moveOut.diff(todayDate1, 'days');
                    propotionalPay["payment"]["day"] = dayDifference;
                    setpropotionalPay_date(propotionalPay);
                }
            }

            // !rent_period_with_complete_month and contract_form_billing_method_value = expire_month

            // >>>> old // 1 case:
            // >>>> old // contract start date : "2024-08-16"
            // >>>> old // due date : 05
            // >>>> old // rental period unchecked
            // >>>> old // moveout : 31/08
            // >>>> old // billing method: expired month

            // >>>> old // 16/06 - 15/07 (paid on 30/06) 25 day rent will be paid first
            // >>>> old // 16/07 - 15/08 (paid on 30/07) 
            // >>>> old // 16/08 - 31/08 (proprotional day) -> 15 tenant need to give money related to 15 day

            // if (!rent_period_with_complete_month && contract_billing == "exipre_month") {
            //     // contract_start_date
            //     let contract_start_day = contract_start_date.format("DD"); // 16-02-2024
            //     let todayDate_day = moment().format("DD"); // today date day
            //     let fetch_contract_start_day = moment(moment().format(`YYYY-MM-${contract_start_day}`)); // Contract Start day  // 16-08-2024
            //     let end_of_rental_month = moment();
            //     if (Number(todayDate_day) > Number(contract_start_day)) {
            //         end_of_rental_month = moment(moment().add(1, "month").format(`YYYY-MM-${contract_start_day}`));
            //     } else {
            //         end_of_rental_month = moment(moment().format(`YYYY-MM-${contract_start_day}`));
            //     }

            //     end_of_rental_month = moment(end_of_rental_month);
            //     fetch_contract_start_day = moment(fetch_contract_start_day);

            //     if (moveOut.isAfter(fetch_contract_start_day) && moveOut.isSameOrAfter(todayDate, 'day')) {
            //     let todayDate1 = fetch_contract_start_day;
            //     let dayDifference = moveOut.diff(todayDate1, 'days');
            //     propotionalPay["payment"]["day"] = dayDifference;
            //     setpropotionalPay_date(propotionalPay);
            //     }
            // }

            // 1 case:
            // contract start date : "2024-07-01"
            // due date : 10
            // rental period unchecked
            // moveout : 27/08
            // billing method: expired month

            // 01/07 - 10/07 (paid on 10/07) 10 day rent will be paid first
            // 10/07 - 10/08 (paid on 10/08) 
            // 11/08 - 27/08 (proprotional day) -> 17 tenant need to give money related to 17 day

            if (!rent_period_with_complete_month && contract_billing == "exipre_month") {
                if (moveOut.isSameOrAfter(todayDate, 'day')) {
                    let dayDifference = moveOut.diff(dueDate, "days");
                    propotionalPay["payment"]["day"] = dayDifference;
                }
            }

            // console.log({
            //     contract_start_date: contract_start_date.format("YYYY-MM-DD"),
            //     moveOut: moveOut.format("YYYY-MM-DD"),
            //     dueDate: dueDate.format("YYYY-MM-DD"),
            // })
        }
        return propotionalPay;
    }

    const handleAddDedCount = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setValue(name, Number(value));
        if (Number(value) > 100) {
            setValue(name, 100);
        }

        if (Number(value) < 1) {
            setValue(name, "");
        }

        setTimeout(() => {
            finalCalculation("300");
        }, 15)
    }

    // Getting this month total date
    const todayDate: any = new Date(dateBrazil);
    const year = todayDate.getFullYear();
    const month1 = todayDate.getMonth() + 1;
    const lastDay = new Date(year, month1, 0);
    const totalDaysInMonth = lastDay.getDate();

    const finalCalculation = (type: any) => {
        let See_Percent_or_fixedAmount = contractDatail?.[0]?.["fixed_amount"];
        let admin_fee_amount: number = 0;
        if (See_Percent_or_fixedAmount !== "") {
            //Admin fee is on fixed admin_fee_amount
            admin_fee_amount = Number(contractDatail?.[0]?.["monthlyAdmin"]);
        } else {
            //Admin fee is on percent
            let getRent = Number(contractDatail?.[0]?.["rent_value"]);
            let getPercentage = Number(contractDatail?.[0]?.["monthlyAdmin"]);
            admin_fee_amount = Number(getRent * getPercentage / 100);
        }

        let amt2: any = getValues("penalty.value") ? getValues("penalty.value") : 0;
        let creditData = getValues("penalty.credit") ? getValues("penalty.credit") : "";

        let get_penalty_amount: number = Number(convertToNumber(`${amt2}`));
        if (creditData == "2") {
            get_penalty_amount = (0 - get_penalty_amount);
        }

        let get_penalty_admin_fee: number = Number(getValues("penalty.admin_fee"));
        if (get_penalty_admin_fee && creditData !== "2") {
            get_penalty_amount = Number(get_penalty_amount) + Number(admin_fee_amount);
        } else if (get_penalty_admin_fee && creditData === "2") {
            get_penalty_amount = 0 - Number(get_penalty_amount) - Number(admin_fee_amount);
        }

        let Proportional = getValues('porpoortional_day');
        let allSumofcharge: number = 0;

        charge && charge.length > 0 &&
            charge.forEach((item: any, index: number) => {
                if (item["status"] == 1) {
                    let currentAmount: any = String(item["total_amount"]);
                    if (currentAmount) {
                        currentAmount = convertToNumber(`${currentAmount}`);
                    }
                    let getValuePorportional = Number(currentAmount) / Number(totalDaysInMonth) * Number(Proportional && Proportional !== "NaN" ? Proportional : 0);
                    if (item["proportional_amount"] !== "" || item["poramount"]) {
                        getValuePorportional = item["proportional_amount"];
                    }

                    if (item["proportional_amount"] === "") {
                        getValuePorportional = convertToNumber("0")
                    }

                    // if (item.admin_fee) {
                    //     allSumofcharge = allSumofcharge + Number(currentAmount) + Number(getValuePorportional) + Number(admin_fee_amount)
                    // } else {

                    let sumData = 0;
                    if (item["credit"] == "2") {
                        let currentAndProp = Number(currentAmount) + Number(getValuePorportional);
                        allSumofcharge = allSumofcharge - currentAndProp
                    } else {
                        allSumofcharge = allSumofcharge + Number(currentAmount) + Number(getValuePorportional)
                    }
                    // allSumofcharge = sumData
                    // }
                }
            })

        let final_amount = Number(allSumofcharge) + Number(get_penalty_amount);
        if (Boolean(getValues("deduct_security"))) {
            final_amount = Number(allSumofcharge) + Number(get_penalty_amount) - Number(getValues("security_deposit") !== undefined && getValues("security_deposit") !== "undefined" && getValues("security_deposit") !== "" ? Number(getValues("security_deposit")) : 0);
        } else {
            final_amount = Number(allSumofcharge) + Number(get_penalty_amount)
        }

        // let additional_deduction_on_off = contractDatail?.[0]?.["different_interest"];
        // if (additional_deduction_on_off === 1) {
        //     // fine
        //     let totalAssigneAmount = final_amount;
        //     let fine = getValues("fine");
        //     if (fine !== "" && String(fine) !== "0") {
        //         let cal = totalAssigneAmount * Number(fine) / 100;
        //         final_amount = final_amount + cal;
        //     }
        //     //fee
        //     let fee = getValues("fee");
        //     if (fee !== "" && String(fee) !== "0") {
        //         let cal = totalAssigneAmount * Number(fee) / 100;
        //         final_amount = final_amount + cal;
        //     }
        //     //discount
        //     let discount = getValues("discount");
        //     if (discount !== "" && String(discount) !== "0") {
        //         let cal = totalAssigneAmount * Number(discount) / 100;
        //         final_amount = final_amount - cal;
        //     }
        // }
        // setValue("total_amount", converter(`${final_amount}`));
        setTotalVal(`${final_amount.toFixed(2)}`)
        setValue("total_amount", final_amount.toFixed(2));
        setFinalAmountDeduction(final_amount.toFixed(2));
    }

    const [finalAmountafterDeduction, setFinalAmountDeduction] = useState<any>(0);
    const handleAddAnother = (e) => {
        setcharge([
            ...charge,
            {
                service: "",
                complement: "",
                total_amount: "",
                proportional_amount: "",
                credit: "",
                admin_fee: "",
                status: 1
            }
        ])
    }

    const handleCancelInstallment = (type: any) => {
        if (type == "cancel") {
            setsafeinstallment([]);
            setinstallment([]);
            reset({ installment: [] })
        }

        if (type == "undo") {
            setsafeinstallment([{
                due_date: "",
                total: "",
            }]);
            setinstallment([{
                due_date: "",
                total: "",
            }]);
        }
    }

    const handleChange = (e: any) => {
        let splitData = String(e.target.name).split(".");
        let type: any = splitData[0];
        let index = splitData[1];
        let field = splitData[2];
        let valueIs = e.target.value;

        if (type == "charge") { type = charge }
        if (type == "installment") {
            let { value } = e.target;
            value = value.replace(/[^\d,]/g, '');
            if (e.target.value !== "") {
                setValue(`installment.${Number(index)}.total`, formatNumber(value))
            } else {
                setValue(`installment.${Number(index)}.total`, "")
            }
            type = installment
        }

        if (field == "admin_fee") {
            valueIs = e.target.checked ? 1 : 0
        }

        let particulatIndex = {
            ...type[index],
            [`${field}`]: valueIs
        }
        type[index] = particulatIndex;
        if (type == "charge") {
            setcharge([...type]);
        }
        if (type == "installment") {
            setinstallment([...type]);
            setsafeinstallment([...type]);
        }

        charge.forEach((item: any, index: number) => {
            item["poramount"] = false;
            charge[index] = item
        })

        if (field == "total_amount") {
            let Proportional: any = getValues('porpoortional_day');
            let amt3 = convertToNumber(valueIs ? `${valueIs}` : 0)
            let getValuePorportional = Number(amt3) / Number(totalDaysInMonth) * Number(Proportional && Proportional !== "NaN" ? Proportional : 0);
            charge[index]["proportional_amount"] = Number(getValuePorportional);
            setValue(`charge.${Number(index)}.proportional_amount`, String(converter(`${getValuePorportional}`)));
            let { value } = e.target;
            value = value.replace(/[^\d,]/g, '');
            if (e.target.value !== "") {
                setValue(`charge.${Number(index)}.total_amount`, formatNumber(value))
            } else {
                setValue(`charge.${Number(index)}.total_amount`, "")
            }
            setTimeout(() => {
                finalCalculation("4444");
            }, 20)
        }

        if (field == "proportional_amount") {
            let { value } = e.target;
            value = value.replace(/[^\d,]/g, '');
            if (e.target.value !== "") {
                // setValue(`charge.${Number(index)}.total_amount`, formatNumber(value))
                charge[index]["proportional_amount"] = Number(convertToNumber(value));
                setValue(`charge.${Number(index)}.proportional_amount`, String(formatNumber(`${value}`)));
            } else {
                charge[index]["proportional_amount"] = Number(convertToNumber("0"));
                setValue(`charge.${Number(index)}.proportional_amount`, String(formatNumber(`0`)));
            }
            setTimeout(() => {
                finalCalculation("4444");
            }, 20)
        }

        if (field == "credit" || field == "proportional_amount") {
            setTimeout(() => {
                finalCalculation("6565");
            }, 20)
        }
        // if (field == "admin_fee" || field == "total_amount") {
        //     handleChangeAdminFee(e, "chargeStr")
        // }
    }

    const handleChangeAdminFee = (e: any, typedata: any) => {
        // setTimeout(() => {
        //     finalCalculation("5555");
        // }, 15)
    }

    const deleteService = (index: any, type: any) => {
        if (type == "undo") {
            charge[index]["status"] = `${1}`;
        }

        if (type == "delete") {
            charge[index]["status"] = `${0}`;
        }
        setValue('charge', []);
        charge.forEach((item: any, index: number) => {
            let fields = Object.keys(item);
            fields.forEach((field, indexs) => {
                let fieldData: any = `charge.${index}.${field}`;
                setValue(fieldData, item[field])
            })
        })
        setcharge([...charge])
    }

    const deleteinstallment = (index: any) => {
        installment.splice(index, 1);
        installment.forEach((item: any, index: number) => {
            let fields = Object.keys(item);
            fields.forEach((field, indexs) => {
                let fieldData: any = `installment.${index}.${field}`;
            })
        })

        safeinstallment.splice(index, 1);
        safeinstallment.forEach((item: any, index: number) => {
            let fields = Object.keys(item);
            fields.forEach((field, indexs) => {
                let fieldData: any = `installment.${index}.${field}`;
            })
        })

        setinstallment([...installment]);
        setsafeinstallment([...safeinstallment]);
        setValue("number_parcels", installment ? installment.length : "");
    }

    useEffect(() => {
        let getValu: any = getValues("number_parcels");
        if (runOnChange && installment && installment.length > 0) {
            let amt2 = convertToNumber(getValues("total_amount"))
            if (Number(getValu) && Number(amt2) > 1) {
                if (getValu == "1") {
                    setinstallment([{
                        due_date: moment(new Date).format("YYYY-MM-DD"),
                        total: Number(amt2)
                    }])
                    setValue("number_parcels", getValu > 2 ? getValu : "1");
                    setValue(`installment.${0}.due_date`, moment(new Date(dateBrazil)).add(1, "M").format("YYYY-MM-DD"));
                    setValue(`installment.${0}.total`, String(amt2));
                } else {
                    if (Number(getValu) > 12) {
                        setValue("number_parcels", "12");
                    }

                    let objectData: any = [];
                    let ArrayCount = Number(getValu) !== 0 && getValu == "1" ? 1 : Number(getValu) > 12 ? 12 : Number(getValu);
                    let GetTotalAmount = Number(amt2) / ArrayCount;
                    let i: number = 1;
                    [...Array(ArrayCount)].forEach((item: any, index: number) => {
                        let todayDate = moment(new Date(dateBrazil)).add(i, "M").format("YYYY-MM-DD");
                        objectData.push({
                            due_date: todayDate,
                            total: Number(GetTotalAmount).toFixed(2)
                        });
                        setValue(`installment.${index}.due_date`, todayDate);
                        // setValue(`installment.${index}.total`, String(GetTotalAmount.toFixed(2)));
                        setValue(`installment.${index}.total`, converter(`${GetTotalAmount}`));
                        i = i + 1
                    })

                    // setValue("installment", []);
                    if (safeinstallment?.[0]?.["due_date"] !== "" && safeinstallment?.[0]?.["total"] !== "") {
                        safeinstallment.forEach((item: any, index: number) => {
                            objectData[index] = item;
                            setValue(`installment.${index}.due_date`, moment(item?.["due_date"].split("T")[0]).format("YYYY-MM-DD"));
                            setValue(`installment.${index}.total`, converter(`${item?.["total"]}`));
                        })
                    }
                    setinstallment([...objectData])
                }
            } else {
                setinstallment([{
                    due_date: moment(new Date(dateBrazil)).format("YYYY-MM-DD"),
                    total: Number(converter(`${getValues("total_amount")}`)).toFixed(2)
                }])
                setValue("number_parcels", getValu < 1 && Number(getValues("total_amount")) > 1 ? getValu : "1");
                setValue(`installment.${0}.due_date`, Number(getValues("total_amount")) > 1 ? moment(new Date(dateBrazil)).add(1, "M").format("YYYY-MM-DD") : "");
                setValue(`installment.${0}.total`, Number(getValues("total_amount")) > 1 ? String(Number(getValues("total_amount"))) : "");
            }
        } else {
            setValue("number_parcels", "0");
        }
        setTimeout(() => {
            setRunOnChange(true);
        }, 10)
    }, [watch("number_parcels"), watch("total_amount")])

    const Close = () => {
        terminationCalculationModalClose();
        reset();
        setrunOnce(true);
        setcharge([
            {
                service: "",
                complement: "",
                total_amount: "",
                proportional_amount: "",
                credit: "",
                admin_fee: "",
                status: 1
            }
        ])
    }

    const handleSaveDraftClick = () => {
        // Set the value to identify the button clicked
        settypeSubmit('draft');
        // Submit the form
        handleSubmit((data, e) => onRecurringExpensesFormSubmit(data, e, "draft"))();
    };

    const handleSaveCompleteClick = () => {
        // Set the value to identify the button clicked
        settypeSubmit('done');
        // Submit the form
        Swal.fire({
            // icon: 'success','warning',
            title: t("Sweetalert.WantToSave"),
            text: t("Sweetalert.Areyousuretosaveit"),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('button.confirm')
        }).then(res => {
            if (res.isConfirmed) {
                handleSubmit((data, e) => onRecurringExpensesFormSubmit(data, e, "done"))();
            }
        })
    };


    //Form submit
    const onRecurringExpensesFormSubmit = (data: any, e: any, type: any) => {
        setloader_on(true)

        data["type"] = typeValue;
        data["contract_id"] = id;

        if (type == "done") {
            data["status"] = 0;
        }

        if (type == "draft") {
            data["status"] = 1;
        }

        data["installment"] = installment;
        data["sub_type"] = "charge_calculation_XYZ";
        let filterCharge: any = [];
        charge.forEach((item: any, index: number) => {
            if (String(item["status"]) === "1") {
                filterCharge.push(item);
            }
        })
        let total_amounts: any = convertToNumber(totalVal);
        data["total_amount"] = total_amounts;
        data["charge"] = filterCharge;
        data["old_expenses_need_to_delete"] = delete_all_remaining_expense;
        const formData = new FormData();
        let objdata: any = [];
        let chargeCreateData: any = {
            total: Number(convertToNumber(totalVal ? totalVal : data["total_amount"])),
            chargeId: "",
            id: Number(data["id"]),
            propertyId: contractDatail[0].propertyId,
            tenant: contractDatail[0].sacadoId,
            branch: contractDatail[0].branch,
            debitType: 1,
            maturity: data["limit_date"],
            objdata,
            totalAmount: Number(convertToNumber(totalVal ? `${totalVal}` : data["total_amount"])),
            much: Number(data["fine"]),
            fees: Number(data["fee"]),
            discount: Number(data["discount"]),
            bankAccount: contractDatail[0].bank_account,
            billingMethod: data["billing_method"],
            internalObservation: data["observation_first"],
            noteCustomer: data["observation_second"],
            competence: "",
            isVendor: false,
            credit: '',
            supplier: '',
            isCalAdmin: 0
        };

        Object.keys(data) && Object.keys(data).length > 0 &&
            Object.keys(data).forEach((item: any, index: number) => {
                if (item == "penalty") {
                    let chargeVal = convertToNumber(`${valueCon}`);
                    objdata.push({
                        chargeserviceId: "",
                        service: Number(data[`${item}`]["service"]),
                        complement: data[`${item}`]["complement"],
                        value: chargeVal ? chargeVal : data[`${item}`]["value"],
                        credit: Number(data[`${item}`]["credit"]),
                        administrationApplies: data[`${item}`]["admin_fee"] == "true" ? 1 : 0,
                        debit: "",
                        debitOwner: "",
                        creditOwner: "",
                        supplier: ""
                    })
                    data["penalty"].value = chargeVal
                    formData.append(`${item}`, JSON.stringify(data[`${item}`]));
                } else if (item == "charge") {
                    data[`${item}`].forEach((singleItem: any, index: number) => {
                        let newVal1 = Number(data[`${item}`][index]["total_amount"]) + Number(data[`${item}`][index]["proportional_amount"]);
                        let newValues = convertToNumber(`${newVal1.toFixed(2)}`);
                        objdata.push({
                            chargeserviceId: "",
                            service: data[`${item}`][index]["service"],
                            complement: data[`${item}`][index]["complement"],
                            value: Number(newValues) || 0,
                            credit: data[`${item}`][index]["credit"],
                            administrationApplies: data[`${item}`][index]["admin_fee"] == "true" ? 1 : 0,
                            debit: "",
                            debitOwner: "",
                            creditOwner: "",
                            supplier: ""
                        })
                    })
                    data["charge"].forEach((item: any) => {
                        let newVal1 = convertToNumber(`${item.total_amount}`)
                        item.total_amount = Number(newVal1 || 0)
                    });
                    formData.append(`${item}`, JSON.stringify(data[`${item}`]));
                } else if (item == "installment") {
                    data["installment"].forEach((item: any) => {
                        let newVal1 = convertToNumber(`${item.total}`)
                        item.total = newVal1
                    });
                    formData.append(`${item}`, JSON.stringify(data[`${item}`]));
                } else {
                    formData.append(`${item}`, data[`${item}`]);
                }
            })

        customerService.post_terminationDefaultTerminationList(formData).then(async res => {
            if (res["data"]["type"] == "success" || res["data"]["type"] == "Success") {
                await dispatch(contractDatails(id));
                if (type == "draft") {
                    Swal.fire({
                        // icon: 'success','success',
                        title: t("Sweetalert.Success"),
                        text: t("Sweetalert.successget"),
                        confirmButtonText: "OK",
                        timer: 2000
                    })
                }

                if (type == "done") {
                    let response = await dispatch(insertExtraCharge(chargeCreateData));
                    if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
                        settypeSubmit('draft')
                        Swal.fire({
                            // icon: 'success','success',
                            title: t("Sweetalert.Success"),
                            text: t("Sweetalert.successget"),
                            confirmButtonText: "OK",
                            timer: 2000
                        })
                        dispatch(getAllExpense(state));
                        dispatch(getAllContractCharge(stateCharge));
                    } else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.successfailed"),
                            // icon: 'success',"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        })
                    }
                }
                Close();
                reset();
                setcharge([
                    {
                        service: "",
                        complement: "",
                        total_amount: "",
                        proportional_amount: "",
                        credit: "",
                        admin_fee: "",
                        status: 1
                    }
                ])
                setTimeout(() => {
                    settypeSubmit('draft');
                }, 1500)
            } else {
                Swal.fire({
                    // icon: 'success','error',
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.successfailed"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
            }
            setloader_on(false);
        })
    };

    const calculation_view = () => {
        const maturityDate: any = new Date(contractDatail[0]["end2"]);
        const currentDate: any = new Date(dateBrazil);
        const timeDifference = maturityDate - currentDate;
        const millisecondsInAMonth = 1000 * 60 * 60 * 24 * 30.44;

        let remainingMonths: any = Math.floor(timeDifference / millisecondsInAMonth);
        if (Number(remainingMonths) < 1) {
            remainingMonths = 1
        }

        let rent_charge = Number(contractDatail[0]["rent_value"]);

        // Start billing method
        // This part will be affected by billing method choosed on contract edit
        let Get_billingMenthod: any = "0";
        let thismonth_lastDay: any = new Date(moment().endOf('month').format("YYYY-MM-DD"));
        let thismonth_startDay: any = new Date(moment().format("YYYY-MM-01"));

        const differenceInMilliseconds = thismonth_lastDay - thismonth_startDay;
        const total_lived_day_milliseconds = todayDate - thismonth_startDay;
        let totalDay_on_this_month: any = Math.floor(differenceInMilliseconds / (24 * 60 * 60 * 1000)) + 1;
        let totalDay_lived_on_leased: any = Math.floor(total_lived_day_milliseconds / (24 * 60 * 60 * 1000)) + 1;
        let per_day_rent = rent_charge / Number(totalDay_on_this_month);

        // if value is 0 then it's is expired month (give previous month rent)
        if (Get_billingMenthod == 0 || Get_billingMenthod == "0") {
            // let total_need_to_pay = totalDay_lived_on_leased * per_day_rent;
            let total_need_to_pay = rent_charge;
            rent_charge = Number(total_need_to_pay.toFixed(2));
        }

        // if value 2 then it's is month due(advance)
        if (Get_billingMenthod == 1 || Get_billingMenthod == "1") {
            // let total_need_to_pay = totalDay_lived_on_leased * per_day_rent - rent_charge;
            let total_need_to_pay = rent_charge;
            rent_charge = Number(total_need_to_pay.toFixed(2));
        }
        // End billing method

        let duration = contractDatail[0]["duration"];
        let month_remain = remainingMonths;

        exemption["fine_status"] = "no";
        if (exemption["type"] == "no_exemption") {
            if (Number(exemption["fine_free_month_after"]) >= Number(exemption["till_now_month"])) {
                rent_charge = rent_charge * 3;
                exemption["fine_status"] = "yes";
            }
        }
        if (exemption["type"] == "exemption") {
            if (Number(exemption["fine_free_month_after"]) > Number(exemption["till_now_month"])) {
                rent_charge = rent_charge * 3;
                exemption["fine_status"] = "yes";
                month_remain = exemption["fine_free_month_after"] - exemption["till_now_month"]

            }
        }

        let penalty_amt: any = Number(rent_charge) / Number(duration) * month_remain;
        penalty_amt = penalty_amt;

        setValueCon(converter(penalty_amt))
        let penaltyAmount: any = Number(penalty_amt.toFixed(2));
        if (exemption["fine_status"] == "no") {
            penalty_amt = Number(rent_charge) * 0;
            month_remain = 0;
            duration = 0;
            rent_charge = 0;
            penaltyAmount = 0;
        }
        if (!(getValues("penalty.value"))) {
            setValue("penalty.value", converter(`${penaltyAmount}`));
        } else {
            let amt2 = getValues("penalty.value");
            setValue("penalty.value", converter(`${amt2}`));
        }
        setCalculationValue({ rent_charge, month_remain, duration, penalty_amt: penaltyAmount })
    }

    const [creditData, setcreditData] = useState<any>([
        { value: 1, label: "Owner" },
        { value: 2, label: "Tenant" },
        { value: 3, label: "Real estate" },
        { value: 4, label: "Third" },
    ])

    useEffect(() => {
        if (i18n.language == "en") {
            setcreditData([
                { value: 1, label: "Owner" },
                { value: 2, label: "Tenant" },
                { value: 3, label: "Real estate" },
                { value: 4, label: "Third" },
            ])
        } else {
            setcreditData([
                { value: 1, label: "Proprietário" },
                { value: 2, label: "Inquilino" },
                { value: 3, label: "Imobiliária" },
                { value: 4, label: "Terceiro" },
            ])
        }
    }, [i18n.language])

    const handleDeductSecurity = (e: any) => {
        let valTarget = e.target.checked;
        let securityDeposit: any = document.querySelector(".securityDeposit");
        if (!valTarget) {
            securityDeposit.setAttribute("disabled", "disabled");
            securityDeposit.setAttribute("readOnly", "readOnly");
            // setValue("security_deposit", "");
        } else {
            securityDeposit.removeAttribute("disabled");
            securityDeposit.removeAttribute("readOnly");
        }

        setValue("deduct_security", valTarget);
        setTimeout(() => {
            finalCalculation("6666");
        }, 15)
    }

    const DayPropotional = (e: any) => {
        //Working heree
        let Proportional: any = e.target.value;
        let Proportional1: any = (String(Number(e.target.value)) == "NaN") ? "0" : e.target.value;

        charge.forEach((item: any, index: number) => {
            let getValuePorportional = Number(item["total_amount"]) / Number(totalDaysInMonth) * Number(Proportional1);
            charge[index]["proportional_amount"] = Number(getValuePorportional);
            setValue(`charge.${Number(index)}.proportional_amount`, (converter(`${getValuePorportional}`)));
        })
        setValue("porpoortional_day", Proportional)
        setTimeout(() => {
            finalCalculation("7777");
        }, 20)

        if (/^-?\d*$/.test(e.target.value)) {
            clearErrors("porpoortional_day");
        } else {
            setError("porpoortional_day", {
                type: "manual",
                message: i18n.language == "en" ? "Number only allow with (-) value" : "Número permitido apenas com valor (-)"
            });
        }
    }

    const AddValue = (e: any) => {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        if (e.target.value !== "") {
            setValue("penalty.value", formatNumber(value))
            setValueCon(value)
        } else {
            setValue("penalty.value", "")
            setValueCon('')
        }
    }

    return (
        <>
            <>
                <Offcanvas show={showTerminationCalculationModal} placement="end" onHide={Close}>
                    <Form className="d-flex flex-column h-100" onSubmit={handleSubmit((data, e) => onRecurringExpensesFormSubmit(data, e, ""))}>
                        <input type="hidden" value={id} {...register('id')} />
                        <input type="hidden"  {...register('propertyId')} />
                        <input type="hidden" {...register('tenant')} />
                        <input type="hidden" {...register('branch')} />
                        <input type="hidden"  {...register('total')} />
                        <input type="hidden" {...register('chargeId')} />
                        <input type="hidden" {...register('bankAccount')} />

                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className="h4">{t("terminatelist.Terminationcalculation")}</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                                {/* <Form.Control type="text"  {...register("id")} hidden readOnly /> */}
                                <Col md={6}>
                                    <FloatingLabel controlId="responsible" label={t('formLabel.responsible') + " *"}>
                                        <Form.Select aria-label="Responsible" {...register("responsible")} className={`DisableThis ${errors.responsible ? "is-invalid" : ""}`} onChange={(e) => handleResponsible(e, "", "form")}>
                                            <option value="slct">{t("formLabel.select")}</option>
                                            {terminationResp && terminationResp.length > 0 &&
                                                terminationResp.map((item, index) => {
                                                    return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                                })
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback className="invalid-feedback">{errors.responsible?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="limit_date" label={t('terminatelist.limit') + " " + t("terminatelist.date") + " *"}>
                                        <Form.Control type="date" min={minDate()} placeholder={t('terminatelist.limit') + " " + t("terminatelist.date")} {...register("limit_date")} className={`DisableThis ${errors.limit_date ? "is-invalid" : ""}`} />
                                        <Form.Control.Feedback className="invalid-feedback">{errors.limit_date?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <FloatingLabel controlId="responsible_party_name" label={t("terminatelist.Responsibleparty'sname") + " *"} className="mb-lg-4 mb-3">
                                <Form.Select aria-label="Responsible party's name" {...register("responsible_party_name")} className={`DisableThis ${errors.responsible_party_name ? "is-invalid" : ""}`}>
                                    <option value="slct">{t("formLabel.select")}</option>
                                    {showResponsible && showResponsible.length > 0 &&
                                        showResponsible.map((item, index) => {
                                            return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                        })
                                    }
                                </Form.Select>
                                <Form.Control.Feedback className="invalid-feedback">{errors.responsible_party_name?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                            <p className="mb-lg-4 mb-3 fw-bold">{t("Chargedata")}</p>
                            {
                                exemption["fine_status"] == "yes" ? ""
                                    :
                                    <Alert className="mb-lg-4 mb-3 d-flex" variant="warning" show={showAlert} onClose={() => setAlertShow(false)} dismissible>
                                        <img src="../assets/images/icons/triangle-exclamation.svg" alt="trophy" className="me-2 me-lg-3 h-20 flex-shrink-0" />
                                        <h6 className="mb-0">{t("Thesettingsofthiscontrac")}.</h6>
                                    </Alert>
                            }

                            <Form.Control type="text" {...register("termination_calculation_id")} hidden />
                            <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                                <Col md={6}>
                                    <FloatingLabel controlId="termination_date" label={t("Report-Contract.TerminationDate") + " *"}>
                                        <Form.Control type="date" min={minDate()} placeholder={t("Report-Contract.TerminationDate")} {...register("terminate_moveout")} className={`DisableThis ${errors.terminate_moveout ? "is-invalid" : ""}`} />
                                        <Form.Control.Feedback className="invalid-feedback">{errors.terminate_moveout?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="proportional_days" label={t("Proportionaldays") + " *"}>
                                        <Form.Control type="text" placeholder={t("Proportionaldays")} {...register("porpoortional_day")} className={`DisableThis ${errors.porpoortional_day ? "is-invalid" : ""}`} onChange={(e) => DayPropotional(e)} />
                                        <Form.Control.Feedback className="invalid-feedback">{errors.porpoortional_day?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <FloatingLabel className="mb-lg-4 mb-3" controlId="charge_due_date" label={t("Chargeduedate") + " *"}>
                                <Form.Control type="date" min={minDate()} placeholder={t("Chargeduedate")} {...register("charge_due_date")} className={`DisableThis ${errors.charge_due_date ? "is-invalid" : ""}`} />
                                <Form.Control.Feedback className="invalid-feedback">{errors.charge_due_date?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                            <Card className="border mb-lg-4 mb-3">
                                <Card.Body>
                                    <div className="d-flex align-items-center fw-bold mb-lg-4 mb-3">
                                        <p className="mb-0 me-2 me-lg-4 me-sm-3">{t("Terminationpenalty")}</p>
                                        <span className="cursor-pe text-primary ms-auto" onClick={viewCalculationModalShow}>{t("Viewcalculation")}</span>
                                    </div>
                                    <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                                        <Col md={6}>
                                            <FloatingLabel controlId="service" label={t("contractsettings.Service") + " *"}>
                                                <Form.Select aria-label="Service" {...register("penalty.service")} className={`DisableThis ${errors.penalty?.service ? "is-invalid" : ""}`}>
                                                    {serviceData && serviceData.length > 0 && serviceData.map((item, index) => {
                                                        if (item.name == "Fine" || item.name == "fine" || item.name == "multar" || item.name == "Multar" || item.name == "Multa" || item.name == "multa") {
                                                            return (
                                                                <option key={index} value={item.id} defaultValue="selected">{item.name}</option>
                                                            )
                                                        }
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback className="invalid-feedback">{errors.penalty?.service?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="complement" label={t("complement")}>
                                                <Form.Control type="text" placeholder={t("complement")}  {...register("penalty.complement")} className={`DisableThis ${errors.penalty?.complement ? "is-invalid" : ""}`} />
                                                <Form.Control.Feedback className="invalid-feedback">{errors.penalty?.complement?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="value" label={t("value") + " (R$)"}>
                                                <Form.Control type="text" placeholder={t("value") + " (R$)"} {...register("penalty.value")} className={`DisableThis ${errors.penalty?.value ? "is-invalid" : ""}`} onChange={(e) => AddValue(e)} />
                                                <Form.Control.Feedback className="invalid-feedback">{errors.penalty?.value?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="credit" label={t("credit") + " *"}>
                                                <Form.Select aria-label="Credit" {...register("penalty.credit")} className={`DisableThis ${errors.penalty?.credit ? "is-invalid" : ""}`} >
                                                    <option value="slct">{t("formLabel.select")}</option>
                                                    {creditData && creditData.length > 0 &&
                                                        creditData.map((item, index) => {
                                                            return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                                        })
                                                    }
                                                </Form.Select>
                                                <Form.Control.Feedback className="invalid-feedback">{errors.penalty?.credit?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                                        <Form.Check id="administration_applies" label={t("services.administrationFeeApplies")}  {...register("penalty.admin_fee")} className={`DisableThis ${errors.penalty?.admin_fee ? "is-invalid" : ""}`} onChange={(e) => handleChangeAdminFee(e, "service")} />
                                        <Form.Control.Feedback className="invalid-feedback">{errors.penalty?.admin_fee?.message}</Form.Control.Feedback>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                            <div className="d-flex align-items-center fw-bold mb-lg-4 mb-3">
                                <p className="mb-0 me-2 me-lg-4 me-sm-3">{t("Othercharges")}</p>
                                {
                                    statusOfChecklist === 1 &&
                                    <>
                                        <span className="cursor-pe text-primary ms-auto" onClick={(e) => handleAddAnother(e)}>{t("newContractDev.AddAnother")}</span>
                                    </>
                                }
                            </div>

                            {
                                charge && charge.length > 0 &&
                                charge.map((item, index) => {
                                    if (item.status == 1) {
                                        return (
                                            <Card className="border mb-lg-4 mb-3" key={index}>
                                                <Card.Body>
                                                    <div className="d-flex align-items-center fw-bold mb-lg-4 mb-3">
                                                        <p className="mb-0 me-2 me-lg-4 me-sm-3">{t("Links.services")}</p>
                                                        {/* {
                                                            charge.length > 1 && */}
                                                        {
                                                            statusOfChecklist === 1 &&
                                                            <>
                                                                <span className="cursor-pe text-primary ms-auto" onClick={(e) => deleteService(index, "delete")}>{t("Sweetalert.Delete")}</span>
                                                            </>
                                                        }
                                                        {/* } */}
                                                    </div>

                                                    <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                                                        <Col md={6}>
                                                            <FloatingLabel controlId="service" label={t("contractsettings.Service")}>
                                                                <Form.Select aria-label="Service" {...register(`charge.${index}.service`)} className={`DisableThis ${errors.charge?.[index]?.service ? "is-invalid" : ""}`} onChange={(e) => handleChange(e)}>
                                                                    <option value={responsiblevalue}>{t("formLabel.select")}</option>
                                                                    {serviceData && serviceData.length > 0 && serviceData.map((item, index) => (
                                                                        <option key={index} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                                <Form.Control.Feedback className="invalid-feedback">{errors.charge?.[index]?.service?.message}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FloatingLabel controlId="complement" label={t("complement")}>
                                                                <Form.Control type="text" placeholder={t("complement")} {...register(`charge.${index}.complement`)} className={`DisableThis ${errors.charge?.[index]?.complement ? "is-invalid" : ""}`} onChange={(e) => handleChange(e)} />
                                                                <Form.Control.Feedback className="invalid-feedback">{errors.charge?.[index]?.complement?.message}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FloatingLabel controlId="total_amount" label={t("newTransfer.totalAmount") + "(R$)"}>
                                                                <Form.Control type="text" placeholder={t("newTransfer.totalAmount") + "(R$)"} {...register(`charge.${index}.total_amount`)} className={`DisableThis ${errors.charge?.[index]?.total_amount ? "is-invalid" : ""}`} onChange={(e) => handleChange(e)} />
                                                                <Form.Control.Feedback className="invalid-feedback">{errors.charge?.[index]?.total_amount?.message}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FloatingLabel controlId="proportional_amount" label={t("Proportionalamount")}>
                                                                {/* <Form.Control type="text" placeholder={t("Proportionalamount")} {...register(`charge.${index}.proportional_amount`)} className={`DisableThis ${errors.charge?.[index]?.proportional_amount ? "is-invalid" : ""}`} onChange={(e) => handleChange(e)} readOnly disabled /> */}
                                                                <Form.Control type="text" placeholder={t("Proportionalamount")} {...register(`charge.${index}.proportional_amount`)} className={`DisableThis ${errors.charge?.[index]?.proportional_amount ? "is-invalid" : ""}`} onChange={(e) => handleChange(e)} />
                                                                <Form.Control.Feedback className="invalid-feedback">{errors.charge?.[index]?.proportional_amount?.message}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FloatingLabel controlId="credit" label={t("credit")}>
                                                                <Form.Select aria-label="Credit" {...register(`charge.${index}.credit`)} className={`DisableThis ${errors.charge?.[index]?.credit ? "is-invalid" : ""}`} onChange={(e) => handleChange(e)} >
                                                                    <option value={chargevalue}>{t("formLabel.select")}</option>
                                                                    {creditData && creditData.length > 0 &&
                                                                        creditData.map((item, index) => {
                                                                            return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                                <Form.Control.Feedback className="invalid-feedback">{errors.charge?.[index]?.credit?.message}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>

                                                    <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                                                        <Form.Check id={`administration_applies_${index}`} label={t("services.administrationFeeApplies") + " *"}  {...register(`charge.${index}.admin_fee`)} className={`DisableThis ${errors.charge?.[index]?.admin_fee ? "is-invalid" : ""}`} onChange={(e) => handleChange(e)} />
                                                        <Form.Control.Feedback className="invalid-feedback">{errors.charge?.[index]?.admin_fee?.message}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>
                                        )
                                    } else {
                                        return (
                                            <Card className="border mb-lg-4 mb-3" key={index}>
                                                <Card.Body>
                                                    <div className="d-sm-flex align-items-center fw-bold">
                                                        <div className="me-lg-4 me-sm-3 w-100 overflow-hidden">
                                                            <p className="mb-sm-0">{t("contractlistother.charge")} <Badge className="bg-danger-30 text-dark ms-lg-4 ms-md-3 ms-2">Service deleted</Badge></p>
                                                        </div>
                                                        {
                                                            statusOfChecklist === 1 &&
                                                            <>
                                                                <div className="flex-shrink-0 ms-sm-auto text-primary cursor-pe" onClick={(e) => deleteService(index, "undo")}>
                                                                    {t("TransferPage.UNDO")}
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        )
                                    }
                                })
                            }
                            <Card className="border mb-lg-4 mb-3">
                                <Card.Body>
                                    <p className="fw-bold mb-lg-4 mb-3">{t("Additionsanddeductions")}</p>
                                    <Row className="gy-lg-4 gy-3">
                                        <Col md={4}>
                                            <FloatingLabel controlId="fine" label={t("ReceiveandReceived.Fine") + " (%)"}>
                                                <Form.Control type="text" placeholder={t("ReceiveandReceived.Fine") + " (%)"}  {...register("fine")} className={`DisableThis ${errors.fine ? "is-invalid" : ""} additionFee_deduct`} onChange={(e) => handleAddDedCount(e)} />
                                                <Form.Control.Feedback className="invalid-feedback">{errors.fine?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="fee" label={t("Fee") + " (%)"}>
                                                <Form.Control type="text" placeholder={t("Fee") + " (%)"}  {...register("fee")} className={`DisableThis ${errors.fee ? "is-invalid" : ""} additionFee_deduct`} onChange={(e) => handleAddDedCount(e)} />
                                                <Form.Control.Feedback className="invalid-feedback">{errors.fee?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="discount" label={t("formReceipt.discount") + "  (%)"}>
                                                <Form.Control type="text" placeholder={t("formReceipt.discount") + " (%)"}  {...register("discount")} className={`DisableThis ${errors.discount ? "is-invalid" : ""} additionFee_deduct`} onChange={(e) => handleAddDedCount(e)} />
                                                <Form.Control.Feedback className="invalid-feedback">{errors.discount?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <div className="d-flex align-items-center fw-bold mb-lg-4 mb-3">
                                <p className="mb-0 me-2 me-lg-4 me-sm-3">{t("Totalchargeamount")}</p>
                                {
                                    statusOfChecklist === 1 &&
                                    <>
                                        <span className="cursor-pe text-primary ms-auto">{t("Install")}</span>
                                    </>
                                }
                            </div>
                            <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                                <Col md={6}>
                                    <FloatingLabel controlId="total_amount" label={t("newTransfer.totalAmount") + " (R$)" + " *"}>
                                        <Form.Control type="text" placeholder={t("newTransfer.totalAmount") + " (R$)"} {...register("total_amount")} className={`DisableThis ${errors.total_amount ? "is-invalid" : ""}`} readOnly disabled />
                                        <Form.Control.Feedback className="invalid-feedback">{errors.total_amount?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="billing_method" label={t("billingMethod") + " *"}>
                                        <Form.Select aria-label="Billing method" {...register("billing_method")} className={`DisableThis ${errors.billing_method ? "is-invalid" : ""}`} >
                                            <option value="slct">{t('dropdownItem.select')}</option>
                                            {
                                                billingData.data && billingData.data.length > 0 ? billingData.data.map((item, index) => (
                                                    <option key={index} value={Number(item.id)}>{item.name}</option>
                                                ))
                                                    : ''
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback className="invalid-feedback">{errors.billing_method?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>

                                {securityDepositRule &&
                                    <>
                                        <Col md={6}>
                                            <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                                                <Form.Check id="deduct_security" label={t("DeductfromSecurityDep")}  {...register("deduct_security")} className={`DisableThis ${errors.deduct_security ? "is-invalid" : ""}`} onChange={(e) => handleDeductSecurity(e)} />
                                                <Form.Control.Feedback className="invalid-feedback">{errors.deduct_security?.message}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="security_deposit_value" label={t("Securitydepositvalue") + "(R$)"}>
                                                <Form.Control type="number" placeholder={t("Securitydepositvalue") + "(R$)"}  {...register("security_deposit")} className={`DisableThis ${errors.security_deposit ? "is-invalid" : ""} securityDeposit`} readOnly={getValues("deduct_security") ? false : true} disabled={getValues("deduct_security") ? statusOfChecklist === 1 ? false : true : true} />
                                                <Form.Control.Feedback className="invalid-feedback">{errors.security_deposit?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </>
                                }

                            </Row>
                            <Card className="border mb-lg-4 mb-3">
                                <Card.Body>
                                    {
                                        statusOfChecklist === 1 &&
                                        <>
                                            <div className="d-flex align-items-center fw-bold mb-lg-4 mb-3">
                                                <p className="mb-0 me-2 me-lg-4 me-sm-3">{t("Install")} {installment.length < 1 && <Badge className="bg-danger-30 text-dark ms-lg-4 ms-md-3 ms-2">{t("button.cancel")}</Badge>}</p>
                                                <span className="cursor-pe text-primary ms-auto" onClick={() => handleCancelInstallment(installment && installment.length !== 0 ? t("button.cancel") : t("TransferPage.UNDO"))}>{installment && installment.length > 0 ? t("Cancelinstallments") : t("TransferPage.UNDO")}</span>
                                            </div>
                                        </>
                                    }
                                    {
                                        installment && installment.length > 0 &&
                                        <FloatingLabel controlId="parcels" label={t("contractlistother.Numberofinstallments") + " *"} className="mb-lg-4 mb-3">
                                            <Form.Control type="number" placeholder={t("contractlistother.Numberofinstallments")} {...register("number_parcels")} className={`DisableThis ${errors.number_parcels ? "is-invalid" : ""}`} disabled={statusOfChecklist === 1 ? false : true} />
                                            <Form.Control.Feedback className="invalid-feedback">{errors.number_parcels?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    }

                                    {/* /// Loop will be runnnn hereeeeeeeee */}
                                    {
                                        installment && installment.length > 0 &&
                                        installment.map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <span className="mb-1 d-block">Parcel {index + 1} of {installment?.length ?? 1}</span>
                                                    <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3" >
                                                        <Col md>
                                                            <FloatingLabel controlId="dut_date" label={t("dueDate")}>
                                                                <Form.Control type="date" min={minDate()} placeholder={t("dueDate")} {...register(`installment.${index}.due_date`)} className={`DisableThis ${errors.installment?.[index]?.due_date ? "is-invalid" : ""}`} onChange={(e) => handleChange(e)} disabled={statusOfChecklist === 1 ? false : true} />
                                                                <Form.Control.Feedback className="invalid-feedback">{errors.installment?.[index]?.due_date?.message}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md>
                                                            <FloatingLabel controlId="total" label={t("total") + " (R$)"}>
                                                                <Form.Control type="text" placeholder={t("total") + " (R$)"} {...register(`installment.${index}.total`)} className={`DisableThis ${errors.installment?.[index]?.total ? "is-invalid" : ""}`} onChange={(e) => handleChange(e)} disabled={statusOfChecklist === 1 ? false : true} />
                                                                <Form.Control.Feedback className="invalid-feedback">{errors.installment?.[index]?.total?.message}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        {
                                                            statusOfChecklist === 1 &&
                                                            <>
                                                                {
                                                                    installment && installment?.length > 1 &&
                                                                    <Col md="auto">
                                                                        <Button type="button" variant="link" className="bg-light w-100"><img src="../assets/images/icons/delete-light.svg" alt="Delete" onClick={(e) => deleteinstallment(index)} /></Button>
                                                                    </Col>
                                                                }
                                                            </>
                                                        }
                                                    </Row>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                    {/* /// End Loop will be runnnn hereeeeeeeee */}
                                </Card.Body>
                            </Card>
                            <FloatingLabel controlId="internal_observations" label={t("formLabel.observation")} className="mb-lg-4 mb-3">
                                <Form.Control as="textarea" placeholder="Internal observations" {...register("observation_first")} className={`DisableThis ${errors.observation_first ? "is-invalid" : ""}`} />
                                <Form.Control.Feedback className="invalid-feedback">{errors.observation_first?.message}</Form.Control.Feedback>
                            </FloatingLabel>

                            <FloatingLabel className="mb-lg-4 mb-3" controlId="observation" label={t("Observationsfortheclient")}>
                                <Form.Control as="textarea" placeholder={t("formLabel.observation")} {...register("observation_second")} className={`DisableThis ${errors.observation_second ? "is-invalid" : ""}`} />
                                <Form.Control.Feedback className="invalid-feedback">{errors.observation_second?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Offcanvas.Body>
                        <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer flex-wrap">
                            <div className="border-end pe-3 border-md-none">
                                <span className="d-block">{t("Finalamount")}</span>
                                <span className="fw-bold">R${converter(`${finalAmountafterDeduction}`)} </span>
                            </div>
                            {/* {
                        statusOfChecklist === 1 && */}
                            <>
                                {/* <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                                <Button variant="outline-primary" onClick={handleSaveDraftClick}>
                                    {t('terminatelist.Savedraft')}
                                </Button>
                                <Button variant="primary" onClick={handleSaveCompleteClick}>
                                    {t('terminatelist.Saveandcomplete')}
                                </Button>
                            </div> */}
                            </>
                            <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                                {loader_on ?
                                    <Button className="btn-width">
                                        <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                    </Button>
                                    :
                                    <>
                                        <Button variant="outline-primary" onClick={Close}>
                                            {t('button.close')}
                                        </Button>
                                        <Button variant="primary" onClick={handleSaveCompleteClick}>
                                            {t('button.save')}
                                        </Button>
                                    </>
                                }
                            </div>
                            {/* } */}
                        </div>
                    </Form>
                </Offcanvas>
                {/* Toast start from here */}
                <CommonToast showCommonToast={showToast} commonToastClose={commonToastClose} />
                {/* Toast end here */}
                {/* Delete expense modal start from here */}
                <Modal show={showDeleteServiceModal} onHide={deleteServiceModalClose}>
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Deleteservice")}?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{t("Bydeletibecharged")}.</Modal.Body>
                        <Button variant="outline-primary" onClick={handleSaveDraftClick}>
                            {t('terminatelist.Savedraft')}
                        </Button>
                        <Button variant="primary" onClick={handleSaveCompleteClick}>
                            {t('terminatelist.Saveandcomplete')}
                        </Button>
                    </Form>
                </Modal>
                {/* Delete expense modal end */}
                {/* Add termination modal start from here */}
                <Modal show={showViewCalculationModal} onHide={viewCalculationModalClose}>
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>Calculation of the termination penalty</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="mb-lg-4 mb-3">The penalty amount is equivalent to three proportional rent payments for the remaining months to terminate the contract.</p>
                            {
                                <>
                                    <Card className="mb-lg-4 mb-3 border">
                                        <Card.Body>
                                            <span className="mb-lg-4 mb-3 fw-bold">Equation</span>
                                            <h4 className="text-center mb-lg-4 mb-3">
                                                <span className="border-bottom border-warning">{calculationValue["rent_charge"].toFixed(2)}</span><span className="mx-1">/</span><span className="border-bottom border-success">{calculationValue["duration"]}</span><span className="mx-1">x</span><span className="border-bottom border-danger">{calculationValue["month_remain"]}</span><span className="mx-1">=</span><span className="border-bottom border-secondary">{(calculationValue["penalty_amt"]).toFixed(2)}</span>
                                            </h4>
                                            <ul className="list-unstyled mb-0 text-secondary">
                                                <li className="mb-1 d-flex align-items-center">
                                                    <span className="d-inline-flex wh-xxs bg-warning rounded-circle me-1"></span>{exemption["fine_status"] == "yes" ? "Three rents" : "Rents"}: {calculationValue["rent_charge"]}
                                                </li>
                                                <li className="mb-1 d-flex align-items-center">
                                                    <span className="d-inline-flex wh-xxs bg-success rounded-circle me-1"></span>Months of contract:  {calculationValue["duration"]}
                                                </li>
                                                <li className="mb-1 d-flex align-items-center">
                                                    <span className="d-inline-flex wh-xxs bg-danger rounded-circle me-1"></span>Months until end of contract:  {calculationValue["month_remain"]}
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <span className="d-inline-flex wh-xxs bg-secondary rounded-circle me-1"></span>{exemption["fine_status"] == "yes" ? "Penalty" : "Total"}: {calculationValue["penalty_amt"].toFixed(2)}
                                                </li>
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </>
                            }

                        </Modal.Body>
                    </Form>
                </Modal>
                {/* Add termination modal end */}
                {/* Success modal */}
                <SuccessMessage showSuccess={showSuccessModal} successClose={successModalClose} />
            </>
        </>
    )
}

export default TerminationCalculationBilling
