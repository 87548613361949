import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, FloatingLabel, Alert } from 'react-bootstrap';
import eventBus from "./EventBus";
import { useTranslation } from 'react-i18next';

const ChargePrintOut = (props) => {
    const [t] = useTranslation();
    //Charge liquidate modal close function
    const { show, onClose, formValues } = props;
    const onSubmitForm = () => {
        eventBus.remove("couponApply", {});
        eventBus.dispatch("couponApply", { message: "coupone applied" });
    };

    return (
        <Modal show={show} onHide={onClose}>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>{t("printOut")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Check type="checkbox" id="rd1" className="mb-lg-4 bm-3" label={t("ReceiveandReceived.BackAddressingRequires")} />
                    <Form.Check type="checkbox" id="rd2" className="mb-lg-4 bm-3" label={t("ReceiveandReceived.Morelinesrequires")} />
                    <Form.Check type="checkbox" id="rd3" className="mb-lg-4 bm-3" label={t("ReceiveandReceived.RegisterinbillingCRM")} />
                    <FloatingLabel controlId="maturity" className="mb-lg-4 bm-3" label={t("maturity")}>
                        <Form.Control type="date" placeholder={t("maturity")} />
                    </FloatingLabel>
                    <Alert className="mb-0" variant="warning">
                        {t("ReceiveandReceived.Expirationwillonlychangeon")}.
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={onClose}>
                        {t("button.close")}
                    </Button>
                    <Button variant="primary" onClick={onSubmitForm}>
                        {t("button.toSave")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ChargePrintOut
