import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FloatingLabel, Container, Col, Row, Carousel, Form, Button, InputGroup, FormControl, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { userService } from "../../services/userService";
import CryptoJS from 'crypto-js';
import { secretKey } from "../../services/apiConfig";
import Loader from "../Profile/Company/Loader";
import { json } from "stream/consumers";

type UserSubmitForm = {
    email: string;
    password: string;
    connectionName: string | any;
    type: string | any;
    agency: string | any;
    code: string | any;
    confirmPassword: string | any;
};

const Login = () => {
    const [hideOnload, sethideOnload] = useState(false);
    const [passwordText, setForgotPasswordText] = useState('I forgot my password');
    const [isForgotPassword, setisForgotPassword] = useState(false);
    const [isForgotChange, setisForgotChange] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        let userDataa = localStorage.getItem('userData');
        let permissionDataa = localStorage.getItem("permissionData");
        if (userDataa && permissionDataa) {
            navigate("/dashboard");
            sethideOnload(false);
        } else {
            localStorage.clear();
            // commonService.logOut().then(res => {
            localStorage.removeItem('userData');
            localStorage.removeItem('permissionData');
            localStorage.removeItem('role');
            localStorage.removeItem("tokenV2");
            localStorage.removeItem("reportData");
            localStorage.removeItem("inspectionData");
            localStorage.removeItem("language");
            // });
            sethideOnload(true);
        }
    }, []);
    const [loader_on, setloader_on] = useState(false);
    const [t] = useTranslation();

    const loginSchema = Yup.object().shape({
        email: Yup.string().trim().nullable().email('Email must be a valid email').required(t('requiredMmessage.fieldRequired')),
        password: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired'))
    });
    const forgotPassSchema = Yup.object().shape({
        email: Yup.string().trim().nullable().email('Email must be a valid email').required(t('requiredMmessage.fieldRequired')),
        agency: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired'))
    });
    const resetPassScheme = Yup.object().shape({
        code: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        password: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        confirmPassword: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    });
    var frm = {
        resolver: yupResolver(loginSchema)
    }
    if (!isForgotPassword && !isForgotChange) {
        var frm = {
            resolver: yupResolver(loginSchema)
        }
    } else if (!isForgotChange && isForgotPassword) {
        var frm = {
            resolver: yupResolver(forgotPassSchema)
        }
    } else if (isForgotChange && !isForgotPassword) {
        var frm = {
            resolver: yupResolver(resetPassScheme)
        }
    }
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<UserSubmitForm>(frm);
    const onSubmit = (data: any) => {
        setloader_on(true);
        let params = {
            email: data.email,
            password: data.password,
            connectionName: '',
            type: '',
        };
        // Compare user info
        userService
            .agencyLogin(params)
            .then(async (res) => {
                if (res) {
                    if (res.resCode == 201 && res.data) {
                        localStorage.clear();
                        localStorage.removeItem('userData');
                        localStorage.removeItem('permissionData');
                        localStorage.removeItem('role');
                        localStorage.removeItem("tokenV2");
                        localStorage.removeItem("reportData");
                        localStorage.removeItem("inspectionData");
                        localStorage.removeItem("language");

                        localStorage.setItem("userData", JSON.stringify(res.data));
                        localStorage.setItem("tokenV2", res.data.auth_token)
                        localStorage.setItem("role", "loading...");
                        params.connectionName = JSON.stringify(res.data);
                        params.type = res.data.role
                        let result = await userService.userPermission(params);
                        if (result.resCode === 201 && result.data) {
                            setloader_on(false);
                            let permissionEncrypt = result?.data?.permissionId;
                            localStorage.setItem("agencyId", result?.data?.agencyId)
                            localStorage.setItem("agencyInitials", result?.data?.agencyInitials)
                            localStorage.setItem("userEmailId", result?.data?.userEmailId)
                            
                            let reportData:any = {
                                email          : data.email,
                                password       : data.password,
                                type           : 'report',
                            }
                            let inspectionData:any = {
                                email          : data.email,
                                password       : data.password,
                                type           : 'inspection',
                            }
                            let reportEnrpt:any = CryptoJS.AES.encrypt(JSON.stringify(reportData),secretKey).toString();
                            reportEnrpt = reportEnrpt.replace(/\//g, "@_@");
                            localStorage.setItem("reportData",reportEnrpt);

                            let inspectionEnrpt:any = CryptoJS.AES.encrypt(JSON.stringify(inspectionData),secretKey).toString();
                            inspectionEnrpt = inspectionEnrpt.replace(/\//g, "@_@");
                            localStorage.setItem("inspectionData",inspectionEnrpt);

                            let stringifyPermissionDataXUFER = JSON.stringify(res?.data?.plan);
                            const encryptedStringXUFER = CryptoJS.AES.encrypt(stringifyPermissionDataXUFER, secretKey).toString();
                            localStorage.setItem("XUFER", encryptedStringXUFER ?? "")

                            // 1111 -> Economic
                            // 2222 -> Essential
                            // 3333 -> Enterprise

                            localStorage.setItem("role", result?.data?.role == "agency" ? "1" : "2");
                            // let permissionEncrypt =  [1000,1001,1010,1020,1030,1031,1040,1041,1050,1060,1061,1070,1071,1080,1090,1091,2000,2001,2010,2011,2020,2030,2031,2040,2041,2050,2051,2060,2061,2070,2071,2080,2081,2090,2091,3000,3001,3010,3011,3020,3021,3030,3031,3032,3033,3034,3040,3041,3042,3043,3050,3051,3060,3070,3071,3080,3081,3090,3091]
                            let stringifyPermissionData = JSON.stringify(permissionEncrypt);
                            const encryptedString = CryptoJS.AES.encrypt(stringifyPermissionData, secretKey).toString();
                            localStorage.setItem("permissionData", encryptedString);

                            if (!res?.data?.plan) {
                                localStorage.removeItem("XUFER");
                            }
                            if (result?.data?.status == "1") {
                                navigate("/dashboard");
                            } else {
                                Swal.fire({
                                    title: t("Sweetalert.Error"),
                                    text: t("Sweetalert.userDeactiveErrorOccured"),
                                    // icon: 'success','error'
                                });
                                localStorage.removeItem("agencyId")
                                localStorage.removeItem("agencyInitials")
                                localStorage.removeItem('userData');
                                localStorage.removeItem('role');
                                localStorage.removeItem("permissionData");
                                localStorage.removeItem("XUFER");
                                localStorage.removeItem("language");
                                localStorage.removeItem("reportData");
                                localStorage.removeItem("inspectionData");
                            }
                        } else {
                            localStorage.removeItem('userData');
                            localStorage.removeItem('role');
                            localStorage.removeItem("reportData");
                            localStorage.removeItem("inspectionData");
                            localStorage.removeItem("language")
                            Swal.fire({
                                title: t("Sweetalert.Error"),
                                text: t("Sweetalert.permissionErrorOccured"),
                                // icon: 'success','error'
                            });
                            setloader_on(false);
                        }
                    } else {
                        // Invalid password
                        let sMsg = (res.data != '') ? res.data : res.message;
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: sMsg ? t("Sweetalert.invaliduserpassword") : t("Sweetalert.somethingWent!"),
                            // icon: 'success','error'
                        });
                        setloader_on(false);
                    }
                } else {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.wrongemail"),
                        // icon: 'success','error'
                    });
                    setloader_on(false);
                }
            }).catch((err) => {
                setloader_on(false);
            });
    };


    const [searchLoader, setSearchLoader] = useState<any>(false);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [selectedId, setSelectedId] = useState(0);

    const onForgotSubmit = (data: any) => {
        data["agency"] = selectedId;
        setloader_on(true);
        let params = {
            email: data.email,
            agencyId: data.agency
        };
        userService
            .resetPassword(params)
            .then(async (res) => {
                if (res) {
                    if (res.resCode == 200 || res.resCode == 201) {
                        setloader_on(false);
                        localStorage.setItem("agencyId", data.agency);
                        Swal.fire({
                            title: t("Sweetalert.Success"),
                            text: res.data,
                            // icon: 'success','success'
                        });
                        setisForgotPassword(false);
                        setisForgotChange(true);
                    } else {
                        let sMsg = (res.data != '') ? res.data : res.message;
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: sMsg ? t("Sweetalert.recoveryFailedPassword") : t("Sweetalert.recoveryFailedPassword!"),
                            // icon: 'success','error'
                        });
                        setloader_on(false);
                        setisForgotChange(false);
                    }
                } else {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.wrongemail"),
                        // icon: 'success','error'
                    });
                    setloader_on(false);
                    setisForgotChange(false);
                }
            })
            .catch((err) => {
                setloader_on(false);
            });
    };

    // CHange password
    const onResetSubmit = (data: any) => {
        let agencyId = localStorage.getItem('agencyId');
        setloader_on(true);
        if (data.password != data.confirmPassword) {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.passwordconform"),
                // icon: 'success','error'
            });
            setloader_on(false);
        } else {
            let params = {
                email: data.email,
                code: data.code,
                password: data.password,
                password_confirmation: data.confirmPassword,
                agencyId: agencyId
            };
            userService
                .cahngePassword(params)
                .then(async (res) => {
                    if (res) {
                        if (res.type == "Success" || res.type == "success") {
                            setloader_on(false);
                            localStorage.setItem("agencyId", data.agency);
                            Swal.fire({
                                title: t("Sweetalert.Success"),
                                text: t("Sweetalert.changepassword"),
                                // icon: 'success','success'
                            });
                            setisForgotPassword(false);
                            setisForgotChange(false);
                        } else {
                            let sMsg = (res.data != '') ? res.data : res.message;
                            Swal.fire({
                                title: t("Sweetalert.Error"),
                                text: sMsg ? t("Sweetalert.unableotchangeorupdate!") : t("Sweetalert.somethingWent!"),
                                // icon: 'success','error'
                            });
                            setloader_on(false);
                            setisForgotPassword(false);
                            setisForgotChange(true);
                        }
                    } else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.wrongemail"),
                            // icon: 'success','error'
                        });
                        setloader_on(false);
                        setisForgotPassword(false);
                        setisForgotChange(true);
                    }
                })
                .catch((err) => {
                    setloader_on(false);
                    setisForgotPassword(false); setisForgotChange(true);
                });
        }
    };
    const activeEye = (currCmd: string) => {
        if (currCmd == "true") {
            if (document.querySelectorAll(".inactiveEye").length > 0) {
                document.querySelector('.inactiveEye')?.removeAttribute("hidden");
            }
            if (document.querySelectorAll(".activeEye").length > 0) {
                document.querySelector('.activeEye')?.setAttribute('hidden', "");
            }
            if (document.querySelectorAll("#password").length > 0) {
                document.querySelector('#password')?.setAttribute('type', "text");
            }
        } else {
            if (document.querySelectorAll(".activeEye").length > 0) {
                document.querySelector('.activeEye')?.removeAttribute("hidden");
            }
            if (document.querySelectorAll(".inactiveEye").length > 0) {
                document.querySelector('.inactiveEye')?.setAttribute('hidden', "");
            }
            if (document.querySelectorAll("#password").length > 0) {
                document.querySelector('#password')?.setAttribute('type', "password");
            }
        }
    }
    const forgotPassword = (currCmd: string) => {
        setisForgotChange(false);
        if (!isForgotPassword) {
            setisForgotPassword(true);
            setForgotPasswordText(t("formLabel.rememberforget"))
        } else {
            setisForgotPassword(false);
            setForgotPasswordText(t("formLabel.forgetpassword"))
        }
    }
    const [listProperty, setListProperty] = useState<any>([]);
    var typingTimer;

    var searchAgency = (evt: any, name_string: any) => {
        var agency = evt.target.value;
        clearTimeout(typingTimer);
        if (listProperty && listProperty.length > 0) {
            setListProperty([])
        }
        typingTimer = setTimeout(() => {
            setSearchLoader(true);
            if (agency && agency.length > 0 && agency !== "") {
                userService
                    .findAgency({ agency: agency, type: "search_agency" })
                    .then(async (res) => {
                        if (res) {
                            if (res.resCode == 201 && res.data.length) {
                                setListProperty(res.data);
                            } else {
                                setListProperty([])
                                Swal.fire({
                                    title: t("Sweetalert.Error"),
                                    text: t("Sweetalert.agencynotfound"),
                                });
                            }
                        } else {
                            setListProperty([])
                            Swal.fire({
                                title: t("Sweetalert.Error"),
                                text: t("Sweetalert.agencynotfound"),
                            });
                        }
                        setSearchLoader(false);
                    }).catch((err) => {
                        setListProperty([])
                        setSearchLoader(false);
                    });
            } else {
                setListProperty([])
                setSearchLoader(false);
            }
        }, 900);
    };

    const handleInputFocus = (e) => {
        setIsInputFocused(true);
    };
    const HandleBankAccount = (e: any, item) => {
        setValue('agency', item?.Agency_name);
        setSelectedId(item?.Agency_ID);
        setIsInputFocused(false);
    }

    return (
        <main id="main">
            {
                hideOnload &&
                <section className="signin-section">
                    <Container fluid className="p-0">
                        <Row className="login-row g-0">
                            {/* <!-- Banner section start from here --> */}
                            <div className="page-primary-bg bg-primary d-none d-md-flex">
                                <Carousel className="login-slider">
                                    <Carousel.Item>
                                        <div className="slide-item-bg-1">
                                            <div className="blur">
                                                <p className="testemonial text-white h6 mb-4">With Kenlo you can have the entire
                                                    real
                                                    estate process in the same system, which gives you a broader view of the
                                                    process as a whole, property registration and search, customer service,
                                                    links with portals, marketing metrics. All in one system.</p>
                                                <p className="signature text-white">
                                                    <span className="fw-bold h6">Daniel Aranovich</span>
                                                    <br />
                                                    <span className="company text-white">
                                                        Imovit Imobiliária
                                                    </span>
                                                </p>
                                                <p className="float text-end">
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/slide-icon.png"} width="82"
                                                        alt="Slide Icon" />
                                                </p>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="slide-item-bg-2">
                                            <div className="blur">
                                                <p className="testemonial text-white h6 mb-4">O sistema Kenlo Imob é completo.
                                                    Ele nos
                                                    dá a oportunidade de ser pontual com nossos clientes, tanto os
                                                    proprietários quanto os compradores. Conseguimos administrar nosso
                                                    negócio com poucos acessos, com relatórios e controles completos. Super
                                                    indico esse sistema!</p>
                                                <p className="signature text-white">
                                                    <span className="fw-bold h6">Willians Aldo Fernandes</span>
                                                    <br />
                                                    <span className="company text-white">
                                                        Willians Fernandes Imóveis
                                                    </span>
                                                </p>
                                                <p className="float text-end">
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/slide-icon.png"} width="82"
                                                        alt="Slide Icon" />
                                                </p>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="slide-item-bg-3">
                                            <div className="blur">
                                                <p className="testemonial text-white h6 mb-4">With Kenlo you can have the entire
                                                    real
                                                    estate process in the same system, which gives you a broader view of the
                                                    process as a whole, property registration and search, customer service,
                                                    links with portals, marketing metrics. All in one system.</p>
                                                <p className="signature text-white">
                                                    <span className="fw-bold h6">Hamilton Páscoli</span>
                                                    <br />
                                                    <span className="company text-white">
                                                        Duo Imóveis
                                                    </span>
                                                </p>
                                                <p className="float text-end">
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/slide-icon.png"} width="82"
                                                        alt="Slide Icon" />
                                                </p>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                            {/* <!-- /Banner section end --> */}
                            {/* <!-- Login form start from here --> */}
                            <Col xs={12} sm={12} md={6} lg={7} id="loginContent">
                                <div className="login-wrapper">
                                    <div className="login-content">
                                        <div className="text-center mb-4 mb-lg-5 mb-xl-6">
                                            <a id="logo" className="login-logo" href="#" aria-label="logo Kenlo site"></a>
                                        </div>
                                        <div className="login-form-group px-lg-0 px-md-3">
                                            {/* LOGIN FORM */}
                                            {(!isForgotPassword && !isForgotChange) &&
                                                <Form action="" method="post" onSubmit={handleSubmit(onSubmit)}>
                                                    <FloatingLabel className="mb-4" controlId="email" label="Email">
                                                        <Form.Control className={`${errors.email ? 'is-invalid' : ''}`} placeholder="Email" {...register("email")}
                                                            type="text" aria-required="true" />
                                                        <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    <FloatingLabel className="mb-4" controlId="password" label="Password">
                                                        <Form.Control className={`${errors.password ? 'is-invalid' : ''}`}  {...register("password")} placeholder="Password" type="password" aria-required="true" />
                                                        <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                                                        <div className="password-toggle cursor-pe" aria-hidden="true">
                                                            <i className="activeEye icon" onClick={() => activeEye("true")}>
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/eye.svg"} alt="Hide password" />
                                                            </i>
                                                            <i className="inactiveEye icon" onClick={() => activeEye("false")} hidden>
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/eye_close.svg"} alt="View Password" />
                                                            </i>
                                                        </div>
                                                    </FloatingLabel>
                                                    <div className="form-group text-center">
                                                        {
                                                            loader_on ?
                                                                <Button className="btn-width">
                                                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">Verifying...</span>
                                                                </Button>
                                                                :
                                                                <Button type="submit" className="btn-width">Submit</Button>
                                                        }
                                                    </div>
                                                </Form>
                                            }

                                            {/* FORGOT PASSWORD */}
                                            {isForgotPassword &&
                                                <Form method="post" onSubmit={handleSubmit(onForgotSubmit)}>
                                                    {/* <FloatingLabel controlId="agencysearch" label="Search Agency">
                                                        <Form.Control placeholder="Search Agency" type="text" onKeyUp={(e) => searchAgency(e, "contract")} />
                                                    </FloatingLabel>
                                                    <br />
                                                    <FloatingLabel controlId="agency" label="Agency" className='closeMultiButton'>
                                                        <Form.Select className={`${errors.agency ? "form-control is-invalid" : "form-control"}`} aria-required="true" {...register("agency")} onChange={selectAgency}>
                                                            <option value="">{t('dropdownItem.select')}..</option>
                                                            {listProperty.map((curElem: any, index: number) =>
                                                                <option key={index} value={curElem.Agency_ID}>{curElem.Agency_name}</option>
                                                            )}
                                                        </Form.Select>
                                                    </FloatingLabel> */}
                                                    <FloatingLabel controlId="agency" label="Agency" className='closeMultiButton'>
                                                        <Form.Control type="text"
                                                            placeholder="Type to search..."
                                                            {...register('agency')}
                                                            onChange={(e) => searchAgency(e, "contract")}
                                                            onFocus={handleInputFocus} />
                                                        {isInputFocused && (
                                                            <ListGroup className="shadow position-absolute z-index-5 overflow-auto end-0 start-0 mt-1 bg-white" style={{ maxHeight: "260px" }}>
                                                                {
                                                                    searchLoader ?
                                                                        <>
                                                                            <Loader />
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {listProperty && listProperty.length > 0 &&
                                                                                listProperty.map((curElem: any, index: number) => {
                                                                                    return (
                                                                                        <>
                                                                                            {/* <option key={index} value={curElem.Agency_ID}>{curElem.Agency_name}</option> */}
                                                                                            <ListGroup.Item className="px-3 cursor-pe" key={index} onClick={(e) => HandleBankAccount(e, curElem)}>
                                                                                                {curElem.Agency_name}
                                                                                            </ListGroup.Item>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                }
                                                            </ListGroup>
                                                        )}
                                                    </FloatingLabel>
                                                    <br />

                                                    <FloatingLabel className="mb-4" controlId="email" label="Email">
                                                        <Form.Control className={`${errors.email ? 'is-invalid' : ''}`} placeholder="Email" {...register("email")} type="text" aria-required="true" />
                                                        <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                                                    </FloatingLabel>
                                                    <div className="form-group text-center">
                                                        {
                                                            loader_on ?
                                                                <Button className="btn-width">
                                                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                                                </Button>
                                                                :
                                                                <Button type="submit" className="btn-width">Submit</Button>
                                                        }
                                                    </div>
                                                </Form>
                                            }

                                            {/* RESET PASSWORD */}
                                            {isForgotChange &&
                                                <Form method="post" onSubmit={handleSubmit(onResetSubmit)}>
                                                    <InputGroup className="mb-4">
                                                        <FloatingLabel controlId="code" label="Code" className='closeMultiButton'>
                                                            <Form.Control className={`${errors.agency ? 'is-invalid' : ''}`} placeholder="code" {...register("code")} type="text" aria-required="true" />
                                                        </FloatingLabel>
                                                    </InputGroup>
                                                    <FloatingLabel className="mb-4" controlId="password" label="Password">
                                                        <Form.Control className={`${errors.password ? 'is-invalid' : ''}`}  {...register("password")} placeholder="Password" type="password" aria-required="true" />
                                                        <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                                                        <div className="password-toggle cursor-pe" aria-hidden="true">
                                                            <i className="activeEye icon" onClick={() => activeEye("true")}>
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/eye.svg"} alt="Hide password" />
                                                            </i>
                                                            <i className="inactiveEye icon" onClick={() => activeEye("false")} hidden>
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/eye_close.svg"} alt="View Password" />
                                                            </i>
                                                        </div>
                                                    </FloatingLabel>
                                                    <FloatingLabel className="mb-4" controlId="confirmPassword" label="Confirm Password">
                                                        <Form.Control className={`${errors.confirmPassword ? 'is-invalid' : ''}`}  {...register("confirmPassword")} placeholder="Confirm Password" type="password" aria-required="true" />
                                                        {/* <Form.Control.Feedback type="invalid">{errors.confirmPassword?.message}</Form.Control.Feedback> */}
                                                        <div className="password-toggle cursor-pe" aria-hidden="true">
                                                            <i className="activeEye icon" onClick={() => activeEye("true")}>
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/eye.svg"} alt="Hide password" />
                                                            </i>
                                                            <i className="inactiveEye icon" onClick={() => activeEye("false")} hidden>
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/eye_close.svg"} alt="View Password" />
                                                            </i>
                                                        </div>
                                                    </FloatingLabel>

                                                    <div className="form-group text-center">
                                                        {
                                                            loader_on ?
                                                                <Button className="btn-width">
                                                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                                                </Button>
                                                                :
                                                                <Button type="submit" className="btn-width">Submit</Button>
                                                        }
                                                    </div>
                                                </Form>
                                            }
                                            <p className="text-center mt-4 fw-bold">
                                                <a id="forgotPasswordLink" onClick={() => forgotPassword("true")} href="#">{passwordText}</a>
                                            </p>
                                        </div>
                                        <footer className="footer-login-container">
                                            <a href="#" target="_blank" rel="noreferrer" className="footer-status lh-1">
                                                <span className="text-dark">Kenlo Imob isonline</span>
                                                <span className="circle online" id="status-circle"></span>
                                            </a>
                                            <div>
                                                <span className="footer-talk">Can't get in? <a href="#" className="fw-bold">Chat with
                                                    our team</a></span>
                                            </div>
                                        </footer>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
        </main>
    )
}

export default Login