import { Modal, Form, Button, FloatingLabel, Accordion, Row, Col, Alert, InputGroup } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getAllAccounts, getAllBillMethodListing } from "../../../../redux/slice/expenditureDataSlice";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { liquidateForm } from '../../../Interface';
import customerService from '../../../../services/customerService';
import { incomeService } from '../../../../services/incomeServices';
import { gettoken } from '../../../../Api/Contact';
import Swal from 'sweetalert2';
import { openErrorbox } from '../../Contracts/Common/actionPerform';
//import moment, { weekdays } from "moment";
import moment, { weekdays } from "moment-timezone";

import { fetchService } from '../../../../redux/slice/ServiceSlice';
import { ServiceRecord, getTaxation } from '../../../../redux/slice/taxationSlice';
import { addBusinessDaysToDate, convertToNumber, converter, formatNumber, formatNumberEnglish } from '../../../constants/utils';
import { showChargeData,deleteBillingTicketCron } from '../../../../redux/slice/contractDataSlice';


const SettleLots = (props,liquidateForm) => {
    const [t] = useTranslation();
    const { billingData,billingStatus, accountData } = useSelector((state: RootState) => state.expenditureData)
    const dispatch = useDispatch<AppDispatch>()
    const [ double,setDouble ] = useState(false);
    const [ loader_on,setloader_on ] = useState(false);
    const [ customDt,setCustomDt ] = useState(false);
    const [ extraAndDiscount,setExtraAndDiscount ] = useState({});
    const { userbanklist,status } = useSelector((state: RootState) => state.bank)
    let obj = {
        ID_FORMAPAGAMENTO_RECB: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        ID_CONTA_CB: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        isSettleDueDate: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    }
    if(customDt){
        obj = {...obj,...{
            DT_LIQUIDACAO_RECB: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
        }}
    }
    const formValidation = Yup.object().shape(obj)
    useEffect(() => {
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
        dispatch(getAllAccounts({ start: 0, end: 1000 }));
    }, [dispatch])

    useEffect(() => {
        (async () => {
            let response = await incomeService.getDiscountData(gettoken.userId);
            setExtraAndDiscount(response.data);
        })()
    }, [])

    let frm = {
        resolver: yupResolver(formValidation)
    }
    const { showSettleLotsModal,settleLotsModalClose,settleLotsModalShow,id,state,getChargesSummery } = props;
    const settleLotsModalClosed = () =>{
        setCustomDt(false);
        setDouble(false);
        setloader_on(false);
        reset();
        settleLotsModalClose(false);
    }
    const { register, handleSubmit, setValue, getValues, reset, formState: { errors },setError, clearErrors } = useForm<liquidateForm>(frm);
    const onSubmit = async(data: any) => {
        setDouble(true);
        setloader_on(true);
        data.id = id;
        data.extraAndDiscount = extraAndDiscount;
        let response = await customerService.chargeBatchSettle(data);
        if (response.data.resCode === 201) {
            settleLotsModalClosed();
            getChargesSummery();
            Swal.fire({
                title: t("Sweetalert.Success"),
                text: t("Sweetalert.successget"),
                confirmButtonText: "OK",
            })
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.successfailed"),
                confirmButtonText: "OK",
            })
        }
    }

    const settleDate = async(evt)=>{
        setCustomDt(false);
        if(evt.target.value==0){
            setCustomDt(true);
        }        
    }
    
    return (
        <Modal show={showSettleLotsModal} onHide={settleLotsModalClosed}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("batchSettlement")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                        <Col md={6}>
                            <FloatingLabel controlId="bank_account" label={t("bankAccount")}>
                                <Form.Select aria-label={t("bankAccount")} className={`form-control ${errors.ID_CONTA_CB ? 'is-invalid' : ''}`} {...register("ID_CONTA_CB")}>
                                    <option value="">{t('dropdownItem.select')}..</option>
                                        {   
                                            accountData && accountData?.data?.length > 0 ?
                                                accountData.data.map((curElem: any, index) => {
                                                    return (
                                                        <option key={index} value={curElem.id}>{curElem.accountRef}</option>
                                                    )
                                                })
                                            : 
                                            <>
                                                <h3 style={{ width: "100%", textAlign: "center", color: "red" }}>{t("formLabel.noRecordsFounds")}!</h3>
                                            </>
                                        }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    { errors.ID_CONTA_CB?.message }
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                        <Col md={6}>
                            <FloatingLabel controlId="form_payment" label={t("formOfPayment")}>
                               <Form.Select aria-label="Form of payment" className={`form-control ${errors.ID_FORMAPAGAMENTO_RECB ? 'is-invalid' : ''}`} {...register("ID_FORMAPAGAMENTO_RECB")}>
                                    <option value="">{t("formLabel.select")}</option>
                                    {
                                        billingData.data && billingData.data.length > 0 ? billingData.data.map((item, index) => (
                                            <option key={index} value={Number(item.id)}>{item.name}</option>
                                        ))
                                            : ''
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    { errors.ID_FORMAPAGAMENTO_RECB?.message }
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Form.Check type="radio" id="cb_1" className={`mb-lg-4 mb-3 ${ errors.isSettleDueDate ? 'is-invalid' : ''}`} label={t("expenditure.settleontheduedate")}  {...register("isSettleDueDate") } onClick={settleDate} value="1" />
                    <Form.Check type="radio" id="cb_2" className="mb-lg-4 mb-3" label={t("sttleCustomDate")} {...register("isSettleDueDate") } onClick={settleDate} value="0"/>
                    <Form.Control.Feedback type="invalid">
                        { errors.isSettleDueDate?.message }
                    </Form.Control.Feedback>   
                    { 
                        customDt ?                             
                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                <Col md={6}>
                                    <FloatingLabel controlId="sale_off" label={t("customDate")}>
                                        <Form.Control type="date" className={`form-control ${ errors.DT_LIQUIDACAO_RECB ? 'is-invalid' : ''}` } placeholder={t("customDate")} {...register("DT_LIQUIDACAO_RECB")} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.DT_LIQUIDACAO_RECB?.message }
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        :
                            ''
                    }
                    {/*<Form.Check type="checkbox" id="cb_3" className="mb-lg-4 mb-3" label={t("ReceiveandReceived.Issuereceipt")} />*/}
                    {/*<Alert variant="warning" className="mb-0">
                        {t("ReceiveandReceived.Chargeswillbecalculatedforeach")}.
                    </Alert>*/}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={settleLotsModalClosed}>
                        {t("button.close")}
                    </Button>
                    {
                        loader_on ?
                            <Button className="btn-width" disabled={double}>
                                <span className="spinner-border spinner-border-sm"></span>
                                <span className="px-1">{t("button.toSave")}</span>
                            </Button>
                        :
                            <Button variant="primary" disabled={double} type="submit">
                                {t("button.toSave")}
                            </Button>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default SettleLots
