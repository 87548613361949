import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config, gettoken } from "../../Api/Contact";
let connectionName : any = '';
if( localStorage.getItem("userData") ){
  connectionName = localStorage.getItem("userData");
}
export const STATUSES = Object.freeze({
  IDLE      : "idle",
  LOADING: "loading",
  SUCEESS: "success",
  ERROR: 'error'
});

export interface Taxationofservices {
  CNPJ: string,
  municipal_registration: string,
  state_registration: string,
  approximate_amount_of_taxes: number,
  tax_regime: string,
  municipal: number,
  state: number,
  federal: number,
  cultural_encouraging: boolean,
  opt_for_simple_nacional: number,
  Withholding_IRRF: string,
  Withhold_income_tax: Number,
  PISWithholding: number,
  cofins_retention: number
  social_contribution_tax: number,
  withhold_tac: number,
  INSS_Withholding: number,
  TransmissãoNacional: string
  Taxregime1: string,
  typeofpatio:string,

}
export interface newTaxCodeModal {
  id: number,
  incurfederal: boolean,
  payIRRF: boolean
  payINSS: boolean,
  CNAE: number,
  servicecode: number,
  ISSQNrate: string,
  description: string,
  taxcode: number,
  termsAndConditions: string
}

export interface NFEservices {
  city_nfse: string,
  environment: number,
  accountcategory?: string,
  accountCategory?: string,
  certificate: string,
  password: string,
  city_code:string
}

interface AuthState {
  taxadata: Taxationofservices[],
  taxalist: newTaxCodeModal[],
  NFEservice1: NFEservices[],
  certificate: any,
  show: boolean,
  show2: boolean,
  show3: boolean,
  status: string,
  status1: string
}

const initialState: AuthState = {
  taxadata: [],
  taxalist: [],
  NFEservice1: [],
  certificate: [],
  show: false,
  show2: false,
  show3: false,
  status: STATUSES.IDLE,
  status1: STATUSES.IDLE,
}
let user_id = gettoken.userId
const taxationSlice = createSlice({
  name: "taxationSlice",
  initialState,
  reducers: {
    Newtaxopn: (state) => {
      state.show = true
    },
    Newtaxclose: (state) => {
      state.show = false
    },
    EditTaxation: (state) => {
      state.show3 = true
    },
    editTaxaClose: (state) => {
      state.show3 = false
    },
    Certificateopen: (state, { payload }) => {
      state.show2 = payload
    },
    Certificateclose: (state, { payload }) => {
      state.show2 = payload
    },
    addTaxation: (state, action) => {
      state.taxalist = [action.payload, ...state.taxalist];
      state.status = STATUSES.SUCEESS;
    },
    delete_Taxation: (state, action) => {
      state.taxalist = state.taxalist.filter((el) => el.id != action.payload);
    },
    NfEdit: (state, action) => {
      state.NFEservice1 = [action.payload]
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ServiceRecord.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(ServiceRecord.fulfilled, (state, action: PayloadAction<any>) => {
        state.taxadata = action.payload.data
        state.status = STATUSES.SUCEESS
      })
      .addCase(ServiceRecord.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(updateServiceRecord.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateServiceRecord.fulfilled, (state, action) => {
        state.status = STATUSES.SUCEESS;
      })
      .addCase(updateServiceRecord.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getTaxation.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getTaxation.fulfilled, (state, action: PayloadAction<any>) => {
        state.taxalist = action.payload.data
        state.status = STATUSES.SUCEESS
      })
      .addCase(getTaxation.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(addNewTaxation.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(addNewTaxation.fulfilled, (state, action: PayloadAction<any>) => {
        state.taxalist = action.payload.data
        state.status = STATUSES.SUCEESS
      })
      .addCase(addNewTaxation.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(updateServiceCode.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateServiceCode.fulfilled, (state, action: PayloadAction<any>) => {
        state.taxalist = action.payload.data
        state.status = STATUSES.SUCEESS
      })
      .addCase(updateServiceCode.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(Edit_taxation.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(Edit_taxation.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.SUCEESS
      })
      .addCase(Edit_taxation.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(Update_Taxation.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(Update_Taxation.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.SUCEESS
      })
      .addCase(Update_Taxation.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(deleteTaxation.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(deleteTaxation.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.SUCEESS
      })
      .addCase(deleteTaxation.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(getNfService.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getNfService.fulfilled, (state, action: PayloadAction<any>) => {
        state.NFEservice1 = action.payload.data
        state.status = STATUSES.SUCEESS
      })
      .addCase(getNfService.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(getCertificate.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getCertificate.fulfilled, (state, action: PayloadAction<any>) => {
        state.certificate = action.payload
        state.status = STATUSES.SUCEESS
      })
      .addCase(getCertificate.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(updateNfService.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateNfService.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.SUCEESS
      })
      .addCase(updateNfService.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(postCertificate.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(postCertificate.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.SUCEESS
      })
      .addCase(postCertificate.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
      builder
      .addCase(postCertificate_afterId.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(postCertificate_afterId.fulfilled, (state, action: PayloadAction<any>) => {
      })
      .addCase(postCertificate_afterId.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
      builder
      .addCase(GetregisterCompnay.pending, (state) => {
        state.status1 = STATUSES.LOADING;
      })
      .addCase(GetregisterCompnay.fulfilled, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.SUCEESS
      })
      .addCase(GetregisterCompnay.rejected, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.ERROR
      });
      builder
      .addCase(registerCompnay.pending, (state) => {
        state.status1 = STATUSES.LOADING;
      })
      .addCase(registerCompnay.fulfilled, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.SUCEESS
      })
      .addCase(registerCompnay.rejected, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.ERROR
      });
      builder
      .addCase(updateCompnay.pending, (state) => {
        state.status1 = STATUSES.LOADING;
      })
      .addCase(updateCompnay.fulfilled, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.SUCEESS
      })
      .addCase(updateCompnay.rejected, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.ERROR
      });
  }
})

export const {  addTaxation, delete_Taxation, Newtaxopn, EditTaxation, editTaxaClose, Newtaxclose, NfEdit, Certificateopen, Certificateclose } = taxationSlice.actions
export default taxationSlice.reducer

// GET - TAXATION SERVICE CODE LIST - API
export const getTaxation = createAsyncThunk(
  'gettaxation',
  async () => {
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/taxation/service_codes_and_rates/`, {
      method: 'GET',
      headers: {
        "Accept": "*/*",
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });
    const data = await response.json();
    return data
  }
);
// PUT UPDATE TAXATION RECORD
export const updateServiceCode = createAsyncThunk(
  'update_service_code',
  async (postData) => {
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/taxation/service_codes_and_rates/`, {
      method: 'PUT',
      body: JSON.stringify(postData),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    })
    const data = await response.json();
    return data
  }
);
// GET -  NF-E SERVICE  - API
export const getNfService = createAsyncThunk(
  'getNfEservice',
  async () => {
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/taxation/Nf_service/`, {
      method: 'POST',
      body: JSON.stringify({user_id:user_id}),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json; charset=UTF-8',
        'connection-name' : connectionName
      },
    });
    const data = await response.json();
    return data
  }
);

// GET -  CERTIFICATE  - API
export const getCertificate = createAsyncThunk(
  'getcertificate',
  async () => {
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/taxation/replace_certificate`, {
      method: 'GET',
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json; charset=UTF-8',
        'connection-name' : connectionName
      },
    });
    const data = await response.json();
    return data
  }
);

// PUT  NF-E SERVICE 
export const updateNfService = createAsyncThunk(
  'updatNfEservice',
  async (postData: any) => {
    postData["user_id"] = user_id;
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/taxation/Nf_service_update`, {
      method: 'PUT',
      body: JSON.stringify(postData),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    })
    const data = await response.json();
    return data
  }
);

//POST -- ADD_NEW_TAXATION TAXATION RECORD
export const addNewTaxation = createAsyncThunk(
  'Add_taxation_code',
  async (postData: any) => {
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/taxation/add_new_taxation`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });

    const data = await response.json();
    return data
  }
);


// EDIT TAXATION 
export const Edit_taxation = createAsyncThunk(
  'edit_taxation',
  async (id: number) => {
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/taxation/edit_taxation`, {
      method: 'POST',
      body: JSON.stringify({
        "id": id
      }),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });
    const data = await response.json();
    return data
  }
);
// POST EDIT BRANCHES -API
export const Update_Taxation = createAsyncThunk(
  'update_taxation ',
  async (branchData: any) => {
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/taxation/Update_taxation/`, {
      method: 'PUT',
      body: JSON.stringify(branchData),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });
    const data = await response.json();
    return data
  }
);
// DETELE TAXATION RECORD
export const deleteTaxation = createAsyncThunk(
  'delete_Single_Bank',
  async (id: number) => {
    let raw = JSON.stringify({
      "id": id
    });
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/taxation/delete_taxation/`, {
      method: 'DELETE',
      body: raw,
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });

    const data = await response.json();
    return data
  }
);

// Taxation of services rendered get data
export const ServiceRecord = createAsyncThunk(
  'user/ServiceRecord',
  async (_, thunkAPI) => {
    let bodyContent = JSON.stringify({
      "user_id": user_id
    });
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/taxation/taxation_of_services_rendered`,
      {
        method: 'POST',
        body: bodyContent,
        headers: {
          'x-auth-token': token,
          'Content-type': 'application/json',
          'connection-name' : connectionName
        },
      });
    const data = await response.json();
    return data;
  }
);

// POST  -  TAXATION OF SERVICES RENDERED -- API
export const updateServiceRecord = createAsyncThunk(
  'posts/updateServiceRecord',
  async (postData: any) => {
    postData["user_id"] = user_id;
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/taxation/update_of_service_rendered/`, {
      method: 'PUT',
      body: JSON.stringify(postData),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json; charset=UTF-8',
        'connection-name' : connectionName
      },
    });

    const data = await response.json();
    return data
  }
);


export const postCertificate = createAsyncThunk(
  'posts/postCertificate',
  async (bodyContent: any) => {
    let token:any = localStorage.getItem("tokenV2");
    let headersList = {
      "Accept": "*/*",
      "x-api-key": `${config.PLUGNOTAS_API_KEY}`,
      "Authorization": "Bearer yhA5NPIoRIF2hOzpI-32WSVaMCOpnmlfxAxTwq9XLpM",
      'x-auth-token': token,
      'connection-name' : connectionName
     }
     let response = await fetch(`${config.PLUGNOTAS_API}/certificado`, { 
       method: "POST",
       body: bodyContent,
       headers: headersList
     });
     let data = await response.json();
     
    return data;
  }
);

export const postCertificate_afterId = createAsyncThunk(
    'posts/postCertificate_afterId',
    async (bodyContent: any) => {
      let token:any = localStorage.getItem("tokenV2");
      const response = await fetch(`${config.companyV2_URL}/taxation/replace_certificate`, {
        method: 'POST',
        body: bodyContent,
        headers: {
          "Authorization": "Bearer yhA5NPIoRIF2hOzpI-32WSVaMCOpnmlfxAxTwq9XLpM",
          'x-auth-token': token,
          'connection-name' : connectionName
        },
      })
      let data = await response.json()
      return data
    }
  );

  export const postCertificate_data = createAsyncThunk(
    'posts/postCertificate',
    async (id: any) => {
      let token:any = localStorage.getItem("tokenV2");
      let headersList = {
        "x-api-key": `${config.PLUGNOTAS_API_KEY}`,
        "Authorization": "Bearer yhA5NPIoRIF2hOzpI-32WSVaMCOpnmlfxAxTwq9XLpM",
        'x-auth-token': token,
        'connection-name' : connectionName
       }
       let response = await fetch(`${config.PLUGNOTAS_API}/certificado/${id}`, { 
         method: "GET",
         headers: headersList
       });
       let data = await response.json();
      return data;
    }
  );

  export const GetregisterCompnay = createAsyncThunk(
    'posts/getregisterCompany',
    async (postData:any) => {
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${config.PLUGNOTAS_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
      console.log(postData)
        let response = await fetch(`${config.PLUGNOTAS_API}/empresa/${postData?.cpfCnpj}`, {
          method: 'GET',
          headers: myHeaders,
        });
        console.log(response, "response")
        let data = await response.json();
        return data
    }
  );  
  export const registerCompnay = createAsyncThunk(
    'posts/registerCompany',
    async (postData:any) => {
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${config.PLUGNOTAS_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify(postData); // Use postData received as input
        let response = await fetch(`${config.PLUGNOTAS_API}/empresa`, {
          method: 'POST',
          headers: myHeaders,
          body: raw,
        });
        let data = await response.json();
        return data
    }
  );  
  export const updateCompnay = createAsyncThunk(
    'posts/updateCompany',
    async (postData:any) => {
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${config.PLUGNOTAS_API_KEY}`,);
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify(postData);
    const response = await  fetch(`${config.PLUGNOTAS_API}/empresa/${postData?.cpfCnpj}`, {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
      })
      let data = await response.json();
      return data;
    }
  );
  
  export const DownloadsPdf = createAsyncThunk(
    'post/downloadspdf',
    async (id:any) =>{
      let token:any = localStorage.getItem("tokenV2");
      let headersList = {
        "Accept": "*/*",
        "Content-Type": "application/pdf",
        "x-api-key": `${config.PLUGNOTAS_API_KEY}`,
        "Authorization": "8860fa83-d15c-4c3f-8c37-26977713bbb9",
        'x-auth-token': token,
        'connection-name' : connectionName
       }
       let response = await fetch(`${config.PLUGNOTAS_API}/nfse/pdf/${id}`, { 
         method: "GET",
         headers: headersList
       });
      return response
    }
  )
