import { Accordion, FloatingLabel, InputGroup, Dropdown, Col, Row, Modal, Table, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { MultiSelect } from "react-multi-select-component";
import Liquidate2 from './liquidate2';

import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import Loader from '../../Profile/Company/Loader';
import FinancialExpenseService from '../Contracts/Common/FinancialExpenseService';
import moment from "moment";
import { CNJP_CPFValidation, encrypt_Decrypt, checkDuplicateCNJP_CPF, converter } from '../../constants/utils';
import { useForm } from 'react-hook-form';
import Pagination from '../../Pagination';

const BankTransactionsListing = (props) => {
    let { bankTransList, setbankTransList, isCheckId, setIsCheckId, handleAllCheck, currentPage, totalpage, PageSize, setCurrentPage } = props;

    // const {  } = useSelector((state: RootState) => state.financialData)
    const { bank_transaction } = useSelector((state: RootState) => state.bankTransaction)
    const { register, handleSubmit, reset, setValue, clearErrors, setError, formState: { errors } } = useForm();

    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [status, setStatus] = useState("");
    // Modal's initiate
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    // More filters modal
    const moreFiltersClose = () => setShow(false);
    const moreFiltersShow = () => setShow(true);

    //LiquidateModalShow2 modal
    const [showLiquidateModal2, setLiquidateModal2] = useState(false);
    const liquidateModalClose2 = () => setLiquidateModal2(false);
    const liquidateModalShow2 = () => setLiquidateModal2(true);

    //New release modal
    const [showNewReleaseModal, setNewReleaseModal] = useState(false);
    const newReleaseModalClose = () => setNewReleaseModal(false);
    const newReleaseModalShow = () => setNewReleaseModal(true);

    //Import expense modal
    const [showImportExpenseModal, setImportExpenseModal] = useState(false);
    const importExpenseModalClose = () => setImportExpenseModal(false);
    const importExpenseModalShow = () => setImportExpenseModal(true);

    //New transfer modal
    const [showNewTransferModal, setNewTransferModal] = useState(false);
    const newTransferModalClose = () => setNewTransferModal(false);
    const newTransferModalShow = () => setNewTransferModal(true);

    //Issue check modal
    const [showIssueCheckModal, setIssueCheckModal] = useState(false);
    const issueCheckModalClose = () => setIssueCheckModal(false);
    const issueCheckModalShow = () => setIssueCheckModal(true);

    //new supplier modal
    const [showNewSupplierModal, setNewSupplierModal] = useState(false);
    const newSupplierModalClose = () => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        setNewSupplierModal(false);
    }
    const newSupplierModalShow = () => {
        setNewSupplierModal(true);
    }

    //new retention modal
    const [showNewRetentionModal, setNewRetentionModal] = useState(false);
    const newRetentionModalClose = () => {
        setNewRetentionModal(false);
    }
    const newRetentionModalShow = () => {
        setNewRetentionModal(true);
    }

    // Mobile filters modal
    const mobileFiltersClose = () => setShow2(false);
    const mobileFiltersShow = () => setShow2(true);
    //Generate all modal
    const generateModalClose = () => setShow3(false);
    const generateModalShow = () => setShow3(true);

    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        setUserJson(userResult);
    }, [])

    const selectAllUsers = () => {
        if (bankTransList.length) {
            setbankTransList(bankTransList.map(topping => ({ ...topping, isChecked: true })))
            let allId: any = [];
            bankTransList.forEach(item => allId.push(item.ID_RECEBIMENTO_RECB))
            setIsCheckId(allId);
        }
    }
    const unSelectAllUsers = () => {
        if (bankTransList) {
            setbankTransList(bankTransList.map(topping => ({ ...topping, isChecked: false })))
            setIsCheckId([]);
        }
    }

    const selectRow = async (e) => {
        setTimeout(() => {
            if (!e.target.checked) {
                e.target.checked = false;
                eitherChecked(e.target.getAttribute('data-id'), false)
            } else {
                e.target.checked = true;
                eitherChecked(e.target.getAttribute('data-id'), true)
            }
        }, 10);
    }

    const eitherChecked = (id, type) => {
        let isAvailable = isCheckId.filter(item => Number(item) == Number(id));
        if (isAvailable.length > 0) {
            let newData: any = [];
            let indexValue = isCheckId.indexOf(id);
            isCheckId.splice(indexValue, 1);
            newData = isCheckId
            setIsCheckId(newData);
        } else {
            setIsCheckId([...isCheckId, id])
        };

        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            setTimeout(() => {
                let checkedAllId: any = false;
                data.forEach((item, index) => {
                    if (!item["checked"]) {
                        checkedAllId = true;
                    }
                })

                let checkedAll: any = document.querySelector('.mainCheckbox');
                if (checkedAllId) {
                    checkedAll["checked"] = false;
                } else {
                    checkedAll["checked"] = true;
                }
            }, 10)
        }
    }

    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name)
            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
                if (duplicateData["data"] > 0) {
                    zipCodeCheck?.["setAttribute"]('disabled', "")
                    setError(name, {
                        type: "manual",
                        message: t("formLabel.ErrorCNPJCPF")
                    });
                } else {
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            // errorCNPJCPF(e.target.value)
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    return (
        <>
            {
                (status == "loading") ?
                    <Loader />
                    :
                    <Table responsive id="BankTransactionsListingtsx" className="table table-custom table-custom-bordered mb-0">
                        <thead>
                            <tr>
                                <th className="w-24"><input type="checkbox" className="form-check-input checkbox-sm mainCheckbox" onClick={(e) => handleAllCheck(e, "all")} /></th>
                                <th></th>
                                <th>{t("bankTransaction.date")}</th>
                                <th>{t("historic")}</th>
                                <th className="text-end">{t("bankTransaction.Revenue")}</th>
                                <th className="text-end">{t("bankTransaction.Expenses")}</th>
                                <th className="text-end">{t("bankTransaction.Balance")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bankTransList.data && bankTransList.data.length > 0 &&
                                bankTransList.data.map((item: any, index) => {
                                    let sperateCurr = Math.sign(item["VL_VALOR_MOV"])
                                    let balanceCurrency = Math.sign(item["balance"]);
                                    const historicoMov = item.ST_HISTORICO_MOV || "";
                                    const containsDigit:any = /\d/.test(historicoMov);
                                    return (
                                        <tr key={index}>
                                            <td className='check_Checkbox'><Form.Check type="checkbox" data-id={item.ID_CONTABANCO_MOV} onChange={selectRow} checked={item.isChecked} value={item.ID_CONTABANCO_MOV} /></td>
                                            
                                            
                                            
                                            <td className="text-nowrap">
                                                {item["ID_ORIGEMVIRTUAL_MOV"] == 1 ?
                                                    <>
                                                        {
                                                            userJson && userJson.length > 0 && (userJson.indexOf(3011) !== -1) &&
                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("button.edit")}</Tooltip>}>
                                                                <img src="assets/images/icons/edit-light.svg" alt="Edit" className="cursor-pe h-16 me-2" />
                                                            </OverlayTrigger>
                                                        }
                                                        {
                                                            userJson && userJson.length > 0 && (userJson.indexOf(3031) !== -1) &&
                                                            <>
                                                                <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("expenditure.liquidate")}</Tooltip>}>
                                                                    <img src="assets/images/icons/liquidate-light.svg" alt="liquidate" onClick={liquidateModalShow2} className="cursor-pe h-18 me-2" />
                                                                </OverlayTrigger>
                                                                <Liquidate2 showLiquidate2={showLiquidateModal2} liquidateClose2={liquidateModalClose2} />
                                                            </>
                                                        }
                                                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("terminatelist.Attachfile")}</Tooltip>}>
                                                            <img src="assets/images/icons/attach.svg" alt="Attach file" className="cursor-pe h-18 me-2" />
                                                        </OverlayTrigger>
                                                        {
                                                            userJson && userJson.length > 0 && (userJson.indexOf(3041) !== -1) &&
                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("bankTransaction.Cloneexpense")}</Tooltip>}>
                                                                <img src="assets/images/icons/uploadfile.svg" alt="Clone expense" className="cursor-pe h-16 me-2" />
                                                            </OverlayTrigger>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <div></div>
                                                    </>
                                                }
                                            </td>


                                            
                                            <td className="text-dark-70"> {
                                                item["DT_LANCAMENTO_MOV"] ? moment(item["DT_LANCAMENTO_MOV"]).format("DD/MM/YYYY") : ''
                                            }</td>
                                            <td>
                                                <span className="text-dark-70">
                                                    {
                                                        (item["ST_NOME_PES"] != '' && sperateCurr != 1) ?
                                                            <>
                                                                <span className="w-100 d-block rent-rent">{t("transfer")} - {item["ST_NOME_PES"]} </span>
                                                                <span className="w-100 d-block rent-rent">{t("customerAndEmployees.with")} {item?.["paymentMethod"] == 0 ? t("customerAndEmployees.boleto") : item?.["paymentMethod"] == 1 ? t("customerAndEmployees.Cheque") : item?.["paymentMethod"] == 2 ? t("customerAndEmployees.money") : item?.["paymentMethod"] == 3 ? t("customerAndEmployees.creditcard") : item?.["paymentMethod"] == 4 ? t("customerAndEmployees.Debitcard") : item?.["paymentMethod"] == 8 ? t("customerAndEmployees.Transferbanking") : item?.["paymentMethod"] == 10 ? t("customerAndEmployees.others") : item?.["paymentMethod"] == 12 ? t("customerAndEmployees.pix") : item?.["paymentMethod"] == 13 ? t("customerAndEmployees.Automatictransfer") : ""  } {item["ST_NOMEBANCO_BAN"]}</span>
                                                                <span className="w-100"> {t("ReceiveandReceived.Paid")} </span>
                                                            </> : 
                                                            <>{ item["DT_LANCAMENTO_MOV"] && item.ID_RECEBIMENTO_RECB!="Previous balance" ? item["DT_LANCAMENTO_MOV"] ? t("Dashboard.Received") : t("bankAccount")+" :- " : "" }  </>
                                                    }
                                                    { item.ID_RECEBIMENTO_RECB ? item.ID_RECEBIMENTO_RECB : item.ID_CONTABANCO_MOV}
                                                    <span className="w-100 d-block rent-rent">{ containsDigit !== "" ? item["ST_HISTORICO_MOV"] : t(`bankTransaction.${item["ST_HISTORICO_MOV"]}`)}</span>
                                                </span>
                                            </td>
                                            {
                                                sperateCurr == 1 ?
                                                    <>
                                                        <td className="text-end">
                                                            <span className="text-nowrap d-block">R$ {converter(`${item["VL_VALOR_MOV"]}`) }</span>
                                                        </td>
                                                        <td className="text-end">R$ 0.00</td>
                                                    </> 
                                                :
                                                    <>
                                                        <td className="text-end">R$ 0.00</td>
                                                        <td className="text-end text-danger-40"><span className="text-nowrap d-block">R$ {converter(`${item["VL_VALOR_MOV"]}`)}</span></td>
                                                    </>
                                            }
                                            {
                                                // Math.sign(Number(item["NM_PARTICIPACAO_MOVA"])) == 1 ?
                                                //     <td>{Number(item["NM_PARTICIPACAO_MOVA"]).toFixed(2)}</td>
                                                //     :
                                                //     <>
                                                //         {
                                                //             Number(item["NM_PARTICIPACAO_MOVA"]) == 0 ?
                                                //                 <td>R$ {Number(item["NM_PARTICIPACAO_MOVA"]).toFixed(2)}</td>
                                                //                 :
                                                //                 <td style={{ "color": "red" }}> R$ {Number(item["VL_VALOR_MOV"]).toFixed(2)}</td>
                                                //         }
                                                //     </>
                                                balanceCurrency == 1 ?
                                                    <td className="text-end"><span className="text-nowrap d-block">R$ {converter(`${item["balance"]}`)}</span></td>
                                                :
                                                    <td className="text-end text-danger-40"><span className="text-nowrap d-block">R$ {converter(`${item["balance"]}`)}</span></td>
                                            }
                                        </tr>
                                    )
                                }
                                )}
                            {
                                bankTransList.data && !bankTransList.data.length ? (
                                <tr>
                                        <td colSpan={7} className="text-center">{t("ReceiveandReceived.NochargesFound")}.</td>
                                    </tr>
                                ) : (<></>)
                            }
                        </tbody>
                    </Table>

            }

            {/* {
                totalpage > 0 &&
                <div className="d-flex justify-content-center my-1">
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalpage}
                        pageSize={PageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            } */}

            {/*<!-- /Main content end -->*/}
            {/*<!-- New expense modal start -->*/}

            {/*<!-- New expense modal end -->*
        {/*<!-- New retention modal start -->*/}
            <Modal show={showNewRetentionModal} onHide={newRetentionModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("bankTransaction.Newretention")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="tax" className="mb-3 mb-lg-4" label={t("contractlistother.Tax")}>
                            <Form.Control type="text" placeholder={t("contractlistother.Tax")} />
                        </FloatingLabel>
                        <FloatingLabel controlId="code" className="mb-3 mb-lg-4" label={t("contractlistother.Code")}>
                            <Form.Control type="text" placeholder={t("contractlistother.Code")} />
                        </FloatingLabel>
                        <FloatingLabel label={t("formLabel.accountCategory")} className="mb-3 mb-lg-4">
                            <Form.Select aria-label="Account (category)">
                                <option value="">{t("TransferPage.Tocarryout")}</option>
                                <option value="">{t("TransferPage.Performed")}</option>
                            </Form.Select>
                        </FloatingLabel>
                        <FloatingLabel controlId="aliquot" className="mb-3 mb-lg-4" label={t("contractsettings.Aliquot") + "(%)"}>
                            <Form.Control type="text" placeholder={t("contractsettings.Aliquot") + "(%)"} />
                        </FloatingLabel>
                        <InputGroup className="mb-3 mb-lg-4">
                            <FloatingLabel label={t("bankTransaction.collectionagency")} >
                                <Form.Select aria-label="collection agency" >
                                    <option value="">{t("formLabel.select")}</option>
                                    <option value="">{t("bankTransaction.collectionagency")}</option>
                                </Form.Select>
                            </FloatingLabel>
                            <InputGroup.Text title={t("formLabel.supplier")}>
                                {/* <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20"  onClick={newSupplierModalShow}/> */}
                            </InputGroup.Text>
                        </InputGroup>
                        <FloatingLabel controlId="ledger" className="mb-3 mb-lg-4" label={t("comparetolastmonth.ledgeraccount")}>
                            <Form.Control type="text" placeholder={t("comparetolastmonth.ledgeraccount")} />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-sm-flex justify-content-end w-100 align-items-center">
                            <Button variant="outline-primary" onClick={newRetentionModalClose}>
                                {t("button.close")}
                            </Button>
                            <Button variant="primary" onClick={newRetentionModalClose}>
                                {t("button.save")}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/*<!-- New retention modal end -->*/}
            {/* New supplier modal start from here */}
            <Modal show={showNewSupplierModal} onHide={newSupplierModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("customerAndEmployees.newSuppliers")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="cnpj" className="mb-3 mb-lg-4" label="CNPJ/CEI/CPF">
                            <Form.Control type="text" placeholder="CNPJ/CEI/CPF" onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-sm-flex justify-content-end w-100 align-items-center">
                            <Button variant="outline-primary" onClick={newSupplierModalClose}>
                                {t("button.close")}
                            </Button>
                            <Button variant="primary" onClick={newSupplierModalClose} className='zipCodeCheck'>
                                {t("button.next")}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /New supplier modal end */}
            {/* New recipe modal start from here */}
            <Modal show={show} onHide={moreFiltersClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("bankTransaction.NewRecipe")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="maturity" className="mb-3 mb-lg-4" label={t("maturity")}>
                            <Form.Control type="date" placeholder={t("maturity")} />
                        </FloatingLabel>
                        <Accordion className="accordion-custom">
                            <Accordion.Item eventKey="17">
                                <Accordion.Header>{t("value")}</Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel label={t("formLabel.accountCategory")} className="mb-3 mb-lg-4">
                                        <Form.Select aria-label="Account (category)">
                                            <option value="">{t("TransferPage.Tocarryout")}</option>
                                            <option value="">{t("TransferPage.Performed")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                    <FloatingLabel className="mb-3 mb-lg-4" label={t("complement")}>
                                        <Form.Control type="Text" aria-label="Complement" placeholder={t("complement")} />
                                    </FloatingLabel>
                                    <FloatingLabel className="mb-3 mb-lg-4" label={t("contractlistother.Amount")}>
                                        <Form.Control type="Text" aria-label="Amount" placeholder={t("contractlistother.Amount")} />
                                    </FloatingLabel>
                                    <FloatingLabel className="mb-3 mb-lg-4" label={t("value")}>
                                        <Form.Control type="Text" aria-label="Value" placeholder={t("value")} />
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="18">
                                <Accordion.Header>{t("bankTransaction.Discounts")}</Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel label={t("formLabel.accountCategory")} className="mb-3 mb-lg-4">
                                        <Form.Select aria-label="Account (category)">
                                            <option value="">{t("TransferPage.Tocarryout")}</option>
                                            <option value="">{t("TransferPage.Performed")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                    <FloatingLabel className="mb-3 mb-lg-4" label={t("complement")}>
                                        <Form.Control type="Text" aria-label="Complement" placeholder={t("complement")} />
                                    </FloatingLabel>
                                    <FloatingLabel className="mb-3 mb-lg-4" label={t("value")}>
                                        <Form.Control type="Text" aria-label="Value" placeholder={t("value")} />
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="19">
                                <Accordion.Header>{t("formLabel.moreFields")}</Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel controlId="Issuance" className="mb-3 mb-lg-4" label={t("bankTransaction.Issuance")}>
                                        <Form.Control type="date" placeholder={t("bankTransaction.Issuance")} />
                                    </FloatingLabel>
                                    <FloatingLabel label={t("formLabel.accountCategory")} className="mb-3 mb-lg-4">
                                        <Form.Select aria-label="Account (category)">
                                            <option value="">{t("TransferPage.Tocarryout")}</option>
                                            <option value="">{t("TransferPage.Performed")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                    <FloatingLabel className="mb-3 mb-lg-4" label={t("historic")}>
                                        <Form.Control type="Text" aria-label="Historic" placeholder={t("historic")} />
                                    </FloatingLabel>
                                    <FloatingLabel className="mb-3 mb-lg-4" label={t("formLabel.comments")}>
                                        <Form.Control type="Text" aria-label="Comments" placeholder={t("formLabel.comments")} />
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <FloatingLabel className="mb-3 mb-lg-4" label={t("contractlistother.Amount")}>
                            <Form.Control type="Text" aria-label="Amount" placeholder={t("contractlistother.Amount")} />
                        </FloatingLabel>
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id={`culturalEncouraging`} label={t("paidTobepaid.Ithasbeenpaid")} />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-sm-flex justify-content-end w-100 align-items-center">
                            <Button variant="outline-primary" className="me-2 me-lg-3" onClick={moreFiltersClose}>
                                {t("button.close")}
                            </Button>
                            <Button variant="primary" onClick={moreFiltersClose}>
                                {t("button.done")}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /New recipe modal end */}


            {/* New release modal start from here */}
            <Modal show={showNewReleaseModal} onHide={newReleaseModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("bankTransaction.Newrelease")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="Maturity" className="mb-3 mb-lg-4" label={t("maturity")}>
                            <Form.Control type="Date" placeholder={t("maturity")} />
                        </FloatingLabel>
                        <FloatingLabel label={t("formLabel.accountCategory")} className="mb-3 mb-lg-4">
                            <Form.Select aria-label="Account (category)">
                                <option value="">{t("TransferPage.Tocarryout")}</option>
                                <option value="">{t("TransferPage.Performed")}</option>
                            </Form.Select>
                        </FloatingLabel>
                        <FloatingLabel controlId="Complement" className="mb-3 mb-lg-4" label={t("complement")}>
                            <Form.Control type="Date" placeholder={t("complement")} />
                        </FloatingLabel>
                        <FloatingLabel controlId="Value" className="mb-3 mb-lg-4" label={t("value")}>
                            <Form.Control type="Date" placeholder={t("value")} />
                        </FloatingLabel>
                        <Form.Group>
                            <Form.Check type="checkbox" label={t("paidTobepaid.Ithasbeenpaid")} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-sm-flex justify-content-end w-100 align-items-center">
                            <Button variant="outline-primary" onClick={newReleaseModalClose}>
                                {t("button.close")}
                            </Button>
                            <Button variant="primary" onClick={newReleaseModalClose}>
                                {t("expenditure.tothrow")}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* New transfer modal start from here */}
            <Modal show={showNewTransferModal} onHide={newTransferModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("bankTransaction.Newtransfer")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="date" className="mb-3 mb-lg-4" label={t("bankTransaction.date")}>
                            <Form.Control type="date" placeholder={t("bankTransaction.date")} />
                        </FloatingLabel>
                        <FloatingLabel controlId="value" className="mb-3 mb-lg-4" label={t("value")}>
                            <Form.Control type="text" placeholder={t("value")} />
                        </FloatingLabel>
                        <FloatingLabel label={t("in")} className="mb-3 mb-lg-4">
                            <Form.Select aria-label="In">
                                <option value="">{t("TransferPage.Tocarryout")}</option>
                                <option value="">{t("TransferPage.Performed")}</option>
                            </Form.Select>
                        </FloatingLabel>
                        <FloatingLabel label={t("notifications.for")} className="mb-3 mb-lg-4">
                            <Form.Select aria-label="For">
                                <option value="">{t("TransferPage.Tocarryout")}</option>
                                <option value="">{t("TransferPage.Performed")}</option>
                            </Form.Select>
                        </FloatingLabel>
                        <Accordion className="accordion-custom">
                            <Accordion.Item eventKey="17">
                                <Accordion.Header>{t("banks.moreOptions")}</Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel controlId="history" className="mb-3 mb-lg-4" label={t("bankTransaction.Historycomplement")}>
                                        <Form.Control type="text" placeholder={t("bankTransaction.Historycomplement")} />
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-sm-flex justify-content-end w-100 align-items-center">
                            <Button variant="outline-primary" onClick={newTransferModalClose}>
                                {t("button.close")}
                            </Button>
                            <Button variant="primary" onClick={newTransferModalClose}>
                                {t("formLabel.transfer")}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /New transfer modal end */}
            {/* Import expense modal start from here */}
            <Modal show={showImportExpenseModal} onHide={importExpenseModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("bankTransaction.ImportexpenseviaNF-e")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="invoice" className="mb-3 mb-lg-4" label={t("bankTransaction.Invoice(Xml)")}>
                            <Form.Control type="file" placeholder={t("bankTransaction.Invoice(Xml)")} />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-sm-flex justify-content-end w-100 align-items-center">
                            <Button variant="outline-primary" onClick={importExpenseModalClose}>
                                {t("button.close")}
                            </Button>
                            <Button variant="primary" onClick={importExpenseModalClose}>
                                {t("button.next")}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Import expense modal end */}

            {/* New retention modal start from here */}
            <Modal show={showNewRetentionModal} onHide={newRetentionModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("bankTransaction.Newretention")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="tax" className="mb-3 mb-lg-4" label={t("contractlistother.Tax")}>
                            <Form.Control type="text" placeholder={t("contractlistother.Tax")} />
                        </FloatingLabel>
                        <FloatingLabel controlId="code" className="mb-3 mb-lg-4" label={t("contractlistother.Code")}>
                            <Form.Control type="text" placeholder={t("contractlistother.Code")} />
                        </FloatingLabel>
                        <FloatingLabel label={t("formLabel.accountCategory")} className="mb-3 mb-lg-4">
                            <Form.Select aria-label="Account (category)">
                                <option value="">{t("TransferPage.Tocarryout")}</option>
                                <option value="">{t("TransferPage.Performed")}</option>
                            </Form.Select>
                        </FloatingLabel>
                        <FloatingLabel controlId="aliquot" className="mb-3 mb-lg-4" label={t("contractsettings.Aliquot") + "(%)"}>
                            <Form.Control type="text" placeholder={t("contractsettings.Aliquot") + "(%)"} />
                        </FloatingLabel>
                        <InputGroup className="mb-3 mb-lg-4">
                            <FloatingLabel label={t("bankTransaction.collectionagency")} >
                                <Form.Select aria-label="collection agency" >
                                    <option value="">{t("formLabel.select")}</option>
                                    <option value="">{t("bankTransaction.collectionagency")}</option>
                                </Form.Select>
                            </FloatingLabel>
                            <InputGroup.Text title={t("formLabel.supplier")}>
                                {/* <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20"  onClick={newSupplierModalShow}/> */}
                            </InputGroup.Text>
                        </InputGroup>
                        <FloatingLabel controlId="ledger" className="mb-3 mb-lg-4" label={t("comparetolastmonth.ledgeraccount")}>
                            <Form.Control type="text" placeholder={t("comparetolastmonth.ledgeraccount")} />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-sm-flex justify-content-end w-100 align-items-center">
                            <Button variant="outline-primary" onClick={newRetentionModalClose}>
                                {t("button.close")}
                            </Button>
                            <Button variant="primary" onClick={newRetentionModalClose}>
                                {t("button.save")}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /New retention modal end */}
            {/* Filter modal for mobile screens start here */}
            <Modal show={show2} onHide={mobileFiltersClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("bankTransaction.Filter")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Accordion className="accordion-custom">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{t("TransferPage.Filterby")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("TransferPage.Filterby")}>
                                            <Form.Select aria-label="Filter by">
                                                <option value="">{t("TransferPage.Tocarryout")}</option>
                                                <option value="">{t("TransferPage.Performed")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>{t("Dashboard.Owner")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("Dashboard.Owner")}>
                                            <Form.Select aria-label="Owner">
                                                {/* <option value="">{t("dropdownItem.physicalAndLegal")}</option> */}
                                                <option value="">{t("dropdownItem.physical")}</option>
                                                <option value="">{t("dropdownItem.legal")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>{t("TransferPage.Withpaidcharges")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("TransferPage.Withpaidcharges")}>
                                            <Form.Select aria-label="With paid charges">
                                                <option value="">{t("formLabel.all")}</option>
                                                <option value="">{t("Dashboard.Overdue")}</option>
                                                <option value="">{t("bankTransaction.Inday")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>{t("bankTransaction.Withchargessettlededbetween")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("bankTransaction.Withchargessettlededbetween")}>
                                            <Form.Control type="date" aria-label="With charges settled between"></Form.Control>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>{t("TransferPage.Duetotheduedatebetween")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("TransferPage.Duetotheduedatebetween")}>
                                            <Form.Control type="date" aria-label="Due to the due date between"></Form.Control>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>{t("ofthecontract")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("ofthecontract")}>
                                            <Form.Control type="date" aria-label="Of the contracts"></Form.Control>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>{t("TransferPage.Fromtheowners")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("TransferPage.Fromtheowners")}>
                                            <Form.Control type="date" aria-label="From the owners"></Form.Control>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>{t("TransferPage.Withouttheowners")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("TransferPage.Withouttheowners")}>
                                            <Form.Control type="date" aria-label="Without the owners"></Form.Control>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="15">
                                    <Accordion.Header>{t("bankTransaction.Withpaymentstatus")}</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Select aria-label="With payment status">
                                            <option value="">{t("formLabel.all")}</option>
                                            <option value="">{t("ReceiveandReceived.Paid")}</option>
                                            <option value="">{t("bankTransaction.Pendingpayment")}</option>
                                            <option value="">{t("bankTransaction.clearingcheck")}</option>
                                        </Form.Select>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="16">
                                    <Accordion.Header>{t("paymentSplit")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel className="mb-3 mb-lg-4" label={t("paymentSplit")}>
                                            <Form.Select aria-label="Payment split">
                                                <option value="">{t("formLabel.all")}</option>
                                                <option value="">{t("bankTransaction.AutomaticforwardingviaSplit")}</option>
                                                <option value="">{t("bankTransaction.Manualtransfer")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>{t("bankTransaction.Withoutguaranteedstatus")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("bankTransaction.Withoutguaranteedstatus")}>
                                            <Form.Select aria-label="Without guaranteed status">
                                                <option value="">{t("formLabel.all")}</option>
                                                <option value="">Guaranteed</option>
                                                <option value="">Unsecured</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>{t("TransferPage.Withoutfinancialstatusofthecontract")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("TransferPage.Withoutfinancialstatusofthecontract")}>
                                            <Form.Select aria-label="Without financial status of the contract">
                                                <option value="">Normal</option>
                                                <option value="">Legal</option>
                                                <option value="">Pending resources</option>
                                                <option value="">{t("formLabel.all")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                    <Accordion.Header>{t("TransferPage.Withoutcontractstatus")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("TransferPage.Withoutcontractstatus")}>
                                            <Form.Select aria-label="Without contract status">
                                                <option value="">{t("formLabel.all")}</option>
                                                <option value="">Active</option>
                                                <option value="">Pending</option>
                                                <option value="">Paused</option>
                                                <option value="">Terminated and terminated</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                    <Accordion.Header>{t("formLabel.propertyType")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("formLabel.propertyType")}>
                                            <Form.Select aria-label="Property type">
                                                <option value="">{t("formLabel.all")}</option>
                                                <option value="">Home</option>
                                                <option value="">Apartment</option>
                                                <option value="">Duplex apartment</option>
                                                <option value="">Triplex apartment</option>
                                                <option value="">Common area</option>
                                                <option value="">corporate floor</option>
                                                <option value="">Bungalow</option>
                                                <option value="">Shed</option>
                                                <option value="">Box/Garage</option>
                                                <option value="">Commercial house</option>
                                                <option value="">House in condominium</option>
                                                <option value="">Two-story house</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                    <Accordion.Header>{t("TransferPage.Ofthebranches")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("TransferPage.Ofthebranches")}>
                                            <Form.Control type="date" aria-label="Of the branches"></Form.Control>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                    <Accordion.Header>{t("TransferPage.OftheManagers")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("TransferPage.OftheManagers")}>
                                            <Form.Control type="date" aria-label="Of the Manager"></Form.Control>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="14">
                                    <Accordion.Header>{t("TransferPage.Bynumberofproeprtiesperowner")}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel label={t("TransferPage.Bynumberofproeprtiesperowner")}>
                                            <Form.Select aria-label="By number of proeprties per owner">
                                                <option value="">{t("formLabel.all")}</option>
                                                <option value="">{t("customerAndEmployees.Only")} 1 {t("customerAndEmployees.propertie")}</option>
                                                <option value="">{t("ReceiveandReceived.with")} 2 {t("Links.properties")} </option>
                                                <option value="">{t("ReceiveandReceived.with")} 3 {t("Links.properties")} </option>
                                                <option value="">{t("ReceiveandReceived.with")} 4 {t("Links.properties")} </option>
                                                <option value="">{t("ReceiveandReceived.with")} 5 {t("Links.properties")} </option>
                                                <option value="">{t("ReceiveandReceived.with")} 6 {t("customerAndEmployees.ormoreproperties")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={mobileFiltersClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={mobileFiltersClose}>
                            {t("button.toSave")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Filter modal for mobile screens end */}
            {/* Generate all start here */}
            <Modal show={show3} onHide={generateModalShow}>
                <Form>
                    <Modal.Body>
                        {t("Links.generateCharges")}?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={generateModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={generateModalClose}>
                            {t("bankTransaction.ok")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Generate all end */}

            {/* issue check start from here */}
            <Modal show={showIssueCheckModal} onHide={issueCheckModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("paidTobepaid.Issuecheck")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel label={t("banks.bankAccounts")}>
                            <Form.Select aria-label="Bank account">
                                <option value="">{t("formLabel.all")}</option>
                                <option value="">{t("customerAndEmployees.Only")} 1 {t("customerAndEmployees.propertie")}</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={issueCheckModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={issueCheckModalClose}>
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /issue check modal end */}
            <FinancialExpenseService />
        </>
    )
}
export default BankTransactionsListing;