import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { set, useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Modal, Form, Button, Row, Col, FloatingLabel, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { openEmailPop, showEmail } from '../../../../redux/slice/contractDataSlice';
import { CustomersAndEmployeesForm } from '../../../Interface';
import { emailPost, emailPostTransfer, mailData } from '../../../../redux/slice/SendEmailSlice';
import customerService from '../../../../services/customerService';
import { getComapnyLogo } from '../../../../redux/slice/companyDataSlice';
import { config } from '../../../../services/apiConfig';
import { isDataView } from 'util/types';
import { openErrorbox } from '../../Contracts/Common/actionPerform';
import { IMAGEURLLINK } from '../../../constants/utils';
import i18n from '../../../../i18n';
import moment from 'moment';

const TransferEmail = (props) => {
    let imgtype = '';
    let { mailData } = props;
    // const contract_id = props.contract_id;
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const { getlogo, status } = useSelector((state: RootState) => state.companyData);
    const { emailShow } = useSelector((state: RootState) => state.contractData);
    let [allDesc, setAllDesc] = useState<any>({ desc_1: "" });
    let [sendDays, setSendDays] = useState<any>([]);
    const [fantasiaName, setFantasiaName] = useState('')
    const [dayAgos, setDayAgos] = useState('')
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

    const mailClose = () => {
        setValue("title", ``)
        setAllDesc({ desc_1: "" })
        dispatch(showEmail(false))
        reset();
    }

    const [templateData, setTemplateData] = useState<any>([]);
    const [variableData, setVariableData] = useState<any>({});
    const [allemails, setAllEmails] = useState([])
    const [loading, setLoading] = useState(false);
    const [double, setDouble] = useState(false);
    const [logoPath, setLogoPath] = useState<any>("/logos/logo.png");

    const mailValidationSchema = Yup.object().shape({
        identifier: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        title: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        // model: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // variable: Yup.string().trim()
        //     .required(t('requiredMmessage.fieldRequired'))

    });
    let frm = {
        resolver: yupResolver(mailValidationSchema)
    }
    const { register, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm<mailData>(frm);

    useEffect(() => {
        let allEmails: any = [];
        mailData.forEach((entry: any) => {
            entry?.owner?.forEach(owner => {
                allEmails.push(owner.email);
                setFantasiaName(owner.name)
            });
        });

        setAllEmails(allEmails)
        let DescArray: any = {};
        let DaysArray: any = {};
        allEmails.forEach((item: any, index: number) => {
            let todayDate = moment.tz(dateBrazil, 'America/Sao_Paulo');
            let maturityDate = moment(mailData[index]?.recMaturity2, "DD/MM/YYYY");
            let Difference_In_Time = maturityDate.diff(todayDate);
            let Difference_In_Days: any = Difference_In_Time / (1000 * 3600 * 24);
            let MathSign = Math.sign(Difference_In_Days);

            let dayAgo: any = "";
            let upcomDay: any = "";

            if (MathSign == -1) {
                dayAgo = String(parseInt(Difference_In_Days)).replace("-", "");
                if (Number(dayAgo) > 30) {
                    if ((Math.floor(Number(dayAgo) / 30)) > 12) {
                        dayAgo = String(Math.floor((Number(dayAgo) / 30) / 12)) + "y";
                    } else {
                        dayAgo = String(Math.floor(Number(dayAgo) / 30)) + "m";
                    }
                } else {
                    if (dayAgo == "0") {
                        dayAgo = t("ReceiveandReceived.Today");

                    } else if (dayAgo == "1") {
                        dayAgo = dayAgo + " " + t("NonPayment.days");;
                    } else {
                        dayAgo = dayAgo + " " + t("NonPayment.days");
                    }
                }
                setDayAgos(dayAgo)
            } else {
                upcomDay = String(parseInt(Difference_In_Days + 1));
                if (Number(upcomDay) > 30) {
                    if ((Math.floor(Number(upcomDay) / 30)) > 12) {
                        upcomDay = String(Math.floor((Number(upcomDay) / 30) / 12)) + "y";
                    } else {
                        upcomDay = String(Math.floor(Number(upcomDay) / 30)) + "m";
                    }
                } else {
                    if (upcomDay == "0") {
                        upcomDay = t("ReceiveandReceived.Today");

                    } else if (upcomDay == "1") {
                        upcomDay = upcomDay + " " + t("NonPayment.days");
                    } else {
                        upcomDay = upcomDay + " " + t("NonPayment.days");
                    }
                }
            }

            DaysArray[`indexdta_${index + 1}`] = dayAgo ? dayAgo : upcomDay;
            DaysArray[`owner_${index + 1}`] = mailData[index]?.owner[0]?.name;
            // <h1>${i18n.language == "en" ? `%[ownername]% paid in %[day]%.` : `%[ownername]% pagou em %[day]%.`}</h1>
        })

        let newText: any = ` <!DOCTYPE html>
                <html>
                    <head>
                        <meta charset="UTF-8">
                    </head>
                    <body style="font-family:sans-serif;margin-left:2em;">
                    <header>
                    <img className="form-control" src=${getlogo?.logo_name && IMAGEURLLINK.replace("{logoname}", ((getlogo?.logo_name).split("_|_")[0]).replace("/", "%2F")).replace("{generation}", (getlogo?.logo_name).split("_|_")[1])} alt="logo">
                    </header>
                    
                    <p>${i18n.language == "en" ? "Hello" : "Olá"} %[ownername]%, </p>
                    <p>${i18n.language == "en" ? "For your convenience, we provide the statement of the transfer" : "Para sua conveniência, fornecemos o extrato da transferência"} ${i18n.language == "en" ? "made" : "fez"}  <b>%[day]%</b> .</p>

                    <p><b>${t("formLabel.email")} </b>: %[owneremail]%,</p>

                    <p><b>${t("yourproperty")} </b>:  %[ownertype]%</p>

                    <p><b>${t("formLabel.payment")} </b>:  %[ownermaturity]%</p>

                    <p><b>${t("formLabel.value")} </b>: %[ownertotal]%</p>
                        <div style="background-color:#F5F5F5; width: 100%; margin: 0 !important; padding: 0 !important;">
                        <div style="max-width: 600px; margin:auto;">
                            <div style="margin: 0 15px;  padding:32px 10px 32px 10px">
                            <img className="form-control" src=${getlogo?.logo_name && IMAGEURLLINK.replace("{logoname}", ((getlogo?.logo_name).split("_|_")[0]).replace("/", "%2F")).replace("{generation}", (getlogo?.logo_name).split("_|_")[1])} alt="logo">
                            </div>
                        </div>
                    </div>
                    </body>
                    </html>
            ` || ''; // Use the actual property name if needed
        DescArray[`desc`] = newText;
        setAllDesc(DescArray);
        setSendDays(DaysArray);
    }, [mailData])

    const mailSubmit = async (data: mailData) => {
        if (allDesc !== "") {
            setLoading(true);
            let emaildata: any = {
                status: "new",
                subject: getValues("title"),
                content: allDesc,
                layout: "default",
                sender: "noreply@dev.kenlo.io",
                to: allemails,
                identifier: "identifier",
                model: 1,
                caption: "caption",
                title: getValues("title"),
                daytransfer: sendDays,
                mailData: mailData
            }

            let res = await dispatch(emailPostTransfer(emaildata))
            if (res.payload.resCode == 201) {
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.mailsent"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
                setLoading(false);
                dispatch(openEmailPop(false))
                reset()
                setValue("title", ``)
                setAllDesc({ desc_1: "" })
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
                setLoading(false);
            }
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.emailsMessage"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            });
            setLoading(false);
        }
    }

    // useEffect(() => {
    //     setValue("title", `${t("formLabel.ExtractforIR")}`)
    //     setDesc(`<p>Olá %proprietario_nome%,</p><p>Para sua comodidade disponibilizamos o extrato para declaração do imposto de renda.</p><p>&nbsp;</p><p>O extrato contém as informações dos recebimentos referentes ao ano de %ano_anterior%.</p><p>%endereco_proprietario_extratoir_html%</p><p>Você recebeu este e-mail pois é cliente da %imobiliariaNome%</p>`)
    // }, []);

    useEffect(() => {
        // Trigger the 'getComapnyLogo' action when the component mounts or when 'imgtype' changes
        dispatch(getComapnyLogo())
    }, [dispatch, imgtype,])

    let type = getlogo?.logo_name
    let gettype = type?.split(/[#?]/)[0].split('.').pop().trim()
    // Split the first part by '.' and retrieve the last part (logo file extension)
    imgtype = gettype;

    useEffect(() => {
        // setValue("title", 'Owner Disbursement Statement')
        setAllDesc({ desc_1: "" })
    }, []);

    useEffect(() => {
        if (emailShow) {
            setTimeout(() => {
                setValue("title", i18n.language == "en" ? `%[ownername]% paid in %[day]%.` : `%[ownername]% pagou em %[day]%.`)
            }, 300)
            // setValue("title", i18n.language == "en" ? "Owner Disbursement Statement" : "Declaração de Desembolso doProprietário")
        }
    }, [i18n.language, emailShow, fantasiaName])
    return (
        <>
            <Modal show={emailShow} className='Owners_and_beneficiariestsx' onHide={mailClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("newTransfer.sendByEmail")}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(mailSubmit)}>
                    <Modal.Body>
                        <Row id="CurrentPage">
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.identifier')}>
                                    <Form.Select className={` ${errors.identifier ? 'is-invalid' : ''}`} {...register('identifier')}>
                                        <>
                                            <option value="2">{t("customerAndEmployees.owners")}</option>
                                            <option value="3">{t("customerAndEmployees.tenants")}</option>
                                        </>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.identifier?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>

                            {/* <Col>
                                <FloatingLabel className="mb-3 selectTemp" controlId="floatingSelect" label={t('formLabel.selectTemplate')}>
                                    <Form.Select aria-label={t('formLabel.selectTemplate')} className={` ${errors.model ? 'is-invalid' : ''}`}  {...register('model')} onChange={HandleModule}>
                                        <option value="">{t('dropdownItem.select')}</option>
                                        <option value="new">{t('dropdownItem.newTemplate')}</option>
                                        {templateData && templateData.length > 0 ? (
                                            templateData.map(item => (
                                                <option key={item.ID_MODELO_MOH} value={item.ID_MODELO_MOH}>
                                                    {item.ST_NOME_MOH}
                                                </option>
                                            ))
                                        ) : (
                                            <option disabled>No data available</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.model?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.title')}>
                                    <Form.Control type="text" placeholder={t('formLabel.title')} className={` ${errors.title ? 'is-invalid' : ''}`}  {...register('title')} />
                                    <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            {/* <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.variables')}>
                                    <Form.Select aria-label={t('formLabel.variables')} className={` ${errors.variable ? 'is-invalid' : ''}`}  {...register('variable')}
                                        onChange={(e) => {
                                            const selectedVariable = e.target.value;
                                            const appendedContent = `${desc} %${selectedVariable}%`; setDesc(appendedContent);
                                        }}>
                                        <option value="">{t('dropdownItem.select')}</option>
                                        {variableData && Object.keys(variableData).length > 0
                                            ? Object.keys(variableData).map(key => (
                                                <option key={key} value={variableData[key]}>
                                                    {key}
                                                </option>
                                            ))
                                            : <option disabled>No data available</option>
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.variable?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col md={12}>
                                <React.Fragment>
                                    {/* <Row className="mt-3">
                                        <Col md={1}>
                                            <h4>{(t("to")).toUpperCase()}:</h4>
                                        </Col>
                                        <Col md={10}>
                                            <span style={{ color: "green", fontSize: "18px" }} ><u>{item}</u></span>
                                        </Col>
                                    </Row> */}
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={allDesc?.[`desc`] ?? ""}
                                        onReady={editor => {
                                            const data = editor.getData();
                                            setAllDesc({
                                                ...allDesc,
                                                [`desc`]: data,
                                            });
                                        }}
                                        onChange={(event: any, editor: any) => {
                                            const data = editor.getData();
                                            setAllDesc({
                                                ...allDesc,
                                                [`desc`]: data,
                                            });
                                        }}
                                        onBlur={(event: any, editor: any) => {
                                            const data = editor.getData();
                                            setAllDesc({
                                                ...allDesc,
                                                [`desc`]: data,
                                            });
                                        }}
                                        onFocus={(event: any, editor: any) => {
                                            const data = editor.getData();
                                            setAllDesc({
                                                ...allDesc,
                                                [`desc`]: data,
                                            });
                                        }}
                                    />
                                </React.Fragment>

                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={mailClose}>
                            {t("button.close")}
                        </Button>
                        {loading ? (
                            <Button disabled={true}>
                                {t("button.submitting")}
                            </Button>

                        ) : (
                            // Your existing Modal content
                            <Button disabled={double} type="submit" variant="primary" onClick={openErrorbox}>
                                {t("button.toSave")}
                            </Button>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default TransferEmail