import React, { useEffect, useState } from 'react';
import { ComProps, } from '../Interface';
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Swal from 'sweetalert2';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { handleClo, handleOpn } from "../../redux/slice/profileSlice"
import { Dropdown, InputGroup, Col, Row, Form, Button, Modal, Card, Table, FloatingLabel, Alert, ButtonGroup, Badge, DropdownButton, OverlayTrigger, Tooltip, ProgressBar } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { deleteTenant, newContact, tenantsOpen } from '../../redux/slice/contractDataSlice';
import { encrypt_Decrypt, formatDateToDDMMYYYY, handleKeyDown } from '../constants/utils';
import customerService from '../../services/customerService';
import TenantsService from '../Pages/Contracts/Common/TenantsService';
import NewGuarantors from '../Pages/Contracts/Customer_and_employees/NewGuarantors';
import { singleemailPost, singleemailfetchData } from '../../redux/slice/SendEmailSlice';
import { incomeService } from '../../services/incomeServices';
import moment from "moment-timezone";
let dateBrazil  = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
 
export interface newContact {
    name: string,
    payment: string
    department: string,
    email: string,
    telephone: number
    tenantId: any
}


const ProfileDetails = ({ submenu }: ComProps) => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    const location = useLocation();
    let contract_id = null;
    const [t] = useTranslation();
    const ContactValidationSchema = Yup.object().shape({
        name: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        department: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        email: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        telephone: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
    });
    const dispatch = useDispatch<AppDispatch>()
    const { show } = useSelector((state: RootState) => state.profile)

    const { id } = useParams();
    const [tenant, settenant] = useState<any>({})
    const [transferStatement, setTransferStatement] = useState<any>([]);
    const [tenantCharges, setTenantCharges] = useState<any>([])
    const [tenantProperty, settenantProperty] = useState<any>({})
    const { showNewContact } = useSelector((state: RootState) => state.contractData)
    const { toBePaidedtransferData } = useSelector((state: RootState) => state.transferData)
    const [tenantContracts, settenantContracts] = useState<any>([])
    const [getContact, setGetContact] = useState<any>([])
    const [addContactTxt, setAddContactTxt] = useState(t("Addcontact"))
    const [filtername, setFiltername] = useState<any>()
    const [transferstate, setTransferState] = useState<any>([])
    const [userType, setUserType] = useState('')
    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M').format("MMM/YYYY");
    let searchDt = moment(newDate).format("MMM/YYYY")
    let endDt = moment(newDate).add(1, 'M').format("MMM/YYYY");
    const [state, setState] = useState({
        contract: "",
        owner: "",
        bankAccount: "",
        paidwith: "",
        supplier: "",
        bankAccountType: "",
        startDate: startDt,
        searchDate: searchDt,
        endDate: endDt,
        start: 0,
        end: 40,
        filterby : "",
        typeofexpenses: "",
        TenantId: id

    })
    const [marital, setMarital] = useState<any>()
    const [show1, setShow1] = useState(false);
    const [hide, setHide] = useState(false);
    const options = [
        { value: "1", label: "Married" },
        { value: "2", label: "Single" },
        { value: "3", label: "Widower" },
        { value: "4", label: "Legally separated" },
        { value: "5", label: "Disquitado" },
        { value: "6", label: "Divorced" },
        { value: "7", label: "Stable union" }
    ];
    const [chargestate, setChargesState] = useState({
        startDate: startDt,
        endDate: endDt,
        searchDate: searchDt,
        start: 0,
        end: 40,
        ownerId: id,
        type: "Tenant"
    });
    // const [banklist, setbanklist] = useState<any>([]) 
    // const [userpaymentMethods, setPaymentMethods] = useState<any>([]) 
    // const [tenantPayment, settenantPayment] = useState(false) 

    const [userJson, setUserJson] = useState<any>([]);
    const navigate = useNavigate();
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(2040) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])

    const handleClose = () => dispatch(handleClo(false))

    const handleShow = () => {
        dispatch(tenantsOpen(true))

    }
    var frm = {
        resolver: yupResolver(ContactValidationSchema)
    }
    const { register, handleSubmit, setValue, reset, setError, clearErrors, formState: { errors } } = useForm<newContact>(frm);
    const mailSubmit = (data: any) => {
        //console.log("okk", JSON.stringify(data))
    }

    useEffect(() => {
        const ddd = window.location.href;
        let userId = ddd.split('/').pop();
        gettenant(userId);
        gettenantPropertListing({ userId: userId });
        gettenantContractListing({ userId: userId });
    }, []);

    useEffect(() => {
        if (toBePaidedtransferData.type == "success") {
            const filteredData = toBePaidedtransferData?.data?.filter(item => {
                return item.owner.some(owner => owner.id == id);
            });
            setTransferState(filteredData)
        }else{
            setTransferState([])
        }
    }, [dispatch, toBePaidedtransferData]);

    const gettenant = (userId) => {
        let  textFilter = ''
        customerService.getSingleCustomer(userId)
            .then((res) => {
                if (res.data.type == "success" || res.data.type == "Success") {
                    settenant(res.data.data[0]);
                    let lebel_callection = [ t("owner"), t("tenant"), t("customerAndEmployees.guarantors"), t("Broker"),  t("customerAndEmployees.inspectors"), t("customerAndEmployees.suppliers")]
                    for(let j= 0;j<res.data.data[0].type_collection.length; j++){
                        if(res.data.data[0].type_collection[j] ==1){
                            textFilter+= `${lebel_callection[j]} `
                        }
                    }
                    setFiltername(textFilter)
                    const option = options.find(option => option.value == res.data.data[0].marital_status);
                    setMarital(option)
                    setValue("tenantId", res?.data?.data[0]?.tenantId)
                    customerService.ListContact(res.data.data[0]?.tenantId).then((res) => {
                        setGetContact(res.data.data)
                    })
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    const gettenantPropertListing = (filter) => {
        let userData = { status: filter.status, tenants: filter.userId, start: 0, end: 20 };
        customerService.getAllProperty(userData)
            .then((res) => {
                if (res.data.type === "success" || res.data.type === "Success") {
                    res.data.data.forEach(element => {
                        element?.contractResult.forEach(tenantItem => {
                            tenantItem?.tenantsResult?.forEach((tenantId) => {
                                if (tenantId?.tenetId == id) {
                                    settenantProperty(res.data.data);
                                }
                            });
                        });
                    });
                } else {
                    settenantProperty([]);
                }
            })
            .catch((err) => {
                // console.log("err", err);
            });
    }
    const gettenantContractListing = (filter) => {
        let userData = { status: filter.status ? filter.status : 'All', tenantId: filter.userId, start: 0, end: 100 };
        customerService.allTenantContracts(userData)
            .then((res) => {
                if (res.data.type == "success" || res.data.type == "Success") {
                    settenantContracts(res.data.data);
                } else {
                    settenantContracts([])
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }
    // Filter COntracts
    const filterContracts = (evnt) => {
        const { value } = evnt.target;
        gettenantContractListing({ userId: tenant.id, status: value });
    };
    const [allEmailList, setAllEmailList] = useState([]);
    const [fetchEmailData, setfetchEmaildata] = useState([]);
    useEffect(() => {
        if (typeof tenant !== "undefined" && tenant) {
            if (Object.keys(tenant).length > 0) {
                (async () => {
                    let res = await dispatch(singleemailPost(id));
                    if (res?.payload?.type == "Success" || res?.payload?.type == "success") {
                        const dataArray:any = Object.values(res.payload.data);
                        let filteredData = dataArray?.filter(entry => entry.DT_ENTRADA_FE !== "0000-00-00 00:00:00" && entry.DT_ENTRADA_FE !== null);
                        setAllEmailList(filteredData)
                    }
                })()
            }
        }
    }, [tenant])
    const deletetheTenant = (e, type, id) => {
        let newid = tenant.id 
        if(userType == "Tenant"){
            newid =  id
        }
        let data = { tenantId: newid,  type: type, userType: userType }
        Swal.fire({
            title: t("Sweetalert.Areyousure"),
            text: t("Sweetalert.Youwontbeabletorevert"),
            // icon: 'success','warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, it!'
        }).then(async (result) => {
            if (result.value) {
                dispatch(deleteTenant(data)).then(response => {
                    let res = response.payload;
                    if (res.resCode == 201 || res.resCode == 408) {
                        Swal.fire({
                            title: t("Sweetalert.Success"),
                            text: t("Sweetalert.deletesuccess"),
                            // icon: 'success',"success",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                        gettenant(id)
                        if(userType == "Tenant"){
                        navigate("/tenants")
                        }else{
                            navigate("/customer_and_employees")

                        }
                    } else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: res.data ? res.message : t("Sweetalert.deletefailed"),
                            // icon: 'success',"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                    }
                }).catch(e => {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.deletefailed"),
                        // icon: 'success',"error",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                })
            }
        })
    }

    const AddContact = () => {
        dispatch(newContact(true))
        setAddContactTxt(t("Addcontact"))
    }
    const CloseContact = () => {
        dispatch(newContact(false))
        reset()
    }
    const ContactSubmit = (data: any) => {
        if (addContactTxt == "Add contact" || addContactTxt == "Adicionar contato") {
            customerService.addContact(data).then((res) => {
                //console.log(res)
                if (res.data.resCode == 202) {
                    Swal.fire({
                        title: t("Sweetalert.Success"),
                        text: t("Sweetalert.successget"),
                        // icon: 'success',"success",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                    dispatch(newContact(false))
                    reset()
                    gettenant(id)
                } else {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.somethingWent"),
                        // icon: 'success',"error",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                }
            })
        } else {
            customerService.UpdateContact(data).then((res) => {
                if (res.data.resCode == 200) {
                    Swal.fire({
                        title: t("Sweetalert.Success"),
                        text: t("Sweetalert.successget"),
                        // icon: 'success',"success",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                    dispatch(newContact(false))
                    reset()
                    gettenant(id)
                } else {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.somethingWent"),
                        // icon: 'success',"error",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                }
            })
        }
    }

    const handleDelete = (EMAIL: any, data) => {
        if (data == "delete") {
            Swal.fire({
                title: `${t('Sweetalert.Warning')}`,
                text: `${t('Sweetalert.deletecontact')}`,
                // icon: 'success',"question",
                showCancelButton: true,
                confirmButtonText: `${t('Sweetalert.yesDeleteIt')}`,
            }).then(async (result) => {
                if (result.isConfirmed == true) {
                    customerService.DeleteContact(EMAIL).then((res) => {
                        if (res.data.resCode == 408) {
                            // Show a success message using Swal
                            Swal.fire({
                                // icon: 'success','success',
                                title: `${t('Sweetalert.Success')}`,
                                text: `${t('Sweetalert.deletesuccess')}`,
                                confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                                timer: 2000
                            })
                            gettenant(id)
                            dispatch(newContact(false))
                            reset()
                        } else {
                            // Show an error message
                            Swal.fire({
                                title: `${t('Sweetalert.Error')}`,
                                text: `${t('Sweetalert.alreadyuse')}`,
                                // icon: 'success',"error",
                                confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                                timer: 2000
                            })
                        }
                    })

                } else {
                    console.log("no")
                };
            });
        }
    }

    const updateContact = (EMAIL: any) => {
        dispatch(newContact(true))
        setAddContactTxt(t("updatecontact"))
        customerService.EditContact((EMAIL)).then((res) => {
            if (res.data.resCode == 201) {
                //console.log(res.data.data[0])
                const fields: string[] = ['name', 'department', "email", "telephone"]
                fields.forEach((field: any) => setValue(field, res.data.data[0][field]));
                var emailInput = document.querySelectorAll('.paymentsDis');
                emailInput.forEach(element => {
                    element.setAttribute('readonly', 'true');
                    element.setAttribute('disabled', 'true');
                });
            }
        })
    }
    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors('telephone')
        }
    }

    const handleNavigateDocument = async (e) => {
        let data = {
            person_id: id,
            person_type: tenant.type.toLowerCase(),
            person_email: tenant?.email
        }
        navigate(`/documents`, { state: data });
    }

    const [firstLoad, setFirstLoad] = useState(false);
    const SeenEmailHandle = async (e, emailid, email) => {
        setFirstLoad(true);
        let reqobj: any = {
            id: emailid,
            email: email
        };
        let res1 = await dispatch(singleemailfetchData(reqobj));
        if (res1["payload"]["data"].length > 0) {
            setfetchEmaildata(res1["payload"]["data"]);
        }
    }
    const filterCharge = (dt, tp, type) => {
        if (typeof tp !== "undefined") {
            let currentDate = new Date(dt);
            if (tp == "Subtract") {
                startDt = moment(currentDate).subtract(1, 'M').format("MMM/YYYY");
                searchDt = moment(startDt).add(1, 'M').format("MMM/YYYY");
                endDt = moment(currentDate).add(1, 'M').format("MMM/YYYY");
            }
            else {
                startDt = moment(currentDate).subtract(1, 'M').format("MMM/YYYY");
                searchDt = moment(startDt).add(1, 'M').format("MMM/YYYY");
                endDt = moment(currentDate).add(1, 'M').format("MMM/YYYY");
            }
            setState({
                ...state,
                ['startDate']: startDt,
                ['endDate']: endDt,
                ['searchDate']: searchDt,
            });
        }
    }

    const filterCharges = (dt, tp, type) => {
        if (typeof tp !== "undefined") {
            let currentDate = new Date(dt);
            if (tp == "Subtract") {
                startDt = moment(currentDate).subtract(1, 'M').format("MMM/YYYY");
                searchDt = moment(startDt).add(1, 'M').format("MMM/YYYY");
                endDt = moment(currentDate).add(1, 'M').format("MMM/YYYY");
            }
            else {
                startDt = moment(currentDate).subtract(1, 'M').format("MMM/YYYY");
                searchDt = moment(startDt).add(1, 'M').format("MMM/YYYY");
                endDt = moment(currentDate).add(1, 'M').format("MMM/YYYY");
            }
              
            if(type == "TenantCharge"){
                setChargesState({
                    ...chargestate,
                    ['startDate']: startDt,
                    ['endDate']: endDt,
                    ['searchDate']: searchDt,
                    ['start']: 0
                });
              
            }else{
                setState({
                    ...state,
                    ['startDate']: startDt,
                    ['searchDate']: searchDt,
                    ['endDate']: endDt,
                })
            }

        }
    }

    useEffect(() => {
        customerService.allOwnerCharge(chargestate).then((res) => {
            if (res.data.type == "success" || res.data.type == "Success") {
                setTenantCharges(res.data.data)
            } else {
                setTenantCharges([])
            }
        })
    
    }, [chargestate])

    useEffect(() => {
        if (location?.state?.type !== "") {
            setUserType(location?.state?.type)
        }
      }, [location.state]);
    return (
        <section className="content-wrapper">
             {/*  <!-- Breadcrumb section start from here --> */}
             <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column overflow-visible justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
                    <div className="w-100">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                            <Col xs={{ order: 12 }} xl={{ span: 7, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                            {
                                    (location && location?.state &&
                                        location?.state?.contract_id &&
                                        location?.state?.contract_id !== null &&
                                        location?.state?.contract_id !== undefined &&
                                        location?.state?.contract_id !== "") ?
                                        <Link to={`/contract_details/${location?.state?.contract_id}`}><img src="../assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                        :
                                        <Link to={"/customer_and_employees"}>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.svg"} className="h-16" alt="Arrow left" />
                                        </Link>
                                }
                                 <h1 className="mb-0 h4 lh-base ms-2 ms-lg-3 ms-xl-4 text-nowrap">{t(`${userType?.toLowerCase()}`)} {t('detail')}</h1>
                                <div className="w-100 ms-2 ms-lg-3 ms-xl-4 d-flex align-items-center">
                                    <h4 className="lh-base mb-0 text-secondary">{tenant.name !== null && tenant.name !== "" ? tenant.name : ""}
                                        <Badge className="text-dark ms-1" bg="success-30">{tenant?.["tanentStatus"] == "0" || tenant?.["tanentStatus"] == null ? t("active") : ''}</Badge>
                                        <Badge className="text-white ms-1" bg="secondary">{tenant?.["tanentStatus"] == "1" ? t("inActive") : ''}</Badge></h4>
                                </div>
                            </div>
                            
                            </Col>
                            <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                               {
                                        userJson && userJson.length > 0 && (userJson.indexOf(2041) !== -1) &&
                                        <>
                                            <li className="list-inline-item me-2 me-xl-3 me-xxl-4">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="dropdown-basic">
                                                        {t("actions")}
                                                        <img src="../assets/images/icons/arrow-bottom-primary.svg" alt="Plus icon" className="ms-1 h-8" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {tenant?.["tanentStatus"] == "0" || tenant?.["tanentStatus"] == null? <Dropdown.Item href="#" onClick={(e) => deletetheTenant(e, "delete", tenant?.tenantId)}>{t("formReceipt.disable")}</Dropdown.Item> : ""}
                                                        {tenant?.["tanentStatus"] == "1" ? <Dropdown.Item href="#" onClick={(e) => deletetheTenant(e, "", tenant?.tenantId)}>{t("notifications.enable")}</Dropdown.Item> : ""}
                                                        {/* <Dropdown.Item href="#">{t("historic")}</Dropdown.Item> */}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                        </>
                                                }
                                 <li className="list-inline-item">
                                        <Button type="button" variant="link" size="sm" className="p-0 fw-bold" onClick={(e) => handleNavigateDocument(e)}>
                                            {t("documents")}
                                        </Button>
                                    </li>
                               </ul>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-4 pb-xl-5 pb-md-4 pb-3" id="tenantProfiletsx">
                <div>
                    <Row className="h-100 gy-3 gy-lg-4">
                        <Col lg={6}>
                            <Card className="h-100">
                                <Card.Header className="d-sm-flex align-items-center justify-content-between">
                                    {t("property.data")}
                                    <div>
                                        {
                                            userJson && userJson.length > 0 && (userJson.indexOf(2041) !== -1) &&
                                            <Button variant="link" className="mb-lg-4 mb-3 p-0" onClick={handleShow}>{t("changeperson")}</Button>
                                        }
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <span className="d-block">
                                        <img src="../assets/images/icons/user-light.svg" alt="User" className="ms-1 h-16" />
                                        <span className="ps-1">{t("formLabel.cnpjCpf")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tenant.cnpj}</span>
                                    </span>
                                    <hr />

                                    <span className="d-block">
                                        <img src="../assets/images/icons/user-light.svg" alt="file" className="ms-1 h-16" />
                                        <span className="ps-1"> {t("formLabel.fantasyName")} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {" "} {tenant.name ? tenant.name : tenant.fantasy}</span>
                                    </span>
                                    <hr />
                                    {tenant.nationality !== null && tenant.nationality !== "" && tenant.nationality !== undefined ?
                                        <>
                                            <span className="d-block">
                                                <img src="../assets/images/icons/file-light.svg" alt="file" className="ms-1 h-16" />
                                                <span className="ps-1"> {t("nationality")} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {tenant?.nationality}  &nbsp;&nbsp; <span className='fw-bold'> {t("formLabel.maritalStatus")}</span> {marital?.label}  &nbsp;&nbsp; <span className='fw-bold'>{tenant?.sex == 1 ? "Male" : "Female"}</span></span>
                                            </span>
                                            <hr />
                                        </>
                                        : ""}
                                    {tenant.rg !== null && tenant.rg !== "" && tenant.rg !== undefined ?
                                        <>
                                            <span className="d-block">
                                                <img src="../assets/images/icons/file-light.svg" alt="file" className="ms-1 h-16" />
                                                <span className="ps-1"> {t("RG")} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {tenant?.rg}  {" "} {t("formLabel.issuingAgency")} {tenant?.issue_agency} </span>
                                            </span>
                                            <hr />
                                        </>
                                        : ""}
                                    {tenant.zipcode !== null && tenant.zipcode !== "" && tenant.zipcode !== undefined ?
                                        <div>
                                            <span className="d-block">
                                                <img src="../assets/images/icons/home_g.svg" alt="file" className="ms-1 h-16" />
                                                <span className="ps-1">{t("formLabel.address")} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {tenant.address} {tenant.number ? tenant.number : ""} {tenant.complement ? tenant.complement : ""} {tenant.neighborhood} {tenant.city} {tenant.state} CEP: {tenant.zipcode}</span>
                                            </span>
                                            <hr />
                                        </div>
                                        :
                                        ""
                                    }
                                    {tenant.observation !== null && tenant.observation !== "" && tenant.observation !== undefined ?
                                        <div>
                                            <span className="d-block">
                                                <img src="../assets/images/icons/file-light.svg" alt="file" className="ms-1 h-16" />
                                                <span className="ps-1">{t("formLabel.observation")} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {tenant.observation}</span>
                                            </span>
                                            <hr />
                                        </div>
                                        :
                                        ""
                                    }

                                    <span className="d-block fw-bold">{t("formLabel.contact")}  </span>
                                    <hr />

                                    <span className="d-block ms-2 ms-lg-3">
                                        <img src="../assets/images/icons/mobile-light.svg" alt="mobile" className="ms-1 h-16" />
                                        <span className="ps-1">{t("formLabel.Cellphone")} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tenant.cellphone}</span>
                                    </span>
                                    <hr />
                                    {tenant.telephone !== null && tenant.telephone !== "" && tenant.telephone !== undefined ?
                                        <div>
                                            <span className="d-block ms-2 ms-lg-3">
                                                <img src="../assets/images/icons/phone-light.svg" alt="phone" className="ms-1 h-18" />
                                                <span className="ps-1">{t("formLabel.telephone")} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tenant.telephone}</span>
                                            </span>
                                            <hr />
                                        </div>
                                        : ""
                                    }

                                    <span className="d-block ms-2 ms-lg-3">
                                        <img src="../assets/images/icons/envelop.svg" alt="envelop" className="ms-1 h-16" />
                                        <span className="ps-1">{t("formLabel.email")}   {tenant.email}</span>
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card className="h-100">
                                <Card.Header className="d-sm-flex align-items-center justify-content-between">
                                    {t("contractlistother.Latestcontracts")}
                                    <div>
                                        {
                                            userJson && userJson.length > 0 && (userJson.indexOf(2041) !== -1) &&
                                            <div> <Link className="mb-lg-4 mb-3 p-0 btn btn-link" to={"/contract"}>{t("newContract")}</Link></div>
                                        }
                                    </div>
                                </Card.Header>
                                <Card.Body className="pb-1">
                                    {/* <!-- Table start from here --> */}
                                    <FloatingLabel label={t("formLabel.Contractsin")} className="mb-lg-4 mb-3">
                                        <Form.Select onChange={(evnt) => filterContracts(evnt)}>
                                            <option value="all">{t("all")}</option>
                                            <option value="inProgress">{t("inProgress")}</option>
                                            <option value="active">{t("customerAndEmployees.active")}</option>
                                            <option value="pending">{t("pending")}</option>
                                            <option value="paused">{t("paused")}</option>
                                            <option value="terminated">{t("terminated")}</option>

                                        </Form.Select>
                                    </FloatingLabel>
                                    <Table responsive className="table-custom table-custom-bordered mb-0">
                                        <tbody>
                                            {tenantContracts && tenantContracts.length > 0 ?
                                                tenantContracts.map((curElem: any, index: number) =>
                                                    <tr key={index} >
                                                        <td className="text-dark-70"><Link className="text-dark-70" to={`/contract_details/${curElem.id}`}><img src="../assets/images/icons/folder.svg" alt="envelop" className="me-1 h-16" /> {t("contract")} {curElem?.property_identifier ? curElem?.property_identifier + "/" + curElem?.lease_count : ""}</Link>
                                                         <span className="d-block text-secondary">{t("tenant")} : {curElem?.tenant_name}</span>
                                                        </td>
                                                    </tr>
                                                ) :
                                                <div className='text-center text-secondary border p-xl-4 p-3 rounded-4'>
                                                    {t("NoRecords")}
                                                </div>
                                            }
                                        </tbody>
                                    </Table>
                                    {/* <!-- /Table end --> */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12}>
                            <Card className="h-100">
                                <Card.Header className="d-sm-flex align-items-center justify-content-between">
                                    <span>{t("Transferstatement")} {t(`half_month.${(state.searchDate).split("/")[0]}`) + "/" + (state.searchDate).split("/")[1]}</span>
                                    <ul className="list-inline fw-bold mb-0 fs-6">
                                        <li className="list-inline-item mb-sm-0 mb-1 me-2 me-xl-3 me-xxl-4">
                                            <Link to={"/report"} role="button">{t("Groupedstatement")}</Link>
                                        </li>
                                        <li className="list-inline-item mb-sm-0 mb-1 me-2 me-xl-3 me-xxl-4">
                                            <Link to={"/new_transfers"} role="button">{t("Unrealizedtransfers")}</Link>
                                        </li>
                                        <li className="list-inline-item mb-sm-0 mb-1">
                                            <Link to={"/new_transfers"} role="button">{t("Transfersmade")}</Link>
                                        </li>
                                    </ul>
                                </Card.Header>
                                <Card.Body className="pb-1">
                                <div className="d-flex justify-content-center mx-auto w-auto">
                                <ButtonGroup className="btn-date-filter" size="sm" aria-label="Date">
                                    <Button variant="primary" className="btn-arrow" onClick={() => filterCharges(state.startDate, 'Subtract' , "")}>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white.svg"} className="h-24" />
                                        {/* {state.startDate} */}
                                    </Button>
                                    <Button variant="primary" className="btn-date" disabled>
                                            {t(`half_month.${(state.searchDate).split("/")[0]}`) + "/" + (state.searchDate).split("/")[1]}
                                    </Button>
                                    <Button variant="primary" className="btn-arrow" onClick={() => filterCharges(state.endDate, 'Add', "")}>
                                        {/* {state.endDate} */}
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white-right.svg"} className="h-24" />
                                    </Button>
                                </ButtonGroup>
                                </div>
                                    <div className="d-block w-100  mt-lg-4 mt-3">
                                        <Table responsive className="table-custom table-custom-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th>{t("maturity")}</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th className='text-end'>{t("value")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {transferstate && transferstate.length > 0 ?
                                                    transferstate.map((item) => (
                                                        <tr>
                                                            <td> <span className="ps-1">
                                                                {item.recMaturity2}

                                                            </span></td>
                                                            <td>
                                                                <span className="mx-1">
                                                                    <img src="../assets/images/icons/home_g.svg" />
                                                                    <span className="ps-1">
                                                                        {item.propertyType ?
                                                                            <Link className="text-dark-70" to={"/property_detail/" + item.propertyId} target="_blank">
                                                                                {item.propertyType + ' : '}
                                                                            </Link>
                                                                            : ''
                                                                        }
                                                                        {item.address ? item.address + ' - ' : ''}
                                                                        {item.number ? item.number + ', ' : ''}
                                                                        {item.zipcode ? 'CEP: ' + item.zipcode : ''}
                                                                        {
                                                                            item.owner.length > 0 ? item.owner.map((items, indx) => (
                                                                                <>
                                                                                    &nbsp;
                                                                                    <img src="../assets/images/icons/user_g.svg" />
                                                                                    <span className="ps-1" key={indx}>
                                                                                        {items.name}
                                                                                    </span>
                                                                                </>
                                                                            ))
                                                                                : ''
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td></td>
                                                            <td className="text-end fw-bold text-danger p-2">
                                                            <span className="d-block text-nowrap">R$ -{item.totalPaidAmount}</span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <div className='text-center text-secondary p-1'>{t('formLabel.noRecordsFounds')}</div>
                                                        </td>
                                                    </tr>

                                                }

                                            </tbody>
                                        </Table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                 
                        {tenant?.tenant == 1 || tenant.owner == 1 ?
                        <Col lg={12}>
                            <Card className="h-100">
                                <Card.Header>{t("tenantcharges")}  {t(`half_month.${(chargestate.searchDate).split("/")[0]}`) + "/" + (chargestate.searchDate).split("/")[1]}</Card.Header>
                                <Card.Body className="pb-1">
                                    <div className="d-block w-100">
                                        <div className='d-inline-flex mb-lg-4 mb-3'>
                                            <ButtonGroup className="btn-date-filter" size="sm" aria-label="Date">
                                                <Button variant="primary" className="btn-arrow" onClick={() => filterCharges(chargestate.startDate, 'Subtract', "TenantCharge")}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white.svg"} className="h-24" />
                                                    {/* {state.startDate} */}
                                                </Button>
                                                <Button variant="primary" className="btn-date" onClick={() => filterCharges(chargestate.startDate, 'Subtract', "TenantCharge")} disabled>
                                                    {t(`half_month.${(chargestate.searchDate).split("/")[0]}`) + "/" + (chargestate.searchDate).split("/")[1]}
                                                </Button>
                                                <Button variant="primary" className="btn-arrow" onClick={() => filterCharges(chargestate.endDate, 'Add', "TenantCharge")}>
                                                    {/* {state.endDate} */}
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white-right.svg"} className="h-24" />
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                        <Table responsive className="table-custom table-custom-bordered  mb-0">
                                            <thead>
                                                <tr>
                                                    <th>{t("maturity")}</th>
                                                    <th>{t("Client")}</th>
                                                    <th className="text-end">{t("value")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tenantCharges && tenantCharges?.length > 0 ?
                                                    tenantCharges?.map((item, ind) => {
                                                        // Parse the date string using Moment.js
                                                        const dateObject = moment(item.recMaturity2);
                                                        // Format the date to "DD/MM/YYYY" format
                                                        const formattedDate = dateObject.format("DD/MM/YYYY");
                                                        return (
                                                            <tr key={ind}>
                                                                <td> <span className="ps-1">
                                                                    {formattedDate}
                                                                </span>
                                                                    <br />
                                                                    {
                                                                        item.status == 1 || item.status == 2 ?
                                                                            <span className="text-dark ms-1 badge bg-success-30">{t("Dashboard.Received")}</span>
                                                                            :
                                                                            item.status == 0 ?
                                                                                <span className="text-dark ms-1 badge bg-primary-30">{t("dropdownItem.Inprogress")}</span>
                                                                                :
                                                                                item.status == 3 ?
                                                                                    <span className="text-dark ms-1 badge bg-danger-30">{t("reportBanking.Suspended")}</span>
                                                                                    : ''
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <span className="ps-1">
                                                                        {item.name}
                                                                        <br />
                                                                        <span className="text-dark-70 d-block">cobrança {item.id} em Conta Teste Ingaia Matriz com NN: {item.id}.</span>

                                                                    </span></td>
                                                                <td className="text-end fw-bold text-danger">
                                                                <span className="d-block text-nowrap">R$ {item.Value}</span>
                                                                </td>
                                                            </tr>)
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={4}>
                                                        <div className='text-center text-secondary p-1'>{t('formLabel.noRecordsFounds')}</div>
                                                        </td>
                                                    </tr>

                                                }

                                            </tbody>
                                        </Table>
                                        {/* <Alert variant="warning" className="d-block mt-lg-4 mt-3">{t("datanotfound")}</Alert> */}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        :  ""}
                        {tenant?.tenant == 1 ? 
                        <Col lg={12}>
                            <Card>
                                <Card.Header className="d-sm-flex align-items-center justify-content-between">
                                    {t("formLabel.contact")}
                                    <div> <Button variant="link" className="mb-lg-4 mb-3 p-0" onClick={AddContact}>{t("Toadd")}</Button></div>
                                </Card.Header>
                                <Card.Body className="pb-1">
                                    {/* <!-- Table start from here --> */}
                                    <Table responsive className="table-custom table-custom-bordered mb-0">
                                        <tbody>
                                            {getContact.length > 0 ?
                                                getContact.map((element, index) => (
                                                    <tr>
                                                        <td className="text-dark-70">

                                                            <span className="ps-1">{element?.ST_NOME}</span><br />
                                                            <span className="ps-1">{element?.ST_DEPARTAMENTO}</span><br />
                                                            <span className="ps-1"><img src="../assets/images/icons/envelop.svg" alt="envelop" className="h-14" /> {element?.EMAIL}</span><br />
                                                            <span className="ps-1"><img src="../assets/images/icons/phone-light.svg" alt="envelop" className="h-14" /> {element?.ST_TELEFONE}</span>
                                                        </td>
                                                        <td className="text-end">
                                                            <ul
                                                                className="list-inline mb-0 d-flex justify-content-end align-items-center">
                                                                <li className="list-inline-item me-3">
                                                                    <img src="../assets/images/icons/edit-light.svg" alt="edit" className="h-14" onClick={() => updateContact(element.EMAIL)} />
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <img src="../assets/images/icons/delete-light.svg" alt="edit" onClick={() => handleDelete(element.EMAIL, "delete")} className="h-14" />
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                ))

                                                :
                                                <div className='text-center text-secondary border p-3 p-xl-4 rounded-4'>{t("datanotfound")}</div>

                                            }
                                        </tbody>
                                    </Table>
                                    {/* <!-- /Table end --> */}
                                </Card.Body>
                            </Card>
                        </Col>
                        : ""} 
                        <Col lg={12}>
                            <Card className="h-100">
                                <Card.Header>{t("Emaillist")}</Card.Header>
                                <Card.Body className="py-lg-5">
                                    {/* <!-- table start grom here --> */}
                                    <Table responsive className="table-custom table-custom-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>{t("Date")}</th>
                                                <th>{t("Message")}</th>
                                                <th className="text-end">{t("reportSystem.Recipient")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allEmailList && allEmailList.length > 0 &&
                                                allEmailList.map((item, index) => {
                                                    let datee = moment(item["DT_ENTRADA_FE"]).format("DD/MM/YYYY HH:mm:ss");
                                                    let dataMat: any = item["DT_ENTRADA_FE"];
                                                    let todayDate = new Date(dateBrazil);
                                                    let maturityDate = new Date(`${dataMat?.split("T")[0]}`);
                                                    let Difference_In_Time = maturityDate.getTime() - todayDate.getTime();
                                                    let Difference_In_Days: any = Difference_In_Time / (1000 * 3600 * 24);
                                                    let MathSign = Math.sign(Difference_In_Days);
                                                    let dayAgo = "";
                                                    if (MathSign == -1) {
                                                        dayAgo = String(parseInt(Difference_In_Days)).replace(
                                                            "-",
                                                            ""
                                                        );
                                                        if (Number(dayAgo) > 30) {
                                                            if (Math.floor(Number(dayAgo) / 30) >= 12) {
                                                                let YearAgo = `${String(
                                                                    Math.floor(Number(dayAgo) / 30 / 12)
                                                                )}`;
                                                                if (YearAgo === "1") {
                                                                    dayAgo = `${YearAgo} year`;
                                                                } else {
                                                                    dayAgo = `${YearAgo} years`;
                                                                }
                                                            } else {
                                                                let monthsAgo = `${String(
                                                                    Math.floor(Number(dayAgo) / 30)
                                                                )}`;
                                                                if (monthsAgo === "1") {
                                                                    dayAgo = `${monthsAgo} month`;
                                                                } else {
                                                                    dayAgo = `${monthsAgo} months`;
                                                                }
                                                            }
                                                        } else {
                                                            if (dayAgo == "0") {
                                                                dayAgo = "Today";
                                                            } else if (dayAgo == "1") {
                                                                dayAgo = "Yesterday";
                                                            } else {
                                                                dayAgo = `${dayAgo} days`;
                                                            }
                                                        }
                                                    }
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td>
                                                                    {/* {
                                                                        userJson && userJson.length > 0 && (userJson.indexOf(2051) !== -1) &&
                                                                        <>
                                                                            <img src="../assets/images/icons/edit-light.svg" alt="edit" className="h-14 cursor-pe" />
                                                                        </>
                                                                    } */}
                                                                </td>
                                                                <td>
                                                                    <p>
                                                                        {item["ST_ASSUNTO_FE"]} <br />
                                                                        {" -" + datee}
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    {/* {
                                                                        item["FL_OCULTO_FE"] ?
                                                                            <>
                                                                                <img src="../assets/images/icons/envlop.svg" alt="eye" className="h-12 me-1 cursor-pe" onClick={(e) => SeenEmailHandle(e, item["ID_EMAIL_FE"], item["ST_PARA_FE"])} />has already been seen
                                                                            </>
                                                                            :
                                                                            <> */}
                                                                    <img src="../assets/images/icons/envelop.svg" alt="eye" className="h-12 me-1 cursor-pe" onClick={(e) => SeenEmailHandle(e, item["ID_EMAIL_FE"], item["ST_PARA_FE"])} /> Click to download and see content
                                                                    {/* </>
                                                                    } */}
                                                                </td>
                                                                <td className="text-dark-70 text-end">
                                                                    <span className="d-block">{item["ST_PARA_FE"]}</span>
                                                                    <Badge> {dayAgo} </Badge>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </Table>
                                    <p className="mb-0">{t("formLabel.listing")} {allEmailList && allEmailList.length} {t("emails")}, {allEmailList && allEmailList.length} {t("sentandwith")}.</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </main>
            {/* <!-- /Main content start end --> */}
            {/* new payment modal start here  */}
            <div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title fs-4" id="newPaymentLabel">New payment method</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(mailSubmit)}>
                        <Modal.Body>
                            <Form.Group className="form-floating">
                                <Form.Select className={`form-select ${errors.payment ? 'is-invalid' : ''}`} id="payment" {...register('payment')} aria-label="payment">
                                    <option value="">{t("formLabel.select")}</option>
                                    <option value="1">ADM</option>
                                    <option value="2">Adm Cond</option>
                                    <option value="3">Am</option>
                                    <option value="4">Delta</option>
                                    <option value="5">Wing towers</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors.payment?.message}
                                </Form.Control.Feedback>
                                <Form.Label className="mb-3">form of payment </Form.Label>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary">
                                Close
                            </Button>
                            <Button type="submit" variant="primary" name="send" >
                                To send
                            </Button>
                        </Modal.Footer>
                    </Form>

                </Modal>
            </div>
            <TenantsService iddata={id} tenant={tenant} gettenant={gettenant} />
            <NewGuarantors show={show1} setShow={setShow1} />
            {/*  <!-- /new payment modal end  */}

            <Modal show={showNewContact} onHide={CloseContact}>
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title fs-4" id="newPaymentLabel">{addContactTxt}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(ContactSubmit)}>
                    <Modal.Body>
                        <input {...register("tenantId")} hidden />
                        <Row>
                            <FloatingLabel controlId="floatingInput" label={t('formLabel.name') + " *"} className="mb-3" >
                                <Form.Control type="text" placeholder={t('formLabel.name')} className={` ${errors.name ? 'is-invalid' : ''}`}  {...register('name')} onChange={handleKeyDown} />
                                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Row>
                        <Row>
                            <FloatingLabel controlId="floatingInput" label={t('formLabel.department')} className="mb-3" >
                                <Form.Control type="text" placeholder={t('formLabel.department')} className={` ${errors.department ? 'is-invalid' : ''}`}  {...register('department')} />
                                <Form.Control.Feedback type="invalid">{errors.department?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Row>
                        <Row>
                            <FloatingLabel controlId="floatingInput" label={t('formLabel.email')} className="mb-3" >
                                <Form.Control type="text" placeholder={t('formLabel.email')} className={` ${errors.email ? 'is-invalid' : ''}`}  {...register('email')} />
                                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Row>
                        <Row>
                            <FloatingLabel controlId="floatingInput" label={t('formLabel.telephone')} className="mb-3" >
                                <Form.Control type="text" placeholder={t('formLabel.telephone')} className={` ${errors.telephone ? 'is-invalid' : ''}`}  {...register('telephone')} onChange={HandleInput} />
                                <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={CloseContact}>
                            {t("button.close")}
                        </Button>
                        <Button type="submit" variant="primary">
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
        </section>
    )
}

export default ProfileDetails