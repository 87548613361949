import React, { useState, useEffect, useRef } from 'react';
import { Outlet, Link, useLinkClickHandler, useNavigate, useParams, useLocation } from 'react-router-dom';
import { FloatingLabel, Dropdown, Col, Row, Modal, Form, Button, Alert } from 'react-bootstrap';
import ChargesOverview from './ChargesOverview';
import ReceivableChargesListing from './ReceivableChargesListing';
import ChargePrintOut from './ChargePrintOut';
import ReceivableSubHeader from './ReceivableSubHeader';
import SettleLots from './SettleLots';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ComProps, RecChargesFotterForm } from '../../../Interface';
import eventBus from "./EventBus";
import { getChargesSummery } from './ReceivableChargesListing';
import { useTranslation } from 'react-i18next';
import { IMAGEURLLINK, converter, encrypt_Decrypt } from '../../../constants/utils';
import { incomeService } from '../../../../services/incomeServices';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { emailPostTransfer } from '../../../../redux/slice/SendEmailSlice';
import moment from "moment-timezone";
import Loader from '../../../Profile/Company/Loader';
import i18n from '../../../../i18n';
import { config } from '../../../../services/apiConfig';

const ReceivableCharges = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    const [show, setShow] = useState(true);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [chargesList, setChargesDue] = useState<any>([]);
    const [isCheckId, setIsCheckId] = useState<any>([]);
    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    const [emialStatus, setEmailStatus] = useState(false)
    let [allDesc, setAllDesc] = useState<any>({ desc_1: "" });
    const { userbanklist } = useSelector((state: RootState) => state.bank)
    const { getlogo, status } = useSelector((state: RootState) => state.companyData);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

    const options = [
        { value: 0, label_en: "Undue charge", label_pt: "Cobrança indevida" },
        { value: 1, label_en: "Replaced charge", label_pt: "Cobrança substituída" },
        { value: 2, label_en: "Cancellation", label_pt: "Cancelamento" },
        { value: 3, label_en: "Bonus", label_pt: "Bonificação" },
        { value: 6, label_en: "Proposal Cancelled", label_pt: "Proposta Cancelada" },
        { value: 7, label_en: "Provision for Doubtful Debts (PDD)", label_pt: "Provisão para Devedores Duvidosos (PDD)" },
        { value: 4, label_en: "Suspension", label_pt: "Suspensão" },
        { value: 8, label_en: "Negotiation", label_pt: "Negociação" },
        { value: 9, label_en: "Underpayment", label_pt: "Pagamento a menor" },
        { value: 5, label_en: "Others", label_pt: "Outros" }
    ];

    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(3030) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])

    const errorSwltAlert = () => {
        Swal.fire({
            //// icon: 'success','warning',
            title: t("Sweetalert.Oops"),
            text: t("Sweetalert.Pleaseselectsomeusers"),
            timer: 2500
        })
    }
    //Change expiration modal initialization
    const [showchangeExpirationModal, setchangeExpirationModal] = useState(false);
    const changeExpirationModalClose = () => setchangeExpirationModal(false);
    const changeExpirationModalShow = () => {
        if (isCheckId.length > 0) {
            setchangeExpirationModal(true)
        } else {
            errorSwltAlert()
        }
    }

    const [searchvalue, setSearchValue] = useState({});
    const [offFirstload, setOffFirstLoad] = useState(false);
    const [search_type, setsearch_type] = useState("");

    const [chargefooter, setChargeFooter] = useState({
        totalCharge: 0,
        totalCustomer: 0,
        totalAmount: 0,
    })

    //Print out modal initialization
    const [showPrintOutModal, setPrintOutModal] = useState(false);
    const printOutModalClose = () => {
        setPrintOutModal(false);
        removeEvent();
    }

    const [oncclose, setcclose] = useState(false);
    const onCloseClicked = () => {
        setcclose(false);
    }

    const printOutModalShow = () => {
        setcclose(true);
        const ID_CONTRATO_CON = "";
        incomeService.getChargeInvoice({ ID_RECEBIMENTO_RECB: isCheckId, ID_CONTRATO_CON: ID_CONTRATO_CON }).then(res => {
            if (res.data && res.data && (res.data.message && res.data.message.status == 'success')) {
                let url = `${config.CUSTOMER___API}/${res.data.message.boletoGCurl}`;
                window.open(url, '_blank');
                setIsCheckId([]);
                unSelectAllUsers();
                setcclose(false);
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.serverError"),
                    confirmButtonText: "OK",
                    timer: 2000
                });
                setIsCheckId([]);
                setcclose(false);
            }
        });
    };

    //Reason for invalidation modal initialization
    const [showReasonInvalidationModal, setReasonInvalidationModal] = useState(false);
    const reasonInvalidationModalClose = () => {
        reset();
        setMaurityError("");
        setLoader_on(false);
        setregenratedate(false);
        setReasonInvalidationModal(false);
    }
    const reasonInvalidationModalShow = (type) => {
        if (isCheckId.length > 0 || type == "invalidate") {
            setReasonInvalidationModal(true);
        } else {
            errorSwltAlert()
        }
    };


    //Change rates modal initialization
    const [showChangeRatesModal, setChangeRatesModal] = useState(false);
    const changeRatesnModalClose = () => setChangeRatesModal(false);
    const changeRatesnModalShow = () => {
        if (isCheckId.length > 0) {
            setChangeRatesModal(true);
        } else {
            errorSwltAlert()
        }
    };

    //Change bank account modal initialization
    const [showChangeBankAccountModal, setChangeBankAccountModal] = useState(false);
    const changeBankAccountClose = () => setChangeBankAccountModal(false);
    const changeBankAccountShow = () => {
        if (isCheckId.length > 0) {
            setChangeBankAccountModal(true);
        } else {
            errorSwltAlert()
        }
    };

    //Settle lots modal initialization
    const [showSettleLotsModal, setSettleLotsModal] = useState(false);
    const settleLotsModalClose = () => setSettleLotsModal(false);
    const settleLotsModalShow = () => {
        if (isCheckId.length > 0) {
            setSettleLotsModal(true);
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000,
            });
        }
    };

    const [data, setData] = useState('');
    const parentToChild = () => {
        setData("");
    }
    useEffect(() => {
        parentToChild();
    }, [])

    const selectAllUsers = () => {
        setIsCheckAll(!isCheckAll);
        setChargesDue(chargesList.map((topping: any) => ({ ...topping, isChecked: true })))
        let allId: any = [];
        chargesList.forEach((item: any) => {
            allId.push(item.ID_RECEBIMENTO_RECB);
        })
        setIsCheckId(allId);
    }

    const unSelectAllUsers = () => {
        setIsCheckAll(!isCheckAll);
        setChargesDue(chargesList.map((topping: any) => ({ ...topping, isChecked: false })))
        setIsCheckId([]);
    }

    // Form Manage
    const form_validationSchema = Yup.object().shape({
        maturityDate: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
    });
    var frm = { resolver: yupResolver(form_validationSchema) }
    if (showReasonInvalidationModal) {
        const frm1 = Yup.object().shape({
            invalidationReason: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired"))
        });
        frm = { resolver: yupResolver(frm1) }
    }
    if (showChangeRatesModal) {
        const frm2 = Yup.object().shape({});
        frm = { resolver: yupResolver(frm2) }
    }
    if (showChangeBankAccountModal) {
        const frm2 = Yup.object().shape({});
        frm = { resolver: yupResolver(frm2) }
    }

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm<RecChargesFotterForm>(frm);
    const formSubmit = (data: RecChargesFotterForm) => {
        let carryData = {
            filter_buttom: {
                data: data.maturityDate,
                id: isCheckId,
                action: 'change_expiration'
            }
        }

        incomeService.buttomFilter_charge(carryData).then(responseData => {
            if (responseData["resCode"] == 201) {
                changeExpirationModalClose();
                setSearchValue({});
                setIsCheckId([]);
                unSelectAllUsers();
                setValue("maturityDate", "");
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.RecordUpdated"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.cannotupdated"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        });
    }

    const [loader_on, setLoader_on] = useState(false);
    let [leaseError, setLeaseError] = useState("");
    let [maurityError, setMaurityError] = useState("");
    const [regenratedate, setregenratedate] = useState(false);
    const boletoAction = (evt) => {
        setregenratedate(false);
        if (evt.target.value == 'CancelRegenrateBoleto') {
            setregenratedate(true);
        }
    };
    const reasonInvalidationFrm = (data: RecChargesFotterForm) => {
        let owner: any = {
            FL_MOTIVOCANCELAR_RECB: data.invalidationReason,
            FL_EXCLUIR_DESPESAS_TERCEIRO: data.deleteExpenses ?? false,
            action: 'reason_invalidation',
            lease_period: "invalidate",
        }
        if (data.lease_period == "CancelRegenrateBoleto" && data.startDate == '') {
            setMaurityError(t("requiredMmessage.fieldRequired"));
        }
        else {
            if (data.lease_period == "CloneBoleto") {
                owner.lease_period = data.lease_period;
            }
            if (data.lease_period == "CancelRegenrateBoleto" && data.startDate != '') {
                owner.startDate = data.startDate;
                owner.lease_period = data.lease_period;
            }
            setMaurityError("")
            setLoader_on(true);
            setData(JSON.stringify(owner));
        }
    }

    const changeRatesFrm = (data: RecChargesFotterForm) => {
        let carryData = {
            filter_buttom: {
                fees: data.fees,
                trafficTicket: data.trafficTicket,
                discount: data.discount,
                id: isCheckId,
                action: 'change_rates'
            }
        }

        incomeService.buttomFilter_charge(carryData).then(responseData => {
            if (responseData["resCode"] == 201) {
                changeRatesnModalClose();
                setSearchValue({});
                setIsCheckId([]);
                unSelectAllUsers();
                setValue("fees", "");
                setValue("trafficTicket", "");
                setValue("discount", "");
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.RecordUpdated"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.cannotupdated"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        });
    }

    const changeBankFrm = (data: RecChargesFotterForm) => {
        let carryData = {
            filter_buttom: {
                data: data.bankAccount,
                id: isCheckId,
                action: 'change_bank'
            }
        }

        incomeService.buttomFilter_charge(carryData).then(responseData => {
            if (responseData["resCode"] == 201) {
                changeBankAccountClose();
                setSearchValue({});
                unSelectAllUsers();
                setIsCheckId([]);

                setValue("bankAccount", "");
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.RecordUpdated"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.cannotupdated"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        });
    }

    const handleCallback = (childData) => {
        // console.log('childData:::', childData);
    }

    eventBus.on("couponApply", (data) => {
        let owner = {
            bankAccount: data.bankAccount,
            action: 'change_bank'
        }
        setData(JSON.stringify(owner));
        removeEvent();
    });

    const removeEvent = () => {
        eventBus.remove("couponApply", {});
    }

    const ClickHandler = async (type) => {
        setEmailStatus(true)
        if (isCheckId.length > 0) {
            if (type == "ship") {
                alert("ship clicked");
            }

            if (type == "unship") {
                alert("unship clicked");

            }

            if (type == "email") {
                let lateDay: any = ''
                let allEmails: any = [];
                let mailData: any = []
                let latedata: any = []
                isCheckId.forEach((givenID) => {
                    // Check if the current ID exists in the data
                    chargesList.forEach(async (item: any, index) => {
                        if (item.ID_RECEBIMENTO_RECB == givenID) {
                            if (item.ST_EMAIL_PES && item.ST_EMAIL_SAC) {
                                allEmails.push(item?.ST_EMAIL_PES);
                            } else if (item.ST_EMAIL_PES) {
                                allEmails.push(item?.ST_EMAIL_PES);
                            } else {
                                allEmails.push();
                            }
                            mailData.push(item)
                        }
                        let maturityDate: any = new Date(item.maturityDt);
                        let currentDate: any = new Date(dateBrazil);
                        // Calculate the difference in time
                        let timeDifference = maturityDate - currentDate;
                        // Convert the time difference from milliseconds to days
                        let dayDifference: any = Math.ceil(timeDifference / (1000 * 3600 * 24));
                        let leteday = `${dayDifference}`
                        leteday = leteday.replace("-", "");
                        latedata.push({
                            lateDay: leteday,
                            today: currentDate
                        })


                    });
                })
                let DescArray: any = {};
                allEmails.forEach((item: any, index: number) => {
                    let arr = moment(mailData[index]?.maturityDt).format("DD/MM/YYYY");
                    let arre = moment(latedata[index]?.today).format("DD/MM/YYYY");
                    // Parse the date strings to Date objects
                    let date1 = new Date(arr.split('/').reverse().join('-'));
                    let date2 = new Date(arre.split('/').reverse().join('-'));
                    // Calculate the difference in milliseconds
                    let timeDifference = date2.getTime() - date1.getTime();
                    // Convert the difference to days
                    let dayDifference = timeDifference / (1000 * 3600 * 24);
                    let newText: any = ` <!DOCTYPE html>
                                <html>
                                  <head>
                                    <meta charset="UTF-8">
                                  </head>
                                  <body style="font-family:sans-serif;margin-left:2em;">
                                   <header>
                                   <img className="form-control" src=${getlogo?.logo_name && IMAGEURLLINK.replace("{logoname}", ((getlogo?.logo_name).split("_|_")[0]).replace("/", "%2F")).replace("{generation}", (getlogo?.logo_name).split("_|_")[1])} alt="logo">
                                   </header>
                                   <h1>KENLO IMÓVEIS venceu há  ${dayDifference} dias.</h1>
                                   <p>Olá ${mailData[index]?.ST_NOME_SAC}, </p>
                                   <p>Para sua comodidade disponibilizamos seu boleto que venceu há <b>${dayDifference} dias</b> .</p>
                                   <p><b>Email </b>: ${mailData[index]?.ST_EMAIL_PES ? mailData[index]?.ST_EMAIL_PES : mailData[index]?.ST_EMAIL_SAC},</p>
                                    <p><b>Emissão </b>:${moment(latedata[index]?.today).format("DD/MM/YYYY")} </p>
                                   <p><b>Vencimento </b>: ${mailData[index]?.maturityDt ? moment(mailData[index]?.maturityDt).format("DD/MM/YYYY") : moment(mailData[index]?.DT_VENCIMENTO_RECB).format("DD/MM/YYYY")}</p>
                                   <p><b>Valor </b>: ${mailData[index]?.vl_emitido_recb}</p>
                                      <div style="background-color:#F5F5F5; width: 100%; margin: 0 !important; padding: 0 !important;">
                                      <div style="max-width: 600px; margin:auto;">
                                        <div style="margin: 0 15px;  padding:32px 10px 32px 10px">
                                        <img className="form-control" src=${getlogo?.logo_name && IMAGEURLLINK.replace("{logoname}", ((getlogo?.logo_name).split("_|_")[0]).replace("/", "%2F")).replace("{generation}", (getlogo?.logo_name).split("_|_")[1])} alt="logo">
                                        </div>
                                      </div>
                                   </div>
                                  </body>
                                </html>
                        ` || ''; // Use the actual property name if needed
                    DescArray[`desc_${index + 1}`] = newText;
                })
                if (allEmails.length > 0) {
                    let emaildata: any = {
                        status: "new",
                        subject: "Email vindo do POSTMAN",
                        content: DescArray,
                        layout: "default",
                        sender: "noreply@dev.kenlo.io",
                        to: allEmails,
                        "cc": [
                            "test@wegile.com"
                        ],
                        "bcc": [
                            "karan@wegile.com"
                        ],
                        identifier: "identifier",
                        model: 1,
                        caption: "caption",
                        title: "Send Email",
                    }
                    let res = await dispatch(emailPostTransfer(emaildata))
                    if (res.payload.resCode == 201) {
                        Swal.fire({
                            title: t("Sweetalert.Success"),
                            text: t("Sweetalert.mailsent"),
                            // icon: 'success',"success",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                        setEmailStatus(false)
                        reset()
                        setAllDesc({ desc_1: "" })
                    } else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.somethingWent"),
                            // icon: 'success',"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                        setEmailStatus(false)
                    }
                } else {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.emailsRequire"),
                        // icon: 'success',"error",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                    setEmailStatus(false)
                }
            }
        } else {
            errorSwltAlert();
        }
    }

    const alertPop = (data) => {
        alert(data);
    }

    const [stateUpdate, setStateUpdate] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    let { id } = useParams();
    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M');
    let searchDt = moment(newDate)
    let endDt = moment(newDate).add(1, 'M');
    const [statusdata, setStatusdata] = useState("");
    const [state, setState] = useState<any>({
        FL_STATUS_RECB: 0,
        type: "toReceive",
        Maturity_Date: "2023-06",
        filter: {},
        startDate: startDt,
        endDate: endDt,
        searchDate: searchDt,
        filteSearchDate: "",
        filterEndDate: "",
        id: id,
        search_type: "",
        start: 0,
        end: 20,
        listType: location.state && location.state != null ? location.state.type : "",
        ID_RECEBIMENTO_RECB: location.state && location.state != null ? location.state.ID_RECEBIMENTO_RECB : "",
    })

    useEffect(() => {
        setTimeout(() => {
            if (location.state && location?.state?.dashboard == "yes") {
                // state["location"] = location.state
                if (location.state.type == "overdue") {
                    setState({
                        ...state,
                        listType: location.state.type,
                        filteSearchDate: moment().startOf("months").format("YYYY-MM-DD"),
                        filterEndDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
                    })
                } else {
                    state["filteSearchDate"] = location.state["searchFrom"];
                    state["filterEndDate"] = location.state["searchFrom"];

                    setState({
                        ...state,
                        listType: location.state.type,
                        filteSearchDate: location.state["searchFrom"],
                        filterEndDate: location.state["searchFrom"]
                    })
                }
            }
        }, 500)
    }, [])

    const handleInviladated = (chargeid: any) => {
        setIsCheckId([]);
        setTimeout(() => {
            setIsCheckId([chargeid]);
        }, 300)
        setTimeout(() => {
            reasonInvalidationModalShow("invalidate");
        }, 1000)
    }

    return (
        <section className="content-wrapper content-wrapper-primary">
            {/*<!-- Breadcrumb section start from here -->*/}
            <ReceivableSubHeader getChargesSummery={getChargesSummery} searchvalue={searchvalue}
                setSearchValue={setSearchValue} offFirstload={offFirstload} setOffFirstLoad={setOffFirstLoad}
                setsearch_type={setsearch_type} setStateUpdate={setStateUpdate}
                stateChange={state} setStatechange={setState}
                currentPage={currentPage} setCurrentPage={setCurrentPage}
                status={statusdata} setStatus={setStatusdata} />
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3" id="receivableChaarges">

                {/* <Alert className="mb-lg-4 mb-3" variant="warning" show={show} onClose={() => setShow(false)} dismissible>
                    <h6>{t("ReceiveandReceived.Questionsaboutcreditcarderrorcodes")}?</h6>
                    <Link to={'/receivable_charges'}>{t("ReceiveandReceived.Clickhere")}</Link> {t("ReceiveandReceived.orHelpintheupperrightcornerandlearnmore")}!
                </Alert> */}

                {/*<!-- Table start from here -->*/}
                {emialStatus ?
                    <Loader />
                    :
                    <ReceivableChargesListing parentToChild={data} setChargeFooter={setChargeFooter} isCheckAll={isCheckAll}
                        setIsCheckAll={setIsCheckAll} chargesList={chargesList} setChargesDue={setChargesDue}
                        isCheckId={isCheckId} setIsCheckId={setIsCheckId} searchvalue={searchvalue}
                        offFirstload={offFirstload} setOffFirstLoad={setOffFirstLoad}
                        search_type={search_type} stateUpdate={stateUpdate} stateChange={state} setStatechange={setState}
                        currentPage={currentPage} setCurrentPage={setCurrentPage}
                        status={statusdata} setStatus={setStatusdata} setLoading={setLoading}
                        reasonInvalidationModalClose={reasonInvalidationModalClose} setLoader_on1={setLoader_on}
                        handleInviladated={handleInviladated} />
                }
                {/*<!-- /Table end -->*/}


                <p className="my-lg-4 my-3">{t("formLabel.listing")} {chargefooter.totalCharge} {t("ReceiveandReceived.chargesfrom")} {chargefooter.totalCustomer} {t("ReceiveandReceived.customersinthetotalamountof")} R$ {converter(String(chargefooter.totalAmount))}</p>
                {/* <!-- Nav listing start from here -->*/}
                {
                    userJson && userJson.length > 0 && (userJson.indexOf(3031) !== -1) &&
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Dropdown>
                                <Dropdown.Toggle variant="light" size="sm">
                                    {t("formLabel.mark")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={selectAllUsers}>{t("formLabel.all")}</Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={unSelectAllUsers}>{t("formLabel.none")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"> <span className="px-1">{t("expenditure.withTagged")}:</span></li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm" onClick={printOutModalShow}>{t("printOut")}</Button>
                            <ChargePrintOut show={showPrintOutModal} onClose={printOutModalClose} isCheckId={isCheckId} setIsCheckId={setIsCheckId} unSelectAllUsers={unSelectAllUsers} />
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm" onClick={() => ClickHandler("email")}>{t("TransferPage.Sendbyemail")}</Button>
                        </li>
                        {/* <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm" onClick={changeExpirationModalShow}>{t("TransferPage.Changeexpiration")}</Button>
                        </li> */}
                        <li className="list-inline-item mb-lg-0 mb-1">
                            <Dropdown drop="up">
                                <Dropdown.Toggle variant="light" size="sm">
                                    {t("button.more")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="mh-456 overflow-auto">
                                    <Dropdown.Item onClick={settleLotsModalShow}>{t("batchSettlement")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => reasonInvalidationModalShow("normal")}>{t("ReceiveandReceived.Invalidate")}</Dropdown.Item>
                                    {/* <Dropdown.Item onClick={changeRatesnModalShow}>{t("ReceiveandReceived.Changeinterestpenaltyanddiscount")}</Dropdown.Item> */}
                                    <Dropdown.Item onClick={() => alertPop("agree interest")}>{t("ReceiveandReceived.Returnagreedinterest")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => alertPop("agree fine")}>{t("ReceiveandReceived.Returnagreedfine")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => alertPop("agreed discount")}>{t("ReceiveandReceived.Backagreeddiscount")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => alertPop("credit card")}>{t("ReceiveandReceived.Forcecreditcardprocessing")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => ClickHandler("ship")}>{t("ReceiveandReceived.Markforshipment")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => ClickHandler("unship")}>{t("ReceiveandReceived.Unmarkfromshipment")}</Dropdown.Item>
                                    <Dropdown.Item onClick={changeBankAccountShow}>{t("ReceiveandReceived.Changebankaccount")}</Dropdown.Item>
                                    <Dropdown.Item onClick={changeExpirationModalShow}>{t("ReceiveandReceived.Changecompetency")}</Dropdown.Item>

                                    <SettleLots showSettleLotsModal={showSettleLotsModal} settleLotsModalClose={settleLotsModalClose} id={isCheckId} state={state} getChargesSummery={getChargesSummery} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>
                }
                {/*<!-- /Nav listing  end -->*/}
            </main>
            {/*<!-- /Main content end -->*/}
            {/* Change expiration modal start from here */}
            <Modal show={showchangeExpirationModal} onHide={changeExpirationModalClose}>
                <Form onSubmit={handleSubmit(formSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("TransferPage.Changematurity")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="maturity" label={t("maturity")}>
                            <Form.Control type="date" placeholder={t("maturity")} className={`form-control ${errors.maturityDate ? 'is-invalid' : ''}`}  {...register('maturityDate')} />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={changeExpirationModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button type="submit" variant="primary" name="save" >{t("button.toSave")}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Change expiration modal end */}
            {/* Reason for invalidation modal start from here */}
            <Modal show={showReasonInvalidationModal} onHide={reasonInvalidationModalClose}>
                <Form onSubmit={handleSubmit(reasonInvalidationFrm)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("ReceiveandReceived.Reasonforinvalidation")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert variant="warning" className="mb-lg-4 mb-3">
                            {t("ReceiveandReceived.usetheoptiononly")}.
                        </Alert>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="reason_invalidation" label={t("ReceiveandReceived.Reasonforinvalidation")}>
                            <Form.Select aria-label="Reason for invalidation" className={`form-control ${errors.invalidationReason ? 'is-invalid' : ''}`}  {...register('invalidationReason')} >
                                {
                                    options.map((item: any, index: number) => {
                                        return (
                                            <option key={index} value={item.value}>{item[`label_${i18n.language}`]}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </FloatingLabel>
                        <Row className="g-3 g-xl-4">
                            <Col md>
                                <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-3">
                                    <Form.Check
                                        type="radio"
                                        {...register("lease_period")}
                                        value="CancelRegenrateBoleto"
                                        onChange={boletoAction}
                                        label={t("CancelRegenrate")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {(regenratedate) &&
                            <>
                                <Row className="g-3 g-xl-4" >
                                    <Col className="mb-lg-4 mb-3">
                                        <FloatingLabel label={t("maturity")}>
                                            <Form.Control type="date" aria-label="From"  {...register("startDate")}></Form.Control>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <p className="error">
                                    {maurityError ? maurityError : ""}
                                </p>
                            </>
                        }
                        <Row className="g-3 g-xl-4">
                            {/*<Col md>
                                <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-3">
                                    <Form.Check
                                        type="radio"
                                        {...register("lease_period")}
                                        value="CloneBoleto"
                                        onChange={boletoAction}
                                        label={t("chargeClone")}
                                    />
                                </Form.Group>
                            </Col>*/}
                            <p className="error">
                                {leaseError ? leaseError : ""}
                            </p>
                        </Row>
                        {/* <Form.Check type="checkbox" {...register('deleteExpenses')} id="delete_expenses" label={t("ReceiveandReceived.Deletependingexpensesforthirdparty")} /> */}
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            loader_on ?
                                <Button className="btn-width">
                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                </Button>
                                :
                                <>
                                    <Button variant="outline-primary" onClick={reasonInvalidationModalClose}>
                                        {t("button.close")}
                                    </Button>
                                    <Button type="submit" variant="primary" name="save" >{t("button.toSave")}</Button>
                                </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Reason for invalidation modal end */}
            {/* Change rates modal start from here */}
            <Modal show={showChangeRatesModal} onHide={changeRatesnModalClose}>
                <Form onSubmit={handleSubmit(changeRatesFrm)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("ReceiveandReceived.Changerates")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="fees" label={t("formReceipt.fees") + "(%)"}>
                            {/* <Form.Select aria-label="Fees" {...register('fees')}>
                                <option value="1">Do not change</option>
                                <option value="2">Change</option>
                            </Form.Select> */}
                            <Form.Control type="text" placeholder={t("formReceipt.fees")} className={`form-control ${errors.fees ? 'is-invalid' : ''}`}  {...register('fees')} />
                        </FloatingLabel>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="traffic_ticket" label={t("formReceipt.trafficTicket") + "(%)"}>
                            {/* <Form.Select aria-label="Traffic ticket" {...register('trafficTicket')}>
                                <option value="1">Do not change</option>
                                <option value="2">Change</option>
                            </Form.Select> */}
                            <Form.Control type="text" placeholder={t("formReceipt.trafficTicket")} className={`form-control ${errors.maturityDate ? 'is-invalid' : ''}`}  {...register('trafficTicket')} />
                        </FloatingLabel>
                        <FloatingLabel controlId="discount" label={t("formReceipt.discount") + "(%)"}>
                            {/* <Form.Select aria-label="Discount" {...register('discount')}>
                                <option value="1">Do not Change</option>
                                <option value="2">Change</option>
                            </Form.Select> */}
                            <Form.Control type="text" placeholder={t("formReceipt.discount")} className={`form-control ${errors.discount ? 'is-invalid' : ''}`}  {...register('discount')} />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={changeRatesnModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button type="submit" variant="primary" name="save" >{t("button.toSave")}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Change rates modal end */}
            {/* Change bank account modal start from here */}
            <Modal show={showChangeBankAccountModal} onHide={changeBankAccountClose}>
                <Form onSubmit={handleSubmit(changeBankFrm)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("ReceiveandReceived.Changebankaccount")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="bank_account" label={t("bankAccount")}>
                            <Form.Select aria-label="Bank account" {...register('bankAccount')}>
                                <option value="">{t('dropdownItem.select')}..</option>
                                {userbanklist && userbanklist?.length > 0 &&
                                    userbanklist.map((curElem: any, index) => {
                                        return (
                                            <option key={index} value={curElem.id}>{curElem.nameReference}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={changeBankAccountClose}>
                            {t("button.close")}
                        </Button>
                        <Button type="submit" variant="primary" name="save" >{t("button.toSave")}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal show={oncclose} onHide={onCloseClicked}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("printOut")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='text-center' style={{paddingBottom: "60px"}}>
                            <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal>
            {/* /Change bank account modal end */}
        </section>
    )
}

export default ReceivableCharges