import { settleForm,contractLabelForm,generateMeatForm,newLiquidateForm,newServiceForm,newExpenseForm,newElectronicEnvelopeForm,newChecklistForm,newExtraChargeForm,quoteForm,warrantyForm,contractForm,contractSearchForm,insuranceForm,tenantsForm,contractPropertyForm,administratorForm,condosForm,ownerForm,insuranceRegistrationForm }  from '../../component/Interface';
import { createAsyncThunk, createSlice, PayloadAction }           from "@reduxjs/toolkit";
import {config,gettoken } from "../../Api/Contact";
import customerService     from '../../services/customerService';
import Swal                from 'sweetalert2';

export const STATUSES = Object.freeze({
  IDLE    : "idle",
  LOADING : "loading",
  SUCEESS : "success",
  ERROR   : "error",
});

export interface Columns {
  TaxaDeAdministracao : string;
  id            : number;
  name          : string;
  email         : string;
  telephone     : string;
  responsible   : string;  
  observation   : string;
  favorecidoId  : number;
  ST_CONTA_CONT : string;
}
export interface contractColumn {
  inProgress        : number;
  pending           : number;
  toReadjust        : number;
  conquered         : number;
  terminated        : number;
  noRentalFee       : number;
  overdueCharge     : number,
  transferComplete  : number,
  transferAmount    : string,
  monthGuaranted    : number,
}

export interface Property {
  aarin_id: string;
  id           : number;
  complement   : string;
  neighborhood : string;
  ownerId      : number;
  ownerName    : string;
  number       : string;
  address      : string;
  zipcode      : string;
  rent_amount  : string;
  monthly_admin_rate  : string;

}

interface PropertyType {
  value: string,
  label: string
}
interface SliceState {
  contractData      : contractSearchForm[] | any,
  readContractData  : contractSearchForm[] | any,
  contExpenseData   : contractSearchForm[] | any,
  contractDatail    : contractSearchForm[] | any,
  contTransferData  : contractSearchForm[] | any,
  suppliersData     : Columns[],
  expenseData       : newExpenseForm[] | any,
  contractCount     : contractColumn[]|any,
  singleContCount   : contractColumn[]|any,
  tenantsData       : Columns[],
  contTenantsData   : Columns[],
  serviceData       : Columns[],
  propertyData      : Property[] | any,
  insuranceData     : Columns[] | any,
  settleData        : Columns[] | any,
  ownerData         : Columns[],
  witnessData       : Columns[],
  citiesData        : Columns[],
  neighborData      : Columns[],
  guarantorData     : Columns[],
  proprtyData       : contractPropertyForm[]|any,
  proprtyStatus     : string,
  guarantorStatus   : string,
  status            : string,
  settleStatus      : string,
  status1           : string,
  transferStatus    : string,
  expenseStatus     : string,
  witnessStatus     : string,
  cityStatus        : string,
  neighborStatus    : string,
  newContractShow   : boolean,
  newInsuranceShow  : boolean,
  kenloPartnershipLarge  : boolean,
  kenloPartnershipSmall  : string,
  newSettleShow     : boolean,
  newTenantsShow    : boolean,
  newManagerShow    : boolean,
  newPropertyShow   : boolean,
  newAdministratorShow      : boolean,  
  newRenewInsuranceShow     : boolean,  
  newElectronicEnvelopeShow : boolean,
  newPropertyElectronicEnvelopeShow: boolean
  newCondosShow     : boolean,
  newOwnerShow      : boolean,
  newBrokerShow     : boolean,
  newWarrantyShow   : boolean,
  newQuoteShow      : boolean,
  newExtraChargeShow: boolean,
  insuranceTp       : string,
  insuranceObj      : any,
  extraChargeLabel  : string,
  newChecklistShow  : boolean,
  newChecklistItemShow  : boolean,
  witnessType       : string,
  newExpenseShow    : boolean,
  newRentalFeeShow  : boolean,
  newExpenseTitle   : string,
  newServiceShow    : boolean,
  newLiquidateShow  : boolean,
  liquidateTitle    : string,
  liquidateType     : boolean,
  contractStatusShow: boolean,
  contractStTitle   : string,
  checkedId         : string,
  contractStNumber  : number,
  contractLabelShow : boolean,
  contractLabel     : string,
  serviceType       : string,
  expenseType       : boolean,
  expenseCheckbox   : boolean,
  chargeId          : string,
  generateMeatShow  : boolean,
  emailShow         : boolean,
  administproprty   : any, 
  adminRate         : any,
  documents         : any,
  readStatus        : string,
  show              : boolean,
  hide              : boolean,
  hideData          : boolean,
  datepickershow    : boolean,
  showContractSearch: boolean
  Property_Type    ?: PropertyType[];
  guarantors       ?: any;
  settleId       : string;
  Contractstatus : string,
  showSupplier   : boolean,
  hideSupplier   :boolean,
  tenantIndex       : string|any,
  tenantId          : string|any,
  TransferType      : string
  showNewContact: false
  filterDatadate      :  any
}

const initialState: SliceState = {
  contractData      : [],
  readContractData  : [],
  contExpenseData   : [],
  contractDatail    : [],
  contTransferData  : [],
  expenseData       : [],
  contractCount     : [],
  suppliersData     : [],
  tenantsData       : [],
  contTenantsData   : [],
  serviceData       : [],
  propertyData      : [],
  insuranceData     : [],
  ownerData         : [],
  witnessData       : [],
  citiesData        : [],
  neighborData      : [],
  proprtyData       : [],
  guarantorData     : [],
  singleContCount   : [],
  documents         : [],
  settleData        : [],
  Property_Type  :  [
    { value: "1", label: "Casa" },
    { value: "4", label: "Apartamento" },
    { value: "33", label: "Apartamento Duplex" },
    { value: "38", label: "Apartamento Triplex" },
    { value: "9", label: "Área comum" },
    { value: "42", label: "Andar corporativo" },
    { value: "29", label: "Bangalô" },
    { value: "13", label: "Barracão" },
    { value: "40", label: "Box/Garagem" },
    { value: "3", label: "Casa comercial" },
    { value: "2", label: "Casa em condomínio" },
    { value: "44", label: "Casa Assobradada" },
    { value: "5", label: "Cobertura" },
    { value: "14", label: "Chácara" },
    { value: "25", label: "Conjunto" },
    { value: "39", label: "Edícula" },
    { value: "10", label: "Escritório" },
    { value: "20", label: "Fazenda" },
    { value: "6", label: "Flat" },
    { value: "16", label: "Galpão" },
    { value: "8", label: "Garagem" },
    { value: "43", label: "Garden" },
    { value: "21", label: "Haras" },
    { value: "35", label: "Hotel" },
    { value: "31", label: "Ilha" },
    { value: "7", label: "Kitnet" },
    { value: "27", label: "Laje" },
    { value: "30", label: "Loft" },
    { value: "12", label: "Loja" },
    { value: "41", label: "Pavilhão" },
    { value: "32", label: "Penthouse" },
    { value: "22", label: "Prédio" },
    { value: "28", label: "Pousada" },
    { value: "19", label: "Ponto" },
    { value: "18", label: "Quiosque" },
    { value: "36", label: "Rancho" },
    { value: "34", label: "Resort" },
    { value: "15", label: "Salão" },
    { value: "11", label: "Sala comercial" },
    { value: "23", label: "Sítio" },
    { value: "24", label: "Sobrado" },
    { value: "37", label: "Studio" },
    { value: "17", label: "Terreno" },
    { value: "26", label: "Village" },
    { value: "45", label: "Consultório" },
    { value: "1000", label: "Outro" }
],
 guarantors: [
  { value: "1", label: "Guarantor" },
  { value: "2", label: "Security deposit" },
  { value: "3", label: "Surety bond" },
  { value: "4", label: "Capitalization bond" },
  { value: "5", label: "Surety" },
  { value: "6", label: "Fiduciary assignment" },
  { value: "0", label: "Has no warranty" }
],
  newContractShow   : false,
  newInsuranceShow  : false,
  kenloPartnershipLarge  : false,
  kenloPartnershipSmall  : "",
  newSettleShow     : false,
  newTenantsShow    : false,
  newManagerShow    : false,
  newPropertyShow   : false,
  newAdministratorShow:false,
  newCondosShow     : false,
  newOwnerShow      : false,
  newBrokerShow     : false,
  newWarrantyShow   : false,
  newQuoteShow      : false,
  generateMeatShow  : false,
  emailShow         : false,
  administproprty   : "",
  newRenewInsuranceShow     : false,
  newExtraChargeShow        : false,
  newElectronicEnvelopeShow : false,
  newPropertyElectronicEnvelopeShow: false,
  insuranceTp       : "",
  insuranceObj      : "",
  extraChargeLabel  : "",
  newChecklistShow  : false,  
  newChecklistItemShow  : false,  
  witnessType       : "",
  newRentalFeeShow  : false,
  newExpenseShow    : false,
  newExpenseTitle   : "",
  newServiceShow    : false,
  newLiquidateShow  : false,
  liquidateTitle    : "",
  liquidateType     : false,
  contractStatusShow: false,
  contractStTitle   : "",
  checkedId         : "",
  contractStNumber  : 0,
  contractLabelShow : false,
  contractLabel     : "",
  serviceType       : "",
  expenseType       : false,
  expenseCheckbox   : false,
  chargeId          : "",
  adminRate         : 0,
  show              : false,
  hide              : false,
  hideData          : false,
  datepickershow    : false,
  showContractSearch : false,
  proprtyStatus     : STATUSES.IDLE,
  status            : STATUSES.IDLE,
  status1           : STATUSES.IDLE,
  expenseStatus     : STATUSES.IDLE,
  witnessStatus     : STATUSES.IDLE,
  cityStatus        : STATUSES.IDLE,
  neighborStatus    : STATUSES.IDLE,
  guarantorStatus   : STATUSES.IDLE,
  readStatus        : STATUSES.IDLE,
  settleStatus      : STATUSES.IDLE,
  transferStatus    : STATUSES.IDLE,
  settleId          : "",
  Contractstatus    : STATUSES.IDLE,
  showSupplier      : false,
  hideSupplier      : false,
  tenantIndex       : "",
  tenantId          : "",
  TransferType      : "",
  showNewContact: false,
  filterDatadate     :  []
};
let user_id = gettoken.userId

const contractDataSlice = createSlice({
  name: "contractDataSlice",
  initialState,
  reducers: {
    contractClose: (state, action) => {
      state.newContractShow = action.payload
    },
    contractOpen: (state, action) => {
      state.newContractShow = action.payload
    },
    insuranceClose: (state, action) => {
      state.newInsuranceShow = action.payload
    },
    insuranceOpen: (state, action) => {
      state.newInsuranceShow = action.payload
    },
    partnershipLargeClose: (state, action) => {
      state.kenloPartnershipLarge = action.payload
    },
    partnershipLargeOpen: (state, action) => {
      state.kenloPartnershipLarge = action.payload
    },
    partnershipSmallClose: (state, action) => {
      state.kenloPartnershipSmall = action.payload
    },
    partnershipSmallOpen: (state, action) => {
      state.kenloPartnershipSmall = action.payload
    },
    settleClose: (state, action) => {
      state.newSettleShow = action.payload
    },
    settleOpen: (state, action) => {
      state.newSettleShow = action.payload
    },
    tenantsClose: (state, action) => {
      state.newTenantsShow = action.payload
    },
    tenantsOpen: (state, action) => {
      state.newTenantsShow = action.payload
    },
    managerClose: (state, action) => {
      state.newManagerShow = action.payload
    },
    managerOpen: (state, action) => {
      state.newManagerShow = action.payload
    },
    propertyClose: (state, action) => {
      state.newPropertyShow = action.payload
    },
    propertyOpen: (state, action) => {
      state.newPropertyShow = action.payload
    },
    administratorClose: (state, action) => {
      state.newAdministratorShow = action.payload
    },
    administratorOpen: (state, action) => {
      state.newAdministratorShow = action.payload
    },
    condosClose: (state, action) => {
      state.newCondosShow = action.payload
    },
    condosOpen: (state, action) => {
      state.newCondosShow = action.payload
    },
    ownerClose: (state, action) => {
      state.newOwnerShow = action.payload
    },
    ownerOpen: (state, action) => {
      state.newOwnerShow = action.payload
    },
    brokerClose: (state, action) => {
      state.newBrokerShow = action.payload
    },
    brokerOpen: (state, action) => {
      state.newBrokerShow = action.payload
    },
    warrantyClose:(state,action)=>{
      state.newWarrantyShow = action.payload
    },
    warrantyOpen:(state,action)=>{
      state.newWarrantyShow = action.payload
    },
    quoteClose:(state,action)=>{
      state.newQuoteShow = action.payload
    },
    quoteOpen:(state,action)=>{
      state.newQuoteShow = action.payload
    },
    renewInsuranceClose:(state,action)=>{
      state.newRenewInsuranceShow = action.payload
    },
    renewInsuranceOpen:(state,action)=>{
      state.newRenewInsuranceShow = action.payload
    },
    reInsuranceTp:(state,action)=>{
      state.insuranceTp = action.payload
    },
    insuranceObj:(state,action)=>{
      state.insuranceObj = action.payload
    },
    newExtraChargeClose:(state,action)=>{
      state.newExtraChargeShow = action.payload
    },
    newExtraChargeOpen:(state,action)=>{
      state.newExtraChargeShow = action.payload
    },
    setExtraChargeLabel:(state,action)=>{
      state.extraChargeLabel = action.payload
    },
    setSettleId:(state,action)=>{
      state.settleId = action.payload
    },
    setTenantIndex:(state,action)=>{
      state.tenantIndex = action.payload
    },
    setTenantId:(state,action)=>{
      state.tenantId = action.payload
    },
    setExtraCharge:(state,action)=>{
      state.chargeId = action.payload
    },
    newChecklistClose:(state,action)=>{
      state.newChecklistShow = action.payload
    },
    newChecklistOpen:(state,action)=>{
      state.newChecklistShow = action.payload
    },
    newChecklistItemOpen:(state,action)=>{
      state.newChecklistItemShow = action.payload
    },
    newChecklistItemClose:(state,action)=>{
      state.newChecklistItemShow = action.payload
    },
    newElectronicEnvelopeClose:(state,action)=>{
      state.newElectronicEnvelopeShow = action.payload
    },
    newElectronicEnvelopeOpen:(state,action)=>{
      state.newElectronicEnvelopeShow = action.payload
    },
    newPropertyElectronicEnvelopeClose:(state,action)=>{
      state.newPropertyElectronicEnvelopeShow = action.payload
    },
    newPropertyElectronicEnvelopeOpen:(state,action)=>{
      state.newPropertyElectronicEnvelopeShow = action.payload
    },
    setWitnessType:(state,action)=>{
      state.witnessType = action.payload
    },
    newRentalFeeClose:(state,action)=>{
      state.newRentalFeeShow = action.payload
    },
    newRentalFeeOpen:(state,action)=>{
      state.newRentalFeeShow = action.payload
    },
    newExpenseClose:(state,action)=>{
      state.newExpenseShow = action.payload
    },
    newExpenseOpen:(state,action)=>{
      state.newExpenseShow = action.payload
    },
    setExpenseTitle:(state,action)=>{
      state.newExpenseTitle=action.payload
    },
    newServiceClose:(state,action)=>{
      state.newServiceShow = action.payload
    },
    newServiceOpen:(state,action)=>{
      state.newServiceShow = action.payload
    },
    newLiquidateClose:(state,action)=>{
      state.newLiquidateShow = action.payload
    },
    newLiquidateOpen:(state,action)=>{
      state.newLiquidateShow = action.payload
    },
    setLiquidateTitle:(state,action)=>{
      state.liquidateTitle=action.payload
    },
    setLiquidateType:(state,action)=>{
      state.liquidateType=action.payload
    },
    contractStatusClose:(state,action)=>{
      state.contractStatusShow = action.payload
    },
    contractStatusOpen:(state,action)=>{
      state.contractStatusShow = action.payload
    },
    setContractStTitle:(state,action)=>{
      state.contractStTitle=action.payload
    },
    setCheckedId:(state,action)=>{
      state.checkedId=action.payload
    },
    setContractStNumber:(state,action)=>{
      state.contractStNumber=action.payload
    },
    contractLabelClose:(state,action)=>{
      state.contractLabelShow = action.payload
    },
    contractLabelOpen:(state,action)=>{
      state.contractLabelShow = action.payload
    },
    setContractLabel:(state,action)=>{
      state.contractLabel=action.payload
    },
    contractGenerateMeatClose:(state,action)=>{
      state.generateMeatShow = action.payload
    },
    contractGenerateMeatOpen:(state,action)=>{
      state.generateMeatShow = action.payload
    },
    setServiceType:(state,action)=>{
      state.serviceType=action.payload
    },
    setExpenseType:(state,action)=>{
      state.expenseType=action.payload
    },
    setExpenseCheckbox:(state,action)=>{
      state.expenseCheckbox=action.payload
    },
    showEmail:(state, action) =>{
      state.emailShow = action.payload
    },
    closeEmail:(state, action)=>{
      state.emailShow = action.payload
    },
    admPropery:(state, action)=>{
      state.administproprty = action.payload
    },
    openEmailPop:(state, action) =>{
      state.emailShow = action.payload
    },

    openDocumentEmailPopup:(state, action)=> {
      state.emailShow = action.payload
    },

    openConfigureEmail:(state, action) =>{
      state.emailShow = action.payload
    },
    openNewOwner:(state, action) =>{
      state.show = action.payload
    },
    ownerhide:(state, action) =>{
      state.hide = action.payload
    },
    showChargeDate:(state, action) =>{
      state.datepickershow = action.payload
    },
    showChargeData:(state, action) =>{
      state.filterDatadate = action.payload
    },
    showSuppliers: (state, action) =>{
    state.showSupplier = action.payload
    },
    hideSuppliers:(state, action) =>{
      state.hideSupplier = action.payload
    },
    contractDateSearch: (state, action) =>{
      state.showContractSearch = action.payload
    },
    TransferCom:(state, action) =>{
    state.TransferType = action.payload
    },
    newContact :(state, action) =>{
      state.showNewContact = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(contractsData.pending, (state, action) => {
        state.Contractstatus = STATUSES.LOADING;
      })
      .addCase(contractsData.fulfilled, (state, action: PayloadAction<any>) => {
        state.contractData = action.payload
       state.adminRate = state.contractData.data.reduce((total, item) => total + parseInt(item.admin_rate), 0);
        state.Contractstatus = STATUSES.SUCEESS;
      })
      .addCase(contractsData.rejected, (state, action: PayloadAction<any>) => {
        state.Contractstatus = STATUSES.ERROR;
      }); 
    builder
      .addCase(getContractSearchData.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getContractSearchData.fulfilled, (state, action: PayloadAction<any>) => {
        state.contractData = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getContractSearchData.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      }); 
    builder
      .addCase(getReadContractData.pending, (state, action) => {
        state.readStatus = STATUSES.LOADING;
      })
      .addCase(getReadContractData.fulfilled, (state, action: PayloadAction<any>) => {
        state.readContractData = action.payload
        state.readStatus = STATUSES.SUCEESS;
      })
      .addCase(getReadContractData.rejected, (state, action: PayloadAction<any>) => {
        state.readStatus = STATUSES.ERROR;
      }); 
    builder
      .addCase(contractsExpenseData.pending, (state, action) => {
        state.expenseStatus = STATUSES.LOADING;
      })
      .addCase(contractsExpenseData.fulfilled, (state, action: PayloadAction<any>) => {
        state.contExpenseData = action.payload
        state.expenseStatus = STATUSES.SUCEESS;
      })
      .addCase(contractsExpenseData.rejected, (state, action: PayloadAction<any>) => {
        state.expenseStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getContractCount.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getContractCount.fulfilled, (state, action: PayloadAction<any>) => {
        state.contractCount = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getContractCount.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      }); 
    builder
      .addCase(getContractDetailsCount.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getContractDetailsCount.fulfilled, (state, action: PayloadAction<any>) => {
        state.singleContCount = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getContractDetailsCount.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
    });
    builder
      .addCase(contractDatails.pending, (state, action) => {
        state.status1 = STATUSES.LOADING;
      })
      .addCase(contractDatails.fulfilled, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.SUCEESS
        state.contractDatail = action.payload.data
      })
      .addCase(contractDatails.rejected, (state, action: PayloadAction<any>) => {
        state.status1 = STATUSES.ERROR;
      }); 
     builder
      .addCase(getContractTransfer.pending, (state, action) => {
        state.transferStatus = STATUSES.LOADING;
      })
      .addCase(getContractTransfer.fulfilled, (state, action: PayloadAction<any>) => {
        state.transferStatus = STATUSES.SUCEESS
        state.contTransferData = action.payload
      })
      .addCase(getContractTransfer.rejected, (state, action: PayloadAction<any>) => {
        state.transferStatus = STATUSES.ERROR;
      });  
    builder
      .addCase(insertContract.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertContract.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertContract.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      }); 
    builder
      .addCase(addContractCronRecords.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(addContractCronRecords.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(addContractCronRecords.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      }); 
    builder
      .addCase(addBillingTicketCron.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(addBillingTicketCron.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(addBillingTicketCron.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      }); 
    builder
      .addCase(getTenants.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getTenants.fulfilled, (state, action: PayloadAction<any>) => {
        state.tenantsData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getTenants.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getContractTenants.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getContractTenants.fulfilled, (state, action: PayloadAction<any>) => {
        state.contTenantsData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getContractTenants.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      }); 
    builder
      .addCase(getGuarantor.pending, (state, action) => {
        state.guarantorStatus = STATUSES.LOADING;
      })
      .addCase(getGuarantor.fulfilled, (state, action: PayloadAction<any>) => {
        state.guarantorData = action.payload.data
        state.guarantorStatus = STATUSES.SUCEESS;
      })
      .addCase(getGuarantor.rejected, (state, action: PayloadAction<any>) => {
        state.guarantorStatus = STATUSES.ERROR;
      }); 
    builder
      .addCase(getCities.pending, (state, action) => {
        state.cityStatus = STATUSES.LOADING;
      })
      .addCase(getCities.fulfilled, (state, action: PayloadAction<any>) => {
        state.citiesData = action.payload.data
        state.cityStatus = STATUSES.SUCEESS;
      })
      .addCase(getCities.rejected, (state, action: PayloadAction<any>) => {
        state.cityStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getNeighborhood.pending, (state, action) => {
        state.neighborStatus = STATUSES.LOADING;
      })
      .addCase(getNeighborhood.fulfilled, (state, action: PayloadAction<any>) => {
        state.neighborData = action.payload.data
        state.neighborStatus = STATUSES.SUCEESS;
      })
      .addCase(getNeighborhood.rejected, (state, action: PayloadAction<any>) => {
        state.neighborStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getAllProperty.pending, (state, action) => {
        state.proprtyStatus = STATUSES.LOADING;
      })
      .addCase(getAllProperty.fulfilled, (state, action: PayloadAction<any>) => {
        state.proprtyData = action.payload
        state.proprtyStatus = STATUSES.SUCEESS;
      })
      .addCase(getAllProperty.rejected, (state, action: PayloadAction<any>) => {
        state.proprtyStatus = STATUSES.ERROR;
      });
      builder
      .addCase(getAllProperty_administractor.pending, (state, action) => {
        state.proprtyStatus = STATUSES.LOADING;
      })
      .addCase(getAllProperty_administractor.fulfilled, (state, action: PayloadAction<any>) => {
        state.proprtyStatus = STATUSES.SUCEESS;
      })
      .addCase(getAllProperty_administractor.rejected, (state, action: PayloadAction<any>) => {
        state.proprtyStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getProperty.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getProperty.fulfilled, (state, action: PayloadAction<any>) => {
        state.propertyData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getProperty.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getAllService.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getAllService.fulfilled, (state, action: PayloadAction<any>) => {
        state.serviceData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getAllService.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      }); 
    builder
      .addCase(getAllSuppliers.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getAllSuppliers.fulfilled, (state, action: PayloadAction<any>) => {
        state.suppliersData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getAllSuppliers.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      }); 
      builder
      .addCase(insertSuppliers.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertSuppliers.fulfilled, (state, action: PayloadAction<any>) => {
        state.suppliersData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertSuppliers.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      }); 
    builder
      .addCase(getInsurance.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getInsurance.fulfilled, (state, action: PayloadAction<any>) => {
        state.insuranceData = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getInsurance.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getSettle.pending, (state, action) => {
        state.settleStatus = STATUSES.LOADING;
      })
      .addCase(getSettle.fulfilled, (state, action: PayloadAction<any>) => {
        state.settleStatus = STATUSES.SUCEESS
        state.settleData = action.payload.data
      })
      .addCase(getSettle.rejected, (state, action: PayloadAction<any>) => {
        state.settleStatus = STATUSES.ERROR;
      }); 

    builder
      .addCase(getOwner.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getOwner.fulfilled, (state, action: PayloadAction<any>) => {
        state.ownerData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getOwner.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
      builder
      .addCase(filterOwners.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(filterOwners.fulfilled, (state, action: PayloadAction<any>) => {
        state.ownerData = action.payload.data
        state.status = STATUSES.SUCEESS;
      })
      .addCase(filterOwners.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(getAllExpense.pending, (state, action) => {
        state.expenseStatus = STATUSES.LOADING;
      })
      .addCase(getAllExpense.fulfilled, (state, action: PayloadAction<any>) => {
        state.expenseData = action.payload
        state.expenseStatus = STATUSES.SUCEESS;
      })
      .addCase(getAllExpense.rejected, (state, action: PayloadAction<any>) => {
        state.expenseStatus = STATUSES.ERROR;
      });
      builder
      .addCase(getsingleExpense.pending, (state, action) => {
        state.expenseStatus = STATUSES.LOADING;
      })
      .addCase(getsingleExpense.fulfilled, (state, action: PayloadAction<any>) => {
        state.expenseData = action.payload
        state.expenseStatus = STATUSES.SUCEESS;
      })
      .addCase(getsingleExpense.rejected, (state, action: PayloadAction<any>) => {
        state.expenseStatus = STATUSES.ERROR;
      });
    builder
      .addCase(getAllWitness.pending, (state, action) => {
        state.witnessStatus = STATUSES.LOADING;
      })
      .addCase(getAllWitness.fulfilled, (state, action: PayloadAction<any>) => {
        state.witnessData = action.payload.data
        state.witnessStatus = STATUSES.SUCEESS;
      })
      .addCase(getAllWitness.rejected, (state, action: PayloadAction<any>) => {
        state.witnessStatus = STATUSES.ERROR;
      });
    builder
      .addCase(insertInsurance.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertInsurance.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertInsurance.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      }); 
    builder
      .addCase(insertTenants.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertTenants.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertTenants.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
      builder
      .addCase(updateTenants.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateTenants.fulfilled, (state, action) => {
        state.status = STATUSES.SUCEESS;
      })
      .addCase(updateTenants.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertManager.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertManager.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertManager.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertProperty.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertProperty.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertProperty.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertAdministrator.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertAdministrator.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertAdministrator.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
      builder
      .addCase(updateAdministrator.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateAdministrator.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(updateAdministrator.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertCondos.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertCondos.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertCondos.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
      builder
      .addCase(updateCondos.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateCondos.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(updateCondos.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
      builder
      .addCase(administratorsCondos.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(administratorsCondos.fulfilled, (state, action) => {
        state.status = STATUSES.SUCEESS;
      })
      .addCase(administratorsCondos.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertOwner.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertOwner.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertOwner.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertBroker.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertBroker.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertBroker.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
      builder
      .addCase(updateBroker.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateBroker.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(updateBroker.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertWarranty.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertWarranty.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertWarranty.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertQuote.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertQuote.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertQuote.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertRenewInsurance.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertRenewInsurance.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertRenewInsurance.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertExtraCharge.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertExtraCharge.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertExtraCharge.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertChecklist.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertChecklist.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertChecklist.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(addChecklistItem.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(addChecklistItem.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(addChecklistItem.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertElectronicEnvelope.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertElectronicEnvelope.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertElectronicEnvelope.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertRentalFee.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertRentalFee.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertRentalFee.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertExpense.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertExpense.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertExpense.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertService.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertService.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertService.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertLiquidate.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertLiquidate.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertLiquidate.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertContractStatus.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertContractStatus.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertContractStatus.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
    builder
      .addCase(insertContractLabel.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertContractLabel.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertContractLabel.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
    });
    builder
      .addCase(insertSimulate.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertSimulate.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertSimulate.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
    });
    builder
    .addCase(CreateCustomers.pending, (state) => {
      state.status = STATUSES.LOADING;
    })
    .addCase(CreateCustomers.fulfilled, (state, action) => {
      state.status = STATUSES.SUCEESS;
    })
    .addCase(CreateCustomers.rejected, (state, action) => {
      state.status = STATUSES.ERROR;
    });
    builder
    .addCase(getDocuments.pending, (state) => {
      state.status = STATUSES.LOADING;
    })
    .addCase(getDocuments.fulfilled, (state, action) => {
      state.documents = action.payload;
      state.status = STATUSES.SUCEESS;
    })
    .addCase(getDocuments.rejected, (state, action) => {
      state.status = STATUSES.ERROR;
    });
    builder
    .addCase(getPropertyDocuments.pending, (state) => {
      state.status = STATUSES.LOADING;
    })
    .addCase(getPropertyDocuments.fulfilled, (state, action) => {
      state.documents = action.payload;
      state.status = STATUSES.SUCEESS;
    })
    .addCase(getPropertyDocuments.rejected, (state, action) => {
      state.status = STATUSES.ERROR;
    });

    builder
    .addCase(getPersonDocuments.pending, (state) => {
      state.status = STATUSES.LOADING;
    })
    .addCase(getPersonDocuments.fulfilled, (state, action) => {
      state.documents = action.payload;
      state.status = STATUSES.SUCEESS;
    })
    .addCase(getPersonDocuments.rejected, (state, action) => {
      state.status = STATUSES.ERROR;
    });

    
    builder
    .addCase(updateWarranty.pending, (state) => {
      state.status = STATUSES.LOADING;
    })
    .addCase(updateWarranty.fulfilled, (state, action) => {
      state.status = STATUSES.SUCEESS;
    })
    .addCase(updateWarranty.rejected, (state, action) => {
      state.status = STATUSES.ERROR;
    });
  }
}) 

export const { setTenantId,setTenantIndex,settleOpen,settleClose,setSettleId,contractGenerateMeatClose,contractGenerateMeatOpen,setExpenseCheckbox,setExpenseType,setServiceType,setContractLabel,contractLabelOpen,contractLabelClose,setContractStNumber,setCheckedId,setContractStTitle,contractStatusOpen,contractStatusClose,setLiquidateType,setLiquidateTitle,newLiquidateOpen,newLiquidateClose,newServiceClose,newServiceOpen,setExpenseTitle,newExpenseOpen,newExpenseClose,newRentalFeeOpen,newRentalFeeClose,setWitnessType,newElectronicEnvelopeOpen,newElectronicEnvelopeClose, newPropertyElectronicEnvelopeOpen, newPropertyElectronicEnvelopeClose , newChecklistOpen,newChecklistItemOpen,newChecklistClose,newChecklistItemClose,setExtraCharge,setExtraChargeLabel,newExtraChargeClose,newExtraChargeOpen,reInsuranceTp,renewInsuranceOpen,renewInsuranceClose,insuranceObj,quoteOpen,quoteClose,warrantyOpen,warrantyClose,brokerOpen,brokerClose,ownerOpen,ownerClose,condosOpen,condosClose,administratorOpen,administratorClose,propertyOpen,propertyClose,managerOpen,managerClose,tenantsClose,tenantsOpen,insuranceOpen,insuranceClose,partnershipLargeOpen,partnershipLargeClose,partnershipSmallOpen,partnershipSmallClose,contractOpen,contractClose, showEmail, closeEmail,admPropery,openEmailPop, openConfigureEmail, openDocumentEmailPopup, openNewOwner, ownerhide, showChargeDate,showChargeData, showSuppliers, hideSuppliers, contractDateSearch, TransferCom, newContact} = contractDataSlice.actions;
export default contractDataSlice.reducer

/*----------Insert the contract label data api-----------*/

export const insertContractLabel = createAsyncThunk(
  'contract/insertContractLabelData',
  async (postData:contractLabelForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.insertContractLabel(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the contract status data api-----------*/

export const insertContractStatus = createAsyncThunk(
  'contract/insertContractStatusData',
  async (postData:contractForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.insertContractStatus(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the liquidate data api-----------*/

export const insertSimulate = createAsyncThunk(
  'contract/insertSimulateData',
  async (postData:generateMeatForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.insertSimulate(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the liquidate data api-----------*/

export const insertLiquidate = createAsyncThunk(
  'contract/insertLiquidateData',
  async (postData:newLiquidateForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.insertLiquidate(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the new service data api-----------*/

export const insertService = createAsyncThunk(
  'contract/insertServiceData',
  async (postData:newServiceForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.insertService(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the expense data api-----------*/

export const insertExpense = createAsyncThunk(
  'contract/insertExpenseData',
  async (postData:newExpenseForm) => {
    postData.userId  = gettoken.userId;
    let resp = await customerService.insertExpense(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the rental fee data api-----------*/

export const insertRentalFee = createAsyncThunk(
  'contract/insertRentalFeeData',
  async (postData:newExpenseForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.insertRentalFee(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the electronic envelope data api-----------*/

export const insertElectronicEnvelope = createAsyncThunk(
  'contract/insertElectronicEnvelopeData',
  async (postData:newElectronicEnvelopeForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.insertElectronicEnvelope(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the checklist data api-----------*/

export const insertChecklist = createAsyncThunk(
  'contract/insertChecklistData',
  async (postData:newChecklistForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.insertChecklist(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
export const addNewChecklist = createAsyncThunk(
  'contract/insertChecklistData',
  async (postData:newChecklistForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.addNewChecklist(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

export const addChecklistItem = createAsyncThunk(
  'contract/addChecklistItem',
  async (postData:newChecklistForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.addChecklistItem(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

export const updateChecklistItem = createAsyncThunk(
  'contract/addChecklistItem',
  async (postData:newChecklistForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.updateChecklistItem(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the extra charge data api-----------*/

export const insertExtraCharge = createAsyncThunk(
  'contract/insertExtraChargeData',
  async (postData:newExtraChargeForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.insertExtraCharge(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

export const deletService = createAsyncThunk(
  'contract/deletService',
  async (postData:any) => {
    let resp = await customerService.deleteServi(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the property data api-----------*/

export const insertProperty = createAsyncThunk(
  'contract/insertPropertyData',
  async (postData:contractPropertyForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.createProperty(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the warranty data api-----------*/

export const insertWarranty = createAsyncThunk(
  'contract/insertWarrantyData',
  async (postData:warrantyForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.insertWarranty(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the renew insurance data api-----------*/

export const insertRenewInsurance = createAsyncThunk(
  'contract/insertRenewInsuranceData',
  async (postData:contractForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.insertRenewInsurance(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the quote data api-----------*/

export const insertQuote = createAsyncThunk(
  'contract/insertQuoteData',
  async (postData:quoteForm) => {
    postData.userId  = gettoken.userId;
    let resp = await customerService.insertQuote(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the administrator data api-----------*/

export const insertAdministrator = createAsyncThunk(
  'contract/insertAdministratorData',
  async (postData:administratorForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.createAdministrators(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
/*----------Insert the administrator data api-----------*/

export const updateAdministrator = createAsyncThunk(
  'contract/updateAdministratorData',
  async (postData:administratorForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.updateAdministrators(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the broker data api-----------*/

export const insertBroker = createAsyncThunk(
  'contract/insertBrokerData',
  async (postData:ownerForm) => {
    postData.userId = gettoken.userId;
    postData.type   = "New Brokers";
    let resp = await customerService.createOwner(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
/*----------update the broker data api-----------*/

export const updateBroker = createAsyncThunk(
  'contract/updateBrokerData',
  async (postData:ownerForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.createOwner(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
/*----------Insert the condos data api-----------*/

export const insertOwner = createAsyncThunk(
  'contract/insertOwnerData',
  async (postData:ownerForm) => {
    postData.userId = gettoken.userId;
    postData.type   = "New Owner";
    let resp = await customerService.createOwner(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Delete Owner api-----------*/
export const deleteOwner = createAsyncThunk(
  'contract/deleteOwner',
  async (postData:any) => {
    postData.userId = gettoken.userId
    let resp = await customerService.deleteOwner(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data
  }
);

/*----------Delete Tenant api-----------*/
export const deleteTenant = createAsyncThunk(
  'contract/deleteTenant',
  async (postData:any) => {
    postData.userId = gettoken.userId
    let resp = await customerService.deleteTenant(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data
  }
);

/*----------Insert the condos data api-----------*/
export const insertCondos = createAsyncThunk(
  'contract/insertCondosData',
  async (postData:condosForm) => {
    postData.userId  = gettoken.userId;
    let resp = await customerService.createCondos(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
/*----------update the condos data api-----------*/

export const updateCondos = createAsyncThunk(
  'contract/updateCondosData',
  async (postData:condosForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.createCondos(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

export const administratorsCondos = createAsyncThunk(
  'contract/administratorsCondos',
  async (postData:any) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.getadministratorsCondos(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
/*----------Insert the insurance data api-----------*/

export const insertManager = createAsyncThunk(
  'contract/insertManagerData',
  async (postData:tenantsForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.insertManager(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

/*----------Insert the insurance data api-----------*/

export const insertTenants = createAsyncThunk(
  'contract/insertTenantsData',
  async (postData:tenantsForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.insertTenants(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
export const updateTenants = createAsyncThunk(
  'contract/updateTenantsData',
  async (postData:tenantsForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.UpdateSingleTenant(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
/*----------ADD Owner Bank api-----------*/
export const addOwnerbank = createAsyncThunk(
  'contract/addOwnerbank',
  async (postData:any) => {
    postData.userId = gettoken.userId
    let resp = await customerService.addOwnerbank(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data
  }
);
export const updateOwnerMainAccount = createAsyncThunk(
  'contract/updateOwnerMainAccount',
  async (postData:any) => {
    postData.userId = gettoken.userId
    let resp = await customerService.updateOwnerMainAccount(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data
  }
);
export const disableMainAccount = createAsyncThunk(
  'contract/disableMainAccount',
  async (postData:any) => {
    postData.userId = gettoken.userId
    let resp = await customerService.disableMainAccount(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data
  }
);

/*----------Insert the insurance data api-----------*/
export const insertInsurance = createAsyncThunk(
  'contract/insertInsuranceData',
  async (postData:insuranceForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.insertInsurance(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
/*----------Get the Witness listing api-----------*/
export const getAllWitness = createAsyncThunk(
  'property/fetchAllWitnessData',
  async () => {
    let witnessData = { witness: null, start: 0, end: 1000 }
    let resp = await customerService.getAllWitness(witnessData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the expense listing api-----------*/
export const getAllExpense = createAsyncThunk(
  'contracts/fetchAllExpenseData',
  async (postData:contractSearchForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.getAllExpense(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

export const getsingleExpense = createAsyncThunk(
  'contracts/getsingleExpense',
  async (postData:contractSearchForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.getsingleExpense(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the property listing in contract api-----------*/
export const getProperty = createAsyncThunk(
  'contracts/fetchPropertyData',
  async (postData:contractSearchForm) => {
    postData.userId  = gettoken.userId;
    let resp = await customerService.getProperty(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the all property listing in property api-----------*/
export const getAllProperty = createAsyncThunk(
  'contracts/fetchAllPropertyData',
  async (postData:contractSearchForm) => {
    let resp = await customerService.getAllProperty(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

export const getAllProperty_administractor = createAsyncThunk(
  'contracts/getAllProperty_administractor',
  async (postData:contractSearchForm) => {
    let resp = await customerService.getAllProperty_administractor(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);
/*----------Get the serive listing api-----------*/

export const getAllService = createAsyncThunk(
  'contracts/fetchAllServiceData',
  async () => {
    let serviceData = { start:0, end:1000 }
    let resp = await customerService.getAllService(serviceData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the suppliers listing api-----------*/
export const insertSuppliers = createAsyncThunk(
  'contract/insertSuppliers',
  async (postData:ownerForm) => {
    postData.userId = gettoken.userId;
    postData.type   = "New Brokers";
    let resp = await customerService.createSuppliers(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
/*----------Get the suppliers listing api-----------*/

export const getAllSuppliers = createAsyncThunk(
  'contracts/fetchAllSuppliersData',
  async () => {
    let supplierData = { start:0, end:1000 }
    let resp = await customerService.getAllSuppliers(supplierData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the single contract count api-----------*/

export const getContractDetailsCount = createAsyncThunk(
  'contracts/fetchContractDetailsCount',
  async (id:number) => {
    let resp = await customerService.getContractDetailsCount(id);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the contract count listing api-----------*/

export const getContractCount = createAsyncThunk(
  'contracts/fetchContractCount',
  async () => {
    let resp = await customerService.getContractCount();
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the guarantor listing api-----------*/

export const getGuarantor = createAsyncThunk(
  'contracts/fetchGuarantorData',
  async () => {
    let guarantorData = { guarantor:null,start:0, end:1000 }
    let resp = await customerService.getAllGuarantor(guarantorData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the tenants listing api-----------*/

export const getTenants = createAsyncThunk(
  'contracts/fetchTenantsData',
  async () => {
    let tentData = { tenant:null,start:0, end:1000,type: "" }
    let resp = await customerService.getAllTenant(tentData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

export const filterTenant = createAsyncThunk(
  'contracts/filterTenant',
  async () => {
    let TentData = { owner:null,start:0, end:1000 }
    let resp = await customerService.filterTenant(TentData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);
/*----------Get the contract tenants listing api-----------*/

export const getContractTenants = createAsyncThunk(
  'contracts/fetchContractTenantsData',
  async () => {
    let tentData = { start:0, end:1000 }
    let resp = await customerService.getContractTenants(tentData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the cities listing api-----------*/

export const getCities = createAsyncThunk(
  'contracts/fetchCitiesData',
  async () => {
    let citiesData = { city:null,start:0, end:1000 }
    let resp = await customerService.getAllCities(citiesData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the neighborhood listing api-----------*/

export const getNeighborhood = createAsyncThunk(
  'contracts/fetchNeighborhoodData',
  async () => {
    let neighData = { neighbor:null,start:0, end:1000 }
    let resp = await customerService.getAllNeighborhood(neighData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the owner listing api-----------*/

export const getOwner = createAsyncThunk(
  'contracts/fetchOwnerData',
  async () => {
    let ownData = { owner:null,start:0, end:1000 }
    let resp = await customerService.getAllOwner(ownData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);
export const getOwnerDetails = createAsyncThunk(
  'contracts/fetchOwnerData',
  async (owner:any) => {
    let ownData = { owner:null,start:0, end:1000 }
    let resp = await customerService.getAllOwner(ownData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);
export const filterOwners = createAsyncThunk(
  'contracts/filterOwner',
  async () => {
    let ownData = { owner:null,start:0, end:1000 }
    let resp = await customerService.filterOwner(ownData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);
/*----------Get the single contract details api-----------*/

export const contractDatails = createAsyncThunk(
  'property/fetchContractDatails',
  async (id:number) => {
    let resp = await customerService.getSingleContract(id);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);
export const cancelInsuranceRequest = createAsyncThunk(
  'contract/cancelInsuranceRequest',
  async (id:number) => {
    let resp = await customerService.cancelInsuranceRequest(id);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the contract transfer api-----------*/

export const getContractTransfer = createAsyncThunk(
  'contract/fetchContractTransferData',
  async (postData:contractSearchForm|any) => {
    let resp = await customerService.getContractTransfer(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the readjustment contracts api-----------*/

export const getReadContractData = createAsyncThunk(
  'contract/fetchReadContractData',
  async (postData:contractSearchForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.getAllReadjustContracts(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the contracts api-----------*/

export const contractsData = createAsyncThunk(
  'contract/fetchContractsData',
  async (postData:contractSearchForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.getAllContracts(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

export const getContractSearchData = createAsyncThunk(
  'contract/fetchGetContractSearchData',
  async (postData:contractSearchForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.getAllContractSearch(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the contracts api-----------*/

export const contractsExpenseData = createAsyncThunk(
  'property/fetchContractsExpenseData',
  async (postData:contractSearchForm) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.getAllContractsExpenseData(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the insurance api-----------*/

export const getInsurance = createAsyncThunk(
  'property/fetchInsuranceData',
  async (postData:insuranceForm) => {
    let insurData = { insurance:null,start:0, end:1000 }
    let resp = await customerService.getAllInsurance(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the Settle api-----------*/

export const getSettle = createAsyncThunk(
  'contract/fetchSettleDatails',
  async (postData:settleForm|any) => {
    let resp = await customerService.getSettleData(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Insert the contract data api-----------*/

export const insertContract = createAsyncThunk(
  'contract/insertContract',
  async (postData:contractForm) => {
    let resp = await customerService.insertContract(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
export const addContractCronRecords = createAsyncThunk(
  'contract/addContractCronRecords',
  async (postData:contractForm) => {
    let resp = await customerService.addContractCronRecords(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
export const updateContractInsurancePolicy = createAsyncThunk(
  'contract/updateContractInsurancePolicy',
  async (contractId:any,policyNumber:any) => {
    let resp = await customerService.updateContractInsurancePolicy(contractId,policyNumber);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
export const addBillingTicketCron = createAsyncThunk(
  'contract/addBillingTicketCron',
  async (postData:contractForm) => {
    let resp = await customerService.addBillingTicketCron(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
export const deleteBillingTicketCron = createAsyncThunk(
  'contract/deleteBillingTicketCron',
  async (postData:contractForm) => {
    let resp = await customerService.deleteBillingTicketCron(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
export const insertNewInsurance = createAsyncThunk(
  'contract/insertNewInsurance',
  async (postData:insuranceRegistrationForm) => {
    let resp = await customerService.insertInsurance(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
export const CreateCustomers = createAsyncThunk(
  'create_customers',
  async (postData: any) => {
    let response = await fetch(`${config.KOBANA_API}/createKobanaCustomer`,{
      method: "POST",
      body: JSON.stringify(postData),
      headers:{
        Authorization:  "Bearer yhA5NPIoRIF2hOzpI-32WSVaMCOpnmlfxAxTwq9XLpM",
        "Content-Type": "application/json"
      }
    })
    const data = await response.json()
    return data
  }
)

export const GetCustomers = createAsyncThunk(
  'get_customers',
  async (customer_id: number) => {
    let response = await fetch(`${config.KOBANA_API}/getKobanaCustomer/${customer_id}`,{
      method: "GET",
      headers:{
        "Authorization":  "Bearer yhA5NPIoRIF2hOzpI-32WSVaMCOpnmlfxAxTwq9XLpM"
      }
    })
    const data = response.json()
     return data
  }
)
export const moreThenFilter = createAsyncThunk(
  'More_then_filter',
  async (customer_id: number) => {
    let response = await fetch(`${config.KOBANA_API}/getKobanaCustomer/${customer_id}`,{
      method: "GET",
      headers:{
        "Authorization":  "Bearer yhA5NPIoRIF2hOzpI-32WSVaMCOpnmlfxAxTwq9XLpM"
      }
    })
    const data = response.json()
     return data
  }
)
export const getDocuments = createAsyncThunk(
  'documents/getDocuments',
  async (id: number) => {
    let resp = await customerService.getContractDetailDocuments(id);
    let data = JSON.parse(JSON.stringify(resp)).data.data;
    return data;
  }
);
export const getPropertyDocuments = createAsyncThunk(
  'documents/getPropertyDocuments',
  async (id: number) => {
    let resp = await customerService.getPropertyDetailDocuments(id);
    let data = JSON.parse(JSON.stringify(resp)).data.data;
    return data;
  }
);

export const getPersonDocuments = createAsyncThunk(
  'documents/getPersonDocuments',
  async (id: number) => {
    let resp = await customerService.getPersonDocuments(id);
    let data = JSON.parse(JSON.stringify(resp)).data.data;
    return data;
  }
);

export const updateWarranty = createAsyncThunk(
  'contract/updateWarrantyData',
  async (postData:warrantyForm) => {
    let resp = await customerService.updateWarranty(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
export const getAllWarranty = createAsyncThunk(
  'contract/allWanty',
  async (postData:warrantyForm) => {
    let resp = await customerService.getAllWarranty(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
export const post_termination_checklist = createAsyncThunk(
  'contract/post_termination_checklist',
  async (postData:any) => {
    let resp = await customerService.post_termination_checklist(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

export const custom_post_termination_checklist = createAsyncThunk(
  'contract/custom_post_termination_checklist',
  async (postData:any) => {
    let resp = await customerService.custom_post_termination_checklist(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

export const get_custom_post_termination_checklist = createAsyncThunk(
  'contract/custom_post_termination_checklist',
  async (postData:any) => {
    let resp = await customerService.get_custom_post_termination_checklist(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
); 

export const RepresentativeInsertService = createAsyncThunk(
  'contract/Representative_ServiceInsert',
  async (postData:any) => {
    postData.userId = gettoken.userId;
    let resp = await customerService.RepresentativeServiceInsert(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

export const GetRepresentativeService = createAsyncThunk(
  'contract/GetRepresentative_Service',
  async () => {
    let resp = await customerService.GetAllRepresentative();
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

export const PdfGenerator = createAsyncThunk(
  'contract/PdfGenerator',
  async (dataPdf: any) => {
    let resp = await customerService.PdfGenerator(dataPdf);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);
