import { Form, Modal, Button, Row, Col, Card, Table, Accordion, FloatingLabel, Dropdown, Collapse } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect } from 'react';
import { checklistOpen, getAllChecklists } from "../../../../../redux/slice/expenditureDataSlice";
import { newChecklistItemOpen,newChecklistOpen } from "../../../../../redux/slice/contractDataSlice";
import { Outlet, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ResolveWithTtlOptions } from 'dns';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import ChecklistsService from '../../Common/ChecklistsService';
import ChecklistService from '../../Common/ChecklistService';
import Loader from '../../../../Profile/Company/Loader';
import '../../../../../App.css';
import ChecklistSettings from './ChecklistSettings';
import Swal from "sweetalert2";
import customerService from "../../../../../services/customerService";

const Checklists = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const checklistShow = () => {
        // dispatch(checklistOpen(true))
        dispatch(newChecklistOpen(true));
    }
    const { checklistData, status } = useSelector((state: RootState) => state.expenditureData)
	const { newChecklistItemShow } = useSelector((state: RootState) => state.contractData)
    const  [checkListId, setCheckListId] = useState()
    useEffect(() => {
        let checkData = { "name": "", "status": "All", "start": 0, "end": 10 }
        dispatch(getAllChecklists(checkData))
    }, [dispatch])
    const getChecklists = (e) => {
        let status = e.target.value;
        let checkData = { "name": "", "status": status, "start": 0, "end": 10 }
        dispatch(getAllChecklists(checkData))
    }
    ///Checklist setting modal initialization
    const [showChecklistSettingModal, setChecklistSettingModal] = useState(false);
    const [newAgencyChecklistItem, setnewAgencyChecklistItem] = useState(false);
    const checklistSettingModalClose = () => setChecklistSettingModal(false);
    const checklistSettingModalShow = () => setChecklistSettingModal(true);
    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);

    function getResponsiblePerson(value) {
        let resPerson = '';
        if (value == 1) { resPerson = 'Tenant' } else if (value == 2) { resPerson = 'Owner' } else if (value == 3) { resPerson = 'Guarantor' }
        else { resPerson = 'Real estate' }
        return resPerson;
    }

    const [myChecklists, setMyChklist] = useState<any>([]);
    const newChecklistsItemOpen = (checkList) => {
        setMyChklist(checkList);
        setnewAgencyChecklistItem(true);
        dispatch(newChecklistItemOpen(true)); 
    }
    const deleteCheckListItem = async (chkItem) => {
        Swal.fire({
            title: `${t('Sweetalert.Warning')}`,
            text: `${t('Sweetalert.updatefooter')}`,
            // icon: 'success',"question",
            showCancelButton: true,
            confirmButtonText: `${t('Sweetalert.changeIt')}`,
            cancelButtonText: `${t("button.cancel")}`
        }).then(async (result) => {
            if (result.isConfirmed == true) {
                let useData = await customerService.deleteChkItem(chkItem);
                if (useData && useData.data && useData.data.data.length) {
                    Swal.fire({
                        position: 'center', 
                        // icon: 'success','success', 
                        title: `${t('Sweetalert.Success')}`,
                        text: `${t('infosuccesschange')}`, confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                        timer: 3000
                    }) 
                    let checkData = { "name": "", "status": "All", "start": 0, "end": 10 }
                    dispatch(getAllChecklists(checkData))
                } else {
                    Swal.fire({
                        title: `${t('Sweetalert.Error')}`, 
                        text: `${t('Sweetalert.deleteFailed')}`,
                        //icon : "error", 
                        confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                        timer: 3000
                    })
                }
            }
        }) 
    }

    const updateCheckListItem = async (chkItem) => {
        setMyChklist(chkItem);
        setnewAgencyChecklistItem(true);
        dispatch(newChecklistItemOpen(true)); 
    }
    const checklistEdit = async (e, id) =>{
        dispatch(newChecklistOpen(true));
        setCheckListId(id)
        }
    return (
        <section className="content-wrapper content-wrapper-primary">
            {/*  <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 5, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/pending_checklists"}><img src="./assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3">
                                    <h1 className="mb-0 h4 lh-base text-truncate">{t("checklist")}</h1>
                                </div>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a onClick={checklistShow} className="fw-bold cursor-pe">
                                        <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />{t("checklistother.newchecklist")}
                                    </a>
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a className="fw-bold cursor-pe">
                                        <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />{t("checklistother.howtouse")}
                                    </a>
                                </li> 
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/settings-light.svg" alt="settings" onClick={checklistSettingModalShow} className="h-20 cursor-pe" />
                                    <ChecklistSettings showChecklistSetting={showChecklistSettingModal} checklistSettingClose={checklistSettingModalClose} />
                                </li>
                                <li className="list-inline-item">
                                    <img src="assets/images/icons/question_icon.svg" alt="Help" className="h-20" />
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
                {/* Filters start here */}
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">Filters</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm">
                                        {t("dropdownItem.status")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu onChange={getChecklists} className="px-4">
                                        <FloatingLabel label={t('dropdownItem.status')}>
                                            <Form.Select aria-label="Status">
                                                <option value="All">{t('formLabel.all')}..</option>
                                                <option value="1">{t('customerAndEmployees.active')}</option>
                                                <option value="0">{t('dropdownItem.Inprogress')}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Form>
                </Collapse>
                {/* /Filters end */}
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/* <!-- Table start from here --> */}
                {
                    (status == "loading") ?
                        <Loader />
                        :
                        <Card>
                        <Card.Body className="pb-1">

                        
                        <Table responsive className="table-custom table-custom-bordered mb-0">
                            <thead>
                                <tr>
                                    <th>{t("formLabel.name")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {checklistData.data && checklistData.data.length > 0 ? checklistData.data.map((checklist, index) => (

                                    <>
                                        <tr key={checklist.id}>
                                            <td className="p-0">
                                            <Accordion defaultActiveKey="0" className="p-0">
                                                <Accordion.Item eventKey={checklist.id} key={index} data-idnew={checklist.id} className='mb-0 position-relative'>
                                                <div className="position-absolute end-0 top-0 mt-2 pt-1 pe-2 me-5 z-index-5">
                                                <Button className="p-0 fw-bold me-2 me-xl-3 me-xxl-4" onClick={() => newChecklistsItemOpen(checklist)} variant="link" size="sm">{t('AddchecklistItem')}</Button>
                                                <Button variant="link" className="p-0 fw-bold" onClick={(e) =>checklistEdit(e, checklist.id)}>
                                                                Edit
                                                            </Button>
                                                 </div>
                                                    <Accordion.Header className="mx-n4">{checklist.name}</Accordion.Header>
                                                    <Accordion.Body className="mx-n4 pb-1">
                                                      
                                                        <Table responsive className="table-custom table-custom-bordered mb-0">
                                                            <thead>
                                                                <tr>
                                                                    {/* <th></th> */}
                                                                    <th>{t('terminatelist.item')}</th>
                                                                    <th className="text-end">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {checklist.chkItems && checklist.chkItems.length > 0 ? checklist.chkItems.map((item, index) => (
                                                                    <tr key={index} data-idnew={item.ID_CHECKLISTITEM_CHI}>
                                                                        {/* <td></td> */}
                                                                        {/* <td className="text-dark-70">
                                                                        <Form.Check type="checkbox" checked={item.FL_STATUS_CCH === 1} className="newUserCh1" id={checklist.id + "newUserCh1" + item.ID_CHECKLISTITEM_CHI} onClick={(e) => markChecklistChecked(e, item.ID_CHECKLISTITEM_CHI, checklist.id)} />
                                                                    </td> */}
                                                                        <td>
                                                                           <span className="d-block"> {item.ST_NOMEITEM_CHI}</span>
                                                                            <small>The {getResponsiblePerson(item.FL_RESPONSAVEL_CHI)} is responsible for this item.</small>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <Button className="me-2 me-xl-3 me-xxl-4 p-0 fw-bold" onClick={() => updateCheckListItem(item)} variant="link" size="sm">
                                                                            <span className="icon"> <img src={process.env.PUBLIC_URL + "/assets/images/icons/edit-primary.svg"} className="h-20" alt="Edit" /></span>
                                                                            </Button>
                                                                            <Button className="p-0 fw-bold" onClick={() => deleteCheckListItem(item)} variant="link" size="sm"><span className="icon"><img src="./assets/images/icons/delete-danger.svg" alt="Delete" title="Delete" className="h-20 cursor-pe" /></span></Button>
                                                                        </td>
                                                                    </tr>
                                                                )) :
                                                                    <tr>
                                                                        <td colSpan={3}>
                                                                            <div className="p-1 text-secondary text-center">
                                                                                No checklist added to this contract.
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                            </td>
                                        </tr>
                                    </>
                                )) :
                                    <tr>
                                        <td>
                                            <span>{t('formLabel.noRecordsFounds')}</span>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        </Card.Body>
                        </Card>
                }
                {/*   <!-- /Table end --> */}
                <p className="d-block mt-lg-4 mt-3">{t('formLabel.listing')} {checklistData.totalRow && checklistData.totalRow > 0 ? checklistData.totalRow : 0} {t('property.record')}.</p>
            </main>
            {/* <!-- /Main content start end --> */}
            {/* <ChecklistsService/> */}
            <ChecklistsService myChecklists={myChecklists} contractId={''} newAgencyChecklistItem={newAgencyChecklistItem} />

            <ChecklistService myChecklists={'1'} contractId={''} newAgencyChecklist={true} checkListId={checkListId} setCheckListId={setCheckListId}/>
        </section>
    )
}
export default Checklists;