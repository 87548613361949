import { Form, Modal, Button, Alert, Row, Col, FloatingLabel } from 'react-bootstrap';
import { contractGenerateMeatClose, insertSimulate } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { generateMeatForm } from '../../../Interface';
import Swal from 'sweetalert2';
import moment from "moment-timezone";
import Loader from "../../../Profile/Company/Loader";

import '../../../../App.css';
import NewChargeGenerate from '../Contracts/ContractDetail/NewChargeGenerate';
import { getGenerateMeat, setExpType } from "../../../../redux/slice/expenditureDataSlice";
const GenerateMeatService = (generateMeatForm: any) => {
  let { getAllContractCharge } = generateMeatForm;
  let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>()
  const [t] = useTranslation();
  const { generateMeatShow, contractDatail } = useSelector((state: RootState) => state.contractData)
  const [start, setStart] = useState("");
  const [num, setNum] = useState(0);
  const [double, setDouble] = useState(false);
  const [loader_on, setloader_on] = useState(false);
  let newDate = new Date(dateBrazil);
  let startDt = moment(newDate).format("YYYY-MM-DD");
  const generateMeatClose = () => {
    dispatch(contractGenerateMeatClose(false));
    setDouble(false);
    setloader_on(false);
  }
  const [minDate, setMinDate] = useState(startDt)
  useEffect(() => {
    if (contractDatail.length > 0) {
      contractDatail.forEach((simulate: any) => {
        if (simulate.id == Number(id)) {
          setValue('startDate', moment(simulate.start2).format("YYYY-MM"));
          setMinDate(moment(simulate.start2).format("YYYY-MM-DD"))
          setStart(simulate.start2);
          setValue('contractId', Number(id));
          setValue('contractStart', simulate.start2);
          setValue('dueDate', Number(simulate.due_date));
        }
      })
    }
  }, [contractDatail, startDt, id])
  const generateValidation = Yup.object().shape({
    startDate: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    numberOfMonth: Yup.number()
      .required(t('requiredMmessage.fieldRequired'))
      .positive()
      .typeError(t('requiredMmessage.Thevaluemustbeanumber'))
      .integer()
      .min(1, t("requiredMmessage.Pleaseenteravaluemorethan1"))
      .max(12, t("requiredMmessage.Pleaseenteravaluelessthan12")),
  });
  let frm = {
    resolver: yupResolver(generateValidation)
  }
  const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<generateMeatForm>(frm);
  const setStartDate = (evt) => {
    let startDt2 = moment(evt.target.value).format("YYYY-MM-DD");
    setValue('startDate', moment(startDt2).format("YYYY-MM"));
    setStart(startDt2);
    if (num > 0 && num <= 12) {
      setValue('endDate', moment(startDt2).add(num ? num - 1 : 0, 'M').endOf("month").format('YYYY-MM-DD'))
    }
  }
  const setEndDate = (evt) => {
    if (evt.target.value && evt.target.value > 0 && evt.target.value <= 12) {
      //setValue('endDate', moment(new Date(start)).add(evt.target.value - 1, 'M').endOf("month").format('YYYY-MM-DD'));
      //setValue('endDate', moment(new Date(start)).add(evt.target.value, 'M').endOf("month").format('YYYY-MM-DD'))
      setValue('endDate', moment(new Date(start)).add(evt.target.value ? evt.target.value - 1 : 0, 'M').endOf("month").format('YYYY-MM-DD'));
      setNum(evt.target.value);
      setDouble(false);
    }
    else {
      setDouble(true);
      setValue('endDate', '')
      setNum(0)
    }
  }
  const calSimulate = async (data: generateMeatForm, e: any) => {
    e.preventDefault();
    setDouble(true);
    setloader_on(true);
    let response = await dispatch(insertSimulate(data));
    if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
      setValue("numberOfMonth", 0);
      setValue("endDate", "");
      getGenerateMeatListing(id);
      //navigate('/generate_meat_listing/' + id);
    }
    else {
      generateMeatClose();
      setDouble(false);
      setloader_on(false);
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t('Sweetalert.successfailed'),
        // icon: 'success',"error",
        confirmButtonText: "OK",
        timer: 2000
      })
    }
  }
  function getCurrentMonth() {
    const today = new Date(dateBrazil);
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are zero-based
    // Format the current date to "YYYY-MM" (e.g., "2023-10")
    return `${year}-${month.toString().padStart(2, '0')}`;
  }

  const getGenerateMeatListing = async (id) => {
    let result = await dispatch(getGenerateMeat(Number(id)));
    generateMeatClose();
    if (result.payload.resCode === 201) {
      setgenerateMeatData(result.payload);
      newChargeGenerateModalShow()
    }
    else if (result.payload.resCode === 425) {
      Swal.fire({
        // icon:'info',
        title: t("Sweetalert.Info"),
        text: result.payload.message,
        confirmButtonText: "OK",
      });
    }
    else if (result.payload.resCode === 403) {
      Swal.fire({
        // icon:'info',
        title: t("Sweetalert.Info"),
        text: result.payload.message,
        confirmButtonText: "OK",
      });
    }
    else {
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.somethingWent"),
        // icon:"error",
        confirmButtonText: "OK",
        timer: 2000
      });
    }
  }
  const [showNewChargeGenerateModal, setNewChargeGenerateModal] = useState(false);
  const [generateMeatData, setgenerateMeatData] = useState<any>([]);
  const newChargeGenerateModalClose = () => setNewChargeGenerateModal(false);
  const newChargeGenerateModalShow = () => setNewChargeGenerateModal(true);
  return (
    <>
      <NewChargeGenerate showNewChargeGenerate={showNewChargeGenerateModal} newChargeGenerateModalShow={newChargeGenerateModalShow}
        newChargeGenerateClose={newChargeGenerateModalClose} generateMeatData={generateMeatData} getAllContractCharge={getAllContractCharge} />
      {/* <!-- New branch modal start from here --> */}
      <Modal show={generateMeatShow} onHide={generateMeatClose} id="contractDetailSimulate">
        <Modal.Header closeButton>
          <Modal.Title>{t('generateCharges')}</Modal.Title>
        </Modal.Header>
        { 
          loader_on ? 
             <Loader />
          :
            <Form >
              <input type="hidden" {...register('contractId')} />
              <input type="hidden" {...register('contractStart')} />
              <input type="hidden" {...register('dueDate')} />

              <Modal.Body>
                <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                  <Col md={6}>
                    <FloatingLabel controlId="start" label={t('start')+ " *"} >
                      {/*---min={getCurrentMonth()} ---*/}
                      <Form.Control type="month" min={minDate} className={`form-control ${errors.startDate ? 'is-invalid' : ''}`} {...register('startDate')} onChange={setStartDate} placeholder={t('start')} />
                      <Form.Control.Feedback type="invalid">{errors.startDate?.message}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel controlId="months" label={t('numberMonths')+ " *"}>
                      <Form.Control type="text" className={`form-control ${errors.numberOfMonth ? 'is-invalid' : ''}`} {...register('numberOfMonth')} onChange={setEndDate} placeholder={t('numberMonths')} />
                      <Form.Control.Feedback type="invalid">{errors.numberOfMonth?.message}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col md={12}>
                    <FloatingLabel controlId="end" label={t('end')} >
                      <Form.Control type="date" {...register('endDate')} placeholder={t('end')} disabled />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Alert variant="warning" className="mb-3 mb-lg-4">
                  <ul className="mb-0 ps-2">
                    <li>{t('generateInfo')}</li>
                    <li>{t('generateInfo2')}</li>
                  </ul>
                </Alert>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={generateMeatClose}>
                  {t('close')}
                </Button>
                {
                  loader_on ?
                    <Button className="btn-width" disabled={double}>
                      <span className="spinner-border spinner-border-sm"></span>
                      <span className="px-1">{t('simulate')}</span>
                    </Button>
                  :
                    <Button variant="primary" disabled={double} onClick={handleSubmit(calSimulate)}>
                      {t('simulate')}
                    </Button>
                }
              </Modal.Footer>
            </Form>
        }
      </Modal>
      {/* <!-- /New branch modal end --> */}
    </>
  )
}
export default GenerateMeatService;