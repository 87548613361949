import React, { useState, useEffect } from 'react';
import { Offcanvas, Form, Button, FloatingLabel, Row, Col, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import customerService from "../../../../services/customerService";
import { yupResolver } from '@hookform/resolvers/yup';
import { RepresentativeServices } from '../../../Interface';
import { RepresentativeInsertService, openNewOwner, ownerhide } from '../../../../redux/slice/contractDataSlice';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF } from '../../../constants/utils';
import { use } from 'i18next';

const RepresentativeService = (props) => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    const { showNewRepresentative, newRepresentativeClose, newRepresentativeShow } = props;

    const renewInsuranceValidation1 = Yup.object().shape({
        name: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        cpf: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        email: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .email(t('requiredMmessage.fieldNotValid')),
    });
    let frm = {
        resolver: yupResolver(renewInsuranceValidation1)
    }

    useEffect(() => {
        if (!showNewRepresentative) {
            reset()
        }
    }, [showNewRepresentative])


    const [cnpjcpferrormessage, setcnpjcpferrormessage] = useState({});
    const [update, setUpdate] = useState(1);
    const [allStates, setAllStates] = useState([]);

    const getLocation = async (e: any, inputType) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
            `${inputType}/${newStr}`
        );
        // Get the DOM element for the zipcode input
        let querySel: any = document.querySelector(
            '.removeInsertDisable [name="zipcode"]'
        );
        // Check if the value is not empty
        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            // Check if the response status is success
            if (resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                querySel.style.border = "0px solid red";
                // Set the city value if available in the response
                if (res[0]["city"]) {
                    setValue("city", res[0]["city"]);
                }
                if (res[0]["neigbohood"]) {
                    setValue("neighborhood", res[0]["neigbohood"]);
                }
                if (res[0]["state"]) {
                    setValue("state", res[0]["state"])
                }
                if (res[0]["street"] || res[0]["streetType"]) {
                    let data =
                        (res[0]["streetType"] ? res[0]["streetType"] + " " : "") +
                        (res[0]["street"] ?? "");
                    setValue("address", data);
                }
                clearErrors("zipcode");
                clearErrors("city");
                clearErrors("neighborhood");
                clearErrors("address")
                clearErrors("state")
            } else {
                // Add red border to the zipcode input
                setError("zipcode", {
                    type: "manual",
                    message: t("invalidzipcode"),
                });
                querySel.style.border = "2px solid red";
                // Reset city, neighborhood, and address values
                setValue("city", "");
                setValue("neighborhood", "");
                setValue("address", "");
                setValue("state", "")
            }
        } else {
            // Remove red border from the zipcode input
            querySel.style.border = "0px solid red";
        }
    };


    const { register, reset, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<RepresentativeServices>(frm);
    const onSubmit = async (data: RepresentativeServices, e: any) => {
        e.preventDefault();
        let response = await dispatch(RepresentativeInsertService(data));
        if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: t("Sweetalert.successget"),
                confirmButtonText: "OK",
                timer: 2000
            })
            newRepresentativeClose()
            dispatch(openNewOwner(true))
            dispatch(ownerhide(false));
            reset()
        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }

    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name)
            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
                if (duplicateData["data"] > 0) {
                    zipCodeCheck?.["setAttribute"]('disabled', "")
                    setcnpjcpferrormessage({
                        name: name,
                        message: checkCNJP?.["message"]
                    })
                    setError(name, {
                        type: "manual",
                        message: checkCNJP?.["message"]
                    });
                } else {
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            // errorCNPJCPF(e.target.value)
            setcnpjcpferrormessage({
                name: name,
                message: checkCNJP?.["message"]
            })
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    useEffect(() => {
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            if (resp.data.status == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })()
    }, [update]);
    return (
        <>
            <Offcanvas className="border-sm-none" show={showNewRepresentative} onHide={newRepresentativeClose} placement="end">
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(onSubmit)}>
                    <Offcanvas.Header className="border-bottom" closeButton>
                        <Offcanvas.Title className="fw-semibold">
                            <img src="assets/images/icons/arrow-left.svg" className="h-14 me-2" alt="Arrow left" />{t('newContractDev.newRepresentative')}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <p className="mb-lg-4 mb-3">{t('newContractDev.newRepresentativeContent')}</p>
                        <FloatingLabel controlId="floatingInput" label={t('formLabel.name') + " *"} className="mb-3 mb-lg-4" >
                            <Form.Control type="text" className={`form-control ${errors.name?.message ? 'is-invalid' : ''}`} placeholder={t('formLabel.name')}  {...register("name")} />
                            <Form.Control.Feedback type="invalid">{errors?.name?.message}</Form.Control.Feedback >
                        </FloatingLabel>
                        <Row className="g-lg-4 g-3">
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.cpf')+ " *"}>
                                    <Form.Control type="text" placeholder={t('formLabel.cpf')}
                                        className={`form-control ${errors.cpf?.message ? 'is-invalid' : ''}`}  {...register("cpf")} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                                    <Form.Control.Feedback type="invalid">{errors?.cpf?.message}</Form.Control.Feedback >
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label="RG">
                                    <Form.Control type="text" placeholder="RG" {...register("rg")} />
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('newContractDev.gender')}>
                                    <Form.Select aria-label={t('newContractDev.gender')} {...register("sex")}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="1">Masculine</option>
                                        <option value="2">Feminine</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.maritalStatus')} {...register("maritalStatus")}>
                                    <Form.Select aria-label={t('formLabel.maritalStatus')}>
                                        <option value="">{t('formLabel.select')}</option>
                                        <option value="1">{t("formLabel.married")}</option>
                                        <option value="2">{t("formLabel.single")}</option>
                                        <option value="3">{t("formLabel.widower")}</option>
                                        <option value="4">{t("formLabel.legallySeparated")}</option>
                                        <option value="5">{t("formLabel.disquitado")}</option>
                                        <option value="6">{t("formLabel.divorced")}</option>
                                        <option value="7">{t("formLabel.stableunion")}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.nationality')}>
                                    <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register("Nationality")} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.telephone')}>
                                    <Form.Control type="text" placeholder={t('formLabel.telephone')}  {...register("telephone")} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.email')+ " *"}>
                                    <Form.Control type="email" placeholder={t('formLabel.email')} className={`form-control ${errors.email?.message ? 'is-invalid' : ''}`} {...register("email")} />
                                    <Form.Control.Feedback type="invalid">{errors?.email?.message}</Form.Control.Feedback >
                                </FloatingLabel>
                            </Col>

                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.address')}</h6>
                        <Row className="g-lg-4 g-3">
                            <Col md={4}>
                                <InputGroup>
                                    <FloatingLabel className="validZipcode mb-lg-4 mb-3 removeInsertDisable" label={t('formLabel.zipCode')}>
                                        <Form.Control type="text" placeholder={t('formLabel.zipCode')}
                                            className={`form-control ${errors.zipcode?.message ? 'is-invalid' : ''}`} {...register("zipcode")} maxLength={9} onChange={(e) => getLocation(e, "zipcode")} />
                                        <div className="invalid-feedback">{errors.zipcode?.message}</div>
                                    </FloatingLabel>
                                    <InputGroup.Text>
                                        <img src="./assets/images/icons/search.svg" className="h-16 cursor-pe" alt="Search icon" />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col md={8}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.address')}>
                                    <Form.Control type="text" placeholder={t('formLabel.address')} {...register("address")} />
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.number')}>
                                    <Form.Control type="text" placeholder={t('formLabel.number')} {...register("number")} />
                                </FloatingLabel>
                            </Col>
                            <Col md={8}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.complement')}>
                                    <Form.Control type="text" placeholder={t('formLabel.complement')} {...register("complement")} />
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.state')}>
                                    <Form.Select {...register("state")}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        {allStates && allStates.length > 0 &&
                                            allStates.map((item, index) => {
                                                return (
                                                    <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                )
                                            })}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.city')}>
                                    <Form.Control type="text" placeholder={t('formLabel.city')} {...register("city")} />
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                    <Form.Control type="text" placeholder={t('formLabel.neighborhood')} {...register("neighborhood")} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('others')}</h6>
                        <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')} >
                            <Form.Control as="textarea" placeholder={t('formLabel.observation')}  {...register("observation")} />
                        </FloatingLabel>
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        <Button variant="outline-primary" onClick={newRepresentativeClose}>
                            {t('button.close')}
                        </Button>
                        <Button variant="primary" className="min-w-auto" type="submit" >
                            {t('button.save')}
                        </Button>
                    </div>
                </Form>
            </Offcanvas>
        </>
    )
}

export default RepresentativeService
