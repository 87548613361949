//The input field will be enabled when the user clicks on the edit button.
function has_Attr_Data(item) {
    if (item.hasAttribute("disabled")) {
        item.removeAttribute("disabled");
    } else {
        item.setAttribute("disabled", "");
    }
}
//The input field will be enabled when the user clicks on the edit button.
export const handleAction = async (e: any, action: string, page: string) => {
    let currElement: any = (e.target).parentElement.parentElement.parentElement.parentElement;
    let disableElement: any = (e.target).parentElement.parentElement.parentElement.parentElement.parentElement;
    if (action == "toedit") {
        currElement.querySelector(".TOEDIT")?.classList.remove('d-block');
        currElement.querySelector(".TOEDIT")?.classList.add('d-none');
        currElement.querySelector(".ACTION")?.classList.remove('d-none');
        currElement.querySelector(".ACTION")?.classList.add('d-block');
        disableElement.querySelectorAll(".card-body .removeInsertDisable.form-control").forEach((item) => {
            has_Attr_Data(item)
        });
        disableElement.querySelectorAll(".card-body #removeInsertDisable .form-select").forEach((item) => {
            has_Attr_Data(item)
        });
        disableElement.querySelectorAll(".card-body .removeInsertDisable .form-select").forEach((item) => {
            has_Attr_Data(item)
        });
        disableElement.querySelectorAll(".card-body .removeInsertDisable  input").forEach((item) => {
            has_Attr_Data(item)
        });
        disableElement.querySelectorAll(".card-body .removeInsertDisable  textarea").forEach((item) => {
            has_Attr_Data(item)
        });
        disableElement.querySelectorAll(".card-body .removeInsertDisable  textarea").forEach((item) => {
            has_Attr_Data(item)
        });
        disableElement.querySelectorAll(".replacehide.removeInsertDisable").forEach((item) => {
            item.removeAttribute('disabled');
        });
        disableElement.querySelectorAll(".opening_hourData button").forEach((item) => {
            item.removeAttribute("hidden");
        });
        var dropdownButton:any = document?.getElementById('dropdown-basic');
        dropdownButton?.classList?.add('editbale');
      
        disableElement.querySelectorAll(".BtnAction, .delete").forEach((item) => {
            if (item.hasAttribute("hidden")) {
                item.removeAttribute("hidden");
            } else {
                item.setAttribute("hidden", "");
            }
        });
       let doc:any =  document.getElementById("moreCond")
       if(doc){
        doc.removeAttribute("hidden")
       }else{
        doc.setAttribute("hidden", "")
       }
        // disableElement.querySelectorAll(".newlogo, .delete").forEach((item) => {
        //     if (item.hasAttribute("hidden")) {
        //         item.removeAttribute("hidden");
        //     } else {
        //         item.setAttribute("hidden", "");
        //     }
        // });

    }

    if (action == "cancelSubmit") {
        currElement.querySelector(".TOEDIT")?.classList.remove('d-none');
        currElement.querySelector(".TOEDIT")?.classList.add('d-block');
        currElement.querySelector(".ACTION")?.classList.remove('d-bock');
        currElement.querySelector(".ACTION")?.classList.add('d-none');

        disableElement.querySelectorAll(".card-body .removeInsertDisable.form-control").forEach((item) => {
            has_Attr_Data(item)
        });
        disableElement.querySelectorAll(".card-body #removeInsertDisable.form-select").forEach((item) => {
            has_Attr_Data(item)
        });
        disableElement.querySelectorAll(".card-body .removeInsertDisable  input").forEach((item) => {
            has_Attr_Data(item)
        });
        disableElement.querySelectorAll(".card-body .removeInsertDisable  textarea").forEach((item) => {
            has_Attr_Data(item)
        });
        disableElement.querySelectorAll(".replacehide.removeInsertDisable").forEach((item) => {
            item.setAttribute('disabled', "");
        });

        document.querySelector('.replacehide.removeInsertDisable button')?.setAttribute('disabled', "");

        disableElement.querySelectorAll(".BtnAction, .delete").forEach((item) => {
            item.setAttribute("hidden", "");
        });
      
        window.location.reload();
    }

    if (action !== "toedit") {
        if (action == "update") {
            let submit: any = disableElement.querySelector(".submitForm");
            submit.click();
        }
    }
}