import { Form, Modal, Button, Row, Col, InputGroup, Table, Card } from 'react-bootstrap';
import { reInsuranceTp, getInsurance, insuranceOpen, insuranceObj, partnershipLargeOpen, partnershipSmallOpen } from "../../../../redux/slice/contractDataSlice";
import React, { useState, MouseEvent, useEffect } from 'react';
import { Outlet, Link, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Loader from '../../../Profile/Company/Loader';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import InsuranceService from '../Common/InsuranceService';
import InsurancePopups from '../Common/InsurancePopups';
import '../../../../App.css';

const Insurers = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const { insuranceData, status } = useSelector((state: RootState) => state.contractData)
    const [agencyInsurance, setAgencyInsurency] = useState<any>('');

    const [limit, setlimit] = useState<any>(0);
    useEffect(() => {
        if (status != 'loading' && agencyInsurance == '') {
            if (limit <= 2) {
                dispatch(getInsurance({ "insurance": "", "start": 0, "end": 10 }));
                gggg();
                setlimit(limit + 1);
            }
        }
    }, [dispatch, status]);

    const gggg = () => {
        setAgencyInsurency('');
        setTimeout(() => {
            if (insuranceData.data && insuranceData.data.length > 0) {
                insuranceData.data.forEach((item: any, index: number) => {
                    if (item["ST_SEGURANCA_EMP"] && item["ST_SEGURANCA_EMP"] !== "") {
                        let agencyDetails = item.AGENCY_DETAILS;
                        setAgencyInsurency(agencyDetails);
                    }
                })
            }
        }, 500);
    }

    const editInsurance = (evt, item) => {
        dispatch(insuranceObj(item));
        dispatch(reInsuranceTp(""));
        dispatch(insuranceOpen(true))
    }
    const getAllInsurers = (insur) => {
        let insurers = { "insurance": insur, "start": 0, "end": 10 }
        dispatch(getInsurance(insurers));
    }
    const openKenloPartLargepopup = (e, agencyInfo) => {
        dispatch(partnershipSmallOpen(agencyInfo.AGENCY_INSURANCE_STATUS))
    }
    const opennewKenloInsurance = () => {
        navigate('/add_insurance');
    }
    const openAddForm = () => {
        // if( insuranceData.totalRow && insuranceData.data.length 
        //     && insuranceData.data[0].AGENCY_DETAILS && insuranceData.data[0].AGENCY_DETAILS.AGENCY_INSURANCE_ID != '' ){
        dispatch(insuranceObj(''));
        dispatch(insuranceOpen(true))
        // } else {
        //     navigate("/add_insurance"); 
        // }
    }
    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/dashboard"}><img src="./assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3 me-1">
                                    <h1 className="mb-0 lh-base h4 text-truncate">{t("insurers")}</h1>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            {/* Search bar */}
                            <InputGroup>
                                <Form.Control onChange={(e) => getAllInsurers(e.target.value)} placeholder={t('formLabel.typeSearch')} aria-label="Search" aria-describedby="search_icon" />
                                <InputGroup.Text id="search_icon">
                                    <img src="./assets/images/icons/search.svg" alt="Search icon" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end mb-xl-0 col-xl mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <Link onClick={openAddForm} to={""} className="fw-bold cursor-pe">
                                        <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />{t("insurelist.newinsurers")}
                                    </Link>
                                    {/* <Button variant="secondary" className='bg-light text-dark p-1 fs-12' onClick={openAddForm}>
                                        <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />{t("insurelist.newinsurers")}
                                    </Button> */}
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">

                {agencyInsurance == '' &&
                    <div style={{ background: "#CBDFEE" }} className=" p-3 rounded-2 mb-3 mb-lg-4 bg-opacity-25">
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <p className='mb-0 ms-3'>
                                    <img src="assets/images/icons/Kenlo.svg" alt="icon" className="me-1" />
                                    {t("Dashboard.Wanttoincreaseyourincome")}.
                                    {t("Dashboard.withKenloWarrantyandKenloInsurance")}
                                </p>
                            </div>
                            <img onClick={opennewKenloInsurance} src="assets/images/icons/arrow-right.svg" alt="icon" />
                        </div>
                    </div>
                }

                {agencyInsurance && agencyInsurance.AGENCY_INSURANCE_STATUS != '0' &&
                    <div className={
                        (agencyInsurance.AGENCY_INSURANCE_STATUS == "1" || agencyInsurance.AGENCY_INSURANCE_STATUS == "2") ? 'bg-warning-10 p-3 rounded-2 mb-lg-4 bg-opacity-25' :
                            agencyInsurance.AGENCY_INSURANCE_STATUS == "3" || agencyInsurance.AGENCY_INSURANCE_STATUS == "4" ? 'bg-warning-40 p-3 rounded-2 mb-lg-4 bg-opacity-25' :
                                agencyInsurance.AGENCY_INSURANCE_STATUS == "5" ? 'bg-success-40 p-3 rounded-2 mb-lg-4 bg-opacity-25' : 'bg-warning-10 p-3 rounded-2 mb-lg-4 bg-opacity-25'
                    }>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <p className='mb-0 ms-3'>
                                    {agencyInsurance.AGENCY_INSURANCE_STATUS == "1" &&
                                        <img src="assets/images/icons/Frame14428.svg" alt="icon" className="me-1" />
                                    }
                                    {agencyInsurance.AGENCY_INSURANCE_STATUS == "2" &&
                                        <img src="assets/images/icons/Frame16079.svg" alt="icon" className="me-1" />
                                    }
                                    {agencyInsurance.AGENCY_INSURANCE_STATUS == "3" &&
                                        <img src="assets/images/icons/Frame14429.svg" alt="icon" className="me-1" />
                                    }
                                    {agencyInsurance.AGENCY_INSURANCE_STATUS == "4" &&
                                        <img src="assets/images/icons/ErrorCircle.svg" alt="icon" className="me-1" />
                                    }
                                    {agencyInsurance.AGENCY_INSURANCE_STATUS == "5" &&
                                        <img src="assets/images/icons/CheckmarkCircle.svg" alt="icon" className="me-1" />
                                    }
                                    <strong>{t(`Dashboard.kenloInsurance${agencyInsurance.AGENCY_INSURANCE_STATUS}T1`)}</strong> {t(`Dashboard.kenloInsurance${agencyInsurance.AGENCY_INSURANCE_STATUS}T2`)}
                                </p>
                            </div>
                            {(agencyInsurance.AGENCY_INSURANCE_STATUS == "1" || agencyInsurance.AGENCY_INSURANCE_STATUS == "2" || agencyInsurance.AGENCY_INSURANCE_STATUS == "3") &&
                                <>
                                    <div>
                                        <Button variant="secondary" className='bg-light text-dark p-1 fs-12 me-3 border-0' onClick={(e) => openKenloPartLargepopup(e, agencyInsurance)} >Requested on {agencyInsurance.REQUESTED_ON}</Button>
                                        <img className="cursor-pe" onClick={(e) => openKenloPartLargepopup(e, agencyInsurance)} src="assets/images/icons/arrow-right.svg" alt="icon" />
                                    </div></>
                            }
                            {(agencyInsurance.AGENCY_INSURANCE_STATUS == "4" || agencyInsurance.AGENCY_INSURANCE_STATUS == "5") &&
                                <img className="cursor-pe" onClick={(e) => openKenloPartLargepopup(e, agencyInsurance)} src="assets/images/icons/arrow-right.svg" alt="icon" />
                            }
                        </div>
                    </div>
                }


                {/*  <!-- Table start from here --> */}
                {
                    (status == "loading") ?
                        <Loader />
                        :
                        <Card>
                            <Card.Body className="pb-1">
                                <Table responsive className="table-custom table-custom-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th className="w-20">{t("formLabel.company")}</th>
                                            <th>{t("insurelist.responsibleParty")}</th>
                                            <th>{t("formLabel.telephone")}</th>
                                            <th>{t("formLabel.email")}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(insuranceData.data && insuranceData.data.length > 0 && insuranceData.data[0].name != undefined) ? insuranceData.data.map((item) => (
                                            <tr key={item.id}>
                                                <td className="text-gray-dark">{item.name}</td>
                                                <td className="text-secondary">{item.responsible ? item.responsible : '?'}</td>
                                                <td className="text-gray-dark">{item.telephone ? item.telephone : ''}</td>
                                                <td className="text-gray-dark">{item.email ? item.email : ''}</td>
                                                <td className="text-gray-dark text-end">
                                                    <Button variant="link" className="p-0" onClick={(e) => editInsurance(e, item)}>
                                                        <img src="assets/images/icons/edit-primary.svg" alt="Edit" title="Edit" className="h-20 cursor-pe" />
                                                    </Button>
                                                </td>
                                            </tr>
                                        )) :
                                            <tr>
                                                <td colSpan={5}>
                                                    <span>{t('formLabel.noRecordsFounds')}</span>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                }
                {/* <!-- /Table end --> */}
                <p className="d-block mt-lg-4 mt-3">{t('formLabel.listing')} {insuranceData.totalRow && insuranceData.totalRow > 0 ? insuranceData.totalRow : 0} {t('property.record')}.</p>
            </main>
            {/* <!-- /Main content start end --> */}
            <InsuranceService />
            <InsurancePopups />
        </section>
    )
}
export default Insurers;