import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Row, Col, InputGroup, Table, Accordion, FloatingLabel, Badge, Card } from 'react-bootstrap';
import CompleteTerminate from './CompleteTerminate';
import PropertyActions from './PropertyActions';
import ScheduleMoveOutInspection from './ScheduleMoveOutInspection';
import ConcludeMoveOutInspection from './ConcludeMoveOutInspection';
import CheckPropertyTax from './CheckPropertyTax';
import CancelInsurance from './CancelInsurance';
import HandoverPropertyKeys from './HandoverPropertyKeys';
import ModifyRecurringExpenses from './ModifyRecurringExpenses';
import GenerateSendDocuments from './GenerateSendDocuments';
import TerminationCalculationBilling from './TerminationCalculationBilling';
import TerminateAddItem from './TerminateAddItem';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '../../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { contractDatails, post_termination_checklist } from '../../../../../../redux/slice/contractDataSlice';
import Terminate from './Terminate';
import customerService from '../../../../../../services/customerService';
import { userService } from '../../../../../../services/userService';
import { Languages } from '../../../../../../constants';
import i18n from '../../../../../../i18n';
import { encrypt_Decrypt } from '../../../../../constants/utils';


const TerminateListing = (props) => {
    const { terminateContractModalShow, ShowThisData, setShowThisData, show, toggleShow, showTerminateContract, terminationResp, allresponsibleList, setallresponsibleList, completeTermination, setCompleteTermination, terminatonReason } = props;
    const [t] = useTranslation();
    const { contractDatail } = useSelector((state: RootState) => state.contractData)
    const { id }: any = useParams();
    const dispatch = useDispatch<AppDispatch>();

    const [countTotal, setcountTotal] = useState<any>({
        checkVal: 0,
        totalcheck: 0
    })

    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        setUserJson(userResult);
    }, [])

    const [ReasonTermination, setReasonTermination] = useState("");
    useEffect(() => {
        let data: any = {};
        data["contract_id"] = id;
        customerService.getinitialTermination(data).then(res => {
            if (res?.["data"]?.["type"] == "success" || res?.["data"]?.["type"] == "Success") {
                let post = res?.["data"]?.["data"][0];
                let filteredReason = terminatonReason.filter(item => Number(item.value) === Number(post?.["FL_MOTIVORESCISAO_CON"]))
                setReasonTermination(filteredReason[0]["label"]);
            }
        })
    }, [])

    //Complete terminate contract modal initialization
    const [showCompleteTerminateModal, setCompleteTerminateModal] = useState(false);
    const completeTerminateModalShow = () => {
        // if (countTotal["totalcheck"] == countTotal["checkVal"]) {
            setShowThisData(true);
            setCompleteTerminateModal(true);
        // } else {
        //     Swal.fire({
        //         title: "Complete all checklist.",
        //         text: "Please check all the checkbox.",
        //         // icon: 'success',"warning",
        //         confirmButtonText: "OK",
        //         timer: 2000
        //     })
        // }
    };

    const completeTerminateModalClose = () => {
        setShowThisData(false);
        setCompleteTerminateModal(false)
    };

    //Property actions contract modal initialization
    const [showPropertyActionsModal, setPropertyActionsModal] = useState(false);
    const propertyActionsModalShow = () => {
        setPropertyActionsModal(true);
    }

    const propertyActionsModalClose = () => {
        setShowThisData(false);
        setPropertyActionsModal(false);
    }

    //Schedule move out inspection contract modal initialization
    const [showScheduleInspectionModal, setScheduleInspectionModal] = useState(false);
    const scheduleInspectionModalShow = () => {
        setScheduleInspectionModal(true);
    }

    const scheduleInspectionModalClose = () => {
        setShowThisData(false);
        setScheduleInspectionModal(false);
    }


    //Conclude move out inspection contract modal initialization
    const [showConcludeInspectionModal, setConcludeInspectionModal] = useState(false);
    const concludeInspectionModalShow = () => {
        setConcludeInspectionModal(true);
    }

    const concludeInspectionModalClose = () => {
        setShowThisData(false);
        setConcludeInspectionModal(false);
    }


    //Check property tax modal initialization
    const [showPropertyTaxModal, setPropertyTaxModal] = useState(false);
    const propertyTaxModalShow = () => {
        setPropertyTaxModal(true);
    }

    const propertyTaxModalClose = () => {
        setShowThisData(false);
        setPropertyTaxModal(false);
    }


    //Cancel insurance modal initialization
    const [showCancelInsuranceModal, setCancelInsuranceModal] = useState(false);
    const cancelInsuranceModalShow = () => {
        setCancelInsuranceModal(true);
    }

    const cancelInsuranceModalClose = () => {
        setShowThisData(false);
        setCancelInsuranceModal(false);
    }


    //Modify recurring expenses modal initialization
    const [showRecurringExpensesModal, setRecurringExpensesModal] = useState(false);
    const recurringExpensesModalShow = () => {
        setRecurringExpensesModal(true);
    }

    const recurringExpensesModalClose = () => {
        setShowThisData(true);
        setRecurringExpensesModal(false);
    }


    //Handover property keys modal initialization
    const [showHandoverPropertyModal, setHandoverPropertyModal] = useState(false);
    const handoverPropertyModalShow = () => {
        setHandoverPropertyModal(true);
    }

    const handoverPropertyModalClose = () => {
        setShowThisData(false);
        setHandoverPropertyModal(false);
    }

    //Termination calculation billing modal initialization
    const [showTerminationCalculationModal, setTerminationCalculationModal] = useState(false);
    const terminationCalculationModalShow = () => {
        setTerminationCalculationModal(true);
    }

    const terminationCalculationModalClose = () => {
        setShowThisData(false);
        setTerminationCalculationModal(false);
    }

    //Generate send documents modal initialization
    const [showGenerateSendDocumentsModal, setGenerateSendDocumentsModal] = useState(false);
    const generateSendDocumentsModalShow = () => {
        setGenerateSendDocumentsModal(true);
    }

    const generateSendDocumentsModalClose = () => {
        setShowThisData(false);
        setGenerateSendDocumentsModal(false);
    }

    //Add item modal initialization
    const [showAddItemModal, setAddItemModal] = useState(false);
    const addItemModalClose = () => {
        // setcurrentClickCustomId("")
        setShowThisData(false);
        setAddItemModal(false)
    };
    const addItemModalShow = () => {
        setShowThisData(true);
        setAddItemModal(true)
    };


    //Toggle Edit button
    const [allTermchecklist, setallTermchecklist] = useState<any>([]);
    const [allcustomTermchecklist, setallcustomTermchecklist] = useState<any>([]);
    const [hideChecklistTermination, sethideChecklistTermination] = useState<Array<any>>([]);
    const [hideChecklistTermination1, sethideChecklistTermination1] = useState<Array<any>>([]);
    const [customLengthAdditem, setcustomLengthAdditem] = useState(5);
    const [currentClickCustomId, setcurrentClickCustomId] = useState("");
    const [reEdit, setreEdit] = useState(false);


    useEffect(() => {
        if (contractDatail) {
            let data: any = {};
            data["contract_id"] = id;
            customerService.getinitialTermination(data).then(res => {
                if (res?.["data"]?.["type"] == "success" || res?.["data"]?.["type"] == "Success") {
                    let post = res?.["data"]?.["data"][0];
                    setreEdit(post !== undefined);
                }
            })
        }
        setCompleteTermination(contractDatail?.[0]?.["termination_list"]?.[0]?.["BL_COMPLETE_TERMINATION_TRM"] === 0);
    }, [contractDatail, dispatch])

    useEffect(() => {
        //  For Default Termination
        let dataUpdate: any = [];
        let showList: any = [];
        let hideList: any = [];

        contractDatail && contractDatail[0] &&
            contractDatail[0]["termination_default_list"] &&
            contractDatail[0]["termination_default_list"]?.["length"] > 0 &&
            setallTermchecklist(contractDatail[0]["termination_default_list"]);

        contractDatail && contractDatail[0] && contractDatail?.[0]?.["termination_default_list"] &&
            contractDatail?.[0]?.["termination_default_list"].length > 0 &&
            contractDatail?.[0]?.["termination_default_list"].forEach((item: any, index: any) => {
                if (index < 10) {
                    if (item["ST_HIDEFROMUSER_DFTCKL"] && item["ST_HIDEFROMUSER_DFTCKL"]?.type == "Buffer") {
                        let data = item["ST_HIDEFROMUSER_DFTCKL"]?.data;
                        let utf8decoder = new TextDecoder();
                        let u8arr = new Uint8Array(data);
                        let changeIntoString = utf8decoder.decode(u8arr);
                        if (changeIntoString !== "") {
                            // dataUpdate.push({ id: item["ID_DEFAULT_TERMINATION_DFTCKL"], hide_from_contract_id: JSON.parse(changeIntoString), type: "default" })
                            dataUpdate.push({ id: item["ID_DEFAULT_TERMINATION_DFTCKL"], hide_from_contract_id: "id", type: "default" })
                        } else {
                            dataUpdate.push({ id: item["ID_DEFAULT_TERMINATION_DFTCKL"], hide_from_contract_id: "", type: "default" })
                        }
                    }
                }
            })

        dataUpdate && dataUpdate.length > 0 &&
            dataUpdate.forEach((item, index) => {
                let Isthere = item["hide_from_contract_id"].indexOf(Number(id)) !== -1 ? true : false;
                if (Isthere) {
                    hideList.push(item["id"]);
                } else {
                    showList.push(item["id"]);
                }
            })

        sethideChecklistTermination([
            {
                contract_id: id,
                allChecklist: dataUpdate,
                showChecklist: showList,
                hideChecklist: hideList,
                type: "default"
            }
        ])

        //  For Custom Termination
        let dataUpdate1: any = [];
        let showList1: any = [];
        let hideList1: any = [];

        contractDatail && contractDatail[0] &&
            contractDatail[0]["termination_custom_list"] &&
            contractDatail[0]["termination_custom_list"]?.["length"] > 0 &&
            setallcustomTermchecklist(contractDatail[0]["termination_custom_list"]);

        contractDatail?.[0]?.["termination_custom_list"] && contractDatail?.[0]?.["termination_custom_list"].length > 0 &&
            contractDatail?.[0]?.["termination_custom_list"].forEach((item, index) => {
                if (item.ST_HIDEFROMUSER_TRMCKL && item["ST_HIDEFROMUSER_TRMCKL"]?.type == "Buffer") {
                    let data = item["ST_HIDEFROMUSER_TRMCKL"]?.data;
                    let utf8decoder = new TextDecoder();
                    let u8arr = new Uint8Array(data);
                    let changeIntoString = utf8decoder.decode(u8arr);
                    if (changeIntoString !== "") {
                        dataUpdate1.push({ id: item["ID_TERMINATION_TRMCKL"], hide_from_contract_id: JSON.parse(changeIntoString), type: "custom" })
                    } else {
                        dataUpdate1.push({ id: item["ID_TERMINATION_TRMCKL"], hide_from_contract_id: "", type: "custom" })
                    }
                }
            })

        dataUpdate1 && dataUpdate1.length > 0 &&
            dataUpdate1.forEach((item, index) => {
                let Isthere = item["hide_from_contract_id"].indexOf(Number(id)) !== -1 ? true : false;
                if (Isthere) {
                    hideList1.push(item["id"]);
                } else {
                    showList1.push(item["id"]);
                }
            })

        sethideChecklistTermination1([
            {
                contract_id: id,
                allChecklist1: dataUpdate1,
                showChecklist1: showList1,
                hideChecklist1: hideList1,
                type: "custom"
            }
        ])
        // moment(newDate).format("MMM/YYYY")
    }, [contractDatail[0]["termination_default_list"] || contractDatail[0]["termination_custom_list"]])

    const [actionRun, setactionRun] = useState<any>({
        action: "",
        clickChecklistId: "",
        index: "",
    })

    const handleHideShow = (e, action, checklist_Data, index, type) => {
        setactionRun({
            action: action,
            clickChecklistId: checklist_Data,
            index: index
        });

        if (type == "default") {
            if (action == "open") {
                let setHideChecklist = hideChecklistTermination?.[0]?.["hideChecklist"].filter((item) => item !== checklist_Data)
                sethideChecklistTermination([{
                    ...hideChecklistTermination[0],
                    ["showChecklist"]: [
                        ...hideChecklistTermination?.[0]?.["showChecklist"],
                        checklist_Data
                    ],
                    ["hideChecklist"]: setHideChecklist
                }]);
            }

            if (action == "close") {
                let setHideChecklist = hideChecklistTermination?.[0]?.["showChecklist"].filter((item) => item !== checklist_Data)
                sethideChecklistTermination([{
                    ...hideChecklistTermination[0],
                    ["hideChecklist"]: [
                        ...hideChecklistTermination?.[0]?.["hideChecklist"],
                        checklist_Data
                    ],
                    ["showChecklist"]: setHideChecklist
                }])
            }
        } else {
            if (action == "open") {
                let setHideChecklist = hideChecklistTermination1?.[0]?.["hideChecklist1"].filter((item) => item !== checklist_Data)
                // console.log({ setHideChecklist, hideChecklistTermination1: hideChecklistTermination1?.[0]?.["hideChecklist1"], checklist_Data })
                sethideChecklistTermination1([{
                    ...hideChecklistTermination1[0],
                    ["showChecklist1"]: [
                        ...hideChecklistTermination1?.[0]?.["showChecklist1"],
                        checklist_Data
                    ],
                    ["hideChecklist1"]: setHideChecklist
                }]);
            }

            if (action == "close") {
                let setHideChecklist = hideChecklistTermination1?.[0]?.["showChecklist1"].filter((item) => item !== checklist_Data)
                sethideChecklistTermination1([{
                    ...hideChecklistTermination1[0],
                    ["hideChecklist1"]: [
                        ...hideChecklistTermination1?.[0]?.["hideChecklist1"],
                        checklist_Data
                    ],
                    ["showChecklist1"]: setHideChecklist
                }])
            }
        }

    }

    useEffect(() => {
        if (hideChecklistTermination && hideChecklistTermination?.[0] && hideChecklistTermination?.[0]?.["allChecklist"] && !show) {
            hideChecklistTermination?.[0]?.["allChecklist"].forEach((item, index) => {
                if (hideChecklistTermination?.[0]?.["hideChecklist"].indexOf(item?.["id"]) !== -1) {
                    if (item?.["hide_from_contract_id"].indexOf(Number(id)) === -1) {
                        if (hideChecklistTermination[0]["allChecklist"][index]["hide_from_contract_id"] === "") {
                            let CollectAllObject: any = [{
                                ...hideChecklistTermination[0],
                                ["allChecklist"]: {
                                    ...hideChecklistTermination[0]["allChecklist"],
                                    [`${index}`]: {
                                        ...hideChecklistTermination[0]["allChecklist"][index],
                                        ["hide_from_contract_id"]: [
                                            ...hideChecklistTermination[0]["allChecklist"][index]["hide_from_contract_id"],
                                            Number(id)
                                        ]
                                    }
                                }
                            }];
                            hideChecklistTermination[0]["allChecklist"] = Object.values(CollectAllObject?.[0]?.["allChecklist"]);
                        }
                    }
                } else {
                    if (item?.["hide_from_contract_id"].indexOf(Number(id)) !== -1) {
                        let restOfValue = hideChecklistTermination[0]["allChecklist"][index]["hide_from_contract_id"].filter((item) => Number(item) !== Number(id));
                        hideChecklistTermination[0]["allChecklist"][index]["hide_from_contract_id"] = restOfValue.length == 0 ? "" : restOfValue;
                    }
                }

                if (actionRun["action"] == "close" && item["hide_from_contract_id"] && item["hide_from_contract_id"].length > 0) {
                    if (Number(item?.["id"]) == Number(actionRun["clickChecklistId"])) {
                        hideChecklistTermination[0]["allChecklist"][index]["hide_from_contract_id"] = [...item["hide_from_contract_id"], Number(id)]
                    }
                }
            });
        }
    }, [hideChecklistTermination?.[0]?.["hideChecklist"], !show])

    useEffect(() => {
        if (hideChecklistTermination1 && hideChecklistTermination1?.[0] && hideChecklistTermination1?.[0]?.["allChecklist1"] && !show) {
            hideChecklistTermination1?.[0]?.["allChecklist1"].forEach((item, index) => {
                if (hideChecklistTermination1?.[0]?.["hideChecklist1"].indexOf(item?.["id"]) !== -1) {
                    if (item?.["hide_from_contract_id"].indexOf(Number(id)) === -1) {
                        if (hideChecklistTermination1[0]["allChecklist1"][index]["hide_from_contract_id"] === "") {
                            let CollectAllObject: any = [{
                                ...hideChecklistTermination1[0],
                                ["allChecklist1"]: {
                                    ...hideChecklistTermination1[0]["allChecklist1"],
                                    [`${index}`]: {
                                        ...hideChecklistTermination1[0]["allChecklist1"][index],
                                        ["hide_from_contract_id"]: [
                                            ...hideChecklistTermination1[0]["allChecklist1"][index]["hide_from_contract_id"],
                                            Number(id)
                                        ]
                                    }
                                }
                            }];
                            hideChecklistTermination1[0]["allChecklist1"] = Object.values(CollectAllObject?.[0]?.["allChecklist1"]);
                        }
                    }
                } else {
                    if (item?.["hide_from_contract_id"].indexOf(Number(id)) !== -1) {
                        let restOfValue = hideChecklistTermination1[0]["allChecklist1"][index]["hide_from_contract_id"].filter((item) => Number(item) !== Number(id));
                        hideChecklistTermination1[0]["allChecklist1"][index]["hide_from_contract_id"] = restOfValue.length == 0 ? "" : restOfValue;
                    }
                }

                if (actionRun["action"] == "close" && item["hide_from_contract_id"] && item["hide_from_contract_id"].length > 0) {
                    if (Number(item?.["id"]) == Number(actionRun["clickChecklistId"])) {
                        hideChecklistTermination1[0]["allChecklist1"][index]["hide_from_contract_id"] = [...item["hide_from_contract_id"], Number(id)]
                    }
                }
            });
        }
    }, [hideChecklistTermination1?.[0]?.["hideChecklist1"], !show])

    // useEffect(() => {
    //     console.log(hideChecklistTermination?.[0]?.["allChecklist"], "this is all checklisttttttt")
    // }, [contractDatail?.[0]?.["termination_list"]])

    const handletoggleShow = (e) => {
        if (!show) {
            Swal.fire({
                title: t("Sweetalert.WantToSave"),
                text: t("Sweetalert.Areyousuretosaveit"),
                // icon: 'success',"warning",
                confirmButtonText: t("bankTransaction.ok"),
                showCancelButton: true,
                showCloseButton: true,
            }).then(async (res) => {
                if (res["isConfirmed"]) {
                    let allData = {
                        defaultData: hideChecklistTermination,
                        customData: hideChecklistTermination1
                    }
                    let response = await dispatch(post_termination_checklist(allData));
                    if (response?.["payload"]?.["type"] == "success" || response?.["payload"]?.["type"] == "Success") {
                        Swal.fire({
                            title: "Success",
                            text: "Successfully updated",
                            // icon: 'success',"success",
                            timer: 1000
                        }).then(() => {
                            toggleShow(true);
                        })
                    } else {
                        Swal.fire({
                            title: "Failed!!!",
                            text: "Update failed please try again!!",
                            // icon: 'success',"error",
                            timer: 1500
                        }).then(() => {
                            toggleShow(false);
                        })
                    }
                } else {
                    await dispatch(contractDatails(id))
                    Swal.fire({
                        title: "Update cancel",
                        text: "Change are cancelled",
                        // icon: 'success',"error",
                        timer: 1000
                    }).then(() => {
                        toggleShow(true);
                    })
                }
            });
        } else {
            toggleShow(false);
        }
    }

    const OpenPopUp = (e, index, name, type, id) => {
        if (type == "default") {
            if (index == 0) {
                terminateContractModalShow();
            }
            if (index == 1) {
                propertyActionsModalShow();
            }
            if (index == 2) {
                scheduleInspectionModalShow();
            }
            if (index == 3) {
                concludeInspectionModalShow();
            }
            if (index == 4) {
                propertyTaxModalShow();
            }
            if (index == 5) {
                cancelInsuranceModalShow();
            }
            if (index == 6) {
                recurringExpensesModalShow();
            }
            if (index == 7) {
                handoverPropertyModalShow();
            }
            if (index == 8) {
                terminationCalculationModalShow();
            }
            if (index == 9) {
                generateSendDocumentsModalShow();
            }
        }

        if (type == "custom") {
            addItemModalShow();
            setcurrentClickCustomId(id);
        }
        setShowThisData(true);
    }

    useEffect(() => {
        setTimeout(() => {
            let TotalChecked = document.querySelectorAll(".check_Ischecked input.form-check-input").length;
            let checkedCount = 0;
            document.querySelectorAll(".check_Ischecked input.form-check-input").forEach((item, index) => {
                if (item?.["checked"]) {
                    checkedCount++;
                }
            })
            setcountTotal({
                checkVal: Number(checkedCount),
                totalcheck: Number(TotalChecked)
            })
        }, 500)

    }, [dispatch, show, contractDatail, reEdit])

    useEffect(() => {
        firstLoadAllDefault(id);
    }, [allTermchecklist])

    let indexDefault = ["property_action_XYZ", "schedule_moveout_inpection_XYZ", "conclude_moveout_inpection_XYZ", "check_properties_tax_XYZ", "cancel_insurance_XYZ", "modify_recurring_XYZ", "key_handover_XYZ", "termination_calculation_XYZ", "generate_send_XYZ"];
    const [limit_notice, setLimit_Notice] = useState([{
        indexDefaultId: "",
        type: "",
        limit: "",
        notice: ""
    }]);

    const firstLoadAllDefault = (id) => {
        customerService.get_terminationDefaultTerminationList_basedOn_Contractid({ "contract_id": id }).then(res => {
            if (res?.["data"]?.["type"] == "success" || res?.["data"]?.["type"] == "Success") {
                let allData: any = [];
                let post = res?.["data"]?.["data"];
                post.forEach((item, index) => {
                    if (item["ID_TERMINATIONPOPUP_TERPOP"]) {
                        allData.push({
                            indexDefaultId: indexDefault.indexOf(item["TP_TYPE_NAME_TERPOP"]),
                            type: indexDefault[indexDefault.indexOf(item["TP_TYPE_NAME_TERPOP"])],
                            limit: item["SL_RESPONSIBLE_TERPOP"],
                            notice: item["DT_LIMITDATE_TERPOP"],
                        })
                    } else {
                        allData.push({
                            indexDefaultId: indexDefault.indexOf(item["TP_TYPE_NAME_TERPOP"]),
                            type: "move_out_notice_XYZ",
                            limit: item["TX_RESPONSABLE_NAME"],
                            notice: item["DT_MOVEOUT_TRM"],
                        })
                    }
                })
                setLimit_Notice(allData)
            }
        })
    }

    const [allagencyData, setAllagencyData] = useState<any>([]);
    useEffect(() => {
        let objectVal_agency: any = [];
        userService.getAllUsers({ userId: "", start: 0, end: 1000 }).then(res => {
            res?.["data"].forEach(itemsingle => {
                objectVal_agency.push({
                    value: itemsingle?.["ID_USUARIO_USU"],
                    label: itemsingle?.["ST_APELIDO_USU"],
                })
            });
            setAllagencyData(objectVal_agency);
        })
    }, [ShowThisData])


    return (
        <>
            <Card className="mb-lg-4 mb-3">
                <Card.Header className="d-sm-flex alig-items-center justify-content-between">
                    {t("terminatelist.Move-out")} {t("terminatelist.details")}
                    {
                        show && !completeTermination && userJson && userJson.length > 0 && (userJson.indexOf(2031) !== -1) &&
                        <span className="text-primary fw-bold h6 mb-0 cursor-pe" onClick={completeTerminateModalShow}>{t("terminatelist.Completetermination")}</span>
                    }
                </Card.Header>
                <Card.Body>
                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                        <Col md className="border-end border-sm-none">
                            <h6>{t("terminatelist.Processstart")}</h6>
                            <h4 className="mb-0">{moment(contractDatail?.[0]?.["termination_date"]).format("DD/MM/YYYY") ?? ""}</h4>
                        </Col>
                        <Col md className="border-end border-sm-none">
                            <h6>{t("terminatelist.Move-out")} {t("terminatelist.date")}</h6>
                            <h4 className="mb-0">{moment(contractDatail?.[0]?.["termination_list"]?.[0]?.["DT_MOVEOUT_TRM"]).format("DD/MM/YYYY") ?? ""}</h4>
                        </Col>
                        <Col md>
                            <h6>{t("terminatelist.Lastmodified")}</h6>
                            <h4 className="mb-0">{moment(contractDatail?.[0]?.["termination_list"]?.[0]?.["DT_LASTMODIFIED_TRM"]).format("DD/MM/YYYY") ?? ""}</h4>
                        </Col>
                    </Row>
                    {
                        ReasonTermination !== "" &&
                        <>
                            <h6>{t("terminatelist.Reason")}</h6>
                            <h4 className="mb-lg-4 mb-3">
                                {/* {t("terminatelist.Propertyunfitforobtainingapermitand/orfiredepartmentclearancecertificate")}. */}
                                {ReasonTermination}.
                            </h4>
                        </>
                    }

                    <Accordion defaultActiveKey="0">
                        <Accordion.Item className="border position-relative" eventKey="0">
                            {
                                !completeTermination && userJson && userJson.length > 0 && (userJson.indexOf(2031) !== -1) &&
                                <Button variant="link" onClick={(e) => handletoggleShow(e)} className="position-absolute end-0 top-0 mt-1 me-5 fw-bold z-index-5 btn btn-link">{show ? t('button.edit') : t("terminatelist.exitediting")}</Button>
                            }

                            <Accordion.Header>
                                {t("terminatelist.Terminationchecklist")} <Badge className="text-dark bg-warning-10 ms-xl-3 ms-xxl-4 ms-2">{countTotal.checkVal} {t("terminatelist.outof")} {countTotal.totalcheck} {t("terminatelist.itemscompleted")}</Badge>
                            </Accordion.Header>
                            <Accordion.Body>
                                {hideChecklistTermination?.[0]?.["showChecklist"].length > 0 &&
                                    <Table responsive borderless className="mb-0 secondary-table check_Ischecked">
                                        <thead>
                                            <tr>
                                                {show &&
                                                    <th className="pe-1 ps-0 py-2">
                                                        {/* <Form.Check type="checkbox" hidden /> */}
                                                    </th>
                                                }
                                                <th className="px-1 py-2">{t("terminatelist.item")}</th>
                                                <th className="px-1 py-2">{t("responsible")}</th>
                                                <th className="px-1 py-2">{t("terminatelist.limit")}</th>
                                                <th className="text-end ps-1 pe-0 py-2"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allTermchecklist &&
                                                allTermchecklist?.["length"] > 0 &&
                                                allTermchecklist.map((item, index) => {
                                                    if (index < 10 && hideChecklistTermination?.[0]?.["showChecklist"].indexOf(item?.["ID_DEFAULT_TERMINATION_DFTCKL"]) !== -1) {
                                                        let contractDDD = 1;
                                                        let limit = "---";
                                                        let notice = "---";
                                                        if (index === 0) {
                                                            let firstIndx = limit_notice.filter(item => String(item["indexDefaultId"]) == "-1");
                                                            allagencyData && allagencyData.length > 0 &&
                                                                allagencyData.map((items, index) => {
                                                                    let valueData = Number(items["value"]) === Number(firstIndx?.[0]?.["limit"]);
                                                                    if (valueData) {
                                                                        limit = items["label"];
                                                                    }
                                                                })

                                                            if (firstIndx && firstIndx?.[0]?.["notice"]) {
                                                                notice = moment(firstIndx?.[0]?.["notice"].split("T")[0]).format("DD/MM");
                                                            }
                                                        }

                                                        if (index !== 0) {
                                                            contractDDD = contractDatail?.[0]?.["termination_default_popup"].filter(item => item["TP_TYPE_NAME_TERPOP"] == indexDefault[index - 1])?.[0]?.["ST_STATUS_TERPOP"] ?? 1;
                                                            if (limit_notice.length > 0) {
                                                                let data = limit_notice.filter(item => String(item["indexDefaultId"]) == String(index - 1));
                                                                if (data.length > 0) {
                                                                    {
                                                                        terminationResp && terminationResp.length > 0 &&
                                                                            terminationResp.map((items, index) => {
                                                                                let valueData = Number(items["value"]) === Number(data?.[0]?.["limit"]);
                                                                                if (valueData) {
                                                                                    limit = items["label"];
                                                                                }
                                                                            })
                                                                    }
                                                                    if (data?.[0]?.["notice"]) {
                                                                        notice = moment(data[0]['notice'].split("T")[0]).format("DD/MM");
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        return (
                                                            <tr key={index}>
                                                                {show &&
                                                                    <td className="pe-1 ps-0 py-2">
                                                                        {
                                                                            //  && contractDatail?.[0]?.["termination_custom_list"].length > 0
                                                                            index === 0 ?
                                                                                <>
                                                                                    {
                                                                                        reEdit ?
                                                                                            <Form.Check type="checkbox" disabled checked />
                                                                                            :
                                                                                            <Form.Check type="checkbox" disabled />
                                                                                    }
                                                                                </>

                                                                                :
                                                                                <>
                                                                                    {
                                                                                        contractDDD == 1 ?
                                                                                            <Form.Check type="checkbox" disabled />
                                                                                            :
                                                                                            <Form.Check type="checkbox" checked disabled />
                                                                                    }

                                                                                </>
                                                                        }
                                                                    </td>
                                                                }
                                                                <td className="px-1 py-2 text-dark">
                                                                    {i18n.language && i18n.language == "en" ? item["TX_TITLE_DFTCKL"] : item["TX_PT_TITLE_TRMCKL"] || item["TX_PT_TITLE_DFTCKL"]}

                                                                    {/* {item["TX_TITLE_DFTCKL"]} */}
                                                                </td>

                                                                <td className="px-1 py-2">
                                                                    {limit}
                                                                </td>

                                                                <td className="px-1 py-2">
                                                                    {notice}
                                                                </td>

                                                                {show && <>
                                                                    <td className="ps-1 pe-0 py-2 text-end cursor-pe">
                                                                        <img src="../assets/images/icons/arrow-right.svg" alt="arrow right" onClick={(e) => OpenPopUp(e, index, item["TX_TITLE_DFTCKL"], "default", "")} />
                                                                    </td>
                                                                </>
                                                                }
                                                                {!show &&
                                                                    <>
                                                                        {
                                                                            contractDatail?.[0]?.["termination_default_popup"].filter(item => item["TP_TYPE_NAME_TERPOP"] == indexDefault[index - 1])?.[0]?.["ST_STATUS_TERPOP"] != 0 && index != 0 &&
                                                                            <td className="ps-1 pe-0 py-2 text-end cursor-pe" onClick={(e) => handleHideShow(e, "close", item?.["ID_DEFAULT_TERMINATION_DFTCKL"], index, "default")}>
                                                                                <img src="../assets/images/icons/eye-primary.svg" className="h-18" alt="View" />
                                                                            </td>
                                                                        }
                                                                    </>
                                                                }
                                                            </tr>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                allcustomTermchecklist &&
                                                allcustomTermchecklist?.["length"] > 0 &&
                                                allcustomTermchecklist.map((item, index) => {
                                                    if (hideChecklistTermination1?.[0]?.["showChecklist1"].indexOf(item?.["ID_TERMINATION_TRMCKL"]) !== -1) {
                                                        return (
                                                            <tr key={index}>
                                                                {show &&
                                                                    <td className="pe-1 ps-0 py-2">
                                                                        {
                                                                            String(item?.["ST_STATUS_TRMCKL"]) == "1" ?
                                                                                <Form.Check type="checkbox" disabled readOnly />
                                                                                :
                                                                                <Form.Check type="checkbox" checked disabled readOnly />
                                                                        }
                                                                    </td>
                                                                }
                                                                <td className="px-1 py-2 text-dark">
                                                                    {item["TX_TITLE_TRMCKL"]}
                                                                </td>
                                                                <td className="px-1 py-2">
                                                                    {
                                                                        terminationResp && terminationResp.length > 0 &&
                                                                        terminationResp.map((items, index) => {
                                                                            let valueData = Number(items["value"]) === Number(item["TA_RESPONSIBLE_TRMCKL"]);
                                                                            if (valueData) {
                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                        {items["label"]}
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </td>
                                                                <td className="px-1 py-2">
                                                                    {moment(item["DT_LIMITDATE_TRMCKL"]).format("DD/MM")}
                                                                </td>
                                                                {show && <>
                                                                    <td className="ps-1 pe-0 py-2 text-end cursor-pe">
                                                                        <img src="../assets/images/icons/arrow-right.svg" alt="arrow right" onClick={(e) => OpenPopUp(e, index, item["TX_TITLE_TRMCKL"], "custom", item?.["ID_TERMINATION_TRMCKL"])} />
                                                                    </td>
                                                                </>
                                                                }
                                                                {!show &&
                                                                    <>
                                                                        {
                                                                            String(item?.["ST_STATUS_TRMCKL"]) == "1" &&
                                                                            <td className="ps-1 pe-0 py-2 text-end cursor-pe" onClick={(e) => handleHideShow(e, "close", item?.["ID_TERMINATION_TRMCKL"], index, "custom")}>
                                                                                <img src="../assets/images/icons/eye-primary.svg" className="h-18" alt="View" />
                                                                            </td>
                                                                        }
                                                                    </>
                                                                }
                                                            </tr>
                                                        )
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                }
                                {!show &&
                                    <>
                                        {
                                            allcustomTermchecklist?.["length"] < customLengthAdditem &&
                                            <Button onClick={addItemModalShow} variant="link" className="d-flex align-items-center justify-content-center mx-auto my-3"><img src="../assets/images/icons/plus-light.svg" className="h-18 cursor-pe me-1" alt="Add" />{t("button.additem")}</Button>
                                        }
                                        <Table responsive borderless className="mb-0 secondary-table">
                                            <thead>
                                                <tr>
                                                    <th className="px-1 py-2">{t("terminatelist.item")}</th>
                                                    <th className="px-1 py-2">{t("terminatelist.limit")}</th>
                                                    <th className="px-1 py-2">{t("terminatelist.notice")}</th>
                                                    <th className="text-end ps-1 pe-0 py-2"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    allTermchecklist &&
                                                    allTermchecklist?.["length"] > 0 &&
                                                    allTermchecklist.map((item, index) => {
                                                        if (index < 10 && hideChecklistTermination?.[0]?.["hideChecklist"].indexOf(item?.["ID_DEFAULT_TERMINATION_DFTCKL"]) !== -1) {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="px-1 py-2 text-dark">
                                                                        {item["TX_TITLE_DFTCKL"]}
                                                                    </td>
                                                                    <td className="px-1 py-2">
                                                                        {
                                                                            terminationResp && terminationResp.length > 0 &&
                                                                            terminationResp.map((items, index) => {
                                                                                let valueData = Number(items["value"]) === Number(item["TA_RESPONSIBLE_DFTCKL"]);
                                                                                if (valueData) {
                                                                                    return (
                                                                                        <React.Fragment key={index}>
                                                                                            {items["label"]}
                                                                                        </React.Fragment>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </td>
                                                                    <td className="px-1 py-2">
                                                                        {moment(item["DT_LIMITDATE_DFTCKL"]).format("DD/MM")}
                                                                    </td>

                                                                    {!show &&
                                                                        <>

                                                                            <td className="ps-1 pe-0 py-2 text-end" onClick={(e) => handleHideShow(e, "open", item?.["ID_DEFAULT_TERMINATION_DFTCKL"], index, "default")}>
                                                                                <img src="../assets/images/icons/eye-close-primary.svg" className="h-18 cursor-pe" alt="View" />
                                                                            </td>
                                                                        </>
                                                                    }
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                }
                                                {
                                                    allcustomTermchecklist &&
                                                    allcustomTermchecklist?.["length"] > 0 &&
                                                    allcustomTermchecklist.map((item, index) => {
                                                        if (hideChecklistTermination1?.[0]?.["hideChecklist1"].indexOf(item?.["ID_TERMINATION_TRMCKL"]) !== -1) {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="px-1 py-2 text-dark">
                                                                        {item["TX_TITLE_TRMCKL"]}
                                                                    </td>
                                                                    <td className="px-1 py-2">
                                                                        {
                                                                            terminationResp && terminationResp.length > 0 &&
                                                                            terminationResp.map((items, index) => {
                                                                                let valueData = Number(items["value"]) === Number(item["TA_RESPONSIBLE_TRMCKL"]);
                                                                                if (valueData) {
                                                                                    return (
                                                                                        <React.Fragment key={index}>
                                                                                            {items["label"]}
                                                                                        </React.Fragment>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </td>
                                                                    <td className="px-1 py-2">
                                                                        {moment(item["DT_LIMITDATE_TRMCKL"]).format("DD/MM")}
                                                                    </td>
                                                                    {!show &&
                                                                        <>
                                                                            <td className="ps-1 pe-0 py-2 text-end" onClick={(e) => handleHideShow(e, "open", item?.["ID_TERMINATION_TRMCKL"], index, "custom")}>
                                                                                <img src="../assets/images/icons/eye-close-primary.svg" className="h-18 cursor-pe" alt="View" />
                                                                            </td>
                                                                        </>
                                                                    }
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </>
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Card.Body>
            </Card >

            <PropertyActions showPropertyActionsModal={showPropertyActionsModal} propertyActionsModalClose={propertyActionsModalClose} ShowThisData={ShowThisData} setShowThisData={setShowThisData} terminationResp={terminationResp} allresponsibleList={allresponsibleList} />
            <ScheduleMoveOutInspection showScheduleInspectionModal={showScheduleInspectionModal} scheduleInspectionModalClose={scheduleInspectionModalClose} ShowThisData={ShowThisData} setShowThisData={setShowThisData} terminationResp={terminationResp} allresponsibleList={allresponsibleList} />
            <ConcludeMoveOutInspection showConcludeInspectionModal={showConcludeInspectionModal} concludeInspectionModalClose={concludeInspectionModalClose} ShowThisData={ShowThisData} setShowThisData={setShowThisData} terminationResp={terminationResp} allresponsibleList={allresponsibleList} />
            <CheckPropertyTax showPropertyTaxModal={showPropertyTaxModal} propertyTaxModalClose={propertyTaxModalClose} ShowThisData={ShowThisData} setShowThisData={setShowThisData} terminationResp={terminationResp} allresponsibleList={allresponsibleList} />
            <CancelInsurance showCancelInsuranceModal={showCancelInsuranceModal} cancelInsuranceModalClose={cancelInsuranceModalClose} ShowThisData={ShowThisData} setShowThisData={setShowThisData} terminationResp={terminationResp} allresponsibleList={allresponsibleList} />
            <ModifyRecurringExpenses showRecurringExpensesModal={showRecurringExpensesModal} recurringExpensesModalShow={recurringExpensesModalShow} recurringExpensesModalClose={recurringExpensesModalClose} ShowThisData={ShowThisData} setShowThisData={setShowThisData} terminationResp={terminationResp} allresponsibleList={allresponsibleList} />
            <HandoverPropertyKeys showHandoverPropertyModal={showHandoverPropertyModal} handoverPropertyModalClose={handoverPropertyModalClose} ShowThisData={ShowThisData} setShowThisData={setShowThisData} terminationResp={terminationResp} allresponsibleList={allresponsibleList} />
            <TerminationCalculationBilling showTerminationCalculationModal={showTerminationCalculationModal} setTerminationCalculationModal={setTerminationCalculationModal} terminationCalculationModalClose={terminationCalculationModalClose} terminationCalculationModalShow={terminationCalculationModalShow} ShowThisData={ShowThisData} setShowThisData={setShowThisData} terminationResp={terminationResp} allresponsibleList={allresponsibleList} />
            <GenerateSendDocuments showGenerateSendDocumentsModal={showGenerateSendDocumentsModal} generateSendDocumentsModalClose={generateSendDocumentsModalClose} ShowThisData={ShowThisData} setShowThisData={setShowThisData} terminationResp={terminationResp} allresponsibleList={allresponsibleList} />
            <TerminateAddItem showAddItem={showAddItemModal} addItemClose={addItemModalClose} addItemShow={addItemModalShow} terminationResp={terminationResp} allresponsibleList={allresponsibleList} hideChecklistTermination1={hideChecklistTermination1} ShowThisData={ShowThisData} setShowThisData={setShowThisData} currentClickCustomId={currentClickCustomId ? currentClickCustomId : ""} setcurrentClickCustomId={setcurrentClickCustomId} />
            <CompleteTerminate showCompleteTerminate={showCompleteTerminateModal} completeTerminateClose={completeTerminateModalClose} />
        </>
    )
}

export default TerminateListing
