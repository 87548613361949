export const isNumber = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}
export const numericOnly = (e: any) => {
    const reg = /^(?:\d+|\d+\.\d*|\.\d+|\d+\.\d+)$/
    let preval = e.target.value
    if (e.target.value === '' || reg.test(e.target.value)) return true
    else e.target.value = preval.substring(0, (preval.length - 1))
}
//The input field will be enabled when the user clicks on the edit button.
export const HandleSubmit = (e: any, cmd: string) => {
    e.preventDefault();
    let thisDivform: any = e.target.parentElement.parentElement.parentElement.parentElement.parentElement;
    let thisButton: any = e.target.parentElement.parentElement.parentElement;
    if (cmd == "edit") {
        if (thisDivform.querySelectorAll("#editForm").length) {
            thisDivform.querySelectorAll("#editForm input").forEach((item: any, index: number) => {
                item.removeAttribute('disabled');
            })
            thisDivform.querySelectorAll("#editForm select").forEach((item: any, index: number) => {
                item.removeAttribute('disabled');
            })
            thisDivform.querySelectorAll("#editForm textarea").forEach((item: any, index: number) => {
                item.removeAttribute('disabled');
            }) 
        }
        if (thisButton.querySelectorAll('.Update,.Edit ').length > 0) {
            //thisButton.querySelector('.Edit')?.setAttribute('hidden', '');
        }
        thisButton.querySelector('.others')?.removeAttribute('hidden')
    }
    thisDivform.querySelectorAll(".delete").forEach((item: any, index: number) => {
        item?.removeAttribute('hidden');
    })
}

export const SubmitData = (e: any, act) => {
    let thisDivform: any;
    let thisButton: any;
    if (act == "") {
        thisDivform = e.target.parentElement.parentElement.parentElement;
        thisButton = e.target.parentElement.parentElement.parentElement;
    } else {
        e.type = "submit"
        thisDivform = e;
        thisButton = e;
        document.querySelector(".edit.buttonclick")?.removeAttribute("hidden");
        document.querySelector(".update.buttonclick")?.setAttribute("hidden", "");
        document.querySelector(".cancel.buttonclick")?.setAttribute("hidden", "");;
    }
    if (e.type == "submit") {
        if (thisDivform.querySelectorAll("#editForm").length) {
            thisDivform.querySelectorAll("#editForm input").forEach((item: any, index: number) => {
                item.setAttribute('disabled', '')
            });
            thisDivform.querySelectorAll("#editForm textarea").forEach((item: any, index: number) => {
                item.setAttribute('disabled', '')
            });
        }
        if (thisDivform.querySelectorAll("#editForm").length) {
            thisDivform.querySelectorAll("#editForm select").forEach((item: any, index: number) => {
                item.setAttribute('disabled', '');
            });
            thisDivform.querySelectorAll("#editForm textarea").forEach((item: any, index: number) => {
                item.setAttribute('disabled', '');
            }); 
        }
        if (thisButton.querySelectorAll('.Update,.Edit,.CancelReset').length > 0) {
            //thisButton.querySelector('.Edit')?.removeAttribute('hidden');
            thisButton.querySelector('.Update')?.setAttribute('hidden', '');
            thisButton.querySelector('.CancelReset')?.setAttribute('hidden', '');
        }
    }
}

export const handleEdit = (data: string) => {
    let action: any = "";
    if (data == "edit") {
        action = document.querySelector(".Edit");
        action.click();
        document.querySelector(".update")?.removeAttribute("hidden");
        document.querySelector(".cancel.zipCodeCheck")?.removeAttribute("hidden");
        document.querySelector(".edit")?.setAttribute("hidden", "");
    }
    if (data == "update") {
        action = document.querySelector(".Update");
        action.click();
        /*-----Harjinder------30082023--*/
        // document.querySelector(".edit")?.removeAttribute("hidden");
        // document.querySelector(".update")?.setAttribute("hidden", "");
    }
    if (data == "cancel") {
        action = document.querySelector(".CancelReset");
        action.click();
    }
}

//Keep Dot inside Number input
const locale = "de-DE";
const numberFormat = new Intl.NumberFormat(locale, {
    style: "decimal",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
});
const onlyChars = new RegExp(/^.$/)
const onlyNumbers = new RegExp(/\d/);

export function dotInsert(e) {
    if (onlyNumbers.test(e.key)) {
        e.preventDefault()
        e.target.value = numberFormat.format(e.target.value.replace(/\./g, '') + e.key)
    } else if (onlyChars.test(e.key)
        && !e.getModifierState('Meta')
        && !e.getModifierState('Ctrl')) {
        e.preventDefault()
    }
}
//End 
// check emptyfield if there text color red
export const openErrorbox = () => {
    setTimeout(() => {
        if (document.querySelectorAll('.accordion-item').length > 0) {
            document.querySelectorAll('.accordion-item').forEach(item => {
                let addClassName: any = item.querySelector('.accordion-header');
                addClassName["style"]["color"] = 'black';
                addClassName.querySelector('button')["style"]["color"] = 'black';

                let search = true;
                item.querySelectorAll('.invalid-feedback').forEach(items => {
                    if (search) {
                        let textvalue: any = items["textContent"];
                        if (textvalue.length > 0) {
                            search = false;
                            // let addClassName: any = item.querySelector('.accordion-collapse.collapse');
                            // addClassName?.classList.add('show')
                            let addClassName: any = item.querySelector('.accordion-header');
                            addClassName["style"]["color"] = 'red';
                            addClassName.querySelector('button')["style"]["color"] = 'red';
                            // addClassName.querySelector('button span')["style"]["color"] = 'red';
                            // addClassName.querySelector('button span').classList.remove('text-dark')
                        }
                    }
                })
            })
        }
    }, 200)
}
//end