import React, { useState, useEffect } from 'react';
import { Outlet, Link, useParams, useLocation } from 'react-router-dom';
import { FloatingLabel, Dropdown, Col, Row, Card, Form, Button, Alert, Modal } from 'react-bootstrap';
import ReceivableSubHeader from '../ReceivableCharges/ReceivableSubHeader';
import ChargePrintOut from '../ReceivableCharges/ChargePrintOut';
import ReceivedListing from './ReceivedListing';
import SettleLots from '../ReceivableCharges/SettleLots';
import ReverseCharge from './ReverseCharge';
import eventBus from "../ReceivableCharges/EventBus";
import ChargeService from '../ReceivableCharges/ChargeService';
import { getChargesSummery } from './ReceivedListing';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { IMAGEURLLINK, encrypt_Decrypt } from '../../../constants/utils';
import moment from "moment-timezone";
import Swal from 'sweetalert2';
import { emailPostTransfer } from '../../../../redux/slice/SendEmailSlice';
import { RecChargesFotterForm } from '../../../Interface';
import { incomeService } from '../../../../services/incomeServices';
import { useForm } from 'react-hook-form';
import Loader from '../../../Profile/Company/Loader';
import i18n from '../../../../i18n';

const Received = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    const [showAlert1, setShowAlert1] = useState(true);
    const [showAlert2, setShowAlert2] = useState(true);
    const [search_type, setsearch_type] = useState("");
    const { getlogo, status } = useSelector((state: RootState) => state.companyData);

    let [allDesc, setAllDesc] = useState<any>({ desc_1: "" });
    //Print out modal initialization
    const [showPrintOutModal, setPrintOutModal] = useState(false);
    const printOutModalClose = () => setPrintOutModal(false);
    const printOutModalShow = () => setPrintOutModal(true);

    //Settle lots modal initialization
    const [showSettleLotsModal, setSettleLotsModal] = useState(false);
    const settleLotsModalClose = () => setSettleLotsModal(false);
    const settleLotsModalShow = () => setSettleLotsModal(true);

    //Reverse charge modal initialization
    const [showReverseChargeModal, setReverseChargeModal] = useState(false);
    const reverseChargeModalClose = () => setReverseChargeModal(false);
    const reverseChargeModalShow = () => setReverseChargeModal(true);
    const [chargesList, setChargesDue] = useState<any>([]);

    const [data, setData] = useState("");
    const [showmodel, setShowmodel] = useState(false);
    const parentToChild = () => {
        setData("");
    }

    const [searchvalue, setSearchValue] = useState({});
    const [offFirstload, setOffFirstLoad] = useState(false);
    const location = useLocation();

    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        setUserJson(userResult);
        if (location.state && location?.state?.dashboard == "yes") {
            // state["location"] = location.state
            // if (location.state.type == "overdue" || location.state.type == "dueToday") {
            setState({
                ...state,
                filteSearchDate: location.state["searchFrom"],
                filterEndDate: location.state["endFrom"]
            })
            // }
        }

        setTimeout(() => {
            let tobepaid_received: any = document.querySelector("body");
            let receId: any = JSON.parse(tobepaid_received?.getAttribute("tobepaid_received"))
            if (receId && receId.length > 0) {
                setState({
                    ...state,
                    tobepaid_receId: String(receId)
                })
                tobepaid_received?.removeAttribute("tobepaid_received");
            }
        }, 1200)
    }, [])

    const options = [
        { value: 0, label_en: "Undue charge", label_pt: "Cobrança indevida" },
        { value: 1, label_en: "Replaced charge", label_pt: "Cobrança substituída" },
        { value: 2, label_en: "Cancellation", label_pt: "Cancelamento" },
        { value: 3, label_en: "Bonus", label_pt: "Bonificação" },
        { value: 6, label_en: "Proposal Cancelled", label_pt: "Proposta Cancelada" },
        { value: 7, label_en: "Provision for Doubtful Debts (PDD)", label_pt: "Provisão para Devedores Duvidosos (PDD)" },
        { value: 4, label_en: "Suspension", label_pt: "Suspensão" },
        { value: 8, label_en: "Negotiation", label_pt: "Negociação" },
        { value: 9, label_en: "Underpayment", label_pt: "Pagamento a menor" },
        { value: 5, label_en: "Others", label_pt: "Outros" }
    ];

    useEffect(() => {
        parentToChild();
    }, [])

    const selectAllUsers = () => {
        let owner = { action: 'selectall' }
        setData(JSON.stringify(owner));
    }
    const unSelectAllUsers = () => {
        let owner = { action: 'unselectall' }
        setData(JSON.stringify(owner));
    }

    eventBus.on("couponApply", (data) => {
        let owner = {
            bankAccount: data.bankAccount,
            action: 'change_bank'
        }
        setData(JSON.stringify(owner));
        removeEvent();
    });

    const removeEvent = () => {
        eventBus.remove("couponApply", {});
    }

    const onClose = () => {
        setShowmodel(false);
    }

    let { id } = useParams();
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M').format("MMM/YYYY");
    let searchDt = moment(newDate).format("MMM/YYYY")
    let endDt = moment(newDate).add(1, 'M').format("MMM/YYYY");

    const [currentPage, setCurrentPage] = useState(1);
    const [statusdata, setStatusdata] = useState("");

    const [state, setState] = useState<any>({
        FL_STATUS_RECB: '1,2',
        type: "received",
        Maturity_Date: "2023-06",
        filter: {},
        startDate: startDt,
        endDate: endDt,
        searchDate: searchDt,
        filteSearchDate: "",
        filterEndDate: "",
        search_type: "",
        start: 0,
        end: 20,
        ID_RECEBIMENTO_RECB: location.state && location.state != null ? location.state.ID_RECEBIMENTO_RECB : "",
    })

    const [emialStatus, setEmailStatus] = useState(false);
    const [isCheckId, setIsCheckId] = useState<any>([]);
    const errorSwltAlert = () => {
        Swal.fire({
            //// icon: 'success','warning',
            title: t("Sweetalert.Oops"),
            text: t("Sweetalert.Pleaseselectsomeusers"),
            timer: 2500
        })
    }

    const ClickHandler = async (type: any) => {
        setEmailStatus(true)
        if (isCheckId.length > 0) {
            if (type == "ship") {
                alert("ship clicked");
            }

            if (type == "unship") {
                alert("unship clicked");
            }

            if (type == "email") {
                let allEmails: any = [];
                let mailData: any = []
                isCheckId.forEach((givenID: any) => {
                    // Check if the current ID exists in the data
                    chargesList.forEach(async (item: any, index) => {
                        if (item.ID_RECEBIMENTO_RECB == givenID) {
                            if (item.ST_EMAIL_PES && item.ST_EMAIL_SAC) {
                                allEmails.push(item?.ST_EMAIL_PES);
                                allEmails.push(item?.ST_EMAIL_SAC);
                            } else if (item.ST_EMAIL_PES) {
                                allEmails.push(item?.ST_EMAIL_PES);
                            } else if (item.ST_EMAIL_SAC) {
                                allEmails.push(item?.ST_EMAIL_SAC);
                            } else {
                                allEmails.push();
                            }
                            mailData.push(item)
                        }
                    });
                })
                let DescArray: any = {};
                allEmails.forEach((item: any, index: number) => {
                    let newText: any = ` <!DOCTYPE html>
                            <html>
                              <head>
                                <meta charset="UTF-8">
                              </head>
                              <body style="font-family:sans-serif;margin-left:2em;">
                               <header>
                               <img className="form-control" src=${getlogo?.logo_name && IMAGEURLLINK.replace("{logoname}", ((getlogo?.logo_name).split("_|_")[0]).replace("/", "%2F")).replace("{generation}", (getlogo?.logo_name).split("_|_")[1])} alt="logo">
                               </header>
                               <h1>KenlooooA pagou há 49 dias.</h1>
                               <p>Hello ${mailData[index]?.ST_NOME_SAC}, </p>
                               <p>For your convenience, we provide the statement of the transfer made  <b>49 days ago</b> .</p>
                               <p><b>Email </b>: ${mailData[index]?.ST_EMAIL_PES ? mailData[index]?.ST_EMAIL_PES : mailData[index]?.ST_EMAIL_SAC},</p>
                               <p><b>Payment </b>: ${mailData[index]?.maturityDt ? moment(mailData[index]?.maturityDt).format("DD/MM/YYYY") : moment(mailData[index]?.DT_VENCIMENTO_RECB).format("DD/MM/YYYY")}</p>
                               <p><b>Value </b>: ${mailData[index]?.vl_emitido_recb}</p>
                                  <div style="background-color:#F5F5F5; width: 100%; margin: 0 !important; padding: 0 !important;">
                                  <div style="max-width: 600px; margin:auto;">
                                    <div style="margin: 0 15px;  padding:32px 10px 32px 10px">
                                    <img className="form-control" src=${getlogo?.logo_name && IMAGEURLLINK.replace("{logoname}", ((getlogo?.logo_name).split("_|_")[0]).replace("/", "%2F")).replace("{generation}", (getlogo?.logo_name).split("_|_")[1])} alt="logo">
                                    </div>
                                  </div>
                               </div>
                              </body>
                            </html>
                    ` || ''; // Use the actual property name if needed
                    DescArray[`desc_${index + 1}`] = newText;
                })
                if (allEmails.length > 0) {
                    let emaildata: any = {
                        status: "new",
                        subject: "Email vindo do POSTMAN",
                        content: DescArray,
                        layout: "default",
                        sender: "noreply@dev.kenlo.io",
                        to: allEmails,
                        "cc": [
                            "vikash@wegile.com"
                        ],
                        "bcc": [
                            "karan@wegile.com"
                        ],
                        identifier: "identifier",
                        model: 1,
                        caption: "caption",
                        title: "Send Email",
                    }
                    let res = await dispatch(emailPostTransfer(emaildata))
                    if (res.payload.resCode == 201) {
                        Swal.fire({
                            title: t("Sweetalert.Success"),
                            text: t("Sweetalert.mailsent"),
                            // icon: 'success',"success",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                        setEmailStatus(false)
                        reset()
                        setAllDesc({ desc_1: "" })
                    } else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.somethingWent"),
                            // icon: 'success',"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                        setEmailStatus(false)
                    }
                    setIsCheckId([]);
                } else {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.emailsRequire"),
                        // icon: 'success',"error",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                    setEmailStatus(false)
                }
            }
        } else {
            errorSwltAlert();
            setEmailStatus(false)
        }
    }

    const alertPop = (data) => {
        alert(data);
    }

    const [showReasonInvalidationModal, setReasonInvalidationModal] = useState(false);
    const reasonInvalidationModalClose = () => {
        setReasonInvalidationModal(false);
        reset();
    }
    const reasonInvalidationModalShow = () => {
        if (isCheckId.length > 0) {
            setReasonInvalidationModal(true);
        } else {
            errorSwltAlert()
        }
    };

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm<RecChargesFotterForm>();

    const [loader_on, setLoader_on] = useState(false);
    const reasonInvalidationFrm = (data: RecChargesFotterForm) => {
        setLoader_on(true);
        let owner = {
            FL_MOTIVOCANCELAR_RECB: data.invalidationReason,
            FL_EXCLUIR_DESPESAS_TERCEIRO: data.deleteExpenses,
            action: 'reason_invalidation'
        }
        setData(JSON.stringify(owner));
    }

    return (
        <section className="content-wrapper content-wrapper-primary">
            {/*<!-- Breadcrumb section start from here -->*/}
            <ReceivableSubHeader getChargesSummery={getChargesSummery} searchvalue={searchvalue}
                setSearchValue={setSearchValue} offFirstload={offFirstload} setOffFirstLoad={setOffFirstLoad}
                setsearch_type={setsearch_type} stateChange={state} setStatechange={setState}
                currentPage={currentPage} setCurrentPage={setCurrentPage}
                status={statusdata} setStatus={setStatusdata} />
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/* <Alert className="mb-lg-4 mb-3" variant="warning" show={showAlert1} onClose={() => setShowAlert1(false)} dismissible>
                    <h6>Questions about credit card error codes?</h6>
                    <Link to={'/receivable_charges'}>Click here</Link>  or Help in the upper right corner and learn more!
                </Alert>
                <Alert className="mb-lg-4 mb-3" variant="warning" show={showAlert2} onClose={() => setShowAlert2(false)} dismissible>
                    The "Inter - Matriz" bank account has not yet been approved. 1 charge is marked for shipment. Generate Shipment .
                </Alert> */}
                {/* <!-- overview -->*/}

                {/* <!-- /overview -->*/}
                {/* Listing */}
                {
                    emialStatus ?
                        <Loader />
                        :
                        <ReceivedListing parentToChild={data} showmodel={showmodel} setShowmodel={setShowmodel}
                            searchvalue={searchvalue} offFirstload={offFirstload} setOffFirstLoad={setOffFirstLoad}
                            search_type={search_type} stateChange={state} setStatechange={setState}
                            currentPage={currentPage} setCurrentPage={setCurrentPage}
                            status={statusdata} setStatus={setStatusdata} isCheckId={isCheckId} setIsCheckId={setIsCheckId}
                            chargesList={chargesList} setChargesDue={setChargesDue} reasonInvalidationModalClose={reasonInvalidationModalClose}
                            setLoader_on1={setLoader_on} />
                }


                {/* <!-- Nav listing start from here -->*/}
                {
                    userJson && userJson.length > 0 && (userJson.indexOf(3031) !== -1) &&
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Dropdown>
                                <Dropdown.Toggle variant="light" size="sm">
                                    {t("formLabel.mark")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={selectAllUsers}>{t("formLabel.all")}</Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={unSelectAllUsers}>{t("formLabel.none")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"> <span className="px-1">{t("expenditure.withTagged")}:</span></li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm" onClick={printOutModalShow}>{t("printOut")}</Button>
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm" onClick={() => ClickHandler("email")} >{t("TransferPage.Sendbyemail")}</Button>
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm" onClick={reasonInvalidationModalShow} >{t("ReceiveandReceived.Invalidation")}</Button>
                        </li>
                        <li className="list-inline-item mb-lg-0 mb-1">
                            <Dropdown drop="up">
                                <Dropdown.Toggle variant="light" size="sm">
                                    {t("button.more")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="mh-456 overflow-auto">
                                    <Dropdown.Item onClick={reverseChargeModalShow}>{t("ReceiveandReceived.Markforshipment")}</Dropdown.Item>
                                    <Dropdown.Item>{t("ReceiveandReceived.Receipt")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => alertPop("credit card")}>{t("ReceiveandReceived.Forcecreditcardprocessing")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => ClickHandler("ship")}>{t("ReceiveandReceived.Markforshipment")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => ClickHandler("unship")}>{t("ReceiveandReceived.Unmarkfromshipment")}</Dropdown.Item>
                                    {/*<Dropdown.Item onClick={settleLotsModalShow}>{t("ReceiveandReceived.liquidateas")}</Dropdown.Item>*/}
                                    <SettleLots showSettleLotsModal={showSettleLotsModal} settleLotsModalClose={settleLotsModalClose} />
                                    <ReverseCharge showReverseChargeModal={showReverseChargeModal} reverseChargeModalClose={reverseChargeModalClose} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>
                }
                {/*<!-- /Nav listing  end -->*/}
            </main>
            <Modal show={showReasonInvalidationModal} onHide={reasonInvalidationModalClose}>
                <Form onSubmit={handleSubmit(reasonInvalidationFrm)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("ReceiveandReceived.Reasonforinvalidation")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert variant="warning" className="mb-lg-4 mb-3">
                            {t("ReceiveandReceived.usetheoptiononly")}.
                        </Alert>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="reason_invalidation" label={t("ReceiveandReceived.Reasonforinvalidation")}>
                            <Form.Select aria-label="Reason for invalidation" className={`form-control ${errors.invalidationReason ? 'is-invalid' : ''}`}  {...register('invalidationReason')} >
                                {
                                    options.map((item: any, index: number) => {
                                        return (
                                            <option key={index} value={item.value}>{item[`label_${i18n.language}`]}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </FloatingLabel>
                        {/* <Form.Check type="checkbox" {...register('deleteExpenses')} id="delete_expenses" label={t("ReceiveandReceived.Deletependingexpensesforthirdparty")} /> */}
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            loader_on ?
                                <Button className="btn-width">
                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                </Button>
                                :
                                <>
                                    <Button variant="outline-primary" onClick={reasonInvalidationModalClose}>
                                        {t("button.close")}
                                    </Button>
                                    <Button type="submit" variant="primary" name="save" >{t("button.toSave")}</Button>
                                </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            <ChargeService show={showmodel} onClose={onClose} getChargesSummery={getChargesSummery} />
            <ChargePrintOut show={showPrintOutModal} onClose={printOutModalClose} />
            {/*<!-- /Main content end -->*/}
        </section>
    )
}

export default Received
