const IP1 = process.env.REACT_APP_LOCALHOST;
const IP2 = process.env.REACT_APP_IP2_PORT;
const IP3 = process.env.REACT_APP_IP3_PORT;
const STAGING_IP = process.env.REACT_APP_STAGING_IP_URL;
let IP: any = '';
let mode = process.env.REACT_APP_SERVER_MODE;
let server = process.env.REACT_APP_SERVER_SITE

let NODE_ENV = process.env.NODE_ENV;
 
if (mode == 'DEVELOPMENT') {
  IP = process.env.REACT_APP_IP3_PORT;
} else if (mode == 'DEVELOPMENT') {
  IP = process.env.REACT_APP_IP2_PORT
} else {
  IP = process.env.REACT_APP_LOCALHOST;
}

let CUST_API = process.env.REACT_APP_KENLO_CUSTOMER_DEV;
let COMP_API = process.env.REACT_APP_KENLO_COMPANY_DEV;
let TRAN_API = process.env.REACT_APP_KENLO_TRANSFER_DEV;
let REPORTS_SERVER_URL = process.env.REACT_APP_REPORTS_DEV_URL;

if (mode == 'STAGING') {
  CUST_API = process.env.REACT_APP_KENLO_CUSTOMER_STG;
  COMP_API = process.env.REACT_APP_KENLO_COMPANY_STG;
  TRAN_API = process.env.REACT_APP_KENLO_TRANSFER_STG;
  REPORTS_SERVER_URL = process.env.REACT_APP_REPORTS_STG_URL;
}

if (mode == 'PRODUCTION') {
  CUST_API = process.env.REACT_APP_KENLO_CUSTOMER_PRD;
  COMP_API = process.env.REACT_APP_KENLO_COMPANY_PRD;
  TRAN_API = process.env.REACT_APP_KENLO_TRANSFER_PRD;
  REPORTS_SERVER_URL = process.env.REACT_APP_REPORTS_STG_URL;
}

// if (NODE_ENV == 'production') {
//   CUST_API = process.env.REACT_APP_KENLO_CUSTOMER_PRD;
//   COMP_API = process.env.REACT_APP_KENLO_COMPANY_PRD;
//   TRAN_API = process.env.REACT_APP_KENLO_TRANSFER_PRD;
//   REPORTS_SERVER_URL = process.env.REACT_APP_REPORTS_STG_URL;
// }

let companyV2s: any = IP3 + `${process.env.REACT_APP_05}/api`;
let companyV2_URLs: any = IP3 + `${process.env.REACT_APP_05}/${process.env.REACT_APP_API_V1}`;
let companyV2_URL_LOCALs: any = IP + `${process.env.REACT_APP_02}/${process.env.REACT_APP_API_V1}`;

let TRANSFER___APIs: any = IP3 + `${process.env.REACT_APP_02}`;
let TRANSFER__APIs: any = IP3 + `${process.env.REACT_APP_02}/api`;
let TRANSFER_APIs: any = IP3 + `${process.env.REACT_APP_02}/${process.env.REACT_APP_API_V1}`;

let CUSTOMER___APIs: any = IP3 + `${process.env.REACT_APP_04}`;
let CUSTOMER__APIs: any = IP3 + `${process.env.REACT_APP_04}/api`;
let CUSTOMER_APIs: any = IP3 + `${process.env.REACT_APP_04}/${process.env.REACT_APP_API_V1}`;
let customerV2s: any = IP3 + `${process.env.REACT_APP_04}/api`;

let API_URLs: any = IP3 + `${process.env.REACT_APP_05}`;
let COA_API_URLs: any = IP3 + `${process.env.REACT_APP_05}`;
let notificationsV2URLs: any = IP3 + `${process.env.REACT_APP_04}/${process.env.REACT_APP_API_V1}`;

if (server === "KENLO") {
  API_URLs = COMP_API;
  COA_API_URLs = COMP_API;
  companyV2s = COMP_API + '/api';
  companyV2_URLs = COMP_API + '/' + process.env.REACT_APP_API_V1;
  TRANSFER___APIs = TRAN_API;
  TRANSFER__APIs = TRAN_API + '/api';
  companyV2_URL_LOCALs = TRAN_API + '/' + process.env.REACT_APP_API_V1;
  TRANSFER_APIs = TRAN_API + '/' + process.env.REACT_APP_API_V1;
  CUSTOMER___APIs = CUST_API;
  CUSTOMER__APIs = CUST_API + '/api';
  customerV2s = CUST_API + '/api';
  notificationsV2URLs = CUST_API + '/' + process.env.REACT_APP_API_V1;
  CUSTOMER_APIs = CUST_API + '/' + process.env.REACT_APP_API_V1;
}

const config = {
  REPORTS_SERVER_URL: REPORTS_SERVER_URL,
  SITE_URL: IP3,

  /*----------Mobile module api's--------*/
  MOBILE_API_URL: IP3 + `${process.env.REACT_APP_03}`, // Provide default port if not defined
  MOBILE_API_URL_JS: STAGING_IP + `${process.env.REACT_APP_88}`, // Provide default port if not defined

  /*----------Company module api's--------*/
  API_URL: API_URLs,
  COA_API_URL: COA_API_URLs,
  companyV2: companyV2s,
  companyV2_URL: companyV2_URLs,

  /*----------Transfer module api's--------*/
  TRANSFER___API: TRANSFER___APIs,
  TRANSFER__API: TRANSFER__APIs,
  companyV2_URL_LOCAL: companyV2_URL_LOCALs,
  TRANSFER_API: TRANSFER_APIs,

  /*----------Customer module api's--------*/
  CUSTOMER___API: CUSTOMER___APIs,
  CUSTOMER__API: CUSTOMER__APIs,
  customerV2: customerV2s,
  notificationsV2URL: notificationsV2URLs,
  CUSTOMER_API: CUSTOMER_APIs,

  /*----------Other module api's--------*/
  KOBANA_API: IP3 + `${process.env.REACT_APP_10}/${process.env.REACT_APP_API_V1}`,
  AARIN_API: IP3 + `${process.env.REACT_APP_005}`,
  locationApi: process.env.REACT_APP_LOCATION_API,
  emailApi: process.env.REACT_APP_EMAILAPI,
  PLUGNOTAS_API: process.env.REACT_APP_PLUGNOTAS_API,
  PLUGNOTAS_API_KEY: process.env.REACT_APP_PLUGNOTAS_API_KEY,
  DOCUSIGN_ACCOUNT_ID: process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID,
  DOCUSIGN_DEV_BASE_URL: process.env.REACT_APP_DOCUSIGN_DEV_BASE_URL,
  DOCUSIGN_CLIENT_ID: process.env.REACT_APP_DOCUSIGN_CLIENT_ID,
  MOVIDESK_KENLO :process.env.REACT_APP_SERVER_MOVIDESK
};
export { config };

// get token from localstorage
let id = 1;
if( localStorage.getItem("userData") && localStorage.getItem("userData") != null && localStorage.getItem("userData") != undefined ){
  const storedData: any = localStorage.getItem("userData");
  // const parsedData = JSON.parse(storedData);
}
export const secretKey = process.env.REACT_APP_SECRET_KEY;
const token = localStorage.getItem("tokenV2") ? localStorage.getItem("tokenV2") : "";
const Bearer = process.env.REACT_APP_BEARER; 

id = 1;
const gettoken: any = {
  Token: token,
  userId: id,
  BearerAuth: Bearer,
};
export { gettoken };