import React, { useEffect, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { FloatingLabel, InputGroup, Dropdown, Col, Row, Modal, Form, Button, Collapse, ButtonGroup, Alert } from 'react-bootstrap';
import InvoicesOverview from './InvoicesOverview';
import InvoicesListing from './InvoicesListing';
import NewServiceNote from './NewServiceNote';
import InvoicesFilters from './InvoicesFilters';
import eventBus from "../ReceivableCharges/EventBus";
import { useTranslation } from "react-i18next";
import { incomeService } from '../../../../services/incomeServices';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch } from 'react-redux';
import { getBraches } from '../../../../redux/slice/branchSlice';
import moment from "moment-timezone";
import Pagination from '../../../Pagination';
import { converter } from '../../../constants/utils';
import { useVerifyApprovedCity } from '../../../../services/hooks';
import { registerData } from '../../../../redux/slice/companyDataSlice';

// const [t] = useTranslation();

const ChargeInvoices = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    //New charges modal initialization
    const [showNewServiceNoteModal, setNewServiceNoteModal] = useState(false);
    const newServiceNoteModalClose = () => setNewServiceNoteModal(false);
    const newServiceNoteModalShow = () => setNewServiceNoteModal(true);

    //console.log("This is errrorrrrr");

    //Change issue date modal initialization
    const [showChangeIssueDateModal, setChangeIssueDateModal] = useState(false);
    const changeIssueDateModalClose = () => setChangeIssueDateModal(false);
    const changeIssueDateModalShow = () => setChangeIssueDateModal(true);

    //Change issue date modal initialization
    const [showResponsibleModal, setChangeResponsibleModal] = useState(false);
    const changeResponsibleModalClose = () => setChangeResponsibleModal(false);
    const changeResponsibleModalShow = () => setChangeResponsibleModal(true);

    //Invoice mobile filter modal initialization
    const [showMobileInvocieFilterModal, setMobileInvocieFilterModal] = useState(false);
    const mobileInvocieFilterModalClose = () => setMobileInvocieFilterModal(false);
    const mobileInvocieFilterModalShow = () => setMobileInvocieFilterModal(true);

    const { isCityApproved, verifyCity } = useVerifyApprovedCity()
    
    const { companydata } = useSelector((state: RootState) => state.companyData);

    const [data, setData] = useState('');
    const [filter, setFilter] = useState("");
    const [getInvioceList, setGetInvioceList] = useState<any>([])
    const [searchData, setSearchData] = useState<any>([])
    const [allCutomer, setallCustomer] = useState<any>([])
    const [totalValue, setTotalValue] = useState<number>()
    const [userstatus, setUserStatus] = useState("Status");
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [selectedBranches, setSelectedBranches] = useState<string[]>([]);
    const [totalRow, setTotalRow] = useState(0)
    const [cusomreFilter, setCusomreFilter] = useState('')
    const [serviceFilter, setserviceFilter] = useState('')
    const { serviceData } = useSelector((state: RootState) => state.contractData)
    const { branchList } = useSelector((state: RootState) => state.branch)
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M');
    let searchDt = moment(newDate)
    let endDt = moment(newDate).add(1, 'M');
    const [state, setState] = useState<any>({
        status: "all",
        startDate: startDt,
        endDate: endDt,
        searchDate: searchDt,
        search_type: "",
        start: 0,
        end: 20
    })
    const [branchState, setBranchState] = useState({
        start: "0",
        end: "100",
    });
    const parentToChild = () => {
        setData("");
    }
    const selectAllUsers = () => {
        let owner = { action: 'selectall' }
        setData(JSON.stringify(owner));
    }
    const unSelectAllUsers = () => {
        let owner = {
            action: 'unselectall'
        }
        setData(JSON.stringify(owner));
    }
    eventBus.on("couponApply", (data) => {
        let owner = {
            bankAccount: data.bankAccount,
            action: 'change_bank'
        }
        setData(JSON.stringify(owner));
        removeEvent();
    });
    const removeEvent = () => {
        eventBus.remove("couponApply", {});
    }
    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);
    let PageSize = state.end;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalpage, setTotalPage] = useState(0);
    useEffect(() => {
        if (totalRow) {
            let count: any = totalRow;
            setTotalPage(count)
        }
    }, [totalRow, currentPage,])

    //console.log(totalpage, "totalpage")
    useEffect(() => {
        state["start"] = (currentPage - 1) * state["end"];
        incomeService.getInvoce(state).then((res) => {
            if (res.resCode == 201) {
               if(res?.data?.InvoceResult){
                setGetInvioceList(res?.data?.InvoceResult)
                setTotalRow(res?.data.totalRow)
                setSearchData(res?.data?.InvoceResult)
                const totalValue = res?.data?.InvoceResult?.reduce((sum, item) => sum + item.VL_VALOR_NOT, 0);
                // Format the total value to have two decimal places
                const formattedTotal = totalValue.toFixed(2);
                setTotalValue(formattedTotal)
               }else{
                setTotalRow(0)
                setGetInvioceList([])
                setSearchData([])
               }
            }
        })
    }, [state, currentPage])


    useEffect(() => {
        (async () => {
            let GetClientData = await incomeService?.getClients();
            if(GetClientData?.data?.clients.length>0){
                setallCustomer(GetClientData.data.clients);
            }
        })();
    }, [])

    useEffect(() => {
      const { city, state } = companydata[0] ?? {}
  
      if (city && state) verifyCity(city, state)
    }, [companydata])

    //Getting the data shown when the page is rendered for the first time --registerData and getbranches
    useEffect(() => {
        dispatch(getBraches(branchState))
        dispatch(registerData())
    }, [dispatch])

    const InvoiceStatusis = (e: any) => {
        e.preventDefault();
        var action: any = "Status";
        action = e.currentTarget.getAttribute("data-action");

        var tname: any = e.currentTarget.getAttribute("data-name");
        // setCompositeFilter(action);
        // console.log('++++++++', action);
        if (action == "all") {
            tname = "Status";
        }
        setUserStatus(tname);
        if (action == 0) {
            let invoiceData: any = []
            incomeService.getInvoce(state).then((res) => {
                res?.data?.InvoceResult?.forEach((invoice) => {
                    if (invoice.FL_STATUS_NOT == action) {
                        invoiceData.push(invoice)
                    }
                });
                setGetInvioceList(invoiceData)
            })

        }
        if (action == -1) {
            let invoiceData: any = []
            incomeService.getInvoce(state).then((res) => {
                res?.data?.InvoceResult.forEach((invoice) => {
                    if (invoice.FL_STATUS_NOT == action) {
                        invoiceData.push(invoice)
                    }
                });
                setGetInvioceList(invoiceData)
            })

        }
    }
    const FilterCusto = (e: any, data) => {
        incomeService.getInvoce(state).then((res) => {
            let invoiceData: any = [];

            if (data === "customer") {
                if (e.target.value === "") {
                    // If the selected value is empty, show all items
                    setGetInvioceList(res.data);
                } else {
                    // Filter invoices based on the selected customer ID
                    res?.data?.InvoceResult?.forEach((invoice) => {
                        if (invoice.ID_SACADO_SAC == e.target.value) {
                            invoiceData.push(invoice);
                        }
                    });
                    setGetInvioceList(invoiceData);
                    var selectElement: any = document.querySelector('select[name="costomer"]');
                    var selectedIndex = selectElement?.selectedIndex;
                    var selectedOption = selectElement?.options[selectedIndex];
                    // Get the value of the data-action attribute
                    var dataAction: any = selectedOption?.getAttribute('label');
                    //console.log(dataAction)
                    setCusomreFilter(dataAction)
                }
            }

            if (data == "service") {
                if (e.target.value === "") {
                    // If the selected value is empty, show all items
                    setGetInvioceList(res.data.InvoceResult);
                } else {
                    var selectElement: any = document.querySelector('select[name="service"]');
                    var selectedIndex = selectElement?.selectedIndex;
                    var selectedOption = selectElement?.options[selectedIndex];
                    // Get the value of the data-action attribute
                    var dataAction: any = selectedOption?.getAttribute('label');
                    setserviceFilter(dataAction)
                    // Filter invoices based on the selected service ID
                    res?.data?.InvoceResult?.forEach((invoice) => {
                        if (invoice.ID_PRODUTO_PRD == e.target.value) {
                            invoiceData.push(invoice);
                        }
                    });
                    setGetInvioceList(invoiceData);
                }
            }
        });
    };
    const handleCheckboxChange = (branchId: any) => {
        if (branchId === -1) {
            setSelectAll(!selectAll);
            setSelectedBranches(selectAll ? [] : branchList && branchList.length > 0 && branchList?.map(branch => branch?.id));
        } else {
            const updatedSelectedBranches = selectedBranches.includes(branchId)
                ? selectedBranches.filter(branch => branch !== branchId)
                : [...selectedBranches, branchId];
            incomeService.getInvoce(state).then((res) => {
                if (res.resCode == 201) {
                    const filteredData = res?.data?.InvoceResult?.filter(entry => updatedSelectedBranches.includes(entry.ID_FILIAL_FIL));
                    setGetInvioceList(filteredData)
                }
            })

            setSelectedBranches(updatedSelectedBranches);
            if (updatedSelectedBranches?.length > 0) {
                setSelectAll(updatedSelectedBranches?.length === branchList?.length);
            }
        }
    };

    const HandleSearch = (e) => {
        if (e.target.value === "") {
            setGetInvioceList(searchData);
        } else {
            const filteredResult = searchData?.filter(item => {
                const lowerCaseFantasia = item.ST_FANTASIA_PES ? item.ST_FANTASIA_PES.toLowerCase() : '';
                return lowerCaseFantasia.includes(e.target.value.toLowerCase());
            });
            if (filteredResult.length > 0) {
                setGetInvioceList(filteredResult)
            } else {
                setGetInvioceList([])
            }
        }
        setFilter(e.target.value);
    }
    const filterReset = () => {
        setCusomreFilter('')
        setserviceFilter('')
        setUserStatus('Status')
        setSelectAll(false)
    }
    const filterCharge = (dt, tp) => {
        if (typeof tp !== "undefined") {
            let currentDate = new Date(dt);
            if (tp == "Subtract") {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            else {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            setState({
                ...state,
                ['startDate']: startDt,
                ['endDate']: endDt,
                ['searchDate']: searchDt,
                ['start']: 0
            });

        }
    }

    return (
        <section className="content-wrapper content-wrapper-primary">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-between me-xl-1">
                                <h1 className="mb-0 lh-base text-truncate">{t("Invoices.invoices")}</h1>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            {/* Search bar */}
                            <InputGroup>
                                <Form.Control placeholder={t("formLabel.typeSearch")} aria-label="Search" aria-describedby="search_icon"
                                    onChange={HandleSearch} />
                                <InputGroup.Text id="search_icon">
                                    <img src="./assets/images/icons/search.svg" alt="Search icon" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a className="fw-bold cursor-pe" onClick={newServiceNoteModalShow}>
                                        <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />
                                        {t("Invoices.newServiceNote")}
                                    </a>
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="moreDropdown">
                                            {t("more")}
                                            <img src="assets/images/icons/arrow-bottom-primary.svg" alt="Plus icon" className="ms-1 h-8" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="/">{t("Invoices.batches")}</Dropdown.Item>
                                            <Dropdown.Item href="/">{t("Invoices.series")}</Dropdown.Item>
                                            <Dropdown.Item href="/">{t("Invoices.Taxation")}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="morePrintAll">
                                            <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item>{t("Invoices.printThisScreen")}</Dropdown.Item>
                                            <Dropdown.Item>{t("Invoices.Taxation")}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </li>
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a href="#">
                                        <img src="assets/images/icons/settings-light.svg" alt="Settings" className="h-20" />
                                    </a>
                                </li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>
                {/* Filters start here */}
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">Filters</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                            <Col xl="auto">
                                <ButtonGroup className="btn-date-filter" size="sm" aria-label="Date">
                                    <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.startDate, 'Subtract')}>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white.svg"} className="h-24" />
                                        {/* {state.startDate} */}
                                    </Button>
                                    <Button variant="primary" className="btn-date capitalize" onClick={() => filterCharge(state.startDate, 'Subtract')} disabled>
                                        {state.searchDate.format('MMM/YYYY')}
                                    </Button>
                                    <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.endDate, 'Add')}>
                                        {/* {state.endDate} */}
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white-right.svg"} className="h-24" />
                                    </Button>
                                </ButtonGroup>
                            </Col>
                            <Col xl="auto">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm" className={`${userstatus !== "Status" ? "show" : ""}`}>
                                        {t(`${userstatus}`)}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="px-4">
                                        <Dropdown.Item
                                            href="#"
                                            data-action="0"
                                            data-name={t("Invoices.issued")}
                                            onClick={InvoiceStatusis}
                                        >
                                            {t("Invoices.issued")}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            href="#"
                                            data-action="-1"
                                            data-name={t("Invoices.canceled")}
                                            onClick={InvoiceStatusis}
                                        >
                                            {t("Invoices.canceled")}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            {/* <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm">
                                    Shipping
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="px-4">
                                    <div className="py-2">
                                        All
                                    </div>
                                    <div className="py-2">
                                        Unmarked
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col> */}
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`${cusomreFilter ? "show" : ""}`}>
                                        {cusomreFilter ? cusomreFilter : t("Invoices.customers")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("Invoices.customers")}>
                                            <Form.Select aria-label="Customers" name="costomer" onChange={(e) => FilterCusto(e, "customer")}>
                                                <option value="">{t('dropdownItem.select')}..</option>
                                                {allCutomer && allCutomer.length > 0 &&
                                                    allCutomer?.map((item, index) => (
                                                        <option key={index} value={item.ID_SACADO_SAC} label={item.ST_NOME_SAC}>{item.ST_NOME_SAC}</option>
                                                    ))}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            {/* <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm">
                                        Groups
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label="Groups">
                                            <Form.Select aria-label="Groups">
                                                <option value="">{t("formLabel.select")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col> */}
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`${serviceFilter ? "show" : ""}`}>
                                        {serviceFilter ? serviceFilter : t("taxation.services")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("taxation.services")}>
                                            <Form.Select
                                                className="form-control"
                                                name="service"
                                                onChange={(e) => FilterCusto(e, "service")}>
                                                <option value="">{t('dropdownItem.select')}..</option>
                                                {serviceData && serviceData.length > 0 &&
                                                    serviceData?.map((item, i) => (
                                                        <option key={i} value={item.id} label={item.name}>{item.name}</option>
                                                    ))}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={selectAll ? "show" : ""}>
                                        {t("branches.branches")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4 pt-4" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                        <Form.Check
                                            type="checkbox"
                                            id="filter_branches_1"

                                            className="mb-3"
                                            label={t("formLabel.selectAll")}
                                            checked={selectAll}
                                            onChange={() => handleCheckboxChange(-1)}
                                        />
                                        {branchList && branchList.length > 0 &&
                                            branchList.map((branch, index) => (
                                                <Form.Check
                                                    key={index}
                                                    type="checkbox"
                                                    id={`filter_branches_${index + 2}`}
                                                    className="mb-3"
                                                    label={branch.name}
                                                    checked={selectedBranches.includes(branch.id)}
                                                    onChange={() => handleCheckboxChange(branch.id)}
                                                />
                                            ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            {cusomreFilter || serviceFilter || userstatus !== "Status" || selectAll ?
                                <Col xl="auto">
                                    <Dropdown autoClose="outside" className="moreFilter">
                                        <Dropdown.Toggle variant="light" size="sm" className="d-flex align-items-center" onClick={filterReset}>
                                            {t("button.resetfilter")}
                                        </Dropdown.Toggle>
                                    </Dropdown>

                                </Col> :
                                ""}
                        </Row>
                    </Form>
                </Collapse>
                {/* /Filters end */}
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {isCityApproved === false && (
                    <Alert variant="danger" className="mb-lg-4 mb-3">
                        {t("taxation.danger")}
                    </Alert>
                )}
                {/* <!-- overview -->*/}
                <InvoicesOverview getInvioceList={getInvioceList} totalRow={totalRow} />
                {/* <!-- /overview -->*/}
                {/*<!-- Table start from here -->*/}
                <InvoicesListing params={data} getInvioceList={getInvioceList} />
                {totalRow ?
                    <div className="d-flex justify-content-center mt-lg-4 mt-3">
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={totalpage}
                            pageSize={PageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                    : ""}

                {/*<!-- /Table end -->*/}
                <p className="my-lg-4 my-3">{t("formLabel.listing")} {totalRow} {t("Invoices.notesFrom")} {getInvioceList.length} {t("Invoices.customers")} {t("comparetolastmonth.total")} R$ {getInvioceList.length > 0 ? converter(String(totalValue))  : 0}</p>
                {/* <!-- Nav listing start from here -->*/}
                <ul className="list-inline mb-0">
                    {/* <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Dropdown>
                            <Dropdown.Toggle variant="light" size="sm">
                                Mark
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={selectAllUsers}>{t("all")}</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={unSelectAllUsers}>None</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li> */}
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"> <span className="px-1">{t("Invoices.withtagged")}:</span></li>
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Button variant="light" size="sm">{t("Invoices.NFEDialtoTransmit")}</Button>
                    </li>
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Button variant="light" size="sm">{t("Invoices.unmarkfromtransmission")}</Button>
                    </li>
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Button variant="light" size="sm">{t("button.cancel")}</Button>
                    </li>
                    <li className="list-inline-item mb-lg-0 mb-1">
                        <Dropdown drop="up">
                            <Dropdown.Toggle variant="light" size="sm">
                                {t("more")}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="mh-456 overflow-auto">
                                <Dropdown.Item>{t("Invoices.sendbyemail")}</Dropdown.Item>
                                <Dropdown.Item>{t("Invoices.printinvoicemirror")}</Dropdown.Item>
                                <Dropdown.Item onClick={changeIssueDateModalShow}>{t("Invoices.changeissuedate")}</Dropdown.Item>
                                <Dropdown.Item onClick={changeResponsibleModalShow}>{t("Invoices.changeresponsiblecompany")}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </ul>
                {/*<!-- /Nav listing  end -->*/}
            </main>
            {/*<!-- /Main content end -->*/}
            {/* Change issue date modal start from here */}
            <Modal show={showChangeIssueDateModal} onHide={changeIssueDateModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Invoices.changeissuedate")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="issuance" label="Issuance">
                            <Form.Control type="date" placeholder="Issuance" />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={changeIssueDateModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={changeIssueDateModalClose}>
                            {t("button.toSave")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Change issue date modal end */}
            {/* Change the company responsible modal start from here */}
            <Modal show={showResponsibleModal} onHide={changeResponsibleModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Invoices.changethecompanyresponsible")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="company" label="Company">
                            <Form.Select>
                                <option value="1">{t("Invoices.headquarters")}</option>
                                <option value="2">{t("Invoices.laercioJoseIlkiu")}</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={changeResponsibleModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={changeResponsibleModalClose}>
                            {t("button.toSave")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Change the company responsible modal end */}
            <NewServiceNote showServiceNote={showNewServiceNoteModal} serviceNoteShow={newServiceNoteModalShow} serviceNoteClose={newServiceNoteModalClose} setGetInvioceList={setGetInvioceList} state={state} setState={setState} />

        </section>
    )
}

export default ChargeInvoices
