import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { brokerClose, propertyOpen, insertBroker } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getBroker } from "../../../../redux/slice/propertyDataSlice";
import { useTranslation } from 'react-i18next';
import { ownerForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
import { openErrorbox } from './actionPerform';
import { getAllBillMethodListing } from '../../../../redux/slice/expenditureDataSlice';
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF } from '../../../constants/utils'; 
const BrokerService = (ownerForm) => {
    const dispatch = useDispatch<AppDispatch>()
    const { billingData } = useSelector((state: RootState) => state.expenditureData)
    useEffect(() => {
        if (newBrokerShow) {
            dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
        }
    }, [dispatch]);

    const [double, setDouble] = useState(false);
    const { id } = useParams();
    const [t] = useTranslation();
    const { newBrokerShow } = useSelector((state: RootState) => state.contractData)
    const [hide, setHide] = useState(true);
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const [hide4, setHide4] = useState(true);
    /*------------History modal hide function-----------*/
    const newBrokerClose = () => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        dispatch(propertyOpen(true));
        dispatch(brokerClose(false));
        setHide2(false);
        setHide3(false);
        setHide(true);
        setDouble(false);
        reset();
    }

    /*------------Form validation-----------*/
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const condosValidation1 = Yup.object().shape({
        cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    });
    const condosValidation2 = Yup.object().shape({
        name: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        payment: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        mobile: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
        telephone: Yup.string().trim().nullable()
            .required(t("requiredMmessage.fieldRequired"))
            .matches(/\(\d{2}\) \d{4}-\d{4}/, `${t("requiredMmessage.TelephoneError")}`),
        email: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .email(t('requiredMmessage.fieldNotValid'))
    });
    let frm = {
        resolver: yupResolver(condosValidation1)
    }
    if (hide2) {
        frm = {
            resolver: yupResolver(condosValidation2)
        }
        openErrorbox();
    }

    /*------------Submit history form data-----------*/

    const { register, reset, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<ownerForm>(frm);
    const [cnpjcpferrormessage, setcnpjcpferrormessage] = useState({});
    const step1 = () => { setHide(true); setHide2(false); setHide3(false); }
    const step2 = (data: ownerForm) => {
        if (errors?.cnpj?.message) {
            setError(cnpjcpferrormessage?.["name"], {
                type: "manual",
                message: cnpjcpferrormessage?.["message"]
            })
            setHide(true);
        } else {
            setHide(false); setHide2(true); setHide3(false);
        }
    }
    const step3 = (data: ownerForm) => { setHide(false); setHide2(false); setHide3(true); }
    const onSubmit = async (data: ownerForm, e: any) => {
        e.preventDefault();
        setDouble(true)
        let response = await dispatch(insertBroker(data));
        if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
            setDouble(false);
            Swal.fire({
            // icon:"success",
            title:t("Sweetalert.Success"),
            text:response.payload.message,
            confirmButtonText:"OK",
            timer:2000})
            dispatch(getBroker())
            newBrokerClose();
        }
        else {
            setDouble(false)
            Swal.fire({
            title:t("Sweetalert.Error"),
            text:response.payload.message,
            // icon:"error",
            confirmButtonText:"OK",
            timer:2000})
        }
    }
    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors('telephone')
        }
    }
    const HandleInputMob = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("mobile", {
                type: "manual",
                message: `${t("requiredMmessage.mobileError")}`
            });
        } else {
            clearErrors('mobile');
        }
    }

    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name)
            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
                if (duplicateData["data"] > 0) {
                    zipCodeCheck?.["setAttribute"]('disabled', "")
                    setError(name, {
                        type: "manual",
                        message: t("formLabel.ErrorCNPJCPF")
                    });
                } else {
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            // errorCNPJCPF(e.target.value)
            setcnpjcpferrormessage({
                name: name,
                message: checkCNJP?.["message"]
            })
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    return (
        <>
            {/* <!-- New branch modal start from here --> */}
            <Modal show={newBrokerShow} onHide={newBrokerClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New broker</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <div data-step="1" style={{ display: hide ? "block" : "none" }}>
                            <FloatingLabel controlId="floatingInput" label={t('formLabel.cnpjCpf') + " *"}>
                                <Form.Control type="text" placeholder={t('formLabel.cnpjCpf')} className={`${errors.cnpj ? 'is-invalid' : ''}`}  {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                                <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </div>
                        <div data-step="2" style={{ display: hide2 ? "block" : "none" }}>
                            <FloatingLabel controlId="floatingInput" label={t('formLabel.name') + " *"} className="mb-3" >
                                <Form.Control type="text" placeholder={t('formLabel.name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`}  {...register('name')} />
                                <div className="invalid-feedback">{errors.name?.message}</div>
                            </FloatingLabel>
                            <span className="d-block mb-3 fw-bold">{t('formLabel.payment')}</span>
                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.defaultPaymentMethod')}>
                                <Form.Select className={`form-control ${errors.payment ? 'is-invalid' : ''}`}  {...register('payment')} aria-label={t('formLabel.defaultPaymentMethod')}>
                                    <option value="">{t('dropdownItem.select')}</option>
                                    {/* {
                                        billingData.data && billingData.data.length > 0 ? billingData.data.map((item, index) => (
                                            <option key={index} value={Number(item.id)}>{item.name}</option>
                                        ))
                                            : ''
                                    } */}
                                    <option value="1">{t("defaultPaymentMethod1.chequedeposit")}</option>
                                    <option value="2">{t("defaultPaymentMethod1.cashdeposit")}</option>
                                    <option value="3">{t("defaultPaymentMethod1.pickupchequeonsite")}</option>
                                    <option value="4">{t("defaultPaymentMethod1.withdrawmoneyonthespot")}</option>
                                    <option value="5">{t("defaultPaymentMethod1.banktransfer")}</option>
                                    <option value="6">{t("defaultPaymentMethod1.docted")}</option>
                                    <option value="12">{t("defaultPaymentMethod1.pixboleto")}</option>
                                </Form.Select>
                                <div className="invalid-feedback">{errors.payment?.message}</div>
                            </FloatingLabel>
                            <span className="d-block mb-3 fw-bold">{t('formLabel.contact')}</span>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                        <Form.Control type="text" placeholder={t('formLabel.mobileNumber')} className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}  {...register('mobile')} onChange={HandleInputMob} />
                                        <div className="invalid-feedback">{errors.mobile?.message}</div>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                        <Form.Control type="text" placeholder={t('formLabel.telephone')} className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}  {...register('telephone')} onChange={HandleInput} />
                                        <div className="invalid-feedback">{errors.telephone?.message}</div>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                <Form.Control type="email" placeholder={t('formLabel.email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`}  {...register('email')} />
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            </FloatingLabel>
                            <Accordion className="accordion-custom">
                                <Accordion.Item eventKey="1" style={{ display: hide4 ? 'none' : 'block' }}>
                                    <Accordion.Header>{t('formLabel.affiliation')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.motherName')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.motherName')}  {...register('mother')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.fatherName')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.fatherName')}  {...register('father')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>{t('formLabel.moreFields')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.rg')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.rg')}  {...register('rg')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.issuingAgency')}  {...register('issue_agency')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.shippingDate')}>
                                                    <Form.Control type="date" placeholder={t('formLabel.shippingDate')}  {...register('shipping_date')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.sex')}>
                                                    <Form.Select {...register('sex')} aria-label={t('formLabel.sex')}>
                                                        <option value="">{t('dropdownItem.select')}..</option>
                                                        <option value="1">{t('masculine')}</option>
                                                        <option value="2">{t('feminine')} </option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.birth')}>
                                                    <Form.Control type="date" placeholder={t('formLabel.birth')}  {...register('birth')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.maritalStatus')}>
                                                    <Form.Select {...register('marital_status')} aria-label={t('formLabel.maritalStatus')}>
                                                        <option value="">{t('formLabel.select')}</option>
                                                        <option value="1">{t("formLabel.married")}</option>
                                                        <option value="2">{t("formLabel.single")}</option>
                                                        <option value="3">{t("formLabel.widower")}</option>
                                                        <option value="4">{t("formLabel.legallySeparated")}</option>
                                                        <option value="5">{t("formLabel.disquitado")}</option>
                                                        <option value="6">{t("formLabel.divorced")}</option>
                                                        <option value="7">{t("formLabel.stableunion")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.nationality')}>
                                            <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register('nationality')} />
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" style={{ display: hide4 ? 'none' : 'block' }}>
                                    <Accordion.Header>{t('formLabel.address')} </Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')} >
                                            <Form.Control type="text" placeholder={t('formLabel.zipCode')}   {...register('zipcode')} />
                                        </FloatingLabel>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('address')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.number')}  {...register('number')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('complement')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('neighborhood')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('city')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                    <Form.Select {...register('state')} aria-label={t('formLabel.state')}>
                                                        <option value="">{t('dropdownItem.select')}..</option>
                                                        <option value="1">B.C</option>
                                                        <option value="2">AL</option>
                                                        <option value="3">AM</option>
                                                        <option value="4">AP</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4" style={{ display: hide4 ? 'none' : 'block' }}>
                                    <Accordion.Header>{t('formLabel.employmentData')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.company')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.company')}  {...register('employment_company')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.profession')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.profession')} {...register('employment_profession')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.fieldOfActivity')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.fieldOfActivity')}  {...register('employment_field_activity')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.monthlyIncome')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.monthlyIncome')}  {...register('employment_monthly_income')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.zipCode')}  {...register('employment_zipcode')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('employment_address')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.number')}  {...register('employment_number')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('employment_complement')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('employment_neighborhood')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('employment_city')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                    <Form.Select {...register('employment_state')} aria-label={t('formLabel.state')}>
                                                        <option value="">{t('dropdownItem.select')}..</option>
                                                        <option value="1">B.C</option>
                                                        <option value="2">AL</option>
                                                        <option value="3">AM</option>
                                                        <option value="4">AP</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5" style={{ display: hide4 ? 'none' : 'block' }}>
                                    <Accordion.Header>{t('formLabel.representative')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col xs={10}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.name')}  {...register('representative_name')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <span className="input-group-text">
                                                    <svg className="cursor-pe" width="18" height="20" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.45 21.5995C21.45 22.0965 21.047 22.4995 20.55 22.4995H12.8457C12.4964 23.0525 12.0768 23.5567 11.5995 23.9995H20.55C21.876 23.9995 22.95 22.9255 22.95 21.5995V9.39451C22.95 8.75751 22.697 8.14751 22.247 7.69751L15.253 0.703512C15.201 0.651477 15.1432 0.607405 15.0858 0.563575C15.0671 0.549284 15.0484 0.535019 15.03 0.520512C14.734 0.287512 14.394 0.120512 14.026 0.0465118C13.872 0.0155118 13.715 -0.000488281 13.555 -0.000488281H5.4C4.075 -0.000488281 3 1.07451 3 2.40051V11.865C3.47237 11.6153 3.97463 11.4146 4.5 11.2696V2.40051C4.5 1.90351 4.904 1.49951 5.4 1.49951H12.95V7.99951C12.95 9.10451 13.846 9.99951 14.95 9.99951L21.45 9.99851V21.5995ZM14.45 2.02251L20.928 8.49851L14.95 8.49951C14.77 8.49951 14.651 8.40851 14.596 8.35351C14.542 8.29851 14.45 8.17951 14.45 7.99951V2.02251ZM13 18.4999C13 22.0898 10.0899 24.9999 6.5 24.9999C2.91015 24.9999 0 22.0898 0 18.4999C0 14.9101 2.91015 11.9999 6.5 11.9999C10.0899 11.9999 13 14.9101 13 18.4999ZM7 14.4999C7 14.2238 6.77614 13.9999 6.5 13.9999C6.22386 13.9999 6 14.2238 6 14.4999V17.9999H2.5C2.22386 17.9999 2 18.2238 2 18.4999C2 18.7761 2.22386 18.9999 2.5 18.9999H6L6 22.4999C6 22.7761 6.22386 22.9999 6.5 22.9999C6.77614 22.9999 7 22.7761 7 22.4999V18.9999H10.5C10.7761 18.9999 11 18.7761 11 18.4999C11 18.2238 10.7761 17.9999 10.5 17.9999H7V14.4999Z" fill="#242424"></path>
                                                    </svg>
                                                </span>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6" style={{ display: hide4 ? 'none' : 'block' }}>
                                    <Accordion.Header>{t('formLabel.taxesRetention')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Check type="checkbox" {...register('retention')} label={t('formLabel.withHoldIssqn')} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>{t('formLabel.observation')}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                                            <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('observation')} style={{ height: '100px' }} />
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div data-step="3" style={{ display: hide3 ? "block" : "none" }}>
                            <div className="text-center">
                                <svg width="56" height="73" viewBox="0 0 36 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.8438 2.86996C19.2714 1.70492 20.5795 0.49992 22.2274 0.988579C23.878 1.47804 24.9525 2.65667 25.5907 4.08561C26.2145 5.48204 26.4481 7.16097 26.4619 8.84995C26.484 11.5692 25.9361 14.5598 25.2216 17.0001H30.2618C33.7238 17.0001 36.238 20.2923 35.3266 23.6322L31.993 35.8489C30.6778 40.6688 25.7729 43.5733 20.9143 42.4094L6.84425 39.0387C4.28242 38.425 2.25507 36.47 1.54875 33.9321L0.315141 29.4997C-0.443623 26.7734 1.1056 24.083 3.51969 22.9752C9.84431 20.073 13.2274 15.8477 15.8774 10.4493C16.7172 8.7386 17.3316 7.04024 18.166 4.73372C18.3757 4.15415 18.5993 3.53617 18.8438 2.86996ZM21.4273 3.41376C21.3468 3.46537 21.2479 3.5756 21.1907 3.73134C20.9596 4.36108 20.7444 4.9563 20.5393 5.52362C19.6984 7.8494 19.0271 9.7064 18.1216 11.551C15.291 17.3172 11.5437 22.0438 4.56235 25.2474C3.10287 25.9171 2.33964 27.4498 2.7236 28.8294L3.95721 33.2618C4.41997 34.9245 5.74824 36.2054 7.42668 36.6075L21.4967 39.9781C25.0422 40.8275 28.6214 38.708 29.5811 35.1908L32.9148 22.9741C33.3922 21.2246 32.0752 19.5001 30.2618 19.5001H23.4995C23.0917 19.5001 22.7096 19.3013 22.4757 18.9673C22.2417 18.6333 22.1854 18.2062 22.3248 17.823C23.2092 15.3909 23.9865 11.8878 23.9619 8.87027C23.9497 7.35891 23.7363 6.06392 23.3081 5.10519C22.8944 4.17897 22.3119 3.62122 21.5167 3.38542C21.5044 3.38176 21.5 3.38242 21.4951 3.38324C21.4873 3.38457 21.4639 3.39031 21.4273 3.41376Z" fill="#F82E52" />
                                </svg>
                                <h3 className="mt-3 mb-2">{t('label.registeredSurveyor')}</h3>
                                <button type="button" className="btn btn-light btn-sm mb-2 px-4">{t('label.inviteToApp')}</button>
                                <p className="text-secondary">{t('label.checkAvailability')}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-sm-flex justify-content-between w-100 align-items-center">
                            <div className="me-sm-2 mb-sm-0 mb-2 d-flex"></div>
                            <div className="flex-shrink-0">
                                <Button variant="outline-primary" className="btn btn-outline-primary rounded-4" onClick={newBrokerClose}>
                                    {t('button.close')}
                                </Button>
                                <Button variant="outline-primary" onClick={handleSubmit(hide2 ? step1 : hide3 ? step2 : step1)} className="btn btn-outline-primary js-btn-step">
                                    {t('button.previous')}
                                </Button>
                                {!hide2 ?
                                    <Button variant="primary" onClick={handleSubmit(hide ? step2 : step3)} className="btn btn-primary min-w-auto js-btn-step zipCodeCheck">
                                        {t('button.next')}
                                    </Button>
                                    :
                                    <Button disabled={double} variant="primary" type="submit" onClick={openErrorbox} >
                                        {t('button.save')}
                                    </Button>
                                }
                            </div>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* <!-- /New branch modal end --> */}
        </>
    )
}
export default BrokerService;