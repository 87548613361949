import { useEffect, useState } from "react"

import axios from "axios"

let nfseAPI = require("../../Api/Contact").config.NFSE_API;
let nfseTOKEN = require("../../Api/Contact").config.PLUGNOTAS_API_KEY;

export const useVerifyApprovedCity = () => {
  const [cities, setCities] = useState<any[]>([])
  const [isCityApproved, setApproval] = useState<boolean | undefined>()

  useEffect(() => {
    const getCities = async () => {
      const response = await axios({
        method: 'get',
        headers: {
          "X-API-KEY": nfseTOKEN,
          "Content-type": "application/json"
        },
        url: `${nfseAPI}/cidades`
      });

      setCities(response?.data ?? [])
    }

    getCities()
  }, [])

  const verifyCity = (city = '', uf = '') => {
    const name = city.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replaceAll(' ', '-')
    const state = uf.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replaceAll(' ', '-')

    const foundCity = cities.find((item) => {
      const nome = item.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replaceAll(' ', '-')
      const estado = item.uf.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replaceAll(' ', '-')

      return nome === name && estado === state
    })

    setApproval(!!foundCity)
  }

  return { isCityApproved, verifyCity }
}