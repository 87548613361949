import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { condosOpen, brokerOpen, administratorOpen, propertyClose, contractOpen, insertProperty, getProperty, getAllProperty, openNewOwner, setTenantId, managerOpen, contractClose } from "../../../../redux/slice/contractDataSlice";
import { getManager, getAdministrators, getCondos, getBroker, getOwner, registerData } from "../../../../redux/slice/propertyDataSlice";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { contractPropertyForm } from '../../../Interface';
import Swal from 'sweetalert2';
import { getBraches } from '../../../../redux/slice/branchSlice';
import AdministratorService from '../Common/AdministratorService';
import CondosService from '../Common/CondosService';
import OwnerService from '../Common/OwnerService';
import BrokerService from '../Common/BrokerService';
import { numericOnly, openErrorbox } from './actionPerform';
import customerService from '../../../../services/customerService';
import '../../../../App.css';
import NewOwner from '../Customer_and_employees/NewOwner';
import { registerDatacomapny } from '../../../../redux/slice/companyDataSlice';
import { converter, convertToNumber, formatNumber, formatNumberEnglish, generateRandomString } from '../../../constants/utils';
import moment from "moment-timezone";
import ManagerService from './ManagerService';
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

const PropertyService = (props) => {
    let { singlePropertyId } = props
    const { managerData, administData, condoData, brokerData, ownerData, status } = useSelector((state: RootState) => state.propertyData)
    const [isMonthlyAdminRatePercentage, setMonthlyAdminRatePercentage] = useState(false);
    const [isLeaseFeePercentage, setLeaseFeePercentage] = useState(false);
    const [fixedday, setfixeday] = useState(false)
    const [double, setDouble] = useState(false);
    const [gurnty, setGurnty] = useState(true);
    const { newPropertyShow } = useSelector((state: RootState) => state.contractData)
    const { branchList } = useSelector((state: RootState) => state.branch)
    const [branchState, setBranchState] = useState({
        start: "0",
        end: "100",
    });
    const dispatch = useDispatch<AppDispatch>();
    const [t] = useTranslation();

    const newPropertyClose = () => {
        dispatch(propertyClose(false))
        dispatch(contractOpen(true));
        setDouble(false);
        setTnsfr(false);
        reset();
        setMultipleOwner([{
            value: "",
            label: "",
            main: "-1",
            percentage: "100"
        }])
        generatrindetifier()
    }

    const newAdministratorOpen = () => {
        dispatch(administratorOpen(true))
        dispatch(propertyClose(false))
    }

    const newCondosOpen = () => {
        dispatch(condosOpen(true))
        dispatch(propertyClose(false))
    }

    const newOwnerOpen = () => {
        dispatch(openNewOwner(true))
        dispatch(propertyClose(false))
    }

    const newBrokerOpen = () => {
        dispatch(brokerOpen(true))
        dispatch(propertyClose(false))
    }

    useEffect(() => {
        if (newPropertyShow) {
            dispatch(getBraches(branchState));
            dispatch(getManager());
            dispatch(getAdministrators())
            dispatch(getCondos())
            dispatch(getBroker())
            dispatch(getOwner())
        }
    }, [dispatch, newPropertyShow])


    const generatrindetifier = () => {
        // Get the current timestamp in milliseconds
        const timestamp = Date.now();
        // Convert the timestamp to a string
        const timestampStr = timestamp.toString();
        // Get the last 7 digits of the timestamp
        const last7Digits = timestampStr.slice(-7);
        setValue("property_identifier", last7Digits)

    }
    const [multipleOwner, setMultipleOwner] = useState<any>([
        {
            value: "",
            label: "",
            main: "-1",
            percentage: "100",
        }
    ])
    const [multipleHobby, setmMultipleHobby] = useState([{
        huby_identification: "",
        huby_observation: "",
    }])
    const propertyValidation = Yup.object().shape({
        rent_amount: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        monthly_admin_rate: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        property_type: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        zipcode: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        address: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')).max(40, t("requiredMmessage.40characters")),
        number: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        neighborhood: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        city: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        state: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        property_identifier: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        admin_rate: Yup.string().trim().nullable(),
        // owner: Yup.array().of(
        //     Yup.object().shape({
        //         name: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        //         main_owner: Yup.string().nullable().required(t("requiredMmessage.fieldRequired")),
        //         percentage_on_receipt: Yup.string().nullable().required(t("requiredMmessage.fieldRequired")),
        //     })
        // ).nullable().required(t("requiredMmessage.fieldRequired")),
    });

    // const validationOwner = () => {
    //     let vaid = true;
    //     multipleOwner.forEach((item, indexs) => {
    //         let name = `owner.${indexs}.name`;
    //         let percent = `owner.${indexs}.percentage_on_receipt`;
    //         let ownerBene: any = document.querySelector('.ownersAndBeneficiariestsx  button');
    //         let ownerSelect: any = document.querySelector('.owner [name="' + name + '"]');
    //         let percentSelect: any = document.querySelector('.percentage [name="' + percent + '"]');
    //         if (!item.value && multipleOwner.length == 1 && ownerSelect != null) {
    //             ownerSelect.style.border = "1px solid red";
    //             ownerBene.style.color = "red";
    //             vaid = false;
    //         }
    //         else if (!item.value && multipleOwner.length > 1 && ownerSelect != null) {
    //             ownerSelect.style.border = "1px solid red";
    //             ownerBene.style.color = "red";
    //             vaid = false;
    //         }
    //         else if (!item.percentage && multipleOwner.length > 1 && percentSelect != null) {
    //             percentSelect.style.border = "1px solid red";
    //             ownerBene.style.color = "red";
    //             vaid = false;
    //         }
    //         else {
    //             if (percentSelect != null) {
    //                 percentSelect.style.border = "0px solid red";
    //                 if (ownerBene != null) {
    //                     ownerBene.style.color = "";
    //                 }
    //             }
    //             if (ownerSelect != null) {
    //                 if (ownerBene != null) {
    //                     ownerBene.style.color = "";
    //                 }
    //                 ownerSelect.style.border = "0px solid red";
    //             }
    //         }
    //     })
    //     return vaid;
    // }

    const validationOwner = () => {
        let vaid = true;
        multipleOwner.forEach((item: any, indexs) => {
            let name: any = `owner.${indexs}.name`;
            let percent: any = `owner.${indexs}.percentage_on_receipt`;
            let mainOwner: any = `owner.${indexs}.main_owner`;

            let ownerBene: any = document.querySelector('.ownersAndBeneficiariestsx  button');
            if (!item.value && multipleOwner.length >= 1) {
                setError(name, {
                    type: "manual",
                    message: t("requiredMmessage.fieldRequired"),
                });
                vaid = false;
            }
            if (!item.percentage && multipleOwner.length >= 1) {
                setError(percent, {
                    type: "manual",
                    message: t("requiredMmessage.fieldRequired"),
                });
                vaid = false;
            }
            if (!item.main && multipleOwner.length >= 1) {
                setError(mainOwner, {
                    type: "manual",
                    message: t("requiredMmessage.fieldRequired"),
                });
                vaid = false;
            }
        })
        return vaid;
    }

    const { register, reset, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors } = useForm<contractPropertyForm>({ resolver: yupResolver(propertyValidation) });

    const [percentornot, setPercentornot] = useState({
        lease_percent: true,
        monthly_percent: true
    })

    useEffect(() => {
        if (singlePropertyId !== "" && singlePropertyId !== undefined) {
            singalProperty(singlePropertyId)
        }
    }, [singlePropertyId])

    const handleCheckboxChange = (e, setState) => {
        const isChecked = e.target.checked;
        const nameTarget = e.target.name;
        let pickValue: any = e.target.parentElement.parentElement.parentElement;
        let valueofTarget: any = "";
        if (nameTarget == "monthly_admin_rate_percentage") {
            valueofTarget = pickValue.querySelector('[name=monthly_admin_rate]');
            if (!isChecked) {
                if (Number(valueofTarget.value) > 100) {
                    setValue("monthly_admin_rate", "100");
                }
                setPercentornot({
                    ...percentornot,
                    ["monthly_percent"]: true
                })
            } else {
                setPercentornot({
                    ...percentornot,
                    ["monthly_percent"]: false
                })
            }
        }
        if (nameTarget == "lease_fee_percentage") {
            valueofTarget = pickValue.querySelector(`[name=lease_fee]`);
            if (!isChecked) {
                if (Number(valueofTarget.value) > 100) {
                    setValue("lease_fee", "100");
                }
                setPercentornot({
                    ...percentornot,
                    ["lease_percent"]: true
                })
            } else {
                setPercentornot({
                    ...percentornot,
                    ["lease_percent"]: false
                })
            }
        }
        setState(isChecked);
    };

    const [use_real_state, setuse_real_state] = useState(false);
    const [owned_properties, setowned_properties] = useState(false);
    const [ownerDDta, setownerDDta] = useState<any>([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const [companyData, setCompanydtaa] = useState<any>({ payload: { data: [] } });

    useEffect(() => {
        (async () => {
            let data: any = await dispatch(registerDatacomapny());
            setCompanydtaa(data);
        })()
    }, [])

    const ownerActionproperties = async (e: any, setState: any) => {
        const isChecked = e.target.checked;
        const nameTarget = e.target.name;
        setState(isChecked);
        if (isChecked == false) {
            setuse_real_state(false);
            setValue("use_real_state", false);
            setValue("owner.0.name", "");
            setownerDDta([]);
        } else {
            setValue("owner.0.percentage_on_receipt", "100");
            if (companyData?.["payload"]?.["data"].length > 0) {
                setValue("owned_properties", false);
                if (companyData?.["payload"]?.type == "Success") {
                    let companyData1 = companyData["payload"]["data"][0];
                    let { email, cnpj } = companyData1;
                    let ownerdatacompany: any = ownerData.filter(item => item["cnpj"] == cnpj);
                    setTimeout(() => {
                        setValue("owner.0.name", ownerdatacompany[0]?.id);
                        setMultipleOwner([{
                            value: ownerdatacompany[0]?.id ? ownerdatacompany[0]?.id : "",
                            label: ownerdatacompany[0]?.name ? ownerdatacompany[0]?.name : ownerdatacompany[0]?.fantasy ? ownerdatacompany[0]?.fantasy : "",
                            main: "-1",
                            percentage: "100",
                        }])
                    }, 900);


                    setownerDDta(ownerdatacompany);
                }
            }
        }
    };
    const handleInputChange = (e: any, fieldName: any, isPercentage: any) => {
        let inputValue = e.target.value;
        if (!isPercentage) {
            // Remove non-numeric characters except comma and allow backspace
            let filteredValue = inputValue.replace(/[^\d,]|(?<=,)[$,.]/g, '');
            // Replace all commas with dots to maintain numeric value
            const normalizedValue = filteredValue.replace(/,/g, '.');
            // Check if the input is a valid number with up to two decimal places
            let isValid = /^\d*\.?\d{0,2}$/.test(normalizedValue);
            if (isValid) {
                // Ensure maximum value is 100.0
                if (parseFloat(normalizedValue) > 100) {
                    filteredValue = '100,0';
                } else {
                    // Format to two decimal places if less than 100
                    filteredValue = normalizedValue.replace('.', ',');
                }
                inputValue = filteredValue

            }
        } else if (isPercentage) {
            let { value } = e.target;
            value = value.replace(/[^\d,]/g, '');
            if (value !== '') {
                inputValue = formatNumber(value);
            }
        }
        setValue(fieldName, inputValue);
    };
    useEffect(() => {
        if (newPropertyShow) {
            const fetchData = async () => {
                let identifierData = getValues('property_identifier');
                let data = {
                    identifier: identifierData
                }
                let responseidentifier = await customerService.checkPropertyIdentifier(data);
                if (responseidentifier.data.type == "success") {
                    // Get the current timestamp in milliseconds
                    const timestamp = Date.now();
                    // Convert the timestamp to a string
                    const timestampStr = timestamp.toString();
                    // Get the last 7 digits of the timestamp
                    const last7Digits = timestampStr.slice(-7);
                    setValue("property_identifier", last7Digits)
                } else {
                    generatrindetifier()

                }
            };
            fetchData(); // Call the async function
        }
    }, [newPropertyShow]);

    function removeBlankEntries(data) {
        return data.filter(entry => Object.values(entry).some(value => value !== ""));
    }

    const onSubmit = async (data: contractPropertyForm, e: any) => {
        e.preventDefault();
        let valid = validationOwner();
        if (!valid) {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Ownerarerequired"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
        else {
            const cleanedData = removeBlankEntries(multipleHobby);
            // Printing the cleaned data
            // if (multipleOwner.length == 1) {
            //     multipleOwner[0]["main"] = "-1";
            //     multipleOwner[0]["percentage"] = "100";
            //     setValue(`owner.0.main_owner`, "-1");
            //     setValue(`owner.0.percentage_on_receipt`, 100);
            // }

            data.hobbybox = cleanedData
            let salevalue: any = `${data.sale_value}`;
            let salevalue_Val: any = convertToNumber(salevalue);
            let rentAmount: any = `${data.rent_amount}`;
            let rentAmount_val: any = convertToNumber(rentAmount);
            let monthly_admin_rates: any = `${data.monthly_admin_rate}`;
            let monthly_admin_rates_Val: any = convertToNumber(monthly_admin_rates);
            let lease_fee_perce: any = `${data.lease_fee}`;
            let lease_fee_perce_val: any = convertToNumber(lease_fee_perce);
            let fundraiser_val: any = `${data.fundraiser_value}`;
            let fundraiser_value: any = convertToNumber(fundraiser_val);
            let admin_rate_val: any = `${data.admin_rate}`;
            let admin_rate_value: any = convertToNumber(admin_rate_val);
            data["rent_amount"] = rentAmount_val
            data["sale_value"] = salevalue_Val
            data["lease_fee"] = lease_fee_perce_val
            data["monthly_admin_rate"] = monthly_admin_rates_Val;
            data["fundraiser_value"] = fundraiser_value;
            data["admin_rate"] = admin_rate_value;
            const totalPercentage = multipleOwner?.reduce((acc, owner) => acc + parseFloat(owner.percentage), 0);
            if (totalPercentage < 100) {
                Swal.fire({
                    // icon: 'success', 
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.lesstotalpercentage"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
                setDouble(false)
            } else if (totalPercentage > 100) {
                Swal.fire({
                    // icon: 'success', 
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.moretotalpercentage"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
                setDouble(false)
            } else {

                setDouble(true)
                data.owner = multipleOwner;
                let response: any = await dispatch(insertProperty(data))
                if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
                    setDouble(false);
                    Swal.fire({
                        // icon: 'success', 
                        title: t("Sweetalert.Success"),
                        text: response.payload.message,
                        confirmButtonText: "OK", timer: 2000
                    })
                    newPropertyClose();
                    dispatch(getProperty({ "start": 0, "end": 1000 }));
                    dispatch(getAllProperty({ start: 0, end: 20 }));
                    dispatch(setTenantId("0"));
                    setValue("use_real_state", false);
                    setuse_real_state(false)
                    document.querySelectorAll(".muiltipeMainowner select, .muiltipeMainowner input").forEach((item) => {
                        item.setAttribute('disabled', "");
                    })
                    reset({
                        owner: [{ value: "", main: "", percentage: "" }],
                    });
                }
                else {
                    setDouble(false)
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: response.payload.message,
                        //icon: "error", 
                        confirmButtonText: "OK",
                        timer: 2000
                    })
                }
            }
        }
    }

    const numhandleChange = async (e, inputType) => {
        setTimeout(async () => {
            let str = e.target.value
            let newStr = str.replace('-', ''); // Removes the hyphen
            // Input processing and formatting
            let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
            let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
            // Error validation
            // Retrieve address based on zipcode using customerService
            // if (inputType === "number") {
            //     const numericText = str.replace(/[^0-9]/g, ''); // Remove non-numeric characters
            //     setValue("number", numericText);
            // }
            let resp = await customerService.getAddressBasedOnZipcode(
                `${inputType}/${newStr}`
            );
            if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
                let querySel: any = document.querySelector('.accordion-custom [name="zipcode"]');
                let res = resp["data"]["data"];
                querySel.style.border = "0px solid red"
                if (res[0]["city"]) {
                    setValue("city", res[0]["city"])
                }
                if (res[0]["neigbohood"]) {
                    setValue("neighborhood", res[0]["neigbohood"])
                }
                if (res[0]["state"]) {
                    setValue("state", res[0]["state"])
                }
                if (res[0]["street"] || res[0]["streetType"]) {
                    let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                    setValue("address", data)
                }
                clearErrors("zipcode");
                clearErrors("city");
                clearErrors("neighborhood");
                clearErrors("address");
                clearErrors("state");
            } else {
                setError("zipcode", {
                    type: "manual",
                    message: t("invalidzipcode"),
                });
                let querySel: any = document.querySelector('.accordion-custom [name="zipcode"]');
                querySel.style.border = "2px solid red"
            }
        }, 50)
    }

    const [AllStates, setAllStates] = useState([]);
    useEffect(() => {
        if (newPropertyShow) {
            (async () => {
                let resp = await customerService.getAddressBasedOnZipcode(`states`);
                if (resp.data.type == "success" || resp.data.type == "Success") {
                    let loop = resp.data.data;
                    let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                    // Sort the stateData alphabetically by label
                    stateData.sort((a, b) => a.label.localeCompare(b.label));
                    setAllStates(stateData);
                }
            })()
            document.querySelectorAll(".muiltipeMainowner select, .muiltipeMainowner input").forEach((item) => {
                item.setAttribute('disabled', "");
            })
            multipleOwner.forEach((item, indexs) => {
                setValue(`owner.${indexs}.percentage_on_receipt`, "100");
            })
        }
    }, [newPropertyShow]);
    /*-----------Multiple owner----------*/
    const handleOwnerChange = (e, index, type) => {
        if (type == "owner" && e.target.value) {
            let pickData = ownerData && ownerData.length > 0 && ownerData.filter(item => Number(item.id) === Number(e.target.value));
            multipleOwner[`${index}`]["value"] = pickData[0]["id"];
            multipleOwner[`${index}`]["label"] = pickData[0]["name"];
        }
        if (type == "main") {
            document.querySelectorAll(".singleMainowner select").forEach((item) => {
            })
            // multipleOwner.forEach((item, indexs) => {
            //     setValue(`owner.${indexs}.main_owner`, "-1");
            //     multipleOwner[`${indexs}`]["main"] = e.target.value;
            // })

            setValue(`owner.${index}.main_owner`, e.target.value);
            multipleOwner[`${index}`]["main"] = e.target.value
        }
        if (type == "percentage") {
            if (Number(e.target.value) < 100) {
                multipleOwner[`${index}`]["percentage"] = e.target.value;
            } else {
                multipleOwner[`${index}`]["percentage"] = "100";
            }
            setValue(`owner.${index}.percentage_on_receipt`, multipleOwner[`${index}`]["percentage"]);
            let totalPercent: number = 0;
            multipleOwner.forEach((item, indexss) => {
                if (item.percentage !== "") {
                    totalPercent += Number(item.percentage);
                    if (indexss !== multipleOwner.length - 1) {
                        let exceed = totalPercent + Number(e?.target?.value);
                        if (totalPercent > 100) {
                            multipleOwner[`${index}`]["percentage"] = "";
                            setValue(
                                `owner.${index}.percentage_on_receipt`,
                                ""
                            );
                        } else {
                            if (totalPercent > 100) {
                                let num = 100 - totalPercent;
                                multipleOwner[`${index}`]["percentage"] = num;
                                setValue(
                                    `owner.${index}.percentage_on_receipt`,
                                    `${num}`
                                );
                            }
                        }
                    }
                }
            })

            if (totalPercent > 100) {
                multipleOwner[`${index}`]["percentage"] = "";
                setValue(`owner.${index}.percentage_on_receipt`, '');
            }
        }
        let newData = multipleOwner;
        setMultipleOwner(newData);
    }

    const addAnother = (e) => {
        setMultipleOwner([
            ...multipleOwner,
            {
                value: "",
                label: "",
                main: "1",
                percentage: "",
            }
        ])
        setValue(`owner.${multipleOwner.length}.main_owner`, "1");
        document.querySelectorAll(".muiltipeMainowner select, .muiltipeMainowner input").forEach((item) => {
            item.removeAttribute('disabled');
        })
    }

    const handleDelete = (e, index) => {
        multipleOwner.splice(index, 1);
        reset({
            owner: [{ value: "", main: "", percentage: "" }],
        });
        setMultipleOwner([...multipleOwner]);
        multipleOwner.forEach((item, indx) => {
            setValue(`owner.${indx}.name`, item.label ? item.label : item.value);
            setValue(`owner.${indx}.main_owner`, item.main);
            setValue(`owner.${indx}.percentage_on_receipt`, item.percentage);
        })
    }

    const [tnsf, setTnsfr] = useState(false);
    const transfer = (e) => {
        if (e.target.value !== "2") {
            setTnsfr(true)
            setfixeday(true)
            setValue("transfer_day", "2");
        } else {
            setTnsfr(false)
            setfixeday(false)
            setValue("transfer_day", "");
        }
        if (e.target.value == 1) {
            setGurnty(false);
        } else {
            setGurnty(true);
        }
    }
    const propertyIdentifier = async (evt) => {
        if (evt.target.value) {
            let data = {
                identifier: evt.target.value
            }
            let response = await customerService.checkPropertyIdentifier(data);
            if (response.data && response.data.data.length > 0) {
                setDouble(true);
                setError("property_identifier", {
                    type: "manual",
                    message: t("Sweetalert.Thisidentifier")
                });
            }
            else {
                setDouble(false);
                clearErrors("property_identifier")
            }
        }
    }

    const newManagerOpen = () => {
        dispatch(managerOpen(true));
        newPropertyClose();
    };

    const singalProperty = async (id) => {
        let data = await dispatch(registerData(id))
        const fields: string[] = ['property_identifier', 'monthly_admin_rate', 'lease_fee', 'zipcode', 'address', 'neighborhood', 'complement', 'city', 'state', 'number', 'usable_area', 'size', 'comments', 'registry', 'registration', 'administrator', 'branch', 'type_of_electrical', 'taxpayer_code', 'firefighter_code', 'spu_code', 'sequential_code', 'transfer_guaranteed', 'location', 'listed_property', 'building', 'gas_code', 'energy_code', 'water_code', 'garbage_fee_code', 'property_type', 'condominium'];
        const managerFiels: string[] = ['managerId', 'manager'];
        const garbageFiels: string[] = ['garageId', 'identification', 'observation'];
        const hobbyFiels: string[] = ['hobbyId', 'huby_identification', 'huby_observation'];
        const ownerFiels: string[] = ['ownerId', 'name', 'owner_type', 'property'];
        const fundrsrFiels: string[] = ['fundraiserId', 'fundraiser_name', 'fundraiser_value', 'fundraiser_type', 'fundraiser_installments'];
        if (data.payload.type == "success" || data.payload.type == "Success") {
            if (typeof data.payload !== "undefined") {
                let ownersData: any = [];
                let propertyType = data.payload.data[0]['propertyType'] ? data.payload.data[0]['propertyType'] + ' : ' : '';
                let number = data.payload.data[0]['number'] ? data.payload.data[0]['number'] + ' -' : '';
                let address = data.payload.data[0]['address'] ? data.payload.data[0]['address'] + ',' : '';
                let city = data.payload.data[0]['city'] ? data.payload.data[0]['city'] + ',' : '';
                let state = data.payload.data[0]['state'] ? data.payload.data[0]['state'] + ',' : '';
                setValue('rent_amount', converter(`${data.payload.data[0].rent_amount}`))
                setValue('sale_value', converter(`${data.payload.data[0].sale_value}`))
                setValue('admin_rate', data?.payload?.data[0].admin_rate ? converter(`${data.payload.data[0].admin_rate}`) : "0,00")
                let headText = propertyType + '' + address + ' ' + number + ' ' + city + '' + state + ' CEP:' + data.payload.data[0]['zipcode'];
                fields.forEach((field: any) => setValue(field, data.payload.data[0][field]));

                setTimeout(() => {
                    setValue('state', data.payload.data[0]["state"])
                }, 1000)

                data.payload.data[0]?.owner.forEach((items, indx) => {
                    let resultData = ownerData && ownerData.length > 0 && ownerData.filter(item => Number(item.id) === Number(items.value));
                    ownersData.push({
                        value: items.value,
                        label: resultData[0]?.["name"],
                        main: items.main,
                        percentage: items.percentage
                    })
                    setValue(`owner.${indx}.name`, items.value);
                    setValue(`owner.${indx}.main_owner`, items.main);
                    setValue(`owner.${indx}.percentage_on_receipt`, items.percentage);
                })
                setMultipleOwner(ownersData)

                if (typeof data.payload.data[0].manager !== "undefined" && data.payload.data[0].manager.length > 0) {
                    managerFiels.forEach((field: any) => setValue(field, data.payload.data[0].manager[0][field]));
                }
                if (typeof data.payload.data[0].garage !== "undefined" && data.payload.data[0].garage.length > 0) {
                    garbageFiels.forEach((field: any) => setValue(field, data.payload.data[0].garage[0][field]));
                }
                if (typeof data.payload.data[0].hobby !== "undefined" && data.payload.data[0].hobby.length > 0) {
                    hobbyFiels.forEach((field: any) => setValue(field, data.payload.data[0].hobby[0][field]));
                }
                if (typeof data.payload.data[0].fundraiser !== "undefined" && data.payload.data[0].fundraiser.length > 0) {
                    fundrsrFiels.forEach((field: any) => setValue(field, data.payload.data[0].fundraiser[0][field]));
                }
                if (typeof data.payload.data[0].fundraiser !== "undefined" && data.payload.data[0].fundraiser.length > 0) {
                    fundrsrFiels.forEach((field: any) => setValue(field, data.payload.data[0].fundraiser[0][field]));
                }
            }
        }
    }
    const handleRentValue = (e: any, type) => {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        // Format number with thousands separator and 2 decimal places
        if (value) {
            if (type == "rentAmount") {
                setValue("rent_amount", formatNumber(value))
            } else if (type == "saleValue") {
                setValue("sale_value", formatNumber(value))
            } else {
                setValue("admin_rate", formatNumber(value))
            }
        }
    }
    const fineExemption = (e) => {
        const input = e.target.value.replace(/\D/g, '');
        if (parseInt(input) > 30) {
            setValue("fine_exemption", "30")
            setShowTooltip(true);
        } else {
            setValue("fine_exemption", input)
            setShowTooltip(false);
        }
    };
    const TransferDay = (e: any) => {
        let value = e.target.value
        if (value == "2") {
            setfixeday(true)
        } else {
            setfixeday(false)
        }
    }
    const handleHoobyBox = (e, index) => {
        let value = e.target.value;
        let name: any = (e.target.name).split(".");
        let finalName: any = name[name.length - 1] // hobbybox.0.huby_identification
        if (value !== "") {
            multipleHobby[`${index}`][finalName] = e.target.value;
        }
    }

    const addAnotherhobby = (e) => {
        let AddNewForm = [
            ...multipleHobby,
            {
                huby_identification: "",
                huby_observation: "",
            }
        ]

        AddNewForm && AddNewForm.length > 0 &&
            AddNewForm.forEach((item: any, index: number) => {
                let huby_identification: any = `hobbybox.${index}.huby_identification`;
                let huby_observation: any = `hobbybox.${index}.huby_observation`;
                setValue(huby_identification, item["huby_identification"]);
                setValue(huby_observation, item["huby_observation"]);
            })
        setmMultipleHobby(AddNewForm);
    }

    const HandleDeleteHobbybox = (e, index) => {
        multipleHobby.splice(index, 1);
        setmMultipleHobby([...multipleHobby]);
        multipleHobby.forEach((item, indx) => {
            setValue(`hobbybox.${indx}.huby_identification`, item.huby_identification);
            setValue(`hobbybox.${indx}.huby_observation`, item.huby_observation);
        })
    }
    const handleAddress = (e) => {
        let { value } = e.target;
        let numberOfCharacters = value.length;
        if (numberOfCharacters > 40) {
            setError("address", {
                type: "manual",
                message: t("requiredMmessage.40characters")
            });
        } else {
            clearErrors("address")
        }
    }
    const handleValue = (evt: any) => {
        let { value } = evt.target;
        value = value.replace(/[^\d,]/g, '');
        if (value !== "") {
            setValue("fundraiser_value", formatNumber(value))
        } else {
            setValue("fundraiser_value", '')
        }
    }
    return (
        <>
            {/* <!-- New branch modal start from here --> */}
            <Modal show={newPropertyShow} onHide={newPropertyClose}>
                <Form onSubmit={handleSubmit(onSubmit)} id="PropertyServicetsx">
                    <Modal.Header closeButton>
                        <Modal.Title>{t('property.newProperty')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md={6}>
                                <FloatingLabel label={t('property.rentAmount') + " *"}>
                                    <Form.Control className={`form-control ${errors.rent_amount ? 'is-invalid' : ''}`} type="text" {...register('rent_amount')} placeholder={t('property.rentAmount')} onChange={(e) => handleRentValue(e, "rentAmount")} />
                                    <Form.Control.Feedback type="invalid">{errors.rent_amount?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel label={t('property.saleValue')}>
                                    <Form.Control type="text" className={`form-control ${errors.sale_value ? 'is-invalid' : ''}`} {...register('sale_value')} placeholder={t('property.saleValue')} onChange={(e) => handleRentValue(e, "saleValue")} />
                                    <Form.Control.Feedback type="invalid">{errors.sale_value?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={5}>
                                <FloatingLabel label={`${t('formLabel.monthlyAdminRate') + " *"} ${percentornot.monthly_percent ? "(%)" : "(R$)"}`}>
                                    <Form.Control
                                        type="text"
                                        className={`form-control ${errors.monthly_admin_rate ? 'is-invalid' : ''}`} //working here
                                        {...register('monthly_admin_rate')}
                                        placeholder="Monthly Admin Rate"
                                        onChange={(e) => handleInputChange(e, 'monthly_admin_rate', isMonthlyAdminRatePercentage)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.monthly_admin_rate?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={7}>
                                <Form.Check
                                    type="checkbox" {...register('monthly_admin_rate_percentage')}
                                    checked={isMonthlyAdminRatePercentage}
                                    onChange={(e) => handleCheckboxChange(e, setMonthlyAdminRatePercentage)}
                                    label={t('formLabel.doNotPercentage')}

                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md={5}>
                                <FloatingLabel label={`${t('formLabel.leaseFee')} ${percentornot.lease_percent ? "(%)" : "(R$)"}`}>
                                    <Form.Control
                                        type="text"
                                        className={`form-control ${errors.lease_fee ? 'is-invalid' : ''}`}
                                        {...register('lease_fee')}
                                        placeholder="Lease Fee"
                                        onChange={(e) => handleInputChange(e, 'lease_fee', isLeaseFeePercentage)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.lease_fee?.message}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={7}>
                                <Form.Check
                                    type="checkbox" {...register('lease_fee_percentage')}
                                    checked={isLeaseFeePercentage}
                                    onChange={(e) => handleCheckboxChange(e, setLeaseFeePercentage)}
                                    label={t('formLabel.doNotPercentage')}
                                />
                            </Col>
                        </Row>
                        <Accordion className="accordion-custom scl2">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    {t('formLabel.address')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3">
                                        <Col md={7}>
                                            <FloatingLabel label={t('formLabel.propertyType') + " *"}>
                                                <Form.Select className={`form-control ${errors.property_type ? 'is-invalid' : ''}`} {...register('property_type')}>
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    <option value="1">{t("propertyTypeData.Casa")}</option>
                                                    <option value="4">{t("propertyTypeData.Apartamento")}</option>
                                                    <option value="33">{t("propertyTypeData.ApartamentoDuplex")}</option>
                                                    <option value="38">{t("propertyTypeData.ApartamentoTriplex")}</option>
                                                    <option value="9">{t("propertyTypeData.Áreacomum")}</option>
                                                    <option value="42">{t("propertyTypeData.Andarcorporativo")}</option>
                                                    <option value="29">{t("propertyTypeData.Bangalo")}</option>
                                                    <option value="13">{t("propertyTypeData.Barracao")}</option>
                                                    <option value="40">{t("propertyTypeData.BoxGaragem")}</option>
                                                    <option value="3">{t("propertyTypeData.Casacomercial")}</option>
                                                    <option value="2">{t("propertyTypeData.Casaemcondominio")}</option>
                                                    <option value="44">{t("propertyTypeData.CasaAssobradada")}</option>
                                                    <option value="5">{t("propertyTypeData.Cobertura")}</option>
                                                    <option value="14">{t("propertyTypeData.Chacara")}</option>
                                                    <option value="25">{t("propertyTypeData.Conjunto")}</option>
                                                    <option value="39">{t("propertyTypeData.Edicula")}</option>
                                                    <option value="10">{t("propertyTypeData.Escritorio")}</option>
                                                    <option value="20">{t("propertyTypeData.Fazenda")}</option>
                                                    <option value="6">{t("propertyTypeData.Flat")}</option>
                                                    <option value="16">{t("propertyTypeData.Galpao")}</option>
                                                    <option value="8">{t("propertyTypeData.Garagem")}</option>
                                                    <option value="43">{t("propertyTypeData.Garden")}</option>
                                                    <option value="21">{t("propertyTypeData.Haras")}</option>
                                                    <option value="35">{t("propertyTypeData.Hotel")}</option>
                                                    <option value="31">{t("propertyTypeData.Ilha")}</option>
                                                    <option value="7">{t("propertyTypeData.Kitnet")}</option>
                                                    <option value="27">{t("propertyTypeData.Laje")}</option>
                                                    <option value="30">{t("propertyTypeData.Loft")}</option>
                                                    <option value="12">{t("propertyTypeData.Loja")}</option>
                                                    <option value="41">{t("propertyTypeData.Pavilhao")}</option>
                                                    <option value="32">{t("propertyTypeData.Penthouse")}</option>
                                                    <option value="22">{t("propertyTypeData.Predio")}</option>
                                                    <option value="28">{t("propertyTypeData.Pousada")}</option>
                                                    <option value="19">{t("propertyTypeData.Ponto")}</option>
                                                    <option value="18">{t("propertyTypeData.Quiosque")}</option>
                                                    <option value="36">{t("propertyTypeData.Rancho")}</option>
                                                    <option value="34">{t("propertyTypeData.Resort")}</option>
                                                    <option value="15">{t("propertyTypeData.Salao")}</option>
                                                    <option value="11">{t("propertyTypeData.Salacomercial")}</option>
                                                    <option value="23">{t("propertyTypeData.Sitio")}</option>
                                                    <option value="24">{t("propertyTypeData.Sobrado")}</option>
                                                    <option value="37">{t("propertyTypeData.Studio")}</option>
                                                    <option value="17">{t("propertyTypeData.Terreno")}</option>
                                                    <option value="26">{t("propertyTypeData.Village")}</option>
                                                    <option value="45">{t("propertyTypeData.Consultorio")}</option>
                                                    <option value="1000">{t("propertyTypeData.Outro")}</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.property_type?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={5}>
                                            <InputGroup>
                                                <FloatingLabel label={t('formLabel.zipCode') + " *"}>
                                                    <Form.Control type="text" placeholder={t('formLabel.zipCode')} className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`} {...register('zipcode')} maxLength={9} onChange={(e) => numhandleChange(e, "zipcode")} />
                                                    <Form.Control.Feedback type="invalid">{errors.zipcode?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                                <InputGroup.Text>
                                                    <img src="./assets/images/icons/search.svg" className="h-16" alt="Search icon" />
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Col md={7}>
                                            <FloatingLabel label={t('formLabel.address') + " *"}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="tooltip-property-id">{t("requiredMmessage.40characters")}</Tooltip>}
                                                >
                                                    <Form.Control type="text" placeholder={t('formLabel.address')} className={`form-control ${errors.address ? 'is-invalid' : ''}`}  {...register('address')} onChange={(e) => handleAddress(e)} />
                                                </OverlayTrigger>
                                                <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={5}>
                                            <FloatingLabel label={t('formLabel.number') + " *"}>
                                                <Form.Control type="text" placeholder={t('formLabel.number')} className={`form-control ${errors.number ? 'is-invalid' : ''}`}  {...register('number')} />
                                                <Form.Control.Feedback type="invalid">{errors.number?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={7}>
                                            <FloatingLabel label={t('formLabel.complement')}>
                                                <Form.Control type="text" placeholder={t('formLabel.complement')} className={`form-control ${errors.complement ? 'is-invalid' : ''}`}  {...register('complement')} />
                                                <Form.Control.Feedback type="invalid">{errors.complement?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={5}>
                                            <FloatingLabel label={t('formLabel.neighborhood') + " *"}>
                                                <Form.Control type="text" placeholder={t('formLabel.neighborhood')} className={`form-control ${errors.neighborhood ? 'is-invalid' : ''}`}  {...register('neighborhood')} />
                                                <Form.Control.Feedback type="invalid">{errors.neighborhood?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={7}>
                                            <FloatingLabel label={t('formLabel.city') + " *"}>
                                                <Form.Control type="text" placeholder={t('formLabel.city')} className={`form-control ${errors.city ? 'is-invalid' : ''}`}  {...register('city')} />
                                                <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={5}>
                                            <FloatingLabel label={t('formLabel.state') + " *"}>
                                                <Form.Select className={`form-control ${errors.state ? 'is-invalid' : ''}`}  {...register('state')}>
                                                    <option value="">{t('dropdownItem.select')}</option>
                                                    {AllStates && AllStates.length > 0 &&
                                                        AllStates.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                            )
                                                        })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.state?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* <Accordion.Item eventKey="00">
                                <Accordion.Header>
                                    {t("contaCorr")}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3">
                                        <Col lg={12}>
                                            <Form.Check
                                                type="checkbox"
                                                {...register('contaProprietario')}
                                                label={t("util")}
                                            />
                                            <span>{t("utilDesc")}</span>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item> */}
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className="ownersAndBeneficiariestsx">
                                    {t('formLabel.ownerAndBenef')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3">
                                        <Col lg={12}>
                                            <Form.Check
                                                type="checkbox"
                                                {...register('use_real_state')}
                                                checked={use_real_state}
                                                onChange={(e) => ownerActionproperties(e, setuse_real_state)}
                                                label={t('formLabel.userealstate')}
                                            />
                                        </Col>
                                        <Col lg={12}>
                                            {
                                                multipleOwner && multipleOwner.length > 0 &&
                                                multipleOwner.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        <Row className="gy-3 gy-lg-4mb-lg-4 mb-3 fs-12">
                                                            <Col xs>
                                                                <InputGroup>
                                                                    <FloatingLabel className="owner " label={t('formLabel.name') + " *"}>
                                                                        <Form.Select className={`form-control fs-12 ${errors?.owner?.[index]?.name ? 'is-invalid' : ''}`} {...register(`owner.${index}.name`)} onChange={(e) => handleOwnerChange(e, index, "owner")}>
                                                                            < option value="">{t('dropdownItem.select')}..</option>
                                                                            {
                                                                                ownerDDta && ownerDDta.length > 0 ?
                                                                                    ownerDDta.map((item, index) => (
                                                                                        <option key={index} value={item?.["id"]}>{item?.["name"]}</option>
                                                                                    ))
                                                                                    :
                                                                                    ownerData.map((item, index) => {
                                                                                        let companyData1 = companyData?.["payload"]?.["data"]?.[0];
                                                                                        if (companyData1) {
                                                                                            let { email, cnpj } = companyData1;
                                                                                            if (item["cnpj"] !== cnpj) {
                                                                                                return (
                                                                                                    <option key={index} value={item?.["id"]}>{item?.["name"]}</option>
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                    })
                                                                            }
                                                                        </Form.Select>
                                                                        <Form.Control.Feedback type="invalid">{errors?.owner?.[index]?.name?.message}</Form.Control.Feedback >
                                                                    </FloatingLabel>
                                                                    <InputGroup.Text onClick={newOwnerOpen} title={t('customerAndEmployees.newOwner')}>
                                                                        <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20" />
                                                                    </InputGroup.Text>
                                                                </InputGroup>
                                                            </Col>

                                                            <>
                                                                <Col md={3}>
                                                                    <FloatingLabel className='singleMainowner muiltipeMainowner ' label={t('formLabel.type') + " *"}>
                                                                        <Form.Select {...register(`owner.${index}.main_owner`)} className={`${errors?.owner?.[index]?.main_owner ? 'is-invalid fs-10' : 'fs-10'}`} onChange={(e) => handleOwnerChange(e, index, "main")}>
                                                                            <option value="-1">{t("formLabel.Mainowner")}</option>
                                                                            <option value="1">{t("owner")}</option>
                                                                            <option value="2">{t("reportSystem.beneficiary")}</option>
                                                                        </Form.Select>
                                                                        <Form.Control.Feedback type="invalid">{errors?.owner?.[index]?.main_owner?.message}</Form.Control.Feedback >
                                                                    </FloatingLabel>
                                                                    {/* <Form.Check className="singleMainowner" type="checkbox" {...register(`owner.${index}.main_owner`)} label="Proprietário principal" onChange={(e) => handleOwnerChange(e, index, "main")} /> */}
                                                                </Col>
                                                                <Col md={3}>
                                                                    <FloatingLabel className="mb-3 percentage getzipcode muiltipeMainowner fs-12" controlId="floatingSelect " label={t('formLabel.propertyPercentage') + " * (%)"} >
                                                                        <Form.Control type="text" placeholder={t('formLabel.propertyPercentage')} {...register(`owner.${index}.percentage_on_receipt`)} className={`${errors?.owner?.[index]?.percentage_on_receipt ? 'is-invalid' : ''}`} onChange={(e) => handleOwnerChange(e, index, "percentage")} />
                                                                        <Form.Control.Feedback type="invalid">{errors?.owner?.[index]?.percentage_on_receipt?.message}</Form.Control.Feedback >
                                                                    </FloatingLabel>
                                                                </Col>
                                                                {
                                                                    multipleOwner.length > 1 && ownerDDta.length == 0 &&
                                                                    <Col md={2}>
                                                                        <Button className="btn btn-link bg-light min-w-auto border-light rounded-4" onClick={(e) => handleDelete(e, index)}><img src={process.env.PUBLIC_URL + "/assets/images/icons/delete-light.svg"} alt="Delete icon" className="h-16 cursor-pe" /></Button>
                                                                    </Col>
                                                                }
                                                            </>
                                                        </Row>
                                                    </React.Fragment>
                                                ))
                                            }
                                            {
                                                multipleOwner.length < 6 && ownerDDta.length == 0 &&
                                                <Row>
                                                    <Col md={6}>
                                                        <Button variant='outline-secondary' onClick={(e) => addAnother(e)}>
                                                            {t("formLabel.another")}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    {t('formLabel.transfer')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3">
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.guaranteedTransfer')}>
                                                <Form.Select {...register('transfer_guaranteed')} onChange={(e) => transfer(e)}>
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    <option value="2">{t("doesNotHave")}</option>
                                                    <option value="1">{t("guaranteeFewMonths")}</option>
                                                    <option value="-1">{t("guaranteeContract")}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6} style={{ display: gurnty ? "none" : "block" }}>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label={t("newContractDev.GuaranteedMonths")}
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder={t("newContractDev.GuaranteedMonths")}
                                                    {...register("months")}
                                                    onChange={(e) => {
                                                        numericOnly(e);
                                                    }}
                                                />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.transferDay')}>
                                                <Form.Select disabled={tnsf ? tnsf : tnsf} {...register('transfer_day')} className={`form-control ${errors.transfer_day ? 'is-invalid' : ''}`} onChange={(e) => TransferDay(e)}> //working here
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    <option value="1">{t("businessPayment")}</option>
                                                    <option value="2">{t("fixedDay")}</option>
                                                    <option value="3">{t("calendarPayment")}</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.transfer_day?.message}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={fixedday ? t("formLabel.transferDay") : t('formLabel.daysOfTransfer')}>
                                                <Form.Control type="text" placeholder={t('formLabel.daysOfTransfer')} {...register('days_of_transfer')} onChange={numericOnly} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                    {t('formLabel.proServiceContact')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3">
                                        <Col md={6}>
                                            <FloatingLabel label={t('dateCapture')}>
                                                <Form.Control type="date" placeholder={t('dateCapture')} {...register('funding_date')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <OverlayTrigger
                                                placement="bottom"
                                                show={showTooltip || undefined}
                                                overlay={<Tooltip id="tooltip-bottom">{t(showTooltip ? "Maximummonths" : "exemptionFineTooltip")}</Tooltip>}
                                            >
                                                <FloatingLabel label={t('formLabel.fineExemption')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.fineExemption')} {...register('fine_exemption')} onKeyPress={numericOnly} onChange={fineExemption} />
                                                </FloatingLabel>
                                            </OverlayTrigger>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.maximumLease')}>
                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={<Tooltip id="tooltip-maximum-lease">{t('formLabel.maximumLeaseTooltip')}</Tooltip>}
                                                >
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={t('formLabel.maximumLease')}
                                                        {...register('maximum_lease')}
                                                        onChange={numericOnly}
                                                        max={999}
                                                    />
                                                </OverlayTrigger>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.administrationFee')}>
                                                <Form.Select {...register('administration_fee')} className={`form-control ${errors.administration_fee ? 'is-invalid' : ''}`}>
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    <option value="0">{t("undefined")}</option>
                                                    <option value="1">{t("focusonlyonrent")}</option>
                                                    <option value="2">{t("focusonrent")}</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.administration_fee?.message}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>
                                    {t('formLabel.identifier')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel label={t('formLabel.propertyIdentifier') + " *"}>
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={<Tooltip id="tooltip-property-id">{t('propertyIdentifierID')}</Tooltip>}
                                        >
                                            <Form.Control className={`form-control ${errors.property_identifier ? 'is-invalid' : ''}`} type="text" placeholder={t('formLabel.propertyIdentifier')} {...register('property_identifier')} onKeyUp={propertyIdentifier} />
                                        </OverlayTrigger>

                                        <Form.Control.Feedback type="invalid">{errors.property_identifier?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>
                                    {t('property.condominium')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3 align-items-center">
                                        <Col md={6}>
                                            <InputGroup>
                                                <FloatingLabel label={t('formLabel.Condominiumadministration')} >
                                                    <Form.Select className={`form-control ${errors.administrator ? 'is-invalid' : ''}`}  {...register('administrator')}>
                                                        <option value="">{t('dropdownItem.select')}..</option>
                                                        {administData && administData.length > 0 &&
                                                            administData.map((item, index) => (
                                                                <option key={index} value={item.id}>{item.name}</option>
                                                            ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.administrator?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                                <InputGroup.Text onClick={newAdministratorOpen} title={t('property.property')}>
                                                    <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20" />
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Col md={6}>
                                            <InputGroup>
                                                <FloatingLabel label={t('formLabel.buildingCondominium')}>
                                                    <Form.Select className={`form-control ${errors.building ? 'is-invalid' : ''}`}  {...register('building')}>
                                                        <option value="">{t('dropdownItem.select')}..</option>
                                                        {condoData && condoData.length > 0 &&
                                                            condoData.map((item, index) => (
                                                                <option key={index} value={item.id}>{item.name}</option>
                                                            ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.building?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                                <InputGroup.Text onClick={newCondosOpen}>
                                                    <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20" />
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel label={t('formLabel.value')}>
                                                <Form.Control type="text" placeholder={t('formLabel.value')} className={`form-control ${errors.admin_rate ? 'is-invalid' : ''}`}  {...register('admin_rate')} onChange={(e) => handleRentValue(e, "admin_rate")} />
                                                <Form.Control.Feedback type="invalid">{errors.admin_rate?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={8}>
                                            <FloatingLabel label={t('formLabel.Condominiumadministration')}>
                                                <Form.Select {...register('condominium')}>
                                                    <option value="1">{t("Report-Property.NotManagedByTheRealEstateAsgency")}</option>
                                                    <option value="2">{t("Report-Property.ManagedByTheRealEstateAgency")}</option>
                                                    <option value="3">{t("Report-Property.ManagedAndPaidByTheRealEstateAgency")}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                            {/* <Form.Check type="checkbox" {...register('condominium')} id="{'default3'}" label={t('formLabel.condominiumEstate')} /> */}
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>
                                    {t('formLabel.propertyDetails')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3">
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.sequentialCode')}>
                                                <Form.Control type="text" placeholder={t('formLabel.sequentialCode')} {...register('sequential_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.waterCode')}>
                                                <Form.Control type="text" placeholder={t('formLabel.waterCode')} {...register('water_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.energyCode')}>
                                                <Form.Control type="text" placeholder={t('formLabel.energyCode')} {...register('energy_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.electricalConnection')}>
                                                <Form.Select {...register('type_of_electrical')}>
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    <option value="0">{t("uninformed")}</option>
                                                    <option value="1">{t("singlephase")}</option>
                                                    <option value="2">{t("biphasic")}</option>
                                                    <option value="3">{t("threephase")}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.gasCode')}>
                                                <Form.Control type="text" placeholder={t('formLabel.gasCode')} {...register('gas_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.usableArea')}>
                                                <Form.Control type="text" placeholder={t('formLabel.usableArea')} {...register('usable_area')} onChange={numericOnly} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.size')}>
                                                <Form.Control type="text" placeholder={t('formLabel.size')} {...register('size')} onChange={numericOnly} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.taxpayerCode')}>
                                                <Form.Control type="text" placeholder={t('formLabel.taxpayerCode')} {...register('taxpayer_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.garbageFeeCode')}>
                                                <Form.Control type="text" placeholder={t('formLabel.garbageFeeCode')} {...register('garbage_fee_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.firefighterCode')}>
                                                <Form.Control type="text" placeholder={t('formLabel.firefighterCode')} {...register('firefighter_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.spuCode')}>
                                                <Form.Control type="text" placeholder={t('formLabel.spuCode')} {...register('spu_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.location')}>
                                                <Form.Select {...register('location')}>
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    <option value="U">{t('urbanproperty')}</option>
                                                    <option value="R">{t('ruralproperty')}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.propertyDestination')}>
                                                <Form.Select {...register('property_destination')}>
                                                    <option value="0">{t('dropdownItem.select')}..</option>
                                                    <option value="1">{t("residential")}</option>
                                                    <option value="2">{t("nonResidential")}</option>
                                                    <option value="3">{t("comercial")}</option>
                                                    <option value="4">{t("indústria")}</option>
                                                    <option value="5">{t("temporada")}</option>
                                                    <option value="6">{t("porEncomenda")}</option>
                                                    <option value="7">{t("misto")}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.listedProperty')}>
                                                <Form.Select {...register('listed_property')}>
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    <option value="1">{t("contractlistother.Not")} </option>
                                                    <option value="2">{t("yes")}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>
                                    {t('property.managers')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <InputGroup className="mb-3">
                                            <FloatingLabel label={t('formLabel.manager')} >
                                                <Form.Select className={`form-control ${errors.manager ? 'is-invalid' : ''}`}  {...register('manager')}>
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    {managerData && managerData.length > 0 &&
                                                        managerData.map((item, index) => (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.manager?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                            <InputGroup.Text onClick={newManagerOpen}>
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/assets/images/icons/user-edit.svg"
                                                    }
                                                    alt="Documents add"
                                                    className="h-20 cursor-pe"
                                                />
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>
                                    {t('formLabel.registry')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3">
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.registry')}>
                                                <Form.Control type="text" placeholder={t('formLabel.registry')} {...register('registry')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel label={t('formLabel.registration')}>
                                                <Form.Control type="text" placeholder={t('formLabel.registration')} {...register('registration')} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>
                                    {t('formLabel.fundraiser')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3">
                                        <Col md={12}>
                                            <InputGroup>
                                                <FloatingLabel label={t('formLabel.name')}>
                                                    <Form.Select className={`form-control ${errors.fundraiser_name ? 'is-invalid' : ''}`} {...register('fundraiser_name')}>
                                                        <option value="">{t('dropdownItem.select')}..</option>
                                                        {brokerData && brokerData.length > 0 &&
                                                            brokerData.map((item, index) => (
                                                                <option key={index} value={item.id}>{item.name}</option>
                                                            ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.fundraiser_name?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                                <InputGroup.Text onClick={newBrokerOpen} title={t('customerAndEmployees.newBroker')}>
                                                    <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20" />
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel label={t('formLabel.value')}>
                                                <Form.Control className={`form-control ${errors.fundraiser_value ? 'is-invalid' : ''}`} type="text" placeholder={t('formLabel.value')} {...register('fundraiser_value')} onChange={(e) => handleValue(e)} />
                                                <Form.Control.Feedback type="invalid">{errors.fundraiser_value?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel label={t('formLabel.type')}>
                                                <Form.Select className={`form-control ${errors.fundraiser_type ? 'is-invalid' : ''}`} {...register('fundraiser_type')}>
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    <option value="1">{t("percentual")}</option>
                                                    <option value="2">{t("fixedvalue")} </option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.fundraiser_type?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel label={t('formLabel.installments')}>
                                                <Form.Select className={`form-control ${errors.fundraiser_installments ? 'is-invalid' : ''}`} {...register('fundraiser_installments')}>
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="-1">{t("foreverycontract")}</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.fundraiser_installments?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="10">
                                <Accordion.Header>
                                    {t('formLabel.branch')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel label={t('formLabel.branch')}>
                                        <Form.Select {...register('branch')}>
                                            <option value="4">{t('dropdownItem.select')}..</option>
                                            {branchList && branchList.length > 0 &&
                                                branchList.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.branch?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="11">
                                <Accordion.Header>
                                    {t('formLabel.garages')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                        <Col md={5}>
                                            <FloatingLabel label={t('formLabel.identification')} >
                                                <Form.Control type="text" placeholder={t('formLabel.identification')}  {...register('identification')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={5}>
                                            <FloatingLabel label={t('formLabel.observation')} >
                                                <Form.Control type="text" placeholder={t('formLabel.observation')} {...register('observation')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={2}>
                                            <Button className="btn btn-link bg-light min-w-auto border-light rounded-4">
                                                <img src="assets/images/icons/delete-light.svg" alt="Delete icon" className="h-20 cursor-pe" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="12">
                                <Accordion.Header>
                                    {t('formLabel.comments')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel label={t('formLabel.observation')}>
                                        <Form.Control as="textarea" {...register('comments')} rows={3} />
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="13">
                                <Accordion.Header>
                                    {t('formLabel.hobbyBox')}
                                </Accordion.Header>
                                <Accordion.Body>
                                    {multipleHobby && multipleHobby.length > 0 &&
                                        multipleHobby.map((item, index) => (
                                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                                <Col md={5}>
                                                    <FloatingLabel label={t('formLabel.identification')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.identification')} {...register(`hobbybox.${index}.huby_identification`)} onChange={(e) => handleHoobyBox(e, index)} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={5}>
                                                    <FloatingLabel label={t('formLabel.observation')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.observation')} {...register(`hobbybox.${index}.huby_observation`)} onChange={(e) => handleHoobyBox(e, index)} />
                                                    </FloatingLabel>
                                                </Col>

                                                <Col md={2}>
                                                    {multipleHobby.length > 1 ?
                                                        <Button className="btn btn-link bg-light min-w-auto border-light rounded-4" onClick={(e) => HandleDeleteHobbybox(e, index)}>
                                                            <img src="assets/images/icons/delete-light.svg" alt="Delete icon" className="h-20 cursor-pe" />
                                                        </Button>
                                                        : ""
                                                    }
                                                </Col>
                                            </Row>

                                        )
                                        )}
                                    <Row>
                                        <Col md={6}>
                                            <Button variant='outline-secondary' onClick={(e) => addAnotherhobby(e)}>
                                                {t("formLabel.another")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-sm-flex justify-content-end w-100 align-items-center">
                            <Button variant="outline-primary" onClick={newPropertyClose}>
                                {t('button.close')}
                            </Button>
                            <Button type="submit" disabled={double} variant="primary" onClick={openErrorbox}>
                                {t('button.toSave')}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            <AdministratorService />
            <CondosService />
            <OwnerService />
            <BrokerService />
            <NewOwner type="properties" />
            <ManagerService type={"properties"} />
            {/* <!-- /New branch modal end --> */}
        </>
    )
}
export default PropertyService;