import { Form, Modal, Button, Row, Col, InputGroup, DropdownButton, ButtonGroup, Table, Accordion, FloatingLabel, Alert, Card, Badge, Dropdown, ListGroup } from "react-bootstrap";
import { getContractDetailsCount, getAllExpense, setLiquidateType, setExpenseType, contractGenerateMeatOpen, setLiquidateTitle, newLiquidateOpen, setExpenseTitle, newExpenseOpen, newRentalFeeOpen, newElectronicEnvelopeOpen, newChecklistOpen, newChecklistItemOpen, setExtraCharge, setExtraChargeLabel, newExtraChargeOpen, renewInsuranceOpen, quoteOpen, contractOpen, getContractTransfer, contractDatails, warrantyOpen, setExpenseCheckbox, getTenants, getProperty, getInsurance, getGuarantor, contractDateSearch, TransferCom, setCheckedId, cancelInsuranceRequest } from "../../../../../redux/slice/contractDataSlice";
import React, { useState, MouseEvent, useEffect, useRef } from "react";
import {
	pauseContractOpen, setExpType, getAllContractCharge, getAllContractChecklist, getAllBillMethodListing, getAllChecklistItems, getAllAccounts, getAllIndexs, typeofcontract, getAllReadjustIndexs, readjustOpen,
} from "../../../../../redux/slice/expenditureDataSlice";
import { Outlet, Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../../../../Profile/Company/Loader";
import * as Yup from "yup";
import "../../../../../App.css";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import WarrantyService from "../../Common/WarrantyService";
import QuoteService from "../../Common/QuoteService";
import RenewInsuranceService from "../../Common/RenewInsuranceService";
import ExtraChargeService from "../../Common/ExtraChargeService";
import ChecklistService from "../../Common/ChecklistService";
import ChecklistsService from "../../Common/ChecklistsService";
import ElectronicEnvelopeService from "../../Common/ElectronicEnvelopeService";
import RentalFeeService from "../../Common/RentalFeeService";
import ExpenseService from "../../Common/ExpenseService";
import LiquidateService from "../../Common/LiquidateService";
import GenerateMeatService from "../../Common/GenerateMeatService";
import InvalidateBoletoService from "../../Common/InvalidateBoletoService";
import { contractForm } from "../../../../Interface";
import { getAccountCategory } from "../../../../../redux/slice/financialDataSlice";

import ChangeFinancialStatus from "./ChangeFinancialStatus";
import PauseContract from "./PauseContract";
import Checkbox from "../../../Contracts/Common/Checkbox";
import customerService from "../../../../../services/customerService";
import NotifyContract from "./NotifyContract";
import Terminate from "./TerminateContract/Terminate";
import TerminateListing from "./TerminateContract/TerminateListing";
import { incomeService } from "../../../../../services/incomeServices";
import { gettoken } from "../../../../../Api/Contact";
import KeyHandOver from "../../../../PDF/Contract/KeyHandOver";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { userService } from "../../../../../services/userService";
import { IMAGEURLLINK, converter, encrypt_Decrypt } from "../../../../constants/utils";
import { getBraches } from "../../../../../redux/slice/branchSlice";
import {
	getManager,
	getOwner,
} from "../../../../../redux/slice/propertyDataSlice";
import i18n from "../../../../../i18n";
import { config } from "../../../../../services/apiConfig";
import Pagination from "../../../../Pagination";
import moment from "moment-timezone";
import { emailPost } from "../../../../../redux/slice/SendEmailSlice";
import ReadjustmentService from "../../Common/ReadjustmentService";
import EmptyReason from "../../../NewDesigns/Documents/EmptyReason";
interface Coverage {
	value: number;
	text: string;
	// Define properties of Coverage
}
interface InsuranceRecord {
	Id: number;
	Name: string;
	Total: number;
	Installments: number;
	TotalCalculatedByInstallments: number;
	Coverages: Coverage[]; // Ensure Coverages property is defined

	// Add other properties if needed
}
const CustomerApiBaseUrl = config.CUSTOMER___API;
const Contract_details = (contractForm: any) => {
	let dateBrazil = moment.tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm");

	const { id } = useParams();
	const [t] = useTranslation();
	//For Show Single data
	const [ShowThisData, setShowThisData] = useState(false);
	const [show, toggleShow] = useState(true);
	const [showPropertyType, setShowPropertyType] = useState<string>("");
	const [gurantorLabel, setGurantorLabel] = useState<string>("");
	const location = useLocation();
	const nagivate = useNavigate();

	const dispatch = useDispatch<AppDispatch>();
	const { singleContCount, contractDatail, expenseData, tenantsData, expenseStatus, guarantorData, status1, transferStatus, contTransferData, Property_Type, guarantors, showContractSearch, } = useSelector((state: RootState) => state.contractData);
	const { contractChecklist, checklistStatus, chargeData, chargeStatus, checklistScroll } = useSelector((state: RootState) => state.expenditureData);
	const { getlogo, status } = useSelector(
		(state: RootState) => state.companyData
	);
	// const { companydata } = useSelector((state: RootState) => state.companyData);
	const [extraAndDiscount, setExtraAndDiscount] = useState({});
	const [gurantorDetails, setGurantorDetails] = useState<any>({});
	const [completeTermination, setCompleteTermination] = useState(false);
	const navigate = useNavigate();
	const linkRef: any = useRef(null);
	const [showAlert, setShowAlert] = useState(true);
	const [electronicEnvelopeListing, setElectronicEnvelopeListing] =
		useState<any>([]);
	// Boleto Pix info modal initialization
    const [showBoletoPixModal, setBoletoPixModal] = useState(false);
    const [BoletoPixAarinError, setBoletoPixAarinError] = useState('');
	const changeBoletoPixClose = () => setBoletoPixModal(false);
	const [chagerfilter, setChargeFilter] = useState("");
	const [amendmant, setAmendmant] = useState<any>([]);
	const [PropertyTypes, setPropertyTypes] = useState<any>("");
	const [userJson, setUserJson] = useState<any>([]);
	const [settledFilter, setSettledFilter] = useState(false);
	const [itemCount, setItemCount] = useState<any>();
	const [processEamil, setProcessEamil] = useState(false);
	const targetRef = useRef<HTMLDivElement>(null);
	const [insuranceToken, setInsuranceToken] = useState<any>("");
	const [pageLoaded, setPageLoaded] = useState(false);
	const [insuranceQuotes, setInsuranceQuotes] = useState<any>([]);
	const { accountCatData } = useSelector((state: RootState) => state.financialData)

	useEffect(() => {
		let userResult = encrypt_Decrypt();
		if (
			!userResult ||
			(userResult.indexOf(2030) === -1 && userResult.indexOf(2070) === -1)
		) {
			navigate("/dashboard");
		}
		setUserJson(userResult);
	}, []);

	let newDate = new Date(dateBrazil);
	let currentYear = moment(newDate).format("YYYY");
	let nextYear = moment(newDate).add(1, "years").format("YYYY");

	//let startDt = moment(newDate).format("MMM/YYYY");
	let startDt = moment(newDate).subtract(1, "M").format("MMM/YYYY");
	let searchDt = moment(newDate).format("MMM/YYYY");
	let endDt = moment(newDate).add(1, "M").format("MMM/YYYY");
	let yearDt = moment(newDate).format("YYYY");

	const [stateTransfer, setStateTransfer] = useState({
		id: id,
		startYear: startDt,
		endYear: endDt,
		startDate: startDt,
		searchDate: searchDt,
		endDate: endDt,
	});

	const [showTerminate, setshowTerminate] = useState(false);
	const [branchState, setBranchState] = useState({
		start: "0",
		end: "100",
	});

	const [stateMode, setStateMode] = useState("monthly");
	const [state, setState] = useState<any>({
		id: id,
		startDate: startDt,
		searchDate: searchDt,
		endDate: endDt,
		type: "",
		expense: "",
		yearlySearch: yearDt,
		start: 0,
		end: 1000,
	});

	const [stateCharge, setStateCharge] = useState<any>({
		id: id,
		startDate: startDt,
		searchDate: searchDt,
		endDate: endDt,
		type: "charge",
		start: 0,
		end: 1000,
	});

	let PageSize = stateCharge.end;
	const [currentPage, setCurrentPage] = useState(1);
	const [totalpage, setTotalPage] = useState(0);

	useEffect(() => {
		dispatch(getAccountCategory());
		dispatch(getAllBillMethodListing({ start: 0, end: 1000 }));
		dispatch(getBraches(branchState));
		dispatch(getManager());
		dispatch(getTenants());
		dispatch(
			getProperty({ id: id, contract: "contract", start: 0, end: 1000 })
		);
		dispatch(getInsurance({ insurance: "", start: 0, end: 1000 }));
		dispatch(getOwner());
		dispatch(getGuarantor());
		dispatch(getAllChecklistItems({ start: 0, end: 1000 }));
		dispatch(getAllAccounts({ start: 0, end: 1000 }));
		dispatch(getAllIndexs({ status: "", start: 0, end: 10 }));
		// setshowTerminate(false);
		getContractDetails(id);
		setCompleteTermination(
			contractDatail?.[0]?.["termination_list"]?.[0]?.[
			"BL_COMPLETE_TERMINATION_TRM"
			] === 0
		);
		if (linkRef.current) {
			// window.open(linkRef.current.href, '_blank', 'noopener noreferrer');
		}
	}, []);

	useEffect(() => {
		if (!state.type) {
			dispatch(contractOpen(false));
			dispatch(getContractDetailsCount(Number(id)));
			dispatch(getAllContractChecklist({ id: id, start: 0, end: 10 }));
			dispatch(getAllExpense(state));
			// dispatch(getAllContractCharge(stateCharge));
		}
		if (state.type == "expense" || state.type == "yearly") {
			dispatch(getAllExpense(state));
		}
	}, [dispatch, state]);

	useEffect(() => {
		if (stateCharge.type == "charge") {
			dispatch(getAllContractCharge(stateCharge));
		}
	}, [stateCharge]);
 
	const extraChargeFormClosed = () => {
		setPageLoaded(true);
		setTimeout(() => {
			setPageLoaded(false);
			dispatch(getAllContractCharge(stateCharge));
		}, 10000);
	}

	const electronicEnvelopeListingCall = async () => {
		const response =
			await customerService.allElectronicEnvelopeForSpecificContract(id);
		if (response.data.resCode == 201 || response.data.resCode == 202) {
			const data = response.data.data;
			setElectronicEnvelopeListing(data);
		}
	};

	useEffect(() => {
		const tokenGenerate = async () => {
			let access_token = localStorage.getItem("access_token");

			// Set a fixed expiration time for the token (e.g., 1 hour)
			let expirationTime: any = localStorage.getItem("access_token_expires_at");
			if ((!access_token && expirationTime) || (expirationTime && Date.now() > expirationTime)) {
				const response = await customerService.getAccessToken();
				const newAccessToken = response.data.data;

				// Set the new access token and its expiration time in localStorage
				localStorage.setItem("access_token", newAccessToken);

				// Set a fixed expiration time (e.g., 1 hour from now)
				let fixedExpirationTime: any = Date.now() + 60 * 60 * 1000; // 1 hour in milliseconds
				localStorage.setItem("access_token_expires_at", fixedExpirationTime);
			}
		};

		const listAmendmant = async () => {
			const response = await customerService.listAmendmant(id);
			setAmendmant(response.data.data);
		};

		listAmendmant();
		electronicEnvelopeListingCall();
		tokenGenerate();
	}, []);

	useEffect(() => {
		if (
			contractDatail &&
			Array.isArray(contractDatail) &&
			contractDatail.length > 0
		) {
			// Check if contractDatail is defined, is an array, and has at least one element
			setValue("start", contractDatail[0].start2);
			guarantorData.forEach((item) => {
				if (item.id === contractDatail[0].guarantor) {
					setGurantorDetails(item);
				}
			});
			Property_Type?.forEach((proprtyType) => {
				if (proprtyType.value === contractDatail[0].proprty_type) {
					setShowPropertyType(proprtyType?.label);
				}
			});
			guarantors.forEach((element: any) => {
				if (element.value == contractDatail[0].guarantee) {
					setGurantorLabel(element?.label);
				}
			});
		}
		fetchInsuranceQuotes(contractDatail);
	}, [contractDatail, guarantorData, Property_Type, guarantors]);

	const fetchInsuranceQuotes = async (contractDetails: any) => {
		let contractDetailsData = await contractDetails;
		if (contractDetailsData.length > 0) {
			const response = await fetch(
				"https://api-seguros.dev.kenlo.io/insurance_api/legacy/auth/login",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						agency_license: "adm055927",
						loginvv: "lenilda.santos@ingaia.com.br",
						login: "apiv2@gmail.com.br",
					}),
				}
			);
			const data = await response.json();
			if (data.access_token != "") {
				const property_id = contractDetailsData[0].immobile;
				const fetchPropertyDetails = await dispatch(
					getProperty({
						id: property_id,
						contract: "contract",
						start: 0,
						end: 1,
					})
				);

				//return;
				const propertyDetails = fetchPropertyDetails.payload.data[0];
				const ownerId = propertyDetails ? propertyDetails.ownerResult[0].ownerId : "";
				const fetchOwnerDetails = await dispatch(getOwner());
				const ownerDetails = fetchOwnerDetails.payload.data.filter(
					(owner: { id: any }) => owner.id === ownerId
				);
				const fetchTenantsDetails = await dispatch(getTenants());
				const tenantIdString =
					contractDetailsData[0].datatenants.tenates[0].ID_PESSOA_PES;
				const tenantId = parseInt(tenantIdString);
				const tenantDetails = fetchTenantsDetails.payload.data.filter(
					(tenant: { id: any }) => {
						return tenant.id === tenantId;
					}
				);
				// return
				const response = await fetch(
					"https://api-seguros.dev.kenlo.io/insurance_api/legacy/properties/quote?apiKey=9e798944154b13a83e62cf96588cfe7f",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `${data.access_token}`,
						},
						body: JSON.stringify({
							property: {
								state: propertyDetails ? propertyDetails.state : "",
								city: propertyDetails ? propertyDetails.city : "",
								address: propertyDetails ? propertyDetails.address : "",
								cep: propertyDetails ? propertyDetails.zipcode : "",
								address_complement: "",
								address_number: propertyDetails ? propertyDetails.number : "",
								address_neighbourhood: propertyDetails ? propertyDetails.neighborhood : "",
							},
							contract_id: "1854",
							policy_duration: {
								effective_date_end: "2025-06-25",
								effective_date_start: "2023-12-25",
							},
							agency_license: "adm055927",
							CONTRATACAO_AUTOMATICA: 0,
							tenant: {
								phone: tenantDetails.length ? tenantDetails[0].mobile : '',
								birth_date: "",
								name: tenantDetails.length ? tenantDetails[0].name : '',
								gender: "M",
								email: tenantDetails.length ? tenantDetails[0].email : '',
								foreign: "",
								document_data: {
									emission_date: "",
									document_nature: "",
									document_number: tenantDetails.length ? tenantDetails[0].cnpj : '',
								},
								politically_exposed: "",
								document_number: "746.624.300-20",
								person_type: "Fisica",
							},
							property_owner: {
								birth_date: ownerDetails.birth,
								email: ownerDetails.email,
								document_number: ownerDetails.cnpj,
								name: ownerDetails.name,
								phone: ownerDetails.mobile,
								person_type: ownerDetails.fantasy,
							},
							user_email: "insurance-team@ingaia.com.br",
							insurance: {
								property_id: propertyDetails ? propertyDetails.id : "",
								renewal: "",
								main_coverage: 150000,
								zip_code: propertyDetails ? propertyDetails.zipcode : "",
								property_type: propertyDetails ? propertyDetails.propertyType : "",
								property_group_id: "1",
							},
						}),
					}
				);
				const quoteData = await response.json();
				setInsuranceQuotes(quoteData.Packages);
			}
		}
	};
	useEffect(() => {
		(async () => {
			let response = await incomeService.getDiscountData(gettoken.userId);
			if (response.status == "success" || response.status == "Success" || response.type == "Success" || response.type == "success") {
				setExtraAndDiscount(response.data);
			}
		})();
	}, []);

	let terminatonReason = [
		{
			value: 1,
			label: t("terminatelist.Rented_outside_the_real_estate_agency"),
		},
		{
			value: 2,
			label: t(
				"terminatelist.Rented_another_property_from_the_real_estate_agency"
			),
		},
		{ value: 3, label: t("terminatelist.Increased_family_birth_of_children") },
		{ value: 4, label: t("terminatelist.Bought_own_property") },
		{ value: 5, label: t("terminatelist.Illness_inability_to_live_alone") },
		{ value: 6, label: t("terminatelist.Termination_of_contract") },
		{
			value: 7,
			label: t(
				"terminatelist.Death_of_a_family_member_who_lived_in_the_same_property"
			),
		},
		{
			value: 8,
			label: t(
				"terminatelist.Death_of_the_guarantor_with_the_impossibility_of_another_guarantee"
			),
		},
		{ value: 9, label: t("terminatelist.Death_of_the_tenant_himself") },
		{ value: 10, label: t("terminatelist.Closed_the_business") },
		{ value: 11, label: t("terminatelist.Small_property") },
		{
			value: 12,
			label: t(
				"terminatelist.Property_without_conditions_for_issuing_a_permit_and/or_certificate_of_firefighter_regularity"
			),
		},
		{ value: 13, label: t("terminatelist.Tenant_moved_to_another_city") },
		{ value: 14, label: t("terminatelist.Structural_problem") },
		{ value: 15, label: t("terminatelist.Financial_problems") },
		{ value: 16, label: t("terminatelist.Problems_in_the_neighborhood") },
		{ value: 17, label: t("terminatelist.Owner_requested_property") },
		{ value: 18, label: t("terminatelist.Tenant_transfer") },
		{ value: 19, label: t("terminatelist.Transfer_of_Ownership") },
		{ value: 20, label: t("terminatelist.Rent_value/contract_fees") },
		{ value: 21, label: t("terminatelist.Withdrawal") },
		{ value: 22, label: t("terminatelist.Others") },
	];

	const getTransfer = (date, action) => {
		if (typeof action !== "undefined") {
			let filterYear = new Date(date);
			let startYear = currentYear;
			let endYear = nextYear;
			if (action == "Subtract") {
				startYear = moment(filterYear).subtract(1, "years").format("YYYY");
				endYear = moment(filterYear).format("YYYY");
			} else {
				startYear = moment(filterYear).format("YYYY");
				endYear = moment(filterYear).add(1, "years").format("YYYY");
			}
			setStateTransfer({
				...stateTransfer,
				["startYear"]: startYear,
				["endYear"]: endYear,
			});
		}
	};

	useEffect(() => {
		dispatch(getContractTransfer(stateTransfer));
	}, [stateTransfer]);

	const filterExpenseMode = (getMode) => {
		setStateMode(getMode);
		if (getMode == "monthly") {
			setState({
				...state,
				["startDate"]: startDt,
				["searchDate"]: searchDt,
				["endDate"]: endDt,
				["type"]: "expense",
			});
		} else {
			setState({
				...state,
				["yearlySearch"]: yearDt,
				["type"]: "yearly",
			});
		}
	};

	const TodayDate = moment();

	const filterExpense = (dt: any, tp: any) => {
		let currentDate = new Date(dt);
		if (typeof tp !== "undefined" && stateMode == "monthly") {
			if (tp == "Subtract") {
				startDt = moment(currentDate).subtract(1, "M").format("MMM/YYYY");
				searchDt = moment(startDt).add(1, "M").format("MMM/YYYY");
				endDt = moment(currentDate).add(1, "M").format("MMM/YYYY");
			} else {
				startDt = moment(currentDate).subtract(1, "M").format("MMM/YYYY");
				searchDt = moment(startDt).add(1, "M").format("MMM/YYYY");
				endDt = moment(currentDate).add(1, "M").format("MMM/YYYY");
			}
			setStateCharge({
				...stateCharge,
				["type"]: "",
			});
			setState({
				...state,
				["startDate"]: startDt,
				["searchDate"]: searchDt,
				["endDate"]: endDt,
				["type"]: "expense",
			});
		} else {
			if (tp == "Subtract") {
				yearDt = moment(state.yearlySearch).subtract(1, "year").format("YYYY");
			} else {
				yearDt = moment(state.yearlySearch).add(1, "year").format("YYYY");
			}
			setState({
				...state,
				["yearlySearch"]: yearDt,
				["type"]: "yearly",
			});
		}
	};

	const filterCharge = (dt, tp) => {
		if (typeof tp !== "undefined") {
			let currentDate = new Date(dt);
			if (tp == "Subtract") {
				startDt = moment(currentDate).subtract(1, "M").format("MMM/YYYY");
				searchDt = moment(startDt).add(1, "M").format("MMM/YYYY");
				endDt = moment(currentDate).add(1, "M").format("MMM/YYYY");
			} else {
				startDt = moment(currentDate).subtract(1, "M").format("MMM/YYYY");
				searchDt = moment(startDt).add(1, "M").format("MMM/YYYY");
				endDt = moment(currentDate).add(1, "M").format("MMM/YYYY");
			}
			setState({
				...state,
				["type"]: "",
			});
			setStateCharge({
				...stateCharge,
				["startDate"]: startDt,
				["searchDate"]: searchDt,
				["endDate"]: endDt,
				["type"]: "charge",
			});
		}
	};

	const [Emptycontract, setEmptycontract] = useState(false);
	const getContractDetails = async (contractId: any) => {
		let data = await dispatch(contractDatails(contractId));
		let totallenght: any = data?.["payload"]?.["data"];
		if (!totallenght || totallenght.length == 0) {
			// nagivate("/contracts")
			setEmptycontract(true)
		}
	};

	const newContractOpen = (evt) => {
		if (contractDatail.length > 0) {
			dispatch(contractOpen(true));
		}
	};

	const newQuoteOpen = (evt) => {
		let cst = checkContractStatus(contractDatail);
		if (contractDatail.length > 0 && cst) {
			dispatch(quoteOpen(true));
		}
	};
	//   const cancelInsurance = async (contractId) => {
	//     let data = await dispatch(cancelInsuranceRequest(contractId));
	//   };
	const cancelInsurance = async (contractId) => {
		Swal.fire({
			title: "Confirmation",
			text: "Are you sure you want to Cancel this Insurance?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, proceed!",
			cancelButtonText: "No, cancel",
		}).then(async (result) => {
			if (result.isConfirmed) {
				// User clicked confirm button
				// You can put your code for the action you want to perform here
				let data = await dispatch(cancelInsuranceRequest(contractId));
				if (data.payload.resCode == "201") {
					window.location.reload();
				} else {
					Swal.fire({
						title: t("Sweetalert.Error"),
						text: t("Sweetalert.somethingWent"),
						// icon: 'success',"error",
						confirmButtonText: "OK",
						timer: 2000,
					});
				}
				//Swal.fire("Success", "Your action was successful!", "success");
			} else {
				// User clicked cancel button or closed the popup
				Swal.fire("Cancelled", "Your action was cancelled", "error");
			}
		});
	};
	const newRenewInsuranceOpen = (evt) => {
		const currentDate = new Date(); // Get current date
		const dateParts = contractDatail[0].insEndLbl.split("/"); // Split the date string into day, month, and year parts

		// Assuming the date format is day/month/year
		const day = parseInt(dateParts[0]);
		const month = parseInt(dateParts[1]) - 1; // Months are 0-indexed in JavaScript Date objects
		const year = parseInt(dateParts[2]);

		// Create a Date object with the parsed day, month, and year
		const value = new Date(year, month, day);

		if (value > currentDate) {
			//   Swal.fire({
			//     title: t("Sweetalert.Warning"),
			//     text: "Your Insurance is in active state. Are you want to renew your Insurance Now?",
			//     // icon: 'success',"error",
			//     confirmButtonText: "Yes",
			//     timer: 8000,
			//   });
			Swal.fire({
				title: "Confirmation",
				text: "Your Insurance is in active Mode, Do You want to renew your insurance?",
				icon: "success",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Yes, proceed!",
				cancelButtonText: "No, cancel",
			}).then(async (result) => {
				if (result.isConfirmed) {
					let newStartDate = value;
					const startDate = new Date(newStartDate);
					const formattedStartDate = moment(startDate).format("DD/MM/YYYY");

					let newEndDate = value.setMonth(value.getMonth() + 12);
					const endDate = new Date(newEndDate);
					const formattedEndDate = moment(endDate).format("DD/MM/YYYY");
					setValue("start", formattedStartDate);
					setValue("end", formattedEndDate);
					let cst = checkContractStatus(contractDatail);
					if (contractDatail.length > 0 && cst) {
						dispatch(renewInsuranceOpen(true));
					}
				}
			});
		} else {
			let newStartDate = value;
			const startDate = new Date(newStartDate);
			const formattedStartDate = moment(startDate).format("DD/MM/YYYY");

			let newEndDate = value.setMonth(value.getMonth() + 12);
			const endDate = new Date(newEndDate);
			const formattedEndDate = moment(endDate).format("DD/MM/YYYY");
			setValue("start", formattedStartDate);
			setValue("end", formattedEndDate);
			let cst = checkContractStatus(contractDatail);
			if (contractDatail.length > 0 && cst) {
				dispatch(renewInsuranceOpen(true));
			}
		}
	};

	const newExtraCharge = (evt) => {
		if (contractDatail.length && contractDatail[0].suspended == 1) {
			constractisSuspended();
		} else {
			(async () => {
				let response = await incomeService.getDiscountData(gettoken.userId);
				if (response.status == "success" || response.status == "Success") {
					setExtraAndDiscount(response.data);
				}
			})();
			let cst = checkContractStatus(contractDatail);
			if (contractDatail.length > 0 && cst) {
				if (evt == "New charge - Tenant" || evt == "New charge - Owner") {
					dispatch(setExtraChargeLabel(evt));
					dispatch(newExtraChargeOpen(true));
				} else {
					dispatch(setExtraChargeLabel("Edit Charge"));
					dispatch(setExtraCharge(evt));
					dispatch(newExtraChargeOpen(true));
				}
			}
		}
	};

	const [myChecklists, setMyChklist] = useState<any>([]);
	const newChecklistsOpen = () => {
		let cst = checkContractStatus(contractDatail);
		if (contractDatail.length > 0 && cst) {
			setMyChklist(
				contractChecklist.data && contractChecklist.data.length > 0
					? contractChecklist.data[0]["myChecklists"]
					: ""
			);
			dispatch(newChecklistOpen(true));
		}
	};

	const newChecklistsItemOpen = (checkList) => {
		let cst = checkContractStatus(contractDatail);
		if (contractDatail.length > 0 && cst) {
			setMyChklist(checkList);
			dispatch(newChecklistItemOpen(true));
		}
	};

	const electronicEnvelopeOpen = () => {
		let cst = checkContractStatus(contractDatail);
		if (contractDatail.length > 0 && cst) {
			dispatch(newElectronicEnvelopeOpen(true));
		}
	};

	const rentalFeeOpen = () => {
		if (contractDatail.length && contractDatail[0].suspended == 1) {
			constractisSuspended();
		} else {
			let cst = checkContractStatus(contractDatail);
			if (contractDatail.length > 0 && cst) {
				dispatch(newRentalFeeOpen(true));
			}
		}
	};

	const expenseOpen = (evt) => {
		if (contractDatail.length && contractDatail[0].suspended == 1) {
			constractisSuspended();
		} else {
			let cst = checkContractStatus(contractDatail);
			if (contractDatail.length > 0 && cst) {
				dispatch(setExpenseTitle(evt));
				dispatch(setExpType(true));
				dispatch(setExpenseCheckbox(false));
				dispatch(setExpenseType(false));
				dispatch(newExpenseOpen(true));
			}
		}
	};

	const liquidateOpen = (evt) => {
		if (isCheck.length > 0) {
			if (evt === "Liquidate") {
				dispatch(setLiquidateType(true));
			} else {
				dispatch(setLiquidateType(false));
			}
			dispatch(setLiquidateTitle(evt));
			dispatch(newLiquidateOpen(true));
		} else {
			Swal.fire({
				title: t("Sweetalert.Error"),
				text: t("Sweetalert.Pleasecheckedthe"),
				// icon: 'success',"error",
				confirmButtonText: "OK",
				timer: 2000,
			});
		}
	};

	const showTheBoletoError = (AARINRESP : any) => {
		if( AARINRESP && AARINRESP != ''){
            let rm = JSON.parse(AARINRESP);
            if( rm.data && rm.data.length ){
                rm = rm.data[0].message + ' ' + rm.data[0].parameter;
                setBoletoPixAarinError(rm);
                setBoletoPixModal(true);
            } else if ( rm.length && rm.message != '' && rm.parameter != '' ){
				rm = rm[0].message + ' ' + rm[0].parameter;
				setBoletoPixAarinError(rm);
                setBoletoPixModal(true);
			}
        }
	}
	const [terminationResp, setTerminationResp] = useState([
		{ value: 1, label: "Agency" },
		{ value: 2, label: "Owner" },
		{ value: 3, label: "Tenent" },
		{ value: 4, label: "Manager" },
		{ value: 5, label: "Gurantor" },
	]);

	useEffect(() => {
		i18n.language == "en"
			? setTerminationResp([
				{ value: 1, label: "Agency" },
				{ value: 2, label: "Owner" },
				{ value: 3, label: "Tenent" },
				{ value: 4, label: "Manager" },
				{ value: 5, label: "Gurantor" },
			])
			: setTerminationResp([
				{ value: 1, label: "Agência" },
				{ value: 2, label: "Proprietário" },
				{ value: 3, label: "Inquilino" },
				{ value: 4, label: "Gerente" },
				{ value: 5, label: "Fiador" },
			]);
	}, [terminationResp && i18n.language]);

	const [allresponsibleList, setallresponsibleList] = useState([
		{
			agency: [],
			owner: [],
			tenent: [],
			manager: [],
			gurantor: [],
		},
	]);

	let managersData = { manager: "", start: 0, end: 3000 };
	let objectVal_agency: any = [];
	let objectsVal_owner: any = [];
	let objectsVal_tenant: any = [];
	let objectVal_manager: any = [];
	let objectVal_guarantor: any = [];

	useEffect(() => {
		if (contractDatail?.[0]?.["termination_date"]) {
			responsibleListFun();
		}
	}, [dispatch, userService, contractDatail?.[0]?.["termination_date"]]);

	useEffect(() => {
		if (
			contractDatail?.[0]?.["termination_date"] !== "" &&
			contractDatail?.[0]?.["termination_date"] !== undefined
		) {
			setshowTerminate(true);
		} else {
			setshowTerminate(false);
		}
	}, [contractDatail?.[0]?.["termination_date"]]);

	// useEffect(() => {
	//     dispatch(registerData());
	// }, [])

	const responsibleListFun = () => {
		// ------------Owner api detail------------

		contractDatail.length > 0 &&
			contractDatail[0].ownerResult.length > 0 &&
			contractDatail[0].ownerResult.forEach((row, indx) => {
				objectsVal_owner.push({
					value: row?.["ownerId"],
					label: row?.["ownerName"],
				});
			});

		//-------------------Tenent api detail-------------
		contractDatail[0]?.["datatenants"]?.["tenates"] &&
			contractDatail[0]?.["datatenants"]?.["tenates"].length > 0 &&
			contractDatail[0]["datatenants"]["tenates"].forEach(
				(itemsingle, index) => {
					objectsVal_tenant.push({
						value: itemsingle?.["ID_PESSOA_PES"],
						label: itemsingle?.["name"],
					});
				}
			);

		//-------------------Manager api detail-------------
		if (
			contractDatail?.[0]?.["manager"] !== "" &&
			contractDatail?.[0]?.["manager"] !== null &&
			contractDatail?.[0]?.["manager"] !== 0
		) {
			customerService.getAllManagers(managersData).then((res) => {
				if (res.data.resCode === 201) {
					let data = res.data.data.filter(
						(item) => Number(item["id"]) === contractDatail?.[0]?.["manager"]
					);
					objectVal_manager.push({
						value: data?.[0]?.["id"],
						label: data?.[0]?.["name"],
					});
				}
			});
		}

		//-------------------Gurantor api detail-------------
		if (
			contractDatail?.[0]?.["guarantor"] !== "" &&
			contractDatail?.[0]?.["guarantor"] !== null &&
			contractDatail?.[0]?.["guarantor"] !== 0
		) {
			let data = guarantorData.filter(
				(item) => Number(item["id"]) === contractDatail?.[0]?.["guarantor"]
			);
			objectVal_guarantor.push({
				value: data?.[0]?.["id"],
				label: data?.[0]?.["name"],
			});
		}

		//-------------------Agency api detail-------------

		userService.getAllUsers({ userId: "", start: 0, end: 1000 }).then((res) => {
			res?.["data"].forEach((itemsingle) => {
				objectVal_agency.push({
					value: itemsingle?.["ID_USUARIO_USU"],
					label: itemsingle?.["ST_APELIDO_USU"],
				});
			});
			allresponsibleList[0]["agency"] = objectVal_agency;
		});

		setallresponsibleList([
			{
				["agency"]: objectVal_agency,
				["owner"]: objectsVal_owner,
				["tenent"]: objectsVal_tenant,
				["manager"]: objectVal_manager,
				["gurantor"]: objectVal_guarantor,
			},
		]);
	};

	/*-------Checked/Unchecked multiple checkbox functionality--------*/

	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState<any[]>([]);
	const handleSelectAll = (e) => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(expenseData.data.map((li) => li.id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	const noneHandleSelectAll = (e) => {
		setIsCheckAll(false);
		setIsCheck([]);
	};

	const handleClick = (e) => {
		const { id, checked } = e.target;
		setIsCheck([...isCheck, parseFloat(id)]);
		if (!checked) {
			setIsCheck(isCheck.filter((item) => item !== parseFloat(id)));
		}
		if (document.querySelectorAll(".check_Checkbox input").length > 0) {
			let data = document.querySelectorAll(".check_Checkbox input");
			let count_allCheck = 0;
			setTimeout(() => {
				data &&
					data.length > 0 &&
					data.forEach((item, index) => {
						if (item["checked"]) {
							count_allCheck++;
						}
						if (!item["checked"]) {
							setIsCheckAll(false);
						}
					});
				if (count_allCheck == data.length) {
					setIsCheckAll(true);
				}
			}, 10);
		}
	};

	const constractisSuspended = () => {
		Swal.fire({
			title: t("Sweetalert.Error"),
			text: t("Sweetalert.contractSuspended"),
			// icon: 'success',"error",
			confirmButtonText: "OK",
			timer: 2000,
		});
	};

	const generateMeatOpen = () => {
		if (contractDatail.length && contractDatail[0].suspended == 1) {
			constractisSuspended();
		} else {
			let cst = checkContractStatus(contractDatail);
			if (contractDatail.length > 0 && cst) {
				dispatch(contractGenerateMeatOpen(true));
			}
		}
	};

	//Change financial status modal initialization
	const [showFinancialStatusModal, setFinancialStatusModal] = useState(false);
	const financialStatusModalClose = () => setFinancialStatusModal(false);
	const financialStatusModalShow = () => setFinancialStatusModal(true);

	//Pause contract modal initialization
	const pauseContractOpened = (e, type) => {
		dispatch(typeofcontract(type));
		dispatch(pauseContractOpen(true));
	};

	//Notified contract modal initialization
	const [showNotifiedContractModal, setNotifiedContractModal] = useState(false);
	const notifiedContractModalClose = () => setNotifiedContractModal(false);
	const notifiedContractModalShow = () => setNotifiedContractModal(true);

	//Terminate contract modal initialization
	const [showTerminateContractModal, setTerminateContractModal] =
		useState(false);
	const terminateContractModalClose = () => {
		setShowThisData(false);
		setTerminateContractModal(false);
	};

	const terminateContractModalShow = () => {
		setTerminateContractModal(true);
	};

	const SureWantToCancel = () => {
		setCancelTerminateModal(true)
	}

	// Contract Status Pending
	const checkContractStatus = (contractDetails) => {
		if (
			contractDetails &&
			contractDetails.length &&
			contractDetails[0].is_active == 0
		) {
			Swal.fire({
				title: t("Sweetalert.Warning"),
				text: t("Sweetalert.contractPending"),
				confirmButtonColor: "#f82e52",
				cancelButtonColor: "#d8d8d8",
				confirmButtonText: "Ok",
				showCancelButton: true,
			});
			return false;
		} else {
			return true;
		}
	};

	const deleteExpense = () => {
		if (isCheck.length > 0) {
			Swal.fire({
				title: t("Sweetalert.Youwontbeabletorevert"),
				text: t("Sweetalert.Youwontbeabletorevert"),
				// icon: 'success','warning',
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "OK",
			}).then((result) => {
				if (result.value) {
					let managersData = isCheck;
					customerService
						.deleteExpenses({ id: isCheck })
						.then((res) => {
							/*-------Check the api response--------*/
							if (res.data.resCode === 408) {
								Swal.fire({
									title: t("Sweetalert.Success"),
									text: t("Sweetalert.deletesuccess"),
									// icon: 'success',"success",
									confirmButtonText: "OK",
									timer: 2000,
								});
								dispatch(getAllExpense(state));
							} else {
								Swal.fire({
									title: t("Sweetalert.Error"),
									text: res.data.message,
									// icon: 'success',"error",
									confirmButtonText: "OK",
									timer: 2000,
								});
							}
						})
						.catch((error) => {
							Swal.fire({
								title: t("Sweetalert.Error"),
								text: t("Sweetalert.somethingWent"),
								// icon: 'success',"error",
								confirmButtonText: "OK",
								timer: 2000,
							});
						});
				}
			});
		} else {
			Swal.fire({
				title: t("Sweetalert.Error"),
				text: t("Sweetalert.Pleasecheckedthe"),
				// icon: 'success',"error",
				confirmButtonText: "OK",
				timer: 2000,
			});
		}
	};

	function formatDateToDDMMYYYY(dateString) {
		const date = new Date(dateString);
		const day = date.getDate().toString().padStart(2, "0");
		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	}

	const [keyHandOver, setkeyHandOver] = useState(false);
	const [keyHandOverData, setkeyHandOverData] = useState({
		date: "",
		tenant: "",
		cpf_cnjp: "",
		property_location: "",
	});

	const handleClose = () => {
		reset();
		setkeyHandOver(false);
	};

	const contractValidation = Yup.object().shape({
		start: Yup.string()
			.trim()
			.nullable()
			.required(t("requiredMmessage.fieldRequired")),
		key_handover_date: Yup.date()
			.typeError(t("requiredMmessage.fieldRequired"))
			.when("start", (start, schema) =>
				moment(start).isValid()
					? schema.min(start, t("formLabel.EnsureKeyHandDateAfterStartDate"))
					: schema
			),
	});

	let frm = {
		resolver: yupResolver(contractValidation),
	};

	const {
		register,
		reset,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
		setError,
		clearErrors,
	} = useForm<contractForm>(frm);
	const keyChange = (e) => {
		let listTenent = "";
		contractDatail[0]?.["datatenants"]?.["tenates"] &&
			contractDatail[0]?.["datatenants"]?.["tenates"].length > 0 &&
			contractDatail[0]["datatenants"]["tenates"].map((itemsingle, index) => {
				let pickData = tenantsData.filter(
					(item) =>
						Number(item?.["id"]) === Number(itemsingle?.["ID_PESSOA_PES"])
				);
				if (index !== contractDatail[0]["datatenants"]["tenates"].length - 1) {
					listTenent += pickData?.["0"]?.["name"] + ", ";
				} else {
					listTenent += pickData?.["0"]?.["name"];
				}
			});

		setkeyHandOverData({
			date: moment(e.target.value).format("DD/MM/YYYY"),
			tenant: String(listTenent),
			cpf_cnjp: "098765456788 --dummy",
			property_location:
				contractDatail.length > 0
					? contractDatail[0].address + " " + contractDatail[0].city
					: "",
		});
	};

	const onSubmit = (data) => {
		let keyhand: any = document?.querySelector(".keyhandover");
		keyhand.click();
		Swal.fire({
			title: t("Sweetalert.Success"),
			text: t("Sweetalert.Generatedfilekeyarehandovered"),
			// icon: 'success',"success",
			confirmButtonText: "OK",
			timer: 2500,
		}).then((res) => {
			handleClose();
		});
	};

	const handleEditExpenses = (e, id, type) => {
		document
			.querySelector("div[id=root]")
			?.setAttribute("expense_data", JSON.stringify([id, type]));
		expenseOpen(type);
	};

	function compareDates(targetDate) {
		// Get the current date
		const currentDate = new Date(dateBrazil);
		// Parse the target date
		const dateToCompare = new Date(targetDate);
		// Compare the two dates
		if (currentDate < dateToCompare) {
			return "-1";
		} else if (currentDate > dateToCompare) {
			return "1";
		} else {
			return "0";
		}
	}

	function compareFireInsuranceDate(targetDate) {
		// Get the current date
		const currentDate: any = new Date(dateBrazil);

		// Parse the target date
		const dateToCompare: any = new Date(targetDate);

		// Check if the dates are on the same day
		if (
			currentDate.getDate() === dateToCompare.getDate() &&
			currentDate.getMonth() === dateToCompare.getMonth() &&
			currentDate.getFullYear() === dateToCompare.getFullYear()
		) {
			return "Today";
		}

		// Calculate the difference in milliseconds
		const differenceInMilliseconds: any = dateToCompare - currentDate;

		if (differenceInMilliseconds < 0) {
			// Handle the case where targetDate is in the past
			const daysAgo = Math.floor(
				-differenceInMilliseconds / (1000 * 60 * 60 * 24)
			);
			const monthsAgo = Math.floor(daysAgo / 30);
			const yearsAgo = Math.floor(monthsAgo / 12);

			if (yearsAgo > 0) {
				return yearsAgo + (yearsAgo === 1 ? " year ago" : " years ago");
			} else if (monthsAgo > 0) {
				return monthsAgo + (monthsAgo === 1 ? " month ago" : " months ago");
			} else {
				return daysAgo + (daysAgo === 1 ? " day ago" : " days ago");
			}
		} else {
			return "In the future"; // Handle the case where targetDate is in the future
		}
	}

	let insuranceComparison: any = "";
	if (contractDatail.length > 0 && contractDatail[0].end) {
		// end : fire insurance end date
		insuranceComparison = compareFireInsuranceDate(contractDatail[0].end);
	}

	function checkReadjustDateAndShowAlert(dateString: any) {
		// Parse the input date string into a Date object
		const inputDate = new Date(dateString);
		// Get the current date
		const currentDate = new Date(dateBrazil);
		// Check if the input date's year and month are less than or equal to the current year and month
		if (
			inputDate.getFullYear() <= currentDate.getFullYear() &&
			inputDate.getMonth() <= currentDate.getMonth()
		) {
			return "1";
		} else {
			return "0";
		}
	}

	const [showCancelTerminateModal, setCancelTerminateModal] = useState(false);
	const [loader_on, setloader_on] = useState(false);
	const hideClose = () => {
		setCancelTerminateModal(false);
	}

	const HandleCancel = () => {
		setloader_on(true);
		let data: any = {};
		data["contract_id"] = id;
		customerService.CancelinitialTermination(data).then(async (res: any) => {
			if (res["data"]["type"] == "success" || res["data"]["type"] == "Success") {
				await dispatch(contractDatails(data["contract_id"]));
				hideClose();
				Swal.fire({
					icon: "success",
					title: t("successCancledTermination"),
					timer: 4000
				})
			} else {
				Swal.fire({
					title: t("Sweetalert.Error"),
					text: t("Sweetalert.successfailed"),
					confirmButtonText: "OK",
					timer: 2000
				})
			}
			setloader_on(false);
		}).catch(e => {
			setTimeout(() => {
				hideClose();
			}, 16000)
		})
	}

	const markChecklistChecked = (e: any, ItemId, checklistId) => {
		let isChecked = e.target.checked;
		let GetId = e.target.id;
		let upObj = {
			itemId: ItemId,
			checklistId: checklistId,
			isChecked: isChecked,
			contractId: contractDatail[0].id,
		};
		customerService
			.updateChecklist(upObj)
			.then((res) => {
				if (res.data.resCode === 202) {
					e.target.checked = isChecked;
					Swal.fire({
						title: t("Sweetalert.Success"),
						text: t("Sweetalert.updatesuccess"),
						// icon: 'success',"success",
						confirmButtonText: "OK",
						timer: 2000,
					});
				} else {
					e.target.checked = false;
					Swal.fire({
						title: t("Sweetalert.Error"),
						text: t("Sweetalert.deletefailed"),
						// icon: 'success',"error",
						confirmButtonText: "OK",
						timer: 2000,
					});
				}
			})
			.catch((error) => {
				e.target.checked = false;
				Swal.fire({
					title: t("Sweetalert.Error"),
					text: t("Sweetalert.somethingWent"),
					// icon: 'success',"error",
					confirmButtonText: "OK",
					timer: 2000,
				});
			});
	};

	function getResponsiblePerson(value) {
		let resPerson = "";
		if (value == 1) {
			resPerson = t("checklistTenant");
		} else if (value == 2) {
			resPerson = t("checklistOwner");
		} else if (value == 3) {
			resPerson = t("checklistGuarantor");
		} else {
			resPerson = t("checkListRealEstate");
		}
		return resPerson;
	}

	useEffect(() => {
		const shouldHideAlert = localStorage.getItem("hideContractAlert");
		if (shouldHideAlert === "true") {
			setShowAlert(false);
		}
	}, []);

	const handleRefreshClick = () => {
		// localStorage.setItem('hideContractAlert', 'true');
		// setShowAlert(false);
		navigate("/readjustContracts", {
			state: {
				contractId: id,
				type: "contract_redjust",
			},
		});
	};

	const contractDelete = async (e: any, contractId: any) => {
		e.preventDefault();
		Swal.fire({
			title: t("Sweetalert.Areyousure"),
			text: t("Sweetalert.Youwontbeabletorevert"),
			// icon: 'success','warning',
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: t("button.yesdelete"),
			cancelButtonText: `${t("button.cancel")}`
		}).then(async (result) => {
			if (result.isConfirmed) {
				let response = await customerService.deleteContract({ id: contractId });
				if (response?.data?.resCode === 408) {
					Swal.fire({
						title: t("Sweetalert.Delete"),
						text: t("Sweetalert.deletesuccess"),
						// icon: 'success',"success",
						showConfirmButton: false,
						timer: 1000,
					});
					navigate("/contracts");
				} else if (response?.data?.resCode === 416) {
					Swal.fire({
						title: t("Sweetalert.Delete"),
						text: response?.data?.message,
						// icon: 'success',"info",
						showConfirmButton: true,
					});
				} else if (response?.data?.resCode === 420) {
					Swal.fire({
						title: t("Sweetalert.Delete"),
						text: i18n.language == "en" ? "Please delete charge and expense for this contract before deleting contract." : "Por favor, exclua a cobrança e a despesa deste contrato antes de excluir o contrato.",
						// icon: 'success',"info",
						showConfirmButton: true,
					})
				} else {
					Swal.fire({
						title: t("Sweetalert.Error"),
						text: t("Sweetalert.somethingWent"),
						// icon: 'success',"error",
						confirmButtonText: "OK",
						timer: 2000,
					});
				}
			}
		});
	};

	const getContractChecklist = async (evt) => {
		evt.preventDefault();
		if (id) {
			let response = await customerService.getContractsChecklistData({
				id: id,
			});
			if (response.data && response.data.resCode == 201) {
				let url = response.data.data[0].fileUrl;
				window.open(url, "_blank");
			} else {
				Swal.fire({
					title: t("Sweetalert.Error"),
					text: response.data.message,
					// icon: 'success',"error",
					confirmButtonText: "OK",
				});
			}
		} else {
			Swal.fire({
				title: t("Sweetalert.Error"),
				text: "Something went wrong",
				// icon: 'success',"error",
				confirmButtonText: "OK",
				timer: 2000,
			});
		}
	};

	const suspendedCharge = async (e, id) => {
		e.preventDefault();
		if (id) {
			Swal.fire({
				title: t("Sweetalert.Areyousure"),
				text: "You would be able to revert this!",
				// icon: 'success','warning',
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Yes, suspended it!",
			}).then(async (result) => {
				if (result.isConfirmed) {
					let response = await customerService.suspendedCharge({ id: id });
					if (response.data && response.data.resCode == 200) {
						dispatch(getAllContractCharge(stateCharge));
						Swal.fire({
							title: t("Sweetalert.Success"),
							text: t("Sweetalert.updatesuccess"),
							// icon: 'success',"success",
							confirmButtonText: "OK",
							timer: 2000,
						});
					} else {
						Swal.fire({
							title: t("Sweetalert.Error"),
							text: response.data.message,
							// icon: 'success',"error",
							confirmButtonText: "OK",
							timer: 2000,
						});
					}
				}
			});
		} else {
			Swal.fire({
				title: t("Sweetalert.Error"),
				text: "Something went wrong",
				// icon: 'success',"error",
				confirmButtonText: "OK",
				timer: 2000,
			});
		}
	};

	const [paymentinfo, setPaymentInfo] = useState<any>("");
	const [canRegBoletoModal, setCanRegBoletoModal] = useState(false);
	const [regenratedate, setregenratedate] = useState(false);

	const cancelRegenBoleto = async (e: any, item: any) => {
		/*
		setPaymentInfo({
		  billingType:
			item.BILLING_TYPE == "PIX"
			  ? 12
			  : item.BILLING_TYPE == "BOLETO"
				? 0
				: "",
		  ID_RECEBIMENTO_RECB: item.objdata[0].ID_RECEBIMENTO_RECB,
		  ID_CONTRATO_CON: item.objdata[0].ID_CONTRATO_CON,
		});
		setCanRegBoletoModal(true);
		*/
	};

	const canRegBoleto = () => {
		setCanRegBoletoModal(false);
	};

	const cancelRegFrmSubmit = (data) => {
		let startDtt = moment(data.startDate).format("YYYY-MM-DD");
		let argument: any = paymentinfo;
		argument["maturity"] = startDtt;
		argument["type"] = data.lease_period;
		incomeService.cancelPaymentStatus(argument).then((res) => {
			if (
				res.data &&
				(	res.data[0].status == "DONE" ||
					res.data[0].status == "Cancelado" ||
					res.data[0].status == "CONCLUIDA" ||
					res.data[0].status == 'ProcessingCancellation' )
			) {
				Swal.fire({
					title: t("Sweetalert.Success"),
					text: t("Sweetalert.PaymentiscanceledonAarin"),
					confirmButtonText: "OK",
					timer: 2000,
				});
			} else if (res.data && res.data.resCode == 202) {
				Swal.fire({
					title: t("Sweetalert.Success"),
					text: res.data.message,
					confirmButtonText: "OK",
					timer: 2000,
				});
				dispatch(getAllContractCharge(stateCharge));
				setCanRegBoletoModal(false);
			} else {
				Swal.fire({
					title: t("Sweetalert.Error"),
					text: res.data.status
						? 'Curent payment status is "' + res.data.status + '"'
						: t("Sweetalert.PaymentisnotCompleted"),
					// icon: 'success',"error",
					confirmButtonText: "OK",
					timer: 2000,
				});
			}
		});
	};

	const boletoAction = (evt) => {
		setregenratedate(false);
		if (evt.target.value == "CancelRegenrateBoleto") {
			setregenratedate(true);
		}
	};

	const settledCharges = async (e, id) => {
		e.preventDefault();
		if (id) {
			let response = await customerService.settledCharge({ id: id });
			if (response.data && response.data.resCode == 201) {
				let url = response.data.data[0].fileUrl;
				window.open(url, "_blank");
			} else {
				Swal.fire({
					title: t("Sweetalert.Error"),
					text: response.data.message,
					// icon: 'success',"error",
					confirmButtonText: "OK",
				});
			}
		} else {
			Swal.fire({
				title: t("Sweetalert.Error"),
				text: "Something went wrong",
				// icon: 'success',"error",
				confirmButtonText: "OK",
				timer: 2000,
			});
		}
	};

	const handleShow = (e, cmd) => {
		dispatch(contractDateSearch(true));
		setChargeFilter(cmd);
	};

	const filterClose = () => {
		dispatch(contractDateSearch(false));
		reset();
	};

	const startDate = () => {
		let today: any = "";
		if (getValues("startDate")) {
			today = getValues("startDate");
		} else {
			today = new Date(dateBrazil).toISOString().split("T")[0];
		}
		return today;
	};

	const dateSubmit = (data) => {
		let startDt = moment(data.startDate).format("MMM/YYYY");
		let endDt = moment(data.endDate).format("MMM/YYYY");
		if (chagerfilter == "charge") {
			setStateCharge({
				...stateCharge,
				["startDate"]: startDt,
				["endDate"]: endDt,
				["type"]: "charge",
			});
		} else {
			setStateTransfer({
				...stateTransfer,
				["startYear"]: startDt,
				["endYear"]: endDt,
			});
		}
		dispatch(contractDateSearch(false));
		reset();
	};

	const handleRouteFolderDetail = async (e, id1) => {
		let data = {
			readjustId: id1,
			contract_id: id,
		};
		navigate(`/folder_detail`, { state: data });
	};

	// const nth = (n) => {
	// 	let mt = [((((n + 90) % 100) - 10) % 10) - 1] || ""
	// 	return n + "" + mt;
	// };

	const HandleTransfer = (e: any, contId: any, receId: any) => {
		e.preventDefault();
		let data = {
			completed: "completed",
			ID_CONTRATO_CON: contId,
			ID_RECEBIMENTO_RECB: receId,
		};

		// navigate("/bank_transactions", { state: data })
		navigate("/paid", { state: data });
	};

	const handleDocumentClick = () => {
		let data = {
			contract_id: id,
			type: "contract_Doc",
		};
		navigate(`/documents`, { state: data });
	};

	const handleNewDocumentClick = () => {
		let data = {
			contract_id: id,
		};
		navigate(`/documents_list`, { state: data });
	};

	// Function to toggle settled filter
	const toggleSettledFilter = () => {
		setSettledFilter(!settledFilter);
	};

	// Filter function to filter settled charges
	const filterSettledCharges = (data) => {
		return settledFilter ? data.filter((item) => item.status == 2) : data;
	};

	// Scroll function
	useEffect(() => {
		if (checklistScroll == "checklist") {
			if (status1 === "success" || status1 === "idle") {
				setItemCount("0");
				setTimeout(() => {
					if (targetRef.current !== null) {
						targetRef.current.scrollIntoView({ behavior: "smooth" });
					}
				}, 50);

				// Resetting location to blank after data is received
			}
		}
	}, [status1]);

	const SendEmail = async (e: any, item: any) => {
		setProcessEamil(true);
		let DescArray: any = {};
		let newText: any =
			` <!DOCTYPE html>
                    <html>
                      <head>
                        <meta charset="UTF-8">
                      </head>
                      <body style="font-family:sans-serif;margin-left:2em;">
                       <header>
                       <img className="form-control" src=${getlogo?.logo_name &&
			IMAGEURLLINK.replace(
				"{logoname}",
				(getlogo?.logo_name)
					.split("_|_")[0]
					.replace("/", "%2F")
			).replace(
				"{generation}",
				(getlogo?.logo_name).split("_|_")[1]
			)
			} alt="logo">
                       </header>
                       <h1>KenlooooA pagou há 49 dias.</h1>
                       <p>Hello ${item?.name}, </p>
                       <p>For your convenience, we provide the statement of the transfer made  <b>49 days ago</b> .</p>
                       <p><b>Email </b>: ${item?.email ? item?.email : ""},</p>
                       <p><b>Payment </b>: ${item?.maturity}</p>
                       <p><b>Value </b>: ${item?.totalAmount}</p>
                          <div style="background-color:#F5F5F5; width: 100%; margin: 0 !important; padding: 0 !important;">
                          <div style="max-width: 600px; margin:auto;">
                            <div style="margin: 0 15px;  padding:32px 10px 32px 10px">
                            <img className="form-control" src=${getlogo?.logo_name &&
			IMAGEURLLINK.replace(
				"{logoname}",
				(getlogo?.logo_name)
					.split("_|_")[0]
					.replace("/", "%2F")
			).replace(
				"{generation}",
				(getlogo?.logo_name).split("_|_")[1]
			)
			} alt="logo">
                            </div>
                          </div>
                       </div>
                      </body>
                    </html>
            ` || ""; // Use the actual property name if needed

		let emaildata: any = {
			status: "new",
			subject: "Email vindo do POSTMAN",
			content: newText,
			layout: "default",
			sender: "noreply@dev.kenlo.io",
			to: [item.email],
			cc: ["vikash@wegile.com"],
			bcc: ["karan@wegile.com"],
			identifier: "1",
			model: "1",
			title: "Extract for IR declaration",
		};
		let res = await dispatch(emailPost(emaildata));
		setProcessEamil(true);

		if (res.payload.resCode == 201) {
			setProcessEamil(false);
			Swal.fire({
				title: t("Sweetalert.Success"),
				text: t("Sweetalert.mailsent"),
				// icon: 'success',"success",
				confirmButtonText: "OK",
				timer: 2000,
			});
			reset();
		} else {
			Swal.fire({
				title: t("Sweetalert.Error"),
				text: t("Sweetalert.somethingWent"),
				// icon: 'success',"error",
				confirmButtonText: "OK",
				timer: 2000,
			});
			setProcessEamil(false);
		}
	};
	useEffect(() => {
		if (location?.state?.type == "propert_Deatils") {
			setPropertyTypes("propert_Deatils");
		}
	}, [location.state]);

	const [readjustMentDate, setReadjustmentDate] = useState<any>({
		readjust_start: moment(new Date()).format("DD/MM/YYYY"),
		readjust_end: moment(new Date()).add(12, "months").format("DD/MM/YYYY"),
		diff: 12,
	});

	let ReadjustData = (
		startDate: any = new Date(),
		endDate: any = new Date()
	) => {
		let startNew: any = moment(startDate, "YYYY-MM-DD");
		let endNew: any = moment(endDate).format("YYYY-MM-DD");
		let monthsBetween: any = getMonthsBetween(startNew, endNew);
		setReadjustmentDate({
			diff: monthsBetween,
			readjust_start: moment(startNew).format("DD/MM/YYYY"),
			readjust_end: moment(startNew).add(1, "years").format("DD/MM/YYYY"),
		});
	};

	useEffect(() => {
		if (contractDatail && contractDatail?.[0]?.start2 && contractDatail?.[0]?.end2) {
			ReadjustData(contractDatail?.[0].readjustment_date && contractDatail?.[0].readjustment_date !== "Invalid date" ? moment(contractDatail?.[0].readjustment_date, "DD-MM-YYYY").format("YYYY-MM-DD") : contractDatail?.[0].start2, contractDatail?.[0].end2);
		}
	}, [contractDatail]);

	function getMonthsBetween(startDate: any, endDate: any) {
		let start = new Date(startDate);
		let end = new Date(endDate);

		let years = end.getFullYear() - start.getFullYear();
		let months = end.getMonth() - start.getMonth();
		return years * 12 + months;
	}

	const handleOwnerRouteChange = (e, ownerId) => {
		e.preventDefault();
		let data = {
			contract_id: id,
		};
		navigate(`/owner_details/${ownerId}`, { state: data });
	};

	const handleTenantRouteChange = (e, tenantId) => {
		e.preventDefault();
		let data = {
			contract_id: id,
		};
		navigate(`/profile_details/${tenantId}`, { state: data });
	};

	const moveToCharge = (e, ID_RECEBIMENTO_RECB, FL_STATUS_IMOD) => {
		e.preventDefault();
		let data = {
			ID_RECEBIMENTO_RECB: ID_RECEBIMENTO_RECB,
		};
		if (FL_STATUS_IMOD == 0) {
			navigate('/receivable_charges', { state: data });
		}
		else {
			navigate('/received', { state: data });
		}
	}

	const [ showInvalidationModal,setInvalidationModal ] = useState(false);
	const [ type,setType ] = useState("");
	const [ recevId,setRecevId ] = useState<any>([]);

	const cancelInvalidate = async(title,recevId)=>{
		setInvalidationModal(true);
		setType(title);
		setRecevId([recevId]);
	}

	const cancelInvalidateClose = ()=>{
		setInvalidationModal(false);
		dispatch(getAllContractCharge(stateCharge));
	}
	if (Emptycontract) {
		return <EmptyReason />
	} else {
		return (
			<>
				<div hidden>
					<PDFDownloadLink
						document={<KeyHandOver keyHandOverData={keyHandOverData} />}
						fileName={`keyhandover_${new Date(dateBrazil).getTime()}.pdf`}
					>
						<Button className={"keyhandover"}>{t("KeyHandOver")}</Button>
					</PDFDownloadLink>
				</div>
				<InvalidateBoletoService showInvalidationModal={showInvalidationModal} cancelInvalidateClose={cancelInvalidateClose} recevId={recevId} type={type} />
				<section className="content-wrapper">
					{/*  <!-- Breadcrumb section start from here --> */}
					<div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3 overflow-visible">
						<div className="w-100">
							<Row className="gx-xl-5 justify-content-between align-items-center">
								<Col
									xs={{ order: 12 }}
									xl={{ order: 0 }}
									className="mb-xl-0 mb-3 col-xl"
								>
									<div className="d-flex align-items-center justify-content-start">
										{PropertyTypes ? (
											<Link
												to={"/property_detail/" + contractDatail[0]?.propertyId}
											>
												<img
													src={
														process.env.PUBLIC_URL +
														"/assets/images/icons/arrow-left.svg"
													}
													className="h-14"
													alt="Arrow left"
												/>
											</Link>
										) : (
											<Link to={"/contracts"}>
												<img
													src={
														process.env.PUBLIC_URL +
														"/assets/images/icons/arrow-left.svg"
													}
													className="h-14"
													alt="Arrow left"
												/>
											</Link>
										)}
										<h1 className="mb-0 h4 lh-base ms-2 ms-lg-3 ms-xl-4 text-nowrap fw-medium wt-contrctdetls">
											{t("contractDetail")}
										</h1>
										<div className="w-100 ms-3 d-flex align-items-center">
											<Badge bg="light" className="text-dark fw-medium">
												{contractDatail.length > 0
													? contractDatail[0].property_identifier +
													"/" +
													contractDatail[0].lease_count
													: ""}
											</Badge>
											{contractDatail &&
												contractDatail.length > 0 &&
												contractDatail[0].suspended == 0 &&
												contractDatail?.[0]?.["termination_list"]?.[0]?.[
												"BL_COMPLETE_TERMINATION_TRM"
												] == 0 ? (
												<Badge bg="warning-10" className="ms-1 text-dark">
													{t("terminated")}
												</Badge>
											) : (
												<>
													{contractDatail.length > 0 &&
														contractDatail[0].suspended == 0 &&
														contractDatail[0].is_active == "1" &&
														compareDates(contractDatail[0].end2) !== "1" ? (
														<Badge className="text-dark ms-1" bg="success-10">
															{contractDatail.length > 0 ? t("active") : ""}
														</Badge>
													) : (
														""
													)}
													{contractDatail.length > 0 &&
														contractDatail[0].suspended == 0 &&
														contractDatail[0].is_active == "0" ? (
														<Badge className="text-white ms-1" bg="danger">
															{contractDatail.length > 0 ? t("pending") : ""}
														</Badge>
													) : (
														""
													)}
													{contractDatail.length > 0 &&
														contractDatail[0].suspended == 0 &&
														contractDatail[0].is_active == "4" ? (
														<Badge className="text-white ms-1" bg="secondary">
															{contractDatail.length > 0 ? t("paused") : ""}
														</Badge>
													) : (
														""
													)}
													{contractDatail.length > 0 &&
														contractDatail[0].suspended == 0 &&
														compareDates(contractDatail[0].end2) == "1" ? (
														<Badge className="text-white ms-1" bg="warning">
															{contractDatail.length > 0 ? t("expired") : ""}
														</Badge>
													) : (
														""
													)}
												</>
											)}
											{/*<Badge className="text-light ms-1" bg="secondary">
												{contractDatail.length > 0 &&
													compareDates(contractDatail[0].end2) == "1"
													? "Suspended"
													: ""}
											</Badge>*/}
											{contractDatail.length &&
												contractDatail[0].suspended == 1 && (
													<Badge className="bg-opacity-75 ms-1" bg="dark">
														{t("suspended")}
													</Badge>
												)}
										</div>
									</div>
								</Col>
								<Col
									xs={{ span: 12, order: 1 }}
									xl={{ order: 3, span: "auto" }}
									className="text-xl-end mb-xl-0 mb-2"
								>
									<ul className="list-inline mb-0">
										{userJson &&
											userJson.length > 0 &&
											userJson.indexOf(2031) !== -1 && (
												<>
													<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-sm-0 mb-1">
														<Dropdown>
															<Dropdown.Toggle
																variant="link"
																className="p-0 fw-bold dropdown-arrow-none"
																size="sm"
																id="dropdown-basic"
															>
																{t("button.action")}
																<img
																	src="../assets/images/icons/arrow-bottom-primary.svg"
																	alt="Plus icon"
																	className="ms-1 h-8"
																/>
															</Dropdown.Toggle>
															<Dropdown.Menu className="mh-456 overflow-auto">
																{contractDatail.length > 0 &&
																	contractDatail[0].suspended == 0 &&
																	contractDatail[0].is_active == "4" ? (
																	""
																) : (
																	<Dropdown.Item onClick={generateMeatOpen}>
																		{t("generateCharges")}
																	</Dropdown.Item>
																)}

																<Dropdown.Item href="#">
																	{t("historic")}
																</Dropdown.Item>
																{!showTerminate ?
																	<Dropdown.Item onClick={terminateContractModalShow}>{t("initiateTermination")}</Dropdown.Item>
																	:
																	<Dropdown.Item onClick={SureWantToCancel} >{t("cancelTermination")}</Dropdown.Item>}
																{contractDatail.length > 0 &&
																	contractDatail[0].suspended == 0 &&
																	contractDatail[0].is_active == "4" ? (
																	<Dropdown.Item
																		onClick={(e) =>
																			pauseContractOpened(e, "Active")
																		}
																	>
																		{t("activeContract")}
																	</Dropdown.Item>
																) : (
																	<Dropdown.Item
																		onClick={(e) =>
																			pauseContractOpened(e, "Pause")
																		}
																	>
																		{t("pauseContract")}
																	</Dropdown.Item>
																)}
																<Dropdown.Item
																	onClick={notifiedContractModalShow}
																>
																	{t("notifyContract")}
																</Dropdown.Item>

																{/*<Dropdown.Item onClick={financialStatusModalShow}>Change financial status</Dropdown.Item>
                                                                      <Dropdown.Item onClick={AlertMsg}>Cancel suspension</Dropdown.Item>
                                                                      <Dropdown.Item onClick={AlertMsg}>Invite to owner and tenant areas</Dropdown.Item>
                                                                      <Dropdown.Item href="#">Invite to use the Owli app</Dropdown.Item>
                                                                      <Dropdown.Item onClick={AlertMsg}>Invite to use card</Dropdown.Item>*/}
																{contractDatail.length > 0 ? (
																	<Dropdown.Item
																		onClick={(e) =>
																			contractDelete(e, contractDatail[0].id)
																		}
																	>
																		{t("button.delete")}
																	</Dropdown.Item>
																) : (
																	""
																)}
															</Dropdown.Menu>
														</Dropdown>
													</li>
													<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-sm-0 mb-1">
														<Dropdown>
															<Dropdown.Toggle
																variant="link"
																className="p-0 fw-bold dropdown-arrow-none"
																size="sm"
																id="dropdown-basic2"
															>
																{t("printOut")}
																<img
																	src="../assets/images/icons/arrow-bottom-primary.svg"
																	alt="Plus icon"
																	className="ms-1 h-8"
																/>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item
																	href="#"
																	onClick={handleNewDocumentClick}
																>
																	{t("documents")}
																</Dropdown.Item>

																{/* <Dropdown.Item href="#">
                                  {t("contract")}
                                </Dropdown.Item>
                                <Dropdown.Item href="#">{t("tag")}</Dropdown.Item>
                                <Dropdown.Item
                                  href="#"
                                  onClick={() => setkeyHandOver(true)}
                                >
                                  {t("keyTerm")}
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                  {t("fireInsurance")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={(e) => getContractChecklist(e)}
                                >
                                  {t("checkList")}
                                </Dropdown.Item> */}
															</Dropdown.Menu>
														</Dropdown>
													</li>
												</>
											)}

										<li className="list-inline-item mb-sm-0 mb-1">
											<Button
												variant="link"
												className="p-0 fw-bold"
												onClick={handleDocumentClick}
											>
												{t("documents")}
											</Button>
										</li>
									</ul>
								</Col>
							</Row>
						</div>
					</div>
					{/* <!-- /Breadcrumb section end --> */}
					{/* <!-- Main content start from here --> */}
					<main
						className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3"
						id="contractdetailstsx"
					>
						{status1 == "loading" ? (
							<Loader />
						) : (
							<Row className="h-100 gy-lg-4 gy-3">
								<Col xl={3}>
									<Card className="h-100">
										<Card.Body className="p-3">
											<h4 className="mb-1">
												{contractDatail.length > 0
													? t(`${contractDatail[0].contractTypeLabel}`)
													: ""}
											</h4>
											{/* {
												(contractDatail?.[0]?.["start2"] && contractDatail?.[0]?.["end2"]) ?
													<>
														<p className="mb-1 fs-12 fw-medium">{contractDatail.length > 0 ? t("from") + " " + moment(contractDatail[0].start2).format("DD/MM/YYYY") + " " + ("to") + " " + moment(contractDatail[0].end2).format("DD/MM/YYYY") : ' '}</p>
													</>
													:
													<p className="mb-1 fs-12 fw-medium">{contractDatail.length > 0 ? t("from") + " " + contractDatail[0].contStartLbl + " " + ("to") + " " + contractDatail[0].contEndLbl : ''}</p>
											} */}
											{readjustMentDate?.["readjust_start"] ? (
												<>
													<p className="mb-1 fs-12 fw-medium">
														{ t("from")+"  " }
														{ contractDatail.length > 0 ? contractDatail?.[0]?.contStartLbl : "" }
														{ "  "+t("to")+"  " }
														{ contractDatail.length > 0 ? Number(contractDatail[0].duration) !== 60 || Number(contractDatail[0].duration) !== 600 ? contractDatail?.[0]?.contEndLbl : t("Indefinite") : ""}
													</p>
												</>
											) : (
												<p className="mb-1 fs-12 fw-medium">
													{contractDatail.length > 0
														? t("from") +
														" " +
														contractDatail[0].contStartLbl +
														" " +
														t("to") +
														" " +
														contractDatail[0].contEndLbl
														: ""}
												</p>
											)}
											<p className="mb-0 fs-12 fw-medium">
												{contractDatail.length > 0
													? t("occupation") +
													" " +
													contractDatail[0].occupationLbl
													: ""}{" "}
												-{" "}
												{contractDatail.length > 0
													? t("dueDate") + " " + contractDatail[0].due_date
													: ""}
											</p>
										</Card.Body>
									</Card>
								</Col>
								<Col xl={3}>
									<Card className="h-100">
										<Card.Body className="d-flex align-items-start flex-column p-3">
											<div className="d-flex align-items-center">
												<span className="icon flex-shrink-0 me-1">
													<img
														src={
															process.env.PUBLIC_URL +
															"/assets/images/icons/overdue_charges.svg"
														}
														alt="Documents"
													/>
												</span>
												<h4 className="mb-0">
													{singleContCount.length > 0
														? singleContCount[0].overdueCharge
														: 0}
												</h4>
											</div>
											<span className="d-block text-secondary mt-auto">
												{singleContCount.length > 0
													? singleContCount[0].overdueCharge == 0
														? t("overdueCharges")
														: singleContCount[0].overdueCharge > 1
															? t("overdueCharges")
															: t("overdueCharge")
													: t("overdueCharges")}
											</span>
										</Card.Body>
									</Card>
								</Col>
								<Col xl={3}>
									<Card className="h-100">
										<Card.Body className="d-flex align-items-start flex-column p-3">
											<div className="d-flex align-items-center">
												<span className="icon flex-shrink-0 me-1">
													<img
														src={
															process.env.PUBLIC_URL +
															"/assets/images/icons/transfers_complete.svg"
														}
														alt="transfers"
													/>
												</span>
												<h4 className="mb-0">
													{singleContCount.length > 0
														? singleContCount[0].transferAmount
														: 0}
												</h4>
											</div>
											<span className="d-block text-secondary mt-auto">
												{singleContCount.length > 0
													? singleContCount[0].transferComplete
													: 0}{" "}
												{singleContCount.length > 0
													? singleContCount[0].transferComplete == 0
														? t("transfersCompletes")
														: singleContCount[0].transferComplete > 1
															? t("transfersCompletes")
															: t("transfersComplete")
													: t("transfersCompletes")}
											</span>
										</Card.Body>
									</Card>
								</Col>
								<Col xl={3}>
									<Card className="h-100">
										<Card.Body className="d-flex align-items-start flex-column p-3">
											<div className="d-flex align-items-center">
												<span className="icon flex-shrink-0 me-1">
													<img
														src={
															process.env.PUBLIC_URL +
															"/assets/images/icons/Inspections3.svg"
														}
														alt="guaranteed"
													/>
												</span>
												<h4 className="mb-0">
													{singleContCount.length > 0
														? singleContCount[0].monthGuaranted
														: 0}
												</h4>
											</div>
											<span className="d-block text-secondary mt-auto">
												{singleContCount.length > 0
													? singleContCount[0].monthGuaranted == 1
														? t("monthsGuaranteed")
														: t("monthsGuaranteeds")
													: t("monthsGuaranteeds")}
											</span>
										</Card.Body>
									</Card>
								</Col>

								{/* {contractDatail.length > 0 && contractDatail[0].rent_value !== 0 && contractDatail[0].rent_value !== "" ?
                                  "" :
                                  <Col xl={12}>
                                      <Alert variant="warning" className="d-flex align-items-center mb-0">
                                          <img src={process.env.PUBLIC_URL + "/assets/images/icons/triangle-exclamation.svg"} alt="Exclamation" className="flex-shrink-0 me-2 h-16" />This contract has no rental fee posted. If it has already been released, <span onClick={handleRefreshClick} style={{ cursor: 'pointer', textDecoration: 'underline' }}>click here</span>.
                                      </Alert>
                                  </Col>
                              } */}

								{/* {contractDatail.length > 0 && compareDates(contractDatail[0].end) == "1" ?
                                  <Col xl={12}>
                                      <Alert variant="warning" className="d-flex align-items-center mb-0">
                                          <img
                                              src={process.env.PUBLIC_URL + "/assets/images/icons/triangle-exclamation.svg"}
                                              alt="Exclamation"
                                              className="flex-shrink-0 me-2 h-16"
                                          />
                                          {`Insurance expired ${insuranceComparison}`}
                                      </Alert>
                                  </Col> : ""
                              } */}

								{/* && showAlert */}
								{/* checkReadjustDateAndShowAlert(
									readjustMentDate["readjust_end"]
								) === "1" */}
								{readjustMentDate["readjust_end"] &&
									TodayDate.isAfter(moment(readjustMentDate["readjust_end"], 'DD/MM/YYYY'))
									? (
										<Col xl={12}>
											<Alert
												variant="warning"
												className="d-flex align-items-center mb-0"
											>
												<img
													src={
														process.env.PUBLIC_URL +
														"/assets/images/icons/triangle-exclamation.svg"
													}
													alt="Exclamation"
													className="flex-shrink-0 me-2 h-16"
												/>
												{t("Contractalerts")} {" "}
												<span
													onClick={handleRefreshClick}
													className="cursor-pe text-primary"
												>
													&nbsp; {t("ReceiveandReceived.Clickhere")} &nbsp;
												</span>{" "}
												{t("toreadjust")}
											</Alert>
										</Col>
									) : (
										""
									)}
								{/* {contractDatail.length > 0 && checkReadjustDateAndShowAlert(contractDatail[0].end2) === "1" && showAlert ? (
                                  <Col xl={12}>
                                      <Alert variant="warning" className="d-flex align-items-center mb-0">
                                          <img src={process.env.PUBLIC_URL + "/assets/images/icons/triangle-exclamation.svg"} alt="Exclamation" className="flex-shrink-0 me-2 h-16" />
                                          Contract needs to be readjusted. {' '}
                                          <span onClick={handleRefreshClick} className="cursor-pe text-primary">&nbsp;Click here&nbsp;</span> {' '} to see the contracts to readjust.
                                      </Alert>
                                  </Col>
                              ) : null} */}

								{/* {contractDatail.length > 0 && contractDatail[0].guarantee2 == "0" ?
                                  <Col xl={12}>
                                      <Alert variant="warning" className="d-flex align-items-center mb-0">
                                          <img src={process.env.PUBLIC_URL + "/assets/images/icons/triangle-exclamation.svg"} alt="Exclamation" className="flex-shrink-0 me-2 h-16" />This contract does not have a registered guarantee.
                                      </Alert>
                                  </Col> : ""
                              } */}

								<Col xl={6} className="wtContractDetls">
									<Card className="mb-lg-4 mb-3">
										<Card.Header className="d-flex align-items-center justify-content-between">
											{t("contract")}
											{userJson &&
												userJson.length > 0 &&
												userJson.indexOf(2031) !== -1 && (
													<div style={{ textAlign: "right" }}>
														{/* Change Contract */}
														<Link to={`/edit_contract/${id}`}>
															<Button
																variant="link"
																className="fw-bold p-0"
																size="sm"
															>
																{t("changeContract")}
															</Button>
														</Link>

														{/* Renewal Contract */}
														{contractDatail.length > 0 &&
															contractDatail[0].suspended === 0 &&
															compareDates(contractDatail[0].end2) === "1" && (
																<Link to={`/edit_contract/${id}`}>
																	<Button
																		variant="link"
																		className="fw-bold p-0 ms-2 ms-xl-3 ms-xxl-4"
																		style={{ marginLeft: "8px" }}
																		size="sm"
																	>
																		{t("renewContract")}
																	</Button>
																</Link>
															)}
													</div>
												)}
										</Card.Header>
										<Card.Body className="pb-1">
											<ListGroup variant="flush">
												<ListGroup.Item>
													<Row>
														<Col md={4} xxl={3}>
															{t("Branch")}
														</Col>
														<Col md xl xxl>
															{contractDatail.length > 0 &&
																contractDatail[0].branchLavel
																? contractDatail[0].branchLavel
																: ""}
														</Col>
													</Row>
												</ListGroup.Item>
												<ListGroup.Item>
													<Row>
														<Col md={4} xxl={3}>
															{t("immobile")}
														</Col>
														<Col md xl xxl>
															{contractDatail.length > 0 ? (
																<Link
																	to={
																		"/property_detail/" +
																		contractDatail[0].propertyId
																	}
																	className="text-dark text-wrap"
																>
																	<img
																		src={
																			process.env.PUBLIC_URL +
																			"/assets/images/icons/home_g.svg"
																		}
																		className="me-1"
																	/>
																	{contractDatail.length > 0
																		? showPropertyType +
																		" " +
																		t("in") +
																		" " +
																		contractDatail[0].neighborhood +
																		", " +
																		contractDatail[0].address +
																		" " +
																		contractDatail[0].number +
																		" " +
																		contractDatail[0].complement +
																		", " +
																		"CEP:" +
																		" " +
																		contractDatail[0].zipcode
																		: ""}
																</Link>
															) : (
																""
															)}
														</Col>
													</Row>
												</ListGroup.Item>
												<ListGroup.Item>
													<Row>
														<Col md={4} xxl={3}>
															{t("rent")}
														</Col>
														<Col md xl xxl>
															<span className="d-block">
																{ contractDatail.length > 0 ? contractDatail[0].rent_value2 : "" }{" "}
																{ contractDatail.length > 0 ? contractDatail[0]?.paymentMethod == 1 ?  " - (" +t("monthdue")+")"   :  " - (" +t("monthExpired")+ ")" : "" }
																{ contractDatail.length > 0 ? contractDatail[0]?.lease_period == 1 ?   " - (" +t("closeMonth")+")"  :  " - (" +t("openMonth")+ ")" : ""}
															</span>
															<span className="d-block">
																{contractDatail.length > 0 &&
																	contractDatail[0].charge_renter
																	? t("chargeBankFee") + " "
																	: ""}
															</span>
															<span className="d-block">
																{contractDatail.length > 0 &&
																	contractDatail[0].charge_renter_boleto
																	? t("chargeBoletoFee")
																	: ""}
															</span>
														</Col>
													</Row>
												</ListGroup.Item>
												<ListGroup.Item>
													<Row>
														<Col md={4} xxl={3}>
															{t("proportionRent")}
														</Col>
														<Col md xl xxl>
															{contractDatail.length > 0
																? "R$" +
																" " +
																converter(`${contractDatail[0]?.proprtyRent}`) +
																", " +
																contractDatail[0]?.proportionLabel
																: "-"}
														</Col>
													</Row>
												</ListGroup.Item>
												<ListGroup.Item>
													<Row>
														<Col md={4} xxl={3}>
															{t("admTxt")}
														</Col>
														<Col md xl xxl>
															{contractDatail[0]?.fixed_amount === 1 ? "R$" : ""}{" "}
															{contractDatail.length > 0
																? converter(`${contractDatail[0].monthlyAdmin}`)
																: ""}{" "}
															{contractDatail[0]?.fixed_amount === 1 ? "" : "%"}
														</Col>
													</Row>
												</ListGroup.Item>
												<ListGroup.Item>
													<Row>
														<Col md={4} xxl={3}>
															{t("nfsE")}
														</Col>
														<Col md xl xxl>
															{contractDatail.length > 0 &&
																contractDatail[0].invoice == 0
																? t("doSystem")
																: t("issueBySystem")}
														</Col>
													</Row>
												</ListGroup.Item>
												<ListGroup.Item>
													<Row>
														<Col md={4} xxl={3}>
															{t("transfer")}
														</Col>
														<Col md xl xxl>
															{contractDatail &&
																contractDatail.length > 0 &&
																contractDatail[0]?.transfer_type == 1 ? (
																<>
																	{" "}
																	{contractDatail.length > 0
																		? contractDatail[0]?.transfer_day
																		: ""}{" "}
																	{t("businessPayment")}
																</>
															) : contractDatail[0]?.transfer_type == 2 ? (
																<>
																	{t("every")}{" "}
																	{contractDatail.length > 0
																		? contractDatail[0]?.transfer_day +
																		", " +
																		contractDatail[0]?.guarantee2Label
																		: ""}{" "}
																</>
															) : contractDatail[0]?.transfer_type == 3 ? (
																<>
																	{" "}
																	{contractDatail.length > 0
																		? contractDatail[0]?.transfer_day
																		: ""}{" "}
																	{t("calendarPayment")}
																</>
															) : (
																""
															)}
														</Col>
													</Row>
												</ListGroup.Item>
												<ListGroup.Item>
													<Row>
														<Col md={4} xxl={3}>
															{t("transferRate")}
														</Col>
														<Col md xl xxl>
															{contractDatail[0]?.fixed_amount === 1
																? "(R$)"
																: ""}{" "}
															{contractDatail.length > 0
																? converter(`${contractDatail[0]?.monthlyAdmin}`)
																: ""}{" "}
															{converter(`${contractDatail[0]?.fixed_amount}`) ===
																1
																? " "
																: "(%)"}
														</Col>
													</Row>
												</ListGroup.Item>
												<ListGroup.Item>
													<Row>
														<Col md={4} xxl={3}>
															{t("billingEnd")}
														</Col>
														<Col md xl xxl>
															{contractDatail.length > 0
																? contractDatail[0].billing_addressLabel ==
																	"Definir outro endereço"
																	? showPropertyType +
																	" " +
																	t("in") +
																	" " +
																	contractDatail[0].neighborhood +
																	"," +
																	contractDatail[0].address +
																	" " +
																	contractDatail[0].number +
																	" " +
																	contractDatail[0].complement +
																	"," +
																	"CEP:" +
																	" " +
																	contractDatail[0].zipcode
																	: contractDatail[0].billing_addressLabel
																: ""}
														</Col>
													</Row>
												</ListGroup.Item>
												<ListGroup.Item>
													<Row>
														<Col md={4} xxl={3}>
															{t("bankAccount")}
														</Col>
														<Col md xl xxl>
															{contractDatail.length > 0
																? contractDatail[0].account +
																"--" +
																contractDatail[0].nameReference
																: ""}
														</Col>
													</Row>
												</ListGroup.Item>
												<ListGroup.Item>
													<Row>
														<Col md={4} xxl={3}>
															{t("deliveryForm")}
														</Col>
														<Col md xl xxl>
															{contractDatail.length > 0
																? contractDatail[0].deliveryMethodLabel
																: ""}
														</Col>
													</Row>
												</ListGroup.Item>
											</ListGroup>
											<h4 className="mt-lg-4 mt-3">{t("readjustment")}</h4>
											<ListGroup variant="flush">
												<ListGroup.Item>
													{contractDatail.length > 0
														? t("index") +
														" " +
														contractDatail[0].readjustment_indexLabel
														: ""}
												</ListGroup.Item>
												<ListGroup.Item>
													<Row>
														<Col md={5} xl={5} xxl={4}>
															{t("nextReadjustment")}
														</Col>
														<Col md xl xxl>
															{/* <p className="mb-1 fs-12 fw-medium">{t("from") + " " + readjustMentDate.readjust_start + " " + ("to") + " " + readjustMentDate.readjust_end}</p> */}
															{/* {contractDatail && contractDatail.length > 0 && contractDatail[0]?.postponeDate ?
                                  contractDatail[0]?.postponeDate
                                  :
                                  moment(contractDatail[0]?.end2).format('DD/MM/YYYY')
                              } */}
															{readjustMentDate?.readjust_end &&
																readjustMentDate["readjust_end"]}
														</Col>
													</Row>
												</ListGroup.Item>
												<ListGroup.Item>
													<Row>
														<Col md={5} xl={5} xxl={4}>
															{t("lastReadjustment")}
														</Col>
														<Col md xl xxl>
															{/* {contractDatail && contractDatail.length > 0 ?
                              moment(contractDatail[0].start2).format('DD/MM/YYYY')
                              : ''} 
                              */}
															{
																(readjustMentDate?.readjust_start &&
																	readjustMentDate["readjust_start"])
															}
														</Col>
													</Row>
												</ListGroup.Item>
											</ListGroup>
											<h4 className="mt-lg-4 mt-3">{t("locator")}</h4>
											<ListGroup variant="flush">
												{contractDatail.length > 0 &&
													contractDatail[0].ownerResult.length > 0
													? contractDatail[0].ownerResult.map((row, indx) => (
														<ListGroup.Item key={indx}>
															<Row className="align-items-center">
																<Col>{row.ownerName}</Col>
																<Col className="text-end">
																	<Button
																		variant="link"
																		className="text-primary fw-bold p-0"
																		onClick={(e) =>
																			handleOwnerRouteChange(e, row.ownerId)
																		}
																	>
																		{t("seeOwner")}
																	</Button>
																</Col>
															</Row>
														</ListGroup.Item>
													))
													: ""}
											</ListGroup>
											<h4 className="mt-lg-4 mt-3">{t("tenant")}</h4>
											<ListGroup variant="flush">
												<ListGroup.Item>
													{contractDatail[0]?.["datatenants"]?.["tenates"] &&
														contractDatail[0]?.["datatenants"]?.["tenates"]
															.length > 0 &&
														contractDatail[0]["datatenants"]["tenates"].map(
															(itemsingle, index) => {
																return (
																	<Row className="align-items-center" key={index}>
																		<Col>{itemsingle.name}</Col>
																		<Col className="text-end">
																			<Button
																				variant="link"
																				className="text-primary fw-bold p-0"
																				onClick={(e) =>
																					handleTenantRouteChange(
																						e,
																						itemsingle.ID_PESSOA_PES
																					)
																				}
																			>
																				{t("seeTenant")}
																			</Button>
																		</Col>
																	</Row>
																);
															}
														)}
												</ListGroup.Item>
											</ListGroup>
										</Card.Body>
									</Card>
									<Accordion defaultActiveKey="0">
										<Accordion.Item className="position-relative" eventKey="0">
											<div className="position-absolute end-0 top-0 mt-2 pt-1 me-5 me-sm-6 pe-2 z-index-5">
												{!completeTermination &&
													userJson &&
													userJson.length > 0 &&
													userJson.indexOf(2031) !== -1 && (
														<Button
															variant="link"
															className="p-0 fw-bold"
															size="sm"
															onClick={newRenewInsuranceOpen}
														>
															{t("addInsurance")}
														</Button>
													)}
											</div>
											<Accordion.Header>
												{contractDatail.length > 0 &&
													contractDatail[0].assuredValue !== ""
													? t("FireInsurance")
													: t("Doesnothaveinsurance")}{" "}
											</Accordion.Header>
											{contractDatail.length > 0 &&
												contractDatail[0].assuredValue !== "" &&
												contractDatail[0].insuranceCompanyName !== ""  ? (
												<Accordion.Body>
													<ul className="list-inline d-sm-flex align-items-center justify-content-start mb-lg-4 mb-3">
														{userJson &&
															userJson.length > 0 &&
															userJson.indexOf(2031) !== -1 && (
																<>
																	<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-sm-0 mb-1">
																		<Button
																			variant="link"
																			className="p-0 fw-bold"
																			size="sm"
																			onClick={newRenewInsuranceOpen}
																		>
																			{t("renewInsurance")}
																		</Button>
																	</li>
																	{/* <li className="list-inline-item mb-sm-0 mb-1">
                                    <Link to={`/edit_contract/${id}`}>
                                      <Button
                                        onClick={newQuoteOpen}
                                        className="p-0 fw-bold"
                                        variant="link"
                                        size="sm"
                                      >
                                        {t("getQuote")}
                                      </Button>
                                    </Link>
                                  </li> */}
																	<li className="list-inline-item mb-sm-0 mb-1">
																		<Button
																			onClick={(e) => cancelInsurance(id)}
																			className="p-0 fw-bold"
																			variant="link"
																			size="sm"
																		>
																			{t("Cancel Insurance")}
																		</Button>
																	</li>
																</>
															)}
													</ul>
													<ListGroup variant="flush">
														<ListGroup.Item>
															<Row>
																<Col md={4} xl={4} xxl={3}>
																	{t("validity")}
																</Col>
																<Col xl md xxl>
																	{t("from") +
																		"\u00A0" +
																		"\u00A0" +
																		(contractDatail.length > 0
																			? contractDatail[0].insStartLbl
																			: "") +
																		"\u00A0" +
																		"\u00A0" +
																		t("to") +
																		"\u00A0" +
																		"\u00A0" +
																		(contractDatail.length > 0
																			? contractDatail[0].insEndLbl
																			: "")}
																</Col>
															</Row>
														</ListGroup.Item>
														<ListGroup.Item>
															<Row>
																<Col md={4} xl={4} xxl={3}>
																	{t("value")}
																</Col>
																<Col xl md xxl>
																	{contractDatail.length > 0
																		? "R$ " + contractDatail[0].assuredValue
																		: ""}
																</Col>
															</Row>
														</ListGroup.Item>
														{contractDatail[0].assuredValue !== "" ? (
															<ListGroup.Item>
																<Row>
																	<Col md={4} xl={4} xxl={3}>
																		{t("assuredAmount")}
																	</Col>
																	<Col xl md xxl>
																		{contractDatail.length > 0
																			? "R$ " + contractDatail[0].assuredValue
																			: ""}
																	</Col>
																</Row>
															</ListGroup.Item>
														) : (
															""
														)}
														{contractDatail[0].installmentValue !== "" ? (
															<ListGroup.Item>
																<Row>
																	<Col md={4} xl={4} xxl={3}>
																		{t("installmentValue")}
																	</Col>
																	<Col xl md xxl>
																		{contractDatail.length > 0
																			? "R$ " + contractDatail[0].installmentValue
																			: ""}
																	</Col>
																</Row>
															</ListGroup.Item>
														) : (
															""
														)}
														{contractDatail[0].prizeAmount !== "" ? (
															<ListGroup.Item>
																<Row>
																	<Col md={4} xl={4} xxl={3}>
																		{t("award")}
																	</Col>
																	<Col xl md xxl>
																		{contractDatail.length > 0
																			? contractDatail[0].prizeAmount
																			: ""}
																	</Col>
																</Row>
															</ListGroup.Item>
														) : (
															""
														)}
														<ListGroup.Item>
															<Row>
																<Col md={4} xl={4} xxl={3}>
																	{t("insuranceCompany")}
																</Col>
																<Col xl md xxl>
																	{contractDatail.length > 0
																		? contractDatail[0].insuranceCompanyName +
																		", policy " +
																		contractDatail[0].policy2
																		: ""}
																</Col>
															</Row>
														</ListGroup.Item>
														{contractDatail[0].numberInstallments2 !== "" ? (
															<ListGroup.Item>
																<Row>
																	<Col md={4} xl={4} xxl={3}>
																		{t("numberInstallment")}
																	</Col>
																	<Col xl md xxl>
																		{contractDatail.length > 0
																			? contractDatail[0].numberInstallments2
																			: ""}
																	</Col>
																</Row>
															</ListGroup.Item>
														) : (
															""
														)}
														{contractDatail[0].contractedPlan !== "" ? (
															<ListGroup.Item>
																<Row>
																	<Col md={4} xl={4} xxl={3}>
																		{t("contractPlan")}
																	</Col>
																	<Col xl md xxl>
																		{contractDatail.length > 0
																			? contractDatail[0].contractedPlan
																			: ""}
																	</Col>
																</Row>
															</ListGroup.Item>
														) : (
															""
														)}
														{contractDatail[0].responsible4 !== "" ? (
															<ListGroup.Item>
																<Row>
																	<Col md={4} xl={4} xxl={3}>
																		{t("contractPlan")}
																	</Col>
																	<Col xl md xxl>
																		{contractDatail.length > 0
																			? contractDatail[0].responsible4 === 1
																				? t("realEstate")
																				: contractDatail[0].responsible4 === 2
																					? t('tenant')
																					: contractDatail[0].responsible4 === 3
																						? t('owner')
																						: ""
																			: ""}
																	</Col>
																</Row>
															</ListGroup.Item>
														) : (
															""
														)}

														{/*  */}
														<ListGroup.Item>
															<Row>
																<Col md={4} xl={4} xxl={3}>
																	{t("observation")}
																</Col>
																<Col xl md xxl>
																	{contractDatail.length > 0
																		? contractDatail[0].observation4
																		: ""}
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
												</Accordion.Body>
											) : (
												<Accordion.Body>
													<div className="text-center border text-secondary p-xl-4 p-3 rounded-4">
														<Row
															className={`gy-3 gy-lg-4 mb-lg-4 mb-3`} >
															{insuranceQuotes &&
																insuranceQuotes.map((record, index) => (
																	<Col md={6}>
																		<Card
																			className={`border border-2 text-center`}
																			//onClick={() => handleInsurance(index, record)}
																			style={{ cursor: "pointer" }}
																		>
																			<Card.Body className="p-lg-4">
																				<h4 className="mb-4">{t(record.Name)}</h4>
																				<img
																					src={
																						process.env.PUBLIC_URL +
																						"/assets/images/temp/tokio_icon.png"
																					}
																					alt="tokio icon"
																					className="img-fluid mx-auto d-block"
																				/>
																				<span className="d-block text-secondary fs-12">
																					{t(
																						"newContractDev.inCollaborationKenloInsurance"
																					)}
																				</span>
																				<div className="fw-bold my-4">
																					{record.Installments}x{" "}
																					<span className="h3 lh-1">
																						R${" "}
																						{record.TotalCalculatedByInstallments}
																					</span>{" "}
																					<span className="fs-12 text-secondary d-block mt-1 ms-2">
																						R$ {record.Total}{" "}
																						{t("newContractDev.inTotal")}
																					</span>
																				</div>
																				<div className="d-flex justify-content-center text-start">
																					<ul className="list-unstyled">
																						{record.Coverages.map(
																							(coverage, idx) => (
																								<li className="mb-3" key={idx}>
																									{coverage.value === 0 ? (
																										<img
																											title="contractInsurance"
																											src={
																												process.env.PUBLIC_URL +
																												"/assets/images/icons/cross-w.svg"
																											}
																											alt="cross icon"
																											className="h-16 cursor-pe me-lg-2 me-1"
																										/>
																									) : (
																										<img
																											title="contractInsurance"
																											src={
																												process.env.PUBLIC_URL +
																												"/assets/images/icons/check.svg"
																											}
																											alt="cross icon"
																											className="h-16 cursor-pe me-lg-2 me-1"
																										/>
																									)}
																									{t(coverage.text)}
																								</li>
																							)
																						)}
																					</ul>
																				</div>
																				<span className="d-block text-dark-70 fs-12">
																					{t(
																						"newContractDev.insuranceAutomaticallyContractedActivated"
																					)}
																				</span>
																			</Card.Body>
																		</Card>
																	</Col>
																))}
														</Row>
														<div className="text-center border text-secondary p-xl-4 p-3 rounded-4">
															{t("insurencefireDescription")}
														</div>

													</div>
												</Accordion.Body>
											)}
										</Accordion.Item>
										<Accordion.Item className="position-relative" eventKey="1">
											<Accordion.Header>
												{contractDatail.length > 0 &&
													contractDatail[0].guaranteeLabel
													? `${t(contractDatail[0].guaranteeLabel)}`
													: t("hasNoWarranty")}
											</Accordion.Header>
											<Accordion.Body>
												{!completeTermination && (
													<WarrantyService setGurantorLabel={setGurantorLabel} />
												)}
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item className="position-relative" eventKey="2">
											<div className="position-absolute end-0 top-0 mt-2 pt-1 me-5 me-sm-6 pe-2 z-index-5">
												<Button
													variant="link"
													size="sm"
													className="p-0 fw-bold"
													onClick={(e) => handleShow(e, "transfer")}
												>
													{t("periodfilter")}
												</Button>
											</div>
											<Accordion.Header>
												{t("Transfersmadein")} {stateTransfer.searchDate}{" "}
											</Accordion.Header>
											<Accordion.Body>
												{/*<ul
                                                  className="list-inline d-sm-flex align-items-center justify-content-start mb-lg-4 mb-3">
                                                  <li className="list-inline-item me-lg-3 mb-sm-0 mb-1">
                                                      <Button type="button" variant="light" size="sm">
                                                          {t("banks.moreOptions")}
                                                      </Button>
                                                  </li>
                                                  <li className="list-inline-item mb-sm-0 mb-1">
                                                      <Button type="button" variant="light" size="sm">
                                                          {t("Groupedstatementof")} { stateTransfer.startYear }
                                                      </Button>
                                                  </li>
                                              </ul>*/}
												{/* <div className="text-center mb-lg-4 mb-3">
                                                  <ButtonGroup size="sm" aria-label="Date">
                                                      <Button variant="light" onClick={()=>getTransfer(stateTransfer.startYear, 'Subtract')}>
                                                          <img src={process.env.PUBLIC_URL + "/assets/images/icons/chevron-sm-left.svg"} className="me-1" />
                                                          { stateTransfer.startYear }
                                                      </Button>
                                                      <Button variant="light" onClick={()=>getTransfer(stateTransfer.endYear, 'Add')}>
                                                          { stateTransfer.endYear }
                                                          <img src={process.env.PUBLIC_URL + "/assets/images/icons/chevron-sm-right.svg"} className="ms-1" />
                                                      </Button>
                                                  </ButtonGroup>
                                              </div> */}
												{/* <!-- Table start from here --> */}
												{transferStatus == "loading" ? (
													<Loader />
												) : (
													<Table
														responsive
														className="table-custom table-custom-bordered mb-0"
													>
														<thead>
															<tr>
																<th></th>
																<th>{t("maturity")}</th>
																<th></th>
																<th className="text-end">{t("value")}</th>
															</tr>
														</thead>
														<tbody>
															{contTransferData.data &&
																contTransferData.data.length > 0 ? (
																contTransferData.data.map(
																	(item: any, index: number) => {
																		return (
																			<tr key={index}>
																				<td>
																					<span>
																						{" "}
																						<img
																							className="h-14 pe"
																							src="../assets/images/icons/send-light.svg"
																							alt="send"
																							onClick={(e) => SendEmail(e, item)}
																						/>
																					</span>
																				</td>
																				<td className="text-dark-70">
																					<span className="d-block text-secondary">
																						{item.maturity}
																					</span>
																					{processEamil ? (
																						<span className="text-primary fw-bold">
																							{t("Processing")}
																						</span>
																					) : (
																						""
																					)}
																				</td>
																				<td>
																					{t("transfer")}
																					<br />
																					<span>
																						<Link
																							to={"#"}
																							onClick={(e) =>
																								HandleTransfer(
																									e,
																									item.ID_CONTRATO_CON,
																									item.recId
																								)
																							}
																							className="text-dark-70"
																						>
																							{" "}
																							{item.property_identifier +
																								"/" +
																								" " +
																								item.neighborhood +
																								"," +
																								item.address +
																								" " +
																								item.number +
																								" " +
																								item.complement +
																								"," +
																								"CEP:" +
																								" " +
																								item.zipcode}
																						</Link>
																					</span>
																				</td>
																				<td className="text-end text-dark-70">
																					<span className="text-nowrap d-block">
																						{" "}
																						R$ -{item.totalPaidAmount}
																					</span>
																				</td>
																			</tr>
																		);
																	}
																)
															) : (
																<tr>
																	<td colSpan={4}>
																		<div className="text-center p-1 text-secondary">
																			{t("formLabel.noRecordsFounds")}
																		</div>
																	</td>
																</tr>
															)}
														</tbody>
													</Table>
												)}
												{/* <!-- /Table end --> */}
												<p className="pt-1 mb-0">
													{t("formLabel.listing")}{" "}
													{contTransferData.data
														? contTransferData.data.length
														: 0}{" "}
													{t("expenditure.expenses")}.
												</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</Col>
								
								<Col xl={6} className="wtExpensDetls">
									{showTerminate && (
										<TerminateListing
											terminateContractModalShow={terminateContractModalShow}
											ShowThisData={ShowThisData}
											setShowThisData={setShowThisData}
											show={show}
											toggleShow={toggleShow}
											showTerminateContract={showTerminateContractModal}
											terminationResp={terminationResp}
											allresponsibleList={allresponsibleList}
											setallresponsibleList={setallresponsibleList}
											completeTermination={completeTermination}
											setCompleteTermination={setCompleteTermination}
											terminatonReason={terminatonReason}
										/>
									)}
									<Card className="mb-lg-4 mb-3">
										<Card.Header className="d-sm-flex align-items-center justify-content-between">
											{stateMode === "monthly" ? (
												<>
													{t("Expensesfor")}{" "}
													{t(`half_month.${state.searchDate.split("/")[0]}`) +
														"/" +
														state.searchDate.split("/")[1]}
												</>
											) : (
												<>
													{t("Expensesfor")} {state.yearlySearch}
												</>
											)}
											<ul className="list-inline d-flex align-items-center justify-content-end mb-0">
												{!completeTermination &&
													contractDatail.length > 0 &&
													contractDatail[0].suspended == 0 &&
													contractDatail[0].is_active == "1" ? (
													<>
														{userJson &&
															userJson.length > 0 &&
															userJson.indexOf(2031) !== -1 && (
																<>
																	<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-sm-0 mb-1">
																		<Dropdown>
																			<Dropdown.Toggle
																				variant="link"
																				size="sm"
																				className="p-0 fw-bold dropdown-arrow-none"
																				id="new_expense"
																			>
																				{t("newExpense")}
																				<img
																					src="../assets/images/icons/arrow-bottom-primary.svg"
																					alt="Plus icon"
																					className="ms-1 h-8"
																				/>
																			</Dropdown.Toggle>
																			<Dropdown.Menu>
																				<Dropdown.Item
																					onClick={() =>
																						expenseOpen("New expense")
																					}
																				>
																					{t("newExpense")}
																				</Dropdown.Item>
																				<Dropdown.Item
																					onClick={() =>
																						expenseOpen("New recurring expense")
																					}
																				>
																					{t("newRecurringExp")}
																				</Dropdown.Item>
																				{/* <Dropdown.Item onClick={() => expenseOpen("With barcode")}>{t('withBarcode')}</Dropdown.Item> */}
																			</Dropdown.Menu>
																		</Dropdown>
																	</li>
																</>
															)}
														{expenseData.thirdTotalRow == 0
															? userJson &&
															userJson.length > 0 &&
															userJson.indexOf(2031) !== -1 && (
																<>
																	<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-sm-0 mb-1">
																		<Button
																			onClick={rentalFeeOpen}
																			variant="link"
																			className="p-0 fw-bold"
																			size="sm"
																		>
																			{t("rentalFee")}
																		</Button>
																	</li>
																</>
															)
															: ""}
													</>
												) : (
													""
												)}
												<li className="list-inline-item">
													<Button variant="link" size="sm" className="p-0">
														<img
															src={
																process.env.PUBLIC_URL +
																"/assets/images/icons/print-light.svg"
															}
															alt="Print"
															className="h-18"
														/>
													</Button>
												</li>
											</ul>
										</Card.Header>
										<Card.Body>
											<div className="text-start d-flex mb-lg-4 mb-3">
												<ButtonGroup
													className="btn-date-filter"
													size="sm"
													aria-label="Date"
												>
													<Button
														variant="primary"
														className="btn-arrow"
														onClick={() =>
															filterExpense(
																stateMode == "monthly"
																	? state.startDate
																	: state.yearlySearch,
																"Subtract"
															)
														}
													>
														<img
															src={
																process.env.PUBLIC_URL +
																"/assets/images/icons/arrow-white.svg"
															}
															className="h-24"
														/>
														{/* {state.startDate} */}
													</Button>
													<Dropdown>
														<Dropdown.Toggle
															variant="link"
															className="btn-date dropdown-arrow-none d-flex align-items-center justify-content-center"
														>
															{stateMode === "monthly" ? (
																<>
																	{t(
																		`half_month.${state.searchDate.split("/")[0]}`
																	) +
																		"/" +
																		state.searchDate.split("/")[1]}
																</>
															) : (
																<>{state.yearlySearch}</>
															)}
															<img
																src="../assets/images/icons/arrow-bottom-primary.svg"
																alt="Plus icon"
																className="ms-2 h-8"
															/>
														</Dropdown.Toggle>
														<Dropdown.Menu>
															{stateMode !== "monthly" ? (
																<Dropdown.Item
																	onClick={() => filterExpenseMode("monthly")}
																>
																	{t("Monthly")}
																</Dropdown.Item>
															) : (
																<Dropdown.Item
																	onClick={() => filterExpenseMode("yearly")}
																>
																	{t("Yearly")}
																</Dropdown.Item>
															)}
														</Dropdown.Menu>
													</Dropdown>
													<Button
														variant="primary"
														className="btn-arrow"
														onClick={() =>
															filterExpense(
																stateMode == "monthly"
																	? state.endDate
																	: state.yearlySearch,
																"Add"
															)
														}
													>
														{/* {state.endDate} */}
														<img
															src={
																process.env.PUBLIC_URL +
																"/assets/images/icons/arrow-white-right.svg"
															}
															className="h-24"
														/>
													</Button>

													{/*<Button variant="light">Most<img src={process.env.PUBLIC_URL + "/assets/images/icons/chevron-sm-bottom.svg"} className="ms-1" /></Button>*/}
												</ButtonGroup>
											</div>
											{/* <!-- Table start from here --> */}
											{expenseStatus == "loading" ? (
												<Loader />
											) : (
												<Table responsive
													className="table-custom table-custom-bordered mb-0 wt-expenseisListings"
												>
													<thead>
														<tr>
															<th className={"check_Checkbox"}>
																{expenseData.data &&
																	expenseData.data.length > 0 ? (
																	<Checkbox
																		type="checkbox"
																		name="selectAll"
																		id="selectAll"
																		handleClick={handleSelectAll}
																		isChecked={isCheckAll}
																	/>
																) : (
																	""
																)}
															</th>
															<th>{t("maturity")}</th>
															<th>{t("terminatelist.details")}</th>
															<th className="text-end">{t("formLabel.value")}</th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														{expenseData.data && expenseData.data.length > 0 ? (
															expenseData.data.map((item, index) => {
																return (
																	<tr key={index}>
																		<td>
																			<Checkbox
																				key={index}
																				type="checkbox"
																				name={item.id}
																				id={item.id}
																				handleClick={handleClick}
																				isChecked={isCheck.includes(item.id)}
																			/>
																		</td>
																		<td className="text-dark-70">
																			<span className="d-block">
																				{t(
																					`full_month.${item.maturityDate.split("/")[0]}`
																				) +
																					"/" +
																					item.maturityDate.split("/")[1]}
																			</span>
																			<span className="d-block text-secondary">
																				{t("expired")}
																			</span>
																			<span className="d-block text-secondary">
																				{item.maturity}
																			</span>
																			<span className="d-block text-secondary">
																				{item.ID_RECEBIMENTO_RECB
																					? item.ID_RECEBIMENTO_RECB
																					: item.id}
																			</span>
																		</td>
																		<td>
																			<span className="text-dark-70 d-block">
																				{item.productName} 
																				{ (item.complement && ( (item.complement).indexOf('1/1') >= 0 && (item.complement).indexOf(10) < 0 ) ? '' : '-' + item.complement ) }
																			</span>
																			<span className="d-block">
																				<img
																					src={
																						process.env.PUBLIC_URL +
																						"/assets/images/icons/clock.svg"
																					}
																				/>
																				<span className="ps-1">
																					{t(`${item.debit.toLowerCase()}`)} {t("formLabel.debit").toLowerCase()}
																				</span>
																			</span>
																			<span className="d-block">
																				<img
																					src={
																						process.env.PUBLIC_URL +
																						"/assets/images/icons/clock.svg"
																					}
																				/>
																				<span className="ps-1">
																					{t(`${item.credit.toLowerCase()}`)} {t("formLabel.credit").toLowerCase()}
																				</span>
																			</span>
																			<span className="d-block">
																				{" "}
																				{item.supName}
																			</span>
																		</td>
																		<td className="text-end text-dark-70">
																			<span className="text-nowrap d-block">
																				R$ {converter(`${item.value}`)}
																			</span>
																		</td>
																		<td className="text-end">
																			{/* {
                                                                              (item.payment == 2 || item.payment == 1) && item.type != "Rental fee" &&
                                                                          <> */}
																			{userJson &&
																				userJson.length > 0 &&
																				userJson.indexOf(2031) !== -1 && (
																					<>
																						<ul className="list-inline mb-0">
																							{item.ID_RECEBIMENTO_RECB == null &&
																								item.status == 0 &&
																								item.type != "Rental fee" &&
																								item.status !== 2 &&
																								item.status !== 4 &&
																								item.FL_STATUS_IMOD == 0 && (
																									<li
																										className="list-inline-item me-0 cursor-pe"
																										onClick={(e) =>
																											handleEditExpenses(
																												e,
																												item.id,
																												item.type
																											)
																										}
																									>
																										<span className="icon">
																											{" "}
																											<img
																												src={
																													process.env.PUBLIC_URL +
																													"/assets/images/icons/edit-primary.svg"
																												}
																												className="h-20"
																												alt="Edit"
																											/>
																										</span>
																									</li>
																								)}

																							{item.type ==
																								"New recurring expense" && (
																									<li className="list-inline-item ms-3 ms-xl-2 ms-xxl-4">
																										<span className="icon">
																											<img
																												src={
																													process.env.PUBLIC_URL +
																													"/assets/images/icons/file-refresh.svg"
																												}
																												className="h-20"
																											/>
																										</span>
																									</li>
																								)}

																							{(item.status != "0" || item.status != 0) && (
																								<li className="list-inline-item ms-3 ms-xl-2 ms-xxl-4">
																									<span className="icon">
																										<img
																											className="h-20"
																											src={
																												process.env.PUBLIC_URL +
																												"/assets/images/icons/CheckmarkCircle.svg"
																											}
																											alt="paid"
																										/>
																									</span>
																								</li>
																							)}

																							{item.ID_RECEBIMENTO_RECB &&
																								(
																									<li className="list-inline-item ms-3 ms-xl-2 ms-xxl-4" onClick={(e) => moveToCharge(e, item.ID_RECEBIMENTO_RECB, item.FL_STATUS_IMOD)}>
																										<span className="icon">
																											<img
																												className="h-20"
																												src={
																													process.env.PUBLIC_URL +
																													"/assets/images/icons/arrow-right.svg"
																												}
																												alt="paid"
																											/>
																										</span>
																									</li>
																								)}
																						</ul>
																					</>
																				)}
																		</td>
																	</tr>
																);
															})
														) : (
															<tr>
																<td colSpan={5}>
																	<div className="p-1 text-secondary text-center">
																		{t("formLabel.noRecordsFounds")}
																	</div>
																</td>
															</tr>
														)}
													</tbody>
												</Table>
											)}
											{/* <!-- /Table end --> */}
											<p className="py-2">
												{t("formLabel.listing")}{" "}
												{expenseData.totalRow > 0 ? expenseData.totalRow : 0}{" "}
												{t("expenditure.expenses")}.{" "}
												{expenseData.secondTotalRow > 0
													? expenseData.secondTotalRow
													: 0}{" "}
												{t("expenditure.beingrecurring")}.
											</p>
											{/* <!-- Nav listing start from here --> */}
											{!completeTermination &&
												contractDatail.length > 0 &&
												contractDatail[0].suspended == 0 &&
												contractDatail[0].is_active == "1" ? (
												<>
													{userJson &&
														userJson.length > 0 &&
														userJson.indexOf(2031) !== -1 && (
															<ul className="list-inline mb-0">
																<li className="list-inline-item mb-1 me-xl-3 me-2">
																	<Dropdown>
																		<Dropdown.Toggle variant="light" size="sm">
																			{t("formLabel.mark")}
																		</Dropdown.Toggle>
																		<Dropdown.Menu>
																			<Dropdown.Item
																				onClick={handleSelectAll}
																				href=""
																			>
																				{t("formLabel.all")}
																			</Dropdown.Item>
																			<Dropdown.Item
																				onClick={noneHandleSelectAll}
																				href=""
																			>
																				{t("formLabel.none")}
																			</Dropdown.Item>
																		</Dropdown.Menu>
																	</Dropdown>
																</li>
																<li className="list-inline-item mb-1 me-xl-3 me-2">
																	{" "}
																	<span className="px-1">
																		{t("formLabel.withTagged")}:
																	</span>
																</li>
																<li className="list-inline-item mb-1 me-xl-3 me-2">
																	<Button
																		variant="light"
																		size="sm"
																		onClick={() => liquidateOpen("Liquidate")}
																		name="Liquidate"
																	>
																		{t("otherfooter.Liquidatenotpayable")}
																	</Button>
																</li>
																<li className="list-inline-item mb-1 me-xl-3 me-2">
																	<Button type="button" variant="light" size="sm">
																		{t("otherfooter.Reversepayable")}
																	</Button>
																</li>
																<li className="list-inline-item mb-1 me-xl-3 me-2">
																	<Button
																		type="button"
																		variant="light"
																		size="sm"
																		onClick={() => liquidateOpen("To throw")}
																		name="To throw"
																	>
																		{t("otherfooter.Launchandleavepending")}
																	</Button>
																</li>
																<li className="list-inline-item mb-1">
																	<Dropdown>
																		<Dropdown.Toggle variant="light" size="sm">
																			{t("button.more")}
																		</Dropdown.Toggle>
																		<Dropdown.Menu>
																			<Dropdown.Item
																				href={"#"}
																				onClick={deleteExpense}
																			>
																				{t("button.delete")}
																			</Dropdown.Item>
																			<Dropdown.Item href="#">
																				{t("otherfooter.Receiptofpayable")}
																			</Dropdown.Item>
																		</Dropdown.Menu>
																	</Dropdown>
																</li>
															</ul>
														)}
												</>
											) : (
												""
											)}
											{/* <!-- /Nav listing  end --> */}
										</Card.Body>
									</Card>
									
									<Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
										<Accordion.Item className="position-relative" eventKey="0">
										<div className="position-absolute end-0 top-0 mt-2 pt-1 me-5 me-sm-6 pe-2 z-index-5">
											{!completeTermination &&
											userJson &&
											userJson.length > 0 &&
											userJson.indexOf(2031) !== -1 && (
												<Button
												variant="link"
												className="p-0 fw-bold"
												size="sm"
												onClick={electronicEnvelopeOpen}
												>
												{t("Newenvelope")}
												</Button>
											)}
										</div>
										<Accordion.Header>
											{t("Electronicenvelope")}
										</Accordion.Header>
										<Accordion.Body className="pb-1">
											{electronicEnvelopeListing ? (
											<Table
												responsive
												className="table-custom table-custom-bordered mb-0"
											>
												<thead>
												<tr>
													<th>Envelope</th>
													<th>Status</th>
													<th>Cancelamento</th>
												</tr>
												</thead>
												<tbody>
												{electronicEnvelopeListing &&
													electronicEnvelopeListing.length > 0 &&
													[...electronicEnvelopeListing]
													.reverse()
													.map((item) => (
														<tr>
														<td>
															{" "}
															<Link
															to={
																"/electronic_envelope/" +
																item.ID_ENVELOPE_ENV
															}
															className="text-dark-70"
															>
															Envelope {item.ID_ENVELOPE_ENV}
															</Link>{" "}
															-{" "}
															{t("contract") +
															" " +
															contractDatail[0].contractTypeLabel +
															" " +
															contractDatail[0].property_identifier +
															"/" +
															contractDatail[0].lease_count}
														</td>
														{item.FL_STATUS_ENV == 0 ? (
															<td>
															<Badge className="ms-1 text-dark bg-warning-10">
																Pendente de envio
															</Badge>
															</td>
														) : (
															<td className="text-center">-</td>
														)}
														{item.FL_STATUS_ENV == 1 ? (
															<td>
															<Badge
																className="text-dark ms-1"
																bg="success-30"
															>
																Assinado
															</Badge>
															</td>
														) : (
															<td className="text-center">-</td>
														)}
														</tr>
													))}
												</tbody>
											</Table>
											) : (
											<div className="border p-3 p-xl-4 text-secondary text-center">
												{t("Noenvelopefound")}
											</div>
											)}
										</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item
										ref={targetRef}
										className="position-relative"
										eventKey={itemCount ? "1" : "0"}
										id="widget_checklist"
										>
										<div className="position-absolute end-0 top-0 mt-2 pt-1 me-5 me-sm-6 pe-2 z-index-5">
											{!completeTermination &&
											userJson &&
											userJson.length > 0 &&
											userJson.indexOf(2031) !== -1 && (
												<Button
												onClick={newChecklistsOpen}
												variant="link"
												className="p-0 fw-bold"
												size="sm"
												>
												{t("Addchecklist")}
												</Button>
											)}
										</div>
										<Accordion.Header>{t("Checklists")}</Accordion.Header>
										<Accordion.Body>
											{checklistStatus == "loading" ? (
											<Loader />
											) : contractChecklist.data &&
											contractChecklist.data.length > 0 &&
											contractChecklist.data[0].id ? (
											contractChecklist.data.map((checklist, index) => (
												<>
												<Accordion.Item
													className="border position-relative"
													eventKey={itemCount ? "1" : checklist.id}
													key={index}
													data-idnew={checklist.id}
												>
													<div className="position-absolute end-0 top-0 mt-2 me-5 me-sm-6 pe-2 z-index-5">
													<Button
														onClick={() =>
														newChecklistsItemOpen(checklist)
														}
														className="p-0 fw-bold"
														variant="link"
														size="sm"
													>
														{t("AddchecklistItem")}
													</Button>
													</div>
													<Accordion.Header className="m-n1">
													{checklist.name}
													</Accordion.Header>
													<Accordion.Body className="pb-1 panel-content">
													<Table
														responsive
														className="table-custom table-custom-bordered mb-0"
													>
														<thead>
														<tr>
															<th></th>
															<th>{t("terminatelist.item")}</th>
															<th>{t("formLabel.name")}</th>
														</tr>
														</thead>
														<tbody>
														{checklist.chkItems &&
															checklist.chkItems.length > 0 ? (
															checklist.chkItems.map((item, index) => {
															return (
																<tr
																key={index}
																data-idnew={
																	item.ID_CHECKLISTITEM_CHI
																}
																>
																<td className="text-dark-70">
																	<Form.Check
																	type="checkbox"
																	checked={
																		item.FL_STATUS_CCH === 1
																	}
																	className="newUserCh1"
																	id={
																		checklist.id +
																		"newUserCh1" +
																		item.ID_CHECKLISTITEM_CHI
																	}
																	onClick={(e) =>
																		markChecklistChecked(
																		e,
																		item.ID_CHECKLISTITEM_CHI,
																		checklist.id
																		)
																	}
																	/>
																</td>
																<td>
																	{item.ST_NOME_CCH} <br></br>
																	<small>
																	{getResponsiblePerson(
																		item.FL_RESPONSAVEL_CHI
																	)}
																	.
																	</small>
																</td>
																<td>
																	{item.names.length
																	? item.names.map(
																		(owname, index) => (
																		<small className="f-11">
																			{owname.name}
																		</small>
																		)
																	)
																	: ""}
																</td>
																</tr>
															);
															})
														) : (
															<tr>
															<td colSpan={3}>
																<div className="text-secondary p-1 text-center">
																No checklist added to this contract.
																</div>
															</td>
															</tr>
														)}
														</tbody>
													</Table>
													</Accordion.Body>
												</Accordion.Item>
												</>
											))
											) : (
											<></>
											)}
										</Accordion.Body>
										</Accordion.Item>
									</Accordion> 
								</Col>

								<Col xl={12} className="widgetcontractAmdmts">
									<Card>
										<Card.Header>{t("ContractAmendmants")}</Card.Header>
										<Card.Body className="pb-1">
											<Table
												responsive
												className="table-custom table-custom-bordered mb-0 aaaaaaaaaaaaaaadnotdeleteme"
											>
												<thead>
													<tr>
														<th colSpan={3}>{t("Amendmant")}</th>
														<th colSpan={3}>{t("Date")}</th>
														<th colSpan={3}>{t("ChangeMade")}</th>
														<th colSpan={3} className="text-end"></th>
													</tr>
												</thead>
												<tbody>
													{amendmant && amendmant.length > 0
														? [...amendmant]
															.filter(
																(item) =>
																	item.ST_OBSERVACAO_CONRE !== "" &&
																	item.ST_OBSERVACAO_CONRE !== null
															)
															.reverse()
															.map((item) => (
																<>
																	<tr>
																		<td colSpan={3}>
																			{item.ST_IDENTIFICADOR_IMO}
																		</td>
																		<td colSpan={3}>
																			{formatDateToDDMMYYYY(
																				item.DT_REAJUSTE_CONRE
																			)}
																		</td>
																		<td
																			colSpan={3}
																			dangerouslySetInnerHTML={{
																				__html: item.ST_OBSERVACAO_CONRE,
																			}}
																		/>

																		<td className="text-end" colSpan={3}>
																			<div className="d-flex justify-content-end">
																				<span
																					className="icon img-primary cursor-pe"
																					onClick={(e) => {
																						handleRouteFolderDetail(
																							e,
																							item.ID_REAJUSTE_CONRE
																						);
																					}}
																				>
																					<img
																						src={
																							process.env.PUBLIC_URL +
																							"/assets/images/icons/arrow-right.svg"
																						}
																						className="h-20"
																						alt="Arrow right"
																					/>
																				</span>
																			</div>
																		</td>
																	</tr>
																</>
															))
														: ""}
												</tbody>
											</Table>
										</Card.Body>
									</Card>
								</Col>

								<Col xl={12} className="widgetContractharges">
									<Card>
										<Card.Header className="d-md-flex align-items-center justify-content-between">
											{t("Contractchargesin")}  {t(`half_month.${stateCharge.searchDate.split("/")[0]}`) + "/" + stateCharge.searchDate.split("/")[1]}
											{!completeTermination &&
												contractDatail.length > 0 &&
												contractDatail[0].suspended == 0 &&
												contractDatail[0].is_active == "1"
												? userJson &&
												userJson.length > 0 &&
												userJson.indexOf(2031) !== -1 && (
													<ul className="list-inline d-sm-flex align-items-center justify-content-start mt-md-0 mt-2 mb-0">
														<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-sm-0 mb-1">
															<Dropdown>
																<Dropdown.Toggle
																	variant="link"
																	size="sm"
																	className="p-0 fw-bold dropdown-arrow-none"
																	id="dropdown-basic"
																>
																	{t("Newextracharge")}
																	<img
																		src="../assets/images/icons/arrow-bottom-primary.svg"
																		alt="Plus icon"
																		className="ms-1 h-8"
																	/>
																</Dropdown.Toggle>
																<Dropdown.Menu>
																	<Dropdown.Item
																		onClick={() =>
																			newExtraCharge("New charge - Tenant")
																		}
																	>
																		{t("Totherenter")}
																	</Dropdown.Item>
																	<Dropdown.Item
																		onClick={() =>
																			newExtraCharge("New charge - Owner")
																		}
																	>
																		{t("Totheowner")}
																	</Dropdown.Item>
																</Dropdown.Menu>
															</Dropdown>
														</li>
														<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-sm-0 mb-1">
															<Link to={"/receivable_charges/" + id}>
																<Button
																	type="button"
																	variant="link"
																	className="p-0 fw-bold"
																	size="sm"
																>
																	{t("banks.moreOptions")}
																</Button>
															</Link>
														</li>
														<li className="list-inline-item mb-sm-0 mb-1">
															{/* <Button type="button" variant="light" size="sm" onClick={(e) => settledCharges(e, id)}>
                                                          {t("Settledcharges")}
                                                      </Button> */}
															<Button
																type="button"
																variant="link"
																className="p-0 fw-bold"
																size="sm"
																onClick={toggleSettledFilter}
															>
																{settledFilter
																	? t("RemoveSettledCharges")
																	: t("Settledcharges")}
															</Button>
															{/* <Link to={"/report"}>  <Button type="button" variant="light" size="sm" >
                                                          {t("Settledcharges")}
                                                      </Button>
                                                      </Link> */}
														</li>
													</ul>
												)
												: ""}
										</Card.Header>
										<Card.Body className="ext-center">
											{!completeTermination &&
												contractDatail.length > 0 &&
												contractDatail[0].suspended == 0 &&
												contractDatail[0].is_active == "1"
												? userJson &&
												userJson.length > 0 &&
												userJson.indexOf(2031) !== -1 && (
													<ul className="list-inline d-sm-flex align-items-center justify-content-start mb-lg-4 mb-3">
														<li className="list-inline-item border-end pe-2 pe-xl-3 me-xl-3 me-2">
															<ButtonGroup
																className="btn-date-filter"
																size="sm"
																aria-label="Date"
															>
																<Button
																	variant="primary"
																	className="btn-arrow"
																	onClick={() =>
																		filterCharge(
																			stateCharge.startDate,
																			"Subtract"
																		)
																	}
																>
																	<img
																		src={
																			process.env.PUBLIC_URL +
																			"/assets/images/icons/arrow-white.svg"
																		}
																		className="h-24"
																	/>
																	{/* {stateCharge.startDate} */}
																</Button>
																<Button
																	variant="primary"
																	className="btn-date"
																	disabled
																>
																	{t(
																		`half_month.${stateCharge.searchDate.split("/")[0]}`
																	) +
																		"/" +
																		stateCharge.searchDate.split("/")[1]}
																</Button>
																<Button
																	variant="primary"
																	className="btn-arrow"
																	onClick={() =>
																		filterCharge(stateCharge.endDate, "Add")
																	}
																>
																	{/* {stateCharge.endDate} */}
																	<img
																		src={
																			process.env.PUBLIC_URL +
																			"/assets/images/icons/arrow-white-right.svg"
																		}
																		className="h-24"
																	/>
																</Button>
															</ButtonGroup>
														</li>
														<li className="list-inline-item">
															<Button
																variant="light"
																size="sm"
																id="dropdown-basic"
																onClick={(e) => handleShow(e, "charge")}
															>
																{t("periodfilter")}
															</Button>
														</li>
													</ul>
												)
												: ""}
											{chargeStatus == "loading" ? (
												<Loader />
											) : (
												<>
													<Table
														responsive
														className="table-custom table-custom-bordered mb-0 bbbbbbbbbbb123456"
													>
														<thead>
															<tr>
																<th>{t("maturity")}</th>
																<th></th>
																<th>{t("Client")}</th>
																<th className="text-end">
																	{t("formLabel.value")}
																</th>
																<th></th>
															</tr>
														</thead>
														<tbody>
															{chargeData.data &&
																filterSettledCharges(chargeData.data).length > 0 ? (
																filterSettledCharges(chargeData.data).map(
																	(item, index) => (
																		<tr key={index}>
																			<td className="text-dark-70">
																				<span className="d-block">
																					{item.maturity}
																				</span>
																				{
					                                                                item?.reverseStatus ?
					                                                                    <span className="d-block text-primary fw-bold">{t("paidTobepaid.Reversed")}</span>
					                                                                : ''
					                                                            }
																			</td>
																			<td className="text-dark-70">
																				{item.status == 1 ? (
																					<Badge
																						bg="success-10"
																						className="text-dark"
																					>
																						Received
																					</Badge>
																				) : item.status == 0 ? (
																					<Badge
																						bg="primary-20"
																						className="text-dark"
																					>
																						In-progress
																					</Badge>
																				) : item.status == 6 ? (
																					<span className="text-dark ms-1 badge bg-danger-30">
																						Suspended
																					</span>
																				) : item.status == 2 ? (
																					<span className="text-dark ms-1 badge bg-danger-30">
																						Settled
																					</span>
																				) : item.status == 3 ? (
																					<span className="text-dark ms-1 badge bg-danger-30">
																						{t("newTransfer.revoke")}
																					</span>
																				) : (
																					""
																				)}
																			</td>
																			<td>

																				{item.ID_DEBITO_IMOD == 1 ?
																					<span className="d-block">
																						{
																							item.ownerResult && item.ownerResult.length > 0
																								? item.ownerResult.map((items, indx) => (
																									<>
																										<img
																											src={
																												process.env.PUBLIC_URL +
																												"/assets/images/icons/user_g.svg"
																											}
																										/>
																										<span className="ps-1">
																											{items.ownerName}
																										</span>
																									</>
																								))
																								: ""
																						}
																					</span>
																					:

																					<span className="d-block">

																						{
																							item.tenantResult && item.tenantResult.length > 0
																								? item.tenantResult.map((items, indx) => (
																									<>
																										<img
																											src={
																												process.env.PUBLIC_URL +
																												"/assets/images/icons/key_g.svg"
																											}
																										/>
																										<span className="ps-1">
																											{items.ST_NOME_PES}
																										</span>
																									</>
																								))
																								: ""
																						}
																					</span>
																				}


																				<span className="text-dark-70 d-block">
																					cobrança {item.id} em Conta {item.accountRef ? item.accountRef : ""} com NN: {item.account ? item.account : ""}.
																				</span>
																				<span className="text-dark-70 d-block">
																					{item.noteCustomer}
																				</span>
																			</td>
																			<td className="text-end text-dark-70">
																				<span className="text-nowrap d-block">
																					R$ {item.total}
																				</span>
																			</td>
																			<td>
																				{/*-------Harjinder-------*/}
																				{/*{
																					userJson && userJson.length > 0 && (userJson.indexOf(2031) !== -1) &&
																						item.ID_AARIN_IMD == '' ?
																						<div onClick={() => newExtraCharge(item.id)} className='m-3 cursor-pe'>
																							<img src={process.env.PUBLIC_URL + "/assets/images/icons/edit-light.svg"} alt="Edit" />
																						</div>
																						: ''
																				}*/}
																				
																				{item.status == 0 ? (
																					<>
																						<div className="d-flex justify-content-end">
																							
																							
																							{ item.AARINRES && item.AARINRES != '' &&
																								<>
																									<span
																										className="img-primary icon cursor-pe me-2 me-xl-3 me-xxl-4"
																										onClick={() =>
																											showTheBoletoError(item.AARINRES)
																										}
																									>
																										<img
																											className="cursor-pe h-20"
																											src={
																												process.env.PUBLIC_URL +
																												"/assets/images/icons/ErrorCircle.svg"
																											}
																											alt="Edit/View"
																										/>
																									</span>

																								</>
																							}
																							
																							<span
																								className="img-primary icon cursor-pe me-2 me-xl-3 me-xxl-4"
																								onClick={() =>
																									newExtraCharge(item.id)
																								}
																							>
																								<img
																									className="cursor-pe h-20"
																									src={
																										process.env.PUBLIC_URL +
																										"/assets/images/icons/edit-light.svg"
																									}
																									alt="Edit/View"
																								/>
																							</span>


																							<span
																								className="img-primary icon cursor-pe me-2 me-xl-3 me-xxl-4"
																								onClick={(e) =>
																									suspendedCharge(e, item.id)
																								}
																							>
																								<img
																									className="cursor-pe h-20"
																									src={
																										process.env.PUBLIC_URL +
																										"/assets/images/icons/unlock.svg"
																									}
																									alt="Suspended"
																								/>
																							</span>
																							{item.BILLING_TYPE == "PIX" ? (
																								<span
																									className="img-primary icon cursor-pe"
																									onClick={(e) =>
																										cancelRegenBoleto(e, item)
																									}
																								>
																									{" "}
																									<img
																										className="cursor-pe h-20"
																										src={
																											process.env.PUBLIC_URL +
																											"/assets/images/icons/pix.svg"
																										}
																										alt="Suspended"
																									/>
																								</span>
																							) : (
																								""
																							)}
																							{ item.BILLING_TYPE == "BOLETO" ? (
																								<>
																								<span
																									className="img-primary icon cursor-pe "
																									onClick={(e) =>
																										cancelRegenBoleto(e, item)
																									}
																								>
																									<img className="cursor-pe h-20 babbicon"
																										src={
																											process.env.PUBLIC_URL +
																											"/assets/images/icons/barcode.svg"
																										}
																										alt="Suspended"
																									/>
																								</span>
																								</>
																							) : (
																								""
																							)}

																							{
																								item.BILLING_TYPE == "PIX" || item.BILLING_TYPE == "BOLETO" ?
																									<Button variant="link" className="p-0" onClick={ ()=>cancelInvalidate('Boleto',item.id) }>
						                                                                                <span className="icon img-primary cursor-pe me-1 me-xxl-2 me-lg-2">
						                                                                                	<img src={process.env.PUBLIC_URL + "/assets/images/icons/cross-w.svg"} alt="Cancel Boleto" className="cursor-pe h-18" />
						                                                                                </span>
						                                                                            </Button>
						                                                                        :
						                                                                        	<Button variant="link" className="p-0" onClick={ ()=>cancelInvalidate('Cash',item.id) } >
						                                                                                <span className="icon img-primary cursor-pe me-1 me-xxl-2 me-lg-2">
						                                                                                	<img src={process.env.PUBLIC_URL + "/assets/images/icons/cross-w.svg"} alt="Cancel Boleto" className="cursor-pe h-18" />
						                                                                                </span>
						                                                                            </Button>
																							}
																							{ item.BILLING_TYPE != "BOLETO" && item.BILLING_TYPE != "PIX" && pageLoaded && 
																								<span className="spinner-border text-danger spinner-border-sm"></span>
																							}
																						</div>
																					</>
																				) : (
																					""
																				)}
																			</td>
																		</tr>
																	)
																)
															) : (
																<tr>
																	<td colSpan={5}>
																		<div className="p-1 text-secondary text-center">
																			{t("formLabel.noRecordsFounds")}
																		</div>
																	</td>
																</tr>
															)}
														</tbody>
													</Table>

													{
														<div className="d-flex justify-content-center my-1">
															<Pagination
																className="pagination-bar"
																currentPage={currentPage}
																totalCount={totalpage}
																pageSize={PageSize}
																onPageChange={(page) => setCurrentPage(page)}
															/>
														</div>
													}
												</>
											)}
											{/* <!-- /Table end --> */}
											<p className="mb-0">
												{t("formLabel.listing")}{" "}
												{chargeData.totalRow > 0 ? chargeData.totalRow : 0}{" "}
												{t("chargesclienttotal")} R${" "}
												{chargeData.secondTotalRow > 0
													? chargeData.secondTotalRow
													: 0}
												.
											</p>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						)}
					</main>

					<ChangeFinancialStatus
						showFinancialStatus={showFinancialStatusModal}
						financialStatusClose={financialStatusModalClose}
					/>
					<NotifyContract
						showNotifiedContract={showNotifiedContractModal}
						notifiedContractClose={notifiedContractModalClose}
					/>

					{/* <!-- /Main content start end --> */}
					{/* <WarrantyService gurantorDetails={gurantorDetails} setGurantorLabel={setGurantorLabel} /> */}
					<QuoteService />
					<RenewInsuranceService insuranceQuotes={insuranceQuotes} />
					<ExtraChargeService
						extraAndDiscount={extraAndDiscount}
						setExtraAndDiscount={setExtraAndDiscount}
						extraChargeClosed={extraChargeFormClosed}
						stateCharge={stateCharge}
						state={state}
					/>
					<ChecklistService
						myChecklists={myChecklists}
						contractId={contractDatail.length ? contractDatail[0].id : ""}
						newAgencyChecklist={false}
					/>
					<ChecklistsService
						myChecklists={myChecklists}
						contractId={contractDatail.length ? contractDatail[0].id : ""}
					/>
					<ElectronicEnvelopeService
						electronicEnvelopeListingCall={electronicEnvelopeListingCall}
					/>
					<RentalFeeService />
					<ExpenseService
						contractExp={"contractExp"}
						setStateMode={setStateMode}
						stateContract={state}
					/>
					<LiquidateService />
					<GenerateMeatService getAllContractCharge={getAllContractCharge(stateCharge)} />
					<Terminate
						showTerminateContract={showTerminateContractModal}
						terminateContractClose={terminateContractModalClose}
						terminateContractShow={terminateContractModalShow}
						ShowThisData={ShowThisData}
						setShowThisData={setShowThisData}
						allresponsibleList={allresponsibleList[0]["agency"]}
						terminatonReason={terminatonReason}
					/>
					<Modal show={keyHandOver} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>{t("Printkeydeliveryterms")}</Modal.Title>
						</Modal.Header>
						<Form onSubmit={handleSubmit(onSubmit)}>
							<Modal.Body>
								<FloatingLabel label={t("Keyhandoverdate")}>
									<input type="hidden" {...register("start")} />
									<Form.Control
										className={`form-control ${errors.key_handover_date ? "is-invalid" : ""
											}`}
										{...register("key_handover_date")}
										type="date"
										placeholder="Time period"
										onChange={(e) => keyChange(e)}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.key_handover_date?.message}
									</Form.Control.Feedback>
								</FloatingLabel>
							</Modal.Body>
							<Modal.Footer>
								<Button variant="outline-primary" onClick={handleClose}>
									{t("button.close")}
								</Button>
								<Button type="submit" variant="primary">
									{t("printOut")}
								</Button>
							</Modal.Footer>
						</Form>
					</Modal>
					<Modal show={showContractSearch} onHide={filterClose}>
						<Form onSubmit={handleSubmit(dateSubmit)}>
							<Modal.Header closeButton>
								<Modal.Title>{t("TransferPage.month")}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Row className="g-3 g-xl-4">
									<Col md>
										<FloatingLabel label={t("TransferPage.startDate")}>
											<Form.Control
												type="date"
												aria-label="From"
												{...register("startDate")}
											></Form.Control>
										</FloatingLabel>
									</Col>
									<Col md>
										<FloatingLabel label={t("TransferPage.endDate")}>
											<Form.Control
												type="date"
												aria-label="to"
												min={startDate()}
												{...register("endDate")}
											></Form.Control>
										</FloatingLabel>
									</Col>
								</Row>
							</Modal.Body>
							<Modal.Footer>
								<Button variant="outline-primary" onClick={filterClose}>
									{t("button.close")}
								</Button>
								<Button variant="primary" type="submit">
									{t("button.save")}
								</Button>
							</Modal.Footer>
						</Form>
					</Modal>
					<PauseContract />
				</section>

				{/* CANCEL AND REGENERATE BOLETO */}
				<Modal
					show={canRegBoletoModal}
					onHide={canRegBoleto}
					className="conctdetailtemcancelregenrateboleto"
				>
					<Form onSubmit={handleSubmit(cancelRegFrmSubmit)}>
						<Modal.Header closeButton>
							<Modal.Title>{t("CancelRegenrateBoleto")}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Row className="g-3 g-xl-4">
								<Col md>
									<Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-3">
										<Form.Check
											type="radio"
											{...register("lease_period")}
											value="CancelBoleto"
											onChange={boletoAction}
											label={t("CancelBoleto")}
										/>
									</Form.Group>
								</Col>
							</Row>

							<Row className="g-3 g-xl-4">
								<Col md>
									<Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-3">
										<Form.Check
											type="radio"
											{...register("lease_period")}
											value="CancelRegenrateBoleto"
											onChange={boletoAction}
											label={t("CancelRegenrateBoleto")}
										/>
									</Form.Group>
								</Col>
							</Row>
							{canRegBoletoModal && regenratedate && (
								<Row className="g-3 g-xl-4">
									<Col md>
										<FloatingLabel label={t("maturity")}>
											<Form.Control
												type="date"
												aria-label="From"
												{...register("startDate")}
											></Form.Control>
										</FloatingLabel>
									</Col>
								</Row>
							)}
						</Modal.Body>

						<Modal.Footer>
							<Button variant="outline-primary" onClick={canRegBoleto}>
								{t("button.close")}
							</Button>
							<Button variant="primary" type="submit">
								{t("button.save")}
							</Button>
						</Modal.Footer>
					</Form>
				</Modal>
				{/* <ReadjustmentService allCheckedItem={""} /> */}

				{/* Change bank account modal start from here */}
				<Modal show={showBoletoPixModal} onHide={changeBoletoPixClose}>
					<Modal.Header closeButton>
					</Modal.Header>
					<Modal.Body className="text-center"> 
						<h5>{ BoletoPixAarinError }</h5>
					</Modal.Body> 
					<Modal.Footer></Modal.Footer>
				</Modal>
				{/* /Change bank account modal end */}

				<Modal show={showCancelTerminateModal} onHide={hideClose}>
					<Form>
						<Modal.Header closeButton>
							<Modal.Title>{t("cancelTermination")}?</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{t("Sweetalert.terminationReversed")}.
						</Modal.Body>
						<Modal.Footer>

							{
								loader_on ?
									<Button className="btn-width">
										<span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
									</Button>
									:
									<>
										<Button variant="outline-primary" onClick={hideClose}>
											{t("button.close")}
										</Button>
										<Button variant="primary" onClick={() => HandleCancel()}>
											{t("Ok")}
										</Button>
									</>
							}

						</Modal.Footer>
					</Form>
				</Modal>

				<style type="text/css">{`
              .renewWarrantyContact {
               border: 0px !important;
               padding: 0;
               color: #f82e52;
               font-weight: 700;
               position: absolute;
               z-index: 5;
               background: #fff;
              top: 1.4rem;
              right: 4rem;
              }
  `}</style>
			</>
		);
	}
};
export default Contract_details;
