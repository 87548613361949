import React, { useEffect, useState, useMemo } from 'react';
import { Table, Form, Badge, Row, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSingleService, fetchService, serviceTaxcod } from '../../../../redux/slice/ServiceSlice';
import { AppDispatch, RootState } from '../../../../redux/store'
import { ServiceShow, singleService } from "../../../../redux/slice/ServiceSlice";
import Loader from '../Loader';
import Swal from "sweetalert2";
import Pagination from '../../../Pagination';
import { getItem } from '../../../../redux/slice/AccountPlanSlice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { encrypt_Decrypt } from '../../../constants/utils';

export const ServiceList = ({ setSingleServ, addnewservice, setGetIdarr, setListCount }) => {
  // This line initializes the useDispatch hook and assigns it to the dispatch variable
  const dispatch = useDispatch<AppDispatch>();
  const [service, setService] = useState<any>([]);
  // Initialize the useTranslation hook for internationalization
  const { i18n, t } = useTranslation();
  // Retrieve serviceData, AccountPlanList,from the Redux state
  const serviceData: any = useSelector((state: RootState) => state.service);
  const {status1}:any = useSelector((state:RootState) => state.service)
 const [status, setStatus] = useState(true)
  const AccountPlanList = useSelector((state: RootState) => state.accountplane.accountPlane);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    // Dispatch the "getItem" action to fetch items
    dispatch(getItem())
  }, [dispatch, i18n.language])

  const navigate = useNavigate();
  const [userJson, setUserJson] = useState<any>([]);
  useEffect(() => {
    let userResult = encrypt_Decrypt();
    setUserJson(userResult);
  }, [])

  useEffect(() => {
    // Update the service state with serviceData.servicelist
    setService(serviceData.servicelist)
    setCurrentPage(1)
  }, [])

  useEffect(() => {
    // Asynchronous function within the useEffect hook
    (async () => {
      // Dispatch the fetchService action
     
      let newdispatch = await dispatch(fetchService());
      if(newdispatch.payload.length >0){
        setStatus(false)
      setService(newdispatch.payload);
      }else{
        setStatus(false)
        setService([]);
      }
      // Set the service state with the fetched data
    })()
 
  }, [addnewservice])
  // Function to handle form show
  const formShow = async (e, id: any) => {
    // Delay the dispatch of ServiceShow action using setTimeout
    let singleData = await dispatch(singleService(id));
    // Set the fetched single service data to the state variable
    setSingleServ(singleData?.["payload"]?.["data"]?.["data"]);
      // Fetch the single service data using dispatch
      dispatch(ServiceShow(true));
  }

  // Pagination start
  let PageSize = 10;

  let id_arr: any = [];

  const currentTableData = useMemo(() => {
    let serv = service ?? [];
    if (document.querySelectorAll('.table-custom tbody .form-check-input').length > 0) {
      document.querySelectorAll('.table-custom tbody .form-check-input').forEach(item => {
        item["checked"] = false;
        id_arr = [];
      })
    }

    if (document.querySelectorAll('.table-custom thead .form-check-input').length > 0) {
      let element: any = document.querySelector('.table-custom thead .form-check-input');
      element["checked"] = false;
    }

    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    let newarr = serv && serv.length > 0 ? serv.slice(firstPageIndex, lastPageIndex) : [];

    if (document.querySelectorAll('#listingCount').length > 0) {
      setListCount(newarr.length);
    }
    return newarr;

  }, [currentPage, service]);

  // Pagination End

  const deleteService = (e, id: any) => {
    // Display a confirmation dialog using Swal
    Swal.fire({
      title: `${t("Sweetalert.Warning")}`,
      text: `${t("Sweetalert.deleteService")}`,
       //// icon: 'success','warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: `${t("Sweetalert.yesDeleteIt")}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let Data: any = await dispatch(deleteSingleService(id));
        if (Data["payload"]["data"]["type"] == "success" || Data["payload"]["data"]["type"] == "Success") {
          // Fetch the updated service list
          let newdispatch = await dispatch(fetchService());
          setService(newdispatch.payload);
          // Show a success message using Swal
          Swal.fire({
            title: `${t("Sweetalert.Success")}`,
            text: `${t("Sweetalert.deleteRecord")}`,
            // icon: 'success','success',
            timer: 1500
          })
        } else {
          // Show an error message
          Swal.fire({
            title: `${t("Sweetalert.Error")}`,
            text: `${t("Sweetalert.somethingWent")}`,
            // icon: 'success','error',
            showCancelButton: false,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Delete'
          })
        }
      }
    })
  }

  const handleCheckAll = (e) => {
    let check = e.target;
    if (check.checked) {
      // If the checkbox is checked, select all items
      id_arr = [];
      if (document.querySelectorAll('.table-custom tbody .form-check-input').length > 0) {
        // Select all checkboxes inside the table body
        document.querySelectorAll('.table-custom tbody .form-check-input').forEach(item => {
          item["checked"] = true;
          id_arr.push(item?.getAttribute("data-id"));
        })
      }
    } else {
      // If the checkbox is unchecked, deselect all items
      if (document.querySelectorAll('.table-custom tbody .form-check-input').length > 0) {
        // Deselect all checkboxes inside the table body
        document.querySelectorAll('.table-custom tbody .form-check-input').forEach(item => {
          item["checked"] = false;
          id_arr = [];
        })
      }
    }
    setGetIdarr(id_arr);
  }

  const handleSingleCheck = (e) => {
    // Initialize an array to store the checked IDs
    id_arr = [];
    let checkbox_length: number = 0;
    // Get the number of checkboxes in the table
    if (document.querySelectorAll('.table-custom tbody .form-check-input').length > 0) {
      checkbox_length = document.querySelectorAll('.table-custom tbody .form-check-input').length;
    }

    setTimeout(() => {
      let count_checked = 0;
      let count_uncheked = 0;
      if (document.querySelectorAll('.table-custom tbody .form-check-input').length > 0) {
        // Loop through each checkbox in the table
        document.querySelectorAll('.table-custom tbody .form-check-input').forEach((item, index) => {
          if (item["checked"]) {
            count_checked += 1;
          } else {
            count_uncheked += 1;
          }
        })

        if (document.querySelectorAll('.table-custom thead .form-check-input').length > 0) {
          let element: any = document.querySelector('.table-custom thead .form-check-input');
          // Check or uncheck the header checkbox based on the checked count
          if (count_checked == checkbox_length) {
            element["checked"] = true;
          } else {
            element["checked"] = false;
          }
        }
        // Get the IDs of the checked checkboxes and update the id_arr state
        document.querySelectorAll('.table-custom tbody .form-check-input').forEach((item, index) => {
          if (item["checked"]) {
            let itemdata = item.getAttribute('data-id');
            id_arr.push(itemdata);
            setGetIdarr(id_arr);
          }
        })
        // If no checkboxes are checked, reset the id_arr state
        if (count_checked == 0) {
          id_arr = [];
          setGetIdarr(id_arr);
        }
      }
    }, 50)
  }
  const [list, setList] = useState<any>([]);
  useEffect(() => {
    // const modifyLabels = (data: any) => {
    //   const updatedData: any = [];
    //   for (const item of data) {
    //     const updatedItem: any = {
    //       value: item.item,
    //       label: item.description,
    //       childData: item.childData ? modifyLabels(item.childData) : [],
    //     };
    //     updatedData.push(updatedItem);
    //   }
    //   return updatedData;
    // };
    // setList(modifyLabels(AccountPlanList))

    let pushThis: any = [];
    AccountPlanList.forEach(firstItem => {
      if ((firstItem.childData).length > 0) {
        let firstData = firstItem.childData;
        firstData.forEach((secondItem, index) => {
          pushThis.push({
            value: secondItem.item,
            label: secondItem.description
          })
          if ((secondItem.childData).length > 0) {
            let secondData = secondItem.childData;
            secondData.forEach((thirdItem, index) => {
              pushThis.push({
                value: thirdItem.item,
                label: thirdItem.description
              })
              if ((thirdItem.childData).length > 0) {
                let thirdData = thirdItem.childData;
                thirdData.forEach((fourthItem) => {
                  pushThis.push({
                    value: fourthItem.item,
                    label: fourthItem.description
                  })
                })
              }
            })
          }
        })
      }
    })
    setList(pushThis)
  }, [AccountPlanList])
  

  return (
    <>
      {
        (status) ? <Loader /> :
          <>
            {
              (service && service.length > 0) ?
                <>
                <Card>
                  <Card.Body className="pb-1">
                  <Table responsive="xl" className="table-custom table-custom-bordered mb-0" id="serviceListtsx" >
                    <thead>
                      <tr>
                        <th className="w-24"><Form.Check aria-label="option 1" onClick={(e: any) => handleCheckAll(e)} /></th>
                        <th>{t("services.description")}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr>
                      <td><Form.Check aria-label="option 1" /></td>
                      <td>
                        <span className="d-block text-dark-70">Rent (3)</span>
                        <span className="d-block">Account category 3.1 Rent.</span>
                        <Badge className="bg-primary">DIMOB: Yield</Badge>
                      </td>
                      <td className="text-end">
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <Badge bg="success-30" className="text-dark">Administration fee applies</Badge>
                          </li>
                          <li className="list-inline-item">
                            <Badge bg="warning-10" className="text-dark">Incides income tax on the owner</Badge>
                          </li>
                        </ul>
                      </td>
                      <td className="text-end">
                        <img src="assets/images/icons/magnifying-glass-plus.svg" alt="icon" />
                      </td>
                    </tr> */}
                      {/* 
                  MAIS => DEBIT/CREDIT are in --Yellow color.
                  REPASS => Calculate administration fee on the service. is in --purple color
                        => Calculate administration fee by deducting the value of the service is in --whiteblue color
                  Tributável => Taxation --whiteblue color change when 9081 Corretagem selected and MAIS Should off.
                  Invoice/dimob => below account in ---grey color 
              */}

                      {
                        currentTableData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td><Form.Check aria-label="option 1" data-id={item?.["ID_PRODUTO_PRD"]} onClick={(e: any) => handleSingleCheck(e)} /></td>
                              <td>
                                <span className="d-block text-dark-70">{item?.["ST_DESCRICAO_PRD"]}</span>
                                {
                                  (item?.["ST_DESCRICAODESCONTO_CONT"] !== null && item?.["ST_DESCRICAODESCONTO_CONT"] !== "") &&
                                  list.map((items, index) => {
                                    if (String(items?.value) === item?.["ST_DESCRICAODESCONTO_CONT"]) {
                                      return <span className="d-block" key={index}>Conta categoria: {items?.value} {items?.label}</span>
                                    }
                                  })
                                }
                                {/* 
                              Invoice/dimob => below account in ---grey color 
                            */}
                                {/* <Badge className="bg-primary">DIMOB: Yield</Badge> */}
                              </td>
                              <td className="text-end">
                               <div className="d-flex align-items-center justify-content-end">
                                   {/* 
                                MAIS => DEBIT/CREDIT are in --Yellow color.
                                REPASS => Calculate administration fee on the service. is in --purple color
                                      => Calculate administration fee by deducting the value of the service is in --whiteblue color
                                Tributável => Taxation --whiteblue color change when 9081 Corretagem selected and MAIS Should off.
                            */}
                                <ul className="list-inline mb-0">
                                  {item["FL_COBRARTXADM_PRD"] == 1 ?
                                  <li className="list-inline-item me-2 me-xxl-4 me-lg-3">
                                    <Badge bg="info" className="text-white">{t("services.tags.admFee")}</Badge>
                                  </li>
                                  : ""
                                    }                                  {
                                    item["FL_INSIDEICMS_PRD"] == 1 ?
                                      <li className="list-inline-item me-2 me-xxl-4 me-lg-3">
                                        <Badge bg="warning" className="text-white">{t("services.tags.ownerFee")}</Badge>
                                      </li>
                                      :
                                      ""
                                  }
                                  {item["FL_DEDUZIR_PRD"] === 1 && (
                                      <li className="list-inline-item me-2 me-xxl-4 me-lg-3">
                                        <Badge bg="success" className="text-white">{t("services.tags.admCalc")}</Badge>
                                      </li>
                                  )}
                                  {item["FL_DIMOB_PRD"] === 2 && (
                                      <li className="list-inline-item me-2 me-xxl-4 me-lg-3">
                                        <Badge bg="secondary" className="text-white">{t("services.tags.income")}</Badge>
                                      </li>
                                  )}
                                  {item["FL_DIMOB_PRD"] === 3 && (
                                      <li className="list-inline-item me-2 me-xxl-4 me-lg-3">
                                        <Badge bg="secondary" className="text-white">{t("services.tags.commission")}</Badge>
                                      </li>
                                  )}
                                  {item["FL_DIMOB_PRD"] === 4 && (
                                      <li className="list-inline-item me-2 me-xxl-4 me-lg-3">
                                        <Badge bg="secondary" className="text-white">{t("services.tags.fee")}</Badge>
                                      </li>
                                  )}
                                  {item["ST_CONTA_CONT"] === '1.1.8' && (
                                      <li className="list-inline-item me-2 me-xxl-4 me-lg-3">
                                        <Badge bg="secondary" className="text-white">{t("services.tags.ownProperty")}</Badge>
                                      </li>
                                  )}
                                </ul>
                                {
                                  userJson && userJson.length > 0 && (userJson.indexOf(1071) !== -1) &&
                                  <span className="icon img-primary cursor-pe" onClick={(e: any) => formShow(e, item?.["ID_PRODUTO_PRD"])}><img src="assets/images/icons/edit-light.svg" className="h-20" alt="icon" /></span>
                                }
                                {/*
                                <img src="assets/images/icons/delete-light.svg" alt="icon" className="p-2" style={{ cursor: "pointer" }} onClick={(e: any) => deleteService(e, item?.["ID_PRODUTO_PRD"])} />
                                */}
                               </div>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                  </Card.Body>
                </Card>
                 

                  {/* <div className="d-flex justify-content-end"> */}
                  <div className="d-flex justify-content-center mt-lg-4 mt-3">
                    <Pagination
                      size="sm"
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={service.length}
                      pageSize={PageSize}
                      onPageChange={page => setCurrentPage(page)}
                    />
                  </div>
                </>
                :
                <>
                  <div className="border rounded-4 text-center text-secondary p-3" >{t("OOPS")}</div>
                </>
            }
          </>
      }
    </>
  )
}


