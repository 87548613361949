import React, { useEffect, useState } from 'react';
import { Modal, Form, Table } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { get_forms_of_receipts } from '../../../../../redux/slice/formsOfReceiptSlice';
import moment from 'moment';
import { converter } from '../../../../constants/utils';

const ViewInterest = (props: any) => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { nonPayment_get_single_rece_expense_Data, nonPaymentSetting_Data } = useSelector((state: RootState) => state.nonPayment);

    //View interest modal close open function
    const { showViewInterest, viewInterestClose, chargeValue, orginalType, setOrginalType, monetory, setMonetory, interestFromContract } = props;
    const [chargeDesc, setChargeDesc] = useState<any>(0);
    const { formdata } = useSelector((state: RootState) => state.forms);
    const [elementVisible, setElementVisible] = useState(false);

    console.log("interestFromContract >>>>>>>", interestFromContract)

    useEffect(() => {
        if (showViewInterest) {
            dispatch(get_forms_of_receipts())
            setChargeDesc(chargeValue)
        }
    }, [chargeValue, showViewInterest])

    useEffect(() => {
        if (showViewInterest) {
            if (orginalType == "orginal") {
                setElementVisible(true);
            }
        }
    }, [showViewInterest, chargeDesc, formdata])

    const viewInterestCloseButton = () => {
        viewInterestClose();
        setElementVisible(false);
        setOrginalType("");
        setMonetory(0);
    }

    return (
        <>
            <Modal show={showViewInterest} onHide={viewInterestCloseButton}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title className="d-sm-flex justify-content-between w-100 pe-sm-3">
                            {
                                (!elementVisible && orginalType == "") ?
                                    t("NonPayment.interestDetails")
                                    :
                                    t("NonPayment.originalCharges")
                            }
                            {
                                orginalType !== "orginal" &&
                                <span className="fw-bold text-primary fs-6 cursor-pe" onClick={() => setElementVisible(!elementVisible)}> {elementVisible ? t("NonPayment.Closeoriginalcharges") : t("NonPayment.openOriginalCharge")}</span>
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pt-0">
                        <Table responsive borderless size="sm">
                            {
                                (!elementVisible && orginalType == "") ? <>
                                    <thead>
                                        <tr>
                                            <th className="pb-lg-3 ps-0">{t("newContractDev.ChargeTitle")}</th>
                                            <th className="pb-lg-3">{t("NonPayment.penalty")}</th>
                                            <th className="pb-lg-3">{t("ReceiveandReceived.Interest")}</th>
                                            <th className="pb-lg-3">{t("formReceipt.fees")}</th>
                                            <th className="pb-lg-3">{t("NonPayment.monetary")}</th>
                                            <th className="pb-lg-3 pe-0">{t("NonPayment.accumulated")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="ps-0 text-nowrap">R$ {converter(chargeDesc)}</td>
                                            {/* {
                                                if (nonPaymentSetting_Data && nonPaymentSetting_Data.length > 0) {
                                                let ResultData = nonPaymentSetting_Data[0][];
                                                    if (ResultData && ResultData?.["BL_ALLOWCHANGES_NON"]) {
                                                    totalAMount = Number(item?.["vl_emitido_recb"]) + (((Number(item?.["vl_emitido_recb"]) * (ResultData["TX_INTERESTRATE_NUMBER_NON"] / 100))) + (Number(item?.["vl_emitido_recb"]) * (formdata.monthly_interest / 100)));
                                                }
                                            } */}

                                            {
                                                (nonPaymentSetting_Data && nonPaymentSetting_Data.length > 0 && nonPaymentSetting_Data?.[0]?.["BL_ALLOWCHANGES_NON"]) ?
                                                    <>
                                                        <td className="text-nowrap">R$ {converter(chargeDesc * (nonPaymentSetting_Data?.[0]?.["TX_INTERESTRATE_NUMBER_NON"] / 100))}</td>
                                                        <td className="text-nowrap">R$  {converter(chargeDesc * (nonPaymentSetting_Data?.[0]?.["TX_INTERESTRATE_NUMBER_NON"] / 100))}</td>
                                                    </>
                                                    :
                                                    <>
                                                        <td className="text-nowrap">R$  {converter(chargeDesc * ((interestFromContract.fees || formdata.fine_rate) / 100))}</td>
                                                        <td className="text-nowrap">R$ {converter(chargeDesc * ((interestFromContract.fees || formdata.fine_rate) / 100))}</td>
                                                    </>
                                            }
                                            <td className="text-nowrap">R$ {converter(chargeDesc * ((interestFromContract.interest || formdata.monthly_interest) / 100))} </td>
                                            {
                                                (monetory && String(monetory) !== "0") ?
                                                    <td className="text-nowrap">R$ {converter(chargeDesc * (monetory / 100))} </td>
                                                    :
                                                    <td className="text-nowrap">R$ {converter(`0.00`)} </td>
                                            }
                                            {
                                                (nonPaymentSetting_Data && nonPaymentSetting_Data.length > 0 && nonPaymentSetting_Data?.[0]?.["BL_ALLOWCHANGES_NON"]) ?
                                                    (monetory && String(monetory) !== "0") ?
                                                        <td className="pe-0 text-nowrap">R$ {converter(chargeDesc + ((chargeDesc * (nonPaymentSetting_Data[0]["TX_INTERESTRATE_NUMBER_NON"] / 100)) * 2) + (chargeDesc * ((interestFromContract.interest || formdata.monthly_interest) / 100)) + (chargeDesc * (monetory / 100)))}</td>
                                                        :
                                                        <td className="pe-0 text-nowrap">R$ {converter(chargeDesc + ((chargeDesc * (nonPaymentSetting_Data[0]["TX_INTERESTRATE_NUMBER_NON"] / 100)) * 2) + (chargeDesc * ((interestFromContract.interest || formdata.monthly_interest) / 100)))}</td>

                                                    :
                                                    (monetory && String(monetory) !== "0") ?
                                                        <td className="pe-0 text-nowrap">R$ {converter(chargeDesc + ((chargeDesc * ((interestFromContract.fees || formdata.fine_rate) / 100)) * 2) + (chargeDesc * ((interestFromContract.interest || formdata.monthly_interest) / 100)) + (chargeDesc * (monetory / 100)))}</td>
                                                        :
                                                        <td className="pe-0 text-nowrap">R$ {converter(chargeDesc + ((chargeDesc * ((interestFromContract.fees || formdata.fine_rate) / 100)) * 2) + (chargeDesc * ((interestFromContract.interest || formdata.monthly_interest) / 100)))}</td>
                                            }
                                        </tr>
                                    </tbody>
                                </>
                                    :
                                    <>
                                        <thead>
                                            <tr>
                                                <th className="pb-2 px-2 pt-0">Cod.</th>
                                                <th className="pb-2 px-2 pt-0">{t("dueDate")}</th>
                                                <th className="pb-2 px-2 pt-0">{t("contractlistother.Amount")} (R$)</th>
                                                <th className="pb-2 px-2 pt-0">{t("newContractDev.TaxInvoice")}</th>
                                                <th className="pb-2 px-2 pt-0">{t("propertyDev.observations")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                nonPayment_get_single_rece_expense_Data && nonPayment_get_single_rece_expense_Data.length > 0 ?
                                                    nonPayment_get_single_rece_expense_Data.map((item: any, index: number) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="px-2">
                                                                    <span className="d-block text-dark">{item["ID_LANCAMENTO_IMOD"]}</span>
                                                                </td>
                                                                <td className="px-2">
                                                                    <span className="d-block text-dark">{moment(item["DT_LANCAMENTO_IMOD"]).format("DD/MMM/YYYY")}</span>
                                                                </td>
                                                                <td className="px-2 text-dark">R$  {converter(item?.["VL_VALOR_IMOD"] ?? 0)}</td>
                                                                <td className="text-dark px-2">N/A</td>
                                                                {/* {item?.["ST_COMPLEMENTO_IMOD"] ?? "--"} */}
                                                                <td className="text-dark px-2">{(item?.["ST_COMPLEMENTO_IMOD"] && item?.["ST_COMPLEMENTO_IMOD"] !== "") ? item?.["ST_COMPLEMENTO_IMOD"] : "N/A"}</td>
                                                            </tr>
                                                        )
                                                    }) :
                                                    <tr>
                                                        <td colSpan={5} className='text-center align-item-center'>
                                                            No record found
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </>
                            }
                        </Table>
                    </Modal.Body>
                </Form>
            </Modal>
        </>
    )
}

export default ViewInterest
