import React, { useState, useEffect, MouseEvent, useImperativeHandle, forwardRef } from 'react';
import { Table, Form, Button, Modal, Tooltip, OverlayTrigger, ButtonGroup, Row, Col, Card, Badge, FloatingLabel, ProgressBar } from 'react-bootstrap';
import ChargeService from './ChargeService';
import ChargeLiquidate from './ChargeLiquidate';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { incomeService } from '../../../../services/incomeServices';
import { config } from '../../../../services/apiConfig';
import Loader from '../../../Profile/Company/Loader';
import Checkbox from '../../../Pages/Contracts/Common/Checkbox';
import { useParams } from 'react-router-dom';
import eventBus from "./EventBus";
import { converter, encrypt_Decrypt, formatDateToDDMMYYYY } from '../../../constants/utils';
import ChargesOverview from './ChargesOverview';
import { Link } from 'react-router-dom';
//import moment from "moment";
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { contractDatails,newExtraChargeOpen,setExtraCharge,setExtraChargeLabel,showChargeData,showChargeDate,deleteBillingTicketCron } from '../../../../redux/slice/contractDataSlice';
import Pagination from '../../../Pagination';
import moment from "moment-timezone";
import InvalidateBoletoService from "../../../Pages/Contracts/Common/InvalidateBoletoService";
import { gettoken } from "../../../../Api/Contact";

import ExtraChargeService from "../../../Pages/Contracts/Common/ExtraChargeService";

var getChargesSummery;
export { getChargesSummery };


const ReceivableChargesListing = (props: any) => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    const { contractDatail } = useSelector((state: RootState) => state.contractData)
    let { parentToChild, setChargeFooter, isCheckAll, setIsCheckAll,
        chargesList, setChargesDue, isCheckId, setIsCheckId, searchvalue,
        offFirstload, setOffFirstLoad, search_type, stateUpdate,
        stateChange, setStatechange, currentPage, setCurrentPage,
        status, setStatus, reasonInvalidationModalClose, setLoader_on1, handleInviladated,extraChargeClosed } = props;

    // isCheck, setIsCheck, chargesList, setChargesDue
    //New charges modal initialization
    const [showChargeModal, setChargeModal] = useState(false);
    const [userJson, setUserJson] = useState<any>([]);
    const newChargeModalClose = () => setChargeModal(false);

    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M').format("MMM/YYYY");
    let searchDt = moment(newDate).format("MMM/YYYY")
    let endDt = moment(newDate).add(1, 'M').format("MMM/YYYY");

    // Boleto Pix info modal initialization
    const [showBoletoPixModal, setBoletoPixModal] = useState(false);
    const [BoletoPixAarinError, setBoletoPixAarinError] = useState('');
    const [canRegBoletoModal, setCanRegBoletoModal] = useState(false);
    const [regenratedate, setregenratedate] = useState(false);
    const changeBoletoPixClose = () => setBoletoPixModal(false);
    const [paymentinfo, setPaymentInfo] = useState<any>("");
    const [singleChargeId, setSingleChargeId] = useState<any>([])
    const [loader_on, setloader_on] = useState(false);
    // Print out modal initialization
    const [showPrintOutModal, setPrintOutModal] = useState(false);
    const printOutModalClose = () => setPrintOutModal(false);
    const printOutModalShow = () => setPrintOutModal(true);

    // Liquidate modal initialization
    const [showLiquidateModal, setLiquidateModal] = useState(false);
    const { register, handleSubmit, setValue, reset, formState: { errors }, setError, clearErrors } = useForm({});
    const { datepickershow } = useSelector((state: RootState) => state.contractData)

    const [chargeTotal, setChargeTotal] = useState({
        totalCharge: 0,
        totalCustomer: 0,
        totalAmount: 0,
    })

    useEffect(() => {
        let userResult = encrypt_Decrypt();
        setUserJson(userResult);
    }, [])

    const updateCharge = (e: any) => {
        const dataName = e.currentTarget.getAttribute('data-name');
        document.querySelector("body")?.setAttribute("new_charge_action", "edit")
        const dataSingle = e.currentTarget.getAttribute('data-name1');
        document.querySelector("body")?.setAttribute("single", dataSingle)
        eventBus.dispatch("chargeIdis", dataName);
        setTimeout(() => {
            setChargeModal(true);
        }, 500)
    }

    const openChargeInfo = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault(); 
        // setPaymentInfo(""); 
        const ID_RECEBIMENTO_RECB = e.currentTarget.getAttribute('data-rowid');
        const ID_CONTRATO_CON = e.currentTarget.getAttribute('data-conId');
        incomeService.getChargeInvoice({ ID_RECEBIMENTO_RECB : ID_RECEBIMENTO_RECB,ID_CONTRATO_CON : ID_CONTRATO_CON}).then(res => {
            if (res.data && res.data && (res.data.message && res.data.message.status == 'success' ) ) {
                let url = `${config.CUSTOMER___API}/${res.data.message.boletoGCurl}`;
                // let url = `http://44.203.23.124:7004/public/images/single_ChargeInvoice.pdf`;
                window.open(url, '_blank');
                // Swal.fire({
                //     title: t("Sweetalert.Success"),
                //     text: t("Sweetalert.PaymentispaidsuccessfullyonAarin"),
                //     confirmButtonText: "OK",
                //     timer: 2000
                // });
                // PaymentMarkPaid();
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.serverError"),
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        });
    }

    const checkAarinPaymentStatus = () => {
        let argument = paymentinfo;
        incomeService.getAarinPaymentStatus(argument).then(res => {
            if (res.data && res.data.length && (res.data[0].status == 'DONE' || res.data[0].status == 'Pago' || res.data[0].status == 'CONCLUIDA')) {
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.PaymentispaidsuccessfullyonAarin"),
                    confirmButtonText: "OK",
                    timer: 2000
                });
                PaymentMarkPaid();
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: res.data[0].status ? 'Curent payment status is "' + res.data[0].status + '"' : t("Sweetalert.PaymentisnotCompleted"),
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        });
    }
    const moreoption = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setPaymentInfo("");
        let boletoUrl = e.currentTarget.name;
        const billingType = e.currentTarget.getAttribute('data-billiingtype');
        const boleto = e.currentTarget.getAttribute('data-boleto');
        const pixData = e.currentTarget.getAttribute('data-pixData');
        const ID_RECEBIMENTO_RECB = e.currentTarget.getAttribute('data-rowid');
        const ID_CONTRATO_CON = e.currentTarget.getAttribute('data-conId');
        if (billingType == 'BOLETO' || billingType == 'Boleto') {
            boletoUrl = 'https://barcode.orcascan.com/?type=code128&data=' + boleto;
        } else if (billingType == 'PIX' || billingType == 'pix') {
            boletoUrl = `data:image/png;base64, ${pixData}`;
        }
        setPaymentInfo({ billingType: billingType, boletoUrl: boletoUrl, boleto: boleto, ID_RECEBIMENTO_RECB: ID_RECEBIMENTO_RECB, ID_CONTRATO_CON: ID_CONTRATO_CON });
        setCanRegBoletoModal(true);
        setBoletoPixModal(false);
    }
    let [ leaseError,setLeaseError ] = useState("");
    let [ maurityError,setMaurityError ] = useState("");
    const cancelRegFrmSubmit = (data) => {
        if(data.lease_period===null){
            setLeaseError(t("requiredMmessage.fieldRequired"));
        }
        else {
            if(data.lease_period=="CancelRegenrateBoleto" && data.startDate==''){
                setMaurityError(t("requiredMmessage.fieldRequired"));
            }
            else {
                setLeaseError("");
                setMaurityError("");
                setloader_on(true)
                let startDtt = moment(data.startDate).format("YYYY-MM-DD");
                let argument: any = paymentinfo;
                argument['maturity'] = startDtt;
                argument['type'] = data.lease_period;               
                incomeService.cancelPaymentStatus(argument).then(res => {
                    if (res.data && (res.data.resCode == 200)) {
                        Swal.fire({
                            title: t("Sweetalert.Success"),
                            text: t("Sweetalert.PaymentiscanceledonAarin"), 
                            confirmButtonText: "OK",
                            timer: 4000
                        });
                        canRegBoleto();
                        setCanRegBoletoModal(false); setBoletoPixModal(false); 
                        setStatus("loading"); getChargesSummery(); reset();
                        setloader_on(false)
                    } else {
                        setloader_on(false)
                        Swal.fire({
                            title : t("Sweetalert.Error"),
                            text  : res.message,
                            confirmButtonText: "OK",
                            timer : 4000
                        });
                    }
                });
            }
        }
    }

    const PrintAfterLoad = (url: any) => {
        const newWindow: any = window.open(url, '_blank');
        newWindow.addEventListener('load', () => {
            newWindow.print();
        });
    }
    const PaymentMarkPaid = () => {
        let argument = paymentinfo;
        dispatch(deleteBillingTicketCron(argument.ID_RECEBIMENTO_RECB))
        incomeService.markPaymentPaid(argument).then(res => {
            if (res && (res.resCode == 200 || res.resCode == 201)) {
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.Paymentmarkedpaidsuccessfully"),
                    confirmButtonText: "OK",
                    timer: 2000
                }).then(async (result) => {
                    setBoletoPixModal(false);
                    setStatus("loading");
                    getChargesSummery();
                });
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.PaymentisnotCompleted"),
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        });
    }
    const liquidateModalClose = () => {
        document.querySelector("body")?.setAttribute("charge_liquidity_id", "")
        setLiquidateModal(false)
    };
    const liquidateModalShow = (e) => {
        let currentId = e.target.getAttribute('data-name');
        document.querySelector("body")?.setAttribute("charge_liquidity_id", currentId)
        setLiquidateModal(true)
    }; 
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [singlecount, setSingleCount] = useState();

    useEffect(() => {
        let compData: any = '';
        if (parentToChild) {
            compData = parentToChild ? JSON.parse(parentToChild) : '';
        }
        if (compData.action == 'selectall') {
            selectAllUsers();
        } else if (compData.action == 'unselectall') {
            unSelectAllUsers();
        } else if (compData.action == 'change_expiration') {

        } else if (compData.action == 'reason_invalidation') {
            if (isCheckId && isCheckId.length > 0) {
                let JsonDataIs = JSON.parse(parentToChild);
                JsonDataIs["id_arr"] = isCheckId;
                JsonDataIs.type = "Cash";
                incomeService.Invalidate_charge(JsonDataIs).then(res => {
                    if (res.resCode == 200 && (res.type == "success" || res.type == "Success") ) {
                        Swal.fire({
                            title: t("Sweetalert.addedService"),
                            text: t("Sweetalert.updatesuccess"),
                            icon: 'success',
                            timer: 2000,
                        });
                        getChargesSummery();
                        unSelectAllUsers();
                        setIsCheckId([]);
                        reasonInvalidationModalClose();
                    } else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.updatefailed"),
                            icon: "error",
                            timer: 2000,
                        });
                    }
                    setLoader_on1(false);
                }).catch(err => {
                    setLoader_on1(false);
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.updatefailed"),
                        icon: "error",
                        timer: 2000,
                    });
                });
            } else {
                setLoader_on1(false);
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.Pleasecheckedthe"),
                    timer: 2000,
                });
                reasonInvalidationModalClose();
            }
        } else {
            setStatus("loading");
        }
    }, [dispatch, parentToChild])

    const { id } = useParams();
    useEffect(() => {
        if (Object.keys(searchvalue) && Object.keys(searchvalue).length) {
            setStatechange({
                ...stateChange,
                ["filter"]: searchvalue
            })
        } else {
            setStatechange({
                ...stateChange,
                ["filter"]: {}
            })
        }
    }, [searchvalue])

    useEffect(() => {
        if (stateUpdate) {
            stateUpdate["startDate"] = startDt;
            stateUpdate["endDate"] = endDt;
            stateUpdate["searchDate"] = searchDt;
            setStatechange(stateUpdate)
        }
    }, [stateUpdate])
    // useEffect(() => {
    //     if (offFirstload) {
    //         //Filter api need to integreate here
    //         let argument = state;
    //         incomeService.getChargesSummery(argument).then(res => {
    //             if (res.data) {
    //                 setChargesDue(res.data);
    //                 let totalAmount = 0;
    //                 let uniqCustomer = new Set();
    //                 (res.data).forEach(item => {
    //                     totalAmount += item.VL_TOTAL_RECB;
    //                     uniqCustomer.add(item.ID_SACADO_SAC)
    //                 })
    //                 setChargeFooter({
    //                     totalCharge: (res.data).length,
    //                     totalCustomer: uniqCustomer.size,
    //                     totalAmount: totalAmount,
    //                 })
    //                 setChargeTotal({
    //                     totalCharge: (res.data).length,
    //                     totalCustomer: uniqCustomer.size,
    //                     totalAmount: totalAmount,
    //                 })
    //             } else {
    //                 setChargesDue([]);
    //             }
    //             setStatus("");
    //         }).catch(err => {
    //             setStatus("");
    //         });
    //     }
    // }, [searchvalue])

    useEffect(() => {
        if (search_type !== "") {
            setStatechange({
                ...stateChange,
                ["search_type"]: search_type
            })
        }
        // else {
        //     setStatechange({
        //         ...stateChange,
        //         ["search_type"]: ""
        //     })
        // }
    }, [search_type])

    // Pagination start
    let PageSize = stateChange.end;
    const [totalpage, setTotalPage] = useState(0);

    useEffect(() => {
        stateChange["start"] = (currentPage - 1) * stateChange["end"];
        let recebentoId: any = document.querySelector("#root")?.getAttribute("recebentoid");
        if (recebentoId && recebentoId !== null) {
            let data = {
                FL_STATUS_RECB: 0,
                ID_RECEBIMENTO_RECB: recebentoId
            }
            getpendingChargesSummer(data)
        } else {
            getChargesSummery()
        }
    }, [stateChange, currentPage])

    let getpendingChargesSummer = (data) => {
        incomeService.getpendingChargesSummery(data).then(res => {
            if (res.data) {
                document.querySelector("#root")?.removeAttribute("recebentoId");
                // if (datachargelist) {
                setChargesDue(res.data);
                setTotalPage(res.totalRow);
                let totalAmount = 0;
                let uniqCustomer = new Set();
                (res.data).forEach(item => {
                    totalAmount += item.VL_TOTAL_RECB;
                    uniqCustomer.add(item.ID_SACADO_SAC)
                })
                setChargeFooter({
                    totalCharge: res.data && (res.data).length,
                    totalCustomer: uniqCustomer.size,
                    totalAmount: totalAmount,
                })
                setChargeTotal({
                    totalCharge: res.data && (res.data).length,
                    totalCustomer: uniqCustomer.size,
                    totalAmount: totalAmount,
                })
                const idCounts = {};
                let singleId: any = [];
                // Iterate through the data and count the occurrences
                (res.data).forEach(item => {
                    const id = item.ID_CONTRATO_CON;
                    if (idCounts[id]) {
                        idCounts[id]++;
                    } else {
                        idCounts[id] = 1;
                    }
                });

                // Check the counts and log the appropriate message
                Object.keys(idCounts).forEach(id => {
                    if (idCounts[id] > 1) {
                    } else {
                        singleId.push({ id })

                    }
                });
                setSingleChargeId(singleId)
            }
            else {
                setChargesDue([]);
            }
            setStatus("");
        }).catch(err => {
            setStatus("");
        });
    }


    getChargesSummery = () => {
        incomeService.getChargesSummery(stateChange).then(res => {
            if (res.data) {
                // if (datachargelist) {
                setChargesDue(res.data);
                setTotalPage(res.totalRow);
                let totalAmount = 0;
                let uniqCustomer = new Set();
                (res.data).forEach(item => {
                    totalAmount += item.VL_TOTAL_RECB;
                    uniqCustomer.add(item.ID_SACADO_SAC)
                })
                setChargeFooter({
                    totalCharge: res.data && (res.data).length,
                    totalCustomer: uniqCustomer.size,
                    totalAmount: totalAmount,
                })
                setChargeTotal({
                    totalCharge: res.totalRow ? res.totalRow : 0,
                    totalCustomer: uniqCustomer.size,
                    totalAmount: res.totalRecievedCharge ? res.totalRecievedCharge : 0,
                })
                const idCounts = {};
                let singleId: any = [];
                // Iterate through the data and count the occurrences
                (res.data).forEach(item => {
                    const id = item.ID_CONTRATO_CON;
                    if (idCounts[id]) {
                        idCounts[id]++;
                    } else {
                        idCounts[id] = 1;
                    }
                });

                // Check the counts and log the appropriate message
                Object.keys(idCounts).forEach(id => {
                    if (idCounts[id] > 1) {
                    } else {
                        singleId.push({ id })

                    }
                });
                setSingleChargeId(singleId)
            } else {
                setChargesDue([]);
            }
            setStatus("");
        }).catch(err => {
            setStatus("");
        });
    }

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        if (!isCheckAll) { selectAllUsers(); } else { unSelectAllUsers(); }
    };

    const selectAllUsers = () => {
        setChargesDue(chargesList.map(topping => ({ ...topping, isChecked: true })))
        let allId: any = [];
        chargesList.forEach(item => allId.push(item.ID_RECEBIMENTO_RECB))
        setIsCheckId(allId);
    }

    const unSelectAllUsers = () => {
        setChargesDue(chargesList.map(topping => ({ ...topping, isChecked: false })))
        setIsCheckId([]);
    }

    // Check / Uncheck Single row event.
    const selectRow = async (e) => {
        setChargesDue([]);
        let allId: any = [];
        await chargesList.map(data => {
            if (data && parseInt(e.target.value) === parseInt(data.ID_RECEBIMENTO_RECB)) {
                data['isChecked'] = !data.isChecked ? true : false;
            }
            if (data.isChecked) {
                allId.push(Number(data.ID_RECEBIMENTO_RECB))
            }
            return data;
        });
        if (chargesList && chargesList.length == allId.length) {
            setIsCheckAll(true);
        } else {
            setIsCheckAll(false);
        }
        setIsCheckId(allId);
        await setChargesDue(chargesList);
    }

    const contractStOpen = (evt, num) => {
        if (isCheckId && isCheckId.length > 0) {
            Swal.fire({
                title: "Are you sure want to readjust it.",
                text: t("Sweetalert.Youwontabletomodifyitlater"),
                // icon: 'success','info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Readjust it!'
            }).then(async (result) => {
                if (result.value) {

                }
            })
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000,
            });
        }
    };

    const filterClose = () => {
        dispatch(showChargeDate(false))
        reset();
    }
    const canRegBoleto = () => {
        setCanRegBoletoModal(false);
        // dispatch(showChargeDate(false))
        reset();
        setloader_on(false);
        setLeaseError("");
        setMaurityError("");
        setregenratedate(false);
    }
    const dateSubmit = (data) => {
        let startDtt = moment(data.startDate).format("YYYY-MM-DD");
        let endDtt = moment(data.endDate).format("YYYY-MM-DD");
        setStatechange({
            ...stateChange,
            ['filteSearchDate']: startDtt,
            ['filterEndDate']: endDtt,
            ["ID_RECEBIMENTO_RECB"]: "",
            ["listType"]: "",
        })
        dispatch(showChargeDate(false))
        dispatch(showChargeData(stateChange))
        reset();
    }

    useEffect(() => {
        if (chargesList?.["totalRow"] !== undefined) {
            let count = Number(chargesList?.["totalRow"]);
            setTotalPage(count);
        }
    }, [chargesList, currentPage])

    const boletoAction = (evt) => {
        setregenratedate(false);
        if (evt.target.value == 'CancelRegenrateBoleto') {
            setregenratedate(true);
        }
    };
    const handleInviladated1 = (chargeid: any) => {
        handleInviladated(chargeid)
    }
	const showTheBoletoError = (AARINRESP : any) => {
		if( AARINRESP && AARINRESP != ''){
            let rm = JSON.parse(AARINRESP);
            if( rm.data && rm.data.length ){
                rm = rm.data[0].message + ' ' + rm.data[0].parameter;
                setBoletoPixAarinError(rm);
                setBoletoPixModal(true);
            }
        }
	}
    const [ showInvalidationModal,setInvalidationModal ] = useState(false);
    const [ type,setType ] = useState("");
    const [ recevId,setRecevId ] = useState<any>([]);

    const cancelInvalidate = async(title,recevId)=>{
        setInvalidationModal(true);
        setType(title);
        setRecevId([recevId]);
    }
    const cancelInvalidateClose = ()=>{
        setInvalidationModal(false);
        getChargesSummery();
        unSelectAllUsers();
        setIsCheckId([]);
        reasonInvalidationModalClose();
    }
    const [ extraAndDiscount,setExtraAndDiscount ] = useState({});
    const [ contractId,setContractId ] = useState(null);
    const newExtraCharge = (chargeId,contractId) => {
        (async () => {
            let response = await incomeService.getDiscountData(gettoken.userId);
            if (response.status == "success" || response.status == "Success") {
                setExtraAndDiscount(response.data);
            }
        })();
        setContractId(contractId);
        dispatch(setExtraChargeLabel("Edit Charge"));
        dispatch(setExtraCharge(chargeId));
        dispatch(newExtraChargeOpen(true)); 
        dispatch(contractDatails(Number(contractId)));       
    };
    const [pageLoaded, setPageLoaded] = useState(false);
    const extraChargeFormClosed = () => {
        setContractId(null)
        dispatch(newExtraChargeOpen(false));      
        getChargesSummery();
    }

    return (
        <>
            <ChargesOverview chargesList={chargesList} chargefooter={chargeTotal} state={stateChange} />
            <InvalidateBoletoService showInvalidationModal={showInvalidationModal} cancelInvalidateClose={cancelInvalidateClose} recevId={recevId} type={type} />
             <ExtraChargeService
                        extraAndDiscount={extraAndDiscount}
                        setExtraAndDiscount={setExtraAndDiscount}
                        extraChargeClosed={extraChargeFormClosed}
                        contractId= {contractId}
                        
                    />   
            {
                (status == "loading") ?
                    <Loader />
                    :
                    <>
                        <Card className="mt-lg-4 mt-3" id="ReceivablelistOftsxContracts">
                            <Card.Body className="pb-1">
                                <Table id="ReceivableChargesListingtsx" responsive className="table-custom table-custom-bordered  mb-0">
                                    <thead>
                                        <tr>
                                            <th className="w-24"><Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} /></th>
                                            <th>{t("maturity")}</th>
                                            <th>{t("Client")}</th>
                                            <th className="text-end">{t("value")}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {chargesList && chargesList.length > 0 &&
                                            chargesList.map((charges: any, index) => {
                                                let BILLINGTYPE = "CASH";
                                                if( charges.BILLING_TYPE ){
                                                    BILLINGTYPE = charges.BILLING_TYPE;
                                                }
                                                let boletRes = ''; let Boletomm: any = ''; let pixData = ''; let hideInvalidateIcon : any= false
                                                if (charges.API_RESPONSE && charges.API_RESPONSE != null) {
                                                    Boletomm = JSON.parse(charges.API_RESPONSE);
                                                    boletRes = Boletomm.url ? Boletomm.url : '';
                                                    pixData = (Boletomm != null && Boletomm && Boletomm.qrCode && Boletomm.qrCode != '') ? Boletomm.qrCode.base64Image : '';
                                                    if( Boletomm.resCode == 400 ){
                                                        hideInvalidateIcon = true;
                                                    }
                                                } 

                                                if ( charges?.GC_BOLETO_URL != null && charges?.GC_BOLETO_URL != '' && ( charges?.GC_BOLETO_URL.indexOf('storage') >= 0 || charges?.GC_BOLETO_URL.indexOf('internal') >= 0  || (charges?.GC_BOLETO_URL).indexOf('aarinip') >= 0  ) ){

                                                } else {
                                                    if( BILLINGTYPE != 'CASH' && ( charges.API_RESPONSE == '' || charges.API_RESPONSE == null )  && charges?.FL_STATUS_RECB != 6 ){
                                                        charges['GC_BOLETO_URL'] = '1'
                                                    } 
                                                }
                                                
                                                let todayDate = moment.tz(dateBrazil, 'America/Sao_Paulo');
                                                let maturityDate = moment(charges.DT_VENCIMENTO_RECB.split('T')[0]);
                                                let Difference_In_Time = maturityDate.diff(todayDate);
                                                let Difference_In_Days: any = Difference_In_Time / (1000 * 3600 * 24);
                                                let MathSign = Math.sign(Difference_In_Days);

                                                let dayAgo: any = "";
                                                let upcomDay: any = "";

                                                if (MathSign == -1) {
                                                    dayAgo = String(parseInt(Difference_In_Days)).replace("-", "");
                                                    if (Number(dayAgo) > 30) {
                                                        if ((Math.floor(Number(dayAgo) / 30)) > 12) {
                                                            dayAgo = String(Math.floor((Number(dayAgo) / 30) / 12)) + "y";
                                                        } else {
                                                            dayAgo = String(Math.floor(Number(dayAgo) / 30)) + "m";
                                                        }
                                                    } else {
                                                        if (dayAgo == "0") {
                                                            dayAgo = dayAgo;
                                                        } else {
                                                            dayAgo = dayAgo;
                                                        }
                                                    }

                                                } else {
                                                    upcomDay = String(parseInt(Difference_In_Days + 1));
                                                    if (Number(upcomDay) > 30) {
                                                        if ((Math.floor(Number(upcomDay) / 30)) > 12) {
                                                            upcomDay = String(Math.floor((Number(upcomDay) / 30) / 12)) + "y";
                                                        } else {
                                                            upcomDay = String(Math.floor(Number(upcomDay) / 30)) + "m";
                                                        }
                                                    } else {
                                                        if (upcomDay == "0") {
                                                            upcomDay = upcomDay;
                                                        } else {
                                                            upcomDay = upcomDay;
                                                        }
                                                    }
                                                }
                                                return (
                                                    <tr key={index}>
                                                        <td className='check_Checkbox'><Form.Check type="checkbox" data-id={charges.ID_RECEBIMENTO_RECB} onChange={selectRow} checked={charges.isChecked} value={charges.ID_RECEBIMENTO_RECB} /></td>
                                                        <td>
                                                            {formatDateToDDMMYYYY((charges.DT_VENCIMENTO_RECB).split("T")[0])}
                                                            {/* <span className="d-block">Late for 23d</span> */}
                                                            {
                                                                dayAgo !== "" ?
                                                                    <>
                                                                        <span className="d-block my-1 fs-12">{dayAgo == "0" ? t("ReceiveandReceived.Remain") : t("ReceiveandReceived.Overdue") + " " + dayAgo + " " + t("NonPayment.days")} </span>
                                                                    </>
                                                                    : ""

                                                            }
                                                            {
                                                                upcomDay !== "" ?
                                                                    <>
                                                                        <span className="d-block my-1 fs-12">{upcomDay == "1" ? t("ReceiveandReceived.Duetomorrow") : t("ReceiveandReceived.Latefor") + " " + upcomDay + " " + t("NonPayment.days")} </span>
                                                                    </>
                                                                    : ""

                                                            }
                                                            {/* <span className="d-block text-primary fw-bold" >Billing suspended</span> */}
                                                            {!charges.ID_CONTRATO_CON ?
                                                                <>
                                                                    <Badge bg="primary-20" className='text-dark'>{t("single")}</Badge>
                                                                </> :
                                                                ""
                                                            }
                                                            {/* {singleChargeId.map((item) => (
                                                            item.id == charges.ID_CONTRATO_CON ? (
                                                                <span key={item.id} className='bg-warning text-white px-1'>Single</span>
                                                            ) : (
                                                                <span key={item.id}></span>
                                                            )
                                                        ))} */}

                                                            { ( charges?.AARIN_PAYMENT_STATUS == 'canceled' || charges.FL_STATUS_RECB == 6 ) &&
                                                                <> <span className='text-danger fs-12'>Suspended</span> </>
                                                            }
                                                            {
                                                                charges?.ID_GARANTIACOBRANCA_GCO ?
                                                                    <div className="d-block"><Badge bg="success-10" className="text-dark">{t("guaranteed")}</Badge></div>
                                                                    : ''
                                                            }
                                                            {
                                                                charges?.ownerStatus ?
                                                                    <div className="d-block"><Badge bg="success-10" className="text-dark">Agency owned</Badge></div>
                                                                    : ''
                                                            }

                                                            {
                                                                charges?.reverseStatus ?
                                                                    <span className="d-block text-primary fw-bold">{t("paidTobepaid.Reversed")}</span>
                                                                : ''
                                                            }
                                                        </td>
                                                        <td>
                                                            {charges.ID_CONTRATO_CON !== null ? charges.ID_CONTRATO_CON && <Link to={"/contract_details/" + charges.ID_CONTRATO_CON}> <span className="text-dark-70">{t("Dashboard.Contract")} {charges.ST_IDENTIFICADOR_IMO}/ {charges.lease_count ? charges.lease_count : ""} </span> </Link> : ""}
                                                            <span className="ps-1">
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/user_g.svg"} /><span className="ps-1">{charges.ID_SACADO_SAC} - {charges.ST_NOMEREF_SAC} </span>
                                                            </span>
                                                            <span className="d-block mb-1">
                                                                {charges.ID_RECEBIMENTO_RECB ?
                                                                    <>{t("contractlistother.charge")} : {charges.ID_RECEBIMENTO_RECB} {t("ReceiveandReceived.with")} {charges.BILLING_TYPE} NN: {Boletomm?.nossoNumero || ""}</>
                                                                    :
                                                                    <></>
                                                                }
                                                                {/* {t("ReceiveandReceived.NochargesFound")}  */}
                                                                {/* <Badge bg="danger">It is not active</Badge> to activate the ticket via Sicoob Teste .. */}
                                                            </span>
                                                            {BILLINGTYPE ?
                                                                <div className="w-75"><ProgressBar className="h-10 w-75" variant="success" now={100} /></div>
                                                                :
                                                                <div className="w-75"><ProgressBar className="h-10 w-75" variant="warning" now={50} /></div>
                                                            }
                                                        </td>
                                                        <td className="text-end">
                                                            <span className="d-block text-nowrap">R$ {converter(`${charges["VL_TOTAL_RECB"]}`)}</span>
                                                        </td>

                                                        <td className="text-nowrap text-end torecvedicons">
                                                            <div className="d-flex align-itmes-center justify-content-end">
                                                                
                                                                { charges?.API_RESPONSE && charges.API_RESPONSE != '' &&
                                                                    <>
                                                                        <span
                                                                            className="img-primary icon cursor-pe me-1 me-xl-2 me-xxl-2"
                                                                            onClick={() =>
                                                                                showTheBoletoError(charges.API_RESPONSE)
                                                                            }
                                                                        >
                                                                            <img
                                                                                className="cursor-pe h-20"
                                                                                src={
                                                                                    process.env.PUBLIC_URL +
                                                                                    "/assets/images/icons/ErrorCircle.svg"
                                                                                }
                                                                                alt="Edit/View"
                                                                            />
                                                                        </span>

                                                                    </>
                                                                }
                                                                
                                                                {charges?.AARIN_PAYMENT_STATUS == null &&
                                                                    <>
                                                                        {
                                                                            userJson && userJson.length > 0 && (userJson.indexOf(3031) !== -1) &&
                                                                                charges.FL_STATUS_RECB == 0 || charges.FL_GARANTIDA_GCO == 0 ?
                                                                                <>
                                                                                    { 
                                                                                        charges.ID_CONTRATO_CON !== null && charges.ID_CONTRATO_CON ?
                                                                                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("Editbilling")}</Tooltip>}>
                                                                                            <span className="img-primary icon cursor-pe me-1 me-xl-2 me-xxl-2" onClick={()=>newExtraCharge(charges.ID_RECEBIMENTO_RECB,charges.ID_CONTRATO_CON)} ><img src="assets/images/icons/edit-light.svg" alt="Edit" className="h-20" /></span>
                                                                                        </OverlayTrigger>
                                                                                        : 
                                                                                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("Editbilling")}</Tooltip>}>
                                                                                            <span className="img-primary icon cursor-pe me-1 me-xl-2 me-xxl-2" onClick={(e) => updateCharge(e)} data-name={charges.ID_RECEBIMENTO_RECB} data-name1={!charges.ID_CONTRATO_CON ? "single" : ""}><img src="assets/images/icons/edit-light.svg" alt="Edit" className="h-20" /></span>
                                                                                        </OverlayTrigger>
                                                                                    }

                                                                                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("expenditure.liquidate")}</Tooltip>}>
                                                                                        <span className="icon img-primary cursor-pe me-1 me-xxl-2 me-lg-2" onClick={liquidateModalShow} data-name={charges.ID_RECEBIMENTO_RECB}><img src={process.env.PUBLIC_URL + "/assets/images/icons/liquidate-light.svg"} alt="liquidate" className="h-20" /></span>
                                                                                    </OverlayTrigger>
                                                                                </>
                                                                                : ''
                                                                        }

                                                                        <OverlayTrigger placement="bottom" overlay={<Tooltip>
                                                                            {BILLINGTYPE} {t("ReceiveandReceived.info")}</Tooltip>}>
                                                                            <Button variant="link" className="p-0" name={boletRes} onClick={openChargeInfo}
                                                                                data-conId={charges.ID_CONTRATO_CON} data-rowid={charges.ID_RECEBIMENTO_RECB} >
                                                                                {( BILLINGTYPE != 'PIX' && BILLINGTYPE != 'BOLETO' ) ?
                                                                                    <span className="icon img-primary cursor-pe me-1 me-xxl-2 me-lg-2">
                                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/currency.svg"} alt="Cash info" className="cursor-pe h-18" /></span>
                                                                                    : <></>
                                                                                }
                                                                            </Button>
                                                                        </OverlayTrigger>

                                                                        { 
                                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip>Ticket information</Tooltip>}>
                                                                                <Button variant="link" className="p-0" name={boletRes} onClick={openChargeInfo}
                                                                                    data-conId={charges.ID_CONTRATO_CON} data-rowid={charges.ID_RECEBIMENTO_RECB} >
                                                                                    <span className="icon img-primary cursor-pe me-1 me-xxl-2 me-lg-2">
                                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/download-arrow-danger.svg"} alt="Download" className="cursor-pe h-18" />
                                                                                    </span>    
                                                                                </Button> 
                                                                            </OverlayTrigger>
                                                                        }
                                                                                

                                                                        { ( charges.FL_STATUS_RECB != 6 && (( charges?.GC_BOLETO_URL != '' && charges?.GC_BOLETO_URL != null ) || charges.API_RESPONSE != '' || charges?.GC_BOLETO_URL == "1" ) ) ?
                                                                        <>
                                                                        <OverlayTrigger placement="bottom" overlay={<Tooltip>
                                                                            {t("button.cancel")} {BILLINGTYPE}</Tooltip>}>
                                                                                
                                                                            {/*<Button variant="link" className="p-0" name={boletRes} onClick={moreoption} data-billiingtype={charges?.BILLING_TYPE} data-boleto={Boletomm?.codigoBarrasFormatado}
                                                                                data-pixData={pixData} data-conId={charges.ID_CONTRATO_CON} data-rowid={charges.ID_RECEBIMENTO_RECB} >*/}

                                                                            <Button variant="link" className="p-0" onClick={()=>cancelInvalidate('Boleto',charges.ID_RECEBIMENTO_RECB)} >
                                                                                {BILLINGTYPE == 'PIX' ?
                                                                                    <span className="icon img-primary cursor-pe me-1 me-xxl-2 me-lg-2"><img src={process.env.PUBLIC_URL + "/assets/images/icons/cross-w.svg"} alt="Cancel Pix" className="h-20" /></span>
                                                                                    : <></>
                                                                                }
                                                                                {BILLINGTYPE == 'BOLETO' ?
                                                                                    <span className="icon img-primary cursor-pe me-1 me-xxl-2 me-lg-2"><img src={process.env.PUBLIC_URL + "/assets/images/icons/cross-w.svg"} alt="Cancel Boleto" className="cursor-pe h-18" /></span>
                                                                                    : <></>
                                                                                }
                                                                            </Button>
                                                                            
                                                                        </OverlayTrigger>
                                                                        </>
                                                                         : ''
                                                                        }  
  
                                                                        {
                                                                        (( charges?.GC_BOLETO_URL != '' && charges?.GC_BOLETO_URL != null && ( (charges?.GC_BOLETO_URL).indexOf('storage') >= 0  || (charges?.GC_BOLETO_URL).indexOf('internal') >= 0 || (charges?.GC_BOLETO_URL).indexOf('aarinip') >= 0  ) ) && charges.API_RESPONSE == '' && BILLINGTYPE != 'CASH' && BILLINGTYPE != 'PIX' || boletRes != '') ?
                                                                            <span className="me-1 me-xxl-2 me-lg-2">
                                                                                {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>Ticket information</Tooltip>}>
                                                                                    <Link to={charges?.GC_BOLETO_URL ? charges?.GC_BOLETO_URL : boletRes} target='_blank' onClick={() => PrintAfterLoad(charges?.GC_BOLETO_URL ? charges?.GC_BOLETO_URL : boletRes)}>
                                                                                        <span className="icon img-primary cursor-pe"><img src={process.env.PUBLIC_URL + "/assets/images/icons/download-arrow-danger.svg"} className="h-20" /></span>
                                                                                    </Link>
                                                                                </OverlayTrigger> */}
                                                                            </span>
                                                                            : 
                                                                            <>
                                                                            {
                                                                                charges.FL_STATUS_RECB != 6 ?
                                                                                    ( ( charges?.GC_BOLETO_URL == '' && charges?.GC_BOLETO_URL == null && charges.API_RESPONSE == '' ) && hideInvalidateIcon ||  BILLINGTYPE == 'CASH' ) && 
                                                                                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("ReceiveandReceived.Invalidate")}</Tooltip>}>
                                                                                            {/*<span className="icon img-primary me-2 mr-0 pointer" onClick={(e) => handleInviladated1(charges.ID_RECEBIMENTO_RECB)}>*/}
                                                                                        <span className="icon img-primary me-2 mr-0 pointer" onClick={()=>cancelInvalidate('Cash',charges.ID_RECEBIMENTO_RECB)}>
                                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/cross-w.svg"} className="cursor-pe h-18" />
                                                                                            </span>
                                                                                        </OverlayTrigger>
                                                                                :   ''
                                                                            }
                                                                            </>
                                                                        }
                                                                    </>
                                                                } 
                                                                  
                                                                {
                                                                    dayAgo !== "" && dayAgo != "Today" ?
                                                                        <span className="icon img-warning" title={t("Dashboard.Overdue")}>
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/pending.svg"} className="h-20" />
                                                                        </span>
                                                                        : ''
                                                                        // <span title={t("pending")}>
                                                                        //     <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 18v-12h2v12h-2zm4 0v-12h2v12h-2zm4 0v-12h1v12h-1zm8 0v-12h1v12h-1zm-5 0v-12h3v12h-3zm8 0v-12h2v12h-2zm4 0v-12h1v12h-1z" /></svg>
                                                                        // </span>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                        {!chargesList.length ? (
                                            <tr>
                                                <td colSpan={6} className="text-center">{t("ReceiveandReceived.NochargesFound")}.</td>
                                            </tr>
                                        ) : (<></>)
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </>
            }

            <div className="d-flex justify-content-center mt-lg-4 mt-3">
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalpage}
                    pageSize={PageSize}
                    onPageChange={(page: any) => setCurrentPage(page)}
                />
            </div> 

            {/* +++Working Heres++++ */}
            <ChargeService show={showChargeModal} onClose={newChargeModalClose} getChargesSummery={getChargesSummery} />
            <ChargeLiquidate show={showLiquidateModal} onClose={liquidateModalClose} getChargesSummery={getChargesSummery} />

            {/* Change bank account modal start from here */}
            <Modal show={showBoletoPixModal} onHide={changeBoletoPixClose}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {/* <img src={paymentinfo.boletoUrl} alt="Payment info" className="cursor-pe img-fluid d-block mx-auto mb-3" style={{ 'width': '350px' }} />
                    {paymentinfo.boleto} */}
                    <h5>{ BoletoPixAarinError }</h5>
                </Modal.Body>
                <Modal.Footer>
                    {
                        userJson && userJson.length > 0 && (userJson.indexOf(3031) !== -1) &&
                        <>
                            {/* <Button variant="link" className="me-2 me-lg-3 p-0 cancelAarinPayment" onClick={moreoption}>{t("button.more")}</Button> */}

                            {/* <Button variant="link" className="me-2 me-lg-3 p-0 cancelAarinPayment" onClick={cancelAarinPayment}>{t("CancelBoleto")}</Button> */}

                            {/*
                            <Button className="Markaspaid" variant="outline-primary PaymentMarkPaid" onClick={PaymentMarkPaid}>{t("MarkAsPaid")}</Button>
                            */ }
                        </>
                    }
                    { /* 
                    <Button variant="primary" className='CheckPaymentStatus' onClick={checkAarinPaymentStatus}>{t("CheckPaymentStatus")}</Button>
                    */ }
                </Modal.Footer>
            </Modal>
            {/* /Change bank account modal end */}

            <Modal show={datepickershow} onHide={filterClose}>
                <Form onSubmit={handleSubmit(dateSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("TransferPage.month")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="g-3 g-xl-4">
                            <Col md>
                                <FloatingLabel label={t("TransferPage.startDate")}>
                                    <Form.Control type="date" aria-label="From"  {...register("startDate")}></Form.Control>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel label={t("TransferPage.endDate")}>
                                    <Form.Control type="date" aria-label="to"  {...register("endDate")}></Form.Control>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={filterClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type='submit'>
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* CANCEL AND REGENERATE BOLETO */}
            <Modal show={canRegBoletoModal} onHide={canRegBoleto} className='receivableChhargeslsitntsx'>
                <Form onSubmit={handleSubmit(cancelRegFrmSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("CancelRegenrateBoleto")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="g-3 g-xl-4">
                            <Col md>
                                <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-3">
                                    <Form.Check
                                        type="radio"
                                        {...register("lease_period")}
                                        value="CancelBoleto"
                                        onChange={boletoAction}
                                        label={t("CancelBoleto")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="g-3 g-xl-4">
                            <Col md>
                                <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-3">
                                    <Form.Check
                                        type="radio"
                                        {...register("lease_period")}
                                        value="CancelRegenrateBoleto"
                                        onChange={boletoAction}
                                        label={t("CancelRegenrate")}
                                    />
                                </Form.Group>                                
                            </Col>
                        </Row>
                        {(canRegBoletoModal && regenratedate) &&
                            <>
                            <Row className="g-3 g-xl-4" >
                                <Col className="mb-lg-4 mb-3">
                                    <FloatingLabel label={t("maturity")}>
                                        <Form.Control type="date" aria-label="From"  {...register("startDate")}></Form.Control>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <p className="error">
                                { maurityError ? maurityError : "" }
                            </p>
                            </>
                        }
                        <Row className="g-3 g-xl-4">
                            {/*<Col md>
                                <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-3">
                                    <Form.Check
                                        type="radio"
                                        {...register("lease_period")}
                                        value="CloneBoleto"
                                        onChange={boletoAction}
                                        label={t("chargeClone")}
                                    />
                                </Form.Group>
                            </Col>*/}
                            <p className="error">
                                { leaseError ? leaseError : "" }
                            </p>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={canRegBoleto}>
                            {t("button.close")}
                        </Button>
                        {loader_on ?
                            <Button className="btn-width">
                                <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                            </Button>
                            :
                            <Button variant="primary" type='submit'>
                                {t("button.save")}
                            </Button>}
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
export default ReceivableChargesListing