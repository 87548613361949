import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup, Offcanvas } from 'react-bootstrap';
import { renewInsuranceOpen, insuranceClose, contractOpen, insertInsurance, getInsurance } from "../../../../redux/slice/contractDataSlice";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { insuranceForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';

const InsuranceService = (insuranceForm) => {
	const { newInsuranceShow, insuranceTp, insuranceObj } = useSelector((state: RootState) => state.contractData)
	const dispatch = useDispatch<AppDispatch>();
	const [double, setDouble] = useState(false);
	const [t] = useTranslation();
	const newInsuranceClose = () => {
		dispatch(insuranceClose(false))
		if (insuranceTp === "reNewInsurance") {
			dispatch(renewInsuranceOpen(true));
		}
		if (insuranceTp === "contractInsurance") {
			dispatch(contractOpen(true));
		}
		setDouble(false);
		reset();
	}
	useEffect(() => {
		if( insuranceObj ){
			setValue('name', insuranceObj.name);
			setValue('email', insuranceObj.email);
			setValue('responsible', insuranceObj.responsible);
			setValue('telephone', insuranceObj.telephone);
			setValue('observation', insuranceObj.observation);
		}
	}, [newInsuranceShow, insuranceObj])

	const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
	const insuranceValidation = Yup.object().shape({
		name: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired')),
		responsible: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired')),
		telephone: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired'))
			.matches(/\(\d{2}\) \d{4}-\d{4}/, `${t("requiredMmessage.TelephoneError")}`),
		email: Yup.string().trim().nullable()
			.required(t('requiredMmessage.fieldRequired'))
			.email(t('requiredMmessage.fieldNotValid')),
	});
	let frm = {
		resolver: yupResolver(insuranceValidation)
	}
	const { register, reset, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<insuranceForm>(frm);
	const onSubmit = async (data: insuranceForm, e: any) => {
		e.preventDefault();
		setDouble(true);
		data['rowId'] = '';
		data["formIs"] = "internal";
		if( insuranceObj && insuranceObj.id != '' && insuranceObj.name != '' ){
			data['rowId'] = insuranceObj.id
		}
		let response = await dispatch(insertInsurance(data));
		if (typeof response.payload !== "undefined" && response.payload.resCode == 202 || response.payload.resCode == 201) {
			setDouble(false);
			Swal.fire({
				// icon: 'success','success',
				title: response.payload.data ? response.payload.data : t("Sweetalert.Success"),
				text: t("Sweetalert.successget"),
				confirmButtonText: "OK",
				timer: 2000
			})
			newInsuranceClose();
			dispatch(getInsurance({ "insurance": "", "start": 0, "end": 1000 }));
		}
		else {
			setDouble(false)
			Swal.fire({
				title:t("Sweetalert.Error"),
				text:t("Sweetalert.somethingWent"),
				// icon:"error",
				confirmButtonText:"OK",
				timer:2000})
		}
	}
	const HandleInput = (e: any) => {
		// Input processing and formatting
		let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
		let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
		// Error validation
		if (newValue == "" || newValue == null || newValue == undefined) {
			// Set an error message using setError or update your error state
			setError("telephone", {
				type: "manual",
				message: `${t("requiredMmessage.TelephoneError")}`
			});
		} else {
			// Clear the telephone error if the value is not empty
			clearErrors('telephone');
		}
	}
	
	return (
		<>
			{/* <!-- New branch modal start from here --> */}
			<Offcanvas show={newInsuranceShow} onHide={newInsuranceClose} placement="end">
			<Form className="d-flex flex-column h-100" onSubmit={handleSubmit(onSubmit)}>
                <Offcanvas.Header className="border-bottom" closeButton>
                { ( insuranceObj && insuranceObj.name ) ? t("insurelist.editinsurers") : t("insurelist.newinsurers") }
                </Offcanvas.Header>
                <Offcanvas.Body>
						<Row className="g-lg-4 g-3">
							<Col md={12}>
								<FloatingLabel label={t("formLabel.name")+ " *"}>
									<Form.Control id="rent" type="text" placeholder={t("formLabel.name") + " *"} className={`form-control ${errors.name ? 'is-invalid' : ''}`}  {...register('name')} />
									<Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
								</FloatingLabel>
							</Col>
							<Col md={12}>
								<FloatingLabel label={t("formLabel.responsible")+ " *"}>
									<Form.Control id="responsible" type="text" placeholder={t("formLabel.responsible")} className={`form-control ${errors.responsible ? 'is-invalid' : ''}`}  {...register('responsible')} />
									<Form.Control.Feedback type="invalid">{errors.responsible?.message}</Form.Control.Feedback>
								</FloatingLabel>
							</Col>
							<Col md={6}>
								<FloatingLabel label={t("formLabel.telephone")+ " *"}>
									<Form.Control id="telephone" type="text" placeholder={t("formLabel.telephone")+ " *"} className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}  {...register('telephone')} onChange={HandleInput} />
									<Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
								</FloatingLabel>
							</Col>
							<Col md={6}>
								<FloatingLabel label={t("formLabel.email") + " *"}>
									<Form.Control id="email" type="text" placeholder={t("formLabel.email")+ " *"} className={`form-control ${errors.email ? 'is-invalid' : ''}`}  {...register('email')} />
									<Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
								</FloatingLabel>
							</Col>
						<Col md={12}>
						<FloatingLabel label={t("formLabel.observation")} >
							<Form.Control as="textarea" id="observation" placeholder={t("formLabel.observation")} {...register('observation')} style={{ height: '100px' }} />
						</FloatingLabel>
						</Col>
						</Row>
				</Offcanvas.Body>
				<div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
				<Button variant="outline-primary" onClick={newInsuranceClose}>
							{t('button.cancel')}
						</Button>
						<Button disabled={double} type="submit" variant="primary" >
							{t('newTransfer.saveChanges')}
						</Button>
				</div>
				</Form>
			</Offcanvas>
			{/* <!-- /New branch modal end --> */}
		</>
	)
}
export default InsuranceService;