import { Form, Modal, Badge, Button, Row, Col, InputGroup, Table, Accordion, FloatingLabel, Card, Dropdown, Collapse } from "react-bootstrap";
import { getContractCount, setContractLabel, contractLabelOpen, setContractStNumber, setCheckedId, setContractStTitle, contractStatusOpen, contractOpen, contractsData, getContractTenants, getInsurance } from "../../../redux/slice/contractDataSlice";
import React, { useState, MouseEvent, useEffect, useMemo } from "react";
import { getAllIndexs, getAllChecklists, getAllAccounts, questionContractOpen, fetch_allFinancialIndexing } from "../../../redux/slice/expenditureDataSlice";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ComProps, contractSearchForm } from "../../Interface";
import ContractQuestionService from "../Contracts/Common/ContractQuestionService";
import ContractStatusService from "../Contracts/Common/ContractStatusService";

import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { getManager } from "../../../redux/slice/propertyDataSlice";
import { getBraches } from "../../../redux/slice/branchSlice";
import Loader from "../../Profile/Company/Loader";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import Checkbox from "../Contracts/Common/Checkbox";
import ContractSettings from "./Contracts/ContractSettings";
import "../../../App.css";
import Pagination from "../../Pagination";
import { PrintPDF, converter, encrypt_Decrypt, encrypt_Decrypt_Plan, formatDateToDDMMYYYY } from "../../constants/utils";
import { MultiSelect } from 'react-multi-select-component';
import customerService from "../../../services/customerService";
import ChangeFinancialStatus from './Contracts/ContractDetail/ChangeFinancialStatus';
import moment from "moment-timezone";
import { ClllThis } from "./Readjustment/ReadjustmentIndexes";
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

const Contracts = (contractSearchForm) => {
  const { contractCount, newContractShow, contractData, contTenantsData, insuranceData, adminRate, status, Contractstatus } = useSelector((state: RootState) => state.contractData);
  const navigate = useNavigate();
  const location = useLocation();
  const [userJson, setUserJson] = useState<any>([]);
  const [readName, setReadName] = useState<any>([]);

  useEffect(() => {
    let userResult = encrypt_Decrypt();
    if (!userResult || userResult.indexOf(2030) === -1) {
      navigate("/dashboard");
    }
    setUserJson(userResult);
    (async () => {
      let dataOutPut = await dispatch(fetch_allFinancialIndexing({}));
      if (dataOutPut["payload"]) {
        let result = await ClllThis(dataOutPut["payload"]["data"]);
        setReadName(result);
      }
    })()
  }, [])


  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [double, setDouble] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any[]>([]);
  const [totalAdminRate, setTotalAdminRate] = useState<any>(0);
  const [listing, setListing] = useState<any>(0);
  const { branchList } = useSelector((state: RootState) => state.branch);
  const { managerData } = useSelector((state: RootState) => state.propertyData);
  const [moreFilter, setMoreFilter] = useState<Boolean>(false)
  const { indexData, indexStatus, checklistData, accountData, accountStatus } =
    useSelector((state: RootState) => state.expenditureData);
  const [branchState, setBranchState] = useState({
    start: "0",
    end: "100",
  });
  const [state, setState] = useState({
    contract: "",
    status: "",
    branch: "",
    landlord: "",
    tenants: "",
    bankAccount: "",
    paymentSplit: "",
    manager: "",
    start: 0,
    end: 20,
  });
  const [propState, setPropState] = useState({
    property: "",
    status: "",
    branch: "",
    city: "",
    tenants: "",
    neighborhood: "",
    administrator: "",
    manager: "",
    owner_type: "",
    condominium: "",
    business_type: "",
    property_type: "",
    real_estate: "",
    start: 0,
    end: 20,
  });
  useEffect(() => {
    dispatch(contractOpen(false));
    dispatch(getBraches(branchState));
    dispatch(getManager());
    dispatch(getContractTenants());
    dispatch(getContractCount());
    dispatch(getInsurance({ insurance: "", start: 0, end: 1000 }));
    dispatch(getAllChecklists({ start: 0, end: 1000 }));
    // getContractsData(state);
    dispatch(getAllAccounts({ start: 0, end: 1000 }));
    dispatch(getAllIndexs({ status: "", start: 0, end: 10 }));
  }, []);

  const newContractOpen = () => {
    //dispatch(contractOpen(true));
    navigate('/contract');
  };


  const [branchOPtion, setbranchOPtion] = useState<any>([]);
  const [tenentOption, setTenentOption] = useState<any>([]);
  const [bankAccountOption, setBankAccountOption] = useState<any>([])
  const [mananerOption, setManageroption] = useState<any>([])
  const [listPropertydata, setListPropertydata] = useState<any>([]);

  const [listBranchdata, setListBranchdata] = useState<any>([]);
  const [listTenentdata, setListTenentdatadata] = useState<any>([]);
  const [listBankAccountdata, setListBankAccountdata] = useState<any>([]);
  const [listManagerdata, setlistManagerdata] = useState<any>([]);

  let typingTimer;
  const searchTextContract = (evt: any, name_string: any) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      const name = name_string;
      const value = evt.target.value;
      setState({
        ...state,
        [name]: value,
      });
    }, 500);
  }

  const searchContract = (evt: any, name_string: any) => {
    let searchId: string[] = [];
    if (evt.length > 0) {
      evt?.forEach((item, index) => {
        searchId.push(item.value)
      })
    }
    if (name_string == "branch") {
      setListBranchdata(evt)
    }
    if (name_string == "tenants") {
      setListTenentdatadata(evt)
    }
    if (name_string == "bankAccount") {
      setListBankAccountdata(evt)
    }
    if (name_string == "manager") {
      setlistManagerdata(evt)
    }
    const name = name_string;
    const value = searchId;
    setState({
      ...state,
      [name]: value,
    });
    let Close: any = document.querySelector('.dropdown-content');
    if (Close) {
      Close.setAttribute("hidden", "");
      Close.click();
    }
  };

  const changeStatus = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    setState({
      ...state,
      [name]: value,
    });
  }

  const getContractListing = (val) => {
    setState({
      ...state,
      ["status"]: val,
    });
  }

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(contractData.data && contractData.data.length > 0 && contractData.data.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  const noneHandleSelectAll = (e) => {
    setIsCheckAll(false);
    setIsCheck([]);
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, parseInt(id)]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
    }
    if (document.querySelectorAll(".check_Checkbox input").length > 0) {
      let data = document.querySelectorAll(".check_Checkbox input");
      let count_allCheck = 0;
      setTimeout(() => {
        data?.forEach((item, index) => {
          if (item["checked"]) {
            count_allCheck++;
          }
          if (!item["checked"]) {
            setIsCheckAll(false);
          }
        });
        if (count_allCheck == data.length) {
          setIsCheckAll(true);
        }
      }, 10);
    }
  };
  const [show, setShow] = useState(false);
  const filterShow = () => {
    setShow(true);
    setFiltersOpen(false);
  }
  const [propertyDataLength, setPropertyLength] = useState(0);
  const filterClose = () => {
    setShow(false);
    setDouble(false);
    reset();
  };
  const filterClear = () => {
    setShow(false)
    reset();
    setState({
      contract: "",
      status: "",
      branch: "",
      landlord: "",
      tenants: "",
      bankAccount: "",
      paymentSplit: "",
      manager: "",
      start: 0,
      end: 20,
    });
    setListBranchdata([])
    setListTenentdatadata([]);
    setListBankAccountdata([]);
    setlistManagerdata([]);
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<contractSearchForm>();

  const filterSubmit = async (data: contractSearchForm) => {
    data.start = 0;
    data.end = 20;
    setDouble(true);
    setShow(false);

    let response = await dispatch(contractsData(data));
    if (response.payload.data.length && response.payload.resCode == 201) {
      setMoreFilter(true)
      setDouble(false);
      // Swal.fire({
      //   title: t("Sweetalert.Success"),
      //   text: t("Sweetalert.getrecord"),
      //   // icon: 'success',"success",
      //   confirmButtonText: "OK",
      //   timer: 2000,
      // });
      filterClose();
    } else {
      setDouble(false);
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.somethingWent"),
        // icon: 'success',"error",
        confirmButtonText: "OK",
        timer: 2000,
      });
    }
  };

  const contractLbOpen = (evt) => {
    if (isCheck.length > 0) {
      dispatch(setCheckedId(isCheck));
      dispatch(setContractLabel(evt));
      dispatch(contractLabelOpen(true));
    } else {
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.Pleasecheckedthe"),
        // icon: 'success',"error",
        confirmButtonText: "OK",
        timer: 2000,
      });
    }
  };

  const contractMirror = async (evt) => {
    evt.preventDefault();
    if (isCheck.length > 0) {
      Swal.fire({
        title: t("Sweetalert.Areyousure"),
        text: "You would be able to revert this!",
        // icon: 'success','warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, mirror it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await customerService.contractsMirrorData({ id: isCheck });
          if (response.data && response.data.resCode == 201) {
            let url = response.data.data[0].fileUrl;
            window.open(url, "_blank")
          }
          else {
            Swal.fire({
              title: t("Sweetalert.Error"),
              text: response.data.message,
              // icon: 'success',"error",
              confirmButtonText: "OK",
              timer: 2000,
            });
          }
        }
      })
    } else {
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.Pleasecheckedthe"),
        // icon: 'success',"error",
        confirmButtonText: "OK",
        showCloseButton: true,
        timer: 2000,

      });
    }
  }

  const contractStOpen = async (evt, num) => {
    if (isCheck.length > 0) {
      if (num > 0) {
        /*--------Harjinder----------*/
        dispatch(setCheckedId(isCheck));
        dispatch(contractStatusOpen(true));
        dispatch(setContractStNumber(num));
        ///setFinancialStatusModal(true);
      }
    } else {
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.Pleasecheckedthe"),
        // icon: 'success',"error",
        confirmButtonText: "OK",
        timer: 2000,
      });
    }
  };

  /*-------Harjinder end----------*/
  //Change financial status modal initialization
  const [showFinancialStatusModal, setFinancialStatusModal] = useState(false);
  const financialStatusModalClose = () => {
    setFinancialStatusModal(false);
  }

  // Contract setting modal initialization
  const [showContractSettingModal, setContractSettingModal] = useState(false);
  const contractSettingModalClose = () => setContractSettingModal(false);
  const contractSettingModalShow = () => setContractSettingModal(true);
  const contractQuestionOpend = () => {
    dispatch(questionContractOpen(true));
  }
  /*----------Property common modal form---------*/
  // Pagination start
  let PageSize = state.end;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpage, setTotalPage] = useState(0);
  useEffect(() => {
    if (contractData?.["totalRow"] !== undefined) {
      let count = Number(contractData?.["totalRow"]);
      if (location.state && location.state.contract_id && location.state.contract_id !== "") {
        count = Number(2);
      } else {
        count = Number(contractData?.["totalRow"]);
      }
      setTotalPage(count)
    }
  }, [contractData, currentPage])

  useEffect(() => {
    state["start"] = (currentPage - 1) * state["end"];
    let checkPendencies = document.querySelector('body.pendencies');
    if (checkPendencies) {
      // Get the current date
      const currentDate = moment();
      // Set the date to the first day of the current month
      const startOfMonth = moment(currentDate).startOf('month');
      // Format the result as "YYYY-MM-DD"
      const startDt = startOfMonth.format('YYYY-MM-DD');
      // Set the date to the last day of the current month
      const endOfMonth = moment(currentDate).endOf('month');
      // Format the result as "YYYY-MM-DD"
      const endDt = endOfMonth.format('YYYY-MM-DD');
      state["dueDate"] = startDt;
      state["expireDate"] = endDt;
      getContractsData(state);
    } else {
      getContractsData(state);
    };
  }, [currentPage, state])

  useEffect(() => {
    if (contractData?.["data"]) {
      const keyToConvert = "admin_rate"; // Specify the key to convert to a number
      let total = 0;

      for (const item of contractData?.["data"]) {
        const value = parseFloat(item[keyToConvert]);
        if (!isNaN(value)) {
          total += value;
        }
      }
      // Format the total to have two decimal places
      const formattedTotal = total.toFixed(2);
      setTotalAdminRate(formattedTotal);
    }
  }, [contractData])

  const getContractsData = async (contData) => {
    let response = await dispatch(contractsData(contData));
  };

  const terminatedData = (cmd: string) => {
    setState(prevState => ({
      ...prevState,
      status: cmd,
      start: 0,
      end: 1000
    }));

  };
  const ExpireContract = () => {
    contractData.data?.forEach(element => {
    });
    const currentDate = new Date(dateBrazil);
    let currentmoths = currentDate.toDateString()
  }
  useEffect(() => {
    let all1branchOption: any = [];
    let allcontTenantsData: any = [];
    let allBankAccount: any = [];
    let allMangerData: any = [];
    if (branchList?.length !== 0) {
      branchList &&
        branchList?.forEach((item, index) => {
          all1branchOption.push(
            { value: item.id, label: `${item.name ? item.name : ''}`, name: "branch" }
          )
        })
    }
    setbranchOPtion(all1branchOption);
    contTenantsData &&
      contTenantsData?.forEach((item, index) => {
        allcontTenantsData.push(
          { value: item.id, label: `${item.name ? item.name : ''}`, name: "tenants" }
        )
      })
    setTenentOption(allcontTenantsData);

    accountData.data &&
      accountData.data?.forEach((item, index) => {
        allBankAccount.push(
          { value: item.id, label: `${item.accountRef ? item.accountRef : ''}`, name: "bankAccount" }
        )

      })
    setBankAccountOption(allBankAccount);
    managerData &&
      managerData?.forEach((item, index) => {
        allMangerData.push(
          { value: item.id, label: `${item.name ? item.name : ''}`, name: "manager" }
        )
      })
    setManageroption(allMangerData);
  }, [branchList, contTenantsData, accountData, managerData])
  // Filters section
  const [openFilters, setFiltersOpen] = useState(false);
  let proprtyDataType = [
    { id: 1, title: t('residential') },
    { id: 2, title: t('nonResidential') },
    { id: 3, title: t('commercial') },
    { id: 4, title: t('industry') },
    { id: 5, title: t('season') },
    { id: 6, title: t('byOrder') },
    { id: 7, title: t('mixed') }
  ]
  const [changeProprtyType, setChangeProprtyType] = useState<any>(proprtyDataType)

  useEffect(() => {
    let changeLanguage = i18n.language;
    let typeofProperty: any = []
    if (changeLanguage == "pt") {
      contractData?.data?.forEach(element => {
        proprtyDataType?.forEach((propery: any) => {
          if (element.type == propery.id) {
            typeofProperty.push(propery)
          }
        })
        setChangeProprtyType(typeofProperty)
      });
    } else {
      contractData?.data?.forEach(element => {
        proprtyDataType?.forEach((propery: any) => {
          if (element.type == propery.id) {
            typeofProperty.push(propery)
          }
        })
        setChangeProprtyType(typeofProperty)
      });
    }
  }, [i18n.language])

  const [limitSetContract, setLimitsetContract] = useState(true);
  const [userPlanString, setUserPlanString] = useState("");

  useEffect(() => {
    let userPLAN = encrypt_Decrypt_Plan();
    // setUserPlanString(userPLAN)
    setUserPlanString("300")
  }, [location])

  useEffect(() => {
    if (Number(contractData.totalRow) >= Number(userPlanString)) {
      setLimitsetContract(false);
    } else {
      setLimitsetContract(true);
    }
  }, [contractData.totalRow, userPlanString, location])

  return (
    <section className="content-wrapper content-wrapper-primary" id="addNewContracttTemplate">
      {/* <!-- Breadcrumb section start from here -->*/}
      <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
        <div className="w-100 mb-xl-4">
          <Row className="gx-xl-5 justify-content-between align-items-center">
            <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
              <div className="d-flex align-items-center justify-content-between">
                <h1 className="mb-0 lh-base text-truncate me-xl-1">
                  {t("Links.contracts")}
                </h1>
                {/* Filter button visible in mobile devices */}
                <div className="d-xl-none">
                  <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                    {t("dropdownItem.filters")}
                    <img
                      src="assets/images/icons/filter-icon.svg"
                      alt="Filter"
                      className="ms-1 h-12"
                    />
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
              {/* Search bar */}
              <InputGroup>
                <Form.Control
                  {...register("contract")}
                  onChange={(e) => searchTextContract(e, "contract")}
                  onKeyUp={(e) => searchTextContract(e, "contract")}
                  placeholder={t("formLabel.typeSearch")}
                  aria-label="Search"
                  aria-describedby="search_icon"
                />
                <InputGroup.Text id="search_icon">
                  <img
                    src="./assets/images/icons/search.svg"
                    alt="Search icon"
                  />
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
              <ul className="list-inline mb-0">

                {
                  userJson && userJson.length > 0 && (userJson.indexOf(2031) !== -1) && limitSetContract &&
                  <li className="list-inline-item  me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                    <a
                      onClick={newContractOpen}
                      title="Add"
                      className="fw-bold cursor-pe"
                    >
                      <img
                        src="assets/images/icons/plus-light.svg"
                        alt="Plus icon"
                        className="me-1 h-20"
                      />
                      {t("newContract")}
                    </a>
                  </li>
                }
                {
                  userJson && userJson.length > 0 && (userJson.indexOf(3041) !== -1) &&
                  <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                    <Link to={"/expenditure"} className="fw-bold">
                      <img
                        src="assets/images/icons/wallet-light.svg"
                        alt="Wallet"
                        className="me-1 h-20"
                      />
                      {t("expenses")}
                    </Link>
                  </li>
                }
                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                  <Link to={"/pending_checklists"} className="fw-bold">
                    <img
                      src="assets/images/icons/listing-light.svg"
                      alt="Listing"
                      className="me-1 h-20"
                    />{" "}
                    {t("pChecklists")}
                  </Link>
                </li>
                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                  <Link to={"/insurers"} className="fw-bold">
                    <img
                      src="assets/images/icons/user-listing.svg"
                      alt="Insurers"
                      className="me-1 h-20"
                    />{" "}
                    {t("insurers")}
                  </Link>
                </li>
                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                  <img
                    src="assets/images/icons/print-light.svg"
                    alt="Print"
                    className="h-20 cursor-pe"
                    onClick={PrintPDF}
                  />
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        <Collapse className="d-xl-block collpase-filters" in={openFilters}>
          <Form id="collpase-filter-text" action="" method="">
            <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
              <h5 className="mb-0 fw-bold">Filters</h5>
              <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
              </span>
            </div>
            <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
              <Col xl="auto">
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle variant="light" size="sm" className={`${state?.["status"] ? "show" : ""}`}>
                    {/* {t("dropdownItem.status")} */}
                    {state?.status ? t(`${state?.status}`) : t("dropdownItem.status")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="px-4">
                    <FloatingLabel label={t("dropdownItem.status")}>
                      <Form.Select
                        {...register("status")}
                        onChange={changeStatus}
                        aria-label="Status"
                      >
                        <option value="All" >{t("All")}..</option>
                        <option value="active">{t("active")}</option>
                        <option value="terminated">{t("terminated")}</option>
                        <option value="pending">{t("pending")}</option>
                        <option value="paused">{t("paused")}</option>
                        <option value="finished">{t("expired")}</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xl="auto">
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle variant="light" size="sm" className={`${listBranchdata?.length ? "show" : ""}`}>
                    {t("Branch")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdwon-lg px-4">
                    <MultiSelect
                      options={branchOPtion}
                      value={listBranchdata}
                      onChange={(e) => searchContract(e, "branch")}
                      labelledBy={t("formLabel.select")}
                      overrideStrings={{
                        selectSomeItems: `${t("formLabel.select")}`,
                        selectAll: `${t("selectAll")}`,
                        search: `${t("formLabel.typeSearch")}`,
                        allItemsAreSelected: `${t("selectAll")}`,
                      }} // <- to override strings
                    />
                  </Dropdown.Menu>
                </Dropdown>

              </Col>
              <Col xl="auto">
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle variant="light" size="sm" className={`${listTenentdata?.length ? "show" : ""}`}>
                    {t("tenant")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdwon-lg px-4">
                    <MultiSelect
                      options={tenentOption}
                      value={listTenentdata}
                      onChange={(e) => searchContract(e, "tenants")}
                      labelledBy={t("formLabel.select")}
                      overrideStrings={{
                        selectSomeItems: `${t("formLabel.select")}`,
                        selectAll: `${t("selectAll")}`,
                        search: `${t("formLabel.typeSearch")}`,
                        allItemsAreSelected: `${t("selectAll")}`,
                      }} // <- to override strings
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xl="auto">
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle variant="light" size="sm" className={`${listBankAccountdata?.length ? "show" : ""}`}>
                    {t("bankAccount")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdwon-lg px-4">
                    <MultiSelect
                      options={bankAccountOption}
                      value={listBankAccountdata}
                      onChange={(e) => searchContract(e, "bankAccount")}
                      labelledBy={t("formLabel.select")}
                      overrideStrings={{
                        selectSomeItems: `${t("formLabel.select")}`,
                        selectAll: `${t("selectAll")}`,
                        search: `${t("formLabel.typeSearch")}`,
                        allItemsAreSelected: `${t("selectAll")}`,
                      }} // <- to override strings
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xl="auto">
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle variant="light" size="sm" className={`${listManagerdata?.length ? "show" : ""}`}>
                    {t("manager")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdwon-lg px-4" >
                    <MultiSelect
                      options={mananerOption}
                      value={listManagerdata}
                      onChange={(e) => searchContract(e, "manager")}
                      labelledBy={t("formLabel.select")}
                      overrideStrings={{
                        selectSomeItems: `${t("formLabel.select")}`,
                        selectAll: `${t("selectAll")}`,
                        search: `${t("formLabel.typeSearch")}`,
                        allItemsAreSelected: `${t("selectAll")}`,
                      }} // <- to override strings
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xl="auto">
                <Dropdown autoClose="outside" className="moreFilter">
                  <Dropdown.Toggle variant="light" size="sm" className={`d-flex align-items-center ${moreFilter ? "show" : ""}`} onClick={filterShow}>
                    {t("moreFilters")}
                    <img
                      src={`assets/images/icons/${moreFilter ? "icons8-plus-24.png" : "plus-light.svg"}`}
                      alt="Plus icon"
                      className="ms-xl-1 ms-auto h-20"
                    />
                  </Dropdown.Toggle>
                </Dropdown>
              </Col>
            </Row>

          </Form>
        </Collapse>
      </div>
      {/* <!-- /Breadcrumb section end -->*/}
      {/* <!-- Main content start from here -->*/}
      <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">

        {/* <!-- overview -->*/}
        <Row className="gy-3 gy-lg-4">
          <Col xl={4}>
            <Card>
              <Card.Header>{t("overview")}</Card.Header>
              <Card.Body>
                <Row className="gy-3 gx-xxl-5 gx-md-4">
                  <Col
                    sm={6}
                    className="border-end border-light border-xs-none"
                  >
                    <div className="d-flex flex-row align-items-center justify-content-start mb-2">
                      <img
                        src="assets/images/icons/refresh-light.svg"
                        alt="progress"
                        className="flex-shrink-0 me-1 h-20"
                      />
                      <span className="d-block text-dark h3 mb-0 lh-1 cursor-pe">
                        {
                          contractCount && contractCount.length > 0 ?
                            <div onClick={() => getContractListing("active")}> {contractCount[0]?.inProgress}</div>
                            :
                            0
                        }
                      </span>
                    </div>
                    <span className="text-secondary d-block">
                      {
                        contractCount[0]?.inProgress == 1 ? t("active") : t("newContractDev.Ativos")
                      }
                    </span>
                  </Col>
                  <Col sm={6}>
                    <div className="d-flex flex-row align-items-center justify-content-start mb-2">
                      <img
                        src="assets/images/icons/clock-light.svg"
                        alt="Pending"
                        className="flex-shrink-0 me-1 h-20"
                      />
                      <span className="d-block text-dark h3 mb-0 lh-1 cursor-pe">
                        {
                          contractCount && contractCount.length > 0 ?
                            <div onClick={() => getContractListing("pending")}> {contractCount[0]?.pending}</div>
                            :
                            0
                        }
                      </span>
                    </div>
                    <span className="text-secondary d-block">
                      {contractCount[0]?.pending == 1 ? t("pending") : t("Pendentes")}
                    </span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={8}>
            <Card>
              <Card.Header>{t("summaryMonth")}</Card.Header>
              <Card.Body>
                <Row className="gy-3 gx-xxl-5 gx-md-4">
                  <Col
                    sm={3}
                    className="border-end border-light border-xs-none"
                  >
                    <div className="d-flex flex-row align-items-center justify-content-start mb-2" >
                      <img
                        src="assets/images/icons/UP.svg"
                        alt="progress"
                        className="flex-shrink-0 me-1 h-20"
                      />
                      <span className="d-block text-dark h3 mb-0 lh-1 cursor-pe">
                        {
                          contractCount && contractCount.length > 0 ?
                            <div onClick={() => getContractListing("conquered")}> {contractCount[0]?.conquered}</div>
                            :
                            0
                        }
                      </span>
                    </div>
                    <span className="text-secondary d-block">
                      {contractCount[0]?.conquered == 1 ? t("conquered") : t("conquered") + "s"}
                    </span>
                  </Col>
                  <Col
                    sm={3}
                    className="border-end border-light border-xs-none"
                  >
                    <div className="d-flex flex-row align-items-center justify-content-start mb-2">
                      <img
                        src="assets/images/icons/Down.svg"
                        alt="progress"
                        className="flex-shrink-0 me-1 h-20"
                      />
                      <span className="d-block text-dark h3 mb-0 lh-1 cursor-pe" >
                        {
                          contractCount && contractCount.length > 0 ?
                            <div onClick={() => terminatedData("terminated")}> {contractCount[0]?.terminated}</div>
                            :
                            0
                        }
                      </span>
                    </div>
                    <span className="text-secondary d-block">
                      {contractCount[0]?.terminated == 1 ? t("terminated") : t("terminateds")}
                    </span>
                  </Col>
                  <Col
                    sm={3}
                    className="border-end border-light border-xs-none"
                  >
                    <Link to={"/readjustContracts"}>

                      <div className="d-flex flex-row align-items-center justify-content-start mb-2">
                        <img
                          src="assets/images/icons/chart-line.svg"
                          alt="Readjust"
                          className="flex-shrink-0 me-1 h-20"
                        />
                        <span className="d-block text-dark h3 mb-0 lh-1">
                          {
                            contractCount && contractCount.length > 0 ?
                              contractCount[0].toReadjust
                              :
                              0
                          }
                        </span>

                      </div>
                      <span className="text-secondary d-block">
                        {t("toReadjust")}
                      </span>
                    </Link>
                  </Col>

                  <Col sm={3}>
                    <div className="d-flex flex-row align-items-center justify-content-start mb-2">
                      <img
                        src="assets/images/icons/money-solid.svg"
                        alt="rental fee"
                        className="flex-shrink-0 me-1 h-20"
                      />
                      <span className="d-block text-dark h3 mb-0 lh-1">
                        {
                          contractCount && contractCount.length > 0 ?
                            <div onClick={() => getContractListing("rentalFee")}> {contractCount[0].noRentalFee}</div>
                            :
                            0
                        }
                      </span>
                    </div>
                    <span className="text-secondary d-block ">
                      {t("noRentalFee")}
                    </span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <!-- /overview -->*/}
        {/* <!-- List of contracts start from here -->*/}

        {/* <!-- Table start from here -->*/}
        {Contractstatus == "loading" ? (
          <Loader />
        ) : (
          <Card className="mt-lg-4 mt-3" id="listOftsxContracts">
            <Card.Header>{t("listOfContracts")}</Card.Header>
            <Card.Body className="pb-1">
              <Table
                responsive
                className="table table-custom table-custom-bordered mb-0"
              >
                <thead>
                  <tr>
                    <th>
                      {contractData.data && contractData.data.length > 0 ? (
                        <Checkbox
                          type="checkbox"
                          name="selectAll"
                          id="selectAll"
                          handleClick={handleSelectAll}
                          isChecked={isCheckAll}
                          className="w-24"
                        />
                      ) : (
                        ""
                      )}
                    </th>
                    <th>{t("maturity")}</th>
                    <th>{t("contract")}</th>
                    <th>{t("rent")} (R$)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {contractData?.["data"] && contractData?.["data"].length > 0 ? (
                    contractData?.["data"]
                      .filter(item => {
                        if (location.state && location.state.contract_id !== "") {
                          return item.id == location.state.contract_id;
                        } else {
                          return true;
                        }
                      }) // Filter by singleId if provided
                      .map((item, index) => {
                        let todayDate = new Date(dateBrazil);
                        let maturityDate = new Date(
                          `${item?.endDate?.split("T")[0]}`
                        );
                        let Difference_In_Time =
                          maturityDate.getTime() - todayDate.getTime();
                        let Difference_In_Days: any =
                          Difference_In_Time / (1000 * 3600 * 24);
                        let MathSign = Math.sign(Difference_In_Days);
                        let dayAgo = "";
                        let stringValue = '' + item.admin_rate;
                        let stringValue1 = '' + item.rent_amount
                        let typeName = changeProprtyType?.filter(items => Number(items.id) == Number(item.type))
                        if (MathSign == -1) {
                          dayAgo = String(parseInt(Difference_In_Days)).replace(
                            "-",
                            ""
                          );
                          if (Number(dayAgo) > 30) {
                            if (Math.floor(Number(dayAgo) / 30) >= 12) {
                              let YearAgo = `${String(
                                Math.floor(Number(dayAgo) / 30 / 12)
                              )}`;
                              if (YearAgo === "1") {
                                dayAgo = `${YearAgo} year`;
                              } else {
                                dayAgo = `${YearAgo} years`;
                              }
                            } else {
                              let monthsAgo = `${String(
                                Math.floor(Number(dayAgo) / 30)
                              )}`;
                              if (monthsAgo === "1") {
                                dayAgo = `${monthsAgo} month`;
                              } else {
                                dayAgo = `${monthsAgo} months`;
                              }
                            }
                          } else {
                            if (dayAgo == "0") {
                              dayAgo = "Today";
                            } else if (dayAgo == "1") {
                              dayAgo = "Yesterday";
                            } else {
                              dayAgo = `${dayAgo} days`;
                            }
                          }


                        }
                        return (
                          <tr key={index}>
                            <td className={"check_Checkbox"}>
                              <Checkbox
                                key={index}
                                type="checkbox"
                                name={item.id}
                                id={item.id}
                                handleClick={handleClick}
                                isChecked={isCheck.includes(item.id)}
                              />
                            </td>
                            <td className="text-dark-70">
                              <div className="d-block">{item?.endDate}</div>
                              {dayAgo !== "" ? (
                                <>
                                  <Badge bg="danger-30" className="me-1">
                                    {" "}
                                    Expired {dayAgo} ago
                                  </Badge>
                                </>
                              ) : (
                                <>
                                  {/* <span className="d-block">{upcomDay} remain</span> */}
                                </>
                              )}
                              {(item.suspended == 1) &&
                                <Badge className="text-dark" bg="light">{t("Suspended")}</Badge>
                              }
                              {(item.FL_ATIVO_CON == 4) &&
                                <Badge className="text-dark" bg="warning-10">{t("paused")}</Badge>
                              }
                              {(item.terminate == 0 || item.FL_ATIVO_CON == 0) &&
                                <Badge className="text-dark" bg="primary-20">{t("terminated")}</Badge>
                              }
                              {
                                item.ownerStatus ?
                                  <Badge bg="success-10" className="text-dark">Agency owned</Badge>
                                  : ''
                              }
                            </td>
                            <td>
                              <span className="text-dark-70">
                                {item.property_identifier ? t("contract") + ' ' + item.property_identifier + "/" + item.lease_count : t("contract") + ' ' + ""}
                              </span>

                              {/* item.property_identifier */}
                              <span className="mx-1">

                                <img src="assets/images/icons/home_g.svg" />
                                <span className="ps-1">
                                  {typeName[0]?.title || ""} {item.address ? item.address + ',' : ''}{" "}
                                  {item.city ? item.city + ',' : ''} {item.state ? item.state + ' -' : ''} {item.zipcode}
                                  {/* item.address */}
                                </span>
                              </span>
                              <span className="d-block my-1" title="Owner">
                                {item.owners && item.owners.length > 0 ? item.owners.map((items, indx) => (
                                  <Link
                                    to={
                                      "/owner_details/" + items.ID_PESSOA_PES
                                    }
                                    className="text-secondary text-nowrap me-2"
                                    key={indx}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/user_g.svg"
                                      }
                                      className="me-1"
                                    />
                                    {items.ST_NOME_PES}
                                  </Link>
                                ))
                                  : ""}
                              </span>
                              <span className="d-flex flex-wrap" title="Tenant">
                                {item.tenates && item.tenates.length > 0
                                  ? item.tenates.map((items, indx) => (
                                    <Link
                                      to={
                                        "/profile_details/" + items.ID_PESSOA_PES
                                      }
                                      className="text-secondary text-nowrap me-2"
                                      key={indx}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/key_g.svg"
                                        }
                                        className="me-1"
                                      />
                                      {items.ST_NOME_PES}
                                    </Link>
                                  ))
                                  : ""}
                              </span>
                            </td>
                            <td>
                              <span className="d-block pb-1 text-dark-70 text-nowrap">
                                R$ {converter(stringValue1)}
                              </span>
                              <span className="d-block text-nowrap">
                                {t("adminFee")} R$ {converter(stringValue)}
                              </span>
                            </td>
                            <td className="text-end">
                              {
                                userJson && userJson.length > 0 && (userJson.indexOf(2030) !== -1) &&
                                <Link
                                  to={"/contract_details/" + item.id}
                                  className="fw-bold me-2 me-xxl-4 me-lg-3 text-nowrap"
                                >
                                  {t("button.seeContract")}
                                </Link>
                              }

                              {
                                userJson && userJson.length > 0 && (userJson.indexOf(2070) !== -1) &&
                                <Link
                                  to={"/property_detail/" + item.propertyId}
                                  className="fw-bold text-nowrap"
                                >
                                  {t("button.seeProperty")}
                                </Link>
                              }
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center">
                        <span>{t("formLabel.noRecordsFounds")}</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        )}

        {/* {

              contractData?.["totalRow"] !== undefined &&
              [...Array(Math.ceil(Number(contractData?.["totalRow"]) / Number(countPage["end"])))].map((ip: any, index) => (
                <h1>{ip} ,</h1>
              ))

            } */}
        {
          contractData.totalRow ?
            <div className="d-flex justify-content-center mt-lg-4 mt-3">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalpage}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            : ''
        }
        {/* <!-- /Table end -->*/}
        <p className="mt-lg-4 mt-3 mb-1">{t("formLabel.moreItems")}</p>
        <p className="mb-3 mb-lg-4">
          {t("formLabel.listing")}{" "}
          {contractData?.["data"] && contractData?.["data"].length} {" "}
          {t("contracts")}. {t("totalAdminFee")} R$ {converter(`${totalAdminRate}`)}
        </p>
        {/* <!-- Nav listing start from here -->*/}
        {
          userJson && userJson.length > 0 && (userJson.indexOf(2031) !== -1) &&
          <ul className="list-inline mb-0">
            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
              <Dropdown>
                <Dropdown.Toggle variant="light" size="sm">
                  {t("formLabel.mark")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleSelectAll} href="">
                    {t("formLabel.all")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={noneHandleSelectAll} href="">
                    {t("formLabel.none")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
              <span className="px-1">{t("formLabel.withTagged")}</span>
            </li>
            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
              <Button
                variant="light"
                size="sm"
                onClick={(e) => contractMirror(e)}
              >
                {t("contractMirror")}
              </Button>
            </li>
            <li className="list-inline-item mb-lg-0 mb-1">
              <Dropdown drop="up">
                <Dropdown.Toggle variant="light" size="sm">
                  {t("most")}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="mh-456 overflow-auto"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <Dropdown.Item
                    onClick={() =>
                      contractStOpen("Change financial status", 1)
                    }
                  >
                    {t("chgFncStatus")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      contractStOpen("Change DOC/TED bank fee", 2)
                    }
                  >
                    {t("chgBankFee")}
                  </Dropdown.Item>
                  {/*<Dropdown.Item
                        onClick={() =>
                          contractStOpen("Change billing and branch bank account", 3)
                        }
                      >
                        {t("chgBankAccount")}
                      </Dropdown.Item>*/}
                  <Dropdown.Item
                    onClick={() =>
                      contractStOpen("Change readjustment index", 4)
                    }
                  >
                    {t("chgRdIndex")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => contractStOpen("Change DIMOB option", 5)}
                  >
                    {t("chgDIMOBOpt")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => contractStOpen("Change split option", 6)}
                  >
                    {t("chgSplitOpt")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => contractStOpen("Change invoice option", 7)}
                  >
                    {t("chgInvoiceOpt")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => contractStOpen("Change billing method", 8)}
                  >
                    {t("chgBillingMethod")}
                  </Dropdown.Item>
                  {/*<Dropdown.Item
                        onClick={() =>
                          contractStOpen("Charge tenant's bank fee", 9)
                        }
                      >
                        {t("chgTenantBankFee")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          contractStOpen("Invite to owner and tenant areas", 10)
                        }
                      >
                        {t("inviteOwnerAreas")}
                      </Dropdown.Item>*/}
                  <Dropdown.Item
                    onClick={() =>
                      contractStOpen(
                        "Change guaranteed transfers of contracts",
                        11
                      )
                    }
                  >
                    {t("changeGtdTsfCnt")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      contractStOpen(
                        "Change to not pass on fines and interest in guaranteed transfer",
                        12
                      )
                    }
                  >
                    Change to not pass on fines and interest in guaranteed transfer
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        }
        {/*  <!-- /Nav listing  end -->*/}

        {/* <!-- /List of contracts end -->*/}
      </main>
      <ContractStatusService />
      {/* <ContractLabelService /> */}
      {/* <!-- /Main content start end --> */}
      {/* More filter modal start from here */}
      <Modal show={show} onHide={filterClose}>
        <Form onSubmit={handleSubmit(filterSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("moreFilters")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FloatingLabel
              className="mb-3 mb-lg-4"
              controlId="floatingSelect"
              label={t("readjustmentIndex")}
            >
              <Form.Select
                {...register("readjustmentIndex")}
                aria-label={t("readjustmentIndex")}
              >
                <option value="All">{t("formLabel.all")}..</option>
                {/* {indexData.data && indexData.data.length > 0 ? (
                  indexData.data.map((item, index) => ( */}
                {
                  readName &&
                    readName.length > 0 ? (
                    readName.map((item: any, index: number) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))
                  ) : (
                    <option value="">{t("formLabel.noRecordsFounds")}</option>
                  )}
              </Form.Select>
            </FloatingLabel>
            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
              <Col md={6}>
                <FloatingLabel
                  controlId="floatingSelect"
                  label={t("incomeTaxStatus")}
                >
                  <Form.Select
                    {...register("incomeTaxStatus")}
                    aria-label={t("incomeTaxStatus")}
                  >
                    <option value="All">{t("formLabel.all")}..</option>
                    <option value="-1">{t("contractsettings.doesTax")}</option>
                    <option value="0">
                      {t("contractsettings.retainsButCollection")}
                    </option>
                    <option value="1">
                      {t("contractsettings.retainAgencyCollects")}
                    </option>
                    <option value="2">{t("contractsettings.retainsTenantCollects")}</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="floatingSelect"
                  label={t("notifications.insurance")}
                >
                  <Form.Select
                    {...register("insurance")}
                    aria-label={t("notifications.insurance")}
                  >
                    <option value="All">{t("formLabel.all")}..</option>
                    {insuranceData.data && insuranceData.data.length > 0 ? (
                      insuranceData.data.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))
                    ) : (
                      <option value="">{t("formLabel.noRecordsFounds")}</option>
                    )}
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
              <Col md={6}>
                <FloatingLabel controlId="floatingInput" label={t("start")}>
                  <Form.Control
                    type="date"
                    placeholder="Start"
                    {...register("dueDate")}
                  />
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="floatingInput"
                  label={t("end")}
                >
                  <Form.Control
                    type="date"
                    placeholder="End"
                    {...register("expireDate")}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <FloatingLabel
              controlId="floatingSelect"
              label={t('contractType')}
            >
              <Form.Select {...register('contractType')} aria-label={t('contractType')}>
                <option value="All">{t("formLabel.all")}..</option>
                <option value="1">{t('residential')}</option>
                <option value="2">{t('nonResidential')}</option>
                <option value="3">{t('commercial')}</option>
                <option value="4">{t('industry')}</option>
                <option value="5">{t('season')}</option>
                <option value="6">{t('byOrder')}</option>
                <option value="7">{t('mixed')}</option>
              </Form.Select>
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={filterClear}>
              {t("button.resetfilter")}
            </Button>
            <Button disabled={double} type="submit" variant="primary">
              {t("button.done")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* /More filter modal end */}

      <ChangeFinancialStatus showFinancialStatus={showFinancialStatusModal} contractids={isCheck} financialStatusClose={financialStatusModalClose} />
      <ContractQuestionService />
    </section>
  );
};

export default Contracts;