import React, { useEffect, useMemo, useState } from 'react';
import { Table, Dropdown, Badge, Card, Toast, ToastContainer } from 'react-bootstrap';
import Contact from './Contract/Contact';
import RecordAgreement from './MoreOptions/RecordAgreement';
import ViewInterest from './MoreOptions/ViewInterest';
import ContractHistroy from './MoreOptions/ContractHistroy';
import { useTranslation } from "react-i18next";
import UndoAgreement from './UndoAgreement';
import AgreementDetails from './AgreementDetails';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../../Profile/Company/Loader';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { NonPaymentFlagProtest, getNonpaymentContactHistory, nonPaymentCharge, nonPaymentExpenseCharge_installment, nonPaymentSetting, nonPayment_get_single_rece_expense, nonPayment_send_charge } from '../../../../redux/slice/NonPaymentSlice';
import moment from "moment-timezone";
import { contractDatails, getAllSuppliers, getContractDetailsCount } from '../../../../redux/slice/contractDataSlice';
import { getAllAccounts, getAllBillMethodListing, getSingleContractCharge } from '../../../../redux/slice/expenditureDataSlice';
import { converter, encrypt_Decrypt_Plan, formatNumberEnglish, formatNumberPortuges } from '../../../constants/utils';
import { fetchService } from '../../../../redux/slice/ServiceSlice';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { config } from '../../../../services/apiConfig';
import { get_forms_of_receipts } from '../../../../redux/slice/formsOfReceiptSlice';
import i18n from '../../../../i18n';
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
const moments = require('moment');
const momentTimezone = require('moment-timezone');

const NonPaymentListing = (props) => {
    let { conditionNonpayment, currentTableData, setconditionNonpayment,
        listData, summery, handleChangeSort, loading, setStateClear,
        state, setLoading } = props;

    const location = useLocation();
    const nagivate = useNavigate();
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    const [singleContact, setSingleContact] = useState([]);
    const [chargeValue, setChargeValue] = useState<any>(0);
    //Contact modal close open function
    const [showContactModal, setContactModal] = useState(false);
    const [recebimento_id, set_recebimento_id] = useState<any>("");
    const [contactHistoryData, setContactHistoryData] = useState<any>([]);
    const [orginalType, setOrginalType] = useState("");
    const { formdata } = useSelector((state: RootState) => state.forms);
    let { nonPaymentSetting_Data, nonPaymentChargeData } = useSelector((state: RootState) => state.nonPayment);
    const { indexedData_based_on_name } = useSelector((state: RootState) => state.expenditureData)

    useEffect(() => {
        dispatch(get_forms_of_receipts());
        dispatch(fetchService());
    }, [])

    const [userPlanString, setUserPlanString] = useState("");
    useEffect(() => {
        let userPLAN = encrypt_Decrypt_Plan();
        //  setUserPlanString(userPLAN)
        setUserPlanString("300")
    }, [location])

    useEffect(() => {
        if (conditionNonpayment["non_payment"] !== "defaulter" && userPlanString === "100") {
            nagivate("/")
        }
    }, [])

    const contactModalClose = () => {
        setContactModal(false);
        setSingleContact([]);
    };

    const contactModalShow = (id: any, item: any) => {
        setContactModal(true);
        let FilteredData = listData["arrayData"].filter((item: any, index: number) => item["ID_RECEBIMENTO_RECB"] == id);
        setSingleContact(FilteredData);
        setCurrentContract(item);
    };

    //Record agreement modal close open function
    const [showRecordAgreementModal, setRecordAgreementModal] = useState(false);
    const recordAgreementModalClose = (data) => setRecordAgreementModal(data ? data : false);
    const recordAgreementModalShow = () => {
        setRecordAgreementModal(true);
    }

    const [currentContract, setCurrentContract] = useState({});
    const handleSetAllUserItem = (e: any, item: any, readjustValue: any) => {
        setCurrentContract(item);
        setMonetory(readjustValue);
    }

    //View interest modal close open function
    const [showViewInterestModal, setViewInterestModal] = useState(false);
    const viewInterestModalClose = () => setViewInterestModal(false);

    // interest details ###
    const [monetory, setMonetory] = useState(0);
    const [interestFromContract, setInterestFromContract] = useState({})
    const viewInterestModalShow = async (charge_value: any, readjustValue: any, interestdetails: any) => {
        setChargeValue(charge_value);
        setMonetory(readjustValue);
        setViewInterestModal(true);
        setInterestFromContract(interestdetails);
    };

    const viewOrginalModal = (amount: any, readjustValue: any, interestdetails: any) => {
        setOrginalType("orginal");
        viewInterestModalShow(amount, readjustValue, interestdetails);
    }

    //Contract history modal close open function
    const [showContractHistroyModal, setContractHistroyModal] = useState(false);
    const contractHistroyModalClose = () => setContractHistroyModal(false);
    const contractHistroyModalShow = async (recebimento_id: any) => {
        set_recebimento_id(recebimento_id);
        const response = await dispatch(getNonpaymentContactHistory({ "recebimento_id": recebimento_id }))
        if (response.payload.resCode == 201) {
            setContactHistoryData(response.payload.data);
        }
        let FilteredData = listData["arrayData"].filter((item: any, index: any) => item["ID_RECEBIMENTO_RECB"] == recebimento_id);
        setSingleContact(FilteredData);
        setContractHistroyModal(true);
    }

    //Undo agreement modal close open function
    const [showUndoAgreementModal, setUndoAgreementModal] = useState(false);
    const [REMIDD, setREMIDD] = useState<any>("");
    const undoAgreementModalClose = () => setUndoAgreementModal(false);
    const undoAgreementModalShow = (e: any, remID: any, receId: any, old_receid: any) => {
        setREMIDD({
            rem_id: remID,
            rece_id: receId,
            old_receid: old_receid
        });
        setUndoAgreementModal(true);
    }

    //Agreement details modal close open function
    const [showAgreementDetailsModal, setAgreementDetailsModal] = useState(false);
    const agreementDetailsModalClose = () => setAgreementDetailsModal(false);
    const [loadingDetail, setLoadingDetail] = useState(true);
    const [singleCharge, setSingleCharge] = useState({});

    const agreementDetailsModalShow = (e: any, RAMID: any, RECB: any, item: any) => {
        (async () => {
            handleOpenOrginalCharge(RECB);
            setLoadingDetail(true);
            let dataResp = await dispatch(nonPaymentExpenseCharge_installment({
                fetch: {
                    RAMID,
                    RECB
                }
            }));
            if (dataResp && dataResp?.["payload"]?.["data"].length > 0) {
                setLoadingDetail(false);
            }
            setSingleCharge(item);
            setAgreementDetailsModal(true);
        })()
    }

    //Common toast
    const [showToast, setToastShow] = useState(false);
    const duplicateChargeToastClose = () => setToastShow(false);
    const duplicateChargeToastShow = async (item: any) => {
        let bodydata = {
            subject: `New Duplicate Charge Regenerated `,
            content: item?.["GC_BOLETO_URL"] ?? "Empty string",
            sender: "noreply@dev.kenlo.io",
            to: [item["ST_EMAIL_SAC"]],
            title: `Charge Regenerated`,
            status: "charge",
            respCharge: []
            // identifier: "",
            // model: "",
            // layout: "",
        };
        let sendData = await dispatch(nonPayment_send_charge(bodydata));
        if (sendData && sendData?.["payload"]?.["type"] == "success") {
            setToastShow(true);
            setTimeout(() => {
                setToastShow(false);
            }, 2000);
        } else {
            Swal.fire({
                //// icon: 'success',"error",
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.successfailed"),
                confirmButtonText: "OK",
            })
        }
    }

    const openDataClickHandle = (e: any, type: string, id: any) => {
        if (id !== "") {
            nagivate(`/${type}/${id}`);
        }
    }

    useEffect(() => {
        dispatch(getAllSuppliers());
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
        dispatch(getAllAccounts({ "start": 0, "end": 1000 }))
    }, [])

    const FlagProtest = async (schedule_id: any, type) => {
        setLoading(true);
        let receIdResult = await dispatch(NonPaymentFlagProtest({
            resetData: {
                schedule_id,
                flag_value: type == "flag" ? 1 : null
            }
        }));
        if (receIdResult["payload"]["data"]["status"] == "success") {
            await dispatch(nonPaymentCharge(state));
            setLoading(false);
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: t("Sweetalert.successget"),
                confirmButtonText: "OK",
            })
        } else {
            setLoading(false);
            Swal.fire({
                // icon: 'success',"error",
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.successfailed"),
                confirmButtonText: "OK",
            })
        }
    }

    useEffect(() => {
        if (currentContract) {
            (async () => {
                dispatch(contractDatails(currentContract["ID_CONTRATO_CON"]))
            })()
        }
    }, [currentContract])

    const handleOpenOrginalCharge = async (receId: any) => {
        dispatch(nonPayment_get_single_rece_expense({ receId: receId }));
    }
    return (
        <>
            <Card>
                <Card.Header className="d-flex align-items-center justify-content-between">
                    {
                        conditionNonpayment["non_payment"] === "defaulter" ?
                            <>
                                {t("NonPayment.DelayedChargeList")}
                            </>
                            :
                            <>
                                {t("NonPayment.listOfAgreedContracts")}
                            </>
                    }
                    {
                        conditionNonpayment["non_payment"] === "defaulter" &&
                        <Dropdown align="end" className="ms-2">
                            <Dropdown.Toggle variant="light" size="sm" className="flex-shrink-0 fw-bold">
                                {t("NonPayment.sorting")}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleChangeSort("SAC.ST_NOME_SAC ASC")}>{t("NonPayment.alphabeticalByClient")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleChangeSort("RECEB.DT_VENCIMENTO_RECB ASC")}>{t("NonPayment.longestDelayTime")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleChangeSort("RECEB.DT_VENCIMENTO_RECB DESC")}>{t("NonPayment.shortestDelayTime")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleChangeSort("RECEB.vl_emitido_recb DESC")}>{t("NonPayment.highestValue")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleChangeSort("RECEB.vl_emitido_recb ASC")}>{t("NonPayment.LowestValue")}</Dropdown.Item>
                                {/* <Dropdown.Item onClick={() => handleChangeSort()}>{t("NonPayment.statusUrgency")}</Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </Card.Header>
                <Card.Body className="pb-1">
                    {
                        loading ?
                            <Loader />
                            :
                            <Table responsive="xl" className="table-custom table-custom-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="d-flex align-items-center">{t("Dashboard.Charges")}
                                                <Badge bg="light" className="wh-xs-1 d-flex align-items-center justify-content-center ms-1 fs-12 rounded-circle text-dark">

                                                    {
                                                        conditionNonpayment["non_payment"] === "defaulter" ?
                                                            // summery?.["total_contract"] ?? "0"
                                                            nonPaymentChargeData["totalData"]
                                                            :
                                                            listData["totalData"] ?? "0"
                                                    }
                                                </Badge>
                                            </div>
                                        </th>
                                        <th>{t("dropdownItem.status")}</th>
                                        {
                                            conditionNonpayment["non_payment"] === "defaulter" ?
                                                <>
                                                    <th>{t("contractlistother.Amount")} (R$)</th>
                                                    <th>{t("NonPayment.delay")}</th>
                                                    <th>{t("Dashboard.Responsible")}</th>
                                                </>
                                                :
                                                <>
                                                    <th>{t("NonPayment.generated")}</th>
                                                    <th>{t("total")} (R$)</th>
                                                    <th>{t("NonPayment.paidInstallments")}</th>
                                                </>
                                        }
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        // listData["arrayData"] && listData["arrayData"].length > 0 ?
                                        //     listData["arrayData"].map((item: any, index: any) => {
                                        currentTableData && currentTableData.length > 0 ?
                                            currentTableData.map((item: any, index: any) => {
                                                let boletRes = '';
                                                let Boletomm: any = '';
                                                let totalValor = 0;

                                                if (item.API_RESPONSE && item.API_RESPONSE != null) {
                                                    Boletomm = JSON.parse(item.API_RESPONSE);
                                                    boletRes = Boletomm?.url;
                                                }
                                                let BILLINGTYPE = "CASH";
                                                BILLINGTYPE = item.BILLING_TYPE;

                                                // let todayDate = new Date();
                                                // let maturityDate = new Date(`${item?.DT_VENCIMENTO_RECB?.split('T')[0]}`);
                                                // let Difference_In_Time = maturityDate.getTime() - todayDate.getTime();
                                                // let Difference_In_Days: any = Difference_In_Time / (1000 * 3600 * 24);
                                                // let MathSign = Math.sign(Difference_In_Days);
                                                // let dayAgo = "";
                                                // let upcomDay = "";

                                                let todayDate = moments.utc();
                                                let maturityDate = moments.utc(item?.DT_VENCIMENTO_RECB?.split('T')[0]);
                                                let Difference_In_Days = maturityDate.diff(todayDate, 'days');
                                                let MathSign = Math.sign(Difference_In_Days);

                                                let dayAgo = "";
                                                let upcomDay = "";

                                                let totalAMount: number = Number(item?.["vl_emitido_recb"]) + (((Number(item?.["vl_emitido_recb"]) * ((item.VL_TXMULTA_RECB || formdata.fine_rate) / 100)) * 2) + (Number(item?.["vl_emitido_recb"]) * ((item.VL_TXJUROS_RECB || formdata.monthly_interest) / 100)));
                                                if (nonPaymentSetting_Data && nonPaymentSetting_Data.length > 0) {
                                                    let ResultData = nonPaymentSetting_Data[0];
                                                    if (ResultData && ResultData?.["BL_ALLOWCHANGES_NON"]) {
                                                        totalAMount = Number(item?.["vl_emitido_recb"]) + (((Number(item?.["vl_emitido_recb"]) * (ResultData["TX_INTERESTRATE_NUMBER_NON"] / 100)) * 2) + (Number(item?.["vl_emitido_recb"]) * ((item.VL_TXJUROS_RECB  || formdata.monthly_interest) / 100)));
                                                    }

                                                    if ((ResultData && ResultData?.["BL_MONETARY_NON"])) {
                                                        // let futureDate = new Date(maturityDate); // Create a copy of the current date
                                                        // futureDate.setDate(futureDate.getDate() + Number(ResultData?.["TX_DAYSAFTER_NON"])); // Add 12 days to the copied date
                                                        // let Difference_In_Time = futureDate.getTime() - todayDate.getTime();
                                                        // let Difference_In_Days: any = Difference_In_Time / (1000 * 3600 * 24);
                                                        // let MathSign = Math.sign(Difference_In_Days);

                                                        let futureDate = moment.utc(maturityDate);
                                                        let daysAfterNon = Number(ResultData?.["TX_DAYSAFTER_NON"]); // Example: 12
                                                        futureDate.add(daysAfterNon, 'days');
                                                        let todayDate = moment.utc();
                                                        let Difference_In_Time = futureDate.diff(todayDate);
                                                        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                                        let MathSign = Math.sign(Difference_In_Days);

                                                        if (MathSign == -1) {
                                                            if (indexedData_based_on_name && indexedData_based_on_name?.["result"]) {
                                                                // const filteredData = indexedData_based_on_name["result"].filter((item: any) => new Date(item.data) > new Date(futureDate));
                                                                const filteredData = indexedData_based_on_name["result"].filter((item: any) => {
                                                                    return moment.utc(item.data).isAfter(futureDate);
                                                                });
                                                                totalValor = filteredData.reduce((sum: any, item: any) => sum + item.valor, 0);
                                                                totalAMount = totalAMount + ((Number(item?.["vl_emitido_recb"]) * (totalValor / 100)));
                                                            }
                                                        }
                                                    }
                                                }

                                                // let totalAMountDiscount = (totalAMount - (Number(item?.["vl_emitido_recb"]) * (formdata.discount_rate / 100))).toFixed(2);
                                                let totalAMountDiscount = totalAMount ? (totalAMount).toFixed(2) : (totalAMount || 0);
                                                if (MathSign == -1) {
                                                    dayAgo = String(parseInt(Difference_In_Days)).replace("-", "");
                                                    if (Number(dayAgo) > 30) {
                                                        if ((Math.floor(Number(dayAgo) / 30)) > 12) {
                                                            dayAgo = String(Math.floor((Number(dayAgo) / 30) / 12)) + " year";
                                                        } else {
                                                            dayAgo = String(Math.floor(Number(dayAgo) / 30)) + " month";
                                                        }
                                                    } else {
                                                        if (dayAgo == "0") {
                                                            dayAgo = t("ReceiveandReceived.Today");

                                                        } else {
                                                            dayAgo = dayAgo == "1" ? dayAgo + " " + t("formLabel.day") : dayAgo + " " + t("NonPayment.days");
                                                        }
                                                    }
                                                }

                                                return (<React.Fragment key={index}>
                                                    <tr data-readjustindexSum={totalValor}>
                                                        <td>
                                                            <span className="d-block text-dark-70">
                                                                {
                                                                    item.BL_FLAG_PROTEST_SCH &&
                                                                    <span title={t("NonPayment.flagProtest")}>
                                                                        <svg width="20px" height="20px" viewBox="0 0 72 72" id="emoji" xmlns="http://www.w3.org/2000/svg">
                                                                            <g id="color">
                                                                                <circle cx="36" cy="36.0001" r="28" fill="#d22f27" />
                                                                            </g>
                                                                            <g id="line">
                                                                                <circle cx="36" cy="36.0001" r="28" fill="none" stroke="#000000" strokeLinejoin="round" strokeWidth="2" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                }
                                                                {
                                                                    (item.ID_CONTRATO_CON === "" || item.ID_CONTRATO_CON === undefined) ?
                                                                        (item.ID_CONTRATO_CON === null || item.ID_CONTRATO_CON === "" || item.ID_CONTRATO_CON === undefined) && t("notConnectedcontract")
                                                                        :
                                                                        (item.ID_CONTRATO_CON !== null) ? item.ID_CONTRATO_CON &&
                                                                            <Link to={"/contract_details/" + item.ID_CONTRATO_CON}>
                                                                                <span className="text-dark-70">{t("Dashboard.Contract")} {(item.ST_IDENTIFICADOR_IMO && item.ST_IDENTIFICADOR_IMO !== "") ?
                                                                                    item.ST_IDENTIFICADOR_IMO : item.ID_CONTRATO_CON}/{item.lease_count || 1}
                                                                                </span>
                                                                                {/* {item.lease_count ? item.lease_count : ""} */}
                                                                            </Link> :
                                                                            <>{t("notConnectedcontract")}</>
                                                                }
                                                            </span>
                                                            <span className="d-block">{t("formLabel.name")} - {item.ST_NOME_PES ? (item.ST_NOME_PES).charAt(0).toUpperCase() + (item.ST_NOME_PES).slice(1) : (item.ST_NOME_SAC).charAt(0).toUpperCase() + (item.ST_NOME_SAC).slice(1)}</span>
                                                            <span className="d-block mb-1">
                                                                {item.ID_RECEBIMENTO_RECB || item.ID_NEW_RECEBIMENTO_RECB ?
                                                                    <>
                                                                        <div>{(t("contractlistother.charge")).charAt(0).toUpperCase() + (t("contractlistother.charge")).slice(1)} : {item.ID_RECEBIMENTO_RECB ?? item.ID_NEW_RECEBIMENTO_RECB}</div>
                                                                        {item.BILLING_TYPE &&
                                                                            <>
                                                                                <div>{t("ReceiveandReceived.with")} {item.BILLING_TYPE} NN: {Boletomm?.nossoNumero || ""}</div>
                                                                            </>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        ""
                                                                    </>
                                                                }
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {
                                                                (item["BL_CONTACT_STATUS_SCH"] == 0 || item["BL_CONTACT_STATUS_SCH"] == null) &&
                                                                <Badge bg="primary-20" className="text-dark">{t("NonPayment.noActionTake")}</Badge>
                                                            }
                                                            {
                                                                item["BL_CONTACT_STATUS_SCH"] == 1 &&
                                                                <Badge bg="secondary-custom" className="text-dark">{t("NonPayment.scheduledContact")}</Badge>
                                                            }
                                                            {
                                                                (item["BL_CONTACT_STATUS_SCH"] == "1.1" || item["BL_CONTACT_STATUS_SCH"] == "1.2") &&
                                                                <Badge bg="success-20" className="text-dark">{t("NonPayment.effectiveContact")}</Badge>
                                                            }
                                                            {
                                                                (item["BL_CONTACT_STATUS_SCH"] == "2.1" || item["BL_CONTACT_STATUS_SCH"] == "2.2" || item["BL_CONTACT_STATUS_SCH"] == "2.3") &&
                                                                <Badge bg="info-20" className="text-dark">{t("NonPayment.inconclusiveContact")}</Badge>
                                                            }
                                                            {
                                                                (item["BL_CONTACT_STATUS_SCH"] == "3.1" || item["BL_CONTACT_STATUS_SCH"] == "3.2" || item["BL_CONTACT_STATUS_SCH"] == "3.3" || item["BL_CONTACT_STATUS_SCH"] == "3.4") &&
                                                                <Badge bg="pending-20" className="text-dark">{t("NonPayment.contactnotMade")}</Badge>
                                                            }
                                                        </td>
                                                        {
                                                            conditionNonpayment["non_payment"] === "defaulter" ?
                                                                <>
                                                                    <td>
                                                                        <span className="d-block text-dark-70 text-nowrap">R$ {item?.["vl_emitido_recb"] ? converter(String(item?.["vl_emitido_recb"])) : (converter(String(item?.["vl_emitido_recb"])) || 0)}</span>
                                                                        <span className="d-block">R$ {converter(String(totalAMountDiscount))} {t("NonPayment.accumulated")}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="d-block text-dark-70">{dayAgo}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="d-block text-dark-70">{(item?.["ST_NOME_PES"] && item?.["ST_NOME_PES"] !== "" ? item?.["ST_NOME_PES"] : item?.["ST_NOME_SAC"])}</span>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <div className="d-flex align-items-center justify-content-end">
                                                                            <span onClick={() => contactModalShow(item?.["ID_RECEBIMENTO_RECB"], item)} className="text-primary fw-bold cursor-pe me-2 me-xxl-4 me-lg-3">{t("NonPayment.contact")}</span>
                                                                            <Dropdown align="end" onClick={() => handleOpenOrginalCharge(item.ID_RECEBIMENTO_RECB || item.ID_ORGINAL_RECEBIMENTO_RECB)}>
                                                                                <Dropdown.Toggle variant="link" size="sm" className="flex-shrink-0 p-0 dropdown-arrow-none border-0" >
                                                                                    <img src="assets/images/icons/dots_danger.svg" alt="dots" />
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu className="mh-336 overflow-auto">
                                                                                    {
                                                                                        userPlanString !== "100" && userPlanString !== "50" && userPlanString !== "200" &&
                                                                                        <Dropdown.Item onClick={(e: any) => {
                                                                                            recordAgreementModalShow();
                                                                                            handleSetAllUserItem(e, item, totalValor);
                                                                                        }}>{t("NonPayment.recordAgreement")}</Dropdown.Item>
                                                                                    }

                                                                                    {item.BILLING_TYPE &&
                                                                                        <Dropdown.Item onClick={() => duplicateChargeToastShow(item)}>{t("NonPayment.sendDuplicateCharge")}</Dropdown.Item>
                                                                                    }
                                                                                    <Dropdown.Item onClick={(e) => { viewInterestModalShow(item.vl_emitido_recb, totalValor, { discount: item.VL_TXDESCONTO_RECB, interest: item.VL_TXJUROS_RECB, fees: item.VL_TXMULTA_RECB }) }}>{t("NonPayment.viewInterestDetails")}</Dropdown.Item>
                                                                                    <Dropdown.Item onClick={(e) => { contractHistroyModalShow(item.ID_RECEBIMENTO_RECB) }}>{t("NonPayment.viewContactHistory")}</Dropdown.Item>
                                                                                    {
                                                                                        item["BL_CONTACT_STATUS_SCH"] &&
                                                                                        <>
                                                                                            {item["BL_FLAG_PROTEST_SCH"] !== 1 ?
                                                                                                <Dropdown.Item onClick={(e) => { FlagProtest(item.ID_SCHEDULE_SCH, "flag") }}>{t("NonPayment.flagProtest")}</Dropdown.Item>
                                                                                                :
                                                                                                <Dropdown.Item onClick={(e) => { FlagProtest(item.ID_SCHEDULE_SCH, "unflag") }}>{t("NonPayment.removeflagProtest")}</Dropdown.Item>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                    <Dropdown.Item onClick={(e) => { viewOrginalModal(item.vl_emitido_recb, totalValor, { discount: item.VL_TXDESCONTO_RECB, interest: item.VL_TXJUROS_RECB, fees: item.VL_TXMULTA_RECB }) }}>{t("NonPayment.openOriginalCharge")}</Dropdown.Item>
                                                                                    {
                                                                                        item.ID_CONTRATO_CON && item.ID_CONTRATO_CON !== "" &&
                                                                                        <>
                                                                                            {/* <Dropdown.Item onClick={(e) => openDataClickHandle(e, "contract_details", item.ID_CONTRATO_CON)}>{t("NonPayment.openContractDetails")}</Dropdown.Item> */}
                                                                                            <Dropdown.Item><Link to={`/contract_details/${item.ID_CONTRATO_CON}`} className="text-black" >{t("NonPayment.openContractDetails")}</Link></Dropdown.Item>
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        item["ID_PESSOA_PES"] && item["ID_PESSOA_PES"] !== "" &&
                                                                                        <>
                                                                                            {/* <Dropdown.Item onClick={(e) => openDataClickHandle(e, "profile_details", item.ID_SACADO_SAC)}>{t("NonPayment.openCustomerRecord")}</Dropdown.Item> */}
                                                                                            <Dropdown.Item ><Link to={`/profile_details/${item["ID_PESSOA_PES"]}`} className="text-black" >{t("NonPayment.openCustomerRecord")}</Link></Dropdown.Item>

                                                                                        </>
                                                                                    }
                                                                                    {/* {
                                                                                        item.ID_CONTRATO_CON && item.ID_CONTRATO_CON !== "" &&
                                                                                        <Dropdown.Item onClick={(e) => openDataClickHandle(e, "profile_details", "")}>{t("NonPayment.openGuarantorRecord")}</Dropdown.Item>
                                                                                    } */}
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </td>
                                                                </>
                                                                :
                                                                <>
                                                                    <td>
                                                                        <span className="d-block text-dark-70">{moment(item.DT_REGISTERED_REAM).format('DD/MMM/YYYY')}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="d-block text-dark-70"> R$ {item?.VL_AMOUNT_REA ? converter(String(item.VL_AMOUNT_REA)) : (converter(String(item.VL_AMOUNT_REA)) || 0)}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="d-block text-dark-70"> {item.MIN_TX_INSTALLMENT_NUMBER_REAM} {i18n.language == "en" ? "of" : "de"} {item.MAX_TX_INSTALLMENT_NUMBER_REAM}</span>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <div className="d-flex align-items-center justify-content-end flex-nowrap">
                                                                            <span onClick={(e) => agreementDetailsModalShow(e, item.ID_RECORD_AGREMENT_MAIN_REAM, item.ID_ORGINAL_RECEBIMENTO_RECB, item)} className="text-primary fw-bold cursor-pe text-nowrap">{t("newTransfer.openDetails")}</span>
                                                                            {
                                                                                (Number(item.MIN_TX_INSTALLMENT_NUMBER_REAM) == 1) &&
                                                                                <span onClick={(e) => undoAgreementModalShow(e, item.ID_RECORD_AGREMENT_MAIN_REAM, item.ID_NEW_RECEBIMENTO_RECB, item.ID_ORGINAL_RECEBIMENTO_RECB)} className="text-primary fw-bold cursor-pe text-nowrap ms-2 ms-xl-3">{t("NonPayment.undoAgreement")}</span>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </>
                                                        }
                                                    </tr>
                                                </React.Fragment>)
                                            })
                                            :
                                            <tr>
                                                <td colSpan={6} className='text-center'>{t("ReceiveandReceived.NochargesFound")}.</td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                    }
                </Card.Body>
            </Card >
            {/* modal add */}
            {
                conditionNonpayment["non_payment"] === "defaulter" ?
                    <>
                        <Contact showContact={showContactModal}
                            contactClose={contactModalClose}
                            contactShow={contactModalShow}
                            singleContact={singleContact}
                            state={state} loading={loading}
                            setLoading={setLoading} currentContract={currentContract} />

                        <ViewInterest showViewInterest={showViewInterestModal}
                            viewInterestClose={viewInterestModalClose}
                            chargeValue={chargeValue} orginalType={orginalType}
                            monetory={monetory}
                            setMonetory={setMonetory}
                            setOrginalType={setOrginalType} 
                            interestFromContract={interestFromContract}/>

                        <RecordAgreement showRecordAgreement={showRecordAgreementModal}
                            recordAgreementClose={recordAgreementModalClose}
                            recordAgreementShow={recordAgreementModalShow}
                            setRecordAgreementModal={setRecordAgreementModal}
                            currentContract={currentContract}
                            formdata={formdata}
                            setStateClear={setStateClear}
                            state={state}
                            loading={loading}
                            monetory={monetory}
                            setMonetory={setMonetory}
                            setLoading={setLoading} 
                            interestFromContract={interestFromContract}/>

                        <ContractHistroy
                            showContractHistroy={showContractHistroyModal}
                            contractHistroyClose={contractHistroyModalClose}
                            contractHistroyShow={contractHistroyModalShow}
                            recebimento_id={recebimento_id}
                            contactHistoryData={contactHistoryData}
                            singleContact={singleContact}
                            state={state}
                            loading={loading}
                            setLoading={setLoading} />

                        <ToastContainer className="p-3 position-fixed" position="bottom-end" style={{ zIndex: 1 }}>
                            <Toast bg="dark" className="rounded-4 text-white border-0 w-auto" show={showToast} onClick={duplicateChargeToastClose}>
                                <Toast.Body className="d-flex align-items-center p-3">
                                    <img className="me-2 me-xxl-4 me-xl-3" src="assets/images/icons/success-light.svg" alt="arrow right" />
                                    <span className="me-2">{t("NonPayment.duplicateChargeSent")}</span>
                                    <span className="ms-3 cursor-pe" onClick={duplicateChargeToastClose}><img src="assets/images/icons/times-white.svg" alt="arrow right" /></span>
                                </Toast.Body>
                            </Toast>
                        </ToastContainer>
                    </>
                    :
                    <>
                        {/* modal start */}
                        <UndoAgreement showUndoAgreement={showUndoAgreementModal}
                            undoAgreementClose={undoAgreementModalClose}
                            currentContract={currentContract}
                            REMIDD={REMIDD} setREMIDD={setREMIDD}
                            loading={loading} setconditionNonpayment={setconditionNonpayment}
                            conditionNonpayment={conditionNonpayment}
                            state={state} setLoading={setLoading} />

                        <AgreementDetails showAgreementDetails={showAgreementDetailsModal}
                            agreementDetailsClose={agreementDetailsModalClose}
                            setStateClear={setStateClear}
                            currentContract={currentContract}
                            loadingDetail={loadingDetail}
                            setLoadingDetail={setLoadingDetail}
                            singleCharge={singleCharge}
                        />
                    </>
            }
        </>
    )
}

export default NonPaymentListing
