import React, { useState, MouseEvent, useEffect } from 'react';
import { Outlet, Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Form, Modal, Button, Row, Col, Dropdown, Table, Badge, FloatingLabel, Collapse, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Loader from '../../../Profile/Company/Loader';
import IndexedService from '../Common/IndexedService';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLunchIndexs, getAllLunchIndexs_based_on_Name, indexedOpen } from "../../../../redux/slice/expenditureDataSlice";
import { encrypt_Decrypt } from '../../../constants/utils';
import moment from 'moment-timezone';

const ReadjustmentedIndexes = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { indexedData, indexedStatus, indexedData_based_on_name, indexedStatus_based_on_name } = useSelector((state: RootState) => state.expenditureData)
    const { id } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(2030) === -1 && userResult.indexOf(2070) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])

    useEffect(() => {
        // dispatch(getAllLunchIndexs({ "id": id, "status": "", "start": 0, "end": 10 }));
        if (location.state !== "") {
            let statelowercase = location.state;
            dispatch(getAllLunchIndexs_based_on_Name({ "collectionName": (statelowercase && statelowercase !== "") && statelowercase.toLowerCase() }));
        }
    }, [dispatch])

    const indexedOpened = () => {
        dispatch(indexedOpen(true))
    }

    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);
    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 5, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <div className="w-100 overflow-hidden">
                                    <h1 className="mb-0 lh-base text-truncate">{t('readjIndex.readjustment_indexes')}</h1>
                                </div>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        {/* <Col xs={{ order: 1 }} xl={{ span: 6, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item">
                                    <a className="fw-bold cursor-pe" onClick={indexedOpened}>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="Plus icon" className="me-1 h-20" />{t('readjIndex.launchIndex')}
                                    </a>
                                </li> */}
                        {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/print-light.svg"} alt="Print" className="h-20" />
                                </li>
                                <li className="list-inline-item">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/question_icon.svg"} alt="Help" className="h-20" />
                                </li> */}
                        {/* </ul>
                        </Col> */}
                    </Row>
                </div>
            </div>
            {/*<!-- /Breadcrumb section end --> */}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/*<!-- Table start from here --> */}
                {
                    (indexedStatus == "loading" || indexedStatus_based_on_name == "loading") ?
                        <Loader />
                        :
                        <Card>
                            <Card.Body className="pb-1">
                                <Table responsive className="table-custom table-custom-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th>{t("Monthyear")}</th>
                                            {/* <th>{t("bankTransaction.date")}</th> */}
                                            <th>{t("index")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {indexedData_based_on_name && indexedData_based_on_name?.result &&
                                            indexedData_based_on_name?.result?.length > 0 ?
                                            indexedData_based_on_name.result.map((row: any, index: number) => {
                                                // let newDate:any = moment(row.data).format("MMM/YYYY")
                                                let dt = (row.data).split('T')[0];
                                                let mt = dt.split('-')[1];
                                                let yt = dt.split('-')[0];

                                                let mths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                                                let Result = moment.tz(row.data, "America/Sao_Paulo").add(1, "months").format("MMM/YYYY");
                                                let mname = mths[mt - 1];
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {/* <span className="d-block text-dark-70"> {t(`half_month.${(Result)?.split("/")[0]}`) + "/" + (Result)?.split("/")[1]}</span> */}
                                                            {/* <span className="d-block text-dark-70">{t(`half_month.${(Result)?.split("/")[0]}`) + "/" + (Result)?.split("/")[1]}</span> */}
                                                            {/* <span className="d-block text-dark-70">{mname}/{yt}</span> */}
                                                            <span className="d-block text-dark-70">{t(`half_month.${(mname)?.split("/")[0]}`) + "/" + yt}</span>
                                                        </td>
                                                        {/* <td>
                                                            <span className="d-block text-dark-70">{row.data}</span>
                                                        </td> */}
                                                        <td>
                                                            <span className="d-block text-dark-70">{(row.valor && row.valor != "") && (row.valor).toFixed(2)}</span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <>
                                                {indexedData.data && indexedData.data.length > 0 ?
                                                    indexedData.data.map((row: any, index: number) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <span className="d-block text-dark-70">{row.indexDate}</span>
                                                            </td>
                                                            <td>
                                                                <span className="d-block text-dark-70">{row.indexValue}</span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <span>{t('formLabel.noRecordsFounds')}</span>
                                                        </td>
                                                    </tr>
                                                }
                                            </>
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                }
                {/*<!-- /Table end --> */}
                {
                    indexedData_based_on_name && indexedData_based_on_name?.result && indexedData_based_on_name?.result?.length > 0 ?
                        <>
                            <p className="mt-lg-4 mt-3">{t("listing")} {indexedData_based_on_name.totalRow > 0 ? indexedData_based_on_name.totalRow : 0} {t("readjIndex.readjustment_indexes")}</p>
                        </>
                        :
                        <>
                            <p className="mt-lg-4 mt-3">{t("listing")} {indexedData.totalRow > 0 ? indexedData.totalRow : 0} {t("readjIndex.readjustment_indexes")}</p>
                        </>
                }
            </main>
            {/*<!-- /Main content start end -->*/}
            {/* New index modal start from here */}
            <IndexedService />
        </section>
    )
}
export default ReadjustmentedIndexes;