import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel } from 'react-bootstrap';
import { contractDatails,newExtraChargeClose, getAllSuppliers, newExtraChargeOpen, getAllService, insertExtraCharge, deletService, getAllExpense, setExtraCharge, setExtraChargeLabel } from "../../../../redux/slice/contractDataSlice";
import { getPropertyExpense, insertPropertyBillingCharge, getAllPropertyCharge } from "../../../../redux/slice/propertyDataSlice";
import { getAllContractCharge, getSingleContractCharge } from "../../../../redux/slice/expenditureDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next'; 
import { newExtraChargeForm } from '../../../Interface';
import Swal from 'sweetalert2';
import moment from "moment-timezone";
import '../../../../App.css';
import { openErrorbox } from './actionPerform';
import { gettoken } from '../../../../Api/Contact';
import { incomeService } from '../../../../services/incomeServices';
import { commonService } from '../../../../services/commonService';
import { addBillingTicketCron } from '../../../../redux/slice/contractDataSlice';
import { converter, convertToNumber, formatNumber } from '../../../constants/utils';
const ExtraChargeService = (props: any) => {
    let { contractId,extraAndDiscount, extraChargeClosed, setExtraAndDiscount, state, stateCharge } = props;
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD");

    const { extraChargeLabel, chargeId, suppliersData, contractDatail, serviceData, newExtraChargeShow} = useSelector((state: RootState) => state.contractData)
    const { accountData, billingData } = useSelector((state: RootState) => state.expenditureData)
    const [double, setDouble] = useState(false);
    const [isLoading, setisLoading] = useState(false); 
    const { id } = useParams();
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();

    const [aarinConfig, setAarinConfig] = useState<any>("1");
    const [aarinSuppConfig, setaarinSuppConfig] = useState<any>("1");
    const [chargeType, setChargeType] = useState<any>("");
    const [chargeIdPaid, setIsPaid] = useState<any>("");
    const [isExtDiscount, setShowExtDiscount] = useState(false); 
    const [adminFeeStatus, setAdminFeeStatus] = useState<any>("0");
    const [isCalculateAdminFee, setisCalculateAdminFee] = useState<any>(false);
    const [valueCon, setValueCon] = useState('') 
    useEffect(() => {
        if (newExtraChargeShow || extraChargeLabel === "New Charge") {
            dispatch(getAllSuppliers());
            dispatch(getAllService());
            getExtraChargeData(chargeId)
            let conId = id && typeof id !== "undefined" ? id : contractId;
            getContractDatails(conId);
            
        }
    }, [ chargeId,newExtraChargeShow,extraChargeLabel ])

    const getContractDatails = async (id) => {
        if (extraChargeLabel !== "New Charge" && contractDatail.length > 0) {
            setValue('bankAccount', contractDatail[0].bank_account);
            setValue('billingMethod', contractDatail[0].billing_method);
            setValue('much', contractDatail[0].trafficTicket ? converter(String(contractDatail[0].trafficTicket)) : converter("000"));
            setValue('fees', contractDatail[0].fees ?  converter(String(contractDatail[0].fees)) : converter("000"));
            setValue('discount', contractDatail[0].punctuality ? converter(String(contractDatail[0].punctuality)) : converter("000"));
            setValue('propertyId', contractDatail[0].propertyId);
            setValue('tenant', contractDatail[0].sacadoId);
            setValue('branch', contractDatail[0].branch);
        }
        if (contractDatail.length &&
            contractDatail[0].invoice === 1) {
            if (serviceData && serviceData.length &&
                serviceData[0].TaxaDeAdministracao
            ) {
                let mt: any = serviceData[0].TaxaDeAdministracao;
                if (mt.ID_CODIGOSERVICO_CSE != null) {
                    setisCalculateAdminFee(true);
                }
            } else {
                setisCalculateAdminFee(false);
            }
        } else {
            setisCalculateAdminFee(true);
        }
    }

    const [servicearr, setServicearr] = useState([
        {
            service: "",
            complement: "",
            value: "",
            credit: "",
            admin_fee: "",
            supplierview: false,
            supplier: "",
            admin_cond: false,
        }
    ]);


    const getExtraChargeData = async (id) => {
        setAarinConfig(1); setIsPaid(0);
        if (typeof id !== "undefined" && id && extraChargeLabel !== "New Charge") {
            let servicearrList: any = [];
            const fields: string[] = ['chargeId', 'maturity', 'total', 'internalObservation', 'noteCustomer', 'bankAccount', 'billingMethod', 'credit', 'supplier', 'isCalAdmin',]
            let data = await dispatch(getSingleContractCharge(id))
            if (data["payload"]["data"][0].IS_PAID === 1) {
                setAarinConfig(0)
                setIsPaid(data["payload"]["data"][0].BILLING_TYPE);
            }
            data["payload"]["data"][0]["objdata"] && data["payload"]["data"][0]["objdata"].length > 0 &&
                data["payload"]["data"][0]["objdata"].forEach((item, index) => {
                    setValue(`objdata.${index}.chargeserviceId`, item["ID_LANCAMENTO_IMOD"])
                    setValue(`objdata.${index}.service`, item["ID_PRODUTO_PRD"])
                    setValue(`objdata.${index}.complement`, item["ST_COMPLEMENTO_IMOD"])
                    setValue(`objdata.${index}.value`, converter(String(item["VL_VALOR_IMOD"])))
                    setValue(`objdata.${index}.credit`, item["ID_CREDITO_IMOD"])
                    setValue(`objdata.${index}.supplier`, item["ID_TERCEIRO_FAV"])
                    setValue(`objdata.${index}.administrationApplies`, item["FL_COBRARTXADM_IMOD"])
                    if (item["ID_DEBITO_IMOD"] === 1) {
                        setChargeType('Owner');
                        setValue('debitType', '1')
                        setExtraChargeLabel('New charge - Owner');
                    } else {
                        setChargeType('Tenant');
                        setValue('debitType', '2')
                        setExtraChargeLabel('New charge - Tenant');
                    }
                    servicearrList.push({
                        service: item["ID_PRODUTO_PRD"],
                        complement: item["ST_COMPLEMENTO_IMOD"],
                        value: item["VL_VALOR_IMOD"],
                        credit: item["ID_CREDITO_IMOD"],
                        supplierview: item["ID_TERCEIRO_FAV"] !== 0 ? true : false,
                        supplier: item["ID_TERCEIRO_FAV"],
                        admin_fee: item["FL_COBRARTXADM_IMOD"]
                    })
                })

            setServicearr(servicearrList)

            if (data.payload.data.length > 0) {
                fields.forEach((field: any) => setValue(field, data.payload.data[0][field]));
                setValue('much', data?.payload?.data[0]?.much ? converter(String(data.payload.data[0].much)) : converter("000"));
                setValue('fees', data?.payload?.data[0]?.fees ? converter(String(data.payload.data[0].fees)) : converter("000"));
                setValue('discount', data?.payload?.data[0]?.discount ? converter(String(data.payload.data[0].discount)) : converter("000"));
                if (data.payload.data[0]['credit'] === 4) {
                    setHide(false);
                }
                if (data.payload.data[0].competence) {
                    setValue("competence", data.payload.data[0].competence)
                } else {
                    let newDate = new Date(dateBrazil);
                    let today = moment(newDate).format('YYYY-MM-DD');
                    setValue("competence", today)

                }
                dispatch(newExtraChargeOpen(true))
                setValue("totalAmount", converter(data.payload.data[0].totalAmount))
                setValueCon(String(data.payload.data[0].totalAmount))
            }
        }


        if (typeof id !== "undefined" && id && id.length > 0 && extraChargeLabel === "New Charge") {
            let servicearrList: any = [];
            const fields: string[] = ['ST_CONTA_CONT', 'maturity', 'propertyId', 'tenant', 'branch',]
            let data = await dispatch(getAllPropertyCharge(id))
            if (data.payload.data.length > 0) {
                data["payload"]["data"][0]["objdata"] && data["payload"]["data"][0]["objdata"].length > 0 && data["payload"]["data"][0]["objdata"].forEach((item, index) => {
                    setValue(`objdata.${index}.chargeserviceId`, item["ID_LANCAMENTO_IMOD"])
                    setValue(`objdata.${index}.debit`, item["debit"])
                    setValue(`objdata.${index}.debitOwner`, item["debitOwner"])
                    setValue(`objdata.${index}.creditOwner`, item["creditOwner"])

                    setValue(`objdata.${index}.service`, item["ID_PRODUTO_PRD"])
                    setValue(`objdata.${index}.complement`, item["ST_COMPLEMENTO_IMOD"])
                    setValue(`objdata.${index}.value`, converter(item["VL_VALOR_IMOD"]))
                    setValue(`objdata.${index}.credit`, item["ID_CREDITO_IMOD"])
                    setValue(`objdata.${index}.supplier`, item["ID_TERCEIRO_FAV"])
                    setValue(`objdata.${index}.administrationApplies`, item["FL_COBRARTXADM_IMOD"])
                    if (item["ID_DEBITO_IMOD"] === 1) {
                        setChargeType('Tenant');
                        setExtraChargeLabel('New charge - Tenant');
                    } else {
                        setChargeType('Owner');
                        setExtraChargeLabel('New charge - Owner');
                    }
                    servicearrList.push({
                        service: item["ID_PRODUTO_PRD"],
                        complement: item["ST_COMPLEMENTO_IMOD"],
                        value: item["VL_VALOR_IMOD"],
                        credit: item["ID_CREDITO_IMOD"],
                        supplierview: item["ID_TERCEIRO_FAV"] !== 0 ? true : false,
                        supplier: item["ID_TERCEIRO_FAV"],
                        admin_fee: item["FL_COBRARTXADM_IMOD"]
                    })
                })
                setServicearr(servicearrList)
                fields.forEach((field: any) => setValue(field, data.payload.data[0][field]));
                setValue("totalAmount", converter(data.payload.data[0].totalAmount))
                setValueCon(String(data.payload.data[0].totalAmount))
                if (data.payload.data[0]['credit'] === 4) {
                    setHide(false);
                }
                dispatch(newExtraChargeOpen(true))
            }
            else {
                dispatch(setExtraCharge([]))
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.alreadybillinggenerate"),
                    // icon: "error", 
                    confirmButtonText: "OK",
                    timer: 2000
                })
            }
        }
    }
    /*------------Extra charge modal hide function-----------*/

    const [hide, setHide] = useState(true);
    const [adminFeeHide, setAdminFeeHide] = useState(false);
    const creditSuppliers = (index: any, e: any) => {
        //setValue(`objdata.0.administrationApplies`, 0)
        let val = e.target.value; setAdminFeeHide(false);
        let allperviousData = servicearr;

        servicearr && servicearr.length == 1 &&
            setValue(`objdata.0.administrationApplies`, 0)

        if (val == 3 || val == "3") {
            setaarinSuppConfig(1)
            // Real Estate then hide Admin fee checkobx, 100% to Admin
            setAdminFeeHide(false);
            setMoreOptions(true);
            allperviousData[index]["admin_cond"] = false;
            setValue(`objdata.${index}.administrationApplies`, 0)
        }

        if (val == 1 || val == "1") {
            // Owner then hide Admin fee checkobx, 100% to Admin
            setMoreOptions(false);
            moreOpt("admin", "");
            allperviousData[index]["admin_cond"] = true;
        }

        if (val == 4 || val == "4") {
            // Third then hide Admin fee checkobx, 100% to Admin
            setMoreOptions(false);
            moreOpt("admin", "");
            allperviousData[index]["admin_cond"] = true;
        }

        setValue(`objdata.${index}.supplier`, '');
        if (val == 4) {
            allperviousData[index]["supplierview"] = true;
        } else {
            allperviousData[index]["supplierview"] = false;
        }
        setServicearr([]);
        setServicearr([...allperviousData]);
        if (e.target.value !== "") {
            clearErrors("credit")
        } else {
            setError("credit", {
                type: "manual",
                message: t('requiredMmessage.fieldRequired'),
            });
        }
        moreOpt("bank", "");
    }

    function moreOpt(type: any, value: any) {
        if (type == "bank") {
            if (servicearr && servicearr.length > 1) {
                setMoreOptions(true);
            } else {
                if (value !== "") {
                    setMoreOptions(false);
                }
            }
        } else {
            if (servicearr && servicearr.length == 1) {
                if (getValues(`objdata.${0}.administrationApplies`)) {
                    setMoreOptions(true);
                } else {
                    setMoreOptions(false);
                }
            }
        }
    }

    const [isMoreOptions, setMoreOptions] = useState(false);
    const issContract = (evt: any, index: number) => {
        const isChecked = evt.target.checked;
        setValue(`objdata.${index}.administrationApplies`, isChecked)
        moreOpt("admin", isChecked);
    }

    const checkSuppliersAarin = (index, e) => {
        let val = e.target.value;
        let dataAccount = suppliersData.filter((item, index) => Number(e.target.value) == Number(item.favorecidoId));
        if (dataAccount.length) {
            val = dataAccount[0]?.id;
            commonService.isUserBankConfigured({ userId: val }).then(res => {
                if (res.resCode == 201) {
                    setaarinSuppConfig(1);
                } else {
                    setaarinSuppConfig(0);
                }
            }).catch(err => {
                console.log("err", err)
            });
        }
    }

    const extraChargeClose = () => {
        reset();
        extraChargeClosed();
        dispatch(newExtraChargeClose(false));
        dispatch(setExtraCharge(null));
        dispatch(setExtraChargeLabel(null))
        setShowExtDiscount(false);
        setHide(true);
        setDouble(false);
        setValue('total', '');
        setValue('chargeId', '');
        setAarinConfig(1);
        setaarinSuppConfig(1);
        //getContractDatails(id);
        setServicearr([
            {
                service: "",
                complement: "",
                value: "",
                credit: "",
                admin_fee: "",
                supplierview: false,
                supplier: "",
                admin_cond: false,
            }
        ])
    }

    /*------------Form validation-----------*/
    let form1 = {
        maturity: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        totalAmount: Yup.string()
            .min(2, "Please enter a value more than 2 digit"),
        bankAccount: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        billingMethod: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        objdata: Yup.array().of(
            Yup.object().shape({
                credit: Yup.string()
                    .required(t('requiredMmessage.fieldRequired')),
                value: Yup.string()
                    .required(t('requiredMmessage.fieldRequired')),
                service: Yup.string()
                    .required(t('requiredMmessage.fieldRequired'))
            })
        )
    };
    let form3 = { ...form1 };
    let form2 = {
        // supplier: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
    }
    if (!hide) {
        openErrorbox()
        form3 = { ...form1, ...form2 };
    }
    const extraChargeValidation = Yup.object().shape(form3);
    let frm = {
        resolver: yupResolver(extraChargeValidation)
    }
    const { register, reset, handleSubmit, setValue, clearErrors, setError, getValues, formState: { errors } } = useForm<newExtraChargeForm>(frm);
    const onSubmit = async (data: newExtraChargeForm, e: any) => {
        e.preventDefault();
        setDouble(true)
        setisLoading(true)
        let TotalVal: any = convertToNumber(valueCon);
        data["total"] = TotalVal;
        data["totalAmount"] = TotalVal;
        for (let key in data.objdata) {
            if (data.objdata[key].hasOwnProperty('value')) {
                data.objdata[key].value = convertToNumber(data.objdata[key].value);
            }
        }
       let discount = `${data.discount}`
       let discount_val:any = convertToNumber(discount)
       let fees = `${data.fees}`
       let fees_val:any = convertToNumber(fees)
       let much = `${data.much}`
       let much_val:any = convertToNumber(much)
       data["discount"] = discount_val
       data["fees"] = fees_val
       data["much"] = much_val
        // return false;
        if (typeof chargeId != "undefined" && chargeId && chargeId.length > 0 && extraChargeLabel == "New Charge") {
            let response = await dispatch(insertPropertyBillingCharge(data));
            if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
                setTimeout(() => {
                    Swal.fire({
                        title: t("Sweetalert.Success"),
                        text: t("Sweetalert.successget"),
                        confirmButtonText: "OK",
                        timer: 2000
                    })
                    setDouble(false);
                    dispatch(getPropertyExpense(state))
                    extraChargeClose();
                    setisLoading(false);
                }, 5000);
            } else {
                setDouble(false); setisLoading(false);
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.successfailed"), 
                    confirmButtonText: "OK",
                    timer: 2000
                })
            }
        }
        else {
            let response = await dispatch(insertExtraCharge(data));
            if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
                setTimeout(() => {
                    dispatch(addBillingTicketCron(response.payload.data[0].ID_RECEBIMENTO_RECB))
                    setDouble(false);
                    Swal.fire({
                        title: t("Sweetalert.Success"),
                        text: t("Sweetalert.successget"),
                        confirmButtonText: "OK",
                        timer: 2000
                    })
                    dispatch(getAllExpense(state));
                    dispatch(getAllContractCharge(stateCharge));
                    extraChargeClose(); extraChargeClosed();
                    setisLoading(false);
                }, 5000);
            } else {
                setDouble(false); setisLoading(false);
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.successfailed"),
                    // icon: "error", 
                    confirmButtonText: "OK",
                    timer: 2000
                })
            }
        }
    }

    const calculateValue = (evt, index) => {
        let { value } = evt.target;
        value = value.replace(/[^\d,]/g, '');
            setValue(`objdata.${index}.value`, formatNumber(value))
           
        servicearr[index]["value"] = evt.target.value
        setTimeout(() => {
            let totalNum: number = 0;
            servicearr.forEach((item, index) => {
                if (String(item.value) != "") {
                    let Val: any = convertToNumber(item.value);
                    totalNum += Number(Val);
                }
            })
            setValue('total', converter(String(totalNum)));
            setValue('totalAmount', converter(String(totalNum)));
            setValueCon(String(totalNum))
        }, 100)
    }

    const [billmethodOnOff, setBillMethodOnOff] = useState({});
    const handlefilterBillmethod = (e) => {
        let dataAccount = accountData.data.filter((item, index) => Number(e.target.value) == Number(item.id));
        if (dataAccount.length) {
            if (dataAccount[0].aarinBankId >= 1) {
                setAarinConfig(1);
                setBillMethodOnOff(dataAccount[0]);
            } else {
                setAarinConfig(0);
            }
        }
    }

    useEffect(() =>{
        if(newExtraChargeShow){
            let newDate = new Date(dateBrazil);
            let today = moment(newDate).format('YYYY-MM-DD');
            setValue("competence", today)
        }
    },[newExtraChargeShow])

    useEffect(() => {
        if (newExtraChargeShow) {
            (async () => {
                let response = await incomeService.getDiscountData(gettoken.userId);
                setExtraAndDiscount(response.data);
            })()
        }
    }, [])

    useEffect(() => {
        if (newExtraChargeShow) {
            setTimeout(() => {
                if (typeof extraAndDiscount !== "undefined" && (!chargeId || extraChargeLabel == "New Charge")) {
                    if (Object.keys(extraAndDiscount).length > 0) {
                        setValue('fees', contractDatail[0]?.trafficTicket ? contractDatail[0].trafficTicket : extraAndDiscount["monthly_interest"]);
                        setValue('much', contractDatail[0]?.fees ? contractDatail[0].fees : extraAndDiscount["fine_rate"]);
                        setValue('discount', contractDatail[0]?.punctuality ? contractDatail[0].punctuality : extraAndDiscount["discount_rate"]);
                    }
                }
            }, 500)
        }
    }, [extraAndDiscount, chargeId, isExtDiscount])

    const serviceCharge = (value: any) => {
        if (typeof extraAndDiscount !== "undefined" && (extraAndDiscount['apply_discount_all'] == 1 || extraAndDiscount['apply_discount_all'] == "1")) {
            setShowExtDiscount(true);
        } else if (typeof extraAndDiscount !== "undefined" && extraAndDiscount['apply_discount_all'] == 2) {
            let dataValue = extraAndDiscount["discounted_services"].filter((item) => item.value == value);
            if (dataValue && dataValue.length && dataValue[0].value) {
                setShowExtDiscount(true);
            }
        } else {
            setShowExtDiscount(false);
        }
    }
    const AddanotherFuncunility = (e) => {
        setServicearr([
            ...servicearr,
            {
                service: "",
                complement: "",
                value: "",
                credit: "",
                admin_fee: "",
                supplierview: false,
                supplier: "",
                admin_cond: false,
            }
        ])
    }

    useEffect(() => {
        moreOpt('bank', "");
    }, [servicearr])
    const handleAdminFeeChange = (e) => {
        const selectedServiceAdminFeeStatus = e.target.options[e.target.selectedIndex].getAttribute('data-value2');
        setAdminFeeStatus(selectedServiceAdminFeeStatus);
    }
    const deleteItem = (serviceValue) => {
        let arr: any = getValues("objdata")
        const updateServ = [...arr]
        const deletedItem = updateServ[serviceValue];

        updateServ.splice(serviceValue, 1);
        setServicearr(updateServ);

        setValue("objdata", updateServ)
        let totalNum: number = 0;
        updateServ.forEach((item, index) => {
            if (String(item.value) != "") {
                totalNum += Number(item.value);
            }
        })
        setValue('total', String(totalNum));
        setValue('totalAmount', String(totalNum));
        if (deletedItem.chargeserviceId) {
            let response = dispatch(deletService(deletedItem));
        }
    };
    return (
        <>
            {/* <!-- New extra charge start from here --> */}
            <Modal show={newExtraChargeShow} className='NewExtraChargetsx' onHide={extraChargeClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" value={ id ? id : contractId } {...register('id')} />
                    <input type="hidden" {...register('propertyId')} />
                    <input type="hidden" {...register('tenant')} />
                    <input type="hidden" {...register('branch')} />
                    <input type="hidden" {...register('total')} />
                    <input type="hidden" {...register('chargeId')} />
                    <input type="hidden" {...register('ST_CONTA_CONT')} />
                    {
                        extraChargeLabel == "New charge - Tenant" ?
                            <input type="hidden" value="2" {...register('debitType')} />
                            : extraChargeLabel == "New charge - Owner" ?
                                <input type="hidden" value="1" {...register('debitType')} />
                                :
                                <input type="hidden" {...register('debitType')} />
                    }
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                extraChargeLabel == "New charge - Tenant" ? t("expenditure.newCharge") + "-" + t("notifications.tenant")
                                    : extraChargeLabel == "New charge - Owner" ? t("expenditure.newCharge") + "-" + t("notifications.owner")
                                        : extraChargeLabel
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="floatingInput" label={t("maturity") + " *"}>
                            <Form.Control type="date" min={dateBrazil} placeholder={t("maturity")} className={`form-control ${errors.maturity ? 'is-invalid' : ''}`} {...register('maturity')} />
                            <Form.Control.Feedback type="invalid">{errors.maturity?.message}</Form.Control.Feedback >
                        </FloatingLabel>
                        <span className="d-block mb-3 fw-bold">{t("Links.services")}</span>
                        {
                            servicearr && servicearr.length > 0 &&
                            servicearr.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {servicearr.length > 1 ?
                                            <span className='d-flex justify-content-end mb-1'>
                                                <Button variant="link" className="fw-bold p-0" onClick={(e) => deleteItem(index)}>
                                                    {t("Sweetalert.Delete")}
                                                </Button>
                                            </span>
                                            : ""}
                                        <input type="hidden" {...register(`objdata.${index}.chargeserviceId`)} readOnly />
                                        <input type="hidden" {...register(`objdata.${index}.debit`)} readOnly />
                                        <input type="hidden" {...register(`objdata.${index}.debitOwner`)} readOnly />
                                        <input type="hidden" {...register(`objdata.${index}.creditOwner`)} readOnly />
                                        <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("Links.services")+ " *"}>
                                                    <Form.Select className={`form-control ${errors?.objdata?.[index]?.service ? 'is-invalid' : ''}`} {...register(`objdata.${index}.service`)}
                                                        onChange={(e) => {
                                                            serviceCharge(e.target.value);
                                                            handleAdminFeeChange(e);
                                                        }}>
                                                        <option value="">{t('dropdownItem.select')}..</option>
                                                        {serviceData && serviceData.length > 0 && serviceData.map((item: any, index) => (
                                                            <option key={index} value={item.id} data-value2={item.admin_fee_status}>{item.name}</option>

                                                        ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors?.objdata?.[index]?.service?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.complement")}>
                                                    <Form.Control type="text" placeholder={t("formLabel.complement")} className={`form-control ${errors.complement ? 'is-invalid' : ''}`} {...register(`objdata.${index}.complement`)} />
                                                    <Form.Control.Feedback type="invalid">{errors.complement?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={hide ? 6 : 4}>
                                                <FloatingLabel controlId="floatingInput" label={t("formLabel.value")+ " *"}>
                                                    <Form.Control type="text" placeholder={t("formLabel.value")} className={`form-control ${errors?.objdata?.[index]?.value ? 'is-invalid' : ''}`} {...register(`objdata.${index}.value`)} onChange={(e) => calculateValue(e, index)} />
                                                    <Form.Control.Feedback type="invalid">{errors?.objdata?.[index]?.value?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={hide ? 6 : 4}>
                                                <FloatingLabel controlId="floatingSelect" label={t("formLabel.credit")+ " *"}>
                                                    <Form.Select className={`form-control ${errors?.objdata?.[index]?.credit ? 'is-invalid' : ''}`} {...register(`objdata.${index}.credit`)} aria-label="Credit" onChange={(e) => creditSuppliers(index, e)}>
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        <option value="3">{t('realEstate')} </option>
                                                        {
                                                            (extraChargeLabel == "New charge - Tenant" || chargeType == 'Tenant') ?
                                                                <option value="1">{t('notifications.owner')}</option>
                                                                : ''
                                                        }
                                                        {/*<option value="5">Immobile</option>*/}
                                                        <option value="4">{t('services.third')}</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid"> {errors?.objdata?.[index]?.credit?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            {
                                                item["supplierview"] &&
                                                <Col md={4}>
                                                    <FloatingLabel controlId="floatingSelect" label={t("formLabel.Supplier")+ " *"}>
                                                        <Form.Select className={`form-control ${errors.supplier ? 'is-invalid' : ''}`} {...register(`objdata.${index}.supplier`)} aria-label="Supplier" onChange={(e) => checkSuppliersAarin(index, e)}>
                                                            <option value="">{t('dropdownItem.select')}..</option>
                                                            {suppliersData && suppliersData.length > 0 && suppliersData.map((item, index) => (
                                                                <option key={index} value={item.favorecidoId}>{item.name}</option>
                                                            ))}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">{errors.supplier?.message}</Form.Control.Feedback>
                                                        {aarinSuppConfig != 1 &&
                                                            <p className="text-danger">Bank account is not configured!</p>
                                                        }
                                                    </FloatingLabel>
                                                </Col>
                                            }
                                        </Row>
                                        <Row className="mb-lg-4 mb-3 align-items-center">
                                            <Col sm="auto">
                                                {isCalculateAdminFee ?
                                                    <>
                                                        {
                                                            item.admin_cond &&
                                                            <>
                                                                <Form.Check type="checkbox" {...register(`objdata.${index}.administrationApplies`)} label={t("services.administrationFeeApplies")} onClick={(e) => issContract(e, index)} />
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <p>
                                                            {t("noCalculateAdminFee")}
                                                        </p>
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )
                            })
                        }
                        {

                            <>
                                <Button className="p-0" variant="link" onClick={(e) => AddanotherFuncunility(e)}>
                                    <img src="../assets/images/icons/plus-light.svg" className="h-20 me-1" /> {t("formLabel.another")}
                                </Button>

                            </>
                        }
                        <FloatingLabel className="my-lg-4 my-3" controlId="floatingInput" label={t("comparetolastmonth.total")}>
                            <Form.Control type="text" disabled placeholder="0" className={`form-control ${errors.totalAmount ? 'is-invalid' : ''}`} {...register('totalAmount')} />
                            <Form.Control.Feedback type="invalid">{errors.totalAmount?.message}</Form.Control.Feedback>
                        </FloatingLabel>

                        <Accordion className="accordion-custom">
                            {/* {isExtDiscount && */}
                            <>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>{t("contractlistother.Extrasanddiscounts")}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="gy-3 gy-lg-4">
                                            <Col md>
                                                <FloatingLabel controlId="floatingInput" label={t("Much")}>
                                                    <Form.Control type="text" className={`form-control ${errors.much ? 'is-invalid' : ''}`} placeholder="Much(%)" {...register('much')} />
                                                    <Form.Control.Feedback type="invalid">{errors.much?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md>
                                                <FloatingLabel controlId="floatingInput" label={t("fees")}>
                                                    <Form.Control type="text" className={`form-control ${errors.fees ? 'is-invalid' : ''}`} placeholder="Fees(%)" {...register('fees')} />
                                                    <Form.Control.Feedback type="invalid">{errors.fees?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md>
                                                <FloatingLabel controlId="floatingInput" label={t("formReceipt.discount") + "(%)"}>
                                                    <Form.Control type="text" className={`form-control ${errors.discount ? 'is-invalid' : ''}`} placeholder={t("formReceipt.discount")} {...register('discount')} />
                                                    <Form.Control.Feedback type="invalid">{errors.discount?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </>
                            {/* } */}
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>{t("banks.moreOptions")}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t("banks.bankAccounts") + " *"}>
                                                    <Form.Select className={`form-control ${errors.bankAccount ? 'is-invalid' : ''}`} {...register('bankAccount')} aria-label="Bank account" onChange={(e) => handlefilterBillmethod(e)}>
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        {
                                                            accountData.data && accountData.data.length > 0 ? accountData.data.map((item, index) => {
                                                                return <option key={index} value={Number(item.id)}>{item.account} -- {item.accountRef}</option>
                                                            }) :
                                                                ''
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.bankAccount?.message}</Form.Control.Feedback>

                                                    {aarinConfig != 1 &&
                                                        <p className="text-danger">Bank account is not configured!</p>
                                                    }

                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t("billingMethod")+ " *"}>
                                                    <Form.Select className={`form-control ${errors.billingMethod ? 'is-invalid' : ''}`} {...register('billingMethod')} aria-label="Billing method">
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        {
                                                            billingData.data && billingData.data.length > 0 ? billingData.data.map((item, index) => (
                                                                <option key={index} value={Number(item.id)}>{item.name}</option>
                                                            ))
                                                                : ''
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.billingMethod?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("contractlistother.Internalobservation")}>
                                                    <Form.Control type="text" className={`form-control ${errors.internalObservation ? 'is-invalid' : ''}`} placeholder={t("contractlistother.Internalobservation")} {...register('internalObservation')} />
                                                    <Form.Control.Feedback type="invalid">{errors.internalObservation?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingInput" label={t("contractlistother.Noteforthecustomer")}>
                                                    <Form.Control type="text" placeholder={t("contractlistother.Noteforthecustomer")} className={`form-control ${errors.noteCustomer ? 'is-invalid' : ''}`} {...register('noteCustomer')} />
                                                    <Form.Control.Feedback type="invalid">{errors.noteCustomer?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <FloatingLabel controlId="floatingInput" label={t("contractlistother.Competence")}>
                                            <Form.Control type="date" placeholder={t("contractlistother.Competence")} className={`form-control ${errors.competence ? 'is-invalid' : ''}`} {...register('competence')} />
                                            <Form.Control.Feedback type="invalid">{errors.competence?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        {(aarinConfig == 0 && chargeIdPaid == 'PIX') &&
                            <p className="text-danger">Charge is already paid, Cannot be updated!</p>
                        }
                        <div className="d-flex align-items-center justify-content-end w-100">
                            <div>
                                <Button variant="outline-primary" onClick={extraChargeClose}>
                                    {t("button.close")}
                                </Button>
                                {(aarinConfig == 1 && aarinSuppConfig == 1) &&
                                    <>
                                    { isLoading ?
                                    <Button className="btn-width">
                                        <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                    </Button>
                                    : 
                                    <Button disabled={double} type="submit" className="new-extra-charegtsx" variant="primary" onClick={openErrorbox}>
                                        {t("button.toSave")}
                                    </Button> 
                                    }
                                    </>
                                }
                            </div>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* <!-- /New extra charge modal end --> */}
        </>
    )
}
export default ExtraChargeService;