import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Row, Col, FloatingLabel, Alert, Card, Dropdown } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { NFEservices, postCertificate, NfEdit, getNfService, updateNfService, getCertificate, postCertificate_afterId, postCertificate_data } from "../../../../redux/slice/taxationSlice"
import { useTranslation } from 'react-i18next';
import { handleAction } from "../actions"
import Swal from 'sweetalert2';
import { getAccountCategory } from '../../../../redux/slice/financialDataSlice';
import { config } from '../../../../Api/Contact';
import { Link, useNavigate } from 'react-router-dom';
import forge from 'node-forge';
import Loader from '../Loader';
import { encrypt_Decrypt } from '../../../constants/utils';
import moment from "moment-timezone";
import i18n from '../../../../i18n';

const NFEservice = () => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

    // This line initializes the useDispatch hook and assigns it to the dispatch variable
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(2010) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])
    //When we first have to show the data in the input field
    //Getting the data shown when the page is rendered for the first time --registerData and getbranches
    useEffect(() => {
        dispatch(getNfService());
        dispatch(getAccountCategory());
        dispatch(getCertificate());
    }, [dispatch])

    const [certifiactename, setCertifiacate] = useState<any>("")
    const [passwordcertificate, setpasswordcertificate] = useState<any>("")
    const [show3, setShow3] = useState(false);
    const [expireDate, setExpireDate] = useState('')
    const [expireDate1, setExpireDate1] = useState('')
    const [certific, setCertific] = useState(false)
    // Initialize the useTranslation hook for internationalization
    const [t] = useTranslation();

    // used it to show validation error on form
    const Schema = yup.object().shape({
        environment: yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        accountcategory: yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    })
    
    const Schema1 = yup.object().shape({
        password: yup.string().required(t('requiredMmessage.fieldRequired')),
        certificate: yup.string().required(t('requiredMmessage.fieldRequired'))
    })

    var frm = { resolver: yupResolver(Schema) }
    if (certific == true) {
        var frm = { resolver: yupResolver(Schema1) }
    }
    // Used Redux-Toolkit, Got the data and Destructors the data from taxationSlice inside the redux folder
    // The useForm hook is used to handle form registration, submission, and validation.
    const { accountCatData } = useSelector((state: RootState) => state.financialData);
    const { show2, NFEservice1, status, taxadata, certificate } = useSelector((state: RootState) => state.taxa)
    const { handleSubmit, register, setValue, setError, clearErrors, getValues, reset, formState: { errors } } = useForm<NFEservices>(frm);
    // Replace certificate modal
    const replaceCertificateModalClose = (type) => {
        let name = certifiactename?.["name"] ?? "";
        let password = passwordcertificate
        let selector: any = document.querySelector(".checkCertificate");
        let selectorcertifiacate: any = document.querySelector(".certificateInvalid");
        if (name && name.length > 0) {
            selector.style.border = "none";
            selector.style.borderRadius = "none";
            selectorcertifiacate.setAttribute("hidden", "");
        } else {
            selector.style.border = "1px solid #d32d3b";
            selector.style.borderRadius = "10px";
            selectorcertifiacate.removeAttribute("hidden");
        }

        if (password && password.length > 0) {

        } else {
            setError("password", {
                type: "manual",
                message: `${t("requiredMmessage.fieldRequired")}`
            });
        }

        if (password && password.length > 0 && name && name.length > 0 && type == "submit") {
            setShow3(false);
        }
        if (type !== "submit") {
            selector.style.border = "none";
            selector.style.borderRadius = "none";
            selectorcertifiacate.setAttribute("hidden", "");
            clearErrors("password");
            clearErrors("certificate");
            handleChangeFile("");
            setShow3(false);
        }
        setCertific(false)
    }

    const replaceCertificateModalShow = () => {
        setShow3(true);
        // const fields: string[] = ['password',];
        // fields.forEach((field: any) => setValue(field, NFEservice[0][field]));
    }
    const [FirstLoad, setFirstLoad] = useState(false);
    const [FirstLoad1, setFirstLoad1] = useState(false);
    const [fileName, setFilename] = useState("");
    const [password, setPassword] = useState('');
    const [verificationResult, setVerificationResult] = useState(null);
    const [pfxFile, setPfxFile] = useState(null);
    useEffect(() => {
        if (certifiactename && certifiactename?.["name"] && certifiactename?.["name"].length > 0) {
            setFilename(certifiactename?.["name"]);

        } else {
            setFilename("");
        }
    }, [certifiactename?.["name"]])

    // useEffect(() => {
    //     if (FirstLoad1) {
    //         setTimeout(() => {
    //             if (fileName.length < 1 && show3) {
    //                 let selector: any = document.querySelector(".checkCertificate");
    //                 let selectorcertifiacate: any = document.querySelector(".certificateInvalid");
    //                 selector.style.border = "1px solid #d32d3b";
    //                 selector.style.borderRadius = "10px";
    //                 selectorcertifiacate.removeAttribute("hidden");
    //             }
    //         }, 500)
    //     }
    //     setFirstLoad1(true);
    // }, [show3])

    useEffect(() => {
        if (FirstLoad) {
            let replacehide: any = document.querySelector(".replacehide");
            // if (fileName.length < 1 || passwordcertificate.length < 1) {
            // replacehide.style.border = "1px solid #d32d3b";
            // replacehide.classList.add("errorShowed");
            // } else {
            //     replacehide.style.border = "1px solid #d8d8d8";
            //     replacehide.classList.remove("errorShowed");
            // }
        }
        setFirstLoad(true);
    }, [certifiactename?.["name"], passwordcertificate, errors])


    // Created a use state to conduct environments shows
    const [environments, setEnvironments] = useState([
        { value: '1', label: "homologation" },
        { value: '2', label: "Production" },
    ])

    const [cityName, setCityName] = useState('')
    useEffect(() => {
        const fields: string[] = ['environment', 'accountcategory'];
        if (NFEservice1 && NFEservice1.length > 0) {
            console.log('=====>>>',NFEservice1);
            setCityName(NFEservice1?.[0].city_nfse)
            fields.forEach((field: any) => setValue(field, NFEservice1?.[0]?.[`${field}`]));
        }

    }, [NFEservice1?.[0]])

    const [fileDownloadLink, setFileDownloadLink] = useState("");
    const [fileDownloadName, setFileDownloadName] = useState("");

    function parseDate(dateString) {
        if (dateString && dateString != '') {
            const [datePart, timePart] = dateString ? dateString.split(' ') : {};
            const [day, month, year] = datePart ? datePart.split('/').map(Number) : {};
            const [hours, minutes, seconds] = timePart ? timePart.split(':').map(Number) : {};
            return new Date(year, month - 1, day, hours, minutes, seconds);
        } else {
            return '';
        }
    }

    // Example usage
    useEffect(() => {
        (async () => {
            if (certificate && certificate?.data && certificate?.data?.length > 0) {
                let plugnotaId = certificate.data[0].ST_TOKEN_CERT;
                let respo = await dispatch(postCertificate_data(plugnotaId))
                const certificateDate: any = parseDate(respo.payload.vencimento);
                const currentDate = new Date(dateBrazil);
                // Calculate the difference in months
                if (certificateDate) {
                    const monthsDiff = (certificateDate.getFullYear() - currentDate.getFullYear()) * 12 +
                        (certificateDate.getMonth() - currentDate.getMonth());

                    if (certificateDate < currentDate) {
                        // Certificate has already expired
                        const formattedExpirationDate = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')
                            }/${currentDate.getDate().toString().padStart(2, '0')
                            }/${currentDate.getFullYear()
                            }`;

                        const message = `${t("taxation.certificateexpired")} ${Math.abs(monthsDiff)} ${t("taxation.monthsago")} (${formattedExpirationDate}).`;
                        setExpireDate(message);
                    } else {
                        // Certificate will expire in the future
                        const formattedExpirationDate = `${(certificateDate.getMonth() + 1).toString().padStart(2, '0')
                            }/${certificateDate.getDate().toString().padStart(2, '0')
                            }/${certificateDate.getFullYear()
                            }`;
                        const message = `${t("taxation.currentcertificatewillexpire")} ${monthsDiff} ${t("taxation.monthson")} (${formattedExpirationDate}).`;
                        setExpireDate1(message);

                    }
                }
            }
        })()
    }, [certificate, i18n.language])

    useEffect(() => {
        if (certificate?.["data"]?.[0]?.["TX_ARQPFX_CERT"]) {
            let data = certificate?.["data"]?.[0]?.["TX_ARQPFX_CERT"]["data"];
            let utf8decoder = new TextDecoder();
            let u8arr = new Uint8Array(data);
            let changeIntoString = utf8decoder.decode(u8arr);
            let urlLinkpath = config.companyV2_URL + "" + changeIntoString.replace("public", "");
            setFileDownloadLink(changeIntoString);
            // setCertifiacate(certificate?.["data"])
            setpasswordcertificate(certificate?.["data"]?.[0]?.["ST_AUTH_CERT"])
        } else {
            setFileDownloadLink("");
        }
    }, [certificate, dispatch])

    useEffect(() => {
        if (fileDownloadLink !== "") {
            let getLastFile: any = fileDownloadLink.split("%2F") || 0;
            let getLastFile1: any = getLastFile[1].split("?") || 0; // New added
            // console.log(getLastFile1[0], "This is file"); // New added
            // let firstName = getLastFile[getLastFile.length - 1].split("_")?.[0];
            // let getExtension = getLastFile[getLastFile.length - 1].split(".");
            // let getPathExtens = getExtension[getExtension.length - 1];
            // let fullFileName = firstName + "." + getPathExtens;
            setFileDownloadName(getLastFile1[0]);
        }
    }, [fileDownloadLink, dispatch])

    useEffect(() => {
        if (fileDownloadName !== "") {
            setValue("password", passwordcertificate);
            setValue("certificate", fileDownloadName);
            // setFileDownloadName({
            //     full_url: fileDownloadLink,
            //     full_name: fileDownloadName
            // })
        }
    }, [fileDownloadName])

    // Used to insert edit NFEservice  data and submit
    const onSubmit = async (data: any, e: any) => {
        e.preventDefault();
        let thisDivform: any = e.target.parentElement.parentElement.parentElement.parentElement;
        let thisButton: any = e.target.parentElement.parentElement.parentElement;

        let obj = {
            "environment": data.environment,
            "accountCategory": data.accountcategory
        }
        let respo = await dispatch(updateNfService(obj))
        if (respo?.payload?.type == "Success") {
            Swal.fire({
                // icon: 'success','success',
                title: `${t("Sweetalert.Success")}`,
                text: `${t("Sweetalert.RecordUpdated")}`,
                showConfirmButton: false,
                timer: 1000
            })
            dispatch(getNfService());
            dispatch(getAccountCategory());

        } else {
            Swal.fire({
                // icon: 'success','error',
                title: `${t("Sweetalert.Error")}`,
                text: `${t("Sweetalert.cannotupdated")}`,
                showConfirmButton: true,
            })
        }

        if (e.type == "submit") {
            if (thisDivform.querySelectorAll("#editTaxa")?.length) {
                thisDivform.querySelectorAll("#editTaxa input").forEach((item: any, index: number) => {
                    item.setAttribute('disabled', '');
                })
            }
            if (thisDivform.querySelectorAll("#editTaxa")?.length) {
                thisDivform.querySelectorAll("#editTaxa select").forEach((item: any, index: number) => {
                    item.setAttribute('disabled', '');
                })
            }
            const elements = thisButton.querySelectorAll(".ACTION");
            if (elements) {
                elements.forEach(element => {
                    element.classList.add('d-none');
                });
            }
            const element1 = thisButton.querySelectorAll(".TOEDIT");
            if (element1) {
                element1.forEach(element => {
                    element.classList.remove('d-none');
                });
            }
            let doc: any = document.getElementById("moreCond");
            if (doc) {
                doc.setAttribute("hidden", "true"); // Set the 'hidden' attribute
            } else {
                doc.removeAttribute("hidden"); // Remove the 'hidden' attribute
            }

        }
    }

    const handleEdit = async (e, data) => {
        //console.log(e.target, data);
    }

    // Show in image gate and input field on onchange
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let file: any = e.target.files;
        setCertifiacate(file?.[0])
        let reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                let newImg: any = reader.result;
                setPfxFile(newImg)
                let selector: any = document.querySelector(".checkCertificate");
                let selectorcertifiacate: any = document.querySelector(".certificateInvalid");
                selector.style.border = "none";
                selector.style.borderRadius = "10px";
                selectorcertifiacate.setAttribute("hidden", "");
            }
        }
        reader.readAsDataURL(file?.[0]);
    }
    const handleCancel = (e, type, cmde: any) => {
        let ElementLoop: any = e.target.parentElement.parentElement.parentElement.parentElement.parentElement;

        // console.log(e.target.parentElement.parentElement.parentElement.parentElement.parentElement, "This is targettt");
        let QuerySlelinput: any = ElementLoop.querySelectorAll("input");
        let QuerySlelselect: any = ElementLoop.querySelectorAll("select");
        QuerySlelinput.forEach((item: any, index: number) => {
            item.setAttribute("disabled", "");
        })

        QuerySlelselect.forEach((item: any, index: number) => {
            item.setAttribute("disabled", "");
        })

        ElementLoop.querySelector(".TOEDIT")?.classList.remove('d-none');
        ElementLoop.querySelector(".TOEDIT")?.classList.add('d-block');
        ElementLoop.querySelector(".ACTION")?.classList.remove('d-bock');
        ElementLoop.querySelector(".ACTION")?.classList.add('d-none');
        ElementLoop.querySelector('.replacehide .removeInsertDisable button')?.setAttribute('disabled', "");
        ElementLoop.querySelectorAll(".BtnAction, .delete").forEach((item) => {
            if (item.hasAttribute("hidden")) {
                item.removeAttribute("hidden");
            } else {
                item.setAttribute("hidden", "");
            }
        });// handleAction(e, "cancelSubmit", "nfservi")
        let doc: any = document.getElementById("moreCond");
        if (doc) {
            doc.setAttribute("hidden", "true"); // Set the 'hidden' attribute
        } else {
            doc.removeAttribute("hidden"); // Remove the 'hidden' attribute
        }

        dispatch(getNfService())
    }

    const handleClick = (e) => {
        replaceCertificateModalShow()
        setCertific(true)
    }

    const handleChangeFile = (e) => {
        setCertifiacate("");
        setpasswordcertificate("")
    }

    const handlePasswordChange = (e) => {
        let Value = e.target.value;
        setpasswordcertificate(Value);
        clearErrors("password");
    }

    const uploadNewData = (e) => {
        let onclickData: any = document.querySelector(".changeFileCLick");
        onclickData.click();
    }
    const handleDropdown = (e, type, cmde: any) => {
        var editableElement: any = document.querySelector('.editbale');
        if (editableElement) {

        }
    }

    const onSubmit1 = async (data: any, e: any) => {
        e.preventDefault();
        let bodyContent = new FormData();
        bodyContent.append("arquivo", certifiactename);
        bodyContent.append("senha", passwordcertificate);
        let resp = await dispatch(postCertificate(bodyContent))
        if (resp?.payload?.message == "Cadastro efetuado com sucesso") {
            reset();
            // Show a success message using Swal
            let bodyContent = new FormData();
            bodyContent.append("arquivo", certifiactename);
            bodyContent.append("senha", passwordcertificate);
            bodyContent.append("certificate_id", resp.payload.data.id);
            // bodyContent.append("DT_VALIDADE_CERT", )
            let res = await dispatch(postCertificate_afterId(bodyContent))
            if (res?.payload?.type == "Success") {
                Swal.fire({
                    // icon: 'success','success',
                    title: `${t("Sweetalert.Success")}`,
                    text: `${t("Sweetalert.RecordUpdated")}`,
                    showConfirmButton: false,
                    timer: 1000
                })

            }
            setCertific(false)
            dispatch(getCertificate());
            replaceCertificateModalClose("close")
        } else {
            // Show an error message
            Swal.fire({
                // icon: 'success','error',
                title: `${t("Sweetalert.Error")}`,
                text: resp?.payload?.error?.message,
                showConfirmButton: true,
            })
        }
    }

    return (
        <>
            {/* NF-E service section start from here */}
            <Card>
                <Card.Header className="d-flex align-items-center justify-content-end">
                    <div className="me-auto">{t("taxation.NF_E_service")}</div>
                    {
                        userJson && userJson.length > 0 && (userJson.indexOf(2011) !== -1) &&
                        <>
                            <span className="d-block TOEDIT">
                                <span>
                                    <span>
                                        <Button
                                            variant="link"
                                            className="p-0 fw-bold text-nowrap me-lg-4 me-3"
                                            onClick={(e) =>
                                                handleAction(e, "toedit", "nfeform")
                                            }
                                        >
                                            {t("button.edit")}
                                        </Button>
                                    </span>
                                </span>
                            </span>
                            <div className="d-none ACTION">
                                <Row>
                                    <Col xl={6} sm={4}>
                                        <Button
                                            variant="link"
                                            className="p-0 fw-bold text-nowrap"
                                            onClick={(e) =>
                                                handleCancel(e, "cancel", "nfeform")
                                            }
                                            type="submit"
                                        >
                                            {t("button.cancel")}
                                        </Button>
                                    </Col>

                                    <Col xl={4} sm={4}>
                                        <Button
                                            variant="link"
                                            className="p-0 fw-bold text-nowrap me-lg-4 me-3"
                                            onClick={(e) =>
                                                handleAction(e, "update", "dayay")
                                            }
                                            type="submit"
                                        >
                                            {t("button.toSave")}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    }
                    {
                        userJson && userJson.length > 0 && (userJson.indexOf(2011) !== -1) &&
                        <>
                            <Dropdown id="moreCond" hidden>
                                <Dropdown.Toggle variant="link" className="p-0 fw-bold dropdown-arrow-none editbale" id="dropdown-basic" >
                                    <Link to="#" onClick={(e) =>
                                        handleDropdown(e, "update", "registerData")
                                    }>{t('button.more')}</Link>
                                    <img src="assets/images/icons/arrow-bottom-primary.svg" alt="Plus icon" className="ms-1 h-8" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Button className='w-100 text-dark text-start' variant="link" size="sm" onClick={handleClick} >{t("taxation.replaceCertificate")}</Button>
                                    {/* <Button variant="link" size="sm" className="w-100 text-start text-dark">{t("taxation.information")}</Button> */}
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    }
                </Card.Header>
                <Form onSubmit={handleSubmit(onSubmit)} id="editTaxa" className='nfseservice-wt221'>
                    <Card.Body>
                        <FloatingLabel className={`mb-lg-4 mb-3`} label={t("taxation.Cityhall")} >
                            <Form.Control type="text" value={cityName} placeholder={t("taxation.Cityhall")} disabled />
                        </FloatingLabel>
                        <FloatingLabel className={`mb-lg-4 mb-3 removeInsertDisable`} label={t("formLabel.environment")+ " *"} >
                            <Form.Select id="removeInsertDisable" aria-label="Produção" className={`${errors.environment ? 'is-invalid' : ''}`}  {...register('environment')} disabled>
                                <option value="">{t("formLabel.environment")}</option>
                                {environments && environments.length > 0 &&
                                    environments.map((curElem: any, ind: number) => (
                                        <option key={ind} value={curElem.value}>{t(`formLabel.${curElem.label}`)}</option>
                                    ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors.environment?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <span className="d-block mb-3 fw-bold">{t("taxation.taxesRetentation")}</span>
                        <FloatingLabel label={t("formLabel.accountCategory")+ " *"} className={`mb-lg-4 mb-3 removeInsertDisable`} >
                            <Form.Select className={`form-control ${errors.accountcategory ? 'is-invalid' : ''}`} aria-label="Account (category)" {...register('accountcategory')} disabled>
                                <option value="">{t('dropdownItem.select')}..</option>
                                {
                                    accountCatData && accountCatData.length > 0 &&
                                    accountCatData.map((item, index) => {
                                        if (item?.childData && (item.childData).length > 0) {
                                            return (
                                                <optgroup key={index} label={`${item.ST_CONTA_CONT} ${item.ST_DESCRICAO_CONT}`}>
                                                    {
                                                        item?.childData && (item.childData).length > 0 &&
                                                        item.childData.map((firstItem, index) => {
                                                            if ((firstItem?.childData && firstItem.childData).length > 0) {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <option value={firstItem.ST_CONTA_CONT}>{firstItem.ST_CONTA_CONT} {firstItem.ST_DESCRICAO_CONT}</option>
                                                                        {
                                                                            firstItem.childData && (firstItem.childData).length > 0 &&
                                                                            (firstItem.childData).map((seconditem, index) => {
                                                                                if (seconditem?.childData && (seconditem.childData).length > 0) {
                                                                                    return (
                                                                                        <React.Fragment key={index}>
                                                                                            <option value={seconditem.ST_CONTA_CONT}>{seconditem.ST_CONTA_CONT} {seconditem.ST_DESCRICAO_CONT}</option>
                                                                                            {
                                                                                                seconditem?.childData && (seconditem.childData).length > 0 &&
                                                                                                (seconditem.childData).map((thirditem, index) => {
                                                                                                    if (thirditem?.childData && (thirditem.childData).length > 0) {
                                                                                                        (thirditem.childData).map((fourthitem, index) => {
                                                                                                            return (
                                                                                                                <option key={index} value={fourthitem.ST_CONTA_CONT}>{fourthitem.ST_CONTA_CONT} {fourthitem.ST_DESCRICAO_CONT}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <option key={index} value={thirditem.ST_CONTA_CONT}>{thirditem.ST_CONTA_CONT} {thirditem.ST_DESCRICAO_CONT}</option>
                                                                                                        )
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        </React.Fragment>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <option key={index} value={seconditem.ST_CONTA_CONT}>{seconditem.ST_CONTA_CONT} {seconditem.ST_DESCRICAO_CONT}</option>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </React.Fragment>
                                                                )
                                                            } else {
                                                                return (
                                                                    <option key={index} value={firstItem.ST_CONTA_CONT}>{firstItem.ST_CONTA_CONT} {firstItem.ST_DESCRICAO_CONT}</option>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </optgroup>
                                            )
                                        }
                                    })
                                }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors.accountcategory?.message}</Form.Control.Feedback>
                        </FloatingLabel>

                        {expireDate ?
                            <>
                                <Alert variant="warning" className="mb-lg-4 mb-3"> {expireDate ? `${t("taxation.A1digitalcertificate")} ${expireDate}` : ""} </Alert>
                            </> :

                            expireDate1 ? <Alert className="mb-lg-4 mb-3 alert alert-success"> {expireDate1 ? `${t("taxation.A1digitalcertificate")} ${expireDate1}` : ""} </Alert> : ""

                        }

                        {
                            certifiactename && certifiactename.length === undefined ? <>
                                <div className="border rounded-4 d-flex align-items-center p-3">
                                    <div className="w-100 overflow-hidden">
                                        <div className="text-truncate text-secondary">{certifiactename?.["name"]}</div>
                                    </div>
                                    <svg className="ms-3 ms-lg-4" xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="green">
                                        <path d="M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H10M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19M19 9V10M9 17H11.5M9 13H14M9 9H10M14 21L16.025 20.595C16.2015 20.5597 16.2898 20.542 16.3721 20.5097C16.4452 20.4811 16.5147 20.4439 16.579 20.399C16.6516 20.3484 16.7152 20.2848 16.8426 20.1574L21 16C21.5523 15.4477 21.5523 14.5523 21 14C20.4477 13.4477 19.5523 13.4477 19 14L14.8426 18.1574C14.7152 18.2848 14.6516 18.3484 14.601 18.421C14.5561 18.4853 14.5189 18.5548 14.4903 18.6279C14.458 18.7102 14.4403 18.7985 14.405 18.975L14 21Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>

                            </> :
                                <>
                                    {
                                        fileDownloadName && certifiactename.length !== undefined ?
                                            <React.Fragment>
                                                <Link to={fileDownloadLink} download="file">
                                                    {
                                                        <>
                                                            <div className="border rounded-4 d-flex align-items-center p-3 oooooooooooooooo">
                                                                <div className="w-100 overflow-hidden">
                                                                    <div className="text-truncate text-secondary">{fileDownloadName}</div>
                                                                </div>
                                                                <span className="img-primary cursor-pe icon ms-3 ms-lg-4"><img src="assets/images/icons/download-arrow.svg" alt="exclamation" className="h-20" /></span>
                                                            </div>
                                                        </>
                                                    }
                                                </Link>
                                            </React.Fragment>
                                            :
                                            <>
                                                <span className="nav-icon flex-shrink-0 me-1 cursor-pe" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="80px" height="80px" viewBox="0 0 24 24" fill="red" onClick={handleClick}>
                                                        <path d="M17 19H21M19 17V21M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H12M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19M19 9V12M9 17H12M9 13H15M9 9H10" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                            </>
                                    }

                                </>
                        }

                        {
                            certifiactename?.["name"] &&
                            certifiactename?.["name"].length > 0 &&
                            passwordcertificate &&
                            passwordcertificate.length < 1 &&
                            <div className="passwordMissed" style={{ "color": "#d32d3b", "width": '100%', "marginTop": '0.25rem', "fontSize": '0.875em' }}>{t('requiredMmessage.passwordrequired')}</div>
                        }


                        {errors.certificate ?
                            <div style={{ backgroundColor: "#e27881", padding: "30px", marginTop: "20px", borderRadius: "10px", fontWeight: "700", }}>
                                {errors.certificate?.message}
                            </div> :
                            ""}
                        {
                            userJson && userJson.length > 0 && (userJson.indexOf(2011) !== -1) &&
                            <>
                                <Button className='submitForm' type="submit" variant="primary" hidden>{t("button.toSave")}</Button>
                                <Button className='fillModalwithThisData' onClick={(e) => handleEdit(e, "nfservi")} hidden>{t('button.edit')}</Button>
                            </>
                        }
                    </Card.Body>
                </Form>
            </Card>

            {/* <Certificate /> */}
            <Modal show={show3} onHide={() => replaceCertificateModalClose("close")}>
                <Form onSubmit={handleSubmit(onSubmit1)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("taxation.Sendcertificate")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className='mb-2'>
                                <div className='checkCertificate'>
                                    {
                                        certifiactename && certifiactename.length === undefined ?
                                            <div className="bg-light bg-opacity-50 border-2 border border-dotted py-5 py-xl-6 px-2 position-relative text-center rounded-4">
                                                <h6 className="text-break">
                                                    {certifiactename?.["name"]}
                                                </h6>
                                                <span className='cursor-pe'  >
                                                    <img src="assets/images/icons/delete-danger.svg" alt="star" className="cursor-pe h-26" />
                                                </span>
                                            </ div>
                                            :
                                            <>
                                                <div className="bg-light bg-opacity-50 border-2 border border-dotted py-5 py-xl-6 px-2 position-relative text-center rounded-4 " hidden>
                                                    <input type="file" {...register("certificate")} className={`${errors.password ? 'is-invalid' : ''} ${"position-absolute cursor-pe end-0 start-0 top-0 bottom-0 h-100 w-100 opacity-0 z-index-1 changeFileCLick"}`}
                                                        accept=".pfx" onChange={(e) => handleChange(e)}
                                                    />
                                                    <i className="fa-solid fa-cloud-arrow-up fa-3x text-opacity-25 text-dark"></i>
                                                    <h6 className="text-opacity-50 text-dark fw-bold my-2">{t("taxation.Drag")} &amp; {t("taxation.DropPFXfile")}</h6>
                                                </div>

                                                {
                                                    fileDownloadName && certifiactename && certifiactename.length !== undefined ?
                                                        <React.Fragment>
                                                            <div onClick={(e) => uploadNewData(e)} className='cursor-pe bg-light bg-opacity-50 border-2 border border-dotted py-5 py-xl-6 px-2 position-relative text-center rounded-4 mb-lg-4 mb-3'>
                                                                <Row className='d-flex justify-content-center p-3'>
                                                                    <Col md={12} className='d-flex justify-content-center'>
                                                                        <h3>{t("taxation.UploadedFile")}</h3>
                                                                    </Col>
                                                                    <Col md={12} className='d-flex justify-content-center'>
                                                                        <span>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="80px" height="80px" viewBox="0 0 24 24" fill="green">
                                                                                <path d="M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H10M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19M19 9V10M9 17H11.5M9 13H14M9 9H10M14 21L16.025 20.595C16.2015 20.5597 16.2898 20.542 16.3721 20.5097C16.4452 20.4811 16.5147 20.4439 16.579 20.399C16.6516 20.3484 16.7152 20.2848 16.8426 20.1574L21 16C21.5523 15.4477 21.5523 14.5523 21 14C20.4477 13.4477 19.5523 13.4477 19 14L14.8426 18.1574C14.7152 18.2848 14.6516 18.3484 14.601 18.421C14.5561 18.4853 14.5189 18.5548 14.4903 18.6279C14.458 18.7102 14.4403 18.7985 14.405 18.975L14 21Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </svg>
                                                                        </span>
                                                                        <span className=' align-self-center'>
                                                                            {fileDownloadName}
                                                                        </span>
                                                                    </Col>
                                                                    <Col md={12} className='d-flex justify-content-center'>
                                                                        {t("taxation.Clicktouploadnewfile")}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </React.Fragment>
                                                        :
                                                        <>
                                                            <div className="bg-light bg-opacity-50 border-2 border border-dotted py-5 py-xl-6 px-2 position-relative text-center rounded-4">
                                                                <input type="file" {...register("certificate")} className={`${errors.password ? 'is-invalid' : ''} ${"position-absolute cursor-pe end-0 start-0 top-0 bottom-0 h-100 w-100 opacity-0 z-index-1"}`}
                                                                    accept=".pfx" onChange={(e) => handleChange(e)}
                                                                />
                                                                <i className="fa-solid fa-cloud-arrow-up fa-3x text-opacity-25 text-dark"></i>
                                                                <h6 className="text-opacity-50 text-dark fw-bold my-2">{t("taxation.Drag")} &amp; {t("taxation.DropPFXfile")}</h6>
                                                            </div>
                                                        </>
                                                }
                                            </>
                                    }
                                </div>
                                <div className="certificateInvalid" style={{ "color": "#d32d3b", "width": '100%', "marginTop": '0.25rem', "fontSize": '0.875em' }} hidden>{t('requiredMmessage.fieldRequired')}</div>
                            </div>
                            <FloatingLabel label={t("taxation.SenhadoCertificado")+ " *"} >
                                <Form.Control placeholder={t("taxation.SenhadoCertificado")} className={`${errors?.password ? 'is-invalid' : ''}`} type="password" {...register('password')} onChange={(e) => handlePasswordChange(e)} />
                                <Form.Control.Feedback type="invalid">{errors?.password?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => replaceCertificateModalClose("cancel")}>{t("button.cancel")}</Button>
                        <Button variant="primary" id="certificate" type='submit'>{t("button.save")}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default NFEservice