import { Table, ButtonGroup, FloatingLabel, InputGroup, Dropdown, Col, Row, Modal, Form, Button, Card, Alert, ProgressBar, Tooltip, OverlayTrigger, Collapse } from 'react-bootstrap';
import { transferToPaid, transferToBePaid } from '../../../redux/slice/transferDataSlice';
import { Show_New_expenses } from '../../../redux/slice/toBePaidSlice';
import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import BarcodeExpense from '../Financial/barcode-expense';
import EditExpense from '../Financial/edit-expense';
import ChangeMaturity from '../Financial/change-maturity';
import Liquidate2 from '../Financial/liquidate2';
import LowerLiquid from '../Financial/lowerliquid';
import SelectPayment from '../Financial/select-payment';
import AttachFile from '../Financial/attach-file';
import { New_expenses } from './New_expenses';
import Settle from './Settle';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import Checkbox from '../Contracts/Common/Checkbox';
//import moment from "moment";
import moment from "moment-timezone";
import { useTranslation } from 'react-i18next';
import Loader from '../../Profile/Company/Loader';
import MainLoader from '../../modules/MainLoader';

import Swal from 'sweetalert2';
import Pagination from "../../Pagination";
import { getSettle, getAllSuppliers, setSettleId, settleOpen } from "../../../redux/slice/contractDataSlice";
import '../../../App.css';
import "react-tooltip/dist/react-tooltip.css";
import { converter, encrypt_Decrypt, encrypt_Decrypt_Plan } from '../../constants/utils';
import customerService from '../../../services/customerService';
import transferService from '../../../services/transferService';
import FinancialExpenseService from '../Contracts/Common/FinancialExpenseService';
import { setFinancialExpOpen } from "../../../redux/slice/financialDataSlice";
import { getAllAccounts, getAllBillMethodListing } from "../../../redux/slice/expenditureDataSlice";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Filter_bank } from '../../../redux/slice/bankSlice';

interface Document {
    contract_id: string;
    file_name: string;
    file_size: number;
    send_date: any;
    file_extension: any;
    file_hash: string;
}

const ToBePaid = () => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    const location = useLocation();

    /*----------------Harjinder-----------------*/
    const formRef = useRef(null);
    const { toBePaidtransferData, toBePaidStatus } = useSelector((state: RootState) => state.transferData)
    const { accountData, accountStatus, billingData } = useSelector((state: RootState) => state.expenditureData)
    const { userbanklist } = useSelector((state: RootState) => state.bank)
    const contractValidation = Yup.object().shape({
    });
    let frm = {
        resolver: yupResolver(contractValidation)
    }
    const { register, reset, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors } = useForm<any>(frm);
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    const [toBePiadlist, settoBePiadlist] = useState<any>([])
    const [allSuplier, setAllSuplier] = useState([])
    const [paidDetailState, setPaidDetailState] = useState<any>([]);
    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M');
    let searchDt = moment(newDate)
    let endDt = moment(newDate).add(1, 'M');

    const [selectedFile, setSelectedFile] = useState<File | null>();
    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    const [fullLoader, setfullLoader] = useState('');
    const [bankFilter, setbankFilter] = useState('')
    const [paidwith, setPaidwith] = useState('')
    const [supplier, setSupplier] = useState('')

    let all_flt_data: any = {
        tablename: "banks",
        start: "",
        end: 1000,
        status: 0,
    };

    const [userPlanString, setUserPlanString] = useState("");
    useEffect(() => {
        let userPLAN = encrypt_Decrypt_Plan();
        // setUserPlanString(userPLAN)
        setUserPlanString("300")
    }, [location])


    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(2090) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])

    useEffect(() => {
        dispatch(getSettle(Number(109)))
        dispatch(getAllAccounts({ start: 0, end: 1000 }));
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
        dispatch(Filter_bank(all_flt_data));
    }, [dispatch])


    const getToBePaid = async (postData) => {
        let response = await dispatch(transferToBePaid(postData));
    }
    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(toBePaidtransferData.data.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const noneHandleSelectAll = e => {
        setIsCheckAll(false);
        setIsCheck([]);
    }
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }
        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item: any, index: number) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }
                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })
                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10)
        }
    };

    const [state, setState] = useState({
        contract: "",
        owner: "",
        bankAccount: "",
        paidwith: "",
        supplier: "",
        bankAccountType: "",
        startDate: startDt,
        searchDate: searchDt,
        endDate: endDt,
        start: 0,
        end: 20,
        type: "normal",
        filterby: "",
        typeofexpenses: "",
        from: "tobepaid",
        recurringStatus: "",
        ID_REPASSE_REP: "",
    })
    /*----------Pagination---------*/

    let PageSize = 20;
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        setState({
            ...state,
            ['start']: (currentPage > 1 ? currentPage - 1 : 0) * 20,
            ['end']: 20,
            ['ID_REPASSE_REP']: ""
        })
    }, [currentPage])

    let typingTimer;
    const filterChargeDue = (evt: any, type: any) => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
            const name = evt.target.name;
            const value = evt.target.value;
            setState({
                ...state,
                [name]: value
            })
            if (type == "bankAccount") {
                var selectElement: any = document.querySelector('select[name="bankAccount"]');
                var selectedIndex = selectElement?.selectedIndex;
                var selectedOption = selectElement?.options[selectedIndex];
                // Get the value of the data-action attribute
                var dataAction: any = selectedOption?.getAttribute('label');
                setbankFilter(dataAction)
            } else if (type == "paidwith") {
                var selectElement: any = document.querySelector('select[name="paidwith"]');
                var selectedIndex = selectElement?.selectedIndex;
                var selectedOption = selectElement?.options[selectedIndex];
                // Get the value of the data-action attribute
                var dataAction: any = selectedOption?.getAttribute('label');
                setPaidwith(dataAction)
            } else {

                var selectElement: any = document.querySelector('select[name="supplier"]');
                var selectedIndex = selectElement?.selectedIndex;
                var selectedOption = selectElement?.options[selectedIndex];
                // Get the value of the data-action attribute
                var dataAction: any = selectedOption?.getAttribute('label');
                setSupplier(dataAction)
            }
        }, 800);
    }

    const filterCharge = (dt, tp) => {
        if (typeof tp !== "undefined") {
            let currentDate = moment(dt);
            if (tp == "Subtract") {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            else {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            setState({
                ...state,
                ['startDate']: startDt,
                ['searchDate']: searchDt,
                ['endDate']: endDt,
                ["type"]: "normal",
                ["recurringStatus"]: "",
                ["ID_REPASSE_REP"]: "",
            })
        }
    }
    useEffect(() => {
        getToBePaid(state);
    }, [dispatch, state]);

    useEffect(() => {
        settoBePiadlist(toBePaidtransferData);
    }, [toBePaidtransferData]);


    const toBePaidLiquidate = async () => {
        if (isCheck.length > 0) {
            Swal.fire({
                title: t("Sweetalert.Areyousure"),
                text: t("Sweetalert.Youwontbeabletorevert"),
                // icon: 'success','warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, it!'
            }).then(async (result) => {
                if (result.value) {
                    let id = isCheck;
                    let data = await dispatch(transferToPaid(id));
                    if (data.payload.resCode === 200) {
                        Swal.fire({
                            title: t("Sweetalert.Success"),
                            text: t("Sweetalert.successget"),
                            // icon: 'success',"success",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                        dispatch(transferToBePaid({
                            ...state,
                            endDate: state.endDate?.format('MM/YYYY'),
                            startDate: state.startDate?.format('MM/YYYY'),
                        }));
                    }
                    else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.somethingWent"),
                            // icon: 'success',"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                    }
                }
            })

        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }

    /*---------------END--------------_*/
    //barcode expense modal
    const [showBarcodeExpenseModal, setBarcodeExpenseModal] = useState(false);
    const barcodeExpenseModalClose = () => setBarcodeExpenseModal(false);
    const barcodeExpenseModalShow = () => setBarcodeExpenseModal(true);

    //liquidateModalShow modal
    const [showLowerLiquidModals, setLowerLiquidModals] = useState(false);
    const lowerliquidCloses = () => setLowerLiquidModals(false);
    const lowerLiquidateShows = () => setLowerLiquidModals(true);

    //liquidateModalShow2 modal
    const [showLiquidateModal2, setLiquidateModal2] = useState(false);
    const liquidateModalClose2 = () => setLiquidateModal2(false);
    const liquidateModalShow2 = (id: number) => {
        setLiquidateId(id)
        setLiquidateModal2(true)
    }

    /*---------Settle modal-------*/

    const settleOpened = (id: number) => {
        dispatch(setSettleId(id));
    }

    //Edit expense modal
    const [showEditExpenseModal, setEditExpenseModal] = useState(false);
    const editExpenseModalClose = () => setEditExpenseModal(false);
    const editExpenseModalShow = () => setEditExpenseModal(true);

    //Change expiration modal initialization
    const [showchangeExpirationModal, setchangeExpirationModal] = useState(false);
    const changeExpirationModalClose = () => setchangeExpirationModal(false);
    const changeExpirationModalShow = () => setchangeExpirationModal(true);

    //issue check modal
    const [showIssueCheckModal, setIssueCheckModal] = useState(false);
    const issueCheckModalClose = () => setIssueCheckModal(false);
    const issueCheckModalShow = () => setIssueCheckModal(true);

    //authorization-date modal
    const [showAuthorizationDateModal, setAuthorizationDateModal] = useState(false);
    const authorizationDateModalClose = () => setAuthorizationDateModal(false);
    const authorizationDateModalShow = () => setAuthorizationDateModal(true);

    //Attach file modal
    const [showAttachFileModal, setAttachFileModal] = useState(false);
    const attachFileModalClose = () => setAttachFileModal(false);
    const attachFileModalShow = () => setAttachFileModal(true);

    const HandleEditExpense = (id: number, item: any) => {
        // setEditId(id)
        // dispatch(Show_New_expenses(true))
        dispatch(setFinancialExpOpen(true));
        setPaidDetailState(item); // Set the state synchronously
    }
    const [liquidateId, setLiquidateId] = useState<number>()
    const [show, setShow] = useState(false);
    const filterShow = () => setShow(true);
    const handleMouseEvent = () => setShow(true);
    const [statusFilter, setStatusFilter] = useState(null);
    const [itemsToDelete, setItemsToDelete] = useState<any[]>([]);
    const [checkboxClicked, setCheckboxClicked] = useState(false);
    const [editId, setEditId] = useState<Number>()
    const [selectAll, setSelectAll] = useState(false);
    const [showperiodfilter, setShowPeriodFilter] = useState(false)
    const handleDelete = () => {
        if (isCheck.length) {
            Swal.fire({
                title: t("Sweetalert.Areyousure"),
                text: t("chartofaccount.deleteTobePaid"),
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t("Sweetalert.yesDeleteIt"),
                cancelButtonText: t("button.cancel")
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let response: any = await transferService.toBePaidDelete({ id: isCheck });
                    if (response.data.resCode == 408) {
                        getToBePaid(state);
                        Swal.fire({
                            title: t("Sweetalert.Success"),
                            text: t("Sweetalert.deleteRecord"),
                            confirmButtonText: "OK",
                            timer: 2000,
                        });
                    }
                    else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: response.data.message,
                            confirmButtonText: "OK",
                            timer: 2000,
                        });
                    }
                }
            })
        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                confirmButtonText: "OK",
                showCloseButton: true,
                timer: 2000,
            });
        }
    };
    const handleStatusFilter = (status) => {
        setStatusFilter(status);
    }
    const handleDeselectAll = () => {
        setSelectAll(false);
        setCheckboxClicked(false);
        // setExpensesList((prevList) => {
        //     return prevList.map((curEle) => {
        //         return { ...curEle, checkbox: false };
        //     });
        // });
    };
    const filterClose = () => {
        setShow(false);
    }
    const onClose = () => {
        setShow(false);
    }
    const newExpenseModalShow = () => {
        /*-----_Harjinder_05122023-----*/
        dispatch(setFinancialExpOpen(true));
        setPaidDetailState({});
        //dispatch(Show_New_expenses(true))
    };
    useEffect(() => {
        (async () => {
            let allSuplier = await dispatch(getAllSuppliers())
            if (allSuplier.payload.resCode == 201) {
                setAllSuplier(allSuplier.payload.data)
            }
        })()
    }, [dispatch])
    const typeOfExpeneses = (e: any) => {
        const val = e.target.value;
        const name = e.target.name
        setState({
            ...state,
            [name]: val

        })
        let data_array: any = [];
        if (val === "all") {
            // If "all" is selected, reset to the original data.
            if (toBePaidtransferData && toBePaidtransferData?.data && toBePaidtransferData?.data?.length > 0) {
                data_array = toBePaidtransferData?.data;
                getToBePaid(state);
            }
        } else if (val === "splitTransfers") {
            if (toBePaidtransferData && toBePaidtransferData?.data && toBePaidtransferData?.data?.length > 0) {
                // If "splitTransfers" is selected, filter the data where owner length is greater than 1.
                data_array = toBePaidtransferData?.data?.filter((element: any) => element.owner.length > 1);
            }
        } else if (val === "currentAccount") {
            if (toBePaidtransferData && toBePaidtransferData?.data && toBePaidtransferData?.data?.length > 0) {
                for (const element of toBePaidtransferData?.data || []) {
                    for (const ele of element.owner) {
                        if (ele.aacountType === 1) {
                            data_array.push(element);
                        }
                    }
                }
            }
        } else if (val === "savingAccount") {
            if (toBePaidtransferData && toBePaidtransferData?.data && toBePaidtransferData?.data?.length > 0) {
                for (const element of toBePaidtransferData?.data || []) {
                    for (const ele of element.owner) {
                        if (ele.aacountType === 2) {
                            data_array.push(element);
                        }
                    }
                }
            }
        } else {
            // Handle unexpected filter values here, e.g., set data_array to the original data.
            if (toBePaidtransferData && toBePaidtransferData?.data && toBePaidtransferData?.data?.length > 0) {
                data_array = toBePaidtransferData?.data;
            }
        }
        // Update the state with the filtered data.
        settoBePiadlist({ data: data_array })
    };
    // Filters section  
    const [openFilters, setFiltersOpen] = useState(false);
    const [documentType, setDocumentType] = useState<any>('');
    const [allFiles, setAllFiles] = useState<any>('');
    const [viewFiles, setviewFiles] = useState(false);
    const [gettingFiles, setgettingFiles] = useState('');
    const viewFilesClose = () => {
        setviewFiles(false);
    }
    const handleUpload = async (type, id_etiqueta_eti, item) => {
        setviewFiles(true);
        if (type == 'newFile') {
            let AddDoc: any = document.querySelector('.attachedDocument');
            AddDoc.click();
        } else {
            setAllFiles('');
            setgettingFiles('loading');
            setDocumentType('');
            let data = { "id": item?.id, "id_etiqueta_eti": id_etiqueta_eti, type: "" }
            let data1 = { "ID_CONTRATO_CON": item.contractId, "id_etiqueta_eti": id_etiqueta_eti, type: type, item: item }
            setDocumentType(data1);
            const response = await customerService.gettoBePaidDocuments(data);
            if (response.data.resCode === 201) {
                setAllFiles(response.data.data);
                setgettingFiles('');
            }
            else {
                setgettingFiles('');
            }
        }
    };
    const handleFileUpload = async (event) => {
        setgettingFiles('loading');
        event.preventDefault();
        const data = new FormData();
        if (selectedFile && selectedFile.name) {
            data.append('image', selectedFile, selectedFile?.name);
            data.append('contractId', documentType.ID_CONTRATO_CON);
            data.append('ID_ETIQUETA_ETI', documentType.id_etiqueta_eti);
            data.append('ID_REPASSE', documentType?.item?.id);

            setgettingFiles('loading');
            const response = await customerService.uploadTobepaidDocuments(data);
            if (typeof response !== "undefined" && response.data.resCode === 202) {
                Swal.fire({
                    // icon: 'success','success',
                    title: t("Sweetalert.Success"),
                    text: `File uploaded successfully!`,
                    confirmButtonText: "OK",
                    timer: 2000
                })
                setfullLoader('');
                setSelectedFile(undefined);
                document.querySelector(`#${documentType.type}${documentType.item.id}`)?.classList.remove('text-primary');
                document.querySelector(`#${documentType.type}${documentType.item.id}`)?.classList.add('text-primary');
                const elementSelector = `#${documentType?.type}${documentType.item.id}`;
                const aadimg = document.querySelector(elementSelector);
                if (aadimg) {
                    // Select the inserted image
                    const insertedImage = aadimg.querySelector('img');
                    // Check if the image exists and remove it
                    if (insertedImage) {
                        insertedImage.remove();
                    }
                    // Insert the new image element at the beginning
                    aadimg.insertAdjacentHTML('afterbegin', '<img class="Checks h-20 me-1" src="./assets/images/icons/circle-check-primary.svg" />');
                }
                setAllFiles('');
                let data = { "id": documentType?.item?.id, "id_etiqueta_eti": documentType?.id_etiqueta_eti, type: "" }
                const Filresponse = await customerService.gettoBePaidDocuments(data);
                if (Filresponse.data.resCode === 201) {
                    setAllFiles(Filresponse.data.data); setgettingFiles('');
                }
            }
            else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                })
                setfullLoader('');
            }
        } else { setfullLoader(''); }
    };
    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(undefined);
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            const maxAllowedSize = 5 * 1024 * 1024;
            if (file.size > maxAllowedSize) {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.5mb") + ' 5MB',
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 3000
                })
                event.target.value = '';
            } else {
                setfullLoader('loading');
                setSelectedFile(file);
                let AddDoc: any = document.querySelector('.uploadFilenow');
                setTimeout(() => {
                    AddDoc.click();
                }, 2000)
            }
        } else {
            // setOpenOnce(true);
        }
    };

    const handleImageDelete = async (e, item) => {
        setfullLoader('');
        let data = {
            ID_CONTRATO_CON: documentType.ID_CONTRATO_CON,
            ID_ARQUIVO_ARQ: item.ID_ARQUIVO_ARQ
        }
        await customerService.deleteTobepaidDocuments(data);
        setAllFiles('');
        setgettingFiles('loading');
        let data1 = { "id": documentType?.item?.id, "id_etiqueta_eti": documentType?.id_etiqueta_eti, type: "" }
        const Filresponse = await customerService.gettoBePaidDocuments(data1);
        setgettingFiles('');
        if (Filresponse.data.resCode === 201) {
            setAllFiles(Filresponse.data.data); setgettingFiles('');
        } else {
            document.querySelector(`#${documentType.type}${documentType.item.id}`)?.classList.add('text-primary');
            document.querySelector(`#${documentType.type}${documentType.item.id}`)?.classList.remove('text-primary');
            const elementSelector = `#${documentType?.type}${documentType.item.id}`;
            const aadimg: any = document.querySelector(elementSelector);
            if (aadimg) {
                // Select the inserted image
                const insertedImage = aadimg.querySelector('img');
                // Check if the image exists and remove it
                if (insertedImage) {
                    insertedImage.remove();
                }
                // Insert the new image element at the beginning
                aadimg.insertAdjacentHTML('afterbegin', '<img  src="./assets/images/icons/plus.svg" />');
            }
        }
        setfullLoader('');
    }

    const handleShow = () => {
        setShowPeriodFilter(true)
    }
    const peridfilterClose = () => {
        setShowPeriodFilter(false)
        reset()
    }
    const startDate = () => {
        let today: any = "";
        if (getValues("startDate")) {
            today = getValues("startDate");
        } else {
            today = new Date(dateBrazil).toISOString().split('T')[0];
        }
        return today
    }

    const dateSubmit = (data) => {
        let startDt = moment(data.startDate);
        let endDt = moment(data.endDate);
        setState({
            ...state,
            ['startDate']: startDt,
            ['endDate']: endDt,
            ["type"]: "periodic",
            ["recurringStatus"]: "",
            ["ID_REPASSE_REP"]: "",
        })
        setShowPeriodFilter(false)
        reset();
    }
    const FilterBy = (e: any) => {
        let value = e.target.value
        setState({
            ...state,
            ['filterby']: value,

        })
    }
    const filterReset = () => {
        let setvalue: any = document.querySelector("#status");
        if (setvalue) {
            setvalue.value = ""
        }
        let setvalue1: any = document.querySelector("#typeofexpanse");
        if (setvalue1) {
            setvalue1.value = ""
        }
        let bakAccount: any = document.querySelector("#bakAccount");
        if (bakAccount) {
            bakAccount.value = ""
        }
        let paidwith: any = document.querySelector("#paidwith");
        if (paidwith) {
            paidwith.value = ""
        }
        let supplier: any = document.querySelector("#supplier");
        if (supplier) {
            supplier.value = "All"
        }
        let bankAccountType: any = document.querySelector("#bankAccountType");
        if (bankAccountType) {
            bankAccountType.value = "all"
        }
        setState({
            contract: "",
            owner: "",
            bankAccount: "",
            paidwith: "",
            supplier: "",
            bankAccountType: "",
            startDate: startDt,
            searchDate: searchDt,
            endDate: endDt,
            start: 0,
            end: 20,
            type: "normal",
            filterby: "",
            typeofexpenses: "",
            from: "tobepaid",
            recurringStatus: "",
            ID_REPASSE_REP: "",
        })
        setbankFilter('')
        setSupplier('')
    }
    useEffect(() => {
        (async () => {
            let docid = [
                { id: 1, "type": "dpay" },
                { id: 2, "type": "dosp" },
                { id: 3, "type": "proof" },
                { id: 4, "type": "other" }
            ]
            let newData: any = []
            toBePiadlist?.data?.forEach((item) => {
                newData.push(item.id)
            })
            if (newData.length > 0) {
                const response = await customerService.gettoBePaidDocuments({ id: newData, type: "tobepaid" });
                if (response.data.resCode === 201) {
                    response?.data?.data?.forEach((item) => {
                        docid?.forEach(async (element: any) => {

                            document.querySelector(`.${element?.type}${item?.ID_ETIQUETA_ETI}${item?.ID_RESPONSAVEL_ARQ}`)?.classList.remove('text-primary')
                            document.querySelector(`.${element?.type}${item?.ID_ETIQUETA_ETI}${item?.ID_RESPONSAVEL_ARQ}`)?.classList.add('text-primary');
                            const elementSelector = `.${element?.type}${item?.ID_ETIQUETA_ETI}${item?.ID_RESPONSAVEL_ARQ}`;
                            const aadimg = document.querySelector(elementSelector);
                            if (aadimg) {
                                // Select the inserted image
                                const insertedImage = aadimg.querySelector('img');
                                // Check if the image exists and remove it
                                if (insertedImage) {
                                    insertedImage.remove();
                                }
                                // Insert the new image element at the beginning
                                aadimg.insertAdjacentHTML('afterbegin', '<img class="Checks h-20 me-1" src="./assets/images/icons/circle-check-primary.svg" />');
                            }
                        })
                    });
                }
            }
        })()

    }, [toBePiadlist?.data])

    useEffect(() => {
        setTimeout(() => {
            if (location.state && location?.state?.dashboard == "yes") {
                state["filteSearchDate"] = location.state["searchFrom"];
                state["filterEndDate"] = location.state["searchFrom"];
                state["type"] = "periodic";
                setState({
                    ...state,
                    startDate: location.state["searchFrom"],
                    endDate: location.state["endFrom"],
                    type: "periodic",
                })
            }
            if (location.state && typeof location.state.ID_REPASSE_REP !== "undefined" && location.state.ID_REPASSE_REP != null) {
                setState({
                    ...state,
                    ["ID_REPASSE_REP"]: location.state.ID_REPASSE_REP
                });
            }
        }, 500)
    }, [])

    const getRecurringRecord = async (evt) => {
        setState({
            ...state,
            ["recurringStatus"]: "1",
            ["ID_REPASSE_REP"]: "",
        })
    }

    const handleReceived = (e) => {
        e.preventDefault();
        if (toBePaidtransferData && toBePaidtransferData?.totalRow?.unPaidRece_Id &&
            toBePaidtransferData?.totalRow?.unPaidRece_Id.length > 0) {
            let receivedId: any = [];
            toBePaidtransferData.totalRow.unPaidRece_Id.forEach((item: any) => {
                receivedId.push(item.ID_RECEBIMENTO_RECB)
            })
            document.querySelector("body")?.setAttribute("tobepaid_received", JSON.stringify(receivedId));
            navigate("/received")
        }
    }
    return (
        <section className="content-wrapper content-wrapper-primary">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-between me-xl-1">
                                {
                                    state.type == "normal" ?
                                        <>
                                            <h1 className="mb-0 lh-base text-truncate">{t("expenses")} {t("in")} {moment(state.searchDate).format("MMM/YYYY")}</h1>
                                        </>
                                        :
                                        <>
                                            <h1 className="mb-0 lh-base text-truncate">{t("expenses")} {t("in")} {moment(state.startDate).format("MMM/YYYY")} - {moment(state.endDate).format("MMM/YYYY")}</h1>
                                        </>
                                }
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            {/* Search bar */}
                            <InputGroup>
                                <Form.Control onChange={e => filterChargeDue(e, '')} name="contract" placeholder={t("formLabel.typeSearch")} aria-label="Search" aria-describedby="search_icon" />
                                <InputGroup.Text id="search_icon">
                                    <img src="./assets/images/icons/search.svg" alt="Search icon" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                {
                                    userJson && userJson.length > 0 && (userJson.indexOf(3041) !== -1) &&
                                    <li className="list-inline-item">
                                        {/* <Dropdown> */}
                                        <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="dropdownMenuButton1" onClick={newExpenseModalShow}>
                                            <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />
                                            {t("expenditure.newExpense")}
                                        </Dropdown.Toggle>
                                        {/* <Dropdown.Menu>
                                                <Dropdown.Item onClick={newExpenseModalShow}>{t("expenditure.newExpense")}</Dropdown.Item> */}
                                        {/*<Dropdown.Item href="/">{t("paidTobepaid.Variousexpenses")}</Dropdown.Item>
                                            <Dropdown.Item onClick={barcodeExpenseModalShow}>{t("withBarcode")}</Dropdown.Item>
                                            <BarcodeExpense showBarcodeExpense={showBarcodeExpenseModal} barcodeExpenseClose={barcodeExpenseModalClose} />
                                            <Dropdown.Item href="/">{t("paidTobepaid.Tributewithbarcode")}</Dropdown.Item>
                                            <Dropdown.Item href="/">{t("paidTobepaid.ImportNFe(xml)")}</Dropdown.Item>*/}
                                        {/* </Dropdown.Menu>
                                        </Dropdown> */}
                                    </li>
                                }
                                {/*<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="dropdownMenuButton1">
                                            <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />
                                            {t("paidTobepaid.Processing")}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="/">{t("paidTobepaid.Markallforprocessing")}</Dropdown.Item>
                                            <Dropdown.Item href="/">{t("paidTobepaid.Unmarkallforprocessing")}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a role="button" className="fw-bold">
                                        <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />
                                        {t("paidTobepaid.Processreturn")}
                                    </a>
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a onClick={handleMouseEvent} className="text-primary fw-bold">
                                        <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />
                                        {t("more")}
                                    </a>
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a href="#">
                                        <img src="assets/images/icons/settings-light.svg" alt="Settings" className="h-20" />
                                    </a>
                                </li>
                                <li className="list-inline-item mb-md-0 mb-1">
                                    <a href="#">
                                        <img src="assets/images/icons/question_icon.svg" alt="Question icon" className="h-20" />
                                    </a>
                                </li>*/}
                            </ul>
                        </Col>
                    </Row>
                </div>
                {/* Filters start here */}
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">Filters</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                            <Col xl="auto">
                                {state.type == "normal" ?
                                    <>
                                        <ButtonGroup className="btn-date-filter" size="sm" aria-label="Date">
                                            <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.startDate, 'Subtract')}>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white.svg"} className="h-24" />
                                                {/* {state.startDate} */}
                                            </Button>
                                            <Button variant="primary" className="btn-date capitalize" disabled>
                                                {moment(state.searchDate).format("MMM/YYYY")}
                                            </Button>
                                            <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.endDate, 'Add')}>
                                                {/* {state.endDate} */}
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white-right.svg"} className="h-24" />
                                            </Button>
                                        </ButtonGroup>
                                    </> :
                                    <>
                                        <Button variant="primary" className="btn-date" disabled>
                                            {moment(state.startDate).format("MMM/YYYY")} - {moment(state.endDate).format("MMM/YYYY")}
                                        </Button>
                                        &nbsp;
                                        <Button variant="primary" className="btn-date" onClick={() => filterCharge(state.searchDate, 'Add')}>
                                            {/* {state.endDate} */}
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/refresh.svg"} className="h-24" />
                                        </Button>
                                    </>}
                            </Col>
                            <Col xl="auto">
                                {/* <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`${state?.["filterby"] ? "show" : ""}`}>
                                        {state?.["filterby"] == "all" ? t("TransferPage.banktransactions") : state?.["filterby"] == "receipts" ? t("TransferPage.receipts") : state?.["filterby"] == "payments" ? t("TransferPage.payments") : t("TransferPage.Filterby")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("TransferPage.Filterby")}>
                                            <Form.Select aria-label="Filter by" onChange={FilterBy} id="status">
                                                <option value="">{t('dropdownItem.select')}..</option>
                                                <option value="all">{t("TransferPage.banktransactions")}</option>
                                                <option value="receipts">{t("TransferPage.receipts")}</option>
                                                <option value="payments">{t("TransferPage.payments")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                            </Col>
                            {/* <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm">
                                    {t("dropdownItem.status")}
                                </Dropdown.Toggle>
                                 <Dropdown.Menu className="px-4">
                                <FloatingLabel label="Status">
                                                <Form.Select aria-label="Status" name="status" onChange={filterChargeDue}>
                                                    <option value="All">{t("all")}</option>
                                                    <option value="pending">{t("pending")}</option>
                                                    <option value="settled">{t("settled")}</option>
                                                    <option value="checkstoclear">{t("checkstoclear")}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col> */}
                            {/* <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state?.["typeofexpenses"] ? "show" : ""}>
                                        {state?.["typeofexpenses"] == "all" ? t("formLabel.all") : state?.["typeofexpenses"] == "splitTransfers" ? t("formLabel.splitTransfers") : t("dropdownItem.typeOfExpenses")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("dropdownItem.typeOfExpenses")}>
                                            <Form.Select aria-label="Cities" name="typeofexpenses" onChange={typeOfExpeneses} id="typeofexpanse">
                                                <option value="">{t("formLabel.select")}</option>
                                                <option value="all">{t("formLabel.all")}</option>
                                                <option value="splitTransfers">{t("formLabel.splitTransfers")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col> */}
                            {/* <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm">
                                {t("paidTobepaid.Processingstatus")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdwon-lg px-4">
                                            <FloatingLabel label={t("paidTobepaid.Processingstatus")}>
                                                <Form.Select aria-label="Processing status">
                                                    <option value="">{t("formLabel.all")}</option>
                                                    <option value="">{t("paidTobepaid.Unmarked")}</option>
                                                    <option value="">{t("marked")}</option>
                                                    <option value="">{t("paidTobepaid.Sent")}</option>
                                                    <option value="">{t("paidTobepaid.Scheduled")}</option>
                                                    <option value="">{t("paidTobepaid.Witherror")}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Dropdown.Menu>
                            </Dropdown>
                        </Col> */}
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={bankFilter ? "show" : ""}>{bankFilter ? bankFilter : t("banks.bankAccounts")}</Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("bankAccount")}>
                                            <Form.Select aria-label="Bank account" name="bankAccount" onChange={(e) => filterChargeDue(e, "bankAccount")} id="bakAccount">
                                                <option value="">{t("formLabel.all")}..</option>
                                                {/* {accountData.data && accountData.data.length > 0
                                                    ? accountData.data.map((item: any, index: number) => (
                                                        <option key={index} value={Number(item.id)} label={item.accountRef}>
                                                            {item.accountRef}
                                                        </option>
                                                    ))
                                                    : ""} */}

                                                {userbanklist && userbanklist.length > 0
                                                    ? userbanklist.map((item: any, index: number) => (
                                                        <option key={index} value={Number(item.id)} label={item.nameReference}>
                                                            {item.nameReference}
                                                        </option>
                                                    ))
                                                    : ""}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state?.["paidwith"] ? "show" : ""}>
                                        {paidwith ? paidwith : t("paidTobepaid.Paidwith")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4" >
                                        <FloatingLabel label={t("paidTobepaid.Paidwith")} >
                                            <Form.Select aria-label="paidwith" name="paidwith" onChange={(e) => filterChargeDue(e, "paidwith")} id="paidwith">
                                                <option value="">{t('dropdownItem.select')}..</option>
                                                {
                                                    billingData.data && billingData.data.length > 0 ? billingData.data.map((item: any, index: number) => (
                                                        <option key={index} value={Number(item.id)} label={item.name}>{item.name}</option>
                                                    ))
                                                        : ''
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/*  */}
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state?.["supplier"] ? "show" : ""}>
                                        {supplier ? supplier : t("formLabel.Supplier")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("formLabel.Supplier")} >
                                            <Form.Select aria-label="Supplier" name="supplier" onChange={(e) => filterChargeDue(e, "supplier")} id="supplier">
                                                <option value="All">{t("formLabel.all")}..</option>
                                                {allSuplier && allSuplier.length > 0
                                                    ? allSuplier.map((item: any, index) => (
                                                        <option key={index} value={Number(item?.id)} label={item?.name}>
                                                            {item?.name}
                                                        </option>
                                                    ))
                                                    : ""}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            {/* <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state?.["bankAccountType"] ? "show" : ""}>
                                        {state?.["bankAccountType"] == "all" ? t("All") : state?.["bankAccountType"] == "currentAccount" ? t("currentAccount") : state?.["bankAccountType"] == "savingAccount" ? t("SavingAccount") : t("dropdownItem.bankAccountType")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("dropdownItem.bankAccountType")}>
                                            <Form.Select aria-label="Cities" name="bankAccountType" onChange={typeOfExpeneses} id="bankAccountType">
                                                <option value="all">{t("All")}</option>
                                                <option value="currentAccount">{t("currentAccount")}</option>
                                                <option value="savingAccount">{t("SavingAccount")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col> */}
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state?.["type"] == "periodic" ? "show" : ""} onClick={handleShow}>
                                        {t("periodfilter")}
                                    </Dropdown.Toggle>
                                </Dropdown>
                            </Col>
                            {state?.["typeofexpenses"] || bankFilter || state?.["paidwith"] || state?.["supplier"] || state?.["bankAccountType"] || state?.["type"] == "periodic" ? <Col xl="auto">
                                <Dropdown autoClose="outside" className="moreFilter">
                                    <Dropdown.Toggle variant="light" size="sm" className="d-flex align-items-center" onClick={filterReset}>
                                        {t("button.resetfilter")}
                                    </Dropdown.Toggle>
                                </Dropdown>

                            </Col> :
                                ""}
                        </Row>
                    </Form>
                </Collapse>
                {/* /Filters end */}
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {toBePaidtransferData.totalRow ? toBePaidtransferData.totalRow.recurringCount ?
                    <Alert className="mb-lg-4 mb-3" variant="warning" onClose={() => setShow(false)} dismissible>
                        {t("paidTobepaid.Youhave")} {toBePaidtransferData.totalRow ? toBePaidtransferData.totalRow.recurringCount : 0} {t("paidTobepaid.recurringexpensestopost")}. <Link to={'#'} className="fw-bold" onClick={(e) => getRecurringRecord(3)}>{t("paidTobepaid.Clickheretolaunch")}</Link>
                    </Alert>
                    :
                    ''
                    : ''
                }
                {/* <!-- overview -->*/}
                <Card className="mb-lg-4 mb-3">
                    <Card.Header>{t("ReceiveandReceived.Summaryof")} {state.type == "normal" ? <span className="capitalize">{state.searchDate.format('MMM/YYYY')}</span> : `${moment(state.startDate).format("MMM/YYYY")} - ${moment(state.endDate).format("MMM/YYYY")}`}</Card.Header>
                    <Card.Body>
                        <Row className="gy-3 gx-xxl-5 gx-md-4">
                            <Col sm={4} className="border-end border-light border-xs-none">
                                <span className="d-block text-dark h3 lh-1 mb-2">R$ {toBePaidtransferData.totalRow ? converter(toBePaidtransferData.totalRow.unPaidAmount) : 0}</span>
                                <span className="text-secondary d-block"> <Link to={"/received"} onClick={handleReceived}>{toBePaidtransferData.totalRow ? toBePaidtransferData.totalRow.unPaidCount : 0} {t("paidTobepaid.chargespaid")}</Link></span>
                            </Col>
                            <Col sm={4} className="border-end border-light border-xs-none">
                                <span className="d-block text-dark h3 lh-1 mb-2">R$ {toBePaidtransferData.totalRow ? converter(`${toBePaidtransferData.totalRow.paidAmount}`) : 0} </span>
                                <span className="text-secondary d-block">{toBePaidtransferData.totalRow ? toBePaidtransferData.totalRow.paidCount : 0} {t("paidTobepaid.collectionsreceivable")}</span>
                            </Col>
                            <Col sm={4}>
                                <span className="d-block text-dark h3 lh-1 mb-2"><img src="assets/images/icons/eye_close.svg" alt="Dollar" className="me-1 h-20" />{toBePaidtransferData.totalRow ? toBePaidtransferData.totalRow.unPaidCount : 0}</span>
                                <span className="text-secondary d-block">{t("paidTobepaid.collectionsreceivable")}</span>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {/* <!-- /overview -->*/}
                {/*<!-- Table start from here -->*/}
                {
                    (toBePaidStatus == "loading") ?
                        <Loader />
                        :
                        <Card>
                            <Card.Body className="pb-1">
                                <Table responsive className="table-custom table-custom-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th className="w-24">
                                                {
                                                    toBePiadlist.data && toBePiadlist.data.length > 0 ?
                                                        <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                                                        : ''
                                                }
                                            </th>
                                            <th>{t("transfer_forecast")}</th>
                                            <th>{t("Links.history")}</th>
                                            <th>{t("formLabel.value")}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {toBePiadlist.data && toBePiadlist.data.length > 0 ?
                                            toBePiadlist.data.map((item: any, index: number) => {
                                                let result: any = billingData?.data?.find(items => items.id == item.ID_FORMAPAGAMENTO_RECB);
                                                return (
                                                    <tr key={index}>
                                                        <td className={"check_Checkbox"}>
                                                            <Checkbox key={item.id} type="checkbox" name={item.id} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)} />
                                                        </td>
                                                        <td className="text-dark-70">
                                                            {item.recMaturity2}
                                                            {
                                                                item.FL_STATUS_REP == 2 ?
                                                                    <span className="d-block text-primary fw-bold">{t("paidTobepaid.Reversed")}</span>
                                                                    : ''
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                item.types ?
                                                                    <span className="text-dark-70">
                                                                        <span className="text-dark-70">
                                                                            {t(`paidTobepaid.${item.types}`)}  {Array.isArray(item?.category) && item?.category.length > 0 ? " - " + item?.category[0]?.ST_DESCRICAO_CONT : ""}
                                                                        </span>
                                                                        <br />

                                                                        <span className="ps-1">
                                                                            {t("paidTobepaid.Expense")} {item.recId ? item.recId : item.id} {t("ReceiveandReceived.with")} {result?.name}
                                                                        </span>
                                                                    </span>
                                                                    :
                                                                    <>
                                                                        <span className="text-dark-70">
                                                                            {
                                                                                item.contractId !== null ? item.contractId && <Link to={"/contract_details/" + item.contractId}> <span className="text-dark-70">{t("Dashboard.Contract")} {item.propertIndentifier}/ {item.lease_count ? item.lease_count : ""} </span> </Link>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                        </span>
                                                                        <span className="mx-1">
                                                                            <img src="assets/images/icons/home_g.svg" />
                                                                            <span className="ps-1">
                                                                                {item.propertyType ?
                                                                                    <Link className="text-dark-70" to={"/property_detail/" + item.propertyId} target="_blank">
                                                                                        {item.propertyType + ' : '}
                                                                                    </Link>
                                                                                    : ''
                                                                                }
                                                                                {item.address ? item.address + ' - ' : ''}
                                                                                {item.number ? item.number + ', ' : ''}
                                                                                {item.zipcode ? 'CEP: ' + item.zipcode : ''}
                                                                            </span>
                                                                            <br />
                                                                            <span className="ps-1">
                                                                                {t("paidTobepaid.Expense")} {item.recId ? item.recId : item.id} {t("ReceiveandReceived.with")} {result?.name}
                                                                            </span>
                                                                        </span>
                                                                    </>
                                                            }
                                                            <span className="d-block">
                                                                {
                                                                    item.owner.length > 0 ? item.owner.map((items, indx) => (
                                                                        <span key={indx}>
                                                                            &nbsp;
                                                                            <img src="assets/images/icons/user_g.svg" />
                                                                            <span className="ps-1">
                                                                                {`${items.name}${items.cnpj ? ",  CNPJ : " + items.cnpj : ''}`}
                                                                            </span>
                                                                        </span>
                                                                    ))
                                                                        : ''
                                                                }
                                                                <span className="ps-1">
                                                                    {item.credit != 3 && item.owner.length > 0 ? t(`Dashboard.${item.owner[0].type}`) : ''}
                                                                </span>
                                                            </span>
                                                            {
                                                                userPlanString !== "100" && userPlanString !== "50" && userPlanString !== "200" &&
                                                                <>
                                                                    <div className="mt-1" id="uploadimg">
                                                                        <span className={(item.ID_ETIQUETA_ETI && item.ID_ETIQUETA_ETI.indexOf('1') >= 0) ? `${'dpay1' + item.id} text-primary me-2 me-xxl-4 me-lg-3 cursor-pe fw-bold` : `${'dpay1' + item.id} text-primary me-2 me-xxl-4 me-lg-3 cursor-pe fw-bold`} id={`dpay` + item.id} onClick={() => handleUpload('dpay', 1, item)}> <img src="assets/images/icons/plus-light.svg" className="me-1 cursor-pe" />{t("paidTobepaid.Documentpayment")}</span>
                                                                        <span className={(item.ID_ETIQUETA_ETI && item.ID_ETIQUETA_ETI.indexOf('2') >= 0) ? ` ${'dpay2' + item.id} text-primary me-2 me-xxl-4 me-lg-3 cursor-pe fw-bold` : `${'dpay2' + item.id} text-primary me-2 me-xxl-4 me-lg-3 cursor-pe fw-bold`} id={`dosp` + item.id} onClick={() => handleUpload('dosp', 2, item)}><img src="assets/images/icons/plus-light.svg" className="me-1 cursor-pe" /> {t("paidTobepaid.Documentsupervisior")}</span>
                                                                        <span className={(item.ID_ETIQUETA_ETI && item.ID_ETIQUETA_ETI.indexOf('3') >= 0) ? `${'dpay3' + item.id} text-primary me-2 me-xxl-4 me-lg-3 cursor-pe fw-bold` : `${'dpay3' + item.id} text-primary me-2 me-xxl-4 me-lg-3 cursor-pe fw-bold`} id={`proof` + item.id} onClick={() => handleUpload('proof', 3, item)}><img src="assets/images/icons/plus-light.svg" className="me-1 cursor-pe" /> {t("paidTobepaid.Proof")}</span>
                                                                        <span className={(item.ID_ETIQUETA_ETI && item.ID_ETIQUETA_ETI.indexOf('4') >= 0) ? `${'dpay4' + item.id} text-primary cursor-pe fw-bold` : `${'dpay4' + item.id} text-primary cursor-pe fw-bold`} id={`other` + item.id} onClick={() => handleUpload('other', 4, item)}><img src="assets/images/icons/plus-light.svg" className="me-1 cursor-pe" /> {t("paidTobepaid.Others")}</span>
                                                                    </div>
                                                                </>
                                                            }
                                                        </td>
                                                        <td className="text-danger-40">
                                                            <span className="text-nowrap d-block">R$ {Math.sign(item.totalPaidAmount) == -1 ? item.totalPaidAmount : "-" + converter(`${item.totalPaidAmount}`)} </span>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex justify-content-end">
                                                                {/* <img src="assets/images/icons/edit-light.svg" alt="Edit" id="edit_newExpenses" className="cursor-pe h-16 me-2" onClick={(e) => HandleEditExpense(item.id, item)} />  */}
                                                                {/* <img src="assets/images/icons/liquidate-light.svg" alt="liquidate" id="liquidate" onClick={() => liquidateModalShow2(item.id)} className="cursor-pe h-18 me-2" /> */}
                                                                {/* <Liquidate2 showLiquidate2={showLiquidateModal2} liquidateClose2={liquidateModalClose2} liquidateId={liquidateId} /> */}
                                                                <span id="liquidate" onClick={() => settleOpened(item.id)} className="cursor-pe img-primary icon"><img src="assets/images/icons/liquidate-light.svg" className="h-20" alt="settle" /></span>
                                                                {item.ID_LANCAMENTO_IMODM ?
                                                                    <>
                                                                        <span className="icon">
                                                                            <img src="assets/images/icons/file-refresh.svg" alt="Edit Recurring" className="h-20" onClick={(e) => HandleEditExpense(item.id, item)} />
                                                                        </span>
                                                                    </>
                                                                    : ''
                                                                }
                                                                {/* <img src="assets/images/icons/edit-light.svg" alt="Edit" id="edit_newExpenses" className="cursor-pe h-16 me-2" />
                                            <img src="assets/images/icons/attach.svg" alt="Attach file" className="cursor-pe h-18 me-2" />
                                            <img src="assets/images/icons/uploadfile.svg" alt="Clone expense" className="cursor-pe h-16 me-2" />*/}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            ) :
                                            <tr>
                                                <td colSpan={5} className="text-center">
                                                    <span>{t('formLabel.noRecordsFounds')}</span>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>

                }
                {/*<!-- /Table end -->*/}
                <div className="d-flex justify-content-center mt-3 mt-lg-4">
                    {
                        toBePaidtransferData && toBePaidtransferData.totalRow && toBePaidtransferData.totalRow.totalRow > 0 ?
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={toBePaidtransferData.totalRow.totalRow > 0 ? toBePaidtransferData.totalRow.totalRow : 0}
                                pageSize={PageSize}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                            :
                            ''
                    }
                </div>
                {/*More Items - Auto - All Items*/}
                <p className="my-lg-4 my-3">{t('formLabel.listing')}  {state.start} to {toBePaidtransferData.data && toBePaidtransferData.data.length > 0 ? state.start + toBePaidtransferData.data.length : state.end + 0} of  {toBePaidtransferData && toBePaidtransferData.totalRow > 0 ? toBePaidtransferData.totalRow : 0} {t("paidTobepaid.transfertobecarriedout")}.</p>
                {/* <!-- Nav listing start from here -->*/}
                {
                    userJson && userJson.length > 0 && (userJson.indexOf(2091) !== -1) &&
                    <ul className="list-inline mb-0">
                        {/* mark checkbox dropdown */}
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Dropdown>
                                <Dropdown.Toggle variant="light" size="sm">
                                    {t('formLabel.mark')}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleSelectAll} href="">{t('formLabel.all')}</Dropdown.Item>
                                    <Dropdown.Item onClick={noneHandleSelectAll} href="">{t('formLabel.none')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                        {/* end mark checkbox dropdown */}
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"> <span className="px-1">{t("expenditure.withTagged")}:</span></li>
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm" onClick={toBePaidLiquidate}>{t("expenditure.liquidate")}</Button>
                        </li>
                        <LowerLiquid showlowerLiquidate={showLowerLiquidModals} lowerliquidateCloses={lowerliquidCloses} />
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm" onClick={issueCheckModalShow}>{t("paidTobepaid.Issuecheck")}</Button>
                        </li>
                        {/* delete button */}
                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                            <Button variant="light" size="sm" onClick={handleDelete}>{t("button.delete")}</Button>
                        </li>
                        {/* end delete button */}
                        <li className="list-inline-item mb-lg-0 mb-1">
                            <Dropdown>
                                <Dropdown.Toggle variant="light" size="sm">
                                    {t("more")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item data-name="Remove Manager" onClick={authorizationDateModalShow}>{t("paidTobepaid.Bordero")}</Dropdown.Item>
                                    <SelectPayment showAuthorizationDate={showAuthorizationDateModal} authorizationDateClose={authorizationDateModalClose} checkboxClicked={isCheck.length > 0} />
                                    <Dropdown.Item data-name="Mark to process">{t("paidTobepaid.Marktoprocess")}</Dropdown.Item>
                                    <Dropdown.Item data-name="Unmark from processing">{t("paidTobepaid.Unmarkfromprocessing")}</Dropdown.Item>
                                    <Dropdown.Item data-name="Receipt">{t("paidTobepaid.Receipt")}</Dropdown.Item>
                                    <Dropdown.Item data-name="Change bank account" onClick={editExpenseModalShow}>{t("paidTobepaid.Changebankaccount")}</Dropdown.Item>
                                    <EditExpense showEditExpense={showEditExpenseModal} editExpenseClose={editExpenseModalClose} checkboxClicked={isCheck.length > 0} />
                                    <Dropdown.Item data-name="Change expiration" onClick={changeExpirationModalShow}>{t("paidTobepaid.Changeexpiration")}</Dropdown.Item>
                                    <ChangeMaturity showchangeExpiration={showchangeExpirationModal} changeExpirationClose={changeExpirationModalClose} checkboxClicked={isCheck.length > 0} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>
                }
                {/*<!-- /Nav listing  end -->*/}
            </main>
            {/*<!-- /Main content end -->*/}
            {/* More filter modal start from here */}
            <Modal show={show} onHide={filterClose}>
                <Form >
                    
                    <Modal.Header closeButton>
                        <Modal.Title>{t("moreFilters")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel className="mb-3 mb-lg-4" controlId="fromSupplierCategories" label={t("paidTobepaid.Fromsuppliercategories")}>
                            <Form.Select aria-label="From supplier categories">
                                <option value="">{t('dropdownItem.select')}</option>
                            </Form.Select>
                        </FloatingLabel>
                        <FloatingLabel className="mb-3 mb-lg-4" controlId="barcode" label={t("paidTobepaid.Barcode")}>
                            <Form.Select aria-label="Bar code">
                                <option value="">{t('dropdownItem.select')}</option>
                            </Form.Select>
                        </FloatingLabel>
                        <FloatingLabel className="mb-3 mb-lg-4" controlId="file" label={t("paidTobepaid.File")}>
                            <Form.Select aria-label="File">
                                <option value="">{t('dropdownItem.select')}</option>
                            </Form.Select>
                        </FloatingLabel>
                        <FloatingLabel className="mb-3 mb-lg-4" controlId="vendorBankAccountType" label={t("paidTobepaid.Vendorbankaccounttype")}>
                            <Form.Select aria-label="Vendor bank account type">
                                <option value="">{t('dropdownItem.select')}</option>
                            </Form.Select>
                        </FloatingLabel>
                        <FloatingLabel className="mb-3 mb-lg-4" controlId="supplierBankAccountBank" label={t("paidTobepaid.Supplierbankaccountbank")}>
                            <Form.Select aria-label="Supplier bank account bank">
                                <option value="">{t('dropdownItem.select')}</option>
                            </Form.Select>
                        </FloatingLabel>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md={6}>
                                <FloatingLabel controlId="ofTheType" label={t("paidTobepaid.Ofthetype")}>
                                    <Form.Select aria-label="Of the type">
                                        <option value="">{t('dropdownItem.select')}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="contractExpenses" label={t("paidTobepaid.Contractexpenses")}>
                                    <Form.Select aria-label="Contract expenses">
                                        <option value="">{t('dropdownItem.select')}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <FloatingLabel className="mb-3 mb-lg-4" controlId="withoutOutstandingCharges" label={t("paidTobepaid.Withoutoutstandingcharges")}>
                            <Form.Select aria-label="Without outstanding charges">
                                <option value="">{t('dropdownItem.select')}</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={filterClose}>{t("paidTobepaid.Resetfilter")}</Button>
                        <Button type="submit" variant="primary">{t("button.done")}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /More filter modal end */}
            {/* View files Popup and add new file */}
            <Modal show={viewFiles} onHide={viewFilesClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("moreFilters")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border-2 border border-dashed position-relative text-center rounded-4 mb-lg-4 mb-3">
                        <form ref={formRef} onSubmit={handleFileUpload} style={{ display: "none" }} id="fileuploadform">
                            <input type="file" accept=".xls,.xlsx,.pdf,.PDF,.jpeg,.png" onChange={handleFileChange} className="position-absolute left-0 end-0 attachedDocument" hidden />
                            <button type='submit' className='uploadFilenow'></button>
                        </form>

                        <div className="cursor-pe py-4 px-2 z-index-2" onClick={() => handleUpload('newFile', 0, '')}>
                            <h4 className="text-primary fw-bold mb-2"><img src="assets/images/icons/upload-file-primary.svg" alt="Upload file" className="cursor-pe me-2" />{t("userModule.addNew")}</h4>
                            <label className="mb-0 cursor-pe fw-normal text-dark"><img src="assets/images/icons/info-dark.svg" alt="Info" className="h-14 cursor-pe me-1" />{t("SupportedFormats")}.</label>
                        </div>
                    </div>

                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                        {/* <Col md={3} className='text-center'>
                            <Button variant="link" size="sm" className="fw-bold position-relative z-index-1 cursor-pe" onClick={() => handleUpload('newFile', 0,'')}>{t("userModule.addNew")}</Button>
                        </Col>  */}
                        {allFiles && allFiles.length > 0
                            ? allFiles.map((item: any, index: number) => (
                                <React.Fragment key={index}>
                                    <Col md={6} lg={4}>
                                        <div className='w-100 text-center border rounded p-3 position-relative'>
                                            <Button variant="link" id="handleImageDelete" className='bg-primary position-absolute rounded-circle wh-xs-1 d-flex align-items-center justify-content-center end-0 mt-n3 me-n3 top-0 z-index-3 p-0' data-rowid={index} onClick={(e) =>
                                                handleImageDelete(e, item)} >
                                                <img className="h-10" src="./assets/images/icons/times-white.svg" alt="Delete" />
                                            </Button>
                                            {(item.ST_EXTENSAO_ARQ == 'jpg' || item.ST_EXTENSAO_ARQ == 'jpeg' || item.ST_EXTENSAO_ARQ == 'png') &&
                                                <a href={item.ST_HASH_ARQ} target='_blank'><img src={item.ST_HASH_ARQ} alt="Filter" style={{ height: "100px" }} className="w-100 object-fit-contain" /></a>
                                            }
                                            {(item.ST_EXTENSAO_ARQ == 'pdf' || item.ST_EXTENSAO_ARQ == 'PDF') &&
                                                <a href={item.ST_HASH_ARQ} target='_blank'><img src="./assets/images/icons/pdf.svg" alt="Filter" style={{ height: "100px" }} className="w-100 object-fit-contain" /></a>
                                            }
                                            {((['jpg', 'png', 'jpeg', 'pdf', 'PDF']).indexOf(item.ST_EXTENSAO_ARQ) < 0) &&
                                                <a href={item.ST_HASH_ARQ} target='_blank'><img src="./assets/images/icons/docx.svg" alt="Filter" style={{ height: "100px" }} className="w-100 object-fit-contain" /></a>
                                            }
                                            <h6 className="fw-semibold fs-14 text-truncate mt-2 mb-0">{item.ST_NOME_ARQ}</h6>
                                        </div>
                                    </Col>
                                </React.Fragment>
                            ))
                            : ""}
                        {
                            (gettingFiles == "loading") &&
                            <Col md={3}><Loader /></Col>
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={viewFilesClose}>{t("button.close")}</Button>
                </Modal.Footer>
            </Modal>
            {/* View files Popup and add new file */}
            <ReactTooltip
                anchorId="edit_newExpenses"
                place="bottom"
                content="Edit"
            />
            <ReactTooltip
                anchorId="liquidate"
                place="bottom"
                content="Liquidate"
            />
            <FinancialExpenseService state={state} type="tobepaid" paidDetail={paidDetailState} />
            <Settle state={state} />
            <Modal show={showperiodfilter} onHide={peridfilterClose}>
                <Form onSubmit={handleSubmit(dateSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("TransferPage.month")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="g-3 g-xl-4">
                            <Col md>
                                <FloatingLabel label={t("TransferPage.startDate")}>
                                    <Form.Control type="date" aria-label="From"  {...register("startDate")} onChange={startDate}></Form.Control>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel label={t("TransferPage.endDate")}>
                                    <Form.Control type="date" aria-label="to" min={startDate()}  {...register("endDate")} ></Form.Control>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={filterClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type='submit'>
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {fullLoader &&
                <MainLoader />
            }
        </section>
    )
}
export default ToBePaid;