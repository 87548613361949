import { Form, Accordion, Modal, Alert, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { insertExpense, contractDatails, contractsExpenseData, setServiceType, newExpenseClose, getAllExpense, getAllService, getAllSuppliers, newServiceOpen, contractsData, getsingleExpense } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import CryptoJS from 'crypto-js';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { newExpenseForm } from '../../../Interface';
import Swal from 'sweetalert2';
import ServiceService from './ServiceService';
import { getAllAccounts } from "../../../../redux/slice/expenditureDataSlice";
import moment from "moment-timezone";
import '../../../../App.css';
import { openErrorbox } from './actionPerform';
import { commonService } from '../../../../services/commonService';
import { convertToNumber, converter, formatNumber, formatNumberEnglish } from '../../../constants/utils';
import i18n from '../../../../i18n';
const ExpenseService = (props: any) => {
    let { contractExp, stateContract, setStateMode } = props;
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD");
    const { newExpenseShow, contractDatail, suppliersData, serviceData, expenseCheckbox, expenseType, expenseData, newExpenseTitle, contractData, status } = useSelector((state: RootState) => state.contractData)
    const { accountData, accountStatus, billingData } = useSelector((state: RootState) => state.expenditureData)
    const { expType } = useSelector((state: RootState) => state.expenditureData)
    const [double, setDouble] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M').format("MMM/YYYY");
    let searchDt = moment(newDate).format("MMM/YYYY")
    let endDt = moment(newDate).add(1, 'M').format("MMM/YYYY");
    const [billmethodOnOff, setBillMethodOnOff] = useState({});
    const [aarinConfig, setAarinConfig] = useState<any>("1");
    const [aarinSuppConfig, setaarinSuppConfig] = useState<any>("1");
    const [isCreditOwner, setisCreditOwner] = useState<any>(false);
    const [isMoreOptions, setMoreOptions] = useState(false);
    const [contractOwners, setOwners] = useState<any>([]);
    const [adminFeeStatus, setAdminFeeStatus] = useState<any>("0");
    const [valueCon, setValueCon] = useState('')
    const [isCalculateAdminFee, setisCalculateAdminFee] = useState<any>(false);
    const [state, setState] = useState({
        id: id,
        startDate: startDt,
        searchDate: searchDt,
        endDate: endDt,
        type: "",
        expense: "",
        start: 0,
        end: 1000
    })
    let contractStDate = contractDatail && contractDatail.length > 0 ? contractDatail[0].start2 : moment.tz('America/Sao_Paulo').format("YYYY-MM-DD");
    // contractDatail[0].bank_account
    useEffect(() => {
        if (newExpenseShow) {
            reset()
            sethideField(false);
            if (stateContract) {
                setState(stateContract);
            }

            if (contractDatail.length &&
                contractDatail[0].invoice == 1) {
                if (serviceData && serviceData.length &&
                    serviceData[0].TaxaDeAdministracao
                ) {
                    let mt: any = serviceData[0].TaxaDeAdministracao;
                    if (mt.ID_CODIGOSERVICO_CSE != null) {
                        setisCalculateAdminFee(true);
                    }
                } else {
                    setisCalculateAdminFee(false);
                }
            } else {
                setisCalculateAdminFee(true);
            }

            let debitmonths = moment(newDate).format("YYYY-MM-DD");
            setValue("maturity", debitmonths)
            dispatch(contractDatails(Number(id)));
            dispatch(getAllAccounts({ "start": 0, "end": 1000 }))
            if (contractDatail && contractDatail.length > 0) {
                setValue("maturity", contractDatail[0].start2)
                setOwners(contractDatail[0].ownerResult);
            };

            if (typeof contractExp !== "undefined" && contractExp.contractExp == "contractExp") {
                let debitmonths = moment(searchDt).format("YYYY-MM-DD");
                if (contractDatail && contractDatail?.length > 0) {
                    setValue("billingMethod", contractDatail?.[0].billing_method ? contractDatail?.[0].billing_method : "0")
                    setValue("bankAccount", contractDatail?.[0]?.bank_account || "")
                    setValue('debit', "");
                    setValue('credit', "");
                    setValue('debitMonth', moment(expenseData?.data[0]?.['debitmonth']).format("YYYY-MM") || "");
                }

                //setValue("maturity", debitmonths)
                // setValue('portion', "1");
                // setValue('in', "1");
            } else {
                if (contractDatail && contractDatail?.length > 0) {
                    setValue('portion', "1");
                    setValue("bankAccount", contractDatail?.[0]?.bank_account || "")
                    setValue('in', "1");
                    // setValue("billingMethod", "0");
                    setValue("billingMethod", contractDatail?.[0].billing_method ? contractDatail?.[0].billing_method : "0")
                    if (newExpenseTitle !== "New recurring expense") {
                        setValue('debitMonth', moment().format("YYYY-MM") || "");
                    }
                }
            }
            if (accountData?.data?.length > 0) {
                accountData?.data?.forEach(element => {
                    if (element.main_ac == 1) {
                        setValue("bankAccount", element?.id)
                    }
                });
            }
        }
    }, [newExpenseShow, contractExp])
    useEffect(() => {
        dispatch(getAllService());
        dispatch(getAllSuppliers());
        if (newExpenseShow) {
            dispatch(contractsData({ "start": 0, "end": 1000 }))
            //dispatch(getAllBanks({ "start": 0, "end": 1000, "waysOfReceiving": "yes" }))
        }
        if (newExpenseTitle != "New expense") {
            setValue('portion', "1");
            setValue('in', "1");
        }
    }, [dispatch, newExpenseShow, newExpenseTitle])

    useEffect(() => {
        if (newExpenseShow) {
            let jsonData: any = document.querySelector('#root')?.getAttribute("expense_data");
            if (jsonData) {
                let simulData: any = JSON.parse(jsonData);
                if (simulData?.[2]) {
                    state["id_lanc"] = simulData?.[0];
                    dispatch(getsingleExpense(state));
                } else {
                    delete state["id_lanc"];
                    dispatch(getAllExpense(state));
                }
            }
        }
    }, [dispatch, document.querySelector('#root')?.getAttribute("expense_data")])

    const [previousExpense, setpreviousExpense] = useState([]);
    const [editSubsiquence, seteditSubsiquence] = useState("");
    useEffect(() => {
        if (newExpenseShow) {
            let data = document.querySelector('#root')?.getAttribute("expense_data");
            if (data == null) {
                setpreviousExpense([]);
            } else {
                let newData = JSON.parse(data);
                setpreviousExpense(newData);
            }
            seteditSubsiquence("");
        }
    }, [newExpenseShow, document.querySelector('#root')?.getAttribute("expense_data")])

    const [PortionData, setPortionData] = useState<any>([]);
    const [hideField, sethideField] = useState(false);
    useEffect(() => {

        if (previousExpense && previousExpense.length > 0) {
            let expdata: any = ""

            if (expenseData) {
                expdata = expenseData &&
                    expenseData?.["data"].length > 0 &&
                    expenseData?.["data"].filter((item: any) => item.id == previousExpense[0]);
            }
            if (expdata.length > 0) {
                seteditSubsiquence(expdata?.[0]?.['ID_MD5PARCELAMENTO_IMOD']);
                setValue("portion", "1");
                setValue("in", "1");
                setValue('maturity', expdata?.[0]?.['maturityDate_full'] ?? "");
                setValue('value', converter(`${expdata?.[0]?.['value']}`) ?? "");
                setValueCon(String(expdata?.[0]?.['value']))
                setValue('service', expdata?.[0]?.['serviceId'] ?? "");
                setValue('complement', expdata?.[0]?.['complement'] ?? "");
                setValue('debit', expdata?.[0]?.['debitId'] > 0 ? expdata[0]['debitId'] : '');
                setValue('credit', expdata?.[0]?.['creditId'] > 0 ? expdata[0]['creditId'] : '');
                setValue('debitMonth', moment(expdata?.[0]?.['debitmonth']).format("YYYY-MM") || "");
                setValue('bankAccount', expdata?.[0]?.['bankAccount']);
                setValue('isCalAdmin', expdata?.[0]?.['isCalAdmin']);
                setValue('billingMethod', expdata?.[0]?.['payment']);
                setValue('dueDate', expdata?.[0]?.['dueDate']);
                setValue('supplier', expdata?.[0]?.['supplier'] ?? "")
                if (expdata?.[0]?.['credit'] == "Third") {
                    setHide(false)
                }
                if (expdata?.[0].type !== "New expense") {
                    document.querySelector('[name=portion')?.removeAttribute("readonly");
                    document.querySelector('[name=in')?.removeAttribute("readonly");
                    document.querySelector('[name=portion')?.removeAttribute("disabled");
                    document.querySelector('[name=in')?.removeAttribute("disabled");
                }
                let arrayportion: any = [];
                arrayportion.push({
                    maturity: expdata?.[0]?.['maturityDate_full'] ?? "",
                    value: converter(`${expdata?.[0]?.['value']}`) ?? "",
                    complement: expdata?.[0]?.['complement'] ?? ""
                })
                setPortionData(arrayportion)
                sethideField(true);
            }
        }
    }, [previousExpense, document.querySelector('#root')?.getAttribute("expense_data"), expenseType, expenseData])

    /*------------New expense modal hide function-----------*/
    const [CanIClose, setCanIClose] = useState(true);
    const expenseClose = () => {
        if (CanIClose) {
            document.querySelector('#root')?.removeAttribute("expense_data");
            reset();
            setValue("in", "");
            setValue("portion", "");
            dispatch(newExpenseClose(false));
            setDouble(false);
            setHide(true);
            setValue('isCalAdmin', false);
            setPortionData([]);
            setMoreOptions(false);

        }
    }

    const serviceOpen = (evt) => {
        dispatch(setServiceType(evt));
        dispatch(newServiceOpen(true));
        dispatch(newExpenseClose(false));
    }
    function formatDate(date) {
        return moment(new Date(date)).endOf('month').format("YYYY-MM-DD")
    }

    /*------------Form validation-----------*/
    let form1 = {
        maturity: Yup.date()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .required(t('requiredMmessage.fieldRequired')),
        value: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        service: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        // complement: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        debit: Yup.string()
            .required(t('requiredMmessage.fieldRequired')),
        credit: Yup.string()
            .notOneOf([Yup.ref('debit'), null], t('requiredMmessage.creditSameAs'))
            .required(t('requiredMmessage.fieldRequired')),
        portion: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        in: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    }
    let form7 = {
        // debitMonth: Yup.date()
        //     .nullable()
        //     .transform((curr, orig) => orig === '' ? null : curr)
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .min(Yup.ref('maturity'), t("requiredMmessage.Enddatecantbebeforestartdate")),
        debitMonth: Yup.date()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .when(
                'maturity',
                (maturity: any, schema: any) => {
                    if (newExpenseTitle !== "New recurring expense") {
                        schema = schema.required(t('requiredMmessage.fieldRequired'));
                    }
                    if (!hideField) {
                        return (moment(maturity).isValid() ? schema.min(moment(maturity).format("YYYY-MM"), t("requiredMmessage.Enddatecantbebeforestartdate")) : moment(schema).format("YYYY-MM"))
                    }
                    return schema
                },
            ),
        bankAccount: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        billingMethod: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    }
    if (isMoreOptions) {
        // form1 = { ...form1,...form7}
    }
    form1 = { ...form1, ...form7 }
    let form2 = {
        supplier: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    }
    let form22 = {}
    if (newExpenseTitle === "New recurring expense") {
        // form22 = {
        //     formOfPayment: Yup.string().trim().nullable()
        //         .required(t('requiredMmessage.fieldRequired')),
        // }
    }
    form2 = { ...form2, ...form22 }
    let form3 = {
        dueDate: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    }
    let form4 = {
        ofContract: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    }
    const [hide, setHide] = useState(true);
    let form5 = { ...form1 };
    if (!hide) {
        openErrorbox();
        if (newExpenseTitle === "New recurring expense") {
            if (expenseType) {
                form5 = { ...form1, ...form2, ...form3, ...form4 };
            }
            else {
                form5 = { ...form1, ...form2, ...form3 };
            }
        }
        else {
            if (expenseType) {
                form5 = { ...form1, ...form2, ...form4 };
            }
            else {
                form5 = { ...form1, ...form2 };
            }
        }
    }
    if (hide && expenseType) {
        openErrorbox();
        form5 = { ...form1, ...form4 };
    }
    const expenseValidation = Yup.object().shape(form5);
    const creditSuppliers = (val) => {
        setAarinConfig(1); setaarinSuppConfig(1); setisCreditOwner(false);
        setAdminFeeHide(false); setMoreOptions(false);
        setValue('isCalAdmin', false);
        if (val == 1 && contractDatail.length && contractDatail?.[0].AARIN_ID == '') {
            // setAarinConfig(0); 
        }
        if (val == 1) {
            setisCreditOwner(true);
        }
        if (val == 3) {
            // Real Estate then hide Admin fee checkobx, 100% to Admin
            setAdminFeeHide(true);
            setMoreOptions(true);
        }
        if (val == 4) { setHide(false); }
        else { setHide(true); }
        if (contractOwners && contractOwners.length == 1) {
            setValue("creditOwner", contractOwners?.[0]?.["ownerId"] || "");
        }
    }
    const checkArrinConfig = (val) => {
        setAarinConfig(1);
        contractOwners && contractOwners.length > 0 &&
            contractOwners.forEach(owner => {
                if (owner.ownerId == val && (owner.AARIN_ID == '' || owner.AARIN_ID == null)) {
                    setAarinConfig(0);
                }
            });
    }
    const [adminFeeHide, setAdminFeeHide] = useState(false);
    const issContract = (evt) => {
        if (evt.target.checked === true) {
            setMoreOptions(true);
        } else {
            setMoreOptions(false);
        }
    }

    let frm = {
        resolver: yupResolver(expenseValidation)
    }

    /*------------Submit expense form data-----------*/

    const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<newExpenseForm>(frm);
    const onSubmit = async (data: any, e: any) => {
        e.preventDefault();
        setDouble(true)
        setCanIClose(false);
        data.type = newExpenseTitle;
        if (previousExpense.length > 0) {
            data["ID_LANCAMENTO_IMOD"] = previousExpense[0];
        }
        if (data.ofContract) {
            let idandProperty = (data.ofContract).split("-");
            data["id"] = idandProperty[0];
            data["propertyId"] = idandProperty[1];
        }
        let values: any = convertToNumber(valueCon ? String(valueCon) : String(data.value));
        data["value"] = values
        data.debitMonth = moment(data.debitMonth).format("YYYY-MM-DD");
        data.PortionData = PortionData.length;
        for (let key in data.data) {
            if (data.data[key].hasOwnProperty('value')) {
                data.data[key].value = convertToNumber(data.data[key].value);
            }
        }

        if (editSubsiquence && editSubsiquence.length > 0 && editSubsiquence !== "") {
            data["MD5PARCELAMENTO"] = editSubsiquence
        }

        let response = await dispatch(insertExpense(data));
        if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
            setDouble(false);
            Swal.fire({
                // icon: 'success','success', 
                title: t("Sweetalert.Success"),
                text: previousExpense.length > 0 ? t("Sweetalert.RecordUpdated") : t("Sweetalert.insertRecord"),
                confirmButtonText: "OK", timer: 2000
            })
            expenseClose();
            dispatch(getAllExpense(state))
            dispatch(contractsExpenseData({ expense: '', start: 0, end: 10 }))
            document.querySelector('#root')?.setAttribute("expense_data_run", "true");
            // setStateMode("monthly");
            reset();
            seteditSubsiquence("");
            setCanIClose(true);
        } else {
            setDouble(false)
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: "error", 
                confirmButtonText: "OK",
                timer: 2000
            })
            setCanIClose(true);
        }
    }
    const handlefilterBillmethod = (e) => {
        let dataAccount = accountData && accountData?.data && accountData?.data.length > 0 && accountData.data.filter((item: any, index: number) => Number(e.target.value) == Number(item.id));
        setBillMethodOnOff(dataAccount[0]);
    }

    const handleExpensesChange = (e: any, type: string) => {
        let arrayportion: any = [];
        setPortionData([]);
        let Value: any = "";
        if (type === "maturity") {
            setValue("debitMonth", moment(e.target.value).format("YYYY-MM"))
        }
        if (type == "in" || type == "portionchange") {
            Value = e.target.value;
            if (type == "portionchange") {
                setValue('portion', Value)
            }
            if (type == "in") {
                setValue('in', Value)
            }
        } else {
            Value = getValues("in");
        }
        if ((type == "in" || type == "portionchange") && Number.isInteger(parseInt(Value))) {
            let portionValue = getValues("portion");
            if (type != "in") {
                portionValue = getValues("in")
            }
            if (Value < 40) {
                if (Number(Value) < Number(portionValue)) {
                    if (type == "portionchange") {
                        setValue('portion', Value);
                    }
                    else {
                        setValue('in', Value);
                    }
                } else {
                    //setValue('in', Value);
                }
            } else {
                if (Number(Value) < Number(portionValue)) {
                    setValue('portion', "40");
                    setValue('in', "40");
                } else {
                    setValue('in', "40");
                }
            }
        }
        if (type == "value") {
            let { value } = e.target;
            value = value.replace(/[^\d,]/g, '');
            setValue("value", formatNumber(value));
            setValueCon(value);
        }
        if (type == "portionchange") {
            Value = getValues("in");
        }
        if (Value !== "") {
            if (getValues('maturity') && getValues('value')) {
                let currentDate = moment(getValues('maturity')).format('YYYY-MM-DD');
                let PortionData: any = getValues("portion");
                //PortionData = 1;
                let dividewithThis = Number(getValues("in")) - Number(PortionData);
                let amt = 0;
                let amt2: any = convertToNumber(getValues("value"));
                if (amt2) {
                    amt2 = convertToNumber(getValues("value"))
                }
                else {
                    amt2 = convertToNumber(getValues("value"))
                }
                amt = Number(amt2);
                [...Array(Number(Value))].forEach((item, index) => {
                    if (index + 1 >= Number(PortionData)) {
                        setPortionData([]);
                        const totalAmount = amt;
                        const numberOfInstallments = dividewithThis + 1;
                        let installmentAmount: any = totalAmount / numberOfInstallments;
                        if (installmentAmount % 1 !== 0) {
                            installmentAmount = parseFloat(installmentAmount).toFixed(2);
                        }
                        let expValue: any = index == numberOfInstallments - 1 ? totalAmount - (numberOfInstallments - 1) * installmentAmount : installmentAmount;
                        if (expValue % 1 !== 0) {
                            expValue = parseFloat(expValue).toFixed(2);
                        }
                        expValue = expValue.toString();
                        expValue = converter(expValue);
                        arrayportion.push({
                            maturity: currentDate,
                            value: expValue,
                            complement: `Parcela ${index + 1} de ${Value}`
                        })
                        var futureMonth = moment(currentDate).add(1, 'months').calendar();
                        let futureMonthnew = new Date(futureMonth);
                        let yyyy: any = futureMonthnew.getFullYear();
                        let mm: any = futureMonthnew.getMonth() + 1;
                        let dd: any = futureMonthnew.getDate();
                        if (dd < 10) dd = '0' + dd;
                        if (mm < 10) mm = '0' + mm;
                        let formattedToday = yyyy + "-" + mm + "-" + dd;
                        currentDate = formattedToday
                    }
                })
            };
            if (arrayportion && arrayportion.length && arrayportion.length == 1) {
                arrayportion[0].complement = '';
            }
            setPortionData(arrayportion);
        } else {
            setPortionData([]);
        }
    }

    useEffect(() => {
        if (PortionData && PortionData.length > 0) {
            setTimeout(() => {
                PortionData.forEach((item, index) => {
                    setValue(`data.install_${index}.maturity`, item?.["maturity"]);
                    setValue(`data.install_${index}.value`, item?.["value"]);
                    setValue(`data.install_${index}.complement`, item?.["complement"]);
                })
            }, 100)
        }
    }, [PortionData])

    const checkSuppliersAarin = (e) => {
        let val = e.target.value;
        let sAcc = suppliersData && suppliersData?.length > 0 && suppliersData.filter((item, index) => Number(e.target.value) == Number(item.id));
        if (sAcc && sAcc.length) {
            val = sAcc[0]?.id;
            commonService.isUserBankConfigured({ userId: val }).then(res => {
                setaarinSuppConfig(1);
            }).catch(err => {
                console.log("err", err)
            });
        }
    }

    useEffect(() => {
        minDate();
    }, [getValues("maturity")])

    const minDate = () => {
        let today: any = "";
        if (getValues("maturity")) {
            today = getValues("maturity");
        } else {
            today = new Date(contractStDate).toISOString().split('T')[0];
        }
        return today
    };
    function getCurrentMonth() {
        const today = new Date(contractStDate);
        const year = today.getFullYear();
        const month = today.getMonth() + 1; // Months are zero-based
        return `${year}-${month.toString().padStart(2, '0')}`;
    }
    // const handleAdminFeeChange = (e) => {
    //     const selectedServiceAdminFeeStatus = e.target.options[e.target.selectedIndex].getAttribute('data-value2');
    //     setAdminFeeStatus(selectedServiceAdminFeeStatus);
    // }

    const handleAdminFeeChange = (e) => {
        const selectedServiceAdminFeeStatus = e.target.options[e.target.selectedIndex].getAttribute('data-value2');
        setAdminFeeStatus(selectedServiceAdminFeeStatus);
        const selectedServiceId = e.target.value;
        const selectedService: any = serviceData.find(service => service.id == selectedServiceId);

        if (selectedService) {
            const debitId = selectedService?.debit_id;
            const creditId = selectedService?.credit_id;
            const supplierId = selectedService?.supplier
            if (debitId && debitId !== null) {
                setValue("debit", debitId.toString()); // Set the debit select input value
            } else {
                setValue("debit", ""); // Set the debit select input value

            }
            if (creditId && creditId !== null) {
                setValue("credit", creditId.toString()); // Set the credit select input value
                if (creditId == Number(4)) {
                    setValue('supplier', supplierId)
                    setHide(false)
                } else {
                    setValue('supplier', "")
                }

            } else {
                setValue("credit", ""); // Set the credit select input value

            }
        }
    }
    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setAdminFeeStatus(isChecked ? "1" : "0");
    }
    return (
        <>
            {/* <!-- New expense start from here --> */}
            <Modal show={newExpenseShow} onHide={expenseClose} className="expenseServeicetsxTemplate" id="contractHExpense">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" value={id} {...register('id')} />
                    <input type="hidden" value={contractDatail.length > 0 ? contractDatail[0].propertyId : ''} {...register('propertyId')} />
                    <Modal.Header closeButton>
                        {
                            newExpenseTitle == "New recurring expense" ?
                                <Modal.Title>{previousExpense.length > 0 ? t("editrecurringexpense") : t('newRecurringExp')} </Modal.Title>
                                :
                                <Modal.Title>{previousExpense.length > 0 ? t('button.edit') + ' ' + t('expenditure.expenses') : t('expenditure.newExpense')} </Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
                            {
                                previousExpense && previousExpense.length > 0 && newExpenseTitle == "New recurring expense" &&
                                <div className='d-flex justify-content-center'>
                                    <Form.Group>
                                        <Form.Check type="checkbox" {...register('EditallSubsequentExpenses')} label={t("EditallSubsequentExpenses")} />
                                    </Form.Group>
                                </div>
                            }

                            {
                                expenseType ?
                                    <Col md={12}>
                                        <FloatingLabel controlId="floatingInput" label={t('ofthecontract') + " *"}>
                                            <Form.Select className={`form-control ${errors.ofContract ? 'is-invalid' : ''}`} {...register('ofContract')} >
                                                <option value="">{t('dropdownItem.select')}..</option>
                                                {
                                                    contractData?.data && contractData?.data.length > 0 ?
                                                        contractData.data.map((item: any, index: number) => {
                                                            return (
                                                                <option key={index} value={item.id + '-' + item.propertyId}>{item.complement ? item.complement : item.neighborhood} -- {item.id}/{item.propertyId} -- {item.ownerName}</option>
                                                            )
                                                        })
                                                        :
                                                        ''
                                                }
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">{errors.ofContract?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    :
                                    ''
                            }
                            <Col md={6}>
                                <FloatingLabel controlId="floatingInput" label={newExpenseTitle == "New recurring expense" ? t("start") : t("maturity")}>
                                    <Form.Control type="date" min={contractStDate} placeholder={newExpenseTitle == "New recurring expense" ? t("start") : t("maturity")} className={`form-control ${errors.maturity ? 'is-invalid' : ''}`} {...register('maturity')} onChange={(e) => handleExpensesChange(e, "maturity")} disabled={hideField} readOnly={hideField} />
                                    <Form.Control.Feedback type="invalid">{errors.maturity?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingInput" label={t("formLabel.value") + " *"}>
                                    <Form.Control type="text" placeholder={t("formLabel.value")} className={`form-control ${errors.value ? 'is-invalid' : ''}`} {...register('value')} onChange={(e) => handleExpensesChange(e, "value")} />
                                    <Form.Control.Feedback type="invalid">{errors.value?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <InputGroup>
                                    <FloatingLabel controlId="floatingInput" label={t("taxation.services") + " *"}>
                                        <Form.Select className={`form-control ${errors.service ? 'is-invalid' : ''}`} {...register('service')} onChange={(e) => handleAdminFeeChange(e)} >
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            {
                                                serviceData && serviceData.length > 0 && serviceData.map((item: any, index) => {
                                                    if (item.ST_CONTA_CONT != '3.1') {
                                                        return (
                                                            <option key={index} value={item.id} data-value2={item.admin_fee_status}>{item.name}</option>
                                                        )
                                                    }
                                                })
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.service?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <InputGroup.Text onClick={() => serviceOpen("expense")} id="basic-addon2">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/documents-add-light.svg"} alt="Documents add" className="h-20 cursor-pe" />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingInput" label={t("complement")}>
                                    <Form.Control type="text" placeholder={t("complement")} className={`form-control ${errors.complement ? 'is-invalid' : ''}`} {...register('complement')} readOnly={previousExpense.length > 0 ? true : false} />
                                    <Form.Control.Feedback type="invalid">{errors.complement?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t("formLabel.debit") + " *"}>
                                    <Form.Select className={`form-control ${errors.debit ? 'is-invalid' : ''}`} {...register('debit')} aria-label="Debit">
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="1">{t('notifications.owner')}</option>
                                        <option value="2">{t('notifications.tenant')}</option>
                                        <option value="3">{t('realEstate')}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.debit?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t("formLabel.credit") + " *"}>
                                    <Form.Select className={`form-control ${errors.credit ? 'is-invalid' : ''}`} {...register('credit')} onChange={(e) => creditSuppliers(e.target.value)} aria-label="Credit">
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="1">{t('notifications.owner')}</option>
                                        <option value="2">{t('notifications.tenant')}</option>
                                        <option value="3">{t('realEstate')}</option>
                                        <option value="4">{t('services.third')}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.credit?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>

                            {isCreditOwner &&
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingSelect" label={t("customerAndEmployees.owners") + " *"}>
                                        <Form.Select className={`form-control ${errors.credit ? 'is-invalid' : ''}`} {...register('creditOwner')} onChange={(e) => checkArrinConfig(e.target.value)} aria-label="CreditOwner">
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            {(contractOwners && contractOwners.length > 0) && contractOwners.map((item, index) => (
                                                <option key={index} data-arn-id={item.AARIN_ID} value={item.ownerId}>{item.ownerName}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.credit?.message}</Form.Control.Feedback>
                                        {aarinConfig != 1 &&
                                            <p className="text-danger">Owner's Bank account is not configured!</p>
                                        }
                                    </FloatingLabel>
                                </Col>
                            }
                            <Col md={newExpenseTitle == "New recurring expense" ? 4 : 6} style={{ display: hide ? 'none' : 'block' }}>
                                <FloatingLabel controlId="floatingSelect" label={t("customerAndEmployees.suppliers") + " *"}>
                                    <Form.Select className={`form-control ${errors.supplier ? 'is-invalid' : ''}`} {...register('supplier')} aria-label="Supplier" onChange={(e) => checkSuppliersAarin(e)}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        {suppliersData && suppliersData.length > 0 && suppliersData.map((item, index) => (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.supplier?.message}</Form.Control.Feedback>
                                    {aarinSuppConfig != 1 &&
                                        <p className="text-danger">Bank account is not configured!</p>
                                    }
                                </FloatingLabel>
                            </Col>
                            {newExpenseTitle == "New recurring expense" ?
                                <Col md={4} style={{ display: hide ? 'none' : 'block' }}>
                                    <FloatingLabel controlId="floatingSelect" label={t('dueDate')}>
                                        <Form.Control type="text" placeholder={t('dueDate')} className={`form-control ${errors.dueDate ? 'is-invalid' : ''}`} {...register('dueDate')} />
                                        <Form.Control.Feedback type="invalid">{errors.dueDate?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                :
                                ''
                            }
                            <Col md={newExpenseTitle == "New recurring expense" ? 4 : 6} style={{ display: hide ? 'none' : 'none' }}>
                                <FloatingLabel controlId="floatingSelect" label={t("formOfPayment")}>
                                    <Form.Select className={`form-control ${errors.formOfPayment ? 'is-invalid' : ''}`} {...register('formOfPayment')} aria-label="Form of payment">
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        {
                                            billingData?.data && billingData?.data.length > 0 ? billingData.data.map((item, index) => (
                                                <option key={index} value={Number(item.id)}>{item.name}</option>
                                            ))
                                                : ''
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.formOfPayment?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            {expType && newExpenseTitle === "New expense" ?
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingInput" label={t('formLabel.portion') + " *"}>
                                        {/*--onChange={(e) => handleExpensesChange(e, "portionchange")}*/}
                                        <Form.Control type="text" placeholder={t('formLabel.portion')} className={`form-control ${errors.portion ? 'is-invalid' : ''}`} {...register('portion')} onChange={(e) => handleExpensesChange(e, "portionchange")} disabled={previousExpense.length > 0 || newExpenseTitle != "New expense" ? true : false} />
                                        <Form.Control.Feedback type="invalid">{errors.portion?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                : ''}
                            {expType && newExpenseTitle === "New expense" ?
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingInput" label={t("In") + " *"}>
                                        <Form.Control type="text" placeholder={t("In")} className={`form-control ${errors.in ? 'is-invalid' : ''}`} {...register('in')} onChange={(e) => handleExpensesChange(e, "in")} disabled={previousExpense.length > 0 || newExpenseTitle != "New expense" ? true : false} />
                                        <Form.Control.Feedback type="invalid">{errors.in?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                : ''}
                        </Row>
                        <Accordion className="accordion-custom">
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>{t("banks.moreOptions")}</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingInput" label={!previousExpense.length && newExpenseTitle == "New recurring expense" ? t('end') + " *" : t('formLabel.debitmonth') + " *"}>
                                                <Form.Control
                                                    type="month"
                                                    placeholder={
                                                        !previousExpense.length && newExpenseTitle === "New recurring expense" ? t('end') : t('formLabel.debitmonth')
                                                    }
                                                    min={getCurrentMonth()} // Set the min attribute
                                                    className={`form-control ${errors.debitMonth ? 'is-invalid' : ''}`}
                                                    {...register("debitMonth")}
                                                    disabled={hideField} readOnly={hideField}
                                                />

                                                <Form.Control.Feedback type="invalid">{errors.debitMonth?.message}</Form.Control.Feedback>
                                            </FloatingLabel>

                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingSelect" label={t("banks.bankAccounts") + " *"}>
                                                <Form.Select className={`form-control ${errors.bankAccount ? 'is-invalid' : ''}`} {...register('bankAccount')} aria-label="Bank account" onChange={(e) => handlefilterBillmethod(e)}>
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    {
                                                        accountData?.data && accountData?.data.length > 0 ? accountData.data.map((item, index) => (
                                                            <option key={index} value={Number(item.id)}>{item.account} -- {item.accountRef}</option>
                                                        ))
                                                            :
                                                            ''
                                                    }
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.bankAccount?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingSelect" label={t("billingMethod") + " *"}>
                                                <Form.Select className={`form-control ${errors.billingMethod ? 'is-invalid' : ''}`} {...register('billingMethod')} aria-label="Billing method">
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    {
                                                        billingData?.data && billingData?.data.length > 0 ? billingData.data.map((item, index) => (
                                                            <option key={index} value={Number(item.id)}>{item.name}</option>
                                                        ))
                                                            : ''
                                                    }
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.billingMethod?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>

                                    {isCalculateAdminFee ?
                                        <Form.Check
                                            type="checkbox"
                                            {...register('isCalAdmin')}
                                            label={t("services.calculateAdministration")}
                                            onClick={issContract}
                                            checked={adminFeeStatus === "1"} // Set checked based on adminFeeStatus
                                            onChange={handleCheckboxChange} // Handle checkbox change event
                                        />
                                        :
                                        <>
                                            <p>
                                                {t("noCalculateAdminFee")}
                                            </p>
                                        </>
                                    }


                                    {
                                        expenseCheckbox ?
                                            <Form.Group>
                                                <br />
                                                <Form.Check type="checkbox" {...register('nextTransfer')} label={t("otherstran.Includeinthenexttransfer")} />
                                                <br />
                                                <Form.Check type="checkbox" {...register('terminationCal')} label={t("otherstran.includeexpensein")} />
                                            </Form.Group>
                                            : !expenseCheckbox && !expenseType && newExpenseTitle != "New recurring expense" ?
                                                <Form.Group>
                                                    <br />
                                                    <Form.Check type="checkbox" {...register('terminationCal')} label={t("otherstran.includeexpensein")} />
                                                </Form.Group>
                                                :
                                                ''
                                    }

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        {
                            PortionData && PortionData.length > 0 &&
                            <>
                                <hr />
                                <h4 className='mb-3'>Expenses {newExpenseTitle == "New recurring expense" ? t("otherstran.recurring") : t("otherstran.installment")}</h4>
                            </>
                        }
                        {
                            PortionData && PortionData.length > 0 &&
                            PortionData.map((item, index) => {
                                return (
                                    <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3" key={index}>
                                        <Col md={4}>
                                            <FloatingLabel controlId="floatingInput" label={newExpenseTitle == "New recurring expense" ? t("start") : t("maturity")}>
                                                <Form.Control type="date" min={contractStDate} placeholder={newExpenseTitle == "New recurring expense" ? t("start") : t("maturity")} className={`form-control`} {...register(`data.install_${index}.maturity`)} disabled={previousExpense && previousExpense.length > 0 ? true : false} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="floatingInput" label={t("value")}>
                                                <Form.Control type="text" placeholder={t("value")} className={`form-control`} {...register(`data.install_${index}.value`)} disabled={previousExpense && previousExpense.length > 0 ? true : false} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="floatingInput" label={t("complement")}>
                                                <Form.Control type="text" placeholder={t("complement")} className={`form-control`} {...register(`data.install_${index}.complement`)} disabled={previousExpense && previousExpense.length > 0 ? true : false} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Modal.Body>
                    <Modal.Footer className="d-flex align-items-center justify-content-end">
                        <div>
                            <Button className="me-2 me-lg-3" variant="outline-primary" onClick={expenseClose}>{t("close")}</Button>
                            {(aarinConfig == 1 && aarinSuppConfig == 1) && CanIClose ?
                                <Button type="submit" variant="primary" onClick={openErrorbox}>{t("save")}</Button>
                                :
                                <Button className="btn-width">
                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                </Button>
                            }
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ServiceService />
        </>
    )
}
export default ExpenseService;