import React, { useEffect, useState } from 'react';
import { Offcanvas, Form, Button } from 'react-bootstrap';
import RecordOnePayment from './RecordOnePayment';
import RecordInstallments from './RecordInstallments';
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import { converter, formatNumberEnglish, formatNumberPortuges } from '../../../../constants/utils';
import { nonPaymentExpenseCharge } from '../../../../../redux/slice/NonPaymentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { fetchService } from '../../../../../redux/slice/ServiceSlice';

const RecordAgreement = (props: any) => {
    const [t] = useTranslation();
    const { register, handleSubmit, setValue, getValues, reset, clearErrors, setError, formState: { errors } } = useForm();
    const { showRecordAgreement, recordAgreementClose,
        recordAgreementShow, setRecordAgreementModal,
        currentContract, formdata, setStateClear, state,
        setLoading, loading, monetory, setMonetory, interestFromContract } = props;
    const [showRecordInstallmentsModal, setRecordInstallmentsModal] = useState(false);
    let { nonPaymentSetting_Data } = useSelector((state: RootState) => state.nonPayment);
    const [payment, setpayment] = useState("one_payment");
    const dispatch = useDispatch<AppDispatch>()
    const [TotalPaid, setTotalPaid] = useState(currentContract?.["vl_emitido_recb"]);
    let SERVICELIST: any = useSelector((state: RootState) => state.service);

    let newitem = {
        expense_id: "",
        service: "",
        amount: "",
        credit: "",
        addition: "",
        supplier: "",
        fee: "",
        amortization: "",
        totalAmount: "",
        installment: "",
        observation: "",
        status: 0,
    }


    const [payment_install, setPayment_install] = useState({
        0: {
            newitem: {
                0: newitem
            }
        }
    })
    const [onceStop, setOnceStop] = useState(true);
    const handleCloseagrement = () => {
        setOnceStop(true);
        recordAgreementClose();
    }

    useEffect(() => {
        if (showRecordAgreement && onceStop) {
            let totalAMount: number = Number(currentContract?.["vl_emitido_recb"]) + (((Number(currentContract?.["vl_emitido_recb"]) * ((interestFromContract.fees || formdata.fine_rate) / 100)) * 2) + (Number(currentContract?.["vl_emitido_recb"]) * ((interestFromContract.interest  || formdata.monthly_interest) / 100)));
            if (monetory && String(monetory) !== "0") {
                totalAMount = Number(currentContract?.["vl_emitido_recb"]) + (((Number(currentContract?.["vl_emitido_recb"]) * ((interestFromContract.fees || formdata.fine_rate) / 100)) * 2) + (Number(currentContract?.["vl_emitido_recb"]) * ((interestFromContract.interest  || formdata.monthly_interest) / 100)) + (Number(currentContract?.["vl_emitido_recb"]) * (monetory / 100)));
            }
            if (nonPaymentSetting_Data && nonPaymentSetting_Data.length > 0 && nonPaymentSetting_Data?.[0]?.["BL_ALLOWCHANGES_NON"]) {
                totalAMount = Number(currentContract?.["vl_emitido_recb"]) + (((Number(currentContract?.["vl_emitido_recb"]) * (nonPaymentSetting_Data?.[0]?.["TX_INTERESTRATE_NUMBER_NON"] / 100)) * 2) + (Number(currentContract?.["vl_emitido_recb"]) * ((interestFromContract.interest  || formdata.monthly_interest) / 100)));
                if (monetory && String(monetory) !== "0") {
                    totalAMount = Number(currentContract?.["vl_emitido_recb"]) + (((Number(currentContract?.["vl_emitido_recb"]) * (nonPaymentSetting_Data?.[0]?.["TX_INTERESTRATE_NUMBER_NON"] / 100)) * 2) + (Number(currentContract?.["vl_emitido_recb"]) * ((interestFromContract.interest  || formdata.monthly_interest) / 100)) + (Number(currentContract?.["vl_emitido_recb"]) * (monetory / 100)));
                }
            }

            // let totalAMountDiscount: any = (totalAMount - (Number(currentContract?.["vl_emitido_recb"]) * (formdata.discount_rate / 100))).toFixed(2);
            let totalAMountDiscount: any = (totalAMount).toFixed(2);
            setTotalPaid(totalAMountDiscount);
            setOnceStop(false);
            dispatch(nonPaymentExpenseCharge({
                contract: currentContract["ID_CONTRATO_CON"],
                chargeId: currentContract["ID_RECEBIMENTO_RECB"]
            }));
        }
    }, [showRecordAgreement])

    const recordInstallmentsModalClose = (data: any) => {
        setRecordInstallmentsModal(false);
        setRecordAgreementModal(data ? data : true);
        setPayment_install({
            0: {
                newitem: {
                    0: newitem
                }
            }
        })
    };

    const recordInstallmentsModalShow = () => {
        recordAgreementClose();
        setRecordInstallmentsModal(true);
    }

    const handlePayment = (e: any, type: string) => {
        setpayment(type);
    }

    const [fineId, setFineId] = useState("");
    useEffect(() => {
        if(showRecordAgreement){
            let fineService: any = SERVICELIST.servicelist;
            let data = fineService.filter((item: any) => item.ST_SINCRO_PRD == "Fine" || item.ST_DESCRICAO_PRD == "Multa");
            setFineId(data?.[0]?.["ID_PRODUTO_PRD"] || "");
        }
    },[SERVICELIST.servicelist && showRecordAgreement])

    return (
        <>
            <Offcanvas className="border-sm-none" show={showRecordAgreement} onHide={handleCloseagrement} placement="end">
                <Form className="d-flex flex-column h-100">
                    <Offcanvas.Header className="border-bottom" closeButton>
                        {t("NonPayment.recordAgreement")}
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <h6 className="mb-lg-4 mb-3 fw-bold">{t("NonPayment.howWillPaymentMade")}</h6>
                        <Form.Group className="border rounded-4 p-2 my-3 min-h-56 d-flex flex-wrap align-items-center">
                            <Form.Check type="radio" id="one_payment"
                                label={t("NonPayment.inOnePayment")} name="payment"
                                defaultChecked={payment == "one_payment" ? true : false}
                                onClick={(e) => handlePayment(e, "one_payment")}
                            />
                        </Form.Group>
                        <Form.Group className="border rounded-4 p-2 my-3 min-h-56 d-flex flex-wrap align-items-center">
                            <Form.Check type="radio" id="installments"
                                label={t("NonPayment.inInstallments")} name="payment"
                                defaultChecked={payment == "one_payment" ? false : true}
                                onClick={(e) => handlePayment(e, "installments")}
                            />
                        </Form.Group>
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        <div className="border-end pe-2 pe-md-3 pe-xl-4 me-2 me-lg-3 me-xl-4 text-lg-end">
                            <span className="text-secondary">{t("newTransfer.totalAmount")}</span>
                            <span className="d-block fw-bold">R$ {converter(String(TotalPaid))  ?? "0"}</span>
                        </div>
                        <Button variant="primary" onClick={recordInstallmentsModalShow}>
                            {t("NonPayment.continue")} - {payment == "one_payment" ? t("NonPayment.inOnePayment") : t("Install")}
                        </Button>
                    </div>
                </Form>
            </Offcanvas>
            {/* modal and otoasts */}
            <RecordInstallments showRecordInstallments={showRecordInstallmentsModal}
                setRecordAgreementModal={setRecordAgreementModal}
                recordAgreementClose={recordAgreementClose}
                handleCloseagrement={handleCloseagrement} setRecordInstallmentsModal={setRecordInstallmentsModal}
                recordInstallmentsClose={recordInstallmentsModalClose} payment={payment}
                payment_install={payment_install} setPayment_install={setPayment_install}
                currentContract={currentContract} data_insert={{ newitem }}
                formdata={formdata}
                interestFromContract={interestFromContract}
                setStateClear={setStateClear}
                state={state}
                loading={loading}
                setLoading={setLoading}
                monetory={monetory} setMonetory={setMonetory}
                fineId={fineId} />
        </>
    )
}

export default RecordAgreement
