import React, { useState, MouseEvent, useEffect } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
//import moment from "moment";
import moment from "moment-timezone";
import { incomeService } from '../../../../services/incomeServices';
import { showChargeDate } from '../../../../redux/slice/contractDataSlice';
import { converter } from '../../../constants/utils';
let sHeaderData = [{
    title: '8 charges paid',
    value: 'R$ 29,779.10'
}, {
    title: '527 collections receivable',
    value: 'R$ 2,382,280.25'
}, {
    title: 'Unseen charges',
    value: '476'
}];

const ChargesOverview = ({ chargesList, chargefooter, state }) => {
    let dateBrazil  = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [headerData, setHeaderData] = useState<any>([]);
    const [summarydueDate, setsummarydueDate] = useState(0)
    const [paidCarge, setPaidChange] = useState([]);
    const [paidChargeAmount, setTotalChargeAmount] = useState(0)
    const [totalCharges, setTotalCharges] = useState<any>([])
    useEffect(() => {
        ChargesSummery();
        chagrePiad();
    }, [dispatch])

    const ChargesSummery = () => {
        const newDateSave: any = []; // Initialize an array to store formatted dates
        let contractsInCurrentMonth = 0; // Initialize the contracts count outside the loop
        chargesList.forEach((item) => {
            const inputDate = new Date(item?.DT_VENCIMENTO_RECB);
            const year = inputDate.getFullYear();
            const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
            const day = inputDate.getDate().toString().padStart(2, '0');
            const formattedDate = `${day}/${month}/${year}`;
            newDateSave.push(formattedDate);
        });

        const currentDate = new Date(dateBrazil);
        const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-based
        const currentYear = currentDate.getFullYear();

        const filteredDates = newDateSave.filter(dateStr => {
            const [day, month, year] = dateStr.split('/').map(Number);
            if (year === currentYear && month === currentMonth && day < currentDate.getDate()) {
                return true
            }
            return false;
        });

        setTotalCharges(filteredDates); // Set the total count after processing all items
    }

    const chagrePiad = () => {
        let argument = {
            FL_STATUS_RECB: '1,2',
            Maturity_Date: "2023-06",
            // type: "toReceive",
            filter: {},
            startDate: state.startDate,
            endDate: state.endDate,
            searchDate: state.searchDate,
            listType:"",
            filteSearchDate: "",
            filterEndDate  : "",
            search_type: "",
            type:"received"
        }
        let totalAmount: number = 0
        incomeService.getChargesSummery(argument).then(res => {
            if (res.data) {
                setPaidChange(res.data);
                res.data.forEach((elem) => {
                    totalAmount += elem.vl_emitido_recb;
                })
                setTotalChargeAmount(totalAmount)
            } else {
                setPaidChange([]);
            }
        }).catch(err => {
        });
    }

    return (
        <>
            {/* <!-- overview -->*/}
            <Card className="mb-lg-4 mb-3">
                <Card.Header>{t("ReceiveandReceived.Summaryof")} {t(`full_month.${moment(state.searchDate, 'MMM/YYYY').format('MMMM')}` ) + " " + moment(state.searchDate, 'MMM/YYYY').format('YYYY')  }</Card.Header>
                <Card.Body>

                    <Row className="gy-3 gx-xxl-5 gx-md-4">
                        <Col sm={4} className="border-end border-light border-xs-none">
                            <span className="d-block text-dark h3 lh-1 mb-2"><img src="assets/images/icons/brazil-real1.png" className="me-1" />{paidChargeAmount ? converter(`${paidChargeAmount}`) : 0}</span>
                            <span className="text-secondary d-block">{paidCarge.length}  {chargesList.length > 1 ? `${t("paidTobepaid.chargespaidto")}` : `${t("paidTobepaid.chargepaidsto")}`}11111 </span>
                        </Col>
                        <Col sm={4} className="border-end border-light border-xs-none">
                            <span className="d-block text-dark h3 lh-1 mb-2"><img src="assets/images/icons/brazil-real1.png" className="me-1" />{chargefooter.totalAmount ? converter(`${chargefooter.totalAmount}`) : 0}</span>
                            <span className="text-secondary d-block"> {chargefooter.totalCharge} {chargefooter.totalCharge < 2 ? `${t("paidTobepaid.chargestobereceived")}` : `${t("paidTobepaid.chargetobereceived")}`} 2222</span>
                        </Col>
                        <Col sm={4}>
                            <span className="d-block text-dark h3 lh-1 mb-2"><img src={process.env.PUBLIC_URL + "/assets/images/icons/eye_close.svg"} alt="Dollar" className="me-1 h-20" />{totalCharges.length ? totalCharges.length : 0}</span>
                            <span className="text-secondary d-block">{totalCharges.length > 1 ? `${t("paidTobepaid.Chargessent")}` : `${t("paidTobepaid.Chargesent")}`} 33333</span>
                        </Col>
                    </Row>

                </Card.Body>
            </Card>
            {/* <!-- /overview -->*/}
        </>
    )
}

export default ChargesOverview
