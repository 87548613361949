import { Form,Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import React,{ useEffect,useState }     from 'react';
import * as Yup                         from 'yup';
import { useForm  }                     from "react-hook-form";
import { useNavigate,useParams }        from 'react-router-dom';
import { yupResolver }                  from '@hookform/resolvers/yup';
import { useDispatch, useSelector }     from 'react-redux';
import { AppDispatch, RootState }       from '../../../../redux/store';
import { indexedClose,insertLunchIndex,getAllLunchIndexs }    from "../../../../redux/slice/expenditureDataSlice";
import { useTranslation }               from 'react-i18next';
import { indexForm }                    from '../../../Interface';
import Swal                             from 'sweetalert2';
import '../../../../App.css';
const IndexedService = (indexForm) => {
  const [double,setDouble]  = useState(false);
  const {id}                = useParams();
  const dispatch            = useDispatch<AppDispatch>()
  const [t]                 = useTranslation();
  const { newIndexedShow } = useSelector((state: RootState) => state.expenditureData)
  const newIndexedClose = () => { 
    dispatch(indexedClose(false)); 
    reset(); 
    setDouble(false);
  }

  const NumericOnly= (e) => { 
    const reg = /^[0-9]*[.,]?[0-9]*$/;
    let preval=e.target.value
    if (e.target.value === '' || reg.test(e.target.value)){
      return true
    }
    else {
      e.target.value = preval.substring(0,(preval.length-1))
    } 
  }
/*------------Form validation-----------*/  

  const indexValidation = Yup.object().shape({
    date: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    index: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    // index: Yup.number()
    //   .required(t('requiredMmessage.fieldRequired'))
    //   .typeError(t('requiredMmessage.Thevaluemustbeanumber'))
    //   .integer(t('requiredMmessage.Thevaluemustbeanumber'))
    //   .max(100, "requiredMmessage.Pleaseenteravaluelessthan2digit"),     
  });
  let frm = {
    resolver: yupResolver(indexValidation)
  }
  const { register,reset,handleSubmit,setValue,getValues,formState: { errors } } = useForm<indexForm>(frm);
  const onSubmit = async (data: indexForm,e: any) => {
    e.preventDefault();
    setDouble(true)
    let response = await dispatch(insertLunchIndex(data));
    if(typeof response.payload !=="undefined" && (response.payload.resCode===202 || response.payload.resCode===200)){
      setDouble(false);
      Swal.fire({
        // icon:'success',
        title:t("Sweetalert.Success"),
        text:t("Sweetalert.successget"),
        confirmButtonText:"OK",
        timer:2000})
      newIndexedClose();
      dispatch(getAllLunchIndexs({"id":id,"status":"","start": 0,"end": 10 }));   
    }
    else {
      setDouble(false)
      Swal.fire({
        title:t("Sweetalert.Error"),
        text:t("Sweetalert.somethingWent"),
        // icon:"error",
        confirmButtonText:"OK",
        timer:2000})
    }
  } 
  
  return (
    <>
      {/* <!-- New Index modal start from here --> */}
      <Modal show={newIndexedShow} onHide={newIndexedClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" value={id} {...register('id')} />
          <Modal.Header closeButton>
            <Modal.Title>Launch Index</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <FloatingLabel controlId="floatingInput" label="Month year">
                  <Form.Control type="date" placeholder="Month year" className={`form-control ${errors.date ? 'is-invalid' : ''}`} {...register('date')} />
                  <Form.Control.Feedback type="invalid">{errors.date?.message}</Form.Control.Feedback > 
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel controlId="floatingInput" label="Index">
                  <Form.Control type="text" placeholder="Index" className={`form-control ${errors.index ? 'is-invalid' : ''}`} {...register('index')} onChange={NumericOnly} />
                  <Form.Control.Feedback type="invalid">{errors.index?.message}</Form.Control.Feedback > 
                </FloatingLabel>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {/* <div className="me-sm-2 mb-sm-0 mb-2 d-flex">
              <Form.Group>
                <Form.Check type="checkbox" {...register('isAfter')}  label="After saving register another"  />
              </Form.Group> 
            </div> */}
            <Button variant="outline-primary" onClick={newIndexedClose}>Close</Button>
            <Button disabled={double} type="submit" variant="primary">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* <!-- /New Index modal end --> */}
    </>
  )
}
export default IndexedService;