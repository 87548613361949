import React, { useState, useEffect, useRef, MouseEvent } from "react";
import { useForm } from "react-hook-form";
import { Offcanvas, Modal, Form, Button, Row, Col, Badge, Alert, Table, FloatingLabel, ToastContainer, Toast } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ComProps, UsersForm, contractForm } from "../../../../Interface";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/store';

import ViewCharge from "./ViewCharge";
import DeleteItem from "./DeleteItem";
import SaveEditChanges from "./SaveEditChanges";
import SaveChanges from "./SaveChanges";
import PostFinancial from "./PostFinancial";
import SettleTransfers from "./SettleTransfers";
import { useTranslation } from "react-i18next";
import transferService from "../../../../../services/transferService";
import { transferToPostinFinancial } from "../../../../../redux/slice/transferDataSlice";
import { PrintPDF,converter, encrypt_Decrypt } from '../../../../constants/utils';
import { ServiceRecord, getTaxation } from "../../../../../redux/slice/taxationSlice";
import { fetchService } from "../../../../../redux/slice/ServiceSlice";
const TransfersSimulate = (props: any) => {
	const [t] = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	//Toggle Edit button
	const [show, toggleShow] = React.useState(true);
	const [singleViewCharge, setSingleViewCharge] = useState<any>([]);
	const [deletedItems, setdeletedItems] = useState<any>([]);
	const [sumInfo, setSumInfo] = useState<any>({});
	const { showTransferSimulation, transferSimulationClose } = props;
	let { simulateData,totalSummaryAmt,financialData,summaryData,isCheck,path } = props;
	const [updateCals, setUpdateCals] = useState(false);
	const [servicesId, setServicesId] = useState<any>()
    const [AdminFee, setAdminFee] = useState()
    const [TaxationServices, setTaxationServices] = useState<any>({})
    const [serviceRender, setServiceRender] = useState<any>({})
    const [getServiceId, setGetServiceId] = useState<any>([])
	const { taxadata, taxalist } = useSelector((state: RootState) => state.taxa)
	//View charge modal close open function
	const [showViewChargeModal, setViewChargeModal] = useState(false);
	const viewChargeModalClose = () => setViewChargeModal(false);
	const viewChargeModalShow = (index, item) => {
		item['index'] = index
		setSingleViewCharge(item);
		setViewChargeModal(true);
	}

	//Delete item modal close open function
	const [showDeleteItemModal, setDeleteItemModal] = useState(false);
	const deleteItemModalClose = () => setDeleteItemModal(false);
	const deleteItemModalShow = () => setDeleteItemModal(true);

	//Save edit changes modal close open function
	const [showSaveEditChangesModal, setSaveEditChangesModal] = useState(false);
	const saveEditChangesModalClose = () => setSaveEditChangesModal(false);
	const saveEditChangesModalShow = () => setSaveEditChangesModal(true);

	//Post financial modal close open function
	const [showPostFinancialModal, setPostFinancialModal] = useState(false);
	const postFinancialModalClose = () => setPostFinancialModal(false);
	const postFinancialModalShow = () => setPostFinancialModal(true);

	//Settle transfers modal close open function
	const [showSettleTransfersModal, setSettleTransfersModal] = useState(false);
	const settleTransfersModalClose = () => setSettleTransfersModal(false);
	const settleTransfersModalShow = () => setSettleTransfersModal(true);

	//Save changes modal close open function
	const [showSaveChangesModal, setSaveChangesModal] = useState(false);
	const saveChangesModalClose = () => {
		setSaveChangesModal(false);
		transferSimulationClose(true);
	};
	// const saveChangesModalShow = () => setSaveChangesModal(true);
	const saveChangesModalShow = () => saveChangesModalClose();

	const [showToast, setToastShow] = useState(false);
	const [ToastText, setShowToastText] = useState('');
	const commonToastClose = () => setToastShow(false);
	const commonToastShow = (text) => {
		if (text) { setShowToastText('Updated successfully!'); }
		setToastShow(true);
		setTimeout(() => {
			setToastShow(false);
			setShowToastText('');
		}, 2000);
	}

	const [ipinputFields, setIPInputFields] = useState({
		[`open_0`]: {
			ip1: "",
			ip2: "",
		},
	});

	const updateTransaction = (item, index) => {
		// UPDATE
		setSingleViewCharge(item);
		drawerMode('editor', index, item);
	};
	const cancelTransaction = (item, index) => {
		drawerMode('detailView', index, item);
	};

	const drawerMode = (mode, index, item) => {
		if (mode == 'editor') {
			setValue('identifier', index);
			/*
			let adminAmount = parseFloat(item.adminAmount);
			let total = item.total
			if( total ){
				ipinputFields['open_0'] = { ip1 : "Standard rent (21/24)", ip2 : total }
			}
			if( adminAmount >= 0 ){
				ipinputFields['open_1'] = { ip1 : "Administration fee", ip2 : (-1 * adminAmount) }
			}
			*/
			// paid = item.paid
			let edata: any = document.querySelector(`.ebtn${index}`);
			edata.classList.add('d-none');
			let cdata: any = document.querySelector(`.cbtn${index}`);
			cdata.classList.remove('d-none');
			let detailBx: any = document.querySelector(`.detailsBox${index}`);
			detailBx.classList.add('d-none');
			let updateBx: any = document.querySelector(`.updateBox${index}`);
			updateBx.classList.remove('d-none');
		} else {
			let edata: any = document.querySelector(`.ebtn${index}`);
			edata.classList.remove('d-none');
			let cdata: any = document.querySelector(`.cbtn${index}`);
			cdata.classList.add('d-none');
			let detailBx: any = document.querySelector(`.detailsBox${index}`);
			detailBx.classList.remove('d-none');
			let updateBx: any = document.querySelector(`.updateBox${index}`);
			updateBx.classList.add('d-none');
		}
	}
	useEffect(() => {
		if (taxadata && taxadata.length > 0) {
			setServiceRender(taxadata[0])
		}
	}, [taxadata])
	useEffect(() => {
		// Asynchronous function within the useEffect hook
		(async () => {
			// Dispatch the fetchService action
			let newdispatch = await dispatch(fetchService());
			// Set the service state with the fetched data
			setGetServiceId(newdispatch.payload);
		})()

	}, [])
	useEffect(()=>{
		dispatch(getTaxation())
        dispatch(ServiceRecord())
	},[dispatch])

	useEffect(() => {
		setValue('identifier', '')
		let newitem: (any) = [];
		if (simulateData && simulateData.length) {
			simulateData.map((itm, index) => {
				setServicesId(itm.serviceId)
				simulateData[index]['newItems'] = [];
				simulateData[index]['newItems'].push({
					ip1: "Standard rent",
					ip2: itm.total
				});
				let adminAmount = parseFloat(itm.adminAmount);
				simulateData[index]['newItems'].push({
					ip1: "Administration fee",
					ip2: (adminAmount)
				});
				if (itm.fine > 0) {
					simulateData[index]['newItems'].push({
						ip1: "Fee",
						ip2: (itm.fine)
					});
				}
				if (itm.interest > 0) {
					simulateData[index]['newItems'].push({
						ip1: "Interest",
						ip2: (itm.interest)
					});
				}
				if (itm.bankFee > 0) {
					simulateData[index]['newItems'].push({
						ip1: "Charge DOC/TED bank fee",
						ip2: (itm.bankFee)
					});
				}
				if (itm.newItemsAdded) {
					itm.newItemsAdded.map((nwitm, index) => {
						simulateData[index]['newItems'].push(
							{
								ip1: nwitm.ST_COMPLEMENTO_COMP,
								ip2: nwitm.ST_VALOR_COMP ? nwitm.ST_VALOR_COMP : 0,
								delete: "true"
							},
						);
					});
					// simulateData[index]['newItems'] = newitem;
				}
			});
		}
		makeSumCalculationstoShow();
		// setIPInputFields(newitem);
	}, [showTransferSimulation])

	const makeSumCalculationstoShow = () => {
		let SumTotal = 0;let bankFee=0; let adminFee = 0; let totalPaid = 0; let standardRent = 0;
		if (simulateData && simulateData.length) {
			simulateData.map((itm, index) => {
				if( itm.newItems && itm.newItems.length ){
					itm.newItems.map( (itm, index) => {
						//console.log("itm",itm)
						
						if( itm.ip1 == "Administration fee" ){
							adminFee   += parseFloat(itm.ip2);
						}
						if( itm.ip1 == "Standard rent" ){
							SumTotal   += parseFloat(itm.ip2);
							standardRent   += parseFloat(itm.ip2);
						}		
						if( itm.ip1 == "Charge DOC/TED bank fee" ){
							bankFee   += parseFloat(itm.ip2);
						}			
					})
					totalPaid  += SumTotal - adminFee - bankFee;
				}
				// SumTotal += itm.totalAmt;
			})
			setSumInfo({ total: SumTotal, length: simulateData.length,bankFee:bankFee, adminFee: adminFee, totalPaid: totalPaid, standardRent: standardRent });
		}
	}

	// ++++++++++++++++++++
	// +++++++FROM 
	// ++++++++++++++++++++
	var form_validationSchema = Yup.object().shape({});
	var frm = { resolver: yupResolver(form_validationSchema) };
	const { register, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm<contractForm>(frm);
	const userFormSubmit = async (data) => {
		let identifier = (data.identifier - 1)
		let IPDate: any = []; let finalAmount = 0;
		let newItems: any = [];
		if (ipinputFields) {
			let i = 0;
			for (var key in ipinputFields) {
				var value = ipinputFields[key];
				if (key.indexOf("open") >= 0) {
					IPDate.push(value);
					if (i > 1) {
						newItems.push(value);
					}
					finalAmount += parseFloat(value.ip2);
				}
				i++;
			}
		}
		setSingleViewCharge['total'] = finalAmount;
		simulateData[identifier]['total'] = singleViewCharge.paid;
		simulateData[identifier]['adminAmount'] = singleViewCharge.newItems[1].ip2;
		simulateData[identifier]['paid'] = finalAmount;
		simulateData[identifier]['newItems'] = newItems;
		simulateData[identifier]['deleteItems'] = deletedItems;

		const response = await transferService.updateTransactionAndAddCharge({ paramData: JSON.stringify(singleViewCharge) })
		if (response.resCode == 201) {
			drawerMode('detailView', (identifier + 1), '');
		}
	}
	const addNewIPAccess = (e: MouseEvent<HTMLButtonElement>, parentIndex) => {
		let newindex = Object.keys(ipinputFields)?.length + 1;
		/*
		let newitem = [
			{
				[`open_${newindex - 1}`]: {
					ip1: "",
					ip2: "",
					delete : "true"
				},
			},
		];
		setIPInputFields({ ...ipinputFields, ...newitem[0] });
		*/
		let simData = simulateData;
		simulateData = '';
		simData[parentIndex]['newItems'].push({
			ip1: "",
			ip2: 0,
			ip3: 0,
			delete: "true"
		});
		simulateData = simData;
		setUpdateCals(true);
	};

	useEffect(() => {
		if (updateCals) {
			makeSumCalculationstoShow();
			setUpdateCals(false);
		}
	}, [updateCals, simulateData])


	const deleteIpField = (e: MouseEvent<HTMLButtonElement>, indexs, parentIndex) => {
		e.preventDefault();
		// New
		simulateData[parentIndex]['newItems'].splice(indexs, 1);
		updateCalculations('', parentIndex);
		setUpdateCals(true);

		/*
		let dataall: any = [ipinputFields];
		let newInputField: any = Object.values(dataall[0]);
		let data: any = {};

		let item = newInputField[indexs];
		deletedItems.push(item)
		setdeletedItems(deletedItems);

		newInputField.splice(indexs, 1);
		newInputField.forEach(async (item, index) => {
			data[`open_${index}`] = item;
		});
		deleteItemModalShow();
		setIPInputFields(data);
		*/
	};
	// culumn is change the data one by one from the opening horse
	const setIPAddress = (index, evnt, parentIndex) => {
		const { name, value } = evnt.target;
		if (name == 'ip2' && parseFloat(value) >= 0) {
			simulateData[parentIndex]['newItems'][index][name] = parseFloat(value).toFixed(2);
		} else {
			simulateData[parentIndex]['newItems'][index][name] = value;
		}
		updateCalculations(value, parentIndex);
	};

	const updateCalculations = (value, parentIndex) => {
		let finalAmount = 0;
		let mits = simulateData[parentIndex]['newItems'];
		if (mits && mits.length) {
			mits.map((itm, index) => {
				if (itm.ip2) {
					finalAmount += parseFloat(itm.ip2);
				}
			})
		}
		simulateData[(parentIndex)]['paid'] = finalAmount;
		makeSumCalculationstoShow();
		setSingleViewCharge(singleViewCharge);
		setUpdateCals(true);
	}

	//  Mark Verified
	const updateTransferStatus = async (e, item) => {
		let data = { status: e.target.checked, ID_RECEBIMENTO: item.id }
		const response = await transferService.updateTransferStatus(data)
		if (response.resCode == 201) {
			commonToastShow(`markVerified`);
		}
	}
	useEffect(() =>{
        getServiceId.forEach((item) => {
            if (item.ID_PRODUTO_PRD == "17") {
                taxalist.forEach((taxserviceCode) => {
                    if (taxserviceCode.id == item.ID_CODIGOSERVICO_CSE) {
                        setTaxationServices(taxserviceCode)
                    }
                })
            }
        })
    },[showPostFinancialModal])
	// SUBMIT 
	const commonPostFinancialShow = async () => {
		// get RECE IDs
		let invoiceData: any = []
		const retrievedData: any = localStorage?.getItem('tipoLogradouro');
        let tipoLogradouro_data: string = "";
        let city_codes: string = ""
        if (retrievedData) {
            const parsedData = JSON.parse(retrievedData);
            tipoLogradouro_data = parsedData?.tipoLogradouro
            city_codes = parsedData?.city_code
        }
		let recIds: string[] = [];
		let totals: any = [];
		if (simulateData && simulateData.length) {
			simulateData.forEach((element:any) => {
				if (element && element.id) {
					recIds.push(element.id);
					totals.push(element.total);
					invoiceData.push({
                        ID_RECEBIMENTO_RECB: element.id,
                        AdminFee: element.adminAmount,
                        invoceid: element.invoice,
                        servicecode: TaxationServices.servicecode ? TaxationServices.servicecode : "",
                        description: TaxationServices.description,
                        CNAE: TaxationServices.CNAE,
                        taxcode: TaxationServices.taxcode,
                        cpfCnpj: serviceRender.CNPJ,
                        municipal_registration: serviceRender.municipal_registration,
                        social_contribution_tax: serviceRender.social_contribution_tax,
                        ISSQNrate: TaxationServices.ISSQNrate ? TaxationServices.ISSQNrate : "",
                        typeofpatio: tipoLogradouro_data,
                        city_code: city_codes,
						saleOf: element.saleOf,
                    })
				}
			});
		}
		let postData = {
			ID_RECEBIMENTO: recIds,
			totals: totals,
			invoiceData: invoiceData
		}	
console.log(postData, "postData")
		let Trresponse = await dispatch(transferToPostinFinancial(postData));
		if (typeof Trresponse.payload !== "undefined" && (Trresponse.payload.resCode === 202 || Trresponse.payload.resCode === 200)) {
			setToastShow(true);
			setPostFinancialModal(false);
			setTimeout(() => {
				setToastShow(false);
				window.location.reload();
			}, 5000);
		} else {
			Swal.fire({
				title: t("Sweetalert.Error"),
				text: Trresponse.payload.message,
				// icon: 'success',"error",
				confirmButtonText: "OK",
				timer: 2000
			});
		}
	}

	const toFixed = (amount) => {
		if (amount) {
			return parseFloat(amount).toFixed(2);
		} else { return amount; }
	}
	const printSimutlate = async(evt)=>{
		evt.preventDefault();
		window.open(path, "_blank")
	}
	return (
		<>
			<Offcanvas
				className="border-sm-none"
				show={showTransferSimulation}
				onHide={transferSimulationClose}
				placement="end"
			>
				{/* <Form className="d-flex flex-column h-100"> */}

				<Offcanvas.Header className="border-bottom">
					<Offcanvas.Title className="fw-bold d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center w-100">
						{t("newTransfer.transferSimulation")}
						<div className="text-primary ms-sm-auto d-flex justify-sm-end justify-content-between align-items-center">
							<span className="me-3 me-lg-4 h6 mb-0 fw-bold" onClick={(e)=>printSimutlate(e)}>{t("newTransfer.printSimulation")}</span>
							<span onClick={saveChangesModalShow} className="cursor-pe">
								<img src="assets/images/icons/cross.svg" alt="Close" className="h-16" />
							</span>
						</div>
						<SaveChanges
							showSaveChanges={showSaveChangesModal}
							saveChangesClose={saveChangesModalClose}
						/>
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body id="divcontents">
					{simulateData && simulateData.length ?
						simulateData.map((item: any, Pindex: number) => {
							return (
								<>
									<div key={`transfersimulate${Pindex + 1}`} className="border border-light rounded-4 p-lg-4 p-3 mb-lg-4 mb-3">
										<div className="d-sm-flex align-items-center justify-content-between mb-3">
											<h6 className="mb-0 fw-bold d-flex align-items-center">
												{t("transfer")} {Pindex + 1}
											</h6>
											<ul className="mb-0 list-inline fw-bold">
												<li className="list-inline-item">
													<Button variant="link" className="p-0 fw-bold"
														onClick={() => viewChargeModalShow((Pindex + 1), item)}>
														{t("newTransfer.viewCharge")}
													</Button>
												</li>
												<li className="list-inline-item">
													{/*<Button
														variant="link"
														onClick={() => updateTransaction(item, (Pindex + 1))}
														className={`p-0 fw-bold ebtn${Pindex + 1}`}
													>{t("button.edit")}</Button>*/}
													<Button
														variant="link"
														onClick={() => cancelTransaction(item, (Pindex + 1))}
														className={`p-0 fw-bold cbtn${Pindex + 1} d-none`}
													>{t("button.cancel")}</Button>
												</li>
											</ul>
										</div>
										<ul className="list-inline d-flex flex-md-nowrap">
											<li className="list-inline-item border-end border-sm-none me-3 pe-md-3">
												<p className="fs-12 text-secondary mb-1">{t("newTransfer.forecast")}</p>
												<h6 className="mb-md-0">{item.liquidate}</h6>
											</li>
											<li className="list-inline-item border-end border-sm-none  w-auto overflow-hidden me-3 pe-md-3">
												<p className="fs-12 text-secondary mb-1">{t("newTransfer.contract")}</p>
												{/* <h6 className="mb-md-0">{item.propertyId} {item.propertIndentifier ? ' / ' + item.propertIndentifier + ' ' : ''}</h6> */}
												<h6 className="mb-md-0 text-truncate"> {t("Dashboard.Contract")} {item.propertIndentifier} / {item.lease_count ? item.lease_count : "0"}</h6>
											</li>
											<li className="list-inline-item border-end border-sm-none me-3 pe-md-3">
												<p className="fs-12 text-secondary mb-1">{t("newTransfer.charge")}</p>
												<h6 className="mb-md-0">{item.id}</h6>
											</li>
											<li className="list-inline-item w-auto overflow-hidden">
												<p className="fs-12 text-secondary mb-1">{t("tenant")}</p>
												<h6 className="mb-md-0 text-truncate">{item.tenant.length > 0 ? item.tenant[0].ST_NOME_PES : item.tenant.length > 1 ? '+' : '?'}</h6>
											</li>
										</ul>
										<div className="d-block">
											<hr className="w-20" />
										</div>

										<div className={`detailsBox${Pindex + 1} detailsWidget`}>
											<Table borderless className="mb-3">
												<thead>
													<tr>
														<th className="text-secondary ps-0 py-0">
															{t("description")}
														</th>
														<th className="text-secondary text-end pe-0 py-0">
															{t("formLabel.value")} (R$)
														</th>
													</tr>
												</thead>
												<tbody>
													{/* <tr>
													<td className="ps-0 pb-0">
														<span className="d-block mb-1">
															{t("newTransfer.standardRent")} (21/24)
														</span>
														<span className="d-block fs-12 text-secondary">
															{t("newTransfer.refFrom")} {item.contractStart} {t("to")} {item.liquidate}
														</span>
													</td>
													<td className="text-end pe-0 pb-0">R$ {item.total}</td>
												</tr>
												<tr>
													<td className="ps-0 pb-0">{t("Dashboard.Administrationfee")}</td>
													<td className="text-end pe-0 pb-0">- {item.adminAmount}</td>
												</tr>  */}
													{item.ownerGetResult && item.ownerGetResult.length &&
														item.ownerGetResult.map((nwitm, index) => (
															<>
																<tr>
																	<td className="ps-0 pb-0 pt-3">
																		{ nwitm.ID_PRODUTO_PRD == 14 ? t("newTransfer.standardRent") : nwitm.ST_DESCRICAO_PRD }
																		<span className="d-block fs-12 text-secondary">{ nwitm.ID_PRODUTO_PRD == 14 ? "Ref from " +item.maturity+" to "+item.maturityEnd : "" }</span>
																		{/*{ nwitm.admTaxAmount ? t("Dashboard.Administrationfee") + nwitm.admTaxAmount : " " } */}
																	</td>
																	<td className="text-end pe-0 pb-0 pt-3">{  nwitm.VL_VALOR_IMOD ? converter(String(nwitm.VL_VALOR_IMOD)) : "0,00" }</td>
																</tr>
															</>
														))}

													{ 
														item.adminAmount ? 
														<tr>
															<td className="ps-0 pb-0 pt-3">
																{ t("Dashboard.Administrationfee") }		
															</td>
															<td className="text-end pe-0 pb-0 pt-3"><span className="d-block text-nowrap">- {item.adminAmount ? converter(`${item.adminAmount}`) : "0,00" }</span></td>
														</tr>
														: ''
													}
													{ 
														item.bankFee ? 
														<tr>
															<td className="ps-0 pb-0 pt-3">
																{t('chargeDocBankFee')}			
															</td>
															<td className="text-end pe-0 pb-0 pt-3"><span className="d-block text-nowrap"> - {item.bankFee ? converter(`${item.bankFee}`) : "0,00" }</span></td>
														</tr>
														: ''
													}
													{ 
														item.taxAmt ?
														<tr>
															<td className="ps-0 pb-0 pt-3">
																IR (3.6)
															</td>
															<td className="text-end pe-0 pb-0 pt-3"><span className="d-block text-nowrap">- {item.taxAmt ? converter(`${item.taxAmt}`) : "0,00"}</span></td>
														</tr>
														: ''
													}
													{ 
														item.VL_TXMULTA_RECB ?
														<tr>
															<td className="ps-0 pb-0 pt-3">
																{ t("ReceiveandReceived.Fine") }
															</td>
															<td className="text-end pe-0 pb-0 pt-3"><span className="d-block text-nowrap">+ {item.VL_TXMULTA_RECB ? converter(`${item.VL_TXMULTA_RECB}`) : "0,00"}</span></td>
														</tr>
														: ''
													}
													{ 
														item.VL_TXJUROS_RECB ?
														<tr>
															<td className="ps-0 pb-0 pt-3">
																{ t("ReceiveandReceived.Interest") }
															</td>
															<td className="text-end pe-0 pb-0 pt-3"><span className="d-block text-nowrap">+ {item.VL_TXJUROS_RECB ? converter(`${item.VL_TXJUROS_RECB}`) : "0,00"}</span></td>
														</tr>
														: ''
													}
													{ 
														item.VL_TAXACOBRANCA_RECB ?
														<tr>
															<td className="ps-0 pb-0 pt-3">
																{ t("formReceipt.atMonetary") }
															</td>
															<td className="text-end pe-0 pb-0 pt-3"><span className="d-block text-nowrap">+ {item.VL_TAXACOBRANCA_RECB ? converter(`${item.VL_TAXACOBRANCA_RECB}`) : "0,00"}</span></td>
														</tr>
														: ''
													}
													{ 
														item.VL_DESCONTOCALCULADO_RECB ?
														<tr>
															<td className="ps-0 pb-0 pt-3">
																{ t("formReceipt.discount") }
															</td>
															<td className="text-end pe-0 pb-0 pt-3"><span className="d-block text-nowrap">- {item.VL_DESCONTOCALCULADO_RECB ? converter(`${item.VL_DESCONTOCALCULADO_RECB}`) : "0,00"}</span></td>
														</tr>
														: ''
													}
													<tr>
														<td className="fw-bold ps-0 pb-0 pt-3">{ t("total") }</td>
														<td className="fw-bold text-end pe-0 pb-0 pt-3"><span className="d-block text-nowrap"> {item.paid ? converter(String(item.paid)) : "0,00" }</span></td>
													</tr>
													{  
		                                                item.totalNxtRentalFee ?
		                                                <tr>
		                                                    <td className="mb-1 mb-sm-0 pt-3">{t("nextRentalFee")}</td>
		                                                    <td colSpan={2} className="fw-bold text-end pe-0 pb-0 pt-3">{ item.totalNxtRentalFee ? converter(Math.abs(item.totalNxtRentalFee)) : "0,00"}</td>
		                                                </tr>
		                                                : ''
		                                            }
												</tbody>
											</Table>
											<div className="d-block">
												<hr className="w-20" />
											</div>
											<Table borderless className="mb-3">
												<thead>
													<tr>
														<th className="text-secondary ps-0 pb-0 py-0">
															{t("newTransfer.beneficiaries")}
														</th>
														<th className="text-secondary text-end pe-0 py-0">
															{t("formLabel.value")} (R$)
														</th>
													</tr>
												</thead>
												<tbody>
													{ item.ownerList && item.ownerList.length ?
														item.ownerList.map((item, index) => (
														<>
															<tr key={ index }>
																<td className="ps-0 pb-0 pt-3">
																	<span className="d-block mb-1">
																		{ item.ST_NOME_PES }
																	</span>
																	<span className="d-block fs-12 text-secondary">
																		CPF : { item.ST_CNPJ_PES }
																	</span>
																</td>
																<td className="text-end pe-0 pb-0 pt-3">
																	<span className="d-block text-nowrap">
																		{ item.amount ? converter(item.amount) : "0,00"}
																	</span>
																</td>
															</tr>
														</>
														)) :
														<></>
													}
												</tbody>
											</Table>
											<Form.Group className="bg-light rounded-4 p-2 mt-3 min-h-56 d-flex flex-wrap align-items-center">
												<Form.Check id="verified_mark_2" onClick={(e) => { updateTransferStatus(e, item) }} label={t("newTransfer.markVerified")} />
											</Form.Group>
										</div>

										<div className={`updateBox${Pindex + 1} updateWidget d-none`}>
											<Form onSubmit={handleSubmit(userFormSubmit)} className="transferSimulateTemplate">
												<input type="hidden" {...register("identifier")} />
												{
													item.newItems &&
													item.newItems.length &&
													item.newItems.map((itm: any, findex) => {
														return (
															<Row className="g-3 mb-3" key={`ands_${findex}`}>
																<Col md={7}>
																	<Form.Control
																		data-ip={findex}
																		onChange={(evnt) => setIPAddress(findex, evnt, (Pindex))}
																		name="ip1"
																		value={`${itm?.["ip1"]}`}
																		type="text"
																		placeholder={t("description")}
																		disabled={(itm['delete'] == "true") ? false : true}
																	/>
																</Col>
																<Col md>
																	<Form.Control
																		data-ip={findex}
																		onChange={(evnt) => setIPAddress(findex, evnt, (Pindex))}
																		name="ip2"
																		value={`${itm?.["ip2"]}`}
																		type="text"
																		placeholder={t("formLabel.value") + " (R$)"}
																		disabled={(itm['delete'] == "true") ? false : true}
																	/>
																</Col>
																<Col md="auto">
																	{itm?.["delete"] == "true" &&
																		<>
																			<Button variant="light" className="bg-light min-w-auto border-light rounded-4"
																				onClick={(e) => deleteIpField(e, findex, (Pindex))}
																			>
																				<img
																					src="assets/images/icons/delete-danger.svg"
																					alt="Delete"
																					className="h-18"
																				/>
																			</Button>
																		</>
																	}
																</Col>
															</Row>
														);
													})
												}
												<div className="text-center d-flex align-items-center justify-content-center">
													<Button variant="link" className="p-0" onClick={(e) => addNewIPAccess(e, (Pindex))}>
														<img
															src="assets/images/icons/plus-light.svg"
															alt="Documents add"
															className="h-20 me-2 fw-bold"
														/>
														{t("button.additem")}
													</Button>
												</div>

												<FloatingLabel className="my-3" controlId="total_amount" label={t("newTransfer.totalAmount") + " (R$)"}>
													<Form.Control
														type="text"
														placeholder={t("newTransfer.totalAmount") + " (R$)"}
														value={ item.paid }
														disabled
													/>
												</FloatingLabel>
												<Button type="submit" variant={"primary"}
													name="save" value={`${Pindex}`}> {t("button.toSave")}
												</Button>
												{/* <button type='submit' className='uploadFilenow'></button> */}
											</Form>
										</div>
									</div>
								</>
							)
						}) : <>
							NOT FOUND
						</>
					}

					<h6 className="fw-bold mb-lg-4 mb-3">{t("ReceiveandReceived.Summary")}</h6>
					<Table borderless className="mb-3">
						<thead>
							<tr>
								<th
									style={{ width: "280px" }}
									className="text-secondary ps-0 py-0"
								>
									{t("newTransfer.financialInstitution")}
								</th>
								<th className="text-secondary py-0">{t("TransferPage.payments")}</th>
								<th className="text-secondary text-end pe-0 py-0">
									{t("formLabel.value")} (R$)
								</th>
							</tr>
						</thead>
						<tbody>
							{ financialData && financialData.length ?
								financialData.map((item, index) => (
									<>
										<tr key={ index }>
											<td className="ps-0 pb-0 pt-3">{ item.bankName }</td>
											<td className="pb-0 pt-3">1</td>
											<td className="text-end pe-0 pb-0 pt-3">{ item.amount ? converter(String(item.amount)) :"0,00" }</td>
										</tr>
									</>
								)) :
								<></>
							}
						</tbody>
					</Table>
					<div className="d-block">
						<hr className="w-20" />
					</div>
					<Table borderless className="mb-3">
						<thead>
							<tr>
								<th
									style={{ width: "280px" }}
									className="text-secondary ps-0 py-0"
								>
									{t("newTransfer.beneficiary")}
								</th>
								<th className="text-secondary py-0">{t("newTransfer.transfers")}</th>
								<th className="text-secondary text-end pe-0 py-0">
									{t("formLabel.value")} (R$)
								</th>
							</tr>
						</thead>
						<tbody>
							{ financialData && financialData.length ?
								financialData.map((item, index) => (
									<>
										<tr key={ index }>
											<td className="ps-0 pb-0 pt-3">{ item.ST_NOME_PES }</td>
											<td className="pb-0 pt-3">1</td>
											<td className="text-end pe-0 pb-0 pt-3">{ item.amount ? converter(String(item.amount)): "0,00" }</td>
										</tr>
									</>
								)) :
								<></>
							}
							<tr>
								<td className="fw-bold ps-0 pb-0 pt-3">{t("total")}</td>
								<td className="pb-0 pt-3">{ (financialData && financialData.length ) ? financialData.length : '' }</td>
								<td className="fw-bold text-end pe-0 pb-0 pt-3">
									{totalSummaryAmt ? converter(totalSummaryAmt) : "0,00" }
								</td>
							</tr>
						</tbody>
					</Table>
					<div className="d-block">
						<hr className="w-20" />
					</div>
					<Table borderless className="mb-3">
						<thead>
							<tr>
								<th className="text-secondary ps-0 py-0">
									{t("newTransfer.totalStatementAmount")}
								</th>
								<th className="text-secondary text-end pe-0 py-0">
									{t("formLabel.value")} (R$)
								</th>
							</tr>
						</thead>
						<tbody>
							{ summaryData && summaryData.length ?
								summaryData.map((item, index) => {
									if(item.amount){
										return(									
											<tr key={index} >
												<td className="ps-0 pb-0 pt-3">
													{ item.id==14 ? t("newTransfer.standardRent") : item.title=="Administration fee" ? t("Dashboard.Administrationfee") : item.title == "Interest" ? t('formReceipt.allFees') : item.title == "Fine" ? t('formReceipt.Fine') : item.title }

												</td>
												<td className="text-end pe-0 pb-0 pt-3">{ item.title!="Standard rent" ? item.sign : '' } { item.amount ? converter(item.amount) :"0,00"}</td>
											</tr>
										)
									}
								}) :
								<></>
							}
							<tr>
								<td className="fw-bold ps-0 pb-0 pt-3">{t("total")}</td>
								<td className="fw-bold text-end pe-0 pb-0 pt-3">{totalSummaryAmt ? converter(String(totalSummaryAmt)): "0,00" }</td>
							</tr>
						</tbody>
					</Table>
					<p className="text-secondary mb-0">
						{ simulateData && simulateData.length ? simulateData.length : 'No' } { t("newTransfer.propertyOwnersPayed") }. { t("total") } R$ {totalSummaryAmt ? converter(totalSummaryAmt) : "0,00" }
					</p>
				</Offcanvas.Body>
				<div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer" id="transtsxsimulatebtn">
					<Button
						variant="outline-primary"
						onClick={settleTransfersModalShow}
					>
						{t("newTransfer.settle")}
					</Button>
					<SettleTransfers
						showSettleTransfers={showSettleTransfersModal}
						settleTransfersClose={settleTransfersModalClose}
						simulateData={simulateData}
						transferSimulation={transferSimulationClose}
					/>
					<Button type="button" className="me-0" onClick={postFinancialModalShow}>
						{t("newTransfer.postInFinancial")}
					</Button>
					{/* <PostFinancial
						showPostFinancial={showPostFinancialModal}
						postFinancialClose={postFinancialModalClose}
					/> */}
					<Modal show={showPostFinancialModal} onHide={postFinancialModalClose}>
						<Form>
							<Modal.Header closeButton>
								<Modal.Title>{t("newTransfer.postInFinancial")}</Modal.Title>
							</Modal.Header>
							<Modal.Body className="pb-2 pt-0">
								{t("newTransfer.postInFinancialContent")}
							</Modal.Body>
							<Modal.Footer>
								<Button variant="outline-primary" onClick={postFinancialModalClose}>
									{t("button.cancel")}
								</Button>
								<Button variant="primary" onClick={commonPostFinancialShow}>
									{t("button.confirm")}
								</Button>
							</Modal.Footer>
						</Form>
					</Modal>
					<ToastContainer className="p-3 position-fixed" position="bottom-end" style={{ zIndex: 1 }}>
						<Toast bg="dark" className="rounded-4 text-white border-0" show={showToast} onClick={commonToastClose}>
							<Toast.Body className="d-flex align-items-center p-3">
								<img className="me-2 me-xxl-4 me-xl-3" src="assets/images/icons/check-circle-success.svg" alt="arrow right" />
								{ToastText != '' ?
									<span className="me-2">{t("Sweetalert.statusUpdate")}</span>
									: <span className="me-2">{t("newTransfer.transfersPostedInFinancial")}</span>
								}
								<span className="ms-auto cursor-pe" onClick={commonToastClose}><img src="assets/images/icons/cross-white.svg" alt="arrow right" /></span>
							</Toast.Body>
						</Toast>
					</ToastContainer>
					<SaveEditChanges
						showSaveEditChanges={showSaveEditChangesModal}
						saveEditChangesClose={saveEditChangesModalClose}
					/>
				</div>
				{/* </Form> */}
				<ViewCharge
					showViewCharge={showViewChargeModal}
					viewChargeClose={viewChargeModalClose}
					singleViewCharge={singleViewCharge}
					summaryData={summaryData}
					totalSummaryAmt={totalSummaryAmt}
				/>
			</Offcanvas>


			<ToastContainer className="p-3 position-fixed" position="bottom-end" style={{ zIndex: 1 }}>
				<Toast bg="dark" className="rounded-4 text-white border-0" show={showToast} onClick={commonToastClose}>
					<Toast.Body className="d-flex align-items-center p-3">
						<img className="me-2 me-xxl-4 me-xl-3" src="assets/images/icons/check-circle-success.svg" alt="arrow right" />
						<span className="me-2">{t("newTransfer.transfersRegisteredSettled")}</span>
						<span className="ms-auto cursor-pe" onClick={commonToastClose}><img src="assets/images/icons/cross-white.svg" alt="arrow right" /></span>
					</Toast.Body>
				</Toast>
			</ToastContainer>

		</>
	);
};

export default TransfersSimulate;
function setServiceRender(arg0: any) {
	throw new Error("Function not implemented.");
}

