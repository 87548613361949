import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import userEvent from '@testing-library/user-event';
import Swal from 'sweetalert2';

export interface Credentials {
    email: string;
    password: string;
}


interface login {
    field: string
    result: object[]
    loading: boolean
    isError: boolean
    isErromessage: ''
    code: number
}

const initialState: login = {
    code: 3,
    field: "",
    result: [],
    loading: false,
    isError: false,
    isErromessage: ''
} 

const loginSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {}, 
})
export default loginSlice.reducer 