import React, { useEffect, useRef, useState } from 'react';
import { Form, Row, Col, InputGroup, FloatingLabel, Card, OverlayTrigger, Tooltip, Accordion, Toast, ToastContainer, Button } from 'react-bootstrap';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch } from 'react-redux';
import { allEmailTemplate, emailPost, listTemplateContent, mailData } from '../../../../../redux/slice/SendEmailSlice';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { config } from '../../../../../services/apiConfig';
import customerService from '../../../../../services/customerService';
import Swal from 'sweetalert2';
import Share from '../../Documents/Folder/Share';
import PrintFolderDetail from '../../Documents/Folder/PrintFolderDetail';
import { contractForm } from '../../../../Interface';
import { newElectronicEnvelopeOpen } from '../../../../../redux/slice/contractDataSlice';



const NotifyEmail = () => {
    const navigate = useNavigate();
    const [t] = useTranslation();
    const location = useLocation();
    // console.log("location state", location.state);
    //Common toast
    const dispatch = useDispatch<AppDispatch>()
    const buttonRef = useRef<any>();
    const [showToast, setToastShow] = useState(false);
    const [logoPath, setLogoPath] = useState<any>("/logos/logo.png");
    const { emailTemplateLst, templatevariable } = useSelector((state: RootState) => state.emailTemplate)
    const { getlogo, status } = useSelector((state: RootState) => state.companyData);
    const [templateId, setTemplateId] = useState<any>("");
    const [showPrintDetailModal, setPrintDetailModal] = useState(false);
    const printDetailModalClose = () => setPrintDetailModal(false);
    const printDetailModalShow = () => setPrintDetailModal(true);
    const [showShareModal, setShareModal] = useState(false);
    const shareModalClose = () => setShareModal(false);
    const shareModalShow = () => setShareModal(true);
    let [desc, setDesc] = useState('');
    const [variableData, setVariableData] = useState<any>({});
    const [contractVariableData, setContractVariableData] = useState<any>({})
    const [propertyVariableData, setPropertyVariableData] = useState<any>({});
    const [ownerVariableData, setOwnerVariableData] = useState<any>({});
    const [tenantVariableData, setTenantVariableData] = useState<any>({});
    const [getAmendmant, setGetAmendmant] = useState<any>([])
    const [selectionData, setSelectionData] = useState<any>([]);
    const [firstSelection, setFirstSelection] = useState('');
    const [secondSelection, setSecondSelection] = useState('');
    const [thirdSelection, setThirdSelection] = useState('');
    const [templateName, setTemplateName] = useState<any>("");
    const [identifier, setIdentifier] = useState<any>("");
    const [functionState, setFunctionState] = useState<any>(0);
    const [identifierStatus, setIdentifierStatus] = useState<any>("");
    const [documentSaveState, setDocumentSaveState] = useState<any>(false);
    const [printState, setPrintState] = useState<any>(false);
    const [envelopeState, setEnvelopeState] = useState<any>(false);
    const electronicEnvelopeOpen = () => {
        dispatch(newElectronicEnvelopeOpen(true));
    }



    let id = "";
    if (location.state && location.state.contract_id !== "" && location.state.contract_id !== null && typeof location.state.contract_id !== "undefined") {
        id = location.state.contract_id;
    }


    const { register, reset, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors } = useForm<contractForm>();

    const getTemplatesData = async (template_id) => {
        if (typeof template_id !== "undefined" && template_id) {
            let data = await dispatch(listTemplateContent(template_id));
            const response = await customerService.getTemplates();
            const variableResult = response.data.data[1];
            const propertyVariableResult = response.data.data[2];
            if (data.payload.data.length > 0) {
                setFunctionState(1);
                let setData = data.payload.data[0];
                let newText = setData.ST_TEXTO_MOH
                newText = newText.toLowerCase();
                setDesc(newText);
                setValue('templateName', setData.ST_NOME_MOH);
                setValue('title', setData.ST_NOME_MOH);
                setValue('description', setData.ST_TEXTO_MOH);
                setValue('documentIdentifier', setData.ST_IDENTIFICADOR_MOH)
                setIdentifierStatus(setData.ST_IDENTIFICADOR_MOH);

                if (setData.ST_IDENTIFICADOR_MOH == "Contract") {
                    setVariableData(variableResult);
                }
                if (setData.ST_IDENTIFICADOR_MOH == "Property") {
                    setVariableData(propertyVariableResult)
                }
                return 1
            } else {
                return 0
            }
        }
    }


    useEffect(() => { 
        const getAllElectronicEnvelopeTemplateWithIdentifierAndFolder = async () => {
            const response = await customerService.getAllElectronicEnvelopeTemplateWithIdentifierAndFolder();
            setSelectionData(response.data.data)
        }
        getAllElectronicEnvelopeTemplateWithIdentifierAndFolder();
        const fetchData = async () => {
            try {
                const response = await customerService.getTemplates();
                const variableResult = response.data.data[1];
                const propertyVariableResult = response.data.data[2];
                const ownerVariableResult = response.data.data[3];
                const tenantVariableResult = response.data.data[4];
                setOwnerVariableData(ownerVariableResult)
                setTenantVariableData(tenantVariableResult)

                // setVariableData(variableResult);
                setContractVariableData(variableResult)
                setPropertyVariableData(propertyVariableResult);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // Check if location.state and template_id exist before using them
        if (location.state && location.state.template_id !== null && location.state.template_id !== undefined && location.state.template_id !== "") {
            getTemplatesData(location.state.template_id);
        } else {
            setFunctionState(0);
        }
    }, []);

    const handleIdentifierChange = (event) => {
        let status = event.target.value

        Swal.fire({
            title: t("Sweetalert.Areyousure"),
            text: `Are you sure you want to select ${status}? You would be able to revert this!`,
            // icon: 'success','warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I Confirm!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (status == "Contract") {
                    setVariableData(contractVariableData);
                }
                if (status == "Property") {
                    setVariableData(propertyVariableData)
                } if (status == "Owner") {
                    setVariableData(ownerVariableData);
                }
                if (status == "Tenant") {
                    setVariableData(tenantVariableData);
                }
                setIdentifierStatus(event.target.value);
                event.target.disabled = true;
            }
        })
    }

    const commonToastClose = () => setToastShow(false);
    const commonToastShow = async () => {
        /*
        First Variable Will Render on basis of contract
        Second retrieve all the emails related to contract detail
        Third send email to persons related to contract
        */
        let type = "normal";
        if (location.state) {
            type = "nonpaymentcontact"
        }

        // console.log(type, "This i stypeee")
        if (type == "normal") {
            // const response = await customerService.getRenderedContractAmmendmantTemplate()
            if (templateId !== "" && templateId !== undefined && templateId !== null) {
                //console.log("send email button clicked!")
                const response = await customerService.getRenderedContractAmmendmantTemplate(id, templateId);
                //console.log(response, ">>Response<<")

                if (response.data.resCode == 201 || response.data.resCode == 200) {
                    let allEmails = response.data.data[1];
                    let renderedData = response.data.data[0][0];
                    let emaildata: any = {
                        status: "new",
                        subject: "Email vindo do POSTMAN",
                        content: renderedData.ST_TEXTO_MOH,
                        layout: "default",
                        sender: "noreply@dev.kenlo.io",
                        to: allEmails,
                        "cc": [
                            "vikash@wegile.com"
                        ],
                        "bcc": [
                            "karan@wegile.com"
                        ],
                        identifier: "identifier",
                        model: renderedData.ID_MODELO_MOH,
                        caption: "caption",
                        title: renderedData.ST_TITULO_MOH,
                    }
                    //console.log(emaildata, "Data");

                    let res = await dispatch(emailPost(emaildata))
                    if (res.payload.resCode == 201) {
                        setToastShow(true);
                        setTimeout(() => {
                            setToastShow(false);
                        }, 2000);
                    } else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.somethingWent"),
                            // icon: 'success',"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                    }
                }

            } else {
                //console.log("Please Send Template Id");
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: "Please Select Template",
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        } else if (type == "nonpaymentcontact") {
            nonpaymentcontactEmailnotify();
        }

    }

    const nonpaymentcontactEmailnotify = async () => {
        // console.log(location.state, "This is dataaaaa from non payment");
        let allState: any = location.state;
        let emaildata: any = {
            status: "new",
            subject: "Email vindo do POSTMAN",
            content: desc,
            layout: "default",
            sender: "noreply@dev.kenlo.io",
            to: allState["email"],
            identifier: "identifier",
            model: allState["template_id"],
            caption: "caption",
            title: "Non Payment Update",
        }

        let res = await dispatch(emailPost(emaildata))
        if (res.payload.resCode == 201) {
            setToastShow(true);
            setTimeout(() => {
                setToastShow(false);
                navigate("/non_payment")
            }, 2000);
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    };

    const createTemplateClick = async () => {
        navigate(`/folder_detail/${id}`);
    }

    useEffect(() => {
        let user_id: any = 1
        dispatch(allEmailTemplate(user_id))
    }, [])

    const HandleModule = (e: any) => {
        const selectedModelId = e.target.value;
        setTemplateId(selectedModelId);
        let selectedModel: any = null;

        for (let i = 0; i < emailTemplateLst.length; i++) {
            if (emailTemplateLst[i].ID_MODELO_MOH == selectedModelId) {
                selectedModel = emailTemplateLst[i];
                break;
            }
        }


        if (selectedModel) {
            const newText: any =
                `
            <!DOCTYPE html>
                        <html>
                          <head>
                            <meta charset="UTF-8">
                          </head>
                          <body style="font-family:sans-serif;margin-left:2em;">
                           <header>
                           <img className="form-control" src="${config.companyV2_URL}/logos/${getlogo?.logo_name}" alt="logo">
                           </header>
                              ${selectedModel.ST_TEXTO_MOH}
                              <div style="background-color:#F5F5F5; width: 100%; margin: 0 !important; padding: 0 !important;">
                              <div style="max-width: 600px; margin:auto;">
                                <div style="margin: 0 15px;  padding:32px 10px 32px 10px">
                                <img src="${config.companyV2_URL}${logoPath}" alt="Logo">
                                </div>
                              </div>
                           </div>
                          </body>
                        </html>
            `
                || ''; // Use the actual property name if needed
            const newTitle = selectedModel.ST_TITULO_MOH || ''
            setDesc(newText);
            //   setValue("title", `${newTitle}`)
        } else {
            setDesc(''); // or set to a default value
            //   setValue("title", ``)
        }
    };

    const jsonData = selectionData;
    // Extracting unique values for first, second, and third dropdowns
    const firstOptions: any = Array.from(new Set(jsonData.map(item => item.ST_NOME_TEH)));
    const secondOptionsData: any = {};
    const thirdOptionsData: any = {};

    jsonData.forEach(item => {
        secondOptionsData[item.ST_NOME_TEH] = Array.from(new Set(jsonData.filter(data => data.ST_NOME_TEH === item.ST_NOME_TEH).map(data => ({
            value: data.ID_MODELO_MOH,
            label: data.ST_NOME_MOH,
            templateData: data.ST_TEXTO_MOH,
            templateIdentifier: data.ST_IDENTIFICADOR_MOH
        }))));
        thirdOptionsData[item.ID_MODELO_MOH] = Array.from(new Set(jsonData.filter(data => data.ID_MODELO_MOH === item.ID_MODELO_MOH).map(data => ({
            value: data.ST_IDENTIFICADOR_MOH,
            label: data.ST_NOME_MOH
        }))));
    });

    // Function to handle change in the first dropdown
    const handleFirstChange = (event) => {
        const selectedValue = event.target.value;
        setFirstSelection(selectedValue);
        // Clear selections in second and third dropdowns
        setSecondSelection('');
        setThirdSelection('');
    };

    // Function to handle change in the second dropdown
    const handleSecondChange = (event, optionsData) => {
        const selectedValue = event.target.value;
        let templateData = optionsData;
        let obj = templateData.find(obj => obj.value == selectedValue);
        setIdentifier(obj.templateIdentifier);
        setTemplateName(obj.label)
        setDesc(obj.templateData);
        setSecondSelection(selectedValue);
        setThirdSelection('');
    };

    // Function to handle change in the third dropdown
    const handleThirdChange = (event) => {
        const selectedValue = event.target.value;
        setThirdSelection(selectedValue);
    };

    const formSubmit = async (data: contractForm, e: any) => {
        if (data.documentIdentifier !== "" && data.documentIdentifier !== undefined && data.documentIdentifier !== null && data.title !== "" && data.title !== undefined && data.title !== null && data.description !== "" && data.description !== null && data.description !== undefined) {
            if (documentSaveState === true) {
                let folder_id = location.state.folder_id;
                let template_id;
                if (location.state.template_id !== "" && location.state.template_id !== null && location.state.template_id !== undefined && location.state.template_id !== "undefined") {
                    template_id = location.state.template_id
                } else {
                    template_id = null;
                }
                data.model = template_id;
                data.folder = folder_id;

                let response = await customerService.updateTemplate(data);
                if (response.data.resCode == 202 || response.data.resCode == 200) {
                    Swal.fire({
                        // icon: 'success','success',
                        title: t("Sweetalert.Success"),
                        text: "Template Saved",
                        confirmButtonText: "OK",
                        timer: 2000
                    })
                    navigate(`/folder_listing/${folder_id}`,);
                } else {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: "Record Failed To Insert",
                        // icon: 'success',"error",
                        confirmButtonText: "OK",
                        timer: 2000
                    })
                }
                //console.log(response, "this is save response")
                setDocumentSaveState(false);
            }
            else if (printState === true) {
                let res = await customerService.renderCKEditorTemplateVariables(data, id);
                let template = res.data.data[0];

                template = `
                    <h4>${t("DocumentTemplate.documentTitle")}: ${data.templateName}</h4>
                    <h4>${t("DocumentTemplate.documentSubtitle")}: ${data.title}</h4>
                    ${template}
                    `

                const response: any = await customerService.createPDFTemplate(template);

                const url = `${config.TRANSFER___API}/api/v1/download`;
                window.open(url, '_blank');
                setPrintState(false);
            } else if (envelopeState == true) {
                //console.log("envelope submit working");
                electronicEnvelopeOpen();
                setEnvelopeState(false);
            } else {
                //console.log("email sent")
                let res = await customerService.renderCKEditorTemplateVariables(data, id);
                let template = res.data.data[0];
                let allEmails = res.data.data[1];

                let emaildata: any = {
                    status: "new",
                    subject: "Email vindo do POSTMAN",
                    content: template,
                    layout: "default",
                    sender: "noreply@dev.kenlo.io",
                    to: allEmails,
                    "cc": [
                        "vikash@wegile.com"
                    ],
                    "bcc": [
                        "karan@wegile.com"
                    ],
                    identifier: "identifier",
                    model: 1,
                    caption: "caption",
                    title: data.title,
                }

                //console.log("emaildata amendmant", emaildata);

                let response = await dispatch(emailPost(emaildata));
                if (response.payload.resCode == 201) {
                    Swal.fire({
                        // icon: 'success','success',
                        title: t("Sweetalert.Success"),
                        text: "Email Sent",
                        confirmButtonText: "OK",
                        timer: 2000
                    })
                } else {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.somethingWent"),
                        // icon: 'success',"error",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                }
            }

        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: "Please Fill Parameters",
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }

    const createElectronicEnvelope = async () => {
        await setEnvelopeState(true);
        //console.log("createElectronicEnvelope");
        buttonRef.current.click();
    }
    const handleClick = () => {
        // Trigger click event on the button
        buttonRef.current.click();
    };

    const downloadPDF = async () => {
        await setPrintState(true);
        buttonRef.current.click();
    }
    const handleSaveTemplate = async () => {
        await setDocumentSaveState(true);
        //console.log("Template Saved");
        buttonRef.current.click();
    }

    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 4, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/non_payment"}><img src="assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3">
                                    <h1 className="mb-0 h4 lh-base text-truncate">{t("DocumentTemplate.newTemplate")}</h1>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 5, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe">
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("DocumentTemplate.changesSavedAutomatically")}</Tooltip>}>
                                        <img src="assets/images/icons/cloud-arrow.svg" alt="cloud icon" className="h-20" />
                                    </OverlayTrigger>
                                </li> */}
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe" onClick={createElectronicEnvelope}>
                                    <img src="assets/images/icons/edit-primary.svg" alt="create" className="h-18 me-2 cursor-pe" />{t("create")} {" "} {t("electronicEnvelope")}
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe" onClick={downloadPDF}>
                                    <img src="assets/images/icons/share.svg" alt="share" className="h-18 me-2" />{t("DocumentTemplate.share")}
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe" onClick={downloadPDF}>
                                    <img src="assets/images/icons/print-light.svg" alt="print" className="h-18 me-2" />{t("DocumentTemplate.print")}
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe" onClick={handleClick}>
                                    <img src="assets/images/icons/send-dark.svg" alt="send" className="h-18 me-2 cursor-pe" />{t("formLabel.sendEmail")}
                                </li>
                                {/*  */}
                                {/* <li className="list-inline-item mb-md-0 mb-1">
                                    <img src="assets/images/icons/question_icon.svg" alt="Help" className="h-20" />
                                </li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <Form onSubmit={handleSubmit(formSubmit)}>
                    <Row>
                        <Col md={5}>
                            <Card className="mb-3">
                                <Card.Body>
                                    {
                                        jsonData && jsonData.length > 0 ?

                                            <>
                                                <Row className="gy-3 gy-lg-4">
                                                    <Card className="mb-3">
                                                        <Card.Header>{t("formLabel.settings")}</Card.Header>
                                                        <Card.Body>
                                                            <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.templateName")}>
                                                                <Form.Control type="text" placeholder={t("DocumentTemplate.templateName")} {...register('templateName')} />
                                                            </FloatingLabel>
                                                            <span className="fs-12 d-block text-secondary mt-1 ms-3 mb-3">{t("DocumentTemplate.templateIdentificationSystem")}</span>
                                                            <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.documentTitle")} >
                                                                <Form.Control type="text" placeholder={t("DocumentTemplate.documentTitle")} {...register('title')} />
                                                            </FloatingLabel>
                                                            <span className="fs-12 d-block text-secondary mt-1 ms-3 mb-3">{t("DocumentTemplate.identifier")}</span>
                                                            {/* <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.identifier")} >
                                                                <Form.Control type="text" placeholder={t("DocumentTemplate.identifier")} value="Contract" {...register('documentIdentifier')} />
                                                            </FloatingLabel> */}


                                                            {/* <FloatingLabel controlId="floatingSelect" label="Folder Template">
                                        <Form.Select {...register('documentFolderTemplateId')}>
                                            <option value="">Select</option>

                                            {
                                                listTemplatefolder && listTemplatefolder.map((item)=> (
                                                    <option value={item.ID_TEMPLATE_TEH}>{item.ST_NOME_TEH}</option>       
                                                ))
                                            }
                                        </Form.Select>
                                    </FloatingLabel> */}
                                                            {
                                                                (location.state && location.state.template_id &&
                                                                    location.state.template_id !== "" && location.state.template_id !== null) ?
                                                                    <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.identifier")}>
                                                                        <Form.Select {...register('documentIdentifier')} onChange={handleIdentifierChange} disabled>
                                                                            <option value="">{t('dropdownItem.select')}</option>
                                                                            <option value="Contract">Contract</option>
                                                                            <option value="Property">Property</option>
                                                                            <option value="Owner">Owner</option>
                                                                            <option value="Tenant">Tenant</option>
                                                                            {/* Add other options as needed */}
                                                                        </Form.Select>
                                                                    </FloatingLabel>

                                                                    :
                                                                    <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.identifier")}>
                                                                        <Form.Select {...register('documentIdentifier')} onChange={handleIdentifierChange}>
                                                                            <option value="">{t('dropdownItem.select')}</option>
                                                                            <option value="Contract">Contract</option>
                                                                            <option value="Property">Property</option>
                                                                            <option value="Owner">Owner</option>
                                                                            <option value="Tenant">Tenant</option>
                                                                            {/* Add other options as needed */}
                                                                        </Form.Select>
                                                                    </FloatingLabel>
                                                            }



                                                            <span className="fs-12 d-block text-secondary mt-1 ms-3 mb-3">{t("DocumentTemplate.subtitleAppearHeader")}</span>
                                                            <Form.Group controlId="logoHeader" className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                                                                <Form.Check type="checkbox" label={t("DocumentTemplate.showLogoHeader")} />
                                                            </Form.Group>
                                                        </Card.Body>
                                                    </Card>

                                                    <Col md={12}>
                                                        {/* First dropdown */}
                                                        <FloatingLabel controlId="firstSelect" label={t('selectFolder')}>
                                                            <Form.Select className={`form-control ${errors.firstSelectionOption ? 'is-invalid' : ''}`} {...register('firstSelectionOption')} aria-label="firstSelectionOption" value={firstSelection} onChange={handleFirstChange} >
                                                                <option value="">Select</option>
                                                                {firstOptions.map((option, index) => (
                                                                    <option key={index} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">{(errors.firstSelectionOption?.message as string)}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>

                                                    <Col md={12}>
                                                        {/* Second dropdown */}
                                                        {firstSelection && (
                                                            <FloatingLabel controlId="secondSelect" label={t('selectTemplate')}>
                                                                <Form.Select className={`form-control ${errors.secondSelectionOption ? 'is-invalid' : ''}`} {...register('secondSelectionOption')} aria-label="secondSelectionOption" value={secondSelection} onChange={(e) => { handleSecondChange(e, secondOptionsData[firstSelection]) }}>
                                                                    <option value="">Select</option>
                                                                    {secondOptionsData[firstSelection].map((option, index) => (
                                                                        <option key={index} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">{(errors.secondSelectionOption?.message as string)}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        )}
                                                    </Col>

                                                    <Col md={12}>
                                                        {/* Third dropdown */}
                                                        {identifier && (identifier == "Contract") ?
                                                            <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                                <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                    onChange={(e) => {
                                                                        const selectedVariable = e.target.value;
                                                                        const appendedContent = `${desc} %${selectedVariable}%`; setDesc(appendedContent.toLowerCase());
                                                                    }}>
                                                                    <option value="">{t('dropdownItem.select')}</option>
                                                                    {contractVariableData && Object.keys(contractVariableData).length > 0
                                                                        ? Object.keys(contractVariableData).map(key => (
                                                                            <option key={key} value={contractVariableData[key]}>
                                                                                {key}
                                                                            </option>
                                                                        ))
                                                                        : <option disabled>No data available</option>
                                                                    }
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                            :
                                                            ""}

                                                        {identifier && (identifier == "Property") ?
                                                            <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                                <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                    onChange={(e) => {
                                                                        const selectedVariable = e.target.value;
                                                                        const appendedContent = `${desc} %${selectedVariable}%`; setDesc(appendedContent.toLowerCase());
                                                                    }}>
                                                                    <option value="">{t('dropdownItem.select')}</option>
                                                                    {propertyVariableData && Object.keys(propertyVariableData).length > 0
                                                                        ? Object.keys(propertyVariableData).map(key => (
                                                                            <option key={key} value={propertyVariableData[key]}>
                                                                                {key}
                                                                            </option>
                                                                        ))
                                                                        : <option disabled>No data available</option>
                                                                    }
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                            :
                                                            ""}

                                                    </Col>

                                                    {/* <Col md={6}>
                                                            {secondSelection && (
                                                                <FloatingLabel controlId="thirdSelect" label="Third Dropdown">
                                                                    <Form.Select className={`form-control ${errors.templateSelection ? 'is-invalid' : ''}`} {...register('templateSelection')} aria-label="templateSelection" value={thirdSelection} onChange={handleThirdChange}>
                                                                        <option value="">Select</option>
                                                                        {thirdOptionsData[secondSelection] && thirdOptionsData[secondSelection].map((option, index) => (
                                                                            <option key={index} value={option.value}>
                                                                                {option.value}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">{(errors.templateSelection?.message as string)}</Form.Control.Feedback>
                                                                </FloatingLabel>
                                                            )}
                                                        </Col> */}



                                                </Row>
                                            </>
                                            :
                                            ""
                                    }
                                </Card.Body>
                            </Card>
                            <Card className="mb-3">
                                <Card.Header className="d-flex align-items-center justify-content-between">
                                    {t("formLabel.variables")}
                                    <OverlayTrigger placement="right" overlay={<Tooltip>{t("DocumentTemplate.variablesContent")}</Tooltip>}>
                                        <img src="assets/images/icons/info-dark.svg" alt="alert" />
                                    </OverlayTrigger>

                                </Card.Header>
                                <Card.Body>
                                    <InputGroup>
                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")} >
                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template" onChange={(e) => {
                                                const selectedVariable = e.target.value;
                                                const appendedContent = `${desc} %${selectedVariable}%`; setDesc(appendedContent.toLowerCase());
                                            }}>
                                                <option value="">...Select</option>
                                                {templatevariable && Object.keys(templatevariable).length > 0
                                                    ? Object.keys(templatevariable).map(key => (
                                                        <option key={key} value={templatevariable[key]}>
                                                            {key}
                                                        </option>
                                                    ))
                                                    :
                                                    ""
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                        <InputGroup.Text id="search_icon">
                                            <img src="assets/images/icons/search.svg" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Card.Body>
                            </Card>
                            {/* <Accordion>
                            <Accordion.Item eventKey="0" className="mb-3">
                                <Accordion.Header>{t("DocumentTemplate.propertyOwnerVariables")}</Accordion.Header>
                                <Accordion.Body>
                                    <InputGroup className="mb-3">
                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                            <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                        </FloatingLabel>
                                        <InputGroup.Text id="search_icon">
                                            <img src="assets/images/icons/search.svg" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" className="mb-3">
                                <Accordion.Header>{t("DocumentTemplate.propertyVariables")}</Accordion.Header>
                                <Accordion.Body>
                                    <InputGroup className="mb-3">
                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                            <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                        </FloatingLabel>
                                        <InputGroup.Text id="search_icon">
                                            <img src="assets/images/icons/search.svg" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2" className="mb-3">
                                <Accordion.Header>{t("DocumentTemplate.tenantVariables")}</Accordion.Header>
                                <Accordion.Body>
                                    <InputGroup className="mb-3">
                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                            <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                        </FloatingLabel>
                                        <InputGroup.Text id="search_icon">
                                            <img src="assets/images/icons/search.svg" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3" className="mb-3">
                                <Accordion.Header>{t("DocumentTemplate.contractVariables")}</Accordion.Header>
                                <Accordion.Body>
                                    <InputGroup className="mb-3">
                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                            <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                        </FloatingLabel>
                                        <InputGroup.Text id="search_icon">
                                            <img src="assets/images/icons/search.svg" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4" className="mb-3">
                                <Accordion.Header>{t("DocumentTemplate.dateVariables")}</Accordion.Header>
                                <Accordion.Body>
                                    <InputGroup className="mb-3">
                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                            <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                        </FloatingLabel>
                                        <InputGroup.Text id="search_icon">
                                            <img src="assets/images/icons/search.svg" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5" className="mb-3">
                                <Accordion.Header>{t("DocumentTemplate.guaranteeVariables")}</Accordion.Header>
                                <Accordion.Body>
                                    <InputGroup className="mb-3">
                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                            <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                        </FloatingLabel>
                                        <InputGroup.Text id="search_icon">
                                            <img src="assets/images/icons/search.svg" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6" className="mb-3">
                                <Accordion.Header>{t("DocumentTemplate.guarantorVariables")}</Accordion.Header>
                                <Accordion.Body>
                                    <InputGroup className="mb-3">
                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                            <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                        </FloatingLabel>
                                        <InputGroup.Text id="search_icon">
                                            <img src="assets/images/icons/search.svg" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7" className="mb-3">
                                <Accordion.Header>{t("DocumentTemplate.transferVariables")}</Accordion.Header>
                                <Accordion.Body>
                                    <InputGroup className="mb-3">
                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                            <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                        </FloatingLabel>
                                        <InputGroup.Text id="search_icon">
                                            <img src="assets/images/icons/search.svg" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8" className="mb-3">
                                <Accordion.Header>{t("DocumentTemplate.realStateAgencyVariables")}</Accordion.Header>
                                <Accordion.Body>
                                    <InputGroup className="mb-3">
                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                            <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                        </FloatingLabel>
                                        <InputGroup.Text id="search_icon">
                                            <img src="assets/images/icons/search.svg" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9" className="mb-3">
                                <Accordion.Header>{t("DocumentTemplate.managerVariables")}</Accordion.Header>
                                <Accordion.Body>
                                    <InputGroup className="mb-3">
                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                            <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                        </FloatingLabel>
                                        <InputGroup.Text id="search_icon">
                                            <img src="assets/images/icons/search.svg" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="10" className="mb-3">
                                <Accordion.Header>{t("DocumentTemplate.signaturesVariables")}</Accordion.Header>
                                <Accordion.Body>
                                    <InputGroup className="mb-3">
                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                            <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                        </FloatingLabel>
                                        <InputGroup.Text id="search_icon">
                                            <img src="assets/images/icons/search.svg" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="11" className="mb-3">
                                <Accordion.Header>{t("DocumentTemplate.otherVariables")}</Accordion.Header>
                                <Accordion.Body>
                                    <InputGroup className="mb-3">
                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                            <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                        </FloatingLabel>
                                        <InputGroup.Text id="search_icon">
                                            <img src="assets/images/icons/search.svg" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> */}
                        </Col>
                        <Col md={7} className="editor-h">
                            <Form.Control type="hidden" placeholder="documentText"  {...register('description')} />
                            <CKEditor
                                editor={ClassicEditor}
                                data={desc}
                                onReady={editor => {
                                    const data = editor.getData();
                                    setDesc(data)
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setDesc(data);
                                    setValue('description', data); // Update the form value
                                }}
                                onBlur={(event, editor) => {
                                    const data = editor.getData();
                                    setDesc(data);
                                    setValue('description', data); // Update the form value
                                }}
                                onFocus={(event, editor) => {
                                    const data = editor.getData();
                                    setDesc(data)
                                }}

                            />
                        </Col>
                    </Row>
                    <Button style={{ display: "none" }} type="submit" ref={buttonRef}>Send Email</Button>
                </Form>
            </main>
            {/* <!-- /Main content start end --> */}
            {/* toast */}
            <Share showShare={showShareModal} shareClose={shareModalClose} />
            <PrintFolderDetail showPrintDetail={showPrintDetailModal} printDetailClose={printDetailModalClose} />
            <ToastContainer className="p-3 position-fixed" position="bottom-end" style={{ zIndex: 1 }}>
                <Toast bg="dark" className="rounded-4 text-white border-0 w-auto" show={showToast} onClick={commonToastClose}>
                    <Toast.Body className="d-flex align-items-center p-3">
                        <img className="me-2 me-xxl-4 me-xl-3" src="assets/images/icons/success-light.svg" alt="arrow right" />
                        <span className="me-2">{t("NonPayment.tenantNotifiedEmail")}</span>
                        <span className="ms-3 cursor-pe" onClick={commonToastClose}><img src="../assets/images/icons/times-white.svg" alt="arrow right" /></span>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </section>
    )
}

export default NotifyEmail
