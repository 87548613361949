import React, { useState, MouseEvent, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import CryptoJS from 'crypto-js';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ComProps, UsersForm } from "../../Interface";
import "../../../App.css";
import { userService } from "../../../services/userService";
import { commonService } from "../../../services/commonService";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { FormValues, UserFormValues } from "./types";
import { DAYS, HITS, PrintPDF, dummyselect, encrypt_Decrypt, getAll_Default_Permission } from "../../constants/utils";
import Checkbox from "../../Pages/Contracts/Common/Checkbox";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
import Pagination from "../../Pagination";
import { Modal, Form, Button, Row, Col, Table, Accordion, FloatingLabel, InputGroup, Dropdown, Alert, Collapse, Card, Badge } from "react-bootstrap";
// import { HITSCheckbox } from '../../modules/hitsChecbox';
// Component
import Groups from "./Groups";
import Loader from "../Company/Loader";
import { access } from "fs";
import { openErrorbox } from "../../Pages/Contracts/Common/actionPerform";
import { compose } from "@reduxjs/toolkit";
import { secretKey } from "../../../services/apiConfig";
import i18n from "../../../i18n";
export { }; // 👈️ if you don't have anything else to export

const User = ({ submenu }: ComProps, UsersForm) => {
	const [t] = useTranslation();
	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);
	const [show3, setShow3] = useState(false);
	const [show5, setShow5] = useState(false);
	const [show6, setShow6] = useState(false);
	const [show7, setShow7] = useState(false);
	const [hide, setHide] = useState(false);
	const [copyuserdata, setCopyUserData] = useState(false);
	const timer: any = useRef(null);
	const [filteredHits, setFilterHits] = useState([]);
	const [filteredGroups, setFilterGroups] = useState([]);
	const [searchUserFilter, setSearchFilter] = useState("");
	const [headingText, setHeadingText] = useState("");
	const [users, setUsers] = useState<Array<any>>([]);
	const [dataId, setDataId] = useState<any>();
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState<Array<any>>([]);
	const [isTicketCheck, setIsTicketCheck] = useState(false);
	const [allGroups, setGroups] = useState([]);
	const [compositefilter, setCompositeFilter] = useState<any>();
	const [isHITSCheckAll, setHITSIsCheckAll] = useState(false);
	const [updateusers, setUpdateusers] = useState('')
	const [currentPage, setCurrentPage] = useState(1);
	let id_arr: any = [];
	const [totalpage, setTotalPage] = useState(0);
	const [PageSize, setPageSize] = useState(25);
	const [limitSelect, setLimitSelect] = useState<any>({
		start: 0,
		end: PageSize,
	})
	const [userGroup, setuserGroup] = useState("user");

	// useEffect(() => {
	// 	limitSelect["start"] = (currentPage - 1) * limitSelect["end"];
	// 	console.log(limitSelect, "limitSelect");
	// 	getAllUsers({ userId: "" })
	// }, [currentPage, limitSelect])

	useEffect(() => {
        setLimitSelect({
            ...limitSelect,
            ["start"]: currentPage == 1 || currentPage == 0 ? 0 : (currentPage - 1) * PageSize,
            ["end"]: PageSize
        })
		getAllUsers({ userId: "" })
    }, [currentPage])
	const navigate = useNavigate();
	const [userJson, setUserJson] = useState<any>([]);
	useEffect(() => {
		let userResult = encrypt_Decrypt();
		if (!userResult || userResult.indexOf(1000) === -1) {
			navigate("/dashboard");
		}
		setUserJson(userResult);
		userClose();
	}, [])

	const [ipinputFields, setIPInputFields] = useState({
		[`open_0`]: {
			ip1: "",
			ip2: "",
			ip3: "",
			ip4: "",
		},
	});

	// const [HITS, setHITS] = useState([{
	//   key: "",
	//   value: "",
	//   label: "",
	//   indexKey: "",
	// }]);
	useEffect(() => {
		getAll_Default_Permission();
	}, [i18n.language])
	// const getAll_Default_Permission = () => {
	//   userService
	//     .getAll_default_permission()
	//     .then((res) => {
	//       let pushAllResponse: any = [];
	//       res && res.data?.length > 0 &&
	//         res["data"].forEach((item, index) => {
	//           pushAllResponse.push({
	//             key: `${item["ID_ACCESS_ACCPER"]}`,
	//             value: `${item["TX_ACCESS_NAME_ACCPER"]}`,
	//             label: `${item["TX_ACCESS_NAME_ACCPER"]}`,
	//             indexKey: item["ID_ACCESS_ACCPER"],
	//           })
	//         })
	//       setHITS(pushAllResponse);
	//     })
	//     .catch((err) => {
	//       console.log("err", err);
	//     });
	// };

	const [scrollTopPosition, setcurrentscrollTop] = useState(0);
	const [status, setStatus] = useState("");
	const [userstatus, setUserStatus] = useState("Status");
	const [singleuser, setSingleUser] = useState(false);
	const [singleUserId, setSingleUserId] = useState("");
	const [singleUserDetail, setSingleUserDetail] = useState<any>("");
	const [registerAnother, setregisterAnother] = useState(false);
	const [groupdata, setGroupData] = useState([]);
	const [alluserList, setAllUserList] = useState([]);
	const [loader_on, setloader_on] = useState(false);
	const [copyaccessChangegroup, setcopyaccessChangegroup] = useState<any>([{
		groups: [],
		users: [],
		permission: ""
	}])
    const [userVal, setUserVal] = useState('')
	const [inputFields, setInputFields] = useState<any>({
		[`open_0`]: {
			day: "",
			das: "",
			untill: "",
		},
	});

	const handleregisterAnother = () => {
		setregisterAnother(!registerAnother);
	};

	if (dataId) {
		let form_validationSchema = Yup.object().shape({
			name: Yup.string().trim().nullable()
				.required(t("requiredMmessage.fieldRequired"))
				.min(3, t("requiredMmessage.Namemusthavemorethan2characters")),
			email: Yup.string().trim().nullable()
				.required(t("requiredMmessage.fieldRequired"))
				.email(t("requiredMmessage.fieldNotValid"))
		});
		var frm = { resolver: yupResolver(form_validationSchema) };
	} else {
		var form_validationSchema = Yup.object().shape({
			name: Yup.string().trim().nullable()
				.required(t("requiredMmessage.fieldRequired"))
				.min(3, t("requiredMmessage.Namemusthavemorethan2characters")),
			email: Yup.string().trim().nullable()
				.required(t("requiredMmessage.fieldRequired"))
				.email(t("requiredMmessage.fieldNotValid")),
			password: Yup.string().trim().nullable()
				.required(t("requiredMmessage.fieldRequired"))
		});
		var frm = { resolver: yupResolver(form_validationSchema) };
	}

	const access_data = Yup.object().shape({
		sourceuser: Yup.string().trim().nullable().required(
			t("requiredMmessage.Sourceuserisrequired")
		),
	});

	useEffect(() => {
		setUsers(groupdata);
	}, [groupdata]);

	const groupform_validationSchema = Yup.object().shape({
		group: Yup.string().trim().nullable().required(t("requiredMmessage.Pleaseselectgroup")),
	});
	const HITSform_validationSchema = Yup.object().shape({
		type: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
	});
	const access_data_time_location = Yup.object().shape({
		// group: Yup.string().trim().nullable().required('Please select group'),
	});
	if (show2) {
		frm = { resolver: yupResolver(HITSform_validationSchema) };
	} else if (show3) {
		frm = { resolver: yupResolver(groupform_validationSchema) };
	}
	if (show6) {
		frm = { resolver: yupResolver(access_data) };
	}
	if (show7) {
		frm = { resolver: yupResolver(access_data_time_location) };
	}
	const { register, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm<UsersForm>(frm);
	const userShow = () => {
		setSingleUser(false);
		setSingleUserId("");
		setShow5(true);
	};
	const userClose = () => {
		setInputFields({
			[`open_0`]: {
				day: "",
				das: "",
				untill: "",
			},
		});
		setIPInputFields({
			[`open_0`]: {
				ip1: "",
				ip2: "",
				ip3: "",
				ip4: "",
			},
		});

		setDataId("");
		setFilterGroups([]);
		setShow5(false);
		setHITSIsCheckAll(false);
		setIsCheck([]);
		reset();

	};
	const [copyGroupAccess, setCopyGroupaccess] = useState([
		{ value: 1, label: "copyGroupAccess 1" },
		{ value: 1, label: "copyGroupAccess 2" },
		{ value: 1, label: "copyGroupAccess 3" },
	]);

	// Get All Groups
	const getAllGroups = (groupId) => {
		let groupData = { groupId: groupId, page: 1, end: 10 };
		userService
			.getAllGroups(groupData)
			.then((res) => {
				if (res.data) {
					setGroups(res.data);
				} else {
					setGroups([]);
				}
			})
			.catch((err) => {
				console.log("err", err);
			});
	};

	useEffect(() => {
		let datapush: any = [];
		allGroups.forEach((item) => {
			datapush.push({
				value: item["ID_GRUPO_GRPU"],
				label: item["ST_NOME_GRPU"],
				access: item["ST_ACESSOS_GRPU"],
			});
		});
		setCopyGroupaccess(datapush);
		// getAllGroups({})
	}, [allGroups]);

	// Check / uncheck Check boxess
	const handleClick = (e) => {
		const { id, checked } = e.target;
		if (isCheck && isCheck?.length) {
			setIsCheck([...isCheck, id]);
		} else {
			setIsCheck([id]);
		}

		if (!checked) {
			setIsCheck(isCheck.filter((item) => item !== id));
		}
		let count = 0;
		if (document.querySelectorAll(".checkOption_Checkbox")?.length > 0) {
			let totalcheck = document.querySelectorAll(
				".checkOption_Checkbox"
			)?.length;
			document
				.querySelectorAll(".checkOption_Checkbox input")
				.forEach((item, index) => {
					if (!item["checked"]) {
						setHITSIsCheckAll(false);
					}

					if (item["checked"]) {
						count++;
					}
				});
			if (count == totalcheck) {
				setHITSIsCheckAll(true);
			}
		}
	};

	const createCheckbox = (item, index) => {
		return (
			<div key={item.key} className="mb-3 my-3">
				<div className="form-check checkOption_Checkbox">
					<Checkbox
						key={item.key}
						type="checkbox"
						name={item.key}
						id={item.key}
						handleClick={handleClick}
						isChecked={
							isCheck && isCheck?.length > 0 ? isCheck.includes(item.key) : false
						}
						disabled={true}
					/>
					<label htmlFor={item.key} className="form-check-label">
						{item.value} {/*  ({item.key}) */}
					</label>
				</div>
			</div>
		);
	};

	function clearRequired() {
		getAllUsers({});
		getAllGroups("");
		reset();
		setTimeout(() => {
			userClose();
		}, 600)
	}

	const createCheckboxes = () =>
		// working hereee
		HITS.map(createCheckbox);

	// function to auto select user permission or show all permissions
	const permissionShow = (e: MouseEvent<HTMLElement>) => {
		setIsCheck([]);
		// e.preventDefault();
		var userId: any = e.currentTarget.getAttribute("data-key-id");
		var action: any = e.currentTarget.getAttribute("data-action-key"); // data-action-key="userpermission"
		// console.log('userId::::', userId, action);
		let userDetails: any;
		if (action == "changepermissionbtn") {
			// Get selected user at least 1 user. if 1 user get user permissions else show all empty
			// console.log('++++ ', getSelectedUsers());
			let userId: any = getSelectedUsers();
			if (userId?.length > 0) {
				let allUserDetails: any = [];
				userId.forEach((itemId, index) => {
					let dataAre = users.filter((usr) => Number(usr.ID_USUARIO_USU) == Number(itemId));
					allUserDetails.push(...dataAre);
				})
				userDetails = allUserDetails;
				setShow2(true);
			}
		} else if (action == "userpermission" && userId) {
			userDetails = users.filter((usr) => usr.ID_USUARIO_USU == userId);
		}

		// Set UTIS Permission
		if (
			userDetails?.[0].ST_ACESSO_USU_JSON !== "undefined" &&
			userDetails?.[0].ST_ACESSO_USU_JSON !== "null" &&
			String(userDetails?.[0].ST_ACESSO_USU_JSON) !== "undefined"
		) {
			let userPermissionAre = JSON.parse(userDetails?.[0].ST_ACESSO_USU_JSON);
			setIsCheck(userPermissionAre);
		}
	};
	// Popup Close
	const permissionClose = () => {
		reset();
		setShow2(false);
	};

	// On submit update/remove the Permissions
	const permissionSubmit = (data: UsersForm) => {
		// console.log('UPDATE User Permission...');
		let postData = {
			uits: isCheck,
			users: getSelectedUsers(),
		};
		userService
			.updateAccessPermission(postData)
			.then((res) => {
				if (res.resCode == 201) {
					Swal.fire({
						title: `${t("Sweetalert.Success")}`,
						text: `${t("Sweetalert.permissionChange")}`,
						// icon: 'success',"success",
						confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
						timer: 2000,
					});
					setShow2(false);
					clearRequired();

				} else {
					Swal.fire({
						title: `${t("Sweetalert.Error")}`,
						text: `${t("Sweetalert.serverError")}`,
						// icon: 'success',"error",
						confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
						timer: 2000,
					});
				}
			}).catch((err) => {
				console.log("err", err);
			});
	};

	const commonClose = () => {
		reset();
		setShow3(false);
		setHide(false);
		setShow6(false);
		setShow7(false);
	};
	const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
		// console.log('Group Event....')
		e.preventDefault();
		var type = e.currentTarget.name;
		if (type === "Remove from a group") {
			setHeadingText(t("userModule.removeFromGroup"));
			setHide(true);
		} else {
			setHeadingText(t("userModule.addToGroup"));

		}
		// Get list of updated groups
		getAllGroups("");
		let userId: any = getSelectedUsers();
		if (userId?.length > 0) {
			let user_Id = userId != undefined && userId?.length ? userId?.[0] : "";
			let userDetails = users.filter((usr) => usr.ID_USUARIO_USU == user_Id);
			let usrGroups = userDetails?.[0].userInGroups;
			let mm: any = [];
			if (usrGroups) {
				usrGroups = usrGroups.split(",");
				usrGroups.forEach(async (element) => {
					allGroups.forEach((group) => {
						if (group["ID_GRUPO_GRPU"] == element) {
							mm.push({
								label: group["ST_NOME_GRPU"],
								value: Number(element),
								groupId: Number(element)
							});
						}
					});
				});
			}

			setFilterGroups(mm);
			setShow3(true);
		}
		setTimeout(() => {
			reset();
		}, 100);
	};
	// Function to get list of users checked
	const getSelectedUsers = () => {
		let selectedUsers: string[] = [];
		users.map((user) => {
			if (user.isChecked) {
				selectedUsers.push(user.ID_USUARIO_USU);
			}
		});
		if (!selectedUsers?.length) {
			Swal.fire({
				title: `${t("Sweetalert.Error")}`,
				text: `${t("Sweetalert.pleaseSelect")}`,
				// icon: 'success',"error",
				confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
			});
		} else {
			return selectedUsers;
		}
	};
	// Function to add users in group.
	const addtoGroup = async (data: UsersForm) => {
		let allGroupPermission: any = [];

		filteredGroups.forEach((item, index) => {
			let allpemissionData = JSON.parse(item?.["ST_ACESSOS_GRPU"]);
			let allKeyPermission = Object.keys(allpemissionData);
			allGroupPermission.push(...allKeyPermission);
		})

		let NewSetPermissionUniq = new Set(allGroupPermission);
		let ChangeSetToArray = Array.from(NewSetPermissionUniq);

		let postData = {
			groups: filteredGroups,
			users: await getSelectedUsers(),
			permission: ChangeSetToArray
		};

		userService
			.AddUsertoGroup(postData)
			.then((res) => {
				if ((res && res.resCode == 201) || res.type == "Success") {
					Swal.fire({
						title: `${t("Sweetalert.Success")}`,
						text: `${t("Sweetalert.RecordUpdated")}`,
						// icon: 'success',"success",
						confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
						timer: 2000,
					});
					commonClose();
					setFilterGroups([]);
					clearRequired();
				} else {
					Swal.fire({
						title: `${t("Sweetalert.Error")}`,
						text: `${t("Sweetalert.somethingWent")}`,
						// icon: 'success',"error",
						confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
						timer: 2000,
					});
				}
			}).catch((error) => {
				Swal.fire({
					title: `${t("Sweetalert.Error")}`,
					text: `${t("Sweetalert.somethingWent")}`,
					// icon: 'success',"error",
					confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
					timer: 2000,
				});
			});
	};
	// Function to remove users from group.
	const removeFromGroup = (data: UsersForm) => {
		// e.preventDefault();
		// console.log("Remove to Group----")
		let postData = {
			groups: filteredGroups,
			users: getSelectedUsers(),
		};

		userService
			.removeUserFromGroup(postData)
			.then((res) => {
				if (res && res.resCode == 201) {
					Swal.fire({
						title: `${t("Sweetalert.Success")}`,
						text: `${t("Sweetalert.RecordUpdated")}`,
						// icon: 'success',"success",
						confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
						timer: 2000,
					});
					clearRequired();
					commonClose();
				} else {
					Swal.fire({
						title: `${t("Sweetalert.Error")}`,
						text: `${t("Sweetalert.somethingWent")}`,
						// icon: 'success',"error",
						confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
						timer: 2000,
					});
				}
			})
			.catch((error) => {
				Swal.fire({
					title: `${t("Sweetalert.Error")}`,
					text: `${t("Sweetalert.somethingWent")}`,
					// icon: 'success',"error",
					confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
					timer: 2000,
				});
			});
	};
	// Check / Uncheck Single user event.
	const selectUser = async (e) => {
		let checkArr: any = [];
		setIsCheck([]);
		await users.map((data) => {
			if (data && parseInt(e.target.value) === data.ID_USUARIO_USU) {
				data["isChecked"] = !data.isChecked ? true : false;
			}
			return data;
		});
		await setUsers(users);

		if (document.querySelectorAll(".check_Checkbox input")?.length > 0) {
			let data = document.querySelectorAll(".check_Checkbox input");
			let count_allCheck = 0;
			setTimeout(() => {
				let chekAllbtn: any = document.querySelector("#selectAll");
				data.forEach((item, index) => {
					if (item["checked"]) {
						count_allCheck++;
						checkArr.push(item.getAttribute("data-id"));
					}

					if (!item["checked"]) {
						chekAllbtn["checked"] = false;
					}
				});

				if (count_allCheck == data?.length) {
					chekAllbtn["checked"] = true;
				}
				setIsCheck(checkArr);
			}, 10);
		}
	};

	const handleHitsCheckAll = (e) => {
		setIsCheck([]);
		setHITSIsCheckAll(!isHITSCheckAll);
		setIsCheck(HITS.map((li) => li.key));
		if (isHITSCheckAll) {
			setIsCheck([]);
		}
	};

	const handleSelectAll = (e) => {
		setIsCheckAll(!isCheckAll);
		if (!isCheckAll) {
			selectAllUsers();
		} else {
			unSelectAllUsers();
		}
	};
	const selectAllUsers = () => {
		setIsCheckAll(true);
		setIsCheck(HITS.map((li) => li.key));
		setUsers(users.map((topping) => ({ ...topping, isChecked: true })));
	};
	const unSelectAllUsers = () => {
		setIsCheckAll(false);
		setIsCheck([]);
		setUsers(users.map((topping) => ({ ...topping, isChecked: false })));
	};

	// Multiple HITS select function
	const multipleHITSSelect = (item) => {
		// console.log('Checked item ====', item);
		setFilterHits(item);
	};
	// Multiple Group select function
	const multipleGroupSelect = (item) => {
		// console.log('Selected Grops ===', item);
		setFilterGroups(item);
	};
	// FILTERs
	// Multiple HITS select function
	const multipleFilterHITS = async (item) => {
		// console.log('Checked item ====', item);
		setStatus("loading");
		await setFilterHits(item);
		setTimeout(() => {
			getAllUsers({
				search: searchUserFilter,
				hits: item,
				groups: filteredGroups,
				statusIs: compositefilter,
			});
		}, 2000);

	};
	// User Filter Multiple Group select function
	const multipleFilterGroup = async (item) => {
		// console.log('Selected Grops ===', item);
		setStatus("loading");
		await setFilterGroups(item);
		setTimeout(() => {
			getAllUsers({
				search: searchUserFilter,
				hits: filteredHits,
				groups: item,
				statusIs: compositefilter,
			});
		}, 2000);
	};

	const userStatusis = (e: MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		var action: any = "Status";
		action = e.currentTarget.getAttribute("data-action");
		var tname: any = e.currentTarget.getAttribute("data-name");
		setCompositeFilter(action);
		// console.log('++++++++', action);
		if (action == "all") {
			tname = "Status";
		}
		setUserStatus(tname);
		getAllUsers({
			search: searchUserFilter,
			hits: filteredHits,
			groups: filteredGroups,
			statusIs: action,
		});
	};
	// FILTERS END

	let typingTimer;
	// const [timer, setTimer] = useState(null);
	// Search field to filter user
	const searchFilter = (change) => {
		clearTimeout(typingTimer);
		typingTimer = setTimeout(() => {
			setStatus("loading");
			setSearchFilter(change);
			getAllUsers({
				search: change,
				hits: filteredHits,
				groups: filteredGroups,
				statusIs: compositefilter,
			});
			//console.log('Typing finished:', change);
		}, 800);
	};

	const contentRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		// Get all Users
		getAllUsers({ userId: "" });
		// Get Groups
		getAllGroups("");
		setTimeout(() => {
			// console.log('========>>');
			// console.log( window.innerHeight + document.documentElement.scrollTop ,'!==', document.documentElement.offsetHeight );
			const onScroll = () => {
				// isCheckAll, setIsCheckAll
				// if (!isCheckAll && hasMoreData && isBottom(contentRef)) {
				if (!isCheckAll) {
					clearTimeout(timer.current);
					timer.current = setTimeout(() => {
						handleScroll();
					}, 2000);
				}
			};

			// document.addEventListener("scroll", onScroll, { passive: true })
			// window.addEventListener('scroll', onScroll);
			// return () => window.removeEventListener('scroll', onScroll);

			// var elem1 = document.getElementsByClassName("scrollable")[0];
			// elem1.addEventListener("scroll",()=>
			// 	{ console.log('Scrolling event' )}
			// );
			window.addEventListener(
				"scroll",
				(e) => {
					onScroll();
				},
				{ capture: true }
			);
		}, 3000);

		// var elem1 = document.getElementsByClassName("scrollable")[0];
		// console.log('elem1',elem1);
		// elem1.addEventListener("scroll",()=>
		// 	{ console.log('Scrolling event' )}
		// );

		// window.onscroll = debounce(() => {
		// 	// const {
		// 	// 	handleScroll
		// 	// } = this;

		// 	if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
		// 		handleScroll();
		// 	}
		// }, 100);
	}, [limitSelect]);
	useEffect(() => {
		let datapush: any = [];
		allGroups.forEach((item) => {
			datapush.push({
				value: item["ID_GRUPO_GRPU"],
				label: item["ST_NOME_GRPU"],
				access: item["ST_ACESSOS_GRPU"],
			});
		});
		setCopyGroupaccess(datapush);
	}, [allGroups]);

	const handleScroll = () => {
		// setIsCheckAll(true);
		// document.getElementsByClassName('scrollable')[0].scrollHeight;
		// let usersDivHeight = contentRef?.current?.clientHeight;
		// console.log('usersDivHeight ',usersDivHeight );
		// console.log('offsetHeight ',contentRef?.current?.offsetHeight );
		// console.log('scrollTop ',contentRef?.current?.scrollTop );
		let scrollTop: any = 0;
		scrollTop =
			contentRef?.current?.scrollTop != undefined
				? contentRef?.current?.scrollTop
				: 0;
		// console.log('scrollTop:::',scrollTop);

		// console.log(window.innerHeight , document.documentElement.offsetHeight , 'dddpppppppppppp....',  window.innerHeight + document.documentElement.scrollTop ,'===', document.documentElement.offsetHeight );
		// if (usersDivHeight == document.documentElement.offsetHeight){
		if (scrollTop == scrollTopPosition) {
			/*
			setcurrentscrollTop( scrollTop );
			let userData = { userId : '', page: 2, end: 20 }
			userService.getAllGroups(userData).then(res => {
			  if (res && res.data?.length ) {
				setUsers( users => users.concat(res.data) );
			  }
			  else { setUsers([]); }
			  setIsCheckAll(false);
			}).catch(err => {
			  console.log("err", err)
			});
			*/
		} else {
			// setcurrentscrollTop( scrollTop );
		}
	};

	// function to Get All Users
	const getAllUsers = (user: any) => {
		let searchFilter = "";
		if (user.search) {
			searchFilter = user.search;
		}
		let userData = {
			userId: user?.userId,
			groups: user?.groups,
			hits: user?.hits,
			searchFrom: searchFilter,
			statusIs: user?.statusIs,
			...limitSelect
		};
		userService
			.getAllUsers(userData)
			.then((res) => {
				setStatus("");
				if (res.data) {
					setAllUserList(res.data);
					setUsers(res.data);
					setTotalPage(res.totalRow)
				} else {
					setUsers([]);
				}
			}).catch((err) => {
				console.log("err", err);
			});
	};

	const addNewLimitAccess = () => {
		let newindex = Object.keys(inputFields)?.length + 1;
		let newitem = [
			{
				[`open_${newindex - 1}`]: {
					day: "",
					das: "",
					until: "",
				},
			},
		];
		setInputFields({ ...inputFields, ...newitem[0] });
	};

	// culumn is change the data one by one from the opening horse
	const limitdays = (index, evnt) => {
		const { name, value } = evnt.target;
		var namew = evnt.currentTarget.getAttribute("data-days");
		let data = { ...inputFields[`open_${namew}`] };
		setInputFields({
			...inputFields,
			[`open_${namew}`]: {
				...data,
				[name]: value,
			},
		});
	};

	const addNewIPAccess = () => {
		let newindex = Object.keys(ipinputFields)?.length + 1;
		let newitem = [
			{
				[`open_${newindex - 1}`]: {
					ip1: "",
					ip2: "",
					ip3: "",
					ip4: "",
				},
			},
		];
		setIPInputFields({ ...ipinputFields, ...newitem[0] });
	};

	// culumn is change the data one by one from the opening horse
	const setIPAddress = (index, evnt) => {
		const { name, value } = evnt.target;

		if (isNaN(value)) {
			Swal.fire({
				title: `${t("Sweetalert.Error")}`,
				text: `${t("Sweetalert.validnumber")}`,
				// icon: 'success',"error",
				confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
				timer: 2000,
			});
			return
		}
		// Check if the entered value is not a valid number
		if (value === "0000" || value === "000") {
			Swal.fire({
				title: `${t("Sweetalert.Error")}`,
				text: `${t("Sweetalert.allownumber")}`,
				// icon: 'success',"error",
				confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
				timer: 2000,
			});

			return; // Stop further execution
		}

		var namew = evnt.currentTarget.getAttribute("data-ip");
		let data = { ...ipinputFields[`open_${namew}`] };
		setIPInputFields({
			...ipinputFields,
			[`open_${namew}`]: {
				...data,
				[name]: value,
			},
		});
	};

	const deleteLimitAccessField = (e: MouseEvent<HTMLButtonElement>, indexs) => {
		e.preventDefault();
		let dataall: any = [inputFields];
		let newInputField: any = Object.values(dataall[0]);
		let data: any = {};

		newInputField.splice(indexs, 1);
		newInputField.forEach(async (item, index) => {
			data[`open_${index}`] = item;
		});

		setInputFields(data);
	};

	const deleteIpField = (e: MouseEvent<HTMLButtonElement>, indexs) => {
		e.preventDefault();
		let dataall: any = [ipinputFields];
		let newInputField: any = Object.values(dataall[0]);
		let data: any = {};

		newInputField.splice(indexs, 1);
		newInputField.forEach(async (item, index) => {
			data[`open_${index}`] = item;
		});

		setIPInputFields(data);
	};

	// Get my Ip Address
	const getmycurrentIp = async (currentNum) => {
		// get_ip
		userService
			.getMyIp()
			.then((res) => {
				if (res && res.data) {
					let ips = res.data.IPv4;
					let myIps = ips.split(".");
					setIPInputFields({
						...ipinputFields,
						[`open_${currentNum - 1}`]: {
							ip1: myIps?.[0],
							ip2: myIps[1],
							ip3: myIps[2],
							ip4: myIps[3],
						},
					});
				}
			}).catch((err) => {
				console.log("err", err);
			});
	};

	const getUserandUpdate = (e: MouseEvent<HTMLElement>, id) => {
		e.preventDefault();
		setSingleUser(true);
		setSingleUserId(id);
		setIsCheck([]);
		setUpdateusers("Edit")
		var userId: any = id;
		setDataId(userId);

		const userDetails = users.filter((usr) => usr.ID_USUARIO_USU == userId);
		setSingleUserDetail(userDetails[0]);
		// Set user Form
		setValue("name", userDetails?.[0].ST_APELIDO_USU);
		setValue("email", userDetails?.[0].ST_NOME_USU);

		// Mange Group Filter here.
		if (userDetails?.[0]["userInGroups"] != null) {
			let selectedGrp = userDetails?.[0]["userInGroups"].split(",");
			let FilterGroups: any = [];
			allGroups.forEach((item) => {
				let indexOfData = selectedGrp.indexOf(`${item["value"]}`);
				if (indexOfData != -1) {
					FilterGroups.push(item);
				}
			});
			setFilterGroups(FilterGroups);
		}
		let newItems = [];
		// Set UTIS Permission
		if (
			userDetails?.[0]?.ST_ACESSO_USERPERMISSION_USU_JSON !== "undefined" &&
			userDetails?.[0]?.ST_ACESSO_USERPERMISSION_USU_JSON !== "null" &&
			String(userDetails?.[0]?.ST_ACESSO_USERPERMISSION_USU_JSON) !== "undefined"
		) {
			let userPermissionAre = JSON.parse(
				userDetails?.[0]?.ST_ACESSO_USERPERMISSION_USU_JSON
			);

			if (HITS?.length == userPermissionAre?.length) {
				setHITSIsCheckAll(true);
			} else {
				setHITSIsCheckAll(false);
			}
			setIsCheck(userPermissionAre);
		}
		 
		setShow5(true);
	};

	// route to this function
	const handleUserDelete = async (e, id, type) => {
		let userData = await userService.deleteUser(singleUserId);
		// console.log("delete api remain to integreate", userData)
		if (userData && userData.type == "Success") {
			setShow5(false);
			Swal.fire({
				title: `${t("Sweetalert.Success")}`,
				text: `${t("Sweetalert.deleteRecord")}`,
				// icon: 'success',"success",
				confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
				timer: 2000,
			});
			setInputFields({
				[`open_0`]: {
					day: "",
					das: "",
					untill: "",
				},
			});
			setIPInputFields({
				[`open_0`]: {
					ip1: "",
					ip2: "",
					ip3: "",
					ip4: "",
				},
			});
			getAllUsers({ userId: "" })
			clearRequired();
			userClose();
		} else {
			Swal.fire({
				title: `${t("Sweetalert.Error")}`,
				text: `${t("Sweetalert.somethingWent")}`,
				// icon: 'success',"error",
				confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
				timer: 2000,
			});
		}
	};

	// Used to add new user and update User
	const userFormSubmit = (data) => {
		setloader_on(true);
		let LimitAccessDate: any = [];
		if (inputFields) {
			for (var key in inputFields) {
				var value = inputFields[key];
				if (key.indexOf("open") >= 0) {
					LimitAccessDate.push(value);
				}
			}
		}

		let newLimitaccessdata: any = [];
		LimitAccessDate.forEach((item, index) => {
			newLimitaccessdata.push({
				PERIODO: item["day"],
				HORA_INICIO: item["das"],
				HORA_FIM: item["until"],
			});
		});

		let IPDate: any = [];
		if (ipinputFields) {
			for (var key in ipinputFields) {
				var value = ipinputFields[key];
				if (key.indexOf("open") >= 0) {
					IPDate.push(value);
				}
			}
		}

		let assignedGroups: any = copyaccessChangegroup["groups"];
		// if (filteredGroups?.length > 0) {
		//   filteredGroups.forEach((element) => {
		//     assignedGroups.push({
		//       value: element["ID_GRUPO_GRPU"],
		//       name: element["ST_NOME_GRPU"],
		//     });
		//   });
		// }

		let checkData: any = isCheck;
		if (data.copy_group_access !== "" && copyGroupAccess?.length) {
			copyGroupAccess.forEach((item, index) => {
				if (item.value == Number(data.copy_group_access) && item["access"]) {
					checkData = Object.keys(JSON.parse(item["access"]));
				}
			});
		} else {
			checkData = isCheck;
		}
		// changing thissssss

		let userData = {
			userId: dataId ? dataId : "",
			name: data.name,
			email: data.email,
			password: data.password,
			ipinputFields: IPDate?.length ? JSON.stringify(IPDate) : "",
			inputFields: newLimitaccessdata ? JSON.stringify(newLimitaccessdata) : "",
			acessoUsu: checkData && checkData?.length ? JSON.stringify(checkData) : "",
			filteredGroups: assignedGroups?.length
				? JSON.stringify(assignedGroups)
				: "",
		};

		userService
			.createUser(userData)
			.then((res) => {
				if ((res && res.resCode == 200) || (res && res.resCode == 202) || (res && res.resCode == 201)) {
					if (registerAnother) {
						setIsCheck([]);
						setHITSIsCheckAll(false);
						setregisterAnother(false);
					} else {
						userClose();
					}
					Swal.fire({
						title: `${t("Sweetalert.Success")}`,
						text: `${t("Sweetalert.insertRecord")}`,
						// icon: 'success',"success",
						confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
						timer: 2000,
					});
					setloader_on(false);
					clearRequired();
				} else {

					let emsg = '';
					if (res.resCode == 400 || res.resCode == 406) {
						emsg = res.message;
					}

					Swal.fire({
						title: `${t("Sweetalert.Error")}`,
						text: emsg ? emsg : `${t("Sweetalert.cannotInsert")}`,
						// icon: 'success',"error",
						confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
						timer: 2000,
					});

					setloader_on(false);
				}
			})
			.catch((error) => {
				Swal.fire({
					title: `${t("Sweetalert.Error")}`,
					text: `${t("Sweetalert.somethingWent")}`,
					// icon: 'success',"error",
					confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
					timer: 2000,
				});
				setloader_on(false);
			});
	};

	const [selectedcmd, setSelectedCmd] = useState("users");
	const handleMostEvent = async (e: any, cmd: string) => {
		e.preventDefault();
		let selectData: any = await getSelectedUsers();
		if (selectData?.length > 0 && cmd == "copy_access_user") {
			setShow6(true);
			setSelectedCmd("users");
			setCopyUserData(true);
		}
		if (selectData?.length > 0 && cmd == "copy_access_group") {
			setShow6(true);
			setSelectedCmd("group");
			setCopyUserData(false);
		}
		if (selectData?.length > 0 && cmd == "change_restrictions") {
			setShow7(true);
			setSelectedCmd("change_restrictions");
		}
		if (selectData?.length > 0 && (cmd == "enableUsr" || cmd == "disableUsr")) {
			changeUsrStatus(cmd);
		}
	};

	const changeUsrStatus = async (action: any) => {
		let selectData: any = await getSelectedUsers();
		Swal.fire({
			title: `${t('Sweetalert.Warning')}`,
			text: `${t('Sweetalert.updatefooter')}`,
			// icon: 'success',"question",
			showCancelButton: true,
			confirmButtonText: `${t('Sweetalert.changeIt')}`,
			cancelButtonText: `${t("button.cancel")}`
		}).then(async (result) => {
			if (result.isConfirmed == true) {
				let useData = await userService.updateStatusUser({ userIds: selectData, action: action });
				if (useData && useData.type == "Success") {
					Swal.fire({
						position: 'center',
						// icon: 'success','success', 
						title: `${t('Sweetalert.Success')}`,
						text: `${t('infosuccesschange')}`, confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
						timer: 3000
					})
					await users.map((data) => {
						if (data && selectData.indexOf(parseInt(data.ID_USUARIO_USU)) >= 0) {
							data["FL_USUARIODESATIVADO_USU"] = (action == 'disableUsr') ? "0" : "1";
						}
						return data;
					});
					setUsers([]);
					setTimeout(async () => {
						await setUsers(users);
					}, 1200);
				} else {
					Swal.fire({
						title: `${t('Sweetalert.Error')}`, text: useData.payload.message,
						// icon: 'success',"error", 
						confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
						timer: 3000
					})
				}
			}
		})
	}

	const updateTimeIp = async (data: any) => {
		let IPDate: any = [];
		for (var key in ipinputFields) {
			var value = ipinputFields[key];
			if (key.indexOf("open") >= 0) {
				IPDate.push(value);
			}
		}
		let LimitAccessDate: any = [];
		for (var key in inputFields) {
			var value = inputFields[key];
			if (key.indexOf("open") >= 0) {
				LimitAccessDate.push(value);
			}
		}
		let userData = {
			user_id: isCheck,
			ipinputFields: IPDate?.length ? JSON.stringify(IPDate) : "",
			inputFields: LimitAccessDate ? JSON.stringify(LimitAccessDate) : "",
		};
		await userService.SingaldayAndTimeChange(userData).then((res) => {
			if (res.resCode === 201) {
				Swal.fire({
					title: `${t("Sweetalert.Success")}`,
					text: `${t("Sweetalert.RecordUpdated")}`,
					// icon: 'success',"success",
					confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
					timer: 2000,
				});
				setShow7(false);
				clearRequired();

				// getAllGroups('')
			} else {
				Swal.fire({
					title: `${t("Sweetalert.Error")}`,
					text: `${t("Sweetalert.somethingWent")}`,
					// icon: 'success',"error",
					confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
					timer: 2000,
				});
			}
		});
	};

	// Change restrictions by day, time and location
	const accessData = async (data: any) => {
		if (selectedcmd === "users") {
			let obj = {
				id_1: data.sourceuser,
				id_2: isCheck,
			};
			let datauser = users.filter((item) => Number(item.ID_USUARIO_USU) == Number(data.sourceuser));
			obj["access"] = datauser?.[0]?.["ST_ACESSO_USERPERMISSION_USU_JSON"];
			obj["ipaddress"] = datauser?.[0]?.["ST_IPSLIBERADOS_USU_JSON"];
			obj["userInGroups"] = datauser?.[0]?.["userInGroups"] || null;
			await userService.copyAccessUser(obj).then((res) => {
				if (res.resCode === 201) {
					Swal.fire({
						title: `${t("Sweetalert.Success")}`,
						text: t("Sweetalert.updatesuccess"),
						// icon: 'success',"success",
						confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
						timer: 2000,
					});
					setShow6(false);
					clearRequired();
					// getAllGroups('')
				} else {
					Swal.fire({
						title: `${t("Sweetalert.Error")}`,
						text: `${t("Sweetalert.somethingWent")}`,
						// icon: 'success',"error",
						confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
						timer: 2000,
					});
				}
			});
		} else {
			let obj = {
				users_id: isCheck,
				group_id: data.sourceuser,
			};
			await userService.copyGroupAccessUser(obj).then((res) => {
				if (res.resCode === 201) {
					Swal.fire({
						title: `${t("Sweetalert.Success")}`,
						text: `${t("Sweetalert.RecordUpdated")}`,
						// icon: 'success',"success",
						confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
						timer: 2000,
					});
					setShow6(false);
					clearRequired();

				} else {
					Swal.fire({
						title: `${t("Sweetalert.Error")}`,
						text: `${t("Sweetalert.somethingWent")}`,
						// icon: 'success',"error",
						confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
						timer: 2000,
					});
				}
			});
		}
	};

	const copyAccessGroupBtn = async (e) => {
		let res: any = ""
		let currLength: any = 0;
		let eachCheck: any = document.querySelectorAll(".checkOption_Checkbox input");
		let selectAll: any = document.querySelector("#selectAll-ell");
		if (e.target.value !== "") {
			res = await userService.getGroupData({ groupId: e.target.value });
			let groupDetails = res.data[0];

			let allGroupPermission: any = [];
			let allpemissionData = JSON.parse(groupDetails?.["ST_ACESSOS_GRPU"]);
			let allKeyPermission = Object.keys(allpemissionData);
			allGroupPermission.push(...allKeyPermission);

			let NewSetPermissionUniq = new Set(allGroupPermission);
			let ChangeSetToArray = Array.from(NewSetPermissionUniq);

			let postData = {
				groups: [{
					value: groupDetails?.["ID_GRUPO_GRPU"]
				}],
				users: [],
				permission: ChangeSetToArray
			};

			setcopyaccessChangegroup(postData);
			if (groupDetails.ST_ACESSOS_GRPU && groupDetails.ST_ACESSOS_GRPU != null) {
				if ((groupDetails.ST_ACESSOS_GRPU).search('"1')) {
					let permi = JSON.parse(groupDetails.ST_ACESSOS_GRPU);
					let mmp: any = [];
					await HITS.map(lis => {
						if (permi[lis.indexKey] == 1) {
							mmp.push(lis.key);
						}
					}) 
					setIsCheck(mmp);
					currLength = mmp?.length;

					eachCheck.forEach((item, index) => {
						item.setAttribute("disabled", "")
					})
					selectAll.setAttribute("disabled", "")
				} else {
					setIsCheck(JSON.parse(groupDetails.ST_ACESSOS_GRPU));
				}
			} else {
				setIsCheck([]);
			}
		} else {
			let userDetails = users.filter((usr) => usr.ID_USUARIO_USU == singleUserId);
			if (
				userDetails?.[0]?.ST_ACESSO_USERPERMISSION_USU_JSON !== "undefined" &&
				userDetails?.[0]?.ST_ACESSO_USERPERMISSION_USU_JSON !== "null" &&
				String(userDetails?.[0]?.ST_ACESSO_USERPERMISSION_USU_JSON) !== "undefined"
			) {
				let userPermissionAre = JSON.parse(
					userDetails?.[0]?.ST_ACESSO_USERPERMISSION_USU_JSON
				);
				currLength = userPermissionAre?.length;
				setIsCheck(userPermissionAre);
				// eachCheck.forEach((item, index) => {
				//   item.removeAttribute("disabled")
				// })
				// selectAll.removeAttribute("disabled")

			} else {
				setIsCheck([]);
				// eachCheck.forEach((item, index) => {
				//   item.removeAttribute("disabled")
				// })
				// selectAll.removeAttribute("disabled")
			}
		}
	}

	useEffect(() => {
		if (HITS?.length == isCheck?.length) {
			setHITSIsCheckAll(true);
		} else {
			setHITSIsCheckAll(false);
		}
	}, [isCheck])

	const changePasswordRequest = (e: MouseEvent<HTMLElement>) => {
		setIsCheck([]);
		e.preventDefault();
		var userId: any = e.currentTarget.getAttribute("data-key-id");
		var email: any = e.currentTarget.getAttribute("data-key-email");
		var action: any = e.currentTarget.getAttribute("data-action-key");
		let userDetails: any;
		if (action == "changePasswordRequest") {
			Swal.fire({
				title: t("Sweetalert.changepass"),
				text: '',
				// icon: 'success','info',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: t("button.send"),
				cancelButtonText: t("button.cancel")
			}).then(async (result) => {
				if (result.value) {
					let data = {
						userId: userId,
						email: email
					};
					await userService.resetPasswordUserByAdmin(data).then((res) => {
						if (res.resCode === 201) {
							Swal.fire({
								title: `${t("Sweetalert.Success")}`,
								text: `${t("Sweetalert.RecordUpdated")}`,
								// icon: 'success',"success",
								confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
								timer: 2000,
							});
						} else {
							Swal.fire({
								title: `${t("Sweetalert.Error")}`,
								text: res.data ? res.data : `${t("Sweetalert.somethingWent")}`,
								// icon: 'success',"error",
								confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
								timer: 5000,
							});
						}
					});
				}
			})
		}
	};

	// Filters section
	const [openFilters, setFiltersOpen] = useState(false);
	// const updatedPermissionSelf = (permissionEncrypt) => {
	//   let stringifyPermissionData = JSON.stringify(permissionEncrypt);
	//   const encryptedString = CryptoJS.AES.encrypt(stringifyPermissionData, secretKey).toString();
	//   localStorage.setItem("permissionData", encryptedString);
	// }
	return (
		<section className="content-wrapper content-wrapper-primary" ref={contentRef}>
			{/* <!-- Breadcrumb section start from here -->*/}
			<div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
				<div className="w-100 mb-xl-4">
					<Row className="gx-xl-5 justify-content-between align-items-center">
						<Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
							<div className="d-flex align-items-center justify-content-between me-xl-1">
								<h1 className="mb-0 lh-base text-truncate">
									{t("users.name")}
								</h1>
								{/* Filter button visible in mobile devices */}
								<div className="d-xl-none">
									<Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
										{t("dropdownItem.filters")}
										<img
											src="assets/images/icons/filter-icon.svg"
											alt="Filter"
											className="ms-1 h-12"
										/>
									</Button>
								</div>
							</div>
						</Col>
						<Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
							{/* Search bar */}
							<InputGroup>
								<Form.Control
									placeholder={t("formLabel.typeSearch")}
									onChange={(e) => {
										searchFilter(e.target.value);
									}}
									aria-label="Search"
									aria-describedby="search_icon"
								/>
								<InputGroup.Text id="search_icon">
									<img
										src="./assets/images/icons/search.svg"
										alt="Search icon"
									/>
								</InputGroup.Text>
							</InputGroup>
						</Col>
						<Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
							<ul className="list-inline mb-0">
								<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
									{
										userJson && userJson?.length > 0 && (userJson.indexOf(1001) !== -1) &&
										<Button
											variant="link"
											onClick={userShow}
											className="fw-bold p-0"
										>
											<img
												src="assets/images/icons/plus-light.svg"
												alt="Plus icon"
												className="me-1 h-20"
											/>
											{t("customerAndEmployees.newUser")}
										</Button>
									}

								</li>
								<li className="list-inline-item mb-md-0 mb-1">
									<img
										src="assets/images/icons/print-light.svg"
										alt="Print"
										className="h-20"
										onClick={PrintPDF}
									/>
								</li>
								{/* <li className="list-inline-item mb-md-0 mb-1">
                  <img
                    src="assets/images/icons/question_icon.svg"
                    alt="Question icon"
                    className="h-20"
                  />
                </li> */}
							</ul>
						</Col>
					</Row>
				</div>
				<Collapse className="d-xl-block collpase-filters" in={openFilters}>
					<Form id="collpase-filter-text" action="" method="">
						<div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
							<h5 className="mb-0 fw-bold">Filters</h5>
							<span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
								<img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
							</span>
						</div>
						<Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
							{/* <Col xl="auto">
                <Dropdown>
                  <Dropdown.Toggle variant="light" size="sm" className={`${userstatus !== "Status" ? "show" : ""}`}>
                    {userstatus}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="px-4">
                    <Dropdown.Item
                      href="#"
                      data-action="0"
                      data-name={t("active")}
                      onClick={userStatusis}
                    >
                      {t("active")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      data-action="1"
                      data-name={t("inActive")}
                      onClick={userStatusis}
                    >
                      {t("inActive")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col> */}
							<Col xl="auto">
								<Dropdown autoClose="outside">
									<Dropdown.Toggle variant="light" size="sm" className={filteredGroups?.length > 0 ? "show" : ""}>
										{t("label.group")}
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdwon-lg px-4">
										<MultiSelect
											options={allGroups}
											value={filteredGroups}
											onChange={multipleFilterGroup}
											labelledBy={t("formLabel.select")}
											overrideStrings={{
												selectSomeItems: `${t("formLabel.select")}`,
												selectAll: `${t("selectAll")}`,
												search: `${t("formLabel.typeSearch")}`,
												allItemsAreSelected: `${t("selectAll")}`,
											}}
										/>
									</Dropdown.Menu>
								</Dropdown>
							</Col>
							<Col xl="auto">
								<Dropdown autoClose="outside">
									<Dropdown.Toggle variant="light" size="sm" className={filteredHits?.length > 0 ? "show" : ""}>
										{t("label.access")}
									</Dropdown.Toggle>
									<Dropdown.Menu className={`dropdwon-lg px-4`} >
										<MultiSelect
											options={HITS}
											value={filteredHits}
											onChange={multipleFilterHITS}
											labelledBy={t("formLabel.select")}
											overrideStrings={{
												selectSomeItems: `${t("formLabel.select")}`,
												selectAll: `${t("selectAll")}`,
												search: `${t("formLabel.typeSearch")}`,
												allItemsAreSelected: `${t("selectAll")}`,
											}}

										/>
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</Row>
					</Form>
				</Collapse>
			</div>
			{/* <!-- /Breadcrumb section end -->*/}
			{/* <!-- Main content start from here -->*/}
			<main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3 scrollable" id="userstsxtempalte">
				<div>
					<Row className="gy-3 gy-lg-4">
						<Col lg={8}>
							{/* <!-- Table start from here --> */}

							{status == "loading" ? (
								<Loader />
							) : (
								<Card>
									<Card.Body className="pb-1">
										<Table
											responsive
											className="table-custom table-custom-bordered mb-0"
										>
											<thead>
												<tr>
													<th className="w-24">
														{users && users?.length > 0 ? (
															<Checkbox
																type="checkbox"
																name="selectAll"
																id="selectAll"
																handleClick={handleSelectAll}
																isChecked={isCheckAll}
															/>
														) : (
															""
														)}
													</th>
													<th>{t("userModule.user")}</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{/* {users?.length === 0 && <h4>Loading ...</h4>} */}
												{users &&
													users?.length > 0 &&
													users.map((user: any, index) => (
														<tr key={index}>
															<td className="check_Checkbox">
																{
																	user.TYPE !== "50" && user.TYPE !== 1111 &&
																	<Form.Check
																		type="checkbox"
																		data-id={user.ID_USUARIO_USU}
																		onChange={selectUser}
																		checked={user.isChecked}
																		value={user.ID_USUARIO_USU ? user.ID_USUARIO_USU : userVal}
																	/>
																}
															</td>
															<td>
																<span className="d-block text-dark-70">
																	{user.ST_APELIDO_USU}
																</span>
																<a className="text-secondary" href={`mailto:${user.ST_NOME_USU}`}>
																	{user.ST_NOME_USU}
																</a>
															</td>
															<td>
																{/* {user.FL_USUARIODESATIVADO_USU == "0" &&
																	<Badge bg="success-10" className="text-dark">{t("active")}</Badge>
																}
																{user.FL_USUARIODESATIVADO_USU == "1" &&
																	<Badge bg="primary-20" className="text-dark">{t("Deactive")}</Badge>
																}
																{String(user.TYPE) === "50" && user.TYPE === 1111 &&
																	<Badge bg="info-20" className="text-dark">{t("AgencyAccount")}</Badge>
																} */}
															</td>
															<td className="text-end">
																{
																	userJson && userJson?.length > 0 && (userJson.indexOf(1001) !== -1) &&
																	user.TYPE !== "50" && user.TYPE !== 1111 &&
																	<ul className="list-inline mb-0 d-flex justify-content-end align-items-center">
																		<li
																			onClick={(e) =>
																				getUserandUpdate(e, user.ID_USUARIO_USU)
																			}
																			data-key-id={user.ID_USUARIO_USU}
																			className="list-inline-item me-2 me-xxl-4 me-lg-3"
																		>
																			<span className="icon img-primary cursor-pe"><img src="assets/images/icons/edit-light.svg" alt="Edit" className="h-20" /></span>
																		</li>
																		<li
																			className="list-inline-item"
																			onClick={changePasswordRequest}
																			data-action-key="changePasswordRequest"
																			data-key-id={user.ID_USUARIO_USU}
																			data-key-email={user.ST_NOME_USU}
																		>
																			<span className="icon img-primary cursor-pe"><img src="./assets/images/icons/key-light.svg" alt="Key" className="h-20" /></span>
																		</li>
																	</ul>
																}
															</td>

														</tr>
													))}
												{!users?.length ? (
													<tr>
														<td colSpan={4} className="text-center">{t("OOPS")}.</td>
													</tr>
												) : (
													<></>
												)}
											</tbody>
										</Table>
									</Card.Body>
								</Card>
							)}

							{
								(totalpage && userGroup == "user" && totalpage > 0) ?
									<div className="d-flex justify-content-center mt-lg-4 mt-3">
										<Pagination
											className="pagination-bar"
											currentPage={currentPage}
											totalCount={totalpage}
											pageSize={PageSize}
											onPageChange={(page: any) => setCurrentPage(page)}
										/>
									</div>
									: ''
							}

							{/* <!-- /Table end -->*/}
							<p className="mt-lg-4 mt-3">
								{t("formLabel.listing")} {users?.length} {users?.length > 1 ? t("users.name") : t("users.user")}
							</p>
							{/* <!-- Nav listing start from here -->*/}
							<ul className="list-inline mb-0">
								{/* <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
											<Dropdown>
												<Dropdown.Toggle variant="light" size="sm" id="select_alll">
													{t('userModule.toMark')}
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<Dropdown.Item href="#" onClick={selectAllUsers}>{t("all")}</Dropdown.Item>
													<Dropdown.Item href="#" onClick={unSelectAllUsers}>None</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</li> */}
								{
									userJson && userJson?.length > 0 && (userJson.indexOf(1001) !== -1) &&
									<>
										<li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
											{" "}
											<span className="px-1">{t("withtagged")}:</span>
										</li>
										<li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
											<Button
												variant="light"
												size="sm"
												type="button"
												onClick={handleMouseEvent}
												name="Add to Group"
											>
												{t("userModule.addToGroup")}
											</Button>
										</li>
										<li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
											<Button
												variant="light"
												size="sm"
												type="button"
												onClick={handleMouseEvent}
												name="Remove from a group"
											>
												{t("userModule.removeFromGroup")}
											</Button>
										</li>

										<li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
											<Button
												variant="light"
												size="sm"
												type="button"
												onClick={(e: any) => {
													handleMostEvent(e, "enableUsr");
												}}
												name="Enable"
											>
												{t("notifications.enable")}
											</Button>
										</li>

										<li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
											<Button
												variant="light"
												size="sm"
												type="button"
												onClick={(e: any) => {
													handleMostEvent(e, "disableUsr");
												}}
												name="Disable"
											>
												{t("formReceipt.disable")}
											</Button>
										</li>

										{/* <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                          <Button
                            variant="light"
                            size="sm"
                            type="button"
                            onClick={permissionShow}
                            className="btn btn-light btn-sm"
                            data-action-key="changepermissionbtn"
                          >
                            {t("userModule.permissionAccess")}
                          </Button>
                        </li> 
                        <li className="list-inline-item mb-lg-3 mb-md-2 mb-1">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              size="sm"
                              id="dropdown-basic"
                            >
                              {t("most")}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={(e: any) => {
                                  handleMostEvent(e, "change_restrictions");
                                }}
                                data-event="change-restrictions"
                              >
                                {t("users.changeRestrictions")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={(e: any) => {
                                  handleMostEvent(e, "copy_access_user");
                                }}
                                data-event="copy-access-permission"
                              >
                                {t("users.copyAccessPermissions")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={(e: any) => {
                                  handleMostEvent(e, "copy_access_group");
                                }}
                                data-event="copy-access"
                              >
                                {t("users.copyAccessFrom")}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li> */}


									</>
								}
							</ul>
							{/* <!-- /Nav listing  end -->*/}
						</Col>
						<Col lg={4}>
							<Groups
								setGroupData={setGroupData}
								allGroups={allGroups}
								setGroups={setGroups}
								HITS={HITS}
								getAllUsers={getAllUsers}
								setuserGroup={setuserGroup}
							/>
						</Col>
					</Row>
				</div>
			</main>
			{/* <!-- /Main content start end --> */}
			{/* New user group add modal start from here */}

			{/* CHANGE PERMISSION */}
			<Modal show={show2} onHide={permissionClose}>
				<Form onSubmit={handleSubmit(permissionSubmit)}>
					<Modal.Header closeButton>
						<Modal.Title>{t("userModule.permissionAccess")}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<span className="d-block mb-3">{t("label.access")}</span>
						<div>
							<Form.Control
								type="text"
								defaultValue="change-access-permissions"
								{...register("type")}
								hidden
							/>
							<Checkbox
								type="checkbox"
								name="selectAll-ell"
								id="selectAll-ell"
								handleClick={handleHitsCheckAll}
								isChecked={isHITSCheckAll}
								disabled={true}
							/>
							<label
								title={t("selectAll")}
								htmlFor="selectAll-ell"
								className="form-check-label ms-1"
							>
								{t("selectAll")}
							</label>
							{createCheckboxes()}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="outline-primary" onClick={permissionClose}>
							{t("button.close")}
						</Button>
						<Button type="submit" variant="primary" name="save">
							{t("button.toSave")}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			{/* /Change access permissions add modal end */}

			{/* Add or remove group modal start from here */}
			<Modal show={show3} onHide={commonClose}>
				<Form onSubmit={handleSubmit(hide ? removeFromGroup : addtoGroup)}>
					<Modal.Header closeButton>
						<Modal.Title>{headingText}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{/* <Form.Select {...register('group')}>
							{allGroups?.length > 0 && allGroups.map((group: any) => (
								<option value={group.ID_GRUPO_GRPU}>{group.ST_NOME_GRPU}</option>
							)
							)}
						</Form.Select> */}
						<Form.Control
							type="text"
							defaultValue="addtoGroup"
							{...register("group")}
							hidden
						/>
						<MultiSelect
							options={allGroups}
							value={filteredGroups}
							onChange={multipleGroupSelect}
							labelledBy={t("formLabel.select")}
							overrideStrings={{
								selectSomeItems: `${t("formLabel.select")}`,
								selectAll: `${t("selectAll")}`,
								search: `${t("formLabel.typeSearch")}`,
								allItemsAreSelected: `${t("selectAll")}`,
							}}

						/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="outline-primary" onClick={commonClose}>
							{t("button.close")}
						</Button>
						<Button type="submit" variant="primary" name="save">
							{t("button.toSave")}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			{/* /Add or remove group modal end */}

			{/* Add new user modal start from here */}
			<Modal show={show5} onHide={userClose} id="usertsxTemplate">
				<Form onSubmit={handleSubmit(userFormSubmit)} className="usertsx-template">
					<Modal.Header closeButton>
						<Modal.Title>{updateusers !== "Edit" ? t("customerAndEmployees.newUser") : t("userModule.updateUser")}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
							<Col md={12}>
								<FloatingLabel
									controlId="floatingInput"
									label={t("formLabel.name") + " *"}
								>
									<Form.Control
										type="text"
										placeholder={t("formLabel.name")}
										className={`${errors.name ? "is-invalid" : ""}`}
										{...register("name")}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.name?.message}
									</Form.Control.Feedback>
								</FloatingLabel>
							</Col>

							<Col md={6}>
								<FloatingLabel
									controlId="floatingInput"
									label={t("formLabel.email")+ " *"}
								>
									<Form.Control
										type="text"
										placeholder={t("formLabel.email")}
										className={`${errors.email ? "is-invalid" : ""}`}
										{...register("email")}
										readOnly={dataId == '' ? false : true}
										disabled={dataId == '' ? false : true}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.email?.message}
									</Form.Control.Feedback>
								</FloatingLabel>
							</Col>

							{(dataId == '') &&
								<Col md={6}>
									<FloatingLabel
										controlId="floatingInput"
										label={t("formLabel.password")+ " *"}
									>
										<Form.Control
											type="password"
											placeholder={t("formLabel.password")}
											className={`${errors.password ? "is-invalid" : ""}`}
											{...register("password")}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.password?.message}
										</Form.Control.Feedback>
									</FloatingLabel>
								</Col>
							}

							<Col md={12}>
								<FloatingLabel
									controlId="floatingInput"
									label={t("formLabel.copygroupaccess")}
								>
									<Form.Select
										className={`${errors.copy_group_access ? "is-invalid" : ""}`}
										{...register("copy_group_access")}
										onChange={(e) => copyAccessGroupBtn(e)}
									>
										<option value="">{t("formLabel.select")}</option>
										{copyGroupAccess.map((item, index) => {
											if (item && item.label && item.label != "") {
												return (
													<option key={index} value={item.value}>
														{" "}
														{item.label}{" "}
													</option>
												);
											}
										})}
									</Form.Select>
									{/* <Form.Control type="text" placeholder="Copy group access"  /> */}
								</FloatingLabel>
							</Col>
						</Row>
						<span className="d-block mb-3 fw-bold">{t("label.access")}</span>
						<div className="mb-lg-4 mb-3">
							{/* Working here */}
							<Checkbox
								type="checkbox"
								name="selectAll-ell"
								id="selectAll-ell"
								handleClick={handleHitsCheckAll}
								isChecked={isHITSCheckAll}
								disabled={true}
							/>
							<label
								title={t("selectAll")}
								htmlFor="selectAll-ell"
								className="form-check-label ms-1"
							>
								{t("selectAll")}
							</label>
							{createCheckboxes()}
						</div>
					</Modal.Body>
					<Modal.Footer className={`d-flex align-items-center ${!!singleuser ? " justify-content-end" : " justify-content-between"}`} >
						{!singleuser && (
							<div className="form-check"></div>
						)}
						<div className="flex-shrink-0 ms-2">
							{
								loader_on ?
									<Button>
										<span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
									</Button>
									:
									<>
										<Button
											variant="link"
											onClick={userClose}
											className="p-0"
										>
											{t("button.close")}
										</Button>
										{singleuser && singleUserDetail.FL_USUARIODESATIVADO_USU == "1" &&
											<Button variant="outline-primary" onClick={(e) => handleUserDelete(e, "id", "disable")}>
												{t("formReceipt.disable")}</Button>
										}
										{singleuser && singleUserDetail.FL_USUARIODESATIVADO_USU == "0" &&
											<Button variant="outline-primary" onClick={(e) => handleUserDelete(e, "id", "enable")}>
												{t("notifications.enable")}</Button>
										}
										<Button
											type="submit"
											variant={singleuser ? "primary" : "primary"}
											name="save"
											onClick={openErrorbox}
										>
											{t("button.toSave")}
										</Button>
									</>
							}
						</div>
					</Modal.Footer>
				</Form>
			</Modal>

			{/* /Add new user modal end */}
			<Modal show={show6} onHide={commonClose}>
				<Form onSubmit={handleSubmit(accessData)}>
					<Modal.Header closeButton>
						<Modal.Title>
							{copyuserdata ? t("copyuserdata") : t("copygroupdata")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row>
							<Col md={12}>
								<FloatingLabel
									controlId="variables"
									label={
										copyuserdata
											? t("formLabel.sourceUser")
											: t("formLabel.sourceUsergroup")
									}
								>
									<Form.Select
										className={`${errors.sourceuser ? "is-invalid" : ""}`}
										{...register("sourceuser")}
									>
										<option value="">{t("formLabel.select")}</option>
										{selectedcmd == "users"
											? alluserList &&
											alluserList.map((curElem: any, index) => {
												return (
													<option key={index} value={curElem?.ID_USUARIO_USU}>
														{curElem?.ST_APELIDO_USU}
													</option>
												);
											})
											: allGroups &&
											allGroups.map((curElem: any, index) => {
												return (
													<option key={index} value={curElem?.ID_GRUPO_GRPU}>
														{curElem?.ST_NOME_GRPU}
													</option>
												);
											})}
									</Form.Select>
								</FloatingLabel>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="outline-primary" onClick={commonClose}>
							{t("button.close")}
						</Button>
						<Button type="submit" variant="primary">
							{t("button.toSave")}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>

			{/* /Add new user modal end */}
			<Modal show={show7} onHide={commonClose}>
				<Form onSubmit={handleSubmit(updateTimeIp)}>
					<Modal.Header closeButton>
						<Modal.Title>{t("users.changeRestrictions")}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row>
							<Accordion defaultActiveKey="0" className="accordion-custom">
								<Accordion.Item eventKey="0">
									<Accordion.Header>
										{t("userModule.limitAccessByday")}
									</Accordion.Header>
									<Accordion.Body>
										{Object.keys(inputFields) &&
											Object.keys(inputFields)?.length > 0 &&
											Object.keys(inputFields).map((item: any, index) => {
												return (
													<Row
														className="gy-lg-4 gy-3 mb-3 mb-lg-4"
														key={"_abc" + index}
													>
														<Col md={4}>
															<FloatingLabel
																controlId="floatingSelect"
																label={t("formLabel.day")}
															>
																<Form.Select
																	data-days={index}
																	value={inputFields[item]?.day}
																	name="day"
																	onChange={(evnt) => limitdays(index, evnt)}
																	aria-label="sex"
																>
																	{DAYS &&
																		DAYS?.length > 0 &&
																		DAYS.map((day: any, index) => (
																			<option key={index} value={day.key}>
																				{day.value}
																			</option>
																		))}
																</Form.Select>
															</FloatingLabel>
														</Col>
														<Col md={3}>
															<FloatingLabel
																controlId="floatingInput"
																label={t("formLabel.das")}
															>
																<Form.Control
																	data-days={index}
																	onChange={(evnt) => limitdays(index, evnt)}
																	name="das"
																	value={inputFields[item]?.das}
																	type="time"
																	placeholder="Enter Das"
																	className={`form-control ${errors.das ? "is-invalid" : ""}`}
																/>
															</FloatingLabel>
														</Col>
														<Col md={3}>
															<FloatingLabel
																controlId="floatingInput"
																label={t("formLabel.until")}
															>
																<Form.Control
																	type="time"
																	onChange={(evnt) => limitdays(index, evnt)}
																	name="until"
																	value={inputFields[item]?.until}
																	placeholder="Enter Until"
																	className={`form-control ${errors.until ? "is-invalid" : ""}`}
																	data-days={index}
																/>
															</FloatingLabel>
														</Col>
														{Object.keys(inputFields)?.length > 1 && (
															<Col md={2}>
																<Button
																	variant="link"
																	className="bg-light border-light rounded-4"
																	data-rowid={index}
																	onClick={(e) =>
																		deleteLimitAccessField(e, index)
																	}
																>
																	<img
																		className="h-14"
																		src="./assets/images/icons/delete-light.svg"
																		alt="Delete"
																	/>
																</Button>
															</Col>
														)}
													</Row>
												);
											})}
										<Button
											variant="link"
											className=" p-0 fw-bold"
											onClick={addNewLimitAccess}
										>
											{t("userModule.addNew")}
										</Button>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="1">
									<Accordion.Header>{t("userModule.fixedIp")}</Accordion.Header>
									<Accordion.Body>
										<Alert variant="warning" className="mb-lg-4 mb-3">
											{t("users.alertTouse")}
										</Alert>
										{Object.keys(ipinputFields) &&
											Object.keys(ipinputFields)?.length > 0 &&
											Object.keys(ipinputFields).map((item: any, index) => {
												return (
													<Row
														className="gy-lg-4 gy-3 mb-3 mb-lg-4 align-items-center gx-1 text-center fw-bold"
														key={`ands_${index}`}
													>
														<Col xs={5} sm={2}>
															<Form.Control
																data-ip={index}
																onChange={(evnt) => setIPAddress(index, evnt)}
																maxLength={4}
																name="ip1"
																value={`${ipinputFields[item]?.["ip1"]}`}
																type="text"
																className="text-center"
																placeholder=""
															/>
														</Col>
														<Col xs="1" sm>
															.
														</Col>
														<Col xs={5} sm={2}>
															<Form.Control
																data-ip={index}
																onChange={(evnt) => setIPAddress(index, evnt)}
																maxLength={4}
																name="ip2"
																value={`${ipinputFields[item]?.["ip2"]}`}
																type="text"
																className="text-center"
																placeholder=""
															/>
														</Col>
														<Col xs="1" sm>
															.
														</Col>
														<Col xs={5} sm={2}>
															<Form.Control
																data-ip={index}
																onChange={(evnt) => setIPAddress(index, evnt)}
																maxLength={4}
																name="ip3"
																value={`${ipinputFields[item]?.["ip3"]}`}
																type="text"
																className="text-center"
																placeholder=""
															/>
														</Col>
														<Col xs="1" sm>
															.
														</Col>
														<Col xs={5} sm={2}>
															<Form.Control
																data-ip={index}
																onChange={(evnt) => setIPAddress(index, evnt)}
																maxLength={4}
																name="ip4"
																value={`${ipinputFields[item]?.["ip4"]}`}
																type="text"
																className="text-center"
																placeholder=""
															/>
														</Col>
														<Col xs={5} sm={2}>
															{Object.keys(ipinputFields)?.length > 1 && (
																<Button
																	variant="link"
																	className="bg-light border-light w-100"
																	data-rowid={index}
																	onClick={(e) => deleteIpField(e, index)}
																>
																	<img
																		className="h-14"
																		src="./assets/images/icons/delete-light.svg"
																		alt="Delete"
																	/>
																</Button>
															)}
														</Col>
													</Row>
												);
											})}
										<div className="d-flex align-items-center">
											<Button
												variant="link"
												className="p-0 fw-bold me-2 me-lg-3"
												onClick={addNewIPAccess}
											>
												{t("userModule.addNew")}
											</Button>
											<span
												className="text-primary fw-bold cursor-pe"
												onClick={() =>
													getmycurrentIp(Object.keys(ipinputFields)?.length)
												}
											>
												{t("userModule.myIp")}
											</span>
										</div>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="outline-primary" onClick={commonClose}>
							{t("button.close")}
						</Button>
						<Button type="submit" variant="primary" onClick={openErrorbox}>
							{t("button.toSave")}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</section>
	);
};
export default User;
